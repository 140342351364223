// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';

/**
 * 
 * Это штука для эффекта параллакса
 */

class Parallax extends PureComponent {

    constructor(props) {
        super(props);

        const { width } = props;

        this.state = {
            width
        }

        this.width = width;
        this.height = width;

        this.invertKoef = 1.2;


        this.edgeY = this.height * 0.27 / this.invertKoef;
        this.koef = this.width / 3200;
    }

    timer;

    componentDidMount = () => {
        this.timer = setInterval(this.timerTime, 1);
        this.me = document.getElementsByClassName('parallax_main')[0];
    }
    componentDidUpdate = ({ width }) => {
        if (width !== this.props.width) {
            this.width = this.props.width;
            this.height = this.props.width;

            this.invertKoef = 1.2;


            this.edgeY = this.height * 0.27 / this.invertKoef;
            this.koef = this.width / 3200;

            this.setState({
                width: this.props.width
            })
        }
    }

    timerTime = () => {
        const { me } = this;
        const parent = me.offsetParent;
        const offsetStart = parent.offsetTop;
        // const windowTop = window.scrollY;
        // const parentTop = this.parent.getBoundingClientRect().top;
        const meTop = me.getBoundingClientRect().top;
        const scrolledY = offsetStart - meTop;

        if (scrolledY !== this.state.scrolledY) {
            this.setState({ scrolledY: Math.min(scrolledY, this.edgeY) })
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.timer = null;
        delete this.timer;
    }

    render() {
        const { width } = this.props;
        const { scrolledY } = this.state;
        const { ScrolledThing } = this;

        const height = width - (0.85 * scrolledY * this.invertKoef) - (0.4 * scrolledY * this.invertKoef  );

        return <div style={{ width, height }} className='parallax_main'>


            <ScrolledThing {...{ inWidth: 3200, inHeight: 2076, top: 0, moveKoef: 0.4, imgSrc: '3_1', rez: 'jpg' }} />
            <ScrolledThing {...{ inWidth: 1042, inHeight: 1042, top: -200, left: 1600, moveKoef: -0.63, imgSrc: '3_3' }} />
            <ScrolledThing {...{ inWidth: 3200, inHeight: 1435, top: 670, moveKoef: 0.1, imgSrc: '3_2' }} />
            <ScrolledThing {...{ inWidth: 3200, inHeight: 1184, top: 2020, skewKoef: 0.85, moveKoef: 0.40, imgSrc: '3_0', rez: 'jpg' }} />

            <ScrolledThing {...{ inWidth: 1135, inHeight: 2041, moveKoef: 0.4, imgSrc: '1_2' }} />
            <ScrolledThing {...{ inWidth: 1367, inHeight: 2634, moveKoef: 0.7, imgSrc: '1_1' }} />
            <ScrolledThing {...{ inWidth: 1100, inHeight: 552, top: 150, left: 500, moveKoef: -0.83, imgSrc: '3_4' }} />
            <ScrolledThing {...{ inWidth: 3200, inHeight: 2634, top: 500, moveKoef: 0.9, imgSrc: 'fog' }} />


            <ScrolledThing {...{ inWidth: 1458, inHeight: 2040, moveKoef: 0.4, align: 'right', imgSrc: '2_4' }} />
            <ScrolledThing {...{ inWidth: 1119, inHeight: 2053, moveKoef: 0.42, align: 'right', imgSrc: '2_3' }} />
            <ScrolledThing {...{ inWidth: 908, inHeight: 2075, moveKoef: 0.43, align: 'right', imgSrc: '2_2' }} />
            <ScrolledThing {...{ inWidth: 726, inHeight: 2568, moveKoef: 0.7, align: 'right', imgSrc: '2_1' }} />

        </div>
    }

    ScrolledThing = ({ inWidth, inHeight, top = 0, left = 0, align = 'left', moveKoef, skewKoef, bg = null, imgSrc = null, rez = 'png' }) => {

        const { scrolledY } = this.state;

        const width = inWidth * this.koef;
        const height = inHeight * this.koef;

        let style = {
            top: top * this.koef,
            left: left * this.koef,
            width,
            height
        };
        let className = 'parallax_main_st';

        if (moveKoef) {
            style.top -= scrolledY * moveKoef * this.invertKoef;
        }
        if (skewKoef) {
            style.height -= scrolledY * skewKoef * this.invertKoef;
        }
        if (bg) style.background = bg;
        if (align === 'right') {
            className += ' parallax_main_st_right';
            style.left = '100%';
        }
        
        return <div style={style} className={className} >
            {imgSrc && <img src={`/../images/ver2/island/parallax/${imgSrc}.${rez}`} />}
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Parallax);