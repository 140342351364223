import React, { Fragment, PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import Loader from 'src/ver2/components/loader';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import { Radio, Select, Input } from 'antd';

import './style.css';

const { Option } = Select;

// class EventContactsPlashka
// classname: championship_manager_component

/**
  
 */

class ChampionshipManager extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        loading: false,
        Koef: 0
    }

    componentDidMount = () => {
        this.initSet();
    }

    initSet = () => {
        const { Championship } = this.props;
        let newState = {};
        if (Championship && Championship.Koef) newState.Koef = Championship.Koef;
        if (Championship && Championship.CreatorId) newState.CreatorId = Championship.CreatorId;
        this.setState(newState);
    }

    componentDidUpdate = ({ Championship: ChampionshipPrev }) => {
        const { Championship } = this.props;
        let newState = {};
        if (!Championship || !ChampionshipPrev) return;
        if (ChampionshipPrev.Koef !== Championship.Koef ) newState.Koef = Championship.Koef;
        if (ChampionshipPrev.CreatorId !== Championship.CreatorId ) newState.CreatorId = Championship.CreatorId;
        this.setState(newState);
    }

    onCreateCompetition = async () => {
        const { client, iamuser, Event, reloadChampionship, uploadEventMain } = this.props;
        this.setState({ loading: true });

        // Title, Date, CreatorId, PredvDate, RegistrationDate, CloseDate, Season

        const { Id } = iamuser;
        const CreatorId = Id;
        const {
            Title,
            Date,
        } = Event;
        const PredvDate = Date;
        const RegistrationDate = Date;
        const CloseDate = Date;
        const Season = '2024-2025'; // тут надо из client брать

        const Data = { Title, Date, CreatorId, PredvDate, RegistrationDate, CloseDate, Season }

        const competitionData = await client.competitionConstructor({ ActionData: 'create', Data });
        if (!competitionData.Competition) {
            console.log('Че то не так пошло')
            return;
        }

        const { Competition } = competitionData;

        const ChampionshipId = Competition.Id;
        await uploadEventMain({ ChampionshipId })

        // теперь надо обновить мероприятие
        console.log({ Event, iamuser });
        await reloadChampionship();

        // await client.addRaitingCategorys(Championship.Id);
        // reloadCategorys();
        this.setState({ loading: false });
    }

    onSendRaiting = async () => {
        const { Championship, client, reloadCategorys } = this.props;
        this.setState({ loading: true });

        await client.addRaitingCategorys(Championship.Id);
        reloadCategorys();
        this.setState({ loading: false });
    }
    onSendMain = async () => {
        const { Championship, client, reloadCategorys } = this.props;
        this.setState({ loading: true });

        await client.addMainCategorys(Championship.Id);
        reloadCategorys();
        this.setState({ loading: false });
    }

    render() {

        const { Event, Championship, Categorys } = this.props;
        const { loading } = this.state;

        if (!Event || !Championship) return <div className='championship_manager_component'>
            <Loader text='Гружу данные' />
        </div>

        const { Type } = Event;

        if (Type !== 'championship') {
            return <div className='championship_manager_component'>
                Это НЕ СОРЕВНОВАНИЕ
            </div>
        }

        const isThereCategorys = (Categorys && Categorys.length) || null;

        if (!Championship.Id) {
            return <div className='championship_manager_component'>
                <span>Не задано соревнование</span>
                <ButtonPlashka upperStyle={{ width: 170, height: 45 }} onClick={this.onCreateCompetition} text={'ДОБАВИТЬ СОРЕВНОВАНИЕ'} disabled={loading} />
            </div>
        }

        return <div className='championship_manager_component'>
            <span>Championship Id - {Championship.Id}</span>
            <span>Всего категорий - {isThereCategorys ? Categorys.length : 'нет'}</span>
            <br />
            {!isThereCategorys && <ButtonPlashka upperStyle={{ width: 170, height: 45 }} onClick={this.onSendRaiting} text={'ДОБАВИТЬ РЕЙТИНГ'} disabled={loading} />}
            {!isThereCategorys && <ButtonPlashka upperStyle={{ width: 170, height: 45 }} onClick={this.onSendMain} text={'ДОБАВИТЬ ОСНОВНЫЕ'} disabled={loading} />}
            <br />
            <br />
            <div className='championship_manager_component_pult'>
                <div>
                    <this.SelectRegion />
                    <this.SelectType />
                    <this.Named Name='FieldsNumber' />
                    <this.InputKoef />
                </div>
                <div>
                    <this.RadioNamed Name='Started' />
                    <this.RadioNamed Name='ShaduleReady' />
                    <this.RadioNamed Name='BriketsReady' />
                    <this.RadioNamed Name='ShowResults' />
                    <this.RadioNamed Name='Finished' />
                </div>
            </div>
        </div>
    }

    Named = ({ Name }) => {
        const { Championship } = this.props;
        const value = Championship[Name];
        return <Fragment>
            {Name}
            <br />
            {value}
            <br /><br />
        </Fragment>
    }


    updateData = async ({ Name, value }) => {
        const { Championship, client, reloadChampionship } = this.props;
        const { Id } = Championship;
        const Data = { [Name]: value };
        await client.competitionConstructor({ ActionData: 'update', CompetitionId: Id, Data });
        await reloadChampionship();
    }

    SelectRegion = () => {
        const { Championship, client } = this.props;
        const Name = 'Region';
        const val = Championship[Name];

        const regions = client.regions;
        const regionsNames = Object.keys(regions);

        return <Fragment>
            {Name}
            <br />
            <Select value={val} onChange={(value) => { this.updateData({ Name, value }) }}>
                {regionsNames.map(regionName => {
                    return <Option key={regionName} value={regionName}> {regions[regionName].title} </Option>
                })}
            </Select>
            <br /><br />
        </Fragment>
    }

    SelectType = () => {
        const { Championship, client } = this.props;
        const Name = 'Type';
        const val = Championship[Name];

        const competitionTypes = client.competitionTypes;
        const competitionTypesNames = Object.keys(competitionTypes);

        return <Fragment>
            {Name}
            <br />
            <Select value={val} onChange={(value) => { this.updateData({ Name, value }) }}>
                {competitionTypesNames.map(competitionTypesName => {
                    return <Option key={competitionTypesName} value={competitionTypesName}> {competitionTypes[competitionTypesName]} </Option>
                })}
            </Select>
            <br /><br />
        </Fragment>
    }

    InputKoef = () => {
        const Name = 'Koef';
        const val = this.state[Name];
        return <Fragment>
            {Name}
            <br />
            <Input
                value={val}
                onChange={(e) => { this.setState({ [Name]: e.target.value }) }}
                onBlur={() => {
                    const value = +val;
                    if (!isNaN(value)) {
                        this.updateData({ Name, value })
                    } else {
                        this.initSet();
                    }

                }}
            />
            <br /><br />
        </Fragment>
    }

    RadioNamed = ({ Name }) => {
        const { client, Championship, reloadChampionship } = this.props;
        const value = Championship[Name];
        return <Fragment>
            {Name}
            <br />
            <Radio.Group onChange={async (e) => { await client.updateCompetitionData({ ChampionshipId: Championship.Id, [Name]: e.target.value }); reloadChampionship(); }} value={value}>
                <Radio value={0}>Нет</Radio>
                <Radio value={1}>Да</Radio>
            </Radio.Group>
            <br /><br />
        </Fragment>
    }

}


export default withEventState(
    `
    Event,
    Championship,
    Categorys, 
    iamuser,
    client,
    reloadCategorys,
    reloadChampionship,
    uploadEventMain
    `
)(ChampionshipManager)