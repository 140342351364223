import React, { PureComponent } from 'react';
import ComboState, { withComboState } from 'src/ver2/components/combo/context';
import { TrefaLayout } from 'src/ver2/containers/layout';

import Plashki from './plashki';

import Loader from 'src/ver2/components/loader';

import './styles.css';

const {
    ComboInfoPlashka,
    ComboTitlePlashka,
    ComboSchemePlashka
} = Plashki;

class ComboPage extends PureComponent {

    state = {
    }

    render() {

        let width = 1200;
        const { isComboReady, Combo, isMobile } = this.props;

        if (!isComboReady) return <div className='combo_page'>
            <Loader text='Загрузка комбо' />
        </div>

        if (!Combo) return <div className='combo_page'>
            Ошибка загрузки комбо
        </div>

        if (isMobile) width = isMobile.width;

        // NOT MOBILE
        let components_ = [];
        components_.push(
            {
                component: ComboTitlePlashka,
                cell_x: 2,
                collapse: 3,
                id: 8151,
                componentProps: { height: 80, ...Combo }
            },
            {
                component: ComboSchemePlashka,
                cell_x: 2,
                collapse: 3,
                id: 8158,
                componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', _title: 'Схема', ...Combo }
            },
            {
                component: ComboInfoPlashka,
                cell_x: 2,
                collapse: 3,
                id: 8156,
                componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', _title: 'Описание', ...Combo }
            }
        )

        return <div style={{ width }} className='combo_page'>
            <TrefaLayout greedsX={[25, 575, 25, 550, 25]}
                components={
                    components_
                }
            />
        </div>
    }
}

const ComboPageWithState = withComboState(`
Combo,
isComboReady,

location,

client,
iamuser,
iAmAdmin,
isMobile
`)(ComboPage);

const RoundPageWithContent = (props) => <ComboState comboId={props.match.params.comboId ? Number(props.match.params.comboId) : 0} {...props}> {ComboPageWithState()} </ComboState>
export default RoundPageWithContent;

