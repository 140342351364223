import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';

import Loader from 'src/ver2/components/loader';

import { Collapse, Avatar, Icon } from 'antd';
import { orderBy } from 'lodash';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventCategorysPlashka
// classname: event_categorys_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */


const { Panel } = Collapse;


class EventCategorysPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false,
            lookedCategoryId: null
        }
    }

    componentDidMount = () => {
        this.setHeight()
    }

    componentDidUpdate = () => {
        this.setHeight()
    }

    setHeight = async () => {
        const { isCompetitorsCountInCategorysReady, competitorsCountInCategorys, Categorys, setComponentSelfHeight, plashkaId } = this.props;
        const { heightSetted } = this.state;
        if (!heightSetted && isCompetitorsCountInCategorysReady) {
            this.setState({
                heightSetted: true
            })
            setComponentSelfHeight({ height: 50 * Categorys.length + 550, plashkaId });
        }
    }

    getCountInCategory = (id) => {
        const { competitorsCountInCategorys, isCompetitorsCountInCategorysReady } = this.props;
        if (!isCompetitorsCountInCategorysReady || !competitorsCountInCategorys || !competitorsCountInCategorys[0]) return 0;
        const findedCount = competitorsCountInCategorys.find(x => x.CategoryId === id);
        if (findedCount) return findedCount.count;
        return 0;
    }

    setActiveCategory = async (key) => {
        const { client, Event } = this.props;

        if (key === undefined) {
            return this.setState({ selectedCategoryData: [], lookedCategoryId: key });
        }

        const selectedCategoryData = await client.getCompetitors(Event.ChampionshipId, key);
        this.setState({ selectedCategoryData, lookedCategoryId: key });
    }

    render() {

        const { Event, upperStyle, Categorys } = this.props;
        const { lookedCategoryId, selectedCategoryData } = this.state;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_categorys_plashka'>
            <Loader />
        </div>

        if (!Categorys || !Categorys.length) return <div style={{ width, height }} className='event_categorys_plashka'>
            <span>
                КАТЕГОРИИ ПОКА НЕ ОПРЕДЕЛЕНЫ
            </span>
        </div>

        const Categorys_ = orderBy( Categorys, ['AgeFrom', 'IsPro', 'Gender'] );

        return <div style={{ width, height }} className='event_categorys_plashka'>
            <Collapse height='fit-content' activeKey={lookedCategoryId} onChange={this.setActiveCategory} accordion>

                {Categorys_.map((item, index) => {
                    return <Panel
                        header={<this.PanelHeader category={item} />}
                        key={item.Id}>
                        <TrefaLayout greedsX={[500]}
                            components={[
                                {
                                    component: PersonsLittleListPlashka,
                                    cell_x: 1,
                                    collapse: 1,
                                    componentProps: {
                                        itemHeight: 40,
                                        height: 300,
                                        Persons: selectedCategoryData,
                                        autoSize: true,
                                        autoSizeMax: 7,
                                        personsDirect: true,
                                        mode: 'categorys'
                                    }
                                }
                            ]}
                        />
                        {/* <CategoryTable
                            clickOnData={undefined}
                            client={client}
                            data={selectedCategoryData}
                            deleteFromCategory={isAdmin ? this.deleteFromCategory : null}
                            moveFromCategory={isAdmin ? this.moveFromCategory : null}
                        /> */}

                    </Panel>
                })}

            </Collapse>
        </div>
    }

    PanelHeader = ({ category }) => {

        let categoryInfoBlock = null;

        const { isCategorysInfoReady } = this.props;

        if (isCategorysInfoReady) {
            const { categorysInfo, iamuser, client } = this.props;
            const {
                GroupIamInId,
                GroupId,
                SchoolId
            } = iamuser;

            const {
                groupHere,
                groupIamInHere,
                schoolHere,
            } = categorysInfo[category.Id] || {};

            const srcSchool = 'https://capoeirasport.com/graphics/logo_schools/' + SchoolId + '/logo.png';
            const srcGroupId = client ? client.getGroupUrl(GroupId) : 'images/groups/' + GroupId + '/logo.png';
            const srcGroupIHId = client ? client.getGroupUrl(GroupIamInId) : 'images/groups/' + GroupIamInId + '/logo.png';

            categoryInfoBlock = <div className='event_categorys_plashka_collapse_header_info'>
                {schoolHere ? <p className='global-tooltip' data-tooltip={'Есть участники из вашей школы'}><this.LogoPlusCount url={srcSchool} count={schoolHere} /></p> : null}
                {groupHere ? <p className='global-tooltip' data-tooltip={'Есть Ваши ученики'}><this.LogoPlusCount url={srcGroupId} count={groupHere} /></p> : null}
                {(GroupIamInId !== GroupId && groupIamInHere) ? <p className='global-tooltip' data-tooltip={'Есть участники из вашей группы'}><this.LogoPlusCount url={srcGroupIHId} count={groupIamInHere} /></p> : null}
            </div>

        }

        return <div className='event_categorys_plashka_collapse_header'>
            <div className='event_categorys_plashka_collapse_header_name_and_info'>{category.Name} {categoryInfoBlock} </div>
            <div> <Icon type="user" /> {this.getCountInCategory(category.Id)} </div>
        </div>
    }

    LogoPlusCount = ({ url, count }) => {
        return [
            <Avatar size={20} src={url} />,
            <div className='cf-lpc'>
                {count}
            </div>
        ]
    }


}

// const EventCategorysPlashkaWrapped = (props) => <EventWrapper Wrapped={EventCategorysPlashka} eventId={props.eventId} componentProps={{ ...props }} />
// export default withCapoeiraSportState('client')(EventCategorysPlashkaWrapped);

export default withEventState(`
Event, 
Championship, 
Categorys, 
isCompetitorsCountInCategorysReady, 
competitorsCountInCategorys, 
client,
iamuser,
categorysInfo,
isCategorysInfoReady
`)(EventCategorysPlashka)