// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';
import ImageUploader from 'src/ver2/containers/imageChanger';

import DataChanger, {compareFunction} from 'src/ver2/containers/dataChanger';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент администрирования мероприятия
 */

const AdminModal = ({ Shop, Discription, iAmAdmin, client, updateDiscription, addDiscription, uploadShopMain }) => {

    if (!iAmAdmin) return <div>ЧО ТЫ ТУТ ДЕЛАЕШЬ???</div>

    const mainInformationChange = async ( ShopMain ) => {

        const ShopMain_ = {...ShopMain, IsOn: ShopMain.IsOn ? 'yes' : 'no'}

        // тут у нас функция сравнения
        const resultSend = compareFunction( Shop, ShopMain_ );

        if (Object.keys(resultSend).length > 0 ) {
            uploadShopMain(resultSend);
        }
    }

    const uploadShopAvatar = async ( data ) => {
        const result = await client.updateAvatarShop( Shop.Id, data );
        if (result) {
            message.success('Автар изменён, перегрузите страницу');
        } else {
            
            message.warn('Ошибка на стороне сервера');
        }
    }

    return <div className='admin_modal'>
        <Tabs className='admin_modal_tabs' tabPosition='left' style={{ color: 'white', heightMin: 500 }} defaultActiveKey="1">

            <TabPane tab={'Основные данные'} key="1">
            <DataChanger
                        header={<div>
                            Основные данные магазина
                         </div>}
                        data={{...Shop, IsOn: Shop.IsOn === 'yes'}}
                        buttonText={'СОХРАНИТЬ'}
                        buttonState={'allNeeded'}
                        onButtonClick={mainInformationChange}
                        components={[
                            {
                                name: 'Title',
                                title: 'Название',
                                type: 'input',
                                style: {
                                    width: 600
                                },
                                neededText: ' '
                            },
                            {
                                name: 'IsOn',
                                title: 'Использовать предварительные даты',
                                text: 'Выберете, если вы еще не знаете точные даты мероприятия',
                                type: 'checkbox',
                                style: {
                                    width: 600
                                },
                            }
                        ]}
                    />
            </TabPane>
            <TabPane tab={'Информация'} key="2">
                {Discription ?
                    <InfoChanger text={Discription.Body} onCallback={updateDiscription} /> :
                    <InfoAdder onCallback={addDiscription} />
                }
            </TabPane>
            <TabPane tab={'Лейбл магазина'} key="3">
                <ImageUploader 
                    imageUrl = {client.getShopUrl() + Shop.Id +'.png'}
                    header = 'Установка аватара магазина'
                    recomendations = 'Добавьте квадратную аватарку, желательно круглый png с прозрачным фоном' 
                    upload = {uploadShopAvatar} />
            </TabPane>
        </Tabs>
    </div>
}

/*
    ChildrenComponent - какой компонент использовать
    CholdrenProps - что туда прокинуть
    onCallback - функция, которую вызывать при триггере компонента
    callbackName - имя функции, которую тригерить у компонента
    callbackParam - имя параметра, в котором к компоненты улетит callbackStatus
*/

class InfoAdder extends Component {
    state = {
        callbackStatus: ''
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { callbackStatus } = this.state;
        return <TrefaTextEdit buttonText={'Добавить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Добавить' }} onButtonClick={this.onCallback} />
    }

}

class InfoChanger extends Component {
    state = {
        callbackStatus: ''
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { text } = this.props;
        const { callbackStatus } = this.state;
        return <TrefaTextEdit text={text} buttonText={'Сохранить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Сохранение' }} onButtonClick={this.onCallback} />
    }

}

export default withShopState(`

Shop, 
Discription,
addDiscription,
updateDiscription,

uploadShopMain,

iAmAdmin,

client

`)(AdminModal);