import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';

import Loader from 'src/ver2/components/loader';
import { ItemTypeComponent, ItemSubtypeComponent } from 'src/ver2/components/item/types';


import { Checkbox } from 'antd';

// import { Link } from 'react-router-dom';

import './style.css';

// class ShopMoneyPlashka
// classname: shop_filters_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopFiltersPlashka extends PureComponent {

    render() {

        const { isShopItemsReady, ShopFilter, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!isShopItemsReady) return <div style={{ width, height }} className='shop_filters_plashka'>
            <Loader text='загрузка фильтра' />
        </div>

        return <div style={{ width, height }} className='shop_filters_plashka'>
            <div className='shop_filters_plashka_discription'>
                <this.TypesBlock />
                <this.SubtypesBlock />
                <this.GradesBlock />
                <this.ICatBuy />
            </div>
        </div>
    }

    TypesBlock = () => {

        const { setFilter, Filter, ShopFilter } = this.props;
        const { filterType, filterSubtype, gradeType, onlyForMe } = Filter;

        const shopFilterTypesKeys = Object.keys(ShopFilter);

        const onFilterClickType = (type) => {
            if (filterType === type) return setFilter({ key: 'filterType', value: null });
            return setFilter({ key: 'filterType', value: type })
        }

        return <div className='shop_filters_plashka_filters'>
            <div className='shop_filters_plashka_filters_types'>
                {shopFilterTypesKeys.map(type => <div
                    onClick={() => { onFilterClickType(type) }}
                    className={(filterType === type)
                        ? 'shop_filters_plashka_filters_type shop_filters_plashka_filters_type_selected'
                        : 'shop_filters_plashka_filters_type'}>
                    <ItemTypeComponent size={45} type={type} />
                </div>
                )}
            </div>
        </div>
    }

    SubtypesBlock = () => {

        const { setFilter, Filter, ShopFilter } = this.props;
        const { filterType, filterSubtype } = Filter;
        if (!filterType) return null;
        if (!ShopFilter[filterType]) return null;

        const shopFilterSubtypesKeys = Object.keys(ShopFilter[filterType]);

        const onFilterClickType = (subtype) => {
            if (filterSubtype === subtype) return setFilter({ key: 'filterSubtype', value: null });
            return setFilter({ key: 'filterSubtype', value: subtype })
        }

        return <div className='shop_filters_plashka_filters'>
            <div className='shop_filters_plashka_filters_types'>
                {shopFilterSubtypesKeys.map(subtype => <div
                    onClick={() => { onFilterClickType(subtype) }}
                    className={(filterSubtype === subtype)
                        ? 'shop_filters_plashka_filters_type shop_filters_plashka_filters_type_selected'
                        : 'shop_filters_plashka_filters_type'}>
                    <ItemSubtypeComponent size={45} subtype={subtype} />
                </div>
                )}
            </div>
        </div>
    }

    GradesBlock = () => {

        const { setFilter, Filter } = this.props;
        const { gradeType, filterType } = Filter;

        const grades = [1, 2, 3, 4, 5];

        const onFilterClickType = (grade) => {
            if (gradeType === grade) return setFilter({ key: 'gradeType', value: null });
            return setFilter({ key: 'gradeType', value: grade })
        }

        return <div className='shop_filters_plashka_filters'>
            <div className='shop_filters_plashka_filters_types'>
                {grades.map(grade => <div
                    title={`${grade} грейд`}
                    onClick={() => { onFilterClickType(grade) }}
                    className={(gradeType === grade)
                        ? 'shop_filters_plashka_filters_type shop_filters_plashka_filters_type_selected'
                        : 'shop_filters_plashka_filters_type'}>
                    <img src={`/../images/ver2/scores/types/${filterType || 'icon'}_${grade}.png`} />
                </div>
                )}
            </div>
        </div>
    }

    ICatBuy = () => {

        
        const { setFilter, Filter } = this.props;
        const { onlyForMe } = Filter;

        return <div className='shop_filters_plashka_filters'>
            <Checkbox
                onChange={(e) => { if (!e.target.checked) { return setFilter({ key: 'onlyForMe', value: false })} setFilter({ key: 'onlyForMe', value: true }); }}
                checked={onlyForMe}> только то, что могу приобрести </Checkbox>
        </div>

    }


}

export default withShopState(`
Filter,
setFilter,
ShopFilter,  
isShopItemsReady,
client
`)(ShopFiltersPlashka);