import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import { Modal, Select, Button, Icon } from 'antd';
import { groupBy, orderBy } from 'lodash';
import LittlePerson from 'src/ver2/components/person/little';

// import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
// import { TrefaLayout } from 'src/ver2/containers/layout';

import Loader from 'src/ver2/components/loader';

import './style.css';

const { Option } = Select;

// class EventBetPlashka
// classname: event_bet_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventBetPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        showBetModal: false,
        plusBets: 0,
        betLvl: 0,
        betChoose: [
            {
                CategoryId: null,
                UserId: null
            },
            {
                CategoryId: null,
                UserId: null
            },
            {
                CategoryId: null,
                UserId: null
            },
            {
                CategoryId: null,
                UserId: null
            },
            {
                CategoryId: null,
                UserId: null
            },
            {
                CategoryId: null,
                UserId: null
            }
        ]
    }

    componentWillMount() {

        const { myBets, iamuser } = this.props;
        const isThereMyBet = (myBets && myBets.length > 0) ? myBets[0] : null;
        if (isThereMyBet) {
            return this.setState({
                betChoose: JSON.parse(isThereMyBet.Settings)
            })
        }

        let betsCount = 6;
        let plusBets = 0;
        let betLvl = 0;


        let betChoose = [];
        if (iamuser && iamuser.myResourses && iamuser.myResourses.Scores && iamuser.myResourses.Scores.bet) {
            if (iamuser.myResourses.Scores.bet.all > 50) {
                plusBets++;
                betLvl = 2;
            }
            if (iamuser.myResourses.Scores.bet.all > 150) {
                plusBets++;
                betLvl = 3;
            }
            if (iamuser.myResourses.Scores.bet.all > 310) {
                plusBets++;
                betLvl = 4;
            }
        }

        for (let i = 0; i < (betsCount + plusBets); i++) {
            betChoose.push({
                CategoryId: null,
                UserId: null
            })
        }

        // console.log ({iamuser});

        this.setState({ betChoose, betLvl, plusBets })



    }

    componentDidUpdate(prevProps) {
        const { myBets } = this.props;
        if (myBets !== prevProps.myBets) {
            const isThereMyBet = (myBets && myBets.length > 0) ? myBets[0] : null;
            if (isThereMyBet) {
                this.setState({
                    betChoose: JSON.parse(isThereMyBet.Settings)
                })
            }
        }
    }

    createOperations = async (BetsWithResult) => {
        console.log('Сделать операции по прогнозам', { BetsWithResult });
        const { addOperation } = this.props;

        /*
                const {
            type,
            persons,
            text,
            payload,
            medals
        } = Data;
        */

        let addProgress = 0;
        const BetsWithResult_ = groupBy(BetsWithResult, 'AllScore');
        console.log('BetsWithResult_', { BetsWithResult_ });

        const BetsWithResultArrayKeys = Object.keys(BetsWithResult_);

        for (let i = 0; i < BetsWithResultArrayKeys.length; i++) {

            const key = BetsWithResultArrayKeys[i];
            const betsWithEqualScores = BetsWithResult_[key];
            let persons = [];
            let FinalScors = Number(key);
            for (let j = 0; j < betsWithEqualScores.length; j++) {
                const { UserId } = betsWithEqualScores[j];
                persons.push({ Id: UserId });
                addProgress++;
            }

            await addOperation({
                type: 'reward',
                persons,
                text: 'ПРОГНОЗ',
                payload: {
                    scores: { social: 3, bet: (FinalScors + 1) },
                    money: { tri: (FinalScors + 1) * 2 }
                }
            });
            console.log(`Добавлен прогноз ${addProgress} из ${BetsWithResult.length}`);
        }
        console.log(`Добавление прогнозов окончено`);
    }

    render() {

        const { Event, upperStyle, iamuser } = this.props;
        const { width, height } = upperStyle;
        const { showBetModal } = this.state;

        if (!Event) return <div style={{ width, height }} className='event_bet_plashka'>
            <Loader />
        </div>

        //console.log('iAmParticipator!!!', iAmParticipator, iAmCompetitor);
        return <div style={{ width, height }} className='event_bet_plashka'>
            <this.MainData />
            <Modal
                className='betClassModal'
                visible={showBetModal}
                onCancel={() => { this.setState({ showBetModal: false }) }}
                footer={null}
                title={'СДЕЛАТЬ ПРОГНОЗ'}
            >
                {showBetModal ? <this.BetModal /> : null}
            </Modal>
        </div>
    }

    BetModal = () => {
        const { Categorys, myBets, eventStatus, Event: { Id }, Bets } = this.props;
        if (!Categorys) return;
        const { betChoose } = this.state;


        let CategorysInGame = [];
        let InCategoryCount = {}

        if (Id === 101)
            CategorysInGame = [
                992, 993, 996, 1023, 1024, 997, 1000, 1001, 1002, 1003, 1004, 1005, 1026
            ]

        if (Id === 129) {
            CategorysInGame = [
                1129, 1130, 1131, 1132, 1133, 1134, 1135, 1136, 1145
            ]
            InCategoryCount = {
                1129: 1, 1134: 1, 1135: 1, 1136: 1, 1145: 1
            }
        }

        if (Id === 133) {
            CategorysInGame = [
                1163, 1168, 1171, 1172, 1173, 1174, 1176, 1177, 1179
            ]
            InCategoryCount = {
                1163: 1, 1168: 1, 1173: 1, 1174: 1, 1176: 1, 1177: 1, 1179: 1
            }
        }

        if (Id === 136) {
            CategorysInGame = [
                1246, 1273, 1275, 1276, 1277, 1278, 1279, 1280, 1254
            ]
            InCategoryCount = {
                1246: 1, 1273: 1, 1275: 1, 1276: 1, 1277: 1, 1278: 1, 1279: 1, 1280: 1, 1254: 1
            }
        }

        if (Id === 142) {
            CategorysInGame = [
                1260, 1261, 1282, 1284, 1285, 1265, 1286, 1287, 1289
            ]
            InCategoryCount = {
                1260: 1, 1261: 1, 1282: 1, 1284: 1, 1285: 1, 1265: 1, 1286: 1, 1287: 1, 1289: 1
            }
        }

        if (Id === 142) {
            CategorysInGame = [
                1260, 1261, 1282, 1284, 1285, 1265, 1286, 1287, 1289
            ]
            InCategoryCount = {
                1260: 1, 1261: 1, 1282: 1, 1284: 1, 1285: 1, 1265: 1, 1286: 1, 1287: 1, 1289: 1
            }
        }

        if (Id === 151) {
            CategorysInGame = [
                1321, 1322, 1323, 1324, 1325, 1326, 1327, 1328
            ]
            InCategoryCount = {
                1325: 1, 1328: 1, 1326: 1, 1327: 1
            }
        }
        if (Id === 149) {
            CategorysInGame = [
                1336, 1337, 1338, 1339, 1340, 1341, 1342, 1343, 1344, 1345, 1346, 1348, 1349, 1350, 1351, 1352, 1353, 1354
            ]
            InCategoryCount = {
                1336: 1, 1338: 1, 1339: 1, 1343: 1, 1344: 1, 1351: 1, 1352: 1
            }
        }
        if (Id === 152) {
            CategorysInGame = [
                1384, 1385, 1368, 1383, 1371, 1372, 1377, 1376, 1380, 1381
            ]
            InCategoryCount = {
                1385: 1, 1383: 1, 1372: 1
            }
        }
        if (Id === 147) {
            CategorysInGame = [
                1415, 1407, 1409, 1410, 1411, 1416, 1413, 1421, 1419, 1422, 1425, 1423, 1420, 1424
            ]
            InCategoryCount = {
                1422: 1, 1425: 1
            }
        }
        if (Id === 138) {
            CategorysInGame = [
                1511, 1501, 1512, 1502, 1513, 1514, 1516, 1517, 1519, 1505, 1520, 1523, 1507, 1510
            ]
            InCategoryCount = {
                1511: 1, 1501: 1, 1512: 1, 1502: 1, 1513: 1, 1514: 1, 1516: 1, 1517: 1, 1519: 1, 1505: 1, 1520: 1, 1523: 1, 1507: 1, 1510: 1
            }
        }
        if (Id === 139) {
            CategorysInGame = [
                1535, 1608, 1528, 1609, 1610, 1611, 1614, 1617, 1618, 1619, 1533
            ]
            InCategoryCount = {
                1535: 1, 1608: 1, 1528: 1, 1609: 1, 1610: 1, 1611: 1, 1614: 1, 1617: 1, 1618: 1, 1619: 1, 1533: 1
            }
        }
        if (Id === 150) {
            CategorysInGame = [
                1556, 1570, 1557, 1540, 1549, 1542, 1543, 1544, 1545, 1546, 1548, 1547, 1553, 1555, 1552, 1554
            ]
            InCategoryCount = {
                1556: 1
            }
        }
        if (Id === 153) {
            CategorysInGame = [
                1589, 1587, 1577, 1578, 1573, 1581, 1582, 1585, 1579, 1586, 1584, 1588
            ]
            InCategoryCount = {
                1589: 1, 1587: 1, 1578: 1, 1573: 1, 1584: 1, 1588: 1
            }
        }
        if (Id === 209) {
            CategorysInGame = [
                1651, 1652, 1655, 1656, 1657, 1658, 1660, 1661, 1665, 1666, 1669
            ]
            InCategoryCount = {
                1651: 1, 1652: 1, 1657: 1, 1658: 1, 1665: 1, 1666: 1, 1669: 1
            }
        }
        if (Id === 210) {
            CategorysInGame = [
                1699, 1700, 1683, 1684, 1687, 1688, 1691, 1692, 1695
            ]
            InCategoryCount = {
                1699: 1, 1700: 1, 1684: 1, 1687: 1, 1688: 1, 1695: 1
            }
        }
        if (Id === 140) {
            CategorysInGame = [
                1816, 1761, 1762, 1817, 1818, 1763, 1769, 1819, 1764, 1820, 1821, 1822, 1823, 1824, 1766, 1825, 1826, 1827, 1767, 1768
            ]
            InCategoryCount = {
                1816: 1, 1761: 1, 1762: 1, 1817: 1, 1818: 1, 1763: 1,
                1769: 1, 1819: 1, 1764: 1, 1820: 1, 1821: 1, 1822: 1,
                1823: 1, 1824: 1, 1766: 1, 1825: 1, 1826: 1, 1827: 1, 1767: 1, 1768: 1
            }
        }
        if (Id === 148) {
            CategorysInGame = [
                1816, 1761, 1762, 1817, 1818, 1763, 1769, 1819, 1764, 1820, 1821, 1822, 1823, 1824, 1766, 1825, 1826, 1827, 1767, 1768
            ]
            InCategoryCount = {
                1816: 1, 1761: 1, 1762: 1, 1817: 1, 1818: 1, 1763: 1,
                1769: 1, 1819: 1, 1764: 1, 1820: 1, 1821: 1, 1822: 1,
                1823: 1, 1824: 1, 1766: 1, 1825: 1, 1826: 1, 1827: 1, 1767: 1, 1768: 1
            }
        }
        if (Id === 148) {
            CategorysInGame = [
                1713, 1714, 1715, 1716, 1717, 1718, 1719, 1720, 1721, 1723, 1725, 1726, 1727, 1728, 1729, 1730, 1731, 1744, 1745, 1865
            ]
            InCategoryCount = {
                1744: 1, 1713: 1, 1745: 1, 1729: 1, 1719: 1
            }
        }
        if (Id === 225) {
            CategorysInGame = [
                1829, 1830, 1831, 1832, 1833, 1834, 1836, 1837, 1838, 1839, 1840, 1841, 1842, 1843, 1845, 1846, 1847, 1848, 1849
            ]
            InCategoryCount = {
                1829: 1, 1830: 1, 1831: 1, 1833: 1, 1834: 1, 1837: 1, 1839: 1, 1840: 1, 1841: 1, 1843: 1, 1845: 1, 1846: 1, 1847: 1, 1848: 1, 1849: 1
            }
        }
        if (Id === 227) {
            CategorysInGame = [
                1860, 1898, 1857, 1899, 1861, 1900, 1853, 1855, 1903, 1854, 1904, 1905, 1906
            ]
            InCategoryCount = {
                1860: 1, 1898: 1, 1857: 1, 1899: 1, 1861: 1, 1900: 1, 1853: 1, 1855: 1, 1903: 1, 1854: 1, 1904: 1, 1905: 1, 1906: 1
            }
        }
        if (Id === 244) {
            CategorysInGame = [
                2021, 2165, 2170, 2171, 2177, 2178, 2179, 2017, 2018, 2184, 2185, 2186, 2191, 2192, 2193
            ]
            InCategoryCount = {
                2021: 1, 2165: 1, 2170: 1, 2171: 1, 2177: 1, 2178: 1, 2179: 1, 2017: 1, 2018: 1, 2184: 1, 2185: 1, 2186: 1, 2191: 1, 2192: 1, 2193: 1
            }
        }
        if (Id === 273) {
            CategorysInGame = [
                2152, 2148, 2149, 2146, 2147, 2143, 2144, 2150, 2151
            ]
            InCategoryCount = {
                2152: 1
            }
        }
        if (Id === 246) {
            CategorysInGame = [
                2063, 2062, 2056, 2057, 2058, 2059, 2060, 2061
            ]
            InCategoryCount = {
                2061: 1
            }
        }
        if (Id === 247) {
            CategorysInGame = [
                2078, 2384, 2071, 2075, 2385, 2386, 2387, 2391, 2394, 2396, 2397, 2398, 2401, 2402
            ]
            InCategoryCount = CategorysInGame.reduce((memo, categoryId) => {
                return { ...memo, [categoryId]: 1 }
            }, {})
        }
        if (Id === 248) {
            CategorysInGame = [
                2221, 2222, 2223, 2224, 2225, 2226, 2227,
                2228, 2229, 2230, 2231, 2232, 2233, 2234,
                2235, 2236, 2237, 2333, 2381, 2509
            ]
            InCategoryCount = {
                2221: 1, 2509: 1, 2224: 1, 2225: 1, 2233: 1, 2226: 1, 2230: 1, 2237: 1, 2381: 1
            }
        }

        if (Id === 249) {
            CategorysInGame = [
                2442, 2443, 2444, 2445, 2446, 2447,
                2448, 2449, 2450, 2451, 2453, 2454, 2455, 2456,
                2457, 2460, 2461
            ]
            InCategoryCount = {
                2453: 1, 2445: 1, 2447: 1,
            }
        }

        if (Id === 258) {
            CategorysInGame = [
                2680, 2681, 2682, 2683, 2684, 2685, 2686, 2687, 2688, 2689, 2691, 2692,
                2693, 2694, 2695, 2698, 2699, 2700, 2701, 2702
            ]
            InCategoryCount = {
                2702: 1, 2687: 1, 2691: 1, 2700: 1, 2701: 1, 2684: 1, 2695: 1
            }
        }

        if (Id === 264) {
            CategorysInGame = [
                2710, 2711, 2712, 2713, 2714, 2715, 2716, 2717, 2718, 2719, 2720, 2721,
                2722, 2723, 2724,2725,2726,2729, 2730, 2731, 2732, 2733, 3103
            ]
            InCategoryCount = {
                2710: 1, 2721: 1, 2722: 1, 2733: 1, 3103: 1
            }
        }
        

        const eventStatus_ = eventStatus();
        const betsOver = (eventStatus_ === 'online' || eventStatus_ === 'past');
        let Scores = 0;

        const { Competitors } = this.props;
        let grCompetitors = groupBy(Competitors, 'CategoryId');
        if (Id === 148) {
            grCompetitors[1865] = (grCompetitors[1865] ? grCompetitors[1865] : []);
            grCompetitors[1865] = [...grCompetitors[1865], ...grCompetitors[1722], ...grCompetitors[1866]]
        }

        const ChoosedUserPlace = ({ CategoryId, UserId }) => {
            if (!CategoryId || !UserId) return null;
            if (!grCompetitors[CategoryId]) return null;
            const Person = grCompetitors[CategoryId].find(x => x.UserId === UserId);
            if (Person) {

                let Score = 0;
                if (Person.Place === 1) Score = 10;
                if (Person.Place === 2) Score = 5;
                if (Person.Place === 3) Score = 3;

                return <span>
                    {`${Person.Place} место`}
                    <br />
                    {`${Score} очков`}
                </span>
            }
            return null;

        }

        const OneBetChooser = ({ index, special }) => {

            const { betChoose } = this.state;

            if (!betChoose || !betChoose[index]) return null;

            return <div className='event_bet_plashka_onechooser'>
                <div className={'event_bet_plashka_onechooser_top ' + (special ? ' event_bet_plashka_onechooser_top_special' : '')}>выберете категорию и спортсмена</div>
                <div className='event_bet_plashka_onechooser_middle'>
                    <Select disabled={betsOver} style={{ width: 300 }} value={betChoose[index].CategoryId} onChange={(value) => {
                        const betChoose_ = [...betChoose];
                        betChoose_[index] = { CategoryId: value }
                        this.setState({ betChoose: betChoose_ })
                    }}>
                        {CategorysInGame.map((Id) => {
                            const Category = Categorys.find(x => x.Id === Id);
                            if (!Category) return null;

                            let count = 0;
                            for (let i = 0; i < betChoose.length; i++) {
                                if (betChoose[i].CategoryId === Id) count++;
                            }

                            let disabled = true;
                            if (InCategoryCount[Id]) {
                                disabled = count >= InCategoryCount[Id];
                            } else disabled = count > 1;

                            return <Option disabled={disabled} value={Category.Id}>
                                {Category.Name}
                            </Option>
                        })}
                    </Select>
                </div>
                {
                    (grCompetitors && grCompetitors[betChoose[index].CategoryId]) ?
                        <div className='event_bet_plashka_onechooser_bottom'>
                            <Select disabled={betsOver} size='large' style={{ width: 300 }} value={betChoose[index].UserId} onChange={(value) => {
                                const betChoose_ = [...betChoose];
                                betChoose_[index] = value !== -1 ? { ...betChoose_[index], UserId: value } : { CategoryId: betChoose_[index].CategoryId }
                                this.setState({ betChoose: betChoose_ })
                            }}>
                                <Option value={-1} >
                                    ОТМЕНИТЬ ВЫБОР
                                </Option>
                                {grCompetitors[betChoose[index].CategoryId].map((DirectPerson) => {

                                    const thereUser = betChoose.find(x => x.UserId === DirectPerson.UserId);

                                    return <Option disabled={thereUser} key={DirectPerson.UserId} value={DirectPerson.UserId}>
                                        <LittlePerson personsDirect={true} mode='bet' DirectPerson={DirectPerson} height={35} />
                                    </Option>
                                })}
                            </Select>
                            {betsOver && <ChoosedUserPlace CategoryId={betChoose[index].CategoryId} UserId={betChoose[index].UserId} />}
                        </div>
                        : null
                }
            </div>
        }

        const isThereMyBet = (myBets && myBets.length > 0) ? myBets[0] : null;

        const sendBet = () => {

            const { addUserBet, updateUserBet } = this.props;

            if (!isThereMyBet) {
                const Settings = JSON.stringify(this.state.betChoose);
                addUserBet({ Settings });
            } else {
                const Settings = JSON.stringify(this.state.betChoose);
                updateUserBet({ BetId: isThereMyBet.Id, Settings })
            }

            this.setState({ showBetModal: false });
        }

        // if (betsOver && myBets && myBets.length) {
        //     Scores = myBets.reduce( (memo, bet) => {

        //     }, 0)
        // }


        const YourBetsResults = () => {
            if (!betChoose) return <div>Вы не делали прогнозов</div>

            let CompetitorsResult = {}
            for (let i = 0; i < Bets.length; i++) {
                const Bet = Bets[i];
                const Settings = JSON.parse(Bet.Settings);
                for (let j = 0; j < Settings.length; j++) {
                    const { UserId, CategoryId } = Settings[j];
                    if (UserId) {
                        if (!CompetitorsResult[UserId]) {
                            CompetitorsResult[UserId] = { CategoryId, Count: 0 }
                        }
                        CompetitorsResult[UserId].Count++;
                    }
                }
            }

            let ScoreAll = 0;
            let BonusAll = 0;
            let BetsDone = 0;

            let BetsComponent = betChoose.map(({ CategoryId, UserId }) => {

                if (!UserId) return null;
                BetsDone++;

                const person = grCompetitors[CategoryId].find(x => x.UserId.toString() === UserId.toString());
                const { Count } = CompetitorsResult[UserId];

                let Score = 0;
                let Bonus = 0;

                if (person && person.Place && ((!InCategoryCount[CategoryId] && person.Place <= 3) || (InCategoryCount[CategoryId] && person.Place === 1))) {

                    let thisScore = 0;

                    if (person.Place === 1) thisScore = 3;
                    if (person.Place === 2) thisScore = 2;
                    if (person.Place === 3) thisScore = 1;
                    Score += thisScore;

                    if (100 * Count / Bets.length <= 10) Bonus += thisScore * 2;

                    ScoreAll += Score;
                    BonusAll += Bonus;

                } else {
                    return <LittlePerson mode='bet' personId={UserId} height={35}
                        Suffix={<span style={{ marginLeft: 'auto' }}> { } </span>} />
                }

                return <LittlePerson mode='bet' personId={UserId} height={35}
                    Suffix={<span style={{ marginLeft: 'auto' }}> {`${Score + Bonus} ${Bonus ? `(${Bonus} бонус)` : ''} `} </span>} />

            })

            if (!BetsDone) return null;

            return <>
                {BetsComponent}
                {`Ваш результат: ${ScoreAll} + ${BonusAll} бонусов = ${ScoreAll + BonusAll} очков `}
                <br />
                <br />
            </>

        }

        const BetsAllREsult = ({ max }) => {

            // парсим bets
            let CompetitorsResult = {}
            for (let i = 0; i < Bets.length; i++) {
                const Bet = Bets[i];
                const Settings = JSON.parse(Bet.Settings);
                for (let j = 0; j < Settings.length; j++) {
                    const { UserId, CategoryId } = Settings[j];
                    if (UserId) {
                        if (!CompetitorsResult[UserId]) {
                            CompetitorsResult[UserId] = { CategoryId, Count: 0 }
                        }
                        CompetitorsResult[UserId].Count++;
                    }
                }
            }

            const CompetitorsResultArray = orderBy(Object.keys(CompetitorsResult).map((UserId) => {
                const { Count, CategoryId } = CompetitorsResult[UserId];
                return { UserId, Count, CategoryId }
            }), 'Count', 'desc');


            return <div>
                {CompetitorsResultArray.map(({ UserId, CategoryId, Count }, index) => {

                    if (index > max) return null;

                    const person = grCompetitors[CategoryId].find(x => x.UserId.toString() === UserId.toString());
                    if (person) {
                        return <LittlePerson personsDirect={true} mode='bet' DirectPerson={person} height={35}
                            Suffix={<span style={{ marginLeft: 'auto' }}> {Count} <Icon type='user' /> {` / ${(100 * Count / Bets.length).toFixed()}%`} </span>} />
                    }
                    return null;
                })}
            </div>
        }

        const BetsAllBettors = ({ max }) => {

            // парсим bets
            let CompetitorsResult = {};
            let BetsWithResult = []; // {UserId, AllScore, Score, BetsGood, Bonus }
            for (let i = 0; i < Bets.length; i++) {
                const Bet = Bets[i];
                const Settings = JSON.parse(Bet.Settings);
                for (let j = 0; j < Settings.length; j++) {
                    const { UserId, CategoryId } = Settings[j];
                    if (UserId) {
                        if (!CompetitorsResult[UserId]) {
                            CompetitorsResult[UserId] = { CategoryId, Count: 0 }
                        }
                        CompetitorsResult[UserId].Count++;
                    }
                }
            }

            for (let i = 0; i < Bets.length; i++) {
                const Bet = Bets[i];
                const Settings = JSON.parse(Bet.Settings);

                // на кого он поставил
                let Score = 0;
                let Bonus = 0;
                let BetsGood = 0;
                let BetsAll = 0;
                for (let j = 0; j < Settings.length; j++) {
                    const { UserId, CategoryId } = Settings[j];
                    if (CompetitorsResult[UserId]) {
                        // находим чувака

                        const { Count } = CompetitorsResult[UserId];

                        BetsAll++;
                        const person = grCompetitors[CategoryId].find(x => x.UserId.toString() === UserId.toString());
                        if (person && person.Place && ((!InCategoryCount[CategoryId] && person.Place <= 3) || (InCategoryCount[CategoryId] && person.Place === 1))) {

                            let thisScore = 0;

                            if (person.Place === 1) thisScore = 3;
                            if (person.Place === 2) thisScore = 2;
                            if (person.Place === 3) thisScore = 1;
                            Score += thisScore;
                            BetsGood++;

                            if (100 * Count / Bets.length <= 10) Bonus += thisScore * 2;

                        }
                    }
                }
                BetsWithResult.push({ UserId: Bet.UserId, AllScore: Score + Bonus, Score, BetsGood, BetsAll, Bonus });
            }

            BetsWithResult = orderBy(BetsWithResult, 'AllScore', 'desc');

            const { iamuser } = this.props;

            return <div>
                {BetsWithResult.map(({ UserId, Score, BetsGood, BetsAll, Bonus }, index) => {
                    if (index > max) return null;

                    return <LittlePerson mode='bet' personId={UserId} height={35}
                        Suffix={<span style={{ marginLeft: 'auto' }}> {`${Score + Bonus} ${Bonus ? `(${Bonus} бонус)` : ''} `} <br /> {`${BetsGood} из ${BetsAll}`} </span>} />

                })}
                <br />

                {/* ЭТО РАСКОММЕНТИТЬ НА СЛУЧАЙ ПРОСТАВЛЕНИЯ ПРОГНОЗОВ !!!! */}
                {/* {(iamuser && iamuser.myPortalRoles && iamuser.myPortalRoles.creator) &&
                    <Button onClick={() => {
                        this.createOperations(BetsWithResult)
                    }}>Сделать операции</Button>
                } */}
            </div>
        }

        if (betsOver) {
            return <div className='event_bet_plashka_bet_modal'>
                <span className='event_bet_plashka_bet_modal_title'>Ваш прогноз</span>
                <YourBetsResults />
                <br />
                <span className='event_bet_plashka_bet_modal_title'>Самые популярные</span>
                <BetsAllREsult max={5} />
                <br />
                <span className='event_bet_plashka_bet_modal_title'>Топ 10 прогнозов</span>
                <BetsAllBettors max={10} />
            </div>
        }

        const { plusBets, betLvl } = this.state;

        return <div className='event_bet_plashka_bet_modal'>
            {
                betLvl ?
                    <span>
                        {`У вас ${betLvl} уровень прогнозов, вы получаете дополнительных ячеек: ${plusBets}`}
                    </span>
                    : null
            }
            {
                betChoose ?
                    betChoose.map((bet, index) => {
                        return <OneBetChooser key={index} index={index} special={plusBets ? (index >= (betChoose.length - plusBets)) : false} />
                    })

                    : null
            }
            {betsOver ? 'Мероприятия началось, больше нельзя сделать прогноз' : <Button size='large' type='primary' onClick={sendBet}> {isThereMyBet ? 'СОХРАНИТЬ' : 'СДЕЛАТЬ ПРОГНОЗ'} </Button>}
        </div>
    }

    MainData = () => {
        const { Event, iAmParticipator, iAmCompetitor, iamuser, isMobile, myBets, Bets, eventStatus } = this.props;

        const openWhatIsThis = () => {
            Modal.info({
                title: 'Что это?',
                content: <p>
                    Вы можете сделать прогноз на соревнование: <br /><br />
                    Вы можете выбрать себе фаворитов из предложенных категорий <br /><br />
                    За каждого фаворита, который займёт призовое место на соревновании, Вы будете получать очки. <br /><br />
                    Чем выше место, тем выше очки. <br />
                    Плюс, на количество очков будет влиять то, как много человек сделали такой же прогноз.
                    Если Вы угадали самого редкого чемпиона, очки будут выше.<br /><br />

                    Для участия Вам необходимо иметь профиль на capoeirasport и зайти под ним. <br />
                    Самые успешные прогнозисты получат очки и предметы для своего аккаунта на Capoeirasport ! <br /><br />

                    Есть ли ограничения? Да, из одной категории можно выбрать только двух фаворитов.<br />
                    Если в категории меньше шести человек, Вы можете сделать лишь один прогноз на неё, к тому же очки Вы получите,
                    только если угадаете золотого призёра<br /><br />

                    Обязательно ли выбирать всех? Нет, можете выбрать столько, сколько хотите, есть только ограничение по количеству сверху.<br /><br />

                    Могу ли я выбирать во время соревнования? Нет, как только соревнование начнется, возможность делать прогнозы исчезнет.<br /><br />

                    Можно ли выбирать себя? Естественно, если уверены в собственной победе! Хотя, о чём это мы, конечно, Вы уверены :D <br /><br />

                    Если я выберу двоих в одной категории и оба выйдут в финал? Получите больше очков <br /><br />

                    Очки и призы дадут сразу? Увы, нет. Функционал пока в тестовом режиме, так что может быть награды прогнозистов будут чуть позже <br /><br /><br />

                    Спортсмены, которых Вы выбрали, будут получать соответствующее уведомление, что, естественно, подбодрит их! <br />
                    А самые популярные спортсмены будут оглашены и тоже получат призы на портале :) <br /><br />

                    Ps. Не относимся к этому слишком серьёзно :)



                </p>,
                onOk: () => { },
                okText: 'Понятно!',
                okCancel: false,
                closable: true,
                centered: true
            })
        }

        let isBetOnMe = [];
        if (iAmCompetitor) {
            if (Bets && Bets.length > 0) {
                for (let i = 0; i < Bets.length; i++) {
                    const parsedBet = JSON.parse(Bets[i].Settings)
                    if (parsedBet && parsedBet.length && parsedBet.find(x => x.UserId === iamuser.Id)) {
                        isBetOnMe.push({ Id: Bets[i].UserId });
                    }
                }
            }
        }

        const eventStatus_ = eventStatus();
        const betsOver = (eventStatus_ === 'online' || eventStatus_ === 'past');

        let MiddleComponent = <span className='event_bet_plashka_maindata_left_makebet'>СДЕЛАТЬ ПРОГНОЗ</span>;
        if ((myBets && myBets.length > 0)) MiddleComponent = <span>МОЙ ПРОГНОЗ</span>;
        if (betsOver) MiddleComponent = <span>РЕЗУЛЬТАТЫ ПРОГНОЗОВ</span>;




        return <div className='event_bet_plashka_maindata'>
            <div className='event_bet_plashka_maindata_left event_bet_plashka_maindata_left_clickable' onClick={openWhatIsThis}>
                ЧТО ЭТО?
                <span>Сделайте прогноз на победителей категорий и получите призы от capoeirasport на свой аккаунт!</span>
            </div>
            <div
                className='event_bet_plashka_maindata_left event_bet_plashka_maindata_left_clickable'
                onClick={() => {
                    if (iamuser) {
                        this.setState({ showBetModal: true })
                    } else {
                        Modal.info({
                            title: 'Вы не авторизованы!',
                            content: <p>
                                Войдите, пожалуйста, в свой аккаунт на портале
                            </p>,
                            onOk: () => { },
                            okText: 'Ок!',
                            okCancel: false,
                            closable: true,
                            centered: true
                        })
                    }

                }}
            >
                {MiddleComponent}
            </div>
            {/* {!(myBets && myBets.length > 0) && (eventStatus_ === 'online' || eventStatus_ === 'past') ?
                null :
                <div
                    className='event_bet_plashka_maindata_left event_bet_plashka_maindata_left_clickable'
                    onClick={() => {

                        if (iamuser) {
                            this.setState({ showBetModal: true })

                        } else {
                            Modal.info({
                                title: 'Вы не авторизованы!',
                                content: <p>
                                    Войдите, пожалуйста, в свой аккаунт на портале
                                </p>,
                                onOk: () => { },
                                okText: 'Ок!',
                                okCancel: false,
                                closable: true,
                                centered: true
                            })
                        }

                    }}
                >
                    {MiddleComponent}
                </div> */}


            {(isBetOnMe && isBetOnMe.length > 0) ?
                <div className='event_bet_plashka_maindata_left event_bet_plashka_maindata_left_clickable' onClick={() => {
                    this.props.showInfoModal(
                        {
                            type: 'betParticipatorsList',
                            Data: isBetOnMe
                        }
                    )
                }}>
                    {`ВАС ВЫБРАЛИ (${isBetOnMe.length})`}
                </div>
                : null}
        </div>
    }


}

export default withEventState(`
Event,

iAmParticipator,

Categorys,

myBets,
Bets,
addUserBet,
updateUserBet,

addOperation,

eventStatus,

isMobile,

iAmCompetitor,
Competitors,


showInfoModal,
client,
iamuser,
`)(EventBetPlashka)