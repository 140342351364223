
import React from 'react';
import { MiniSequenceOnlyTechnics } from 'src/ver2/containers/challenges/components/mini-sequence';
import './styles.css';

const ExerciseSchemePlashka = ({ upperStyle: { width, height }, Scheme, elementHeight = null }) =>
    <div style={{ width, height }} className='exercise_standart_plashka'>
        <div className='exercise_standart_inside'>
            <MiniSequenceOnlyTechnics height={elementHeight || (height - 40)} elements={Scheme} />
        </div>
    </div >

export default ExerciseSchemePlashka;