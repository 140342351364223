// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';
import ImageUploader from 'src/ver2/containers/imageChanger';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент создания предмета
 */

const ChangeOrderStatus = ({ iAmAdmin, changeOrderStatus, RealOrderId, RealShopOrdersAll, closeModal, statusData }) => {

    if (!iAmAdmin) return <div>ЧО ТЫ ТУТ ДЕЛАЕШЬ???</div>

    const Order = (RealShopOrdersAll || []).find( x => x.Id === RealOrderId );

    if (!Order) {
        return <div>НЕ МОГУ НАЙТИ ЗАКАЗ {RealOrderId} </div>
    }

    const data = { Status: Order.Status };

    const mainInformationChange = async (Data) => {
        changeOrderStatus({ ...Data, RealOrderId }, {UserId: Order.UserId, orderTitle: statusData(Number(Data.Status)).title });
        closeModal();
    }

    let options = [];
    options.push({ key: 1, title: statusData(1).title });
    options.push({ key: 2, title: statusData(2).title });
    options.push({ key: 3, title: statusData(3).title });
    options.push({ key: 4, title: statusData(4).title });
    options.push({ key: 5, title: statusData(5).title });

    return <div className='admin_modal'>
        <Tabs className='admin_modal_tabs' tabPosition='left' style={{ color: 'white', heightMin: 400 }} defaultActiveKey="1">

            <TabPane tab={'Статус'} key="1">
                <DataChanger
                    header={<div>
                        Изменение статуса заказа
                    </div>}
                    data={data}
                    buttonText={'ИЗМЕНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={mainInformationChange}
                    components={[
                        {
                            name: 'Status',
                            title: 'Статус',
                            type: 'select',
                            isNecessery: true,
                            options,
                            defValue: 1
                        },
                    ]}
                />
            </TabPane>
            <TabPane tab={'Оплата'} key="2">
                <DataChanger
                    header={<div>
                        Оплата
                    </div>}
                    data={{ Payment: Order.Payment || 0 }}
                    buttonText={'ИЗМЕНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={mainInformationChange}
                    components={[
                        {
                            name: 'Payment',
                            title: 'Поступила оплата',
                            type: 'input',
                            style: {
                                width: 200
                            },
                            neededText: ' '
                        }
                    ]}
                />
            </TabPane>
        </Tabs>
    </div>
}

export default withShopState(`

Shop, 
RealShopOrdersAll,
changeOrderStatus,
statusData,

iAmAdmin,

client

`)(ChangeOrderStatus);