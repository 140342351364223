import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaText from 'src/ver2/components/trefaText';

import Loader from 'src/ver2/components/loader';
import { groupBy } from 'lodash';
// import { Link } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import { Button, message } from 'antd';
import 'moment/locale/ru';

import './style.css';

// class RealShopAdminPlashka
// classname: realshop_myorders_plashki

/**
  Это просто типовая плашка, копировать отсюда
 */
const copyToClipboard = (value) => {
    const copytext = document.getElementById('copyCopyText');
    if (copytext) {
        copytext.value = value;
        copytext.select();
        document.execCommand('copy');
        message.success('Скопировано ' + value);
    }
}


class RealShopMyOrdersPlashka extends PureComponent {

    state = {
        selectedCode: null
    }

    setSelectedCode = (selectedCode = null) => this.setState({ selectedCode });

    render() {

        const {
            upperStyle,
            isShopItemsReady
        } = this.props;

        const { selectedCode } = this.state;

        const { width, height } = upperStyle;

        if (!isShopItemsReady) return <div style={{ width, height }} className='realshop_myorders_plashki'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='realshop_myorders_plashki'>
            {selectedCode ?
                <this.CodePayment />
                :
                <this.Orders />
            }
        </div>
    }

    CodePayment = () => {

        const { PaymentDiscription, iamuser } = this.props;
        const { selectedCode } = this.state;
        const { Code, payment } = selectedCode;

        if (!iamuser) return;

        return <div className='realshop_myorders_plashki_selectedCode'>
            <div className='realshop_myorders_plashki_main_ordertitle'>
                {`Оплата ${payment}₽`}
            </div>
            <div className='realshop_myorders_plashki_main_orderpaymenttitle'>
                {`Оплата заказа: ${Code}`}
                <br />
                {`ФИО: ${iamuser.Surname} ${iamuser.Name}`}
            </div>
            <div className='realshop_myorders_plashki_main_orderpaymentpleasecopy'>
                <span>
                    Пожалуйста, скопируйте текст в рамке самостоятельно, или нажав на кнопку, и прикрепите к сообщению
                </span>
                <Button type = 'primary' onClick={() => {copyToClipboard(`Оплата заказа: ${Code}  ФИО: ${iamuser.Surname} ${iamuser.Name}`)}}>СКОПИРОВАТЬ ТЕКСТ</Button>
            </div>
            <TrefaText style={{ avatarsSize: 16, fontSize: 19, minWidth: 100 }} trefaText={PaymentDiscription ? PaymentDiscription.Body : '^a_^text^a_^'} />
            <Button type ='primary' onClick={() => { this.setSelectedCode() }}>ВСЁ СДЕЛАНО!</Button>
        </div>


    }

    Orders = () => {
        const {
            RealShopOrders = [],
            iamuser,
        } = this.props;

        if (!iamuser || !iamuser.Id) return <div className='realshop_myorders_plashki_main'>
            <div>
                Вы не вошли в свой аккаунт
            </div>
        </div>

        const RealOrdersBuUsers = groupBy(RealShopOrders, 'UserId') || {};
        const MyRealOrders = RealOrdersBuUsers[iamuser.Id] || [];

        return <div className='realshop_myorders_plashki_main'>
            <this.CurrentOrder />
            <this.LastOrders MyRealOrders={MyRealOrders} />
        </div>
    }

    LastOrders = ({ MyRealOrders }) => {
        const {
            RealShopItems = [],
            RealShopArticules,
            statusData,
            isMobile
        } = this.props;

        if (!MyRealOrders || !MyRealOrders.length) return null;

        let Answer = [];

        for (let i = 0; i < MyRealOrders.length; i++) {
            const { Payment, Status, TimeStamp, Orders, Code } = MyRealOrders[i];

            let FinalCost = 0;
            let realOrderWithIds = {};
            for (let j = 0; j < Orders.length; j++) {
                const { RealArticuleId, Count } = Orders[j];
                const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
                const {
                    RealItemId
                } = RealArticule;
                const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
                const { Cost } = RealItem;
                FinalCost += Cost * Count;
                realOrderWithIds[RealItemId] = realOrderWithIds[RealItemId] || 0;
                realOrderWithIds[RealItemId] += Count;
            }

            const sale = this.calculateSales(realOrderWithIds);
            FinalCost -= sale;

            Answer.push(
                <div className='realshop_myorders_plashki_main_lastorders_oneorder'>
                    <div className='realshop_myorders_plashki_main_lastorders_title'>
                        <span>
                            {`${TimeStamp} / Заказ ${Code}`}
                        </span>
                        <div
                            style={statusData(Status).style}
                            className='realshop_myorders_plashki_tovars_row_lowtitle realshop_myorders_plashki_tovars_row_lowtitle_status'>
                            {statusData(Status).title}
                        </div>
                    </div>
                    <div className='realshop_myorders_plashki_tovars_row realshop_myorders_plashki_tovars_row_header'>
                        <div className='realshop_myorders_plashki_tovars_row_title'>
                            Товар
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            Артикул
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            {isMobile ? 'Кол-во' : 'Количество'}
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            Цена
                        </div>
                    </div>
                    <div className='realshop_myorders_plashki_main_lastorders_orders'>
                        {Orders.map(({ RealArticuleId, Count, Id, RealItemId }) => {
                            const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
                            const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
                            const {
                                Name
                            } = RealArticule;
                            return <div className='realshop_myorders_plashki_tovars_row' key={`raptrrr_${Id}`}>
                                <div className='realshop_myorders_plashki_tovars_row_title'>
                                    {RealItem.Title}
                                </div>
                                <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                                    {Name}
                                </div>
                                <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                                    {`x${Count}`}
                                </div>
                                <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                                    {`${Count * RealItem.Cost}₽`}
                                </div>
                            </div>
                        })}
                    </div>
                    <div className='realshop_myorders_plashki_tovars_row_itog'>
                        <div className='realshop_myorders_plashki_tovars_row_cost '>
                            {Payment ? `Оплачено: ${Payment}₽` : <Button
                                type="primary"
                                onClick={() => { this.setSelectedCode({ Code, payment: FinalCost }) }}
                            // onClick={() => this.props.showInfoModal({ type: 'eventDiscription', Discription: { ...PaymentDiscription, title: 'Оплата' } })}
                            > {`ОПЛАТИТЬ: ${FinalCost}₽`}</Button>}
                        </div>
                    </div>
                </div >
            )
        }

        return <div className='realshop_myorders_plashki_main_lastorders_main'>
            {Answer}
        </div>
    }

    calculateSales = (dataObj = {}) => {
        // мы берём заказ, он нужен нам как список { RealItemId: Count }

        const { Shop } = this.props;
        if (!Shop || !Shop.SettingsSales || !Shop.SettingsSales.length) return;

        let minusCost = 0;

        for (let i = 0; i < Shop.SettingsSales.length; i++) {
            const { Ids, Minus } = Shop.SettingsSales[i];
            let someSaleMaxCount = 999;
            for (let j = 0; j < Ids.length; j++) {
                const neededId = Ids[j];
                if (dataObj[neededId]) {
                    someSaleMaxCount = Math.min(someSaleMaxCount, dataObj[neededId]);
                } else { someSaleMaxCount = 0; continue; }
            }

            if (someSaleMaxCount > 0) {
                minusCost += Minus * someSaleMaxCount;
            }
        }

        return minusCost;
    }

    CurrentOrder = () => {
        const {
            currentOrder,
            RealShopItems = [],
            RealShopArticules,
            isMobile
        } = this.props;
        if (!currentOrder || !currentOrder.length) return null;

        let FinalCost = 0;

        let realOrderWithIds = {};
        for (let i = 0; i < currentOrder.length; i++) {
            const { RealArticuleId, Count } = currentOrder[i];
            const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
            const {
                RealItemId
            } = RealArticule;
            const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
            const { Cost } = RealItem;
            FinalCost += Cost * Count;
            realOrderWithIds[RealItemId] = realOrderWithIds[RealItemId] || 0;
            realOrderWithIds[RealItemId] += Count;
        }

        const sale = this.calculateSales(realOrderWithIds);
        let itogComponent = <span>{`ИТОГ: ${FinalCost}₽`}</span>

        if (sale && sale > 0) {
            itogComponent = <React.Fragment>
                <span>{`ИТОГ:`}</span>
                <span className='realshop_myorders_plashki_main_order_sale'>{`${FinalCost}₽`}</span>
                <span>{`${FinalCost - sale}₽`}</span>
            </React.Fragment>
        }

        return <React.Fragment>
            <div className='realshop_myorders_plashki_main_ordertitle'>
                Ваш текущий заказ
            </div>
            <div className='realshop_myorders_plashki_main_myorders'>
                <div className='realshop_myorders_plashki_tovars_row realshop_myorders_plashki_tovars_row_header'>
                    <div className='realshop_myorders_plashki_tovars_row_title'>
                        Товар
                    </div>
                    <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                        Артикул
                    </div>
                    <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                        {isMobile ? 'Кол-во' : 'Количество'}
                    </div>
                    <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                        Цена
                    </div>
                </div>
                {currentOrder.map(({ RealArticuleId, Count }) => {
                    const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
                    const {
                        Name, RealItemId
                    } = RealArticule;
                    const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
                    const { Title, Cost } = RealItem;

                    return <div className='realshop_myorders_plashki_tovars_row' key={`raptrtttrr_${RealArticuleId}_${Count}`}>
                        <div className='realshop_myorders_plashki_tovars_row_title'>
                            {Title}
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            {Name}
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            {`x${Count}`}
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            {`${Count * Cost}₽`}
                        </div>
                    </div>
                })}
            </div>
            <div className='realshop_myorders_plashki_main_myorders_down'>
                <div className='realshop_myorders_plashki_main_myorders_down_itog'>
                    {itogComponent}
                </div>
                <Button type='primary' onClick={this.props.makeAnOrder}>
                    СОЗДАТЬ ЗАКАЗ
                </Button>
            </div>
        </React.Fragment>
    }

}

export default withShopState(`

Shop,
PaymentDiscription,
isShopItemsReady,
RealShopOrders,  
RealShopItems,
RealShopArticules,
RealShopArticulesByItems,
RealShopArrivals,

statusData,
currentOrder,
makeAnOrder,

showInfoModal,

iamuser,
isMobile,
client
`)(RealShopMyOrdersPlashka);