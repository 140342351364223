
import React from 'react';
import StarIcon from 'src/ver2/components/ui/icons/star';
import './styles.css';

const STARS = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

const ExerciseDiscriptionPlashka = ({ upperStyle: { width, height }, Difficult, innerId }) =>
    <div style={{ width, height }} className='exercise_standart_plashka'>
        <div className='exercise_standart_inside' id={innerId}>
            <div className='exercise_discription_row'>
                <div className='exercise_discription_title'>
                    ТИП:
                </div>
                <div className='exercise_discription_upper'>
                    УДАРНАЯ ТЕХНИКА
                </div>
            </div>
            <div className='exercise_discription_row'>
                <div className='exercise_discription_title'>
                    СЛОЖНОСТЬ:
                </div>
                <div className='exercise_discription_bottom'>
                    {STARS.map((value, index) => {
                        console.log(index, Difficult);
                        const className = 'exercise_discription_star ' + ((index < Difficult) ? ' exercise_discription_star_bright' : '');
                        return <div className={className}>
                            <StarIcon />
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div >

export default ExerciseDiscriptionPlashka;