import React, { Component } from 'react';
import { Input, Radio } from 'antd';

// КОМПОНЕНТ ВЫБОРА ЧИСТОЙ/НЕ ЧИСТОЙ ПОБЕДЫ

export class SetWinTo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWin: 0
        }
    }

    onChange = (e) => {

        const { setSelectedWin } = this.props;
        setSelectedWin(e.target.value);

        this.setState({ selectedWin: e.target.value })
    }

    render() {

        const { selectedWin } = this.state;

        return <div>
            <Radio.Group onChange={this.onChange} value={selectedWin}>
                <Radio value={0}>Единогласно</Radio>
                <Radio value={1}>С преимуществом в 1</Radio>
            </Radio.Group>
        </div>
    }

}

// КОМПОНЕНТ ИНПУТА ТАЙМИНГА
export class SetTimeStamp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoTimeStamp: 0
        }
    }

    onChange = (e) => {

        const { setVideoTimeStamp } = this.props;
        setVideoTimeStamp(e.target.value);

        this.setState({ videoTimeStamp: e.target.value })
    }

    render() {

        const { videoTimeStamp } = this.state;

        return <div>
            <Input onChange={this.onChange} value={videoTimeStamp} />
        </div>
    }

}


export class TrefaTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWin: 0,
            shiftDate: 0,
            status: 'pause'
        }
        this.startDate = new Date();
        this.timer = setInterval(this.timerTime, 50);
    }

    timerTime = () => {
        const nowTime = new Date();
        const { status, shiftDate } = this.state;
        if (status === 'play')
            this.setState({
                shiftDate: shiftDate + (nowTime - this.startDate)
            })
        this.startDate = nowTime;
    }

    setPause = () => { this.setState({ status: 'pause' }) }
    setPlay = () => {
        this.startDate = new Date();
        this.setState({ status: 'play' })
    }

    setStop = () => {
        this.setState({ shiftDate: 0, status: 'pause' })
    }

    onFirstClick = () => {
        const { status } = this.state;
        if (status === 'play') this.setPause(); else this.setPlay();
    }

    render() {

        const { shiftDate, status } = this.state;
        const { MinutesPerCouple } = this.props;
        const seconds = shiftDate / 1000;

        let SecPerCouple = 0;
        if (MinutesPerCouple === 3) SecPerCouple = 60;
        if (MinutesPerCouple === 2.5) SecPerCouple = 45;
        if (MinutesPerCouple === 2) SecPerCouple = 30;

        let { alertMode } = this.props;

        let neededAlertMode = null;

        if (SecPerCouple) {
            if (seconds.toFixed(0) >= SecPerCouple - 1) {
                neededAlertMode = 'orange';
            }
            if (seconds.toFixed(0) > SecPerCouple + 2) {
                neededAlertMode = 'red';
            }
        }

        let secondsClassName = 'tt-seconds ';
        let secondsText = `${seconds.toFixed(1)}`;

        if (SecPerCouple) {
            secondsText = `${seconds.toFixed(1)} / ${SecPerCouple}.0`;
        }
        if (neededAlertMode) {
            secondsClassName += ` tt-seconds_alert_${neededAlertMode}`;
        }

        if ( alertMode !== neededAlertMode) {
            this.props.setAlertMode (neededAlertMode);
        }

        return <div className='trefa-timer-container'>
            <div className={secondsClassName}>{secondsText}</div>
            <div className='tt-panel'>
                <div className='tt-button' onClick={this.onFirstClick}>
                    {status === 'play' ? 'Пауза' : 'Пуск'}
                </div>
                <div className='tt-button' onClick={this.setStop}>
                    Сброс
          </div>
            </div>

        </div>
    }

}