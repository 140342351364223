// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { gsap } from 'gsap';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

const SCHEME = [
    1,
    1,
    1, // 2 - начало
    1,
    3,
    1,
    1,
    2,
    1,
    3, // 9
    2,
    3,
    1, // 12
    1,
    2, 
    3,
    3,
    1,
    3,
    3,
    1
]


class Quest254 extends PureComponent {
    state = {
        steps: 0,
        nowStep: 1
    }

    send = () => {
        const { steps } = this.state;
        // let answerString = answer.reduce( ( memo, value) => memo + value.toString(), '');
        // this.props.sendSpecial({ data: { [answerString]: 1 } })

        if ( steps === 8) {
            this.props.sendSpecial({ data: { 'steps8': 1 } })
        } else {
            this.props.sendSpecial({ data: { 'steps9': 1 } })
        }
        this.init();
    }

    init = () => {

        gsap.killTweensOf('.quest259_step');
        gsap.killTweensOf('.quest259_player');

        gsap.to('.quest259_step', {
            scale: 1,
            translateY: 0,
            repeat: 0,
            duration: 0.1,
        }
        )
        gsap.to('.quest259_player', {
            scale: 1,
            translateY: 0,
            repeat: 0,
            duration: 0.1,
        }
        )

        this.setState({
            steps: 0,
            nowStep: 1
        })
    }

    stepsPLus = (step) => {
        let steps = this.state.steps;
        let nowStep = this.state.nowStep;
        nowStep += step;
        steps++;
        this.setState({ steps, nowStep });

        let onComplete = () => { };
        // тут мы задаём gsap штуку
        if (SCHEME[nowStep] === 2) {
            onComplete = () => {
                gsap.fromTo(`.quest259_steps_${nowStep}`, { delay: 0.4, translateY: -3 }, {
                    translateY: 3,
                    repeat: -1,
                    duration: 0.7,
                    yoyoEase: true
                }
                )
                gsap.fromTo(`.quest259_player`, { delay: 0.4, translateY: -6 }, {
                    translateY: 6,
                    repeat: -1,
                    duration: 0.7,
                    yoyoEase: true
                }
                )
            }
        }
        if (SCHEME[nowStep] === 3) {
            onComplete = () => {
                gsap.fromTo(`.quest259_steps_${nowStep}`, { scale: 1 }, {
                    delay: 0.4,
                    scale: 0,
                    repeat: 0,
                    duration: 1.3
                }
                )
                gsap.fromTo(`.quest259_player`, { scale: 1 }, {
                    delay: 0.4,
                    scale: 0,
                    repeat: 0,
                    duration: 1.3
                }
                )
            }
        }
        gsap.killTweensOf('.quest259_player');
        gsap.to('.quest259_player', {
            scale: 1,
            translateY: 0,
            repeat: 0,
            duration: 0.1,
        }
        )
        gsap.killTweensOf('.quest259_step');
        gsap.to('.quest259_step', {
            scale: 1,
            translateY: 0,
            repeat: 0,
            duration: 0.1,
            onComplete
        }
        )
        return;

    }

    render() {
        const className = 'quest259_container';
        const { steps } = this.state;

        return <div className={className}>
            <this.Steps />
            <div className='quest259_container_stepscount'>
                Шагов: {steps}
            </div>
            <this.Pult />
        </div>
    }

    Steps = () => {

        const { isMobile } = this.props;
        const allWidth = isMobile ? isMobile.width : 500;
        const stepsWidth = allWidth * 0.98;

        const oneStepAllWidth = stepsWidth / 21;
        const oneStepWidth = oneStepAllWidth * 0.9;

        let answ = [];
        for (let i = 0; i < 21; i++) {
            const dopClassName = '';
            let style = {
                width: oneStepWidth,
                height: oneStepWidth * 3
            }
            if (i === 0) {
                style.width = oneStepWidth / 2;
                style.left = 0;
            } else {
                style.left = (i - 1) * oneStepAllWidth + oneStepAllWidth / 2
            }
            answ.push(
                <div key={`q259s_${i}`} style={style} className={`quest259_step quest259_steps_${i} ${dopClassName}`} />
            )
        }

        const { nowStep } = this.state;
        const style =
            (nowStep === 0) ?
                {
                    left: 0,
                    top: oneStepWidth * 1.5 - oneStepWidth / 2,
                    width: oneStepWidth,
                    height: oneStepWidth
                } : {
                    left: (nowStep - 1) * oneStepAllWidth + oneStepAllWidth / 2,
                    top: oneStepWidth * 1.5 - oneStepWidth / 2,
                    width: oneStepWidth,
                    height: oneStepWidth
                }

        answ.push(<div style={style} key={`q259s_player`} className='quest259_player' />)

        return <div style={{ height: oneStepWidth * 5 }} className='quest259_steps'>
            {answ}
        </div>
    }

    Pult = () => {
        const { nowStep, steps } = this.state;

        let btns = [];
        if (SCHEME[nowStep] === 3) {
            btns.push(<div onClick={this.init} key={`q259_ret`} className='quest259_pult_btn'>
                <spam>ЗАНОВО</spam>
            </div>)
        } else if (nowStep >= 20) {
            btns.push(<div onClick={this.send} key={`q259_ok`} className='quest259_pult_btn'>
                <spam>ГОТОВО! {steps} ХОДОВ!</spam>
            </div>)
        } else {
            console.log(SCHEME[nowStep]);
            if (SCHEME[nowStep] === 1) {
                btns.push(<div onClick={() => { this.stepsPLus(3) }} key={`q259_bt2`} className='quest259_pult_btn'>
                    <spam>ПРЫЖОК <br /> НА ТРИ</spam>
                </div>)
            }
            if (nowStep !== 17)
                btns.push(<div onClick={() => { this.stepsPLus(4) }} key={`q259_bt3`} className='quest259_pult_btn'>
                    <spam>ПРЫЖОК <br /> НА ЧЕТЫРЕ</spam>
                </div>)

            const addClassName = nowStep === 1 ? 'quest259_pult_dashed' : '';

            if (nowStep > 0)
                btns.push(<div onClick={() => { this.stepsPLus(-1) }} key={`q259_btback`} className={`quest259_pult_btn ${addClassName}`}>
                    <spam>НАЗАД <br /> НА ОДНУ</spam>
                </div>)
        }

        return <div className='quest259_pult'>
            {btns}
        </div>
    }


    Lamp = ({ index }) => {

        let className = `quest254_lamp quest254_lamp_${index} `;
        const { answer } = this.state;
        if (answer[index]) className += 'quest254_lamp_on'

        return <div className={className}>
        </div>
    }

    Swticher = ({ index }) => {

        const { isMobile } = this.props;
        const width = (isMobile ? isMobile.width / 6.5 : 500 / 6.5) / 2;
        const height = width * 2;
        const style = {
            width,
            height
        }

        const onClick = () => {
            let answer = [...this.state.answer];

            for (let i = 0; i < Object.keys(SCHEME[index]).length; i++) {
                const thisLampIndex = Object.keys(SCHEME[index])[i]; // это индекс лампы
                const switcherType = SCHEME[index][thisLampIndex];

                if (switcherType === 1) answer[thisLampIndex] = !answer[thisLampIndex];
                if (switcherType === 2) answer[thisLampIndex] = true;
                if (switcherType === 3) answer[thisLampIndex] = false;
            }
            gsap.to(`.quest254_switcher_${index}`, {
                translateX: 2, translateY: 2, duration: 0.5,
                onComplete: () => {
                    gsap.to(`.quest254_switcher_${index}`, { translateX: 0, translateY: 0, duration: 0.5, });
                }
            });
            this.setState({ answer });
            this.stepsPLus();
        }

        return <div onClick={onClick} style={style} className={`quest254_switcher quest254_switcher_${index}`} />
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest254);