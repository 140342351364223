import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import Loader from 'src/ver2/components/loader';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';
import PurposerComponent from 'src/ver2/components/purpose';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import { message } from 'antd';



// import { Link } from 'react-router-dom';

import './style.css';

// class EventContactsPlashka
// classname: event_purpose_component

/**
  Это плашка в которой мы назначаем персонал. Для начала это будут только контакты
 */

class EventPurposePlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        removeMode: false
    }

    toggleRemoveMode = () => this.setState({ removeMode: !this.state.removeMode });

    onPersonClick = (Id) => {
        message.info(`Добавляем пользователя ${Id}`);
        const { addStuff } = this.props;
        addStuff({ UserId: Id, Role: 'contact' });
    }

    onRemovePersonClick = (Id) => {
        const { removeMode } = this.state;
        const { deleteStuff } = this.props;

        if (!removeMode) return;

        const { Stuff } = this.props;
        if (Stuff && Stuff.contacts) {
            for (let i = 0; i < Stuff.contacts.length; i++) {
                if (String(Stuff.contacts[i].Id) === String(Id)) {

                    if (!Stuff.contacts[i].RoleId) {
                        message.info(`Нельзя удалить единственный контакт`);
                        return;
                    }

                    message.info(`Удаляем роль ${Stuff.contacts[i].RoleId}`);
                    deleteStuff(Stuff.contacts[i].RoleId);
                    return;
                }
            }
        }
    }

    onAuthorClick = (Id) => {
        const { uploadEventMain } = this.props;
        message.info(`Смена создателя ${Id}`);
        uploadEventMain({ AuthorId: Id })
    }

    render() {

        const { isStuffReady, Stuff, Event } = this.props;
        const { removeMode } = this.state;

        const { AuthorId } = Event;

        if (!isStuffReady) return <div className='event_purpose_component'>
            <Loader text='загрузка контактов' />
        </div>

        const width = 700;

        console.log ('Создатель мероприятия');

        return <div className='event_purpose_component'>


            <div className='event_purpose_component_header'>Создатель мероприятия</div>
            <PurposerComponent mode={'contact_noclick'} onPersonClick={this.onAuthorClick} allreadyPersons={[{Id: AuthorId}]} />
            <TrefaLayout greedsX={[5, width - 10, 5]}
                components={[
                    {

                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 9090912,
                        componentProps: { height: 100, Persons: [{Id: AuthorId}], autoSize: true, mode: 'contact_noclick', onPersonClick: () => {} }

                    }
                ]}
            />

            <div className='event_purpose_component_header'>Контакты</div>
            <div className='event_purpose_component_purposer'>
                {removeMode ?
                    <>Нажмите на пользователе для удаления</>
                    : <PurposerComponent mode={'contact_noclick'} onPersonClick={this.onPersonClick} allreadyPersons={Stuff.contacts} />}
            </div>

            <TrefaLayout greedsX={[5, width - 10, 5]}
                components={[
                    {

                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 8888,
                        componentProps: { height: 100, Persons: Stuff.contacts, autoSize: true, mode: removeMode ? 'contact_noclick' : 'contact', onPersonClick: this.onRemovePersonClick }

                    }
                ]}
            />

            <br />
            <ButtonPlashka text={removeMode ? 'ВКЛЮЧИТЬ РЕЖИМ ДОБАВЛЕНИЯ' : 'ВКЛЮЧИТЬ РЕЖИМ УДАЛЕНИЯ'} upperStyle={{ width: 270, height: 50 }} onClick={this.toggleRemoveMode} />


        </div>
    }

}


export default withEventState('isStuffReady, Event, Stuff, addStuff, deleteStuff, uploadEventMain')(EventPurposePlashka)