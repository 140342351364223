import React, { Component } from 'react';

import { MobileElements } from '../elements';
import { SequenceConfirm } from '../confirm';
/*

  Тут мы непосредственно создаём связку. Сюда приходит ID раунда

*/

export class LowerRow extends Component {

  render () { 

    const { 
      our_svyazka, 
      elements, 
      client, 
      RoundId, 
      RoundTier,
      mobile, 
      sendSequence, 
      reloadPage, 
      selectCardElement, 
      cardSelectedElement, 
      rules, 
      userUsedElements,
      setOktatAndChangedPoints,
      mayDropHere,
      sequenceSelectedNumber,
      svyazka_to_show,
      UserElements,
      points
    } = this.props;

    let svyazka_is_over = true;

    for ( let i = 0; i < our_svyazka.length; i++ ) {
        if (our_svyazka[i] === null) {
            svyazka_is_over = false;
            break;
        }
    }

    return (
        svyazka_is_over 
        ?
          <SequenceConfirm {...{RoundId, client, our_svyazka, mobile, rules, sendSequence, reloadPage, setOktatAndChangedPoints, points}} />         
        :
          <MobileElements 
          cover_id = {'usual'} 
          {...{
            RoundTier, 
            rules, 
            userUsedElements, 
            elements, 
            client, 
            mobile, 
            selectCardElement, 
            cardSelectedElement,
            our_svyazka,
            setOktatAndChangedPoints,
            mayDropHere,
            sequenceSelectedNumber,
            svyazka_to_show,
            UserElements
          }}/>
    );
   }


}