import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';
import LittlePerson from 'src/ver2/components/person/little';
import { TrefaLayout } from 'src/ver2/containers/layout';
import Loader from 'src/ver2/components/loader';
import Map from 'src/ver2/pages/milagre/map';
import { Button, Icon, Select, Modal, message } from 'antd';
import ModalInvite from 'src/ver2/pages/sportland/modalinvite';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import { InfoAdder, InfoChanger } from 'src/ver2/components/trefaText/adders';
import TrefaText from 'src/ver2/components/trefaText';

import './styles.css';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

const { Option } = Select;

const DAYS = {
    1: 'ПОНЕДЕЛЬНИК',
    2: 'ВТОРНИК',
    3: 'СРЕДА',
    4: 'ЧЕТВЕРГ',
    5: 'ПЯТНИЦА',
    6: 'СУББОТА',
    7: 'ВОСКРЕСЕНЬЕ',
}

const REQUEST_CODES = {
    0: 'Не видел',
    1: 'Видел',
    2: 'Связался',
    3: 'Не смог связаться',
    4: 'Пришел',
    5: 'Не пришел'
}

class SubgroupPage extends PureComponent {

    constructor(props) {
        super();
        this.subgroupId = null;
        if (props && props.match && props.match.params && props.match.params.subgroupId) {
            this.subgroupId = props.match.params.subgroupId;
        }
    }

    state = {
        status: 'loading',
        goInvite: false,
        isShownAddDiscriptionModal: false
    }

    setModalGoInvite = (goInvite = null) => this.setState({ goInvite })
    setIsShownAddDiscriptionModal = (isShownAddDiscriptionModal = true) => this.setState({ isShownAddDiscriptionModal })

    addInvite = async ({ Name, Surname, Tel }) => {
        const { client } = this.props;
        const { subgroup } = this.state;
        const { id, groupId } = subgroup;

        client.addCRMRequest({ GroupId: groupId, SubgroupId: id, Name, Surname, Tel });
        message.success('Запись на пробную тренировку добавлена!');
        this.setModalGoInvite();
        const { subgroupId } = this;
        const requests = await client.getCRMRequests({ SubgroupId: subgroupId }) || [];
        this.setState({ requests })
    }

    componentDidMount = () => {
        if (this.props.groupsLoaded)
            this.initLoad();
    }
    componentDidUpdate({ groupsLoaded }) {
        if (!groupsLoaded && this.props.groupsLoaded) {
            this.initLoad();
        }
    }

    updateRequest = async ({ RequestId, Status, Comment, IsHide }) => {
        const { client } = this.props;
        await client.updateCRMRequest({ RequestId, Status, Comment, IsHide });
        const { subgroupId } = this;
        const requests = await client.getCRMRequests({ SubgroupId: subgroupId }) || [];
        this.setState({ requests })
    }

    // DISCRIPTION //////////////////////////////////////////
    loadDiscription = async () => {
        const { subgroupId } = this;
        const { client } = this.props;
        const Discription = await client.getDiscriptions({ subject: 'subgroup', subjectId: subgroupId, type: 'info' });
        return Discription ? Discription[0] : null;
    }

    reloadDiscription = () => {
        this.setState({ isDiscriptionReady: false });
        this.loadDiscription().then(
            (Discription) => {
                this.setState({ isDiscriptionReady: true, Discription });
            }
        )
    }

    addDiscription = async (body) => {
        const { client } = this.props;
        const { subgroupId } = this;
        await client.addDiscription({ body, subject: 'subgroup', subjectId: subgroupId, type: 'info' });
        this.reloadDiscription();
        message.success('Добавлено инфо');
    }

    updateDiscription = async (body) => {
        const { client } = this.props;
        const { Discription } = this.state;
        if (!Discription) return;
        const discriptionId = Discription.Id;
        await client.updateDiscription({ discriptionId, body });
        this.reloadDiscription();
        message.success('Изменения сохранены');
    }
    // ENDED DISCRIPTION //////////////////////////////////////////

    initLoad = async () => {
        const { client } = this.props;
        const { subgroupId } = this;
        if (!subgroupId) return null;
        let subgroup = await client.getSubgroup(subgroupId);

        if (!subgroup || !subgroup.length) {
            return this.setState({
                status: 'failed'
            })
        }


        subgroup = subgroup[0];
        const requests = await client.getCRMRequests({ SubgroupId: subgroupId }) || [];
        const shadule = await client.getShadule(subgroupId);

        const { groupId } = subgroup;
        const group = client.getGroupById(groupId);

        // const { CreatorId } = group;

        console.log({ subgroup, requests, shadule, group });

        this.reloadDiscription();

        this.setState({
            status: 'loaded',
            requests,
            shadule,
            subgroup,
            group
        })

    }

    render() {

        const { subgroupId, updateRequest } = this;
        const { isMobile, client, iamuser } = this.props;
        const { status, subgroup, shadule, requests, group = {}, goInvite, Discription,
            isShownAddDiscriptionModal } = this.state;

        if (!subgroupId) {

        }

        if (status === 'loading') {
            return <div className='subgroup_page_main'>
                <Loader text='загружается' />
            </div>
        }
        if (status === 'failed') {
            return <div className='subgroup_page_main'>
                <span>Нет такой подгруппы</span>
            </div>
        }

        const { title, groupId, adres, coords, city } = subgroup;
        const { CreatorId, SchoolId } = group;

        const places = [
            {
                id: 's1',
                name: adres,
                address: adres,
                metro: '',
                school: 'sf',
                rasp: ``
                ,
                coords: JSON.parse(`[${coords}]`)
            },
        ]

        const imgUrl = client.getGroupUrl(groupId);

        const iAmAdmin = iamuser && iamuser.Id === CreatorId;

        let components_ = [];
        // , yPadding: 'usual', _title: 'Схема'
        if (!isMobile) {

            if (iAmAdmin)
                components_.push(
                    {
                        component: ButtonPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 'mmmmm1',
                        componentProps: { height: 70, yPadding: 'usual', text: 'ОПИСАНИЕ (АДМИН)', onClick: this.setIsShownAddDiscriptionModal }
                    }
                )

            components_.push(
                {
                    component: School,
                    cell_x: 2,
                    collapse: 1,
                    id: 'm1',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', SchoolId, _title: 'Школа' }
                },
                {
                    component: Group,
                    cell_x: 2,
                    collapse: 1,
                    id: 'm2',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', groupId, _title: 'Группа' }
                },
                {
                    component: Instructor,
                    cell_x: 2,
                    collapse: 1,
                    id: 'm3',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', CreatorId, _title: 'Инструктор' }
                },
                {
                    component: Shadule,
                    cell_x: 2,
                    collapse: 1,
                    id: 'm4',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', shadule, _title: 'Расписание' }
                },
                {
                    component: SubgroupName,
                    cell_x: 3,
                    collapse: 3,
                    id: 'm5',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', title, places, imgUrl, adres, city }
                });

            if (Discription) {
                components_.push(
                    {
                        component: DiscriptionPlashka,
                        cell_x: 3,
                        collapse: 3,
                        id: 'mm5',
                        componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', Discription }
                    }
                )
            }

            components_.push(

                {
                    component: MapPlashka,
                    cell_x: 3,
                    collapse: 3,
                    id: 'mmm5',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', title, places, imgUrl, adres, city }
                },
                {
                    component: Submit,
                    cell_x: 2,
                    collapse: 4,
                    id: 'm6',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', onClick: () => { this.setModalGoInvite(true) } }
                },
                {
                    component: Table,
                    cell_x: 2,
                    collapse: 4,
                    id: 'm7',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', _title: 'Таблица записи', requests, iAmAdmin, updateRequest }
                },
            );
        } else {
            components_.push(
                {
                    component: SubgroupName,
                    cell_x: 2,
                    collapse: 2,
                    id: 'm5',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', isMobile, title, places, imgUrl, adres, city }
                });

            if (Discription) {
                components_.push(
                    {
                        component: DiscriptionPlashka,
                        cell_x: 2,
                        collapse: 2,
                        id: 'mm77',
                        componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', Discription }
                    }
                )
            }

            components_.push(
                {
                    component: MapPlashka,
                    cell_x: 1,
                    collapse: 4,
                    id: 'mmmm5',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', _title: 'Карта', title, places, imgUrl, adres, city }
                },
                {
                    component: Shadule,
                    cell_x: 1,
                    collapse: 4,
                    id: 'm4',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', isMobile, shadule, _title: 'Расписание' }
                },
                // {
                //     component: School,
                //     cell_x: 2,
                //     collapse: 1,
                //     id: 'm1',
                //     componentProps: { componentSelfHeight: true, height: 20, isMobile, yPadding: 'usual', SchoolId, _title: 'Школа' }
                // },
                // {
                //     component: Group,
                //     cell_x: 3,
                //     collapse: 1,
                //     id: 'm2',
                //     componentProps: { componentSelfHeight: true, height: 20, isMobile, yPadding: 'usual', groupId, _title: 'Группа' }
                // },
                {
                    component: Instructor,
                    cell_x: 2,
                    collapse: 2,
                    id: 'm3',
                    componentProps: { componentSelfHeight: true, height: 20, isMobile, yPadding: 'usual', CreatorId, _title: 'Инструктор' }
                },
                {
                    component: Submit,
                    cell_x: 2,
                    collapse: 3,
                    id: 'm6',
                    componentProps: { componentSelfHeight: true, height: 20, isMobile, yPadding: 'usual', onClick: () => { this.setModalGoInvite(true) } }
                },
                {
                    component: Table,
                    cell_x: 2,
                    collapse: 3,
                    id: 'm7',
                    componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', _title: 'Таблица записи', requests, iAmAdmin, updateRequest }
                },
            );
        }

        const { updateDiscription, addDiscription } = this;

        return <div className='subgroup_page_main'>
            <TrefaLayout noTransition greedsX={isMobile ? [5, (isMobile.width - 10) / 2, (isMobile.width - 10) / 2, 5] : [23, 288, 288, 288, 288, 23]}
                components={
                    components_
                }
            />
            <Modal
                footer={null}
                title={'Запись на пробную тренировку'}
                visible={goInvite}
                onCancel={() => this.setModalGoInvite()}
            >
                {(goInvite) && <ModalInvite addInvite={this.addInvite} />}
            </Modal>
            <Modal
                width={1200}
                footer={null}
                title={'Добавить описание'}
                visible={isShownAddDiscriptionModal}
                onCancel={() => this.setIsShownAddDiscriptionModal(false)}
            >
                {(isShownAddDiscriptionModal) &&
                    <div className='subgroup_page_main_discription_add'>
                        {Discription ?
                            <InfoChanger text={Discription.Body} onCallback={updateDiscription} /> :
                            <InfoAdder onCallback={addDiscription} />
                        }
                    </div>
                }
            </Modal>
        </div>
    }

    ShaduleBlock = () => <div className='stat_crm_page_subgroup_shadule'>
        {(this.state.shadule && this.state.shadule.map(({ day, timeStart, timeEnd }) => {
            return <div className='stat_crm_page_subgroup_shadule_one'>
                <span>{DAYS[day]}</span>
                <span>{`${timeStart} - ${timeEnd}`}</span>
            </div>
        })) || null}
    </div>
}

const School = ({ upperStyle: { width, height }, innerId, SchoolId, isMobile }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner' id={innerId}>
            <SchoolPlate width={isMobile ? 120 : 300} height={isMobile ? 40 : 60} SchoolId={SchoolId} linkTo={null} />
        </div>
    </div>

const Group = ({ upperStyle: { width, height }, innerId, groupId, isMobile }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner' id={innerId}>
            <GroupPlate width={isMobile ? 120 : 300} height={isMobile ? 40 : 60} GroupId={groupId} />
        </div>
    </div>

const Instructor = ({ upperStyle: { width, height }, innerId, CreatorId, isMobile }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner' id={innerId}>
            <LittlePerson personId={CreatorId} height={70} mode='contact' />
        </div>
    </div>

const DiscriptionPlashka = ({ upperStyle: { width, height }, innerId, Discription }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner' id={innerId}>
            <div className='subgroup_page_main_discription'>
                <TrefaText trefaText={Discription.Body} />
            </div>
        </div>
    </div>

const SubgroupName = ({ upperStyle: { width, height }, innerId, title, city, adres }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner' id={innerId}>
            <h1>Набор в группу капоэйра</h1>
            <h1>{title}</h1>
            <h2>{`${city}, ${adres}`}</h2>
        </div>
    </div>

const MapPlashka = ({ upperStyle: { width, height }, innerId, isMobile, places }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner' id={innerId}>
            <Map isMobile={isMobile} places={places} imgUrl={'none'} center={places[0].coords} />
        </div>
    </div>

const Shadule = ({ upperStyle: { width, height }, innerId, shadule, isMobile }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner' id={innerId}>
            <div className={isMobile ? 'subgroup_page_shadule_mobile' : 'subgroup_page_shadule'}>
                {(shadule && shadule.map(({ day, timeStart, timeEnd }) => {
                    return <div className='subgroup_page_shadule_one'>
                        <span>{DAYS[day]}</span>
                        <span>{`${timeStart} - ${timeEnd}`}</span>
                    </div>
                })) || null}
            </div>
        </div>
    </div>

const Submit = ({ upperStyle: { width, height }, innerId, onClick, isMobile }) =>
    <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner ' id={innerId}>
            <div className={isMobile ? 'subgroup_plashka_submit_main_mobile' : 'subgroup_plashka_submit_main'}>
                <Button onClick={onClick} className='subgroup_plashka_submit_main_button' class='subgroup_plashka_submit_main_button' size='large' type="danger">ЗАПИСАТЬСЯ</Button>
                <span>
                    Ваши контактные данные попадут только инструктору группы, который свяжется с Вами и обсудит все нюансы по Вашей первой тренировке :)
                </span>
            </div>
        </div>
    </div>

const Table = ({ upperStyle: { width, height }, innerId, requests = [], iAmAdmin, updateRequest }) => {
    return <div style={{ width, height }} className='subgroup_plashka'>
        <div className='combo_scheme_plashka_inner ' id={innerId}>
            <div className='subgroup_plashka_table'>
                {requests.map(request => {

                    const {
                        Id, Name, Surname, Tel, TimeStamp, Status, IsHide
                    } = request;

                    if (!iAmAdmin && IsHide !== 1) return null;

                    return <div key={`sptr_${Id}`} className='subgroup_plashka_table_row'>
                        <div className='subgroup_plashka_table_row_name'>
                            {`${Surname} ${Name}`}
                        </div>
                        {iAmAdmin && <div className='subgroup_plashka_table_row_tel'>
                            {`${Tel}`}
                        </div>}
                        {iAmAdmin && <div className='subgroup_plashka_table_row_status'>
                            <Select style={{ width: 150 }} value={Status} onChange={(value) => {
                                updateRequest({ RequestId: Id, Status: value });
                            }}>
                                {Object.keys(REQUEST_CODES).map(v => {
                                    const num = Number(v)
                                    return <Option key={num} value={num}>{REQUEST_CODES[num]}</Option>
                                })}
                            </Select>
                        </div>}
                        <div className='subgroup_plashka_table_row_timestamp'>
                            {`${moment(TimeStamp).locale('ru').format('DD.MM.YYYY HH:mm')}`}
                        </div>
                        {iAmAdmin && <div className='subgroup_plashka_table_row_eye'>
                            <Icon type={IsHide === 1 ? "eye" : "eye-invisible"} onClick={() => { updateRequest({ RequestId: Id, IsHide: (IsHide === 1) ? 2 : 1 }) }} />
                        </div>}
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default withCapoeiraSportState('iamuser, client, groupsLoaded, isMobile, doShowFooter')(SubgroupPage);