import { createContext } from 'react';
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { groupBy, orderBy } from 'lodash';
import { message } from 'antd';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

const context = createContext(
    {
        defVal: 'FUUUCK YEAH'
    }
);

/*

  Это контекст, в который приходит shopId, он его подгружает и всё выдает наследникам контекста
  в пропсы ожидает shopId, client, iamuser

*/


const initialState = {
    Shop: null, // это рассматриваемый в данный момент магазин
    isShopReady: false,

    Filter: {
        filterType: null, filterSubtype: null, gradeType: null, onlyForMe: false
    },

    ShopItems: null,
    ShopFilter: null,
    isShopItemsReady: false,


    RealShopItems: null,
    RealShopArticules: null,
    RealShopArticulesByItems: null,

    RealShopArrivals: null,
    RealShopOrders: null,
    RealShopOrdersAll: null,

    ShopBuyers: null,
    isShopBuyersReady: false,

    ShopMoney: null,
    isShopMoneyReady: false,

    Discription: null,
    isDiscriptionReady: false,

    PaymentDiscription: null,

    ServerQuests: null,
    isServerQuestsReady: false,

    NewNews: null,
    isNewNewsReady: false,

    currentOrder: [],

    iAmAdmin: null, // { role }
}

class ShopState extends PureComponent {

    constructor(props) {
        super(props);

        this.state = initialState;

        if (props.client) {
            this.client = props.client;
        }

    }

    initialLoad = async () => {
        const Shop = await this.loadShop();
        console.log('Пробую подгрузить магазин')
        if (!Shop) return this.setState({ isShopReady: false });

        this.setState({ isShopReady: true, Shop });

        this.reloadDiscription();
        this.loadPaymentDiscription();
        this.checkIfAmAdminAsync();
        this.loadShopItems();
        this.loadShopMoney();
        this.loadShopBuyers();
        this.reloadServerQuests();
        // this.reloadNewNews();
    }

    get isReal() {
        const { Shop } = this.state;
        if (!Shop || !Shop.Type) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        this.initialLoad();
    }

    componentDidUpdate({ iamuser, match }, { isShopReady, isShopItemsReady }) {
        if (match.params.shopId !== this.props.match.params.shopId) {
            this.reloadShop().then(() => {
                this.initialLoad();
                this.checkIfAmAdminAsync();
            })
        }

        if (this.props.iamuser !== iamuser) {
            this.checkIfAmAdminAsync();
        }

        if (isShopReady !== this.state.isShopReady) {
            this.loadShopItems();
            this.loadShopBuyers();
            this.reloadServerQuests();
        }

        if (isShopItemsReady !== this.state.isShopMoneyReady) {
            this.loadShopMoney();
        }

    }


    reloadShop = async () => {
        this.setState(initialState);
    }


    loadShop = async () => {
        const { client } = this.props;
        const shopId = this.props.match.params.shopId;
        const Shop = await client.getShop(shopId);

        if (!Shop) return null;

        const { Settings } = Shop;
        if (!Settings) return Shop;

        const SettingsObj = JSON.parse(Settings);

        let SettingsSales = [];
        if (SettingsObj.RealThemes) {
            const themes = Object.values(SettingsObj.RealThemes);
            themes.forEach(({ sales }) => {
                if (sales && sales[0]) {
                    SettingsSales.push(...sales);
                }
            });
        }

        return { ...Shop, SettingsObj, SettingsSales };
    }

    // ADMIN /////////

    checkIfAmAdminAsync = () => {
        this.checkIfAmAdmin().then((iAmAdmin) => { this.setState({ iAmAdmin }) })
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать роли
    checkIfAmAdmin = async () => {
        const { iamuser, client } = this.props;
        const { Shop } = this.state;

        let result = null;

        if (!iamuser || !Shop) return result;

        if (iamuser.myPortalRoles && (iamuser.myPortalRoles.creator)) {
            result = { role: 'admin' };
        }

        if (Shop.CreatorId === iamuser.Id) result = { role: 'admin' };

        if (result && result.role === 'admin') {
            await this.reloadRealShopArrivals();
            await this.reloadRealShopOrders();
        } else {
            await this.reloadRealShopOrders( true );
        }

        return result;
    }

    // ARRIVALS
    reloadRealShopArrivals = async () => {
        const { iamuser, client } = this.props;
        const { Shop } = this.state;
        if (!iamuser || !Shop) return;

        const RealShopArrivals = await client.realshopArrivals(Shop.Id);
        this.setState({ RealShopArrivals })
    }

    // ORDERS
    reloadRealShopOrders = async ( justFormMe = false ) => {
        const { iamuser, client } = this.props;
        const { Shop } = this.state;
        if (!iamuser || !Shop) return;

        // СДЕЛАТЬ ЗАГРУЗКУ ТОЛЬКО СВОИХ
        let RealShopOrdersAll = await client.realshopOrders(Shop.Id) || [];
        if (justFormMe) {
            let RealShopOrdersAll_timed = [];
            for ( let i = 0; i < RealShopOrdersAll.length; i++ ) {
                if (RealShopOrdersAll[i].UserId === iamuser.Id ) {
                    RealShopOrdersAll_timed.push ( {...RealShopOrdersAll[i]});
                }
            }
            RealShopOrdersAll = [...RealShopOrdersAll_timed]
        }


        const RealShopOrdersInnerCodes = groupBy ( orderBy( RealShopOrdersAll, 'Id' ), 'Code' );

        let RealShopOrders = [];

        Object.values( RealShopOrdersInnerCodes ).forEach ( (ordersArray) => {
            const { Id, Code, Payment, Status, CreationStamp, UserId, UserName, UserSurname, Tel, GroupIamInId } = ordersArray[0];
            const TimeStamp = moment(CreationStamp).subtract(3, 'hours').format('DD.MM.YYYY HH:mm');
            RealShopOrders.push ({
                Code,
                Payment,
                Status,
                CreationStamp,
                TimeStamp,
                HighId: Id,
                UserId,
                UserName, 
                UserSurname,
                Tel,
                GroupIamInId,
                Orders: [
                    ...ordersArray
                ]
            })
        } )

        RealShopOrders = orderBy( RealShopOrders, 'HighId', 'desc' );
        this.setState({ RealShopOrders, RealShopOrdersAll })
    }

    // SHOPS ITEM //////////////////////////////////////////
    loadShopItems = async () => {
        const { Shop } = this.state;
        if (!Shop) return;
        const { client } = this.props;


        if (this.isReal) {
            const RealShopItems = await client.realshopItems(Shop.Id);
            const RealShopArticules = await client.realshopArticules(Shop.Id);
            const RealShopArticulesByItems = groupBy(RealShopArticules, 'RealItemId');
            return this.setState({ isShopItemsReady: true, RealShopItems, RealShopArticules, RealShopArticulesByItems });
        }

        const ShopItems_ = await client.getShopItems(Shop.Id);
        let ShopItems = [];

        /*
            // структура
            'berimbao' : {'beribma': true } }
        */

        let ShopFilter = {

        }

        // определяем тут типы и субтипы
        for (let i = 0; i < ShopItems_.length; i++) {
            const { Cost, Name } = ShopItems_[i];
            const CostObj = JSON.parse(Cost);
            ShopItems.push({ ...ShopItems_[i], CostObj });

            const item = client.getAbstractItem(Name);
            const { type, subtype, grade } = item;
            if (!ShopFilter[type]) ShopFilter[type] = {};
            if (!ShopFilter[type][subtype]) ShopFilter[type][subtype] = true;
        }

        return this.setState({ isShopItemsReady: true, ShopItems, ShopFilter });
    }

    // SHOPS MONEY //////////////////////////////////////////
    loadShopMoney = async () => {
        const { ShopItems, isShopItemsReady } = this.state;

        if (this.isReal) {

            return;
        }

        if (!isShopItemsReady || !ShopItems) return;

        let ShopMoney_ = {};
        for (let i = 0; i < ShopItems.length; i++) {
            const { CostObj } = ShopItems[i];
            const CostObjKeys = Object.keys(CostObj);

            CostObjKeys.map(moneyKey => { if (ShopMoney_[moneyKey] = true) return 1; })
        }

        const ShopMoney = Object.keys(ShopMoney_);

        return this.setState({ isShopMoneyReady: true, ShopMoney });
    }

    //  BUYERS //////////////////////////////////////////
    loadShopBuyers = async () => {
        const { Shop } = this.state;
        if (!Shop) return;

        if (this.isReal) {

            return;
        }

        const { client } = this.props;
        const ShopBuyers = await client.getOperationsByShop(Shop.Id);
        return this.setState({ isShopBuyersReady: true, ShopBuyers });
    }

    // SERVER QUESTS (Это просто те квесты, которые приходят с сервера для person) /////
    loadServerQuests = async () => {
        const { client } = this.props;
        const { Shop } = this.state;
        if (!Shop) return;
        const ServerQuests = await client.getQuests({ PlaceType: 'shop', PlaceId: Shop.Id });
        return ServerQuests;
    }

    reloadServerQuests = () => {
        this.setState({ isServerQuestsReady: false });
        this.loadServerQuests().then(
            (ServerQuests) => {
                this.setState({ isServerQuestsReady: true, ServerQuests });
            }
        )
    }

    // DISCRIPTION PAYMENT //////////////////////////////////////////
    loadPaymentDiscription = async () => {
        const { Shop } = this.state;
        if (!Shop) return;
        const { client } = this.props;
        const PaymentDiscription = await client.getDiscriptions({ subject: 'shop', subjectId: Shop.Id, type: 'payment' });
        this.setState({ PaymentDiscription: (PaymentDiscription ? PaymentDiscription[0] : '')  })
    }


    // DISCRIPTION //////////////////////////////////////////
    loadDiscription = async () => {
        const { Shop } = this.state;
        if (!Shop) return;
        const { client } = this.props;
        const Discription = await client.getDiscriptions({ subject: 'shop', subjectId: Shop.Id, type: 'info' });
        return Discription ? Discription[0] : null;
    }

    reloadDiscription = () => {
        this.setState({ isDiscriptionReady: false });
        this.loadDiscription().then(
            (Discription) => {
                this.setState({ isDiscriptionReady: true, Discription });
            }
        )
    }

    addDiscription = async (body) => {
        const { client } = this.props;
        const { Shop } = this.state;
        await client.addDiscription({ body, subject: 'shop', subjectId: Shop.Id, type: 'info' });
        this.reloadDiscription();
        message.success('Добавлено инфо');
    }

    updateDiscription = async (body) => {
        const { client } = this.props;
        const { Discription } = this.state;
        if (!Discription) return;
        const discriptionId = Discription.Id;
        await client.updateDiscription({ discriptionId, body });
        this.reloadDiscription();
        message.success('Изменения сохранены');
    }

    buyShopItems = async (ShopItemId) => {
        const { client, renewResourses, iamuser } = this.props;
        const { ShopItems } = this.state;

        if (!iamuser) return;

        // надо проверить, что там по количеству
        for (let i = 0; i < ShopItems.length; i++) {
            const { Id, Count, Name } = ShopItems[i];
            if (Id === ShopItemId) {

                if (!Count) {
                    const answer = await client.buyShopItems(ShopItemId);
                    message.success('Предмет куплен');
                    renewResourses();
                    return;
                } else {
                    // проверяем количество таким предметов у пользоваеля
                    const ItemsUser = await client.getAllUserItems(iamuser.Id, Name);
                    if (!ItemsUser || ItemsUser.length < Count) {
                        const answer = await client.buyShopItems(ShopItemId);
                        message.success('Предмет куплен');
                        renewResourses();
                        return;
                    }
                    message.error('У вас слишком много таких предметов');
                    return;
                }


            }
        }

        message.error('Ошибка при покупке');
    }

    exchangeMoney = async (Amount) => {
        const { client, renewResourses } = this.props;
        const { Shop: { Id, SettingsObj: { Exchange: { MoneyFrom } } } } = this.state;
        await client.exchangeMoney({ ShopId: Id, MoneyFrom, Amount });

        message.success('Обмен произведён');
        renewResourses();
    }

    addRealItem = async ({ Title, Type, Cost, ArticuleTitle }) => {

        const { Shop } = this.state;
        if (!Shop) return;
        const { client } = this.props;
        const { Id } = Shop;

        const { iamuser } = this.props;

        let iamadmin = null;

        if (!iamuser) return message.error('Вы не админ');

        if (iamuser.myPortalRoles && (iamuser.myPortalRoles.creator)) {
            iamadmin = true;
        }

        if (Shop.CreatorId === iamuser.Id) iamadmin = true;

        if (!iamadmin) return message.error('Вы не админ');

        await client.addRealshopItem({ ShopId: Id, Title, Type, Cost, ArticuleTitle })
        await this.loadShopItems();
    }

    addRealArrival = async ({ RealArticuleId, Count }) => {

        const { client } = this.props;
        const { iAmAdmin } = this.state;

        if (!iAmAdmin) return message.error('Вы не админ');

        await client.addRealshopArrival({ RealArticuleId, Count })
        await this.reloadRealShopArrivals();
        await this.loadShopItems();
    }

    // { RealOrderId, Status ?, Payment ?}
    // subData {UserId, orderTitle }
    changeOrderStatus = async (data, subData) => {
        const { client } = this.props;
        const { iAmAdmin } = this.state;

        if (!iAmAdmin) return message.error('Вы не админ');

        if (!data.Payment && subData && subData.UserId)
        client.addNotification({
            UserId: subData.UserId,
            Body: `^a_^text^a_^Изменён статус заказа на: ${subData.orderTitle}^n_^<a href='https://capoeirasport.com/shop/11/?page=orders'>ВАШИ ЗАКАЗЫ</a>`
        })
        
        await client.updateRealshopOrder(data);
        await this.reloadRealShopArrivals();
        await this.reloadRealShopOrders();
        await this.loadShopItems();
    }

    addToOrder = ({ RealArticuleId, Count = 1 }) => {
        let currentOrder = [...this.state.currentOrder];
        message.info('Добавлено в корзину');
        for (let i = 0; i < currentOrder.length; i++) {
            if (currentOrder[i].RealArticuleId === RealArticuleId) {
                currentOrder[i].Count += Count;
                this.setState({ currentOrder });
                return;
            }
        }

        currentOrder.push({ RealArticuleId, Count })
        this.setState({ currentOrder });
    }

    removeFromOrder = (index) => {
        const { currentOrder } = this.state;
        let currentOrder_ = [];
        for (let i = 0; i < currentOrder.length; i++) {
            if (i !== index) currentOrder_.push({ ...currentOrder[i] })
        }
        this.setState({ currentOrder: currentOrder_ });
    }

    makeAnOrder = async () => {
        const { client } = this.props;
        const { currentOrder } = this.state;

        const helperRandomString = (i) => {
            let rnd = '';
            while (rnd.length < i)
                rnd += Math.random().toString(36).substring(2);
            return rnd.substring(0, i);
        };

        const InnerCode = helperRandomString(7);
        let allOk = true;

        for (let i = 0; i < currentOrder.length; i++) {
            const { RealArticuleId, Count } = currentOrder[i];
            const result = await client.addRealshopOrder({ RealArticuleId, Count, InnerCode });
            if (result && result.Message) {
            } else {
                allOk = false;
            }
        }

        if (allOk) {
            message.info('Создан заказ');
        } else {
            message.error('Что-то пошло не так');
        }

        await this.reloadRealShopArrivals();
        await this.reloadRealShopOrders();
        await this.loadShopItems();

        this.setState({ currentOrder: [] });
    }

    redirect = (link) => {
        this.props.history.push(link);
    }

    setFilter = ({ key, value }) => {
        if (key === 'filterType') {
            return this.setState({
                Filter: { ...this.state.Filter, filterType: value, filterSubtype: null }
            })
        }
        return this.setState({
            Filter: { ...this.state.Filter, [key]: value }
        })
    }

    statusData = (status) => {
        if (status === 1) return { title: 'создан', style: { background: 'gray', color: 'white' } };
        if (status === 2) return { title: 'возвращен', style: { background: 'black', color: 'white' } };
        if (status === 3) return { title: 'в работе', style: { background: 'orange', color: 'white' } };
        if (status === 4) return { title: 'выдача', style: { background: 'green', color: 'black' } };
        if (status === 5) return { title: 'выдан', style: { background: 'rgb(0, 47, 75)', color: 'white' } };


        return { title: 'ошибка', style: { background: 'red', color: 'white' } };
    }

    render() {
        const {
            props: {
                children,
                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                renewResourses,
                isMobile
            },
            state: {
                Shop,
                isShopReady,

                Filter,

                ShopItems,
                ShopFilter,
                isShopItemsReady,

                RealShopItems,
                RealShopArticules,
                RealShopArticulesByItems,
                RealShopArrivals,
                RealShopOrders,
                RealShopOrdersAll,

                ShopMoney,
                isShopMoneyReady,

                ShopBuyers,
                isShopBuyersReady,

                ServerQuests,
                isServerQuestsReady,

                currentOrder,

                Discription,
                isDiscriptionReady,

                PaymentDiscription,

                NewNews,
                isNewNewsReady,
                iAmAdmin
            },
            redirect,
            addDiscription,
            updateDiscription,
            uploadEventMain,
            buyShopItems,
            exchangeMoney,
            reloadServerQuests,
            setFilter,

            statusData,

            addRealItem,
            addRealArrival,
            changeOrderStatus,

            makeAnOrder,
            addToOrder,
            removeFromOrder

        } = this;

        return (
            <context.Provider value={{
                Shop,
                isShopReady,

                Filter,

                ShopItems,
                ShopFilter,
                isShopItemsReady,

                RealShopItems,
                RealShopArticules,
                RealShopArticulesByItems,
                RealShopArrivals,
                RealShopOrders,
                RealShopOrdersAll,

                ShopMoney,
                isShopMoneyReady,

                ShopBuyers,
                isShopBuyersReady,

                Discription,
                isDiscriptionReady,
                PaymentDiscription,
                NewNews,
                isNewNewsReady,
                iAmAdmin,

                redirect,
                addDiscription,
                updateDiscription,
                uploadEventMain,
                buyShopItems,
                exchangeMoney,
                setFilter,

                addRealItem,
                addRealArrival,
                changeOrderStatus,

                makeAnOrder,
                addToOrder,
                removeFromOrder,

                currentOrder,

                statusData,

                renewResourses,

                reloadServerQuests,
                ServerQuests,
                isServerQuestsReady,

                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                isMobile
            }}>
                {children}
            </context.Provider>
        )

    }
}

export default withCapoeiraSportState('iamuser, client, isMobile, showInfoModal, closeInfoModal, renewResourses')(ShopState);


export const withShopState = (withProps = '') => {
    return (
        (Wrapped) =>
            (props) => (
                <context.Consumer>
                    {
                        (context) => (
                            <Wrapped
                                {...props}
                                {...getWhatINeed(withProps, context)}
                            />
                        )
                    }
                </context.Consumer>
            )
    );
};

const getWhatINeed = (withProps, context) => {

    if (withProps === '') return context;

    return withProps.split(',').reduce((memo, value_) => {
        const value = value_.trim();
        if (!context[value]) {
            return memo;
        }
        return { ...memo, [value]: context[value] }
    }, {})


}