
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import Shine3D from 'src/ver2/components/3d/shine';

// import { Tooltip } from 'antd';
import EventAvatar from 'src/ver2/components/event/avatar';

import './style.css';

/* 

    // заводим сюда
    width (это всегда квадрат)
    name 
    uid (чтобы дать индивидуальность)

    withType (иконка снизу)
    withSubtype (ионнка снизу)

    Item, чтобы была возможность считать Modyfied

*/

class ItemOnWallComponent extends PureComponent {

    render() {

        const { width, height, srcUsed, client, Item = {} } = this.props;

        const { name } = Item

        const item = client.getAbstractItem(name);
        const { subtype, place } = item;

        const srcUsed_ = srcUsed || item.srcUsed;

        let EventComponent = null;
        const { ModyfiedObj } = Item || {};

        // смотрим чё к нам такое пришло
        if (ModyfiedObj) {
            if (ModyfiedObj.EventId) {
                if (subtype === 'medal_shalve') {

                    //если это штука медальная с EventId - мы смотрим, какой тип и исходя из него 
                    // добавляем сияние, или нет, и выбираем left и топ

                    let isShine = false;
                    let left = width * 0.476 - width * 0.5 / 2;
                    let top = width * 0.12;
                    let eventWidth = 0.6;

                    if ( ['','medal_bbb1', 'medal_bbb2', 'medal_bbb3'].indexOf(name) > 0 ) {
                        left = width * 0.521 - width * 0.5 / 2;
                        top = width * 0.15;
                    }

                    if ( ['','medal_bb1', 'medal_bb2', 'medal_bb3'].indexOf(name) > 0 ) {
                        left = width * 0.521 - width * 0.5 / 2;
                        top = width * 0.15;
                    }

                    if ( ['','medal_an1', 'medal_an2', 'medal_an3'].indexOf(name) > 0 ) {
                        left = width * 0.521 - width * 0.5 / 2;
                        top = width * 0.15;
                        eventWidth = 0.47;
                    }

                    if ( ['','medal_l1', 'medal_l2', 'medal_l3'].indexOf(name) > 0 ) {
                        left = width * 0.432 - width * 0.5 / 2;
                        top = width * 0.45;
                    }

                    if ( ['','medal_ll1', 'medal_ll2', 'medal_ll3'].indexOf(name) > 0 ) {
                        left = width * 0.462 - width * 0.5 / 2;
                        top = width * 0.115;
                    }

                    if (['', 'medal_bbbb1', 'medal_bbbb2', 'medal_bbbb3'].indexOf(name) > 0) {
                        eventWidth = 0.75;
                        left = width * 0.362 - width * 0.5 / 2;
                        top = width * 0.24;
                    }

                    if (['', 'medal_bbbbcr1', 'medal_bbbbcr2', 'medal_bbbbcr3'].indexOf(name) > 0) {
                        eventWidth = 0.75;
                        left = width * 0.362 - width * 0.5 / 2;
                        top = width * 0.24;
                    }
                    
                    if ( ['','medal_cr1', 'medal_cr2', 'medal_cr3'].indexOf(name) > 0 ) {
                        isShine = true;
                    }

                    if ( ['','medal_r1', 'medal_r2', 'medal_r3'].indexOf(name) > 0 ) {
                        eventWidth = 0;
                    }

                    if ( [0, 2, 101, 147].indexOf(ModyfiedObj.EventId) > 0 ) {
                        isShine = true;
                    }

                    const style = {
                        left,
                        top
                    }
                    EventComponent = <>
                        <div style={style} className='item_onwall_components_event'>
                            {(eventWidth > 0) && <EventAvatar size={width * eventWidth} eventId={ModyfiedObj.EventId} />}
                        </div>
                        { isShine && <div className='item_onwall_components_event_shine'>
                            <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={`nagr-${name}-${ModyfiedObj.EventId}`} Place={place} />
                        </div> }
                    </>

                    // исключения
                    if ( ['','medal_bbbbcr1', 'medal_bbbbcr2', 'medal_bbbbcr3'].indexOf(name) > 0 ) {
                        EventComponent = <>
                        <div style={style} className='item_onwall_components_event'>
                            <EventAvatar size={width * eventWidth} eventId={ModyfiedObj.EventId} />
                        </div>
                        { isShine && <div style={{ left: -0.25 * width, top: -0.18 * width }} className='item_onwall_components_event_shine'>
                            <Shine3D drawWidth={width * 1.5} drawHeight={width * 1.5} Id={`nagr-${name}-${ModyfiedObj.EventId}`} Place={place} />
                        </div> }
                    </>
                    }

                }
            }
        }

        if (name === 'kubok_2019_b1' || name === 'kubok_2021_b1' || name === 'kubok_2022_b1') {
            EventComponent = <>
                <div style={{ left: -0.15 * width }} className='item_onwall_components_event_shine'>
                    <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={700 + '_' + name} Place={1} />
                </div>
            </>
        }

        if (name === 'kubok_2019_b2' || name === 'kubok_2021_b2' || name === 'kubok_2022_b2') {
            EventComponent = <>
                <div style={{ left: -0.15 * width }} className='item_onwall_components_event_shine'>
                    <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={701+ '_' + name} Place={2} />
                </div>
            </>
        }

        if (name === 'kubok_2019_b3' || name === 'kubok_2021_b3' || name === 'kubok_2022_b3') {
            EventComponent = <>
                <div style={{ left: -0.15 * width }} className='item_onwall_components_event_shine'>
                    <Shine3D drawWidth={width * 1.3} drawHeight={width * 1.3} Id={702+ '_' + name} Place={3} />
                </div>
            </>
        }
    
        if (name === 'plant_donate_1') {
            EventComponent = <>
                <div style={{ left: 0, top: -0.15 * width, zIndex: -1 }} className='item_onwall_components_event_shine'>
                    <Shine3D drawWidth={width} drawHeight={width} Id={12} Place={4} />
                </div>
            </>
        }

        if (name === 'toy_donate_5') {
            EventComponent = <>
                <div style={{ left: -0.15 * width, top: -0.15 * width }} className='item_onwall_components_event_shine'>
                    <Shine3D drawWidth={width*1.3} drawHeight={width*1.3} Id={13} Place={4} />
                </div>
            </>   
        }


        return <div style={{ width, height }} className='item_onwall_components'>
            <img src={srcUsed_} draggable="false" />
            {EventComponent}
        </div>
    }

}

export default withCapoeiraSportState('client')(ItemOnWallComponent);