import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import TrefaText from 'src/ver2/components/trefaText';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventInfoPlashka
// classname: event_info_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventInfoPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        if (this.props.Event && this.props.Event.Id) {
            this.loadEventHTMLtext(this.props.Event.Id);
        }
    }

    componentDidUpdate({ Event: OldEvent, isDiscriptionReady }) {
        const { Event } = this.props;
        if ((!OldEvent && Event) || (OldEvent && Event && OldEvent.Id !== Event.Id) || (isDiscriptionReady !== this.props.isDiscriptionReady)) {
            this.loadEventHTMLtext(Event.Id);
        }
    }


    loadEventHTMLtext = async (eventId) => {
        const { client, isDiscriptionReady, Discription } = this.props;

        // нам сначала надо понять, подгрузилось ли описание
        if (!isDiscriptionReady) return;

        if (Discription) {
            // const { setComponentSelfHeight, plashkaId } = this.props;
            // setComponentSelfHeight({ height: 500, plashkaId });
            return;
        }

        const HTMLtext = await client.getEventHTMLText(eventId)

        const element = document.getElementById('event_info_plashka_id');

        if (element)
            element.innerHTML = HTMLtext || 'Информация еще не задана';


        const element_ = document.getElementById('event_info_plashka_id');
        if (!element_) return;
        const height = element_.clientHeight;

        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    }

    onTrefaTextRender = (heigth) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: heigth + 100, plashkaId });
    }

    render() {

        const { Event, upperStyle, Discription } = this.props;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_info_plashka'>
            <Loader />
            <div id='event_info_plashka_id' />
        </div>

        if (Discription)
            return <div style={{ width, height }} className='event_info_plashka'>
                <div className='event_info_plashka_discription'>
                    <TrefaText trefaText={Discription.Body} onRender={this.onTrefaTextRender} />
                </div>
            </div>

        return <div style={{ width, height }} className='event_info_plashka'>
            <div id='event_info_plashka_id' />
        </div>
    }

}

export default withEventState(`
Event,  
client,
Discription,
isDiscriptionReady
`)(EventInfoPlashka);