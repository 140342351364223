import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import { groupBy } from 'lodash';
import LittlePerson from 'src/ver2/components/person/little';
import { DISCIPLINE_SMILES } from '../GroupCRM/components/disciplineSmiles';
import { Button } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';

import './style.css';


class PersonCRM extends PureComponent {

    /*
        1 - Загружаем самого чувака.
        2 - Если такой есть - мы смотрим, ходит ли это чувак куда-нибудь
    */

    state = {
        personCRMData: null,
        status: 'loading',
        thisIsIam: false,
        subgroupsDays: {},
        inviteStudents: [],
        inviteSubgroup: null
    }

    componentDidMount = () => {
        const { personId } = this.props;

        if (!personId) return;
        if (personId[0] === 'i') {
            return this.loadInvite();
        }

        this.loadPersonCRMDate();
    }

    componentDidUpdate({ iamuser }) {
        const { personId } = this.props;
        if (!personId) return;
        if (!iamuser && this.props.iamuser && personId[0] === 'i') {
            return this.loadInvite();
        }
    }

    loadInvite = async () => {

        this.setState({ status: 'loading' })

        const { iamuser, client, personId } = this.props;
        if (!iamuser || !iamuser.Id) return;

        const inviteSubgroupId = personId.slice(6);
        const inviteSubgroup = await client.getSubgroup(inviteSubgroupId);

        if (!inviteSubgroup || !inviteSubgroup[0]) return null;

        const inviteStudents = await client.getSubgroupUsers(inviteSubgroupId) || [];

        this.setState({ status: 'invite', inviteStudents, inviteSubgroup: inviteSubgroup[0] })
    }

    onInviteInSubgroup = async () => {
        this.setState({ status: 'loading' })
        const { inviteSubgroup } = this.state;
        const { iamuser, client } = this.props;
        await client.addSubgroupUser(iamuser.Id, inviteSubgroup.id);
        this.loadInvite();
    }

    loadPersonCRMDate = async () => {
        const { client, personId } = this.props;

        const person = await client.getUserFromServer(personId, {});

        if (!person || !person.Id) {
            return this.setState({ status: 'noperson' });
        }

        const personCRMData = await client.getCRMPersonStat(personId);
        if (personCRMData && personCRMData.subgroups && personCRMData.subgroupsVisits) {
            // загружаем подгруппы
            let subgroups = {};
            for (let i = 0; i < personCRMData.subgroups.length; i++) {
                const { SubgroupId } = personCRMData.subgroups[i]
                const subgroup = await client.getSubgroup(SubgroupId);
                if (subgroup && subgroup[0]) {
                    subgroups[SubgroupId] = { ...subgroup[0] };
                }
            }

            // теперь будем форматировать данные
            let subgroupsDays = groupBy(personCRMData.subgroupsVisits, 'DataKey');

            for (let i = 0; i < Object.keys(subgroupsDays).length; i++) {
                subgroupsDays[Object.keys(subgroupsDays)[i]] = groupBy(subgroupsDays[Object.keys(subgroupsDays)[i]], 'SubgroupId');
            }

            let visitsDays = groupBy(personCRMData.visits, 'DataKey');
            for (let i = 0; i < Object.keys(visitsDays).length; i++) {
                visitsDays[Object.keys(visitsDays)[i]] = groupBy(visitsDays[Object.keys(visitsDays)[i]], 'SubgroupId');
            }

            this.setState({ ...personCRMData, subgroupsDays, visitsDays, subgroups });
            this.setState({ status: 'loaded' });
        } else {
            return this.setState({ status: 'novisits' });
        }
    }

    render() {
        const { personId, isMobile } = this.props;
        const { status, visitsDays, visits } = this.state;

        if (status === 'loading') {
            return <div className='person_crm_main'>
                <Loader text='формируется статистика' />
            </div>
        }
        if (status === 'noperson') {
            return <div className='person_crm_main'>
                <div className='person_crm_main_allblock'>
                    ТАКОГО ПОЛЬЗОВАТЕЛЯ НЕ СУЩЕСТВУЕТ
                </div>
            </div>
        }
        if (status === 'novisits') {
            return <div className='person_crm_main'>
                <div className='person_crm_main_allblock'>
                    ВЫ НЕ ЗАРЕГИСТРИРОВАНЫ В ПОДГРУППЫ <br />
                    ИЛИ У ВАС НЕ ВЫСТАВЛЕНЫ ПОСЕЩЕНИЯ
                </div>
            </div>
        }
        if (status === 'invite') {
            return <div className='person_crm_main'>
                <div className='person_crm_main_allblock'>
                    <this.Invite />
                </div>
            </div>
        }

        console.log('loadPersonCRMDate', this.state)

        const personHeight = isMobile ? 100 : 150;

        const subgroupsDaysArray = Object.keys(visitsDays).reverse();
        const visitsByDataKey = groupBy(visits, 'DataKey');

        return <div className='person_crm_main'>
            <div className='person_crm_main_person'>
                <LittlePerson personId={personId} height={personHeight} />
            </div>
            {subgroupsDaysArray.map(datakey => {

                const subgroups = visitsDays[datakey];
                const sbValues = Object.keys(subgroups);

                const allTrainings = (visitsByDataKey[datakey] && visitsByDataKey[datakey].length) || 0;

                return <div className='person_crm_main_onemonth'>
                    <div className='person_crm_main_onemonth_title'>
                        <span className='person_crm_main_onemonth_title_month'>
                            {moment(datakey, 'MM_YYYY').format('MMMM')}
                        </span>
                        <span className='person_crm_main_onemonth_title_visits'>
                            {`${allTrainings} посещений`}
                        </span>
                    </div>
                    <div className='person_crm_main_onemonth_subgroups'>
                        {sbValues.map(subgroupId => <this.OneSubGroup subgroupId={subgroupId} datakey={datakey} />)}
                    </div>
                </div>
            })}
        </div>
    }

    Invite = () => {
        const { inviteStudents = [], inviteSubgroup } = this.state;
        if (!inviteSubgroup) return <div>Какая-то ошибка</div>

        const { iamuser } = this.props;
        const iam = inviteStudents.find(x => x.UserId === iamuser.Id);
        const { title, adres } = inviteSubgroup;

        if (iam) {
            return <span>
                Вы уже состоите в подгруппе<br />
                {title} <br />
                {adres} <br /> <br />
                Инструктор может ставить Вам посещения
            </span>
        }

        return <div className='person_crm_main_invite'>
            <span>
                Вас приглашают в подгруппу <br />
                {title} <br />
                {adres} <br /><br />
            </span>
            <Button type = 'primary' onClick={this.onInviteInSubgroup}>ПРИНЯТЬ</Button>
        </div>

    }

    OneSubGroup = ({ subgroupId, datakey }) => {

        const { subgroupsDays, subgroups, visitsDays } = this.state;

        const subgroup = subgroups[subgroupId] || {};
        let title = '';
        if (subgroup.title) title += subgroup.title;
        if (subgroup.adres) title += ' / ' + subgroup.adres;

        const subgroupDays = subgroupsDays[datakey][subgroupId];
        const visitDaysObj = groupBy((visitsDays[datakey] && visitsDays[datakey][subgroupId]) || {}, 'Day');

        let answerTrainings = [];
        let isMonthPayed = null;
        let isThereUnpaid = null;
        let Month = 0;
        let Year = 0;

        for (let i = 0; i < subgroupDays.length; i++) {
            const day = subgroupDays[i].Day;
            if (!Month) Month = subgroupDays[i].Month;
            if (!Year) Year = subgroupDays[i].Year;

            let insideSign = '';
            let boxClassNamePlus = '';
            let spanClassNamePLus = '';
            if (visitDaysObj && visitDaysObj[day]) {

                const { DayPayment, MonthPayment, Discipline } = visitDaysObj[day][0];
                if (MonthPayment) isMonthPayed = true;
                if (!DayPayment) {
                    boxClassNamePlus += 'person_crm_main_onesb_oneday_box_unpaid';
                    isThereUnpaid = true;
                } else {
                    boxClassNamePlus += 'person_crm_main_onesb_oneday_box_paid';
                }
                if (Discipline && Discipline < 5 && DISCIPLINE_SMILES[Discipline]) {
                    insideSign = DISCIPLINE_SMILES[Discipline];
                } else {
                    insideSign = '✚';
                    spanClassNamePLus = 'person_crm_main_onesb_oneday_box_cross';
                }
            }

            answerTrainings.push(
                <div key={`${subgroupId}_${datakey}_${day}`} className='person_crm_main_onesb_oneday'>
                    <div className='person_crm_main_onesb_oneday_title'>
                        <span>
                            {day}
                        </span>
                    </div>
                    <div className={'person_crm_main_onesb_oneday_box ' + boxClassNamePlus}>
                        <span className={spanClassNamePLus}>
                            {insideSign}
                        </span>
                    </div>
                </div>
            )
        }

        const isPayed = (isMonthPayed || !isThereUnpaid);
        let visitsClassName = '';
        let paymentClassName = '';
        if (isMonthPayed) visitsClassName += ' person_crm_main_onesb_main_visits_monthpayed'
        if (isPayed) paymentClassName += ' person_crm_main_onesb_main_payment_payed'

        return <div className='person_crm_main_onesb'>
            <div className={'person_crm_main_onesb_payment ' + paymentClassName}>
                <div className='person_crm_main_onesb_payment_month'> {moment(datakey, 'MM_YYYY').format('MMMM')} </div>
                <div className='person_crm_main_onesb_payment_pay'> {isPayed ? 'ОПЛАЧЕН' : 'НЕ ОПЛАЧЕН'} </div>
            </div>
            <div className='person_crm_main_onesb_main'>
                <div className='person_crm_main_onesb_main_title'>
                    {title}
                </div>
                <div className={'person_crm_main_onesb_main_visits ' + visitsClassName}>
                    {answerTrainings}
                </div>
            </div>
        </div>
    }
}

export default withCapoeiraSportState('iamuser, client, isMobile')(PersonCRM);