import React, { Component, Fragment } from 'react';
//import logo from './logo.svg';

import './style.css';
import { withCRMState } from '../../context';
import { OneRaitingBlock, BestRaitingBlock } from 'src/ver2/plashki/raiting/bigTable/blocks';
import { orderBy, groupBy } from 'lodash';

import { Checkbox, Select } from 'antd';
import Loader from 'src/ver2/components/loader';

import StatsGraphics from 'src/ver2/pages/stats/components/graphics';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

const { Option } = Select;

class Statistics extends Component {

    /**
     * props
     * subgroups
     * shadule
     */

    state = {
        loaded: false,
        paymentData: null,
        visitsStatistics: null,

        filterPeriod: 'all',
        periods: [],

        subgroupsOptions: [],
        subgroupsFilter: 'all',
        subgroupsSelectedFilter: [],
        subgroupsFilterClicked: false,

        filterMonth: '0',

        dontShowInstructor: true

    };

    componentDidMount = async () => {
        const visitsStatistics = await this.loadVisitsStatistics();
        this.periodsCalculate(visitsStatistics);
        this.formPaymentGraph(visitsStatistics);
        this.formVisitsGraph();
        this.formPersonsVisitsData(visitsStatistics);
        this.setState({
            loaded: true
        })
    }

    componentDidUpdate = ({ }, { filterPeriod, subgroupsFilter, subgroupsSelectedFilter, filterMonth }) => {

        if (
            filterPeriod !== this.state.filterPeriod ||
            subgroupsFilter !== this.state.subgroupsFilter ||
            subgroupsSelectedFilter.length !== this.state.subgroupsSelectedFilter.length
        ) {
            this.formPaymentGraph();
            this.formVisitsGraph();
            this.formPersonsVisitsData();
        }
        if (filterMonth !== this.state.filterMonth) {
            this.formPersonsVisitsData();
        }

    }

    loadVisitsStatistics = async () => {
        const { client, groupId } = this.props;
        const visitsStatistics = await client.groupVisitsStatistics(groupId)
        this.setState({ visitsStatistics });
        return visitsStatistics;
    }

    // вычисляет какие периоды ( с сентября по сентябрь ) были в подгруппах
    periodsCalculate = async (_visitsStatistics) => {
        const visitsStatistics = _visitsStatistics || this.state.visitsStatistics;
        const { trainingDays, visits } = visitsStatistics;
        const { subgroups } = this.props;
        let periods = {};

        // находим все года и месяца

        for (let i = 0; i < trainingDays.length; i++) {
            const { Month, Year } = trainingDays[i];
            if (Month < 9) periods[Year - 1] = 1;
            if (Month >= 9) periods[Year] = 1;
        }

        periods = Object.keys(periods);

        this.setState({
            periods,
            subgroupsOptions: subgroups.map(({ id, title }) => ({ label: title, value: id })),
            subgroupsSelectedFilter: [subgroups[0].id]
        })

    }

    formVisitsGraph = (_visitsStatistics) => {

        const { subgroups } = this.props;
        const {
            subgroupsFilter,
            subgroupsSelectedFilter,
            filterPeriod
        } = this.state;

        const visitsStatistics = _visitsStatistics || this.state.visitsStatistics;
        const { trainingDays, visits } = visitsStatistics;

        let trainingData = {};

        const startMonth = 9;
        const endMonth = 8;
        const startYear = filterPeriod !== 'all' && Number(filterPeriod);
        const endYear = filterPeriod !== 'all' && (Number(filterPeriod) + 1);

        for (let i = 0; i < trainingDays.length; i++) {

            const { Month, Year } = trainingDays[i];
            if (filterPeriod !== 'all') {
                if (!((Year === startYear && Month >= startMonth) || (Year === endYear && Month <= endMonth))) {
                    continue;
                }
            }
            const MonthWithNull = trainingDays[i].Month > 9 ? trainingDays[i].Month : `0${trainingDays[i].Month}`;
            const key = `${MonthWithNull}.${trainingDays[i].Year}`;

            if (!trainingData[key]) trainingData[key] = {
                Month: trainingDays[i].Month,
                Year: trainingDays[i].Year,
                subgroups: {},
                allTraingings: 0,
                allVisits: 0,
                allAvarage: 0
            }

            const { SubgroupId, Count } = trainingDays[i];
            trainingData[key].subgroups[SubgroupId] = {
                trainigs: Count
            }
            trainingData[key].allTraingings += Count;
        }

        for (let i = 0; i < visits.length; i++) {
            const { Month, Year } = trainingDays[i];
            if (filterPeriod !== 'all') {
                if (!((Year === startYear && Month >= startMonth) || (Year === endYear && Month <= endMonth))) {
                    continue;
                }
            }
            const MonthWithNull = trainingDays[i].Month > 9 ? trainingDays[i].Month : `0${trainingDays[i].Month}`;
            const key = `${MonthWithNull}.${trainingDays[i].Year}`;

            const { SubgroupId, Count } = visits[i];

            if (trainingData[key] && trainingData[key].subgroups[SubgroupId]) {
                trainingData[key].subgroups[SubgroupId].visits = Count;
                const { trainigs } = trainingData[key].subgroups[SubgroupId]
                trainingData[key].subgroups[SubgroupId].avarage = Number((Count / trainigs).toFixed(1));

                trainingData[key].allVisits += Count;
                trainingData[key].allAvarage = Number((trainingData[key].allVisits / trainingData[key].allTraingings).toFixed(1));
            }
        }

        trainingData = orderBy(Object.values(trainingData), ['Year', 'Month'], ['asc', 'asc']);
        console.log({ trainingData, subgroups });

        let xDataAvarage = {}
        let yData = [];

        let xDataVisits = {};

        if (subgroupsFilter === 'all') {
            xDataAvarage[1] = {
                title: 'ОБЩЕЕ',
                dots: []
            }
            xDataVisits[1] = {
                title: 'ОБЩЕЕ',
                dots: []
            }
        } else {
            for (let i = 0; i < subgroups.length; i++) {
                const { id, title } = subgroups[i];
                if (subgroupsSelectedFilter.find(x => Number(x) === id)) {
                    xDataAvarage[id] = {
                        title,
                        dots: []
                    }
                    xDataVisits[id] = {
                        title,
                        dots: []
                    }
                }
            }
        }

        // subgroupsFilter,
        // subgroupsSelectedFilter,


        trainingData.map(({ Month, Year, allAvarage, allVisits, subgroups: innerSubgroups }, index) => {
            yData.push({
                title: `${moment(`1.${Month}.${Year}`, 'DD.MM.YYYY').format('MMMM YYYY').toUpperCase()}`
            });

            if (subgroupsFilter === 'all') {
                xDataAvarage[1].dots.push({ x: index, y: allAvarage })
                xDataVisits[1].dots.push({ x: index, y: allVisits })
                return 1;
            }

            for (let i = 0; i < subgroups.length; i++) {
                const { id } = subgroups[i];
                !!xDataAvarage[id] && xDataAvarage[id].dots.push({ x: index, y: innerSubgroups[id] ? innerSubgroups[id].avarage : 0 })
                !!xDataVisits[id] && xDataVisits[id].dots.push({ x: index, y: innerSubgroups[id] ? innerSubgroups[id].visits : 0 })
            }

            return 1;
        })


        trainingData = {
            graphicsTitle: 'СРЕД. ПОСЕЩЕНИЯ',
            axes: {
                higherY: 0,
                yStep: 3,
                legendsX: yData
            },
            data: Object.values(xDataAvarage)
        }

        const visitsData = {
            graphicsTitle: 'ПОСЕЩЕНИЯ',
            axes: {
                higherY: 0,
                yStep: 20,
                legendsX: yData
            },
            data: Object.values(xDataVisits)
        }

        // делим по группам 

        this.setState({
            trainingData,
            visitsData
        })

    }

    formPaymentGraph = () => {
        const { payments, subgroups } = this.props;
        const {
            subgroupsFilter,
            subgroupsSelectedFilter,
            filterPeriod
        } = this.state;

        // месяц/год
        const paymentsKeys = Object.keys(payments);
        console.log('payments', { payments })


        let answer = [];
        let answerBySubgroups = {};
        for (let i = 0; i < subgroups.length; i++) {
            const { id } = subgroups[i];
            answerBySubgroups[id] = [];
        }


        const startMonth = 9;
        const endMonth = 8;
        const startYear = filterPeriod !== 'all' && Number(filterPeriod);
        const endYear = filterPeriod !== 'all' && (Number(filterPeriod) + 1);

        for (let i = 0; i < paymentsKeys.length; i++) {
            const dataKey = paymentsKeys[i];
            if (filterPeriod !== 'all') {
                const pm_data = dataKey.split('_');
                const month = Number(pm_data[0]);
                const year = Number(pm_data[1]);

                if (!((year === startYear && month >= startMonth) || (year === endYear && month <= endMonth))) {
                    continue;
                }
            }

            const subgroups = Object.keys(payments[dataKey]);

            for (let j = 0; j < subgroups.length; j++) {
                const subgroupid = subgroups[j];
                const users = Object.keys(payments[dataKey][subgroupid]);
                for (let k = 0; k < users.length; k++) {
                    const userid = users[k];
                    if (payments[dataKey][subgroupid][userid].month) {
                        const { Month, Year } = payments[dataKey][subgroupid][userid].month;
                        answer.push({
                            ...payments[dataKey][subgroupid][userid].month,
                            dateIndex: (Year * 100) + Month
                        })
                        answerBySubgroups[subgroupid].push({
                            ...payments[dataKey][subgroupid][userid].month,
                            dateIndex: (Year * 100) + Month
                        })
                    }
                    if (payments[dataKey][subgroupid][userid].day) {
                        const days = Object.keys(payments[dataKey][subgroupid][userid].day);
                        for (let z = 0; z < days.length; z++) {
                            answer.push({
                                ...payments[dataKey][subgroupid][userid].day[days[z]],
                                dateIndex: (payments[dataKey][subgroupid][userid].day[days[z]].Year * 100) + payments[dataKey][subgroupid][userid].day[days[z]].Month
                            })
                            answerBySubgroups[subgroupid].push({
                                ...payments[dataKey][subgroupid][userid].day[days[z]],
                                dateIndex: (payments[dataKey][subgroupid][userid].day[days[z]].Year * 100) + payments[dataKey][subgroupid][userid].day[days[z]].Month
                            })
                        }

                    }


                }
            }
        }

        let paymentsSubgroupsObj = {};
        for (let j = 0; j < subgroups.length; j++) {
            const { id, title } = subgroups[j];
            answerBySubgroups[id] = orderBy(answerBySubgroups[id], ['dateIndex', 'Id'], ['asc', 'desc']);
            let paymentsObj = [];
            let nowDataKey = null;
            const answer = answerBySubgroups[id];
            for (let i = 0; i < answer.length; i++) {
                if (!nowDataKey || nowDataKey !== answer[i].dateIndex) {
                    nowDataKey = answer[i].dateIndex;
                    paymentsObj.push(
                        {
                            nowDataKey,
                            month: answer[i].Month,
                            year: answer[i].Year,
                            sum: 0
                        }
                    );
                }
                paymentsObj[paymentsObj.length - 1].sum += answer[i].Value;
            }

            paymentsSubgroupsObj[id] = {
                id,
                title,
                payments: paymentsObj
            }
        }

        answer = orderBy(answer, ['dateIndex', 'Id'], ['asc', 'desc']);
        console.log('ОПЛАТЫ', { answer });

        let paymentsObj = [];
        let nowDataKey = null;
        for (let i = 0; i < answer.length; i++) {
            if (!nowDataKey || nowDataKey !== answer[i].dateIndex) {
                nowDataKey = answer[i].dateIndex;
                paymentsObj.push(
                    {
                        nowDataKey,
                        month: answer[i].Month,
                        year: answer[i].Year,
                        sum: 0
                    }
                );
            }
            paymentsObj[paymentsObj.length - 1].sum += answer[i].Value;
        }

        console.log({ paymentsObj, paymentsSubgroupsObj })

        let paymentData;

        if (subgroupsFilter === 'all') {
            paymentData = {
                unit: '₽',
                graphicsTitle: 'ОПЛАТА',
                axes: {
                    higherY: 0,
                    yStep: 10000,
                    legendsX:
                        paymentsObj.map(({ month, year }) => { return { title: `${moment(`1.${month}.${year}`, 'DD.MM.YYYY').format('MMMM YYYY').toUpperCase()}` } })
                },
                data: [
                    { title: 'Сумма', dots: paymentsObj.map(({ sum }, index) => { return { x: index, y: sum } }) }
                ]
            }
        } else {
            //subgroupsSelectedFilter
            let data = [];
            for (let j = 0; j < subgroupsSelectedFilter.length; j++) {
                const Id = Number(subgroupsSelectedFilter[j]);
                if (!paymentsSubgroupsObj[Id]) continue;

                const { payments, title } = paymentsSubgroupsObj[Id];
                data.push(
                    {
                        title, dots:
                            paymentsObj.map(
                                ({ month, year }, index) => {

                                    const payment = payments.find(x => x.month === month && x.year === year);
                                    return { x: index, y: payment ? payment.sum : 0 }
                                })
                    }
                )
            }

            paymentData = {
                unit: '₽',
                graphicsTitle: 'ОПЛАТА',
                axes: {
                    higherY: 0,
                    yStep: 10000,
                    legendsX:
                        paymentsObj.map(({ month, year }) => { return { title: `${moment(`1.${month}.${year}`, 'DD.MM.YYYY').format('MMMM YYYY').toUpperCase()}` } })
                },
                data
            }
        }

        this.setState({
            paymentData
        })

    }

    formPersonsVisitsData = (_visitsStatistics) => {
        const { iamuser } = this.props;
        const {
            subgroupsFilter,
            subgroupsSelectedFilter,
            filterPeriod,
            filterMonth,
            dontShowInstructor
        } = this.state;

        const visitsStatistics = _visitsStatistics || this.state.visitsStatistics;
        const { personVisits } = visitsStatistics;

        const startMonth = 9;
        const endMonth = 8;
        const startYear = filterPeriod !== 'all' && Number(filterPeriod);
        const endYear = filterPeriod !== 'all' && (Number(filterPeriod) + 1);

        /*
            <UserId> : {
                UserId,
                all,
                months: {
                    1: 2,
                    2: 10,
                    3...
                }
            }
        */
        let personsVisits = {

        }
        let subgroupsSelectedFilterObj = {};
        for (let i = 0; i < subgroupsSelectedFilter.length; i++) {
            subgroupsSelectedFilterObj[Number(subgroupsSelectedFilter[i])] = 1;
        }

        for (let i = 0; i < personVisits.length; i++) {
            const { Month, Year, Count, SubgroupId, UserId } = personVisits[i];

            if (dontShowInstructor && iamuser.Id === UserId) {
                continue;
            }

            if (filterPeriod !== 'all') {
                if (!((Year === startYear && Month >= startMonth) || (Year === endYear && Month <= endMonth))) {
                    continue;
                }
                if (Number(filterMonth) && Number(filterMonth) !== Month) {
                    continue;
                }
            }
            if (subgroupsFilter !== 'all') {
                if (!subgroupsSelectedFilterObj[SubgroupId]) continue;
            }

            if (!personsVisits[UserId]) {
                personsVisits[UserId] = {
                    UserId,
                    Count: 0,
                    months: {
                        1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0
                    }
                }
            }
            personsVisits[UserId].Count += Count;
            if (filterPeriod !== 'all')
                personsVisits[UserId].months[Month] = Count;
        }

        let result = {
            all: [],
            months: {
                1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: []
            }
        }

        result.all = orderBy(personsVisits, ['Count'], ['desc']);

        const formPlaces = (arr, month = 0) => {

            if (!arr || !arr.length) return []

            const byCount = groupBy(arr, 'Count');
            const maxCount = arr[0].Count + 1;

            let result = [];
            let placeNow = 1;
            let numberNow = 1;

            for (let i = maxCount; i > 0; i--) {
                if (!byCount[i]) continue;
                for (let j = 0; j < byCount[i].length; j++) {
                    const { UserId } = byCount[i][j];
                    result.push({
                        UserId,
                        Count: i,
                        Place: placeNow,
                        PlaceName: (byCount[i].length === 1) ? numberNow : `${numberNow} - ${numberNow + byCount[i].length - 1}`
                    })
                }
                placeNow++;
                numberNow += byCount[i].length;
            }
            return result;
        }

        result.all = formPlaces(result.all);

        // if (filterPeriod === 'all') {

        // }

        this.setState({ personsVisits: result })

    }

    onSubgroupsSelectedFilterChanged = (subgroupsSelectedFilter) => {
        if (!subgroupsSelectedFilter.length) return null;
        this.setState({ subgroupsSelectedFilter })
    }

    onSubgroupsFilterChecked = (e) => {
        if (e.target.checked) return this.setState({ subgroupsFilter: 'all' })
        this.setState({ subgroupsFilter: 'notall' })
    }

    FilterSubroups = () => {

        const { subgroupsOptions, subgroupsFilter, subgroupsSelectedFilter, subgroupsFilterClicked } = this.state;

        return <div className='crm_stats_main_onefiltr'>
            <div>Подгруппы</div>
            <div className='crm_stats_main_onefiltr_row' onClick={() => { this.setState({ subgroupsFilterClicked: true }) }}>
                {subgroupsFilter === 'all' ? 'ВСЕ ПОДГРУППЫ' : subgroupsSelectedFilter.map(value => {
                    const sb = subgroupsOptions.find(x => x.value === value);
                    if (!sb) return null;
                    const { label } = sb;
                    return <span>{label}</span>
                }

                )}
            </div>

            {!!subgroupsFilterClicked && <Fragment>
                <div className='crm_stats_main_onefiltr_modal_bg' onClick={() => { this.setState({ subgroupsFilterClicked: false }) }} />
                <div className='crm_stats_main_onefiltr_modal'>
                    <Checkbox checked={subgroupsFilter === 'all'} onChange={this.onSubgroupsFilterChecked} > Все подгруппы </Checkbox>
                    <div className='crm_stats_main_onefiltr_modal_devider' />
                    <Checkbox.Group disabled={subgroupsFilter === 'all'} options={subgroupsOptions} value={subgroupsSelectedFilter}
                        onChange={this.onSubgroupsSelectedFilterChanged} />
                </div>
            </Fragment>}


        </div>

    }

    FilterPeriod = () => {
        // 'all', ...years
        const { filterPeriod, periods } = this.state;

        return <div className='crm_stats_main_onefiltr'>
            <div>Период:</div>
            <Select value={filterPeriod} onChange={(filterPeriod) => { this.setState({ filterPeriod }) }}>
                <Option key='all'>ВСЁ ВРЕМЯ</Option>
                {periods.map(value => <Option key={value}>{`СЕНТ ${value} - АВГ ${Number(value) + 1}`}</Option>)}
            </Select>
        </div>
    }

    FilterMonth = () => {
        // 'all', ...years
        const { filterMonth, filterPeriod } = this.state;

        return <div className='crm_stats_main_onefiltr'>
            <div>Месяц:</div>
            <Select disabled={filterPeriod === 'all'} value={filterMonth} onChange={(filterMonth) => { this.setState({ filterMonth }) }}>
                <Option key={0}>ВЕСЬ ПЕРИОД</Option>
                <Option key={9}>СЕНТЯБРЬ</Option>
                <Option key={10}>ОКТЯБРЬ</Option>
                <Option key={11}>НОЯБРЬ</Option>
                <Option key={12}>ДЕКАБРЬ</Option>
                <Option key={1}>ЯНВАРЬ</Option>
                <Option key={2}>ФЕРАЛЬ</Option>
                <Option key={3}>МАРТ</Option>
                <Option key={4}>АПРЕЛЬ</Option>
                <Option key={5}>МАЙ</Option>
                <Option key={6}>ИЮНЬ</Option>
                <Option key={7}>ИЮЛЬ</Option>
                <Option key={8}>АВГУСТ</Option>
            </Select>
        </div>
    }

    Filtres = () => {
        const { FilterPeriod, FilterMonth, FilterSubroups } = this;
        return <div className='crm_stats_main_filtres'>
            <FilterPeriod />
            <FilterMonth />
            <FilterSubroups />
        </div>
    }

    BestBlock = () => {

        const { isMobile } = this.props;
        const { personsVisits } = this.state;
        if (!personsVisits) return null;

        const { all, months } = personsVisits;

        let databest = [];
        let lastPlace = 1;
        for (let i = 0; i < all.length; i++) {
            const { UserId, Place } = all[i];
            if (Place > 3) break;

            if (i >= 3 && lastPlace !== Place) break;
            lastPlace = Place;

            databest.push(
                {
                    UserId,
                    Place: Place || (i + 1)
                }
            )
        }

        const slicedAll = all.slice(0, 10);

        return <div className='crm_stats_main_best'>
            <div className='crm_stats_main_best_best'>
                <BestRaitingBlock isMobile={isMobile} data={databest} />
            </div>
            <div className='crm_stats_main_best_table'>
                {slicedAll && !!slicedAll.length &&
                    slicedAll.map(({ UserId, Place, PlaceName, Count }, index) => {
                        return <OneRaitingBlock key={`_${UserId}_`} data={{
                            PlaceName: PlaceName || (index + 1),
                            Place: Place || (index + 1),
                            AllScores: Count,
                            UserId
                        }} />
                    })
                }
            </div>
        </div>
    }


    render() {
        const { Filtres, BestBlock } = this;
        const { paymentData, trainingData, visitsData, loaded } = this.state;
        const { isMobile } = this.props;

        const width = isMobile ? (isMobile.width - 20) : 1200;
        const height = isMobile ? 300 : 500;

        console.log(this.props)

        if (!loaded) return <div className='crm_stats_main'>
            <Loader text='загрузка статистики' />
        </div>

        const size = isMobile ? 'little' : '';

        return <div className='crm_stats_main'>
            <Filtres />
            <BestBlock />
            {paymentData && <StatsGraphics size = {size} width={width} height={height} data={paymentData} />}
            {trainingData && <StatsGraphics size = {size}  width={width} height={height} data={trainingData} />}
            {visitsData && <StatsGraphics size = {size}  width={width} height={height} data={visitsData} />}
        </div>
    }

}

export default withCRMState(
    `
        iamuser,
        isMobile,

        client,
        groupId,
    
        payments,
        studentVisits,
        trainingDaysAsObj,
        visits,

        subgroups,
        shadule,
    `
)(Statistics)