// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { message } from 'antd';
import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';


/**
 * 
 * Это компонент добавления или редактирования новости
 */

class AddNewsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: ''
        }

        if (props.modalData && props.modalData.title) {
            this.state.title = props.modalData.title;
        }
        if (props.modalData && props.modalData.template) {
            this.state.text = props.modalData.template;
        }

    }


    changeTitle = ({title}) => { this.setState({title}) }
    goAddNewNews = async ( body ) => {
        const { title } = this.state;
        if (!title || title === '') {
            return message.warn('Введите тайтл!');
        }
        if (!body || body === '') {
            return message.warn('Напишите что-то в новости!');
        }

        const { addNewNews, close } = this.props;
        addNewNews( {title, body, lvl: 1} );
        close(false);
    }

    render() {

        const { iAmAdmin } = this.props;
        const { title, text } = this.state;

        if (!iAmAdmin) return <div>ЧО ТЫ ТУТ ДЕЛАЕШЬ???</div>

        return <div className='add_news_modal'>
            {/* <InfoChanger text={Discription.Body} onCallback={updateDiscription} /> : */}
            <DataChanger
                header={<div>
                   Введите тайтл и текст новости
                         </div>}
                data={{title}}
                changeData = {this.changeTitle}
                noButton = {true}
                components={[
                    {
                        name: 'title',
                        title: 'Тайтл новости',
                        type: 'input',
                        style: {
                            width: 600
                        },
                        text: 'Тайтл новости не должен превышать 30-40 символов',
                    },
                ]}
            />
            <InfoAdder text = {text || null} onCallback={this.goAddNewNews} />
        </div>
    }
}

/*
    ChildrenComponent - какой компонент использовать
    CholdrenProps - что туда прокинуть
    onCallback - функция, которую вызывать при триггере компонента
    callbackName - имя функции, которую тригерить у компонента
    callbackParam - имя параметра, в котором к компоненты улетит callbackStatus
*/

class InfoAdder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callbackStatus: ''
        }
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { callbackStatus } = this.state;
        const { text } = this.props;
        return <TrefaTextEdit text = {text || null} buttonText={'Добавить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Добавить' }} onButtonClick={this.onCallback} />
    }

}


export default withShopState(`
addNewNews,
close,
iAmAdmin
`)(AddNewsModal);