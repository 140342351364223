// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input } from 'antd';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest27 extends PureComponent {
    state = {
        editText: '',
        count: 0
    }

    clickOnSend = () => {

        let editText = this.state.editText;
        if (!editText || editText === '') editText = '_';


        // editText = editText.toUpperCase();
        // if (editText === 'БЕНГЕЛА' || editText === 'BENGELA' || editText === 'BENGUELA') {
        //     editText = 'BENGUELA';
        // }

        const cdd = '8254781';
        let count = 0;

        for ( let i = 0; i < 7; i ++ ) {
            if (!cdd[i] || !editText[i]) break;
            if ( cdd[i] === editText[i]) count++;
        }

        this.props.sendSpecial({ data: { [editText]: 1 } })
        this.setState({count});
    }



    render() {
        const { isMobile } = this.props;
        const { editText, count } = this.state;
        const className = 'quest27_container'

        return <div className={className}>
            <Input style={{ width: 200 }} value={editText} onChange={(e) => {
                if (e.target.value.length > 7) {
                    return;
                }
                this.setState({ editText: e.target.value })
            }} />
            <div> {count} лампочек </div>
            <div className='quest20_container_send' onClick={this.clickOnSend}>
                <span>{'ВВЕСТИ КОД'}</span>
            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest27);