import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import { PDFReader } from 'reactjs-pdf-reader';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class JudgePdfRulesPlashka
// classname: judge_prfrules_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class JudgePdfRulesPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { upperStyle, isMobile } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='judge_prfrules_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='judge_prfrules_plashka'>
            {
                isMobile ?
                    <a href="/../images/ver2/pravila.pdf">
                        <div className='judge_prfrules_text'>
                            Последняя редакция правил 2022-2023
                        </div>
                    </a>
                    :
                    <div className='judge_prfrules_text'
                        onClick={() => { this.props.showInfoModal({ type: 'pdfrules' }); }}>
                        Последняя редакция правил 2022-2023
                    </div>
            }
        </div>
    }
}

export default withCapoeiraSportState(`
showInfoModal,
isMobile
`)(JudgePdfRulesPlashka)