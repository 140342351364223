import React, { Component } from 'react';

// import { places, citys, metros, getGroupsByPlace } from '../../../client';

import './styles.css';

export default class Map extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }


        const { places, center = [55.760636, 37.544374], zoom = 14.5, imgUrl } = props;
        const ymaps = window.ymaps;

        const placemarkData = imgUrl === 'none' ?
            {

            } :
            {
                iconImageHref: (imgUrl || `images/groups/milagre.png`),
                iconImageSize: [40, 40],
                iconImageOffset: [-20, -20]
            }

        ymaps.ready(() => {
            var myMap = new ymaps.Map('map', {
                center,
                zoom,
                controls: []
            }, {
                searchControlProvider: 'yandex#search'
            }),

                // Создаём макет содержимого.
                MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
                    '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
                )

            places.reduce((result, place) => {
                const Placesmark = new ymaps.Placemark(place.coords, {
                    hintContent: `<div>${place.name}</br>${place.metro}</div>`,
                    hasBalloon: false
                }, {
                    iconLayout: 'default#image',
                    ...placemarkData
                })

                myMap.geoObjects
                    .add(Placesmark);

                return {}

            }, [])

            this.myMap = myMap;


        });

    }

    hello = () => {
        alert('hello');
    }

    render() {
        const { isMobile } = this.props;

        if (isMobile)
            return <div id="map" style={{ width: isMobile.width - 10, height: isMobile.height - 100 }}>
            </div>

        return <div id="map">
        </div>
    }

}