import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
import MoneyComponent from 'src/ver2/components/money';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import ExchangeModal from './sendApplcation';

import { Modal } from 'antd';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class ShopExchangerPlashka
// classname: shop_exchanger_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopExchangerPlashka extends PureComponent {

    state = {
        modalExchange: false
    }

    setExchangeVisible = (modalExchange = true) => {
        this.setState({ modalExchange })
    }

    onChangeClick = (Amount) => {
        const { exchangeMoney } = this.props;
        this.setExchangeVisible(false);
        // меняем бабки и обновляем ресурсы
        exchangeMoney(Amount);
    }

    render() {

        const { Shop: { SettingsObj }, upperStyle, iamuser } = this.props;
        const { modalExchange } = this.state;
        const { width, height } = upperStyle;

        if (!SettingsObj) return <div style={{ width, height }} className='shop_exchanger_plashka'>
            <Loader />
        </div>

        let Answer = [];

        const { Exchange: { MoneyFrom, MoneyTo, Rate } } = SettingsObj;

        const goExchange = () => {

        }

        let myResourses = {};
        if (iamuser) myResourses = iamuser.myResourses;
        const { Money } = myResourses || {};

        let disabled = false;
        let disabledDiscription = '';

        if (!iamuser) {
            disabled = true;
            disabledDiscription = 'Вы не зарегистрированы';
        }

        // console.log( 'Money', { MoneyFrom, Money } );
        if (!Money || !Money[MoneyFrom] || !Money[MoneyFrom].all || Money[MoneyFrom].all < 1) {
            disabled = true;
            disabledDiscription = 'Нет данных ресурсов';
        }


        return <div style={{ width, height }} className='shop_exchanger_plashka'>
            <div className='shop_exchanger_plashka_discription'>
                <MoneyComponent size={35} type={MoneyFrom} amount={1} />
                <span>=</span>
                <MoneyComponent size={35} type={MoneyTo} amount={Rate} />
                <div className='shop_exchanger_plashka_button'>
                    <ButtonPlashka
                        upperStyle={{ width: width - 100, height: height - 12 }}
                        disabled={disabled}
                        disabledDiscription={disabledDiscription}
                        onClick={() => { this.setExchangeVisible() }}
                        text={'ОБМЕН'}
                    />
                </div>
            </div>
            <Modal
                className='shop_exchanger_plashka_modal_class'
                visible={modalExchange}
                onCancel={() => { this.setExchangeVisible(false) }}
                footer={null}
                title={'Обмен ресурсов'}
                width={300}
            >
                {modalExchange ? <ExchangeModal onChangeClick={this.onChangeClick} /> : null}
            </Modal>
        </div>
    }

}

export default withShopState(`
Shop,  
isShopMoneyReady, 
ShopMoney,
exchangeMoney,
iamuser,
client
`)(ShopExchangerPlashka);