import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';

import Loader from 'src/ver2/components/loader';

import { Collapse, Avatar, Icon } from 'antd';
import { sortBy } from 'lodash';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventBatizadoCordaosPlashka
// classname: event_categorys_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */


const { Panel } = Collapse;


class EventBatizadoCordaosPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false,
            lookedCordaoKey: null,

            Cordaos: null, // key, name, cordao
            participatorsInCordaos: {}

        }
    }

    componentDidMount = () => {
        this.setCordaosState();
        this.setHeight();
    }

    componentDidUpdate = () => {
        this.setCordaosState();
        this.setHeight();
    }

    setCordaosState = () => {
        const { Event, Participators, iamuser } = this.props;
        if (!Event || !Participators) return  {}
        if (this.state.Cordaos) return {};
        
        const { SettingsObj } = Event;
        if (!SettingsObj || !SettingsObj.BatizadoMain || !SettingsObj.ParticipatorsMain || !SettingsObj.ParticipatorsMain.ParticipatorsData) return {}


        const ParticipatorsData = SettingsObj.ParticipatorsMain.ParticipatorsData;
        const BatizadoMain = SettingsObj.BatizadoMain;

        // нам нужно вытащить кордао
        const { cordaoParam, cordaoKeys } = BatizadoMain;
        let Cordaos = [];
        let participatorsInCordaos = {};

        const ourData = ParticipatorsData.find( x => x.name === cordaoParam);
        if (ourData) {
            const { options } = ourData;
            for ( let i = 0; i < options.length; i++) {
                const option = options[i];
                if ( cordaoKeys[option.key]) {
                    Cordaos.push ({
                        key: option.key,
                        name: option.title,
                        cordao: cordaoKeys[option.key]
                    })
                }
            }
        }

        // теперь состав набираем

        for ( let i = 0; i < Cordaos.length; i++) {
            const { key } = Cordaos[i];
            participatorsInCordaos[key] = {
                count:0,
                participators: [],

                groupHere: 0,
                groupIamInHere: 0,        
            };
        }

        for ( let i = 0; i < Participators.length; i++ ) {
            const { Settings, GroupIamInId } = Participators[i];
            if (!Settings) continue;
            const Settings_ = JSON.parse(Settings);

            const toCordao = Settings_[cordaoParam];
            if (toCordao && participatorsInCordaos[toCordao]) {
                participatorsInCordaos[toCordao].count++;
                participatorsInCordaos[toCordao].participators.push( {...Participators[i], Id: Participators[i].UserId } );

                if (iamuser && iamuser.GroupId === GroupIamInId) participatorsInCordaos[toCordao].groupHere++;
                if (iamuser && iamuser.GroupIamInId === GroupIamInId) participatorsInCordaos[toCordao].groupIamInHere++;
            }
        }

        this.setState({Cordaos, participatorsInCordaos});

    }

    setHeight = async () => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const { heightSetted, Cordaos } = this.state;
        if (!heightSetted && Cordaos && Cordaos.length) {
            this.setState({
                heightSetted: true
            })
            setComponentSelfHeight({ height: 50 * Cordaos.length + 550, plashkaId });
        }
    }

    getCountInCordao = (key) => {
        // const { competitorsCountInCategorys, isCompetitorsCountInCategorysReady } = this.props;
        // if (!isCompetitorsCountInCategorysReady || !competitorsCountInCategorys || !competitorsCountInCategorys[0]) return 0;
        // const findedCount = competitorsCountInCategorys.find(x => x.CategoryId === id);
        // if (findedCount) return findedCount.count;

        const { participatorsInCordaos } = this.state;
        if (!participatorsInCordaos || !participatorsInCordaos[key]) return 0;
        return participatorsInCordaos[key].count;
    }

    setActiveCategory = async (key) => {
        if (key === undefined) {
            return this.setState({ selectedCordaoData: [], lookedCordaoKey: key });
        }

        const { participatorsInCordaos } = this.state;

        const selectedCordaoData = (participatorsInCordaos && participatorsInCordaos[key] ? participatorsInCordaos[key].participators : []);
        this.setState({ selectedCordaoData, lookedCordaoKey: key });
    }

    render() {

        const { Event, upperStyle } = this.props;
        const { lookedCordaoKey, selectedCordaoData, Cordaos } = this.state;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_categorys_plashka'>
            <Loader />
        </div>

        if (!Cordaos || !Cordaos.length) return <div style={{ width, height }} className='event_categorys_plashka'>
            <span>
                КАТЕГОРИИ ПОКА НЕ ОПРЕДЕЛЕНЫ
            </span>
        </div>

        return <div style={{ width, height }} className='event_categorys_plashka'>
            <Collapse height='fit-content' activeKey={lookedCordaoKey} onChange={this.setActiveCategory} accordion>

                {Cordaos.map((item, index) => {
                    return <Panel
                        header={<this.PanelHeader cordao={item} />}
                        key={item.key}>
                        <TrefaLayout greedsX={[500]}
                            components={[
                                {
                                    component: PersonsLittleListPlashka,
                                    cell_x: 1,
                                    collapse: 1,
                                    componentProps: {
                                        itemHeight: 40,
                                        height: 300,
                                        Persons: selectedCordaoData,
                                        autoSize: true,
                                        autoSizeMax: 7,
                                        personsDirect: true,
                                        mode: 'categorys'
                                    }
                                }
                            ]}
                        />
                    </Panel>
                })}

            </Collapse>
        </div>
    }

    PanelHeader = ({ cordao }) => {

        let categoryInfoBlock = null;

        const { participatorsInCordaos } = this.state;

        if (participatorsInCordaos && participatorsInCordaos[cordao.key] && this.props.iamuser) {
            const { iamuser, client } = this.props;
            const {
                GroupIamInId,
                GroupId
            } = iamuser;

            const {
                groupHere,
                groupIamInHere
            } = participatorsInCordaos[cordao.key] || {};

            const srcGroupId = client ? client.getGroupUrl(GroupId) : 'images/groups/' + GroupId + '/logo.png';
            const srcGroupIHId = client ? client.getGroupUrl(GroupIamInId) : 'images/groups/' + GroupIamInId + '/logo.png';

            categoryInfoBlock = <div className='event_categorys_plashka_collapse_header_info'>
                {groupHere ? <p className='global-tooltip' data-tooltip={'Есть Ваши ученики'}><this.LogoPlusCount url={srcGroupId} count={groupHere} /></p> : null}
                {(GroupIamInId !== GroupId && groupIamInHere) ? <p className='global-tooltip' data-tooltip={'Есть участники из вашей группы'}><this.LogoPlusCount url={srcGroupIHId} count={groupIamInHere} /></p> : null}
            </div>

        }

        return <div className='event_categorys_plashka_collapse_header'>
            <div className='event_categorys_plashka_collapse_header_name_and_info'>{cordao.name} {categoryInfoBlock} </div>
            <div> <Icon type="user" /> {this.getCountInCordao(cordao.key)} </div>
        </div>
    }

    LogoPlusCount = ({ url, count }) => {
        return [
            <Avatar size={20} src={url} />,
            <div className='cf-lpc'>
                {count}
            </div>
        ]
    }


}

export default withEventState(`
Event, 
Participators,
client,
iamuser
`)(EventBatizadoCordaosPlashka)