import React, { PureComponent } from 'react';
import ShopState, { withShopState } from './context';
import { Modal } from 'antd';

import ButtonPlashka from 'src/ver2/plashki/ui/button';
import AdminModal from './containers/adminModal';
import AddNewsModal from './containers/addNewsModal';
import AddItemModal from './containers/addItemModal';

import { TrefaLayout } from 'src/ver2/containers/layout';
import SBP from './plashki/bigPlashka';
import ShopInfoPlashka from './plashki/info';
import ShopItemsPlashka from './plashki/shopItems';
import ShopPersonMoneyPlashka from './plashki/personMoney';
import ShopMoneyPlashka from './plashki/shopMoney';
import ShopBuyersPlashka from './plashki/buyers';
import ShopFiltersPlashka from './plashki/shopFilters';
import ShopExchangerPlashka from './plashki/exchanger';
import ShopDonatePlashka from './plashki/donate';
import PersonQuestsPlashka from './plashki/quests';
import ShopKMOPlashka from './plashki/kmo';
import RealShopMyOrdersPlashka from './plashki/myOrders';

import RealShopAdminPlashka from './plashki/realAdmin';

import Loader from 'src/ver2/components/loader';

import UiChooser from 'src/ver2/plashki/ui/chooser';


import './styles.css';

class ShopPage extends PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            page: 'info',
            showAdminModal: false,
            showRegistrtionModal: false,
            showAddNewNewsModal: false, //{ title: 'Предлагаю новость!', template: `^a_^text^a_^Охуенно то как !` }
            showAddItemModal: false
            //false // тут может быть заложенная уже информация { title, template }
        }

        this.searchDefault = {
            page: 'info'
        }

    }

    changeShowAdminModal = (showAdminModal = true) => this.setState({ showAdminModal })
    changeAddNewNewsModal = (showAddNewNewsModal = true) => this.setState({ showAddNewNewsModal })
    changeAddItemModalModal = (showAddItemModal = true) => this.setState({ showAddItemModal })

    linkTo = (param, value) => {
        let { page, itemId } = this.state;
        let linkObject = { page };
        if (page === 'shop') linkObject.itemId = itemId;
        linkObject[param] = value;
        return `?${Object.keys(linkObject).reduce((memo, key, index) => {
            if (!linkObject[key]) return memo;
            return memo + `${index > 0 ? '&' : ''}${key}=${linkObject[key]}`
        }, '')}`
    }

    chooseTab = () => {
        const { location } = this.props;
        const { search } = location;

        // парсим search
        let searchObj = {
            ...this.searchDefault
        }

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        this.setState({
            ...searchObj
        })

    }

    componentDidMount() {
        this.chooseTab();
    }

    componentDidUpdate() {
        this.chooseTab();
    }

    render() {

        const { Shop: { Id, Type } = {}, isShopReady, iAmAdmin, iamuser, isMobile } = this.props;
        const { showAdminModal, showAddNewNewsModal, showAddItemModal } = this.state;

        if (!isShopReady) return <div className='shop_page'>
            <Loader />
        </div>

        // SettingsObj && SettingsObj.Exchange

        const width = isMobile ? isMobile.width : 1200;


        let components_ = []

        // виртуальный магазин
        if (!Type) {
            components_ = this.isAddingBig(components_);
            components_ = this.isAddingUi(components_);
            components_ = this.isAddingShopMoney(components_);
            components_ = this.isAddingShopPersonMoney(components_);


            components_ = this.isAddingShopFilters(components_);
            components_ = this.isShopExchanger(components_);
            components_ = this.isAddingQuests(components_);
            components_ = this.isAddingInfo(components_);
            components_ = this.isAddingDonate(components_);
            components_ = this.isAddingShopItems(components_);
            components_ = this.isAddingKMO(components_);
            components_ = this.isShopBuyers(components_);
            components_ = this.isAddingAdminButton(components_);
        }

        // реальный магазин
        if (Type === 1) {
            components_ = this.isAddingBig(components_);
            components_ = this.isAddingUi(components_);

            components_ = this.isAddingQuests(components_);
            components_ = this.isAddingInfo(components_);
            components_ = this.isAddingDonate(components_);
            components_ = this.isAddingShopItems(components_);
            components_ = this.isShopRealAdmin(components_);
            components_ = this.isAddingAdminButton(components_);
            components_ = this.isAddingMyOrders(components_);
        }

        return <div style={{ width }} className='shop_page'>
            <Modal
                className='adminModalClass'
                visible={showAdminModal}
                onCancel={() => { this.changeShowAdminModal(false) }}
                footer={null}
                title={'Редактировать данные'}
                width={900}
            >
                {showAdminModal ? <AdminModal /> : null}
            </Modal>
            <Modal
                className='adminModalClass'
                visible={showAddNewNewsModal}
                onCancel={() => { this.changeAddNewNewsModal(false) }}
                footer={null}
                title={'Новости мероприятия'}
                width={900}
            >
                {showAddNewNewsModal ? <AddNewsModal close={this.changeAddNewNewsModal} modalData={showAddNewNewsModal} /> : null}
            </Modal>
            <Modal
                className='adminModalClass'
                visible={showAddItemModal}
                onCancel={() => { this.changeAddItemModalModal(false) }}
                footer={null}
                title={'Добавить предмет'}
                width={900}
            >
                {showAddItemModal ? <AddItemModal close={this.changeAddItemModalModal} closeModal={() => this.changeAddItemModalModal(false)} /> : null}
            </Modal>
            <TrefaLayout greedsX={isMobile ? [5, isMobile.width - 10, 5] : [25, 200, 25, 650, 25, 250, 25]}
                components={
                    components_
                }
            />
        </div>
    }

    isAddingBig = (components) => {
        const { isMobile } = this.props;

        if (isMobile) {
            return [...components,
            {
                component: SBP,
                cell_x: 2,
                collapse: 1,
                id: 1,
                componentProps: {
                    height: 70
                }

            }
            ]
        }

        return [...components,
        {
            component: SBP,
            cell_x: 2,
            collapse: 4,
            id: 1,
            componentProps: {
                height: 140
            }

        }
        ]
    }

    isAddingInfo = (components) => {
        const { Shop: { Id: shopId } = { Id: 0 }, isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'info') return [...components]

        if (isMobile) return [...components,
        {
            component: ShopInfoPlashka,
            cell_x: 2,
            collapse: 1,
            id: 4,
            componentProps: { height: 200, yPadding: 'little', _title: 'Основная информация', shopId }
        }
        ]

        return [...components,
        {
            component: ShopInfoPlashka,
            cell_x: 4,
            collapse: 1,
            id: 4,
            componentProps: { height: 200, yPadding: 'little', _title: 'Основная информация', shopId }
        }
        ]
    }

    isAddingDonate = (components) => {
        const { Shop: { Id: shopId } = { Id: 0 }, isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'info' || shopId !== 4) return [...components]

        if (isMobile) return [...components,
        {
            component: ShopDonatePlashka,
            cell_x: 2,
            collapse: 1,
            id: 69964,
            componentProps: { height: 700, yPadding: 'little', _title: 'Поддержать проект' }
        }
        ]

        return [...components,
        {
            component: ShopDonatePlashka,
            cell_x: 4,
            collapse: 1,
            id: 69964,
            componentProps: { height: 1000, yPadding: 'little', _title: 'Поддержать проект' }
        }
        ]
    }

    isAddingShopFilters = (components) => {
        const { isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'shop') return [...components]

        if (isMobile) return [...components,
        {
            component: ShopFiltersPlashka,
            cell_x: 2,
            collapse: 1,
            id: 7769,
            componentProps: { height: 200, yPadding: 'little', _title: 'Фильтры' }
        }
        ]

        return [...components,
        {
            component: ShopFiltersPlashka,
            cell_x: 2,
            collapse: 1,
            id: 7769,
            componentProps: { height: 500, yPadding: 'little', _title: 'Фильтры' }
        }
        ]
    }

    isShopBuyers = (components) => {
        const { isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'admin') return [...components]

        if (isMobile) return [...components,
        {
            component: ShopBuyersPlashka,
            cell_x: 2,
            collapse: 1,
            id: 7774,
            componentProps: { height: 1200, yPadding: 'little', _title: 'Покупатели' }
        }
        ]

        return [...components,
        {
            component: ShopBuyersPlashka,
            cell_x: 4,
            collapse: 1,
            id: 7774,
            componentProps: { height: 1200, yPadding: 'little', _title: 'Покупатели' }
        }
        ]
    }

    isShopRealAdmin = (components) => {
        const { isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'admin') return [...components]

        if (isMobile) return [...components,
        {
            component: RealShopAdminPlashka,
            cell_x: 2,
            collapse: 1,
            id: 777412,
            componentProps: { componentSelfHeight: true, height: 400, yPadding: 'little', _title: 'Товары админ' }
        }
        ]

        return [...components,
        {
            component: RealShopAdminPlashka,
            cell_x: 4,
            collapse: 3,
            id: 777412,
            componentProps: { componentSelfHeight: true, height: 400, yPadding: 'little', _title: 'Товары админ', changeAddItemModalModal: this.changeAddItemModalModal }
        }
        ]
    }

    isShopExchanger = (components) => {
        const { isMobile, Shop } = this.props;

        const { page } = this.state;
        if (!Shop || page !== 'info') return [...components];
        const { SettingsObj } = Shop;
        if (!SettingsObj || !SettingsObj.Exchange) return [...components]

        if (isMobile) return [...components,
        {
            component: ShopExchangerPlashka,
            cell_x: 2,
            collapse: 1,
            id: 57774,
            componentProps: { height: 90, yPadding: 'little', _title: 'Обменник' }
        }
        ]

        return [...components,
        {
            component: ShopExchangerPlashka,
            cell_x: 6,
            collapse: 1,
            id: 57774,
            componentProps: { height: 90, yPadding: 'little', _title: 'Обменник' }
        }
        ]
    }

    isAddingQuests = (components) => {
        const { page } = this.state;
        const { isMobile, ServerQuests } = this.props;



        if (isMobile) {
            if (page !== 'quests' || (!ServerQuests || !ServerQuests.length)) return [...components]
            return [...components,
            {
                component: PersonQuestsPlashka,
                cell_x: 2,
                collapse: 1,
                id: 73999,
                componentProps: {
                    height: 200,
                    yPadding: 'little',
                    _title: 'Задания',
                }
            }
            ]
        }

        if (page !== 'info' || (!ServerQuests || !ServerQuests.length)) return [...components]

        return [...components,
        {
            component: PersonQuestsPlashka,
            cell_x: 6,
            collapse: 1,
            id: 73999,
            componentProps: {
                height: 200,
                yPadding: 'little',
                _title: 'Задания',
            }
        }
        ]
    }

    isAddingShopItems = (components) => {
        const { isMobile, Shop } = this.props;

        const { page } = this.state;
        if (page !== 'shop') return [...components]

        let _title = 'Предметы на продажу';

        if (Shop && (Shop.Type === 1)) {
            _title = 'Витрина магазина'
        }

        if (isMobile) return [...components,
        {
            component: ShopItemsPlashka,
            cell_x: 2,
            collapse: 1,
            id: 994,
            componentProps: { height: 200, yPadding: 'little', _title }
        }
        ]

        return [...components,
        {
            component: ShopItemsPlashka,
            cell_x: 4,
            collapse: 3,
            id: 994,
            componentProps: { height: 1700, yPadding: 'little', _title }
        }
        ]
    }

    isAddingMyOrders = (components) => {
        const { isMobile, iamuser } = this.props;

        const { page } = this.state;
        if (page !== 'orders' || !iamuser) return [...components]

        if (isMobile) return [...components,
        {
            component: RealShopMyOrdersPlashka,
            cell_x: 2,
            collapse: 1,
            id: 6994,
            componentProps: { height: 1200, yPadding: 'little', _title: 'Мои заказы' }
        }
        ]

        return [...components,
        {
            component: RealShopMyOrdersPlashka,
            cell_x: 4,
            collapse: 3,
            id: 6994,
            componentProps: { height: 1700, yPadding: 'little', _title: 'Мои заказы' }
        }
        ]
    }

    isAddingKMO = (components) => {
        const { isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'results') return [...components]

        if (isMobile) return [...components,
        {
            component: ShopKMOPlashka,
            cell_x: 2,
            collapse: 1,
            id: 8994,
            componentProps: { height: 800, yPadding: 'little', _title: 'топ группы ивента' }
        }
        ]

        return [...components,
        {
            component: ShopKMOPlashka,
            cell_x: 4,
            collapse: 3,
            id: 8994,
            componentProps: { height: 800, yPadding: 'little', _title: 'топ группы ивента' }
        }
        ]
    }

    isAddingAdminButton = (components) => {
        const { page } = this.state;
        const { iAmAdmin, isMobile } = this.props;
        if (page !== 'info' || !iAmAdmin) return [...components]

        if (isMobile) return [...components,
        {
            component: ButtonPlashka,
            cell_x: 2,
            collapse: 1,
            id: 19,
            componentProps: { height: 70, yPadding: 'usual', text: 'АДМИНИСТРИРОВАТЬ', onClick: this.changeShowAdminModal }
        },
        ]

        return [...components,
        {
            component: ButtonPlashka,
            cell_x: 6,
            collapse: 1,
            id: 19,
            componentProps: { height: 70, yPadding: 'usual', text: 'АДМИНИСТРИРОВАТЬ', onClick: this.changeShowAdminModal }
        },
        ]
    }

    isAddingShopPersonMoney = (components) => {

        const { isMobile, iamuser } = this.props;
        if (!iamuser) return [...components]

        if (isMobile) return [...components,
        {
            component: ShopPersonMoneyPlashka,
            cell_x: 2,
            collapse: 1,
            id: 1119,
            componentProps: { height: 90, personId: iamuser.Id, yPadding: 'usual', _title: 'Ваши ресурсы' }
        },
        ]

        return [...components,
        {
            component: ShopPersonMoneyPlashka,
            cell_x: 6,
            collapse: 1,
            id: 1119,
            componentProps: { height: 90, personId: iamuser.Id, yPadding: 'usual', _title: 'Ваши ресурсы' }
        },
        ]
    }

    isAddingShopMoney = (components) => {

        const { isMobile, isShopMoneyReady } = this.props;
        const { page } = this.state;

        if (!isShopMoneyReady) return [...components];

        if (isMobile) {

            if (page !== 'info') return [...components];

            return [...components,
            {
                component: ShopMoneyPlashka,
                cell_x: 2,
                collapse: 1,
                id: 2119,
                componentProps: { height: 60, _title: 'Принимаемые ресурсы' }
            },
            ]
        }

        return [...components,
        {
            component: ShopMoneyPlashka,
            cell_x: 6,
            collapse: 1,
            id: 2119,
            componentProps: { height: 60, _title: 'Принимаемые ресурсы' }
        },
        ]
    }

    isAddingUi = (components) => {

        const { page } = this.state;
        const { Shop: { Id: shopId, Type, SettingsObj } = { Id: 0 }, iAmAdmin, isMobile, iamuser } = this.props;

        let Items = [
            { Title: 'Инфо', name: 'info', icon: 'info', linkTo: `/shop/${shopId}/${this.linkTo('page', 'info')}` },
            { Title: Type ? 'Витрина' : 'Магазин', name: 'shop', icon: 'shop', linkTo: `/shop/${shopId}/${this.linkTo('page', 'shop')}` },
        ]

        if (isMobile && !Type) {
            Items.push({ Title: 'Задания', name: 'quests', icon: 'quests', linkTo: `/shop/${shopId}/${this.linkTo('page', 'quests')}` });
        }


        if (iAmAdmin) {
            Items.push({ Title: Type ? 'Админка' : 'Покупатели', name: 'admin', icon: 'admin', linkTo: `/shop/${shopId}/${this.linkTo('page', 'admin')}` });
        }

        if ((Type === 1) && (iamuser)) {
            Items.push({ Title: 'Мои заказы', name: 'orders', icon: 'telega', linkTo: `/shop/${shopId}/${this.linkTo('page', 'orders')}` });
        }

        if (SettingsObj && SettingsObj.KMOResults) {
            Items.push({ Title: 'Группы', name: 'results', icon: 'results', linkTo: `/shop/${shopId}/${this.linkTo('page', 'results')}` });
        }



        if (isMobile) return [...components,
        {
            component: UiChooser,
            cell_x: 2,
            collapse: 1,
            id: 6,
            componentProps: {
                height: 100,
                yPadding: 'usual',
                tab: page,
                mobileStyle: true,
                Items
            }
        }
        ]

        return [...components,
        {
            component: UiChooser,
            cell_x: 2,
            collapse: 1,
            id: 6,
            componentProps: {
                height: Items.length === 1 ? 60 : Items.length * 45, yPadding: 'usual', tab: page, Items
            }
        }
        ]
    }

}

const ShopPageWithState = withShopState(`
Shop,
isShopReady,

isShopMoneyReady,

location,

ServerQuests,

client,
iamuser,
iAmAdmin,
isMobile
`)(ShopPage);

const ShopPageWithContent = (props) => <ShopState {...props}> {ShopPageWithState()} </ShopState>

export default ShopPageWithContent;