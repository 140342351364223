import React from 'react';

export const REGIONS_MAIN_TEXT = (count) => <>

    На портале представлены только те регионы, в которых есть официально зарегистрированная Региональная Федерация Капоэйра
    <br/>
    {`
     В данный момент количество регионов: ${count}
    `}
    <br/><br/>
    Вы можете выбрать интересующий Вас регион, кликнув на карту или на название региона.
</>

export const REGIONS_HOW_TO_ADD = {
    title: 'Как оформить региональную федерацию?',
    value: <>
    
    Если Вы хотите ознакомиться с порядком оформления региональной федерации, обратитесь по следующим контактам:
    <br/><br/>
    Хромов Николай <br/>
    <a href = 'https://wa.me/89032632245?text=По+вопросу+оформления+федерации'>8 903 263 2245</a> <br/>
    <br/>
    Григорий Криворуцкий <br/>
    <a href = 'https://wa.me/79030077472?text=По+вопросу+оформления+федерации'>8 903 007 7472</a> <br/>
    <br/>
    </>
}

export const MY_REGION = {
    title: 'Как указать регоин спортсмена?',
    value: <>
    
    Спортсмен может указать свой регион двумя способами: <br/>
    1. Указать регион в настройках своего профиля<br/>
        - зайти на страницу своего профиля<br/>
        - нажать Редактировать данные<br/>
        - выбрать вкладку Регион<br/>
        - указать свой регион<br/>
        <br/><br/>
    2. Указать группу, в которой состоит. Регионом спортсмена будет считаться регион, от которого выступает группа<br/>
        - зайти на страницу своего профиля<br/>
        - нажать Редактировать данные<br/>
        - выбрать вкладку Группа<br/>
        - указать группу из предложенного списка<br/>
        <br/><br/>
    Если у спортсмена выбрана группа, отдельно указывать регион имеет смысл только в том случае, если он отличается от региона группы.
    </>
}