import React, { Component } from 'react';
//import logo from './logo.svg';

import './style.css';
import { Subgroup } from './components/subgroup';
import { withCRMState } from '../../context';

import { Modal, message } from 'antd';
import { SubgroupEdit } from './components/edit';
import { StudentEdit } from './components/student-edit';
import PersonFastRegistration from 'src/ver2/containers/personFastRegistration';

class Subgroups extends Component {

    /**
     * props
     * subgroups
     * students
     */

    constructor() {
        super();

        this.state = {
            subgroupToEdit: null,
            studentToEdit: null,
            studentToAddSbId: null
        }
    }

    updateSubgroup = (subgroupObject) => {
        const { updateSubgroup } = this.props;
        updateSubgroup(subgroupObject)
        this.setState({
            subgroupToEdit: null
        })
    }

    addSubgroup = (subgroupObject) => {
        const { addSubgroup } = this.props;
        addSubgroup(subgroupObject);

        this.setState({
            subgroupToEdit: null
        })

    }

    deleteSubgroup = (subgroupObject) => {
        const { deleteSubgroup } = this.props;
        deleteSubgroup(subgroupObject)
        this.setState({
            subgroupToEdit: null
        })
    }

    onAddPerson = async (newperson) => {

        const { moveToNewSb, renewStudents } = this.props;

        if (!newperson || !newperson.UserId) {
            message.error('Ошибка сервера')
        }
        message.success('Ученик добавлен')

        await renewStudents();
        await moveToNewSb({ personId: newperson.UserId, subgroupFromId: 0, subgroupToId: this.state.studentToAddSbId })

        this.setState({
            studentToAddSbId: null
        })
    }

    setSubgroupToEdit = (subgroupToEdit) => {
        this.setState({ subgroupToEdit })
    }

    setStudentToEdit = (studentToEdit) => {
        this.setState({ studentToEdit })
    }

    setStudentToAddSbId = (studentToAddSbId) => {
        this.setState({ studentToAddSbId })
    }

    render() {

        const { subgroups, client, studentsBySubgroups, moveToNewSb, groupId, iamuser } = this.props;
        const { subgroupToEdit, studentToEdit, studentToAddSbId } = this.state;

        const modalTitle = subgroupToEdit && subgroupToEdit.id ? 'Редактировать подгруппу' : 'Добавить подгруппу';

        return <div className='subgroups-main'>

            <Modal footer={null} title={modalTitle} style={{ width: 'fit-content' }} onCancel={() => { this.setState({ subgroupToEdit: null }) }} visible={subgroupToEdit} >
                {subgroupToEdit ? <SubgroupEdit addSubgroup={this.addSubgroup} updateSubgroup={this.updateSubgroup} deleteSubgroup={this.deleteSubgroup} subgroupToEdit={subgroupToEdit} /> : null}
            </Modal>

            <Modal footer={null} title={'Редактировать ученика'} style={{ width: 'fit-content' }} onCancel={() => { this.setState({ studentToEdit: null }) }} visible={studentToEdit} >
                {studentToEdit ? <StudentEdit studentToEdit={studentToEdit} close ={() => { this.setState({ studentToEdit: null }) }} /> : null}
            </Modal>


            <Modal footer={null} title={'Добавить нового ученика'} style={{ width: 'fit-content' }} onCancel={() => { this.setState({ studentToAddSbId: null }) }} visible={studentToAddSbId} >
                {studentToAddSbId ? <PersonFastRegistration onAddPerson={this.onAddPerson} schoolId={iamuser.SchoolId} groupId={groupId} /> : null}
            </Modal>

            {subgroups.map((subgroup, index) => {
                const studentsBySubgroup = studentsBySubgroups[subgroup.id]
                return <Subgroup
                    onClickToEditStudent={this.setStudentToEdit}
                    setSubgroupToEdit={this.setSubgroupToEdit}
                    setStudentToAddSbId={this.setStudentToAddSbId}
                    moveToNewSb={moveToNewSb}
                    client={client}
                    key={'sbgr-' + index}
                    index={index + 1}
                    subgroup={subgroup}
                    students={studentsBySubgroup} />
            })}
            <this.EmptySubgroup />
            <Subgroup moveToNewSb={moveToNewSb} client={client} key={'sbgr-' + 0} index={0} subgroup={{ id: 0, color: 'white', title: 'Без подгруппы' }} students={studentsBySubgroups[0]} />
        </div>
    }

    EmptySubgroup = () => {
        return <div className='subgroup-empty-container' onClick={() => { this.setSubgroupToEdit({ id: 0 }) }}>
            <div className='subgroup-empty-container-add'>
                ДОБАВИТЬ ПОДГРУППУ
            </div>
        </div>
    }
}

export default withCRMState(
    `
    client,
    iamuser,
    groupId,
    subgroups,
    studentsBySubgroups,

    addSubgroup,
    updateSubgroup,
    deleteSubgroup,
    moveToNewSb,
    renewStudents

`
)(Subgroups)