// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import { groupBy } from 'lodash';
import './style.css';
// import { Tabs, message } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import PersonWrapper from 'src/ver2/components/person/personWrapper';
import ItemInventarComponent from 'src/ver2/components/item/inventar';
import { message } from 'antd';
import { ItemTypeComponent, ItemSubtypeComponent } from 'src/ver2/components/item/types';

/**
 * 
 * Это компонент инвентаря пользователя
 */

class PersonInventar extends PureComponent {
    constructor(props) {
        super()

        const { isMobile } = props;

        this.state = {
            itemWidth: 110,
            filterType: null,
            filterSubtype: null,
            itemTypes: {}
        }

        if (isMobile) {
            this.state.itemWidth = isMobile.width / 4;
        }

    }

    setItemTypes = () => {
        const { Items } = this.props;
        if (!Items) return;

        const typeKeys = Object.keys(Items);
        const itemTypes = typeKeys.reduce((memo, type) => {
            const subtypeKeys = groupBy(Items[type], 'subtype');
            return { ...memo, [type]: subtypeKeys }
        }, {})

        this.setState({ itemTypes })

    }

    componentDidMount() {
        this.setItemTypes();
    }

    componentDidUpdate({ Items }) {
        if (Items !== this.props.Items)
            this.setItemTypes();
    }


    render() {



        const { itemsStatus, isMobile } = this.props;

        if (!(itemsStatus === 'loaded')) return <div className='person_inventar_container'>
            <Loader />
        </div>


        const className = isMobile ? 'person_inventar_container person_inventar_container_mobile' : 'person_inventar_container';

        return <div className = {className}>
            <this.SubtypesBlock />
            <this.ItemsBlock />
        </div>
    }

    SubtypesBlock = () => {

        const { itemTypes, filterType } = this.state;
        const { isMobile } = this.props;
        const itemTypesKeys = Object.keys(itemTypes);

        const onFilterClickType = (type) => {
            if (filterType === type) return this.setState({ filterType: null })
            return this.setState({ filterType: type })
        }

        return <div className='person_inventar_container_filter'>
            <div className='person_inventar_container_filter_types'>
                {itemTypesKeys.map(type => <div
                    onClick={() => { onFilterClickType(type) }}
                    className={(filterType === type)
                        ? 'person_inventar_container_filter_type person_inventar_container_filter_type_selected'
                        : 'person_inventar_container_filter_type'}>
                    <ItemTypeComponent size={ isMobile ? 30 : 45} type={type} />
                </div>
                )}
            </div>
        </div>
    }

    onItemClick = (item) => {
        const { iamuser, updateMyVSettings } = this.props;
        const { name, uid, type, subtype } = item;
        if (type === 'berimbao') {
            const VSettings = iamuser.VSettings || "{}";
            if (VSettings) {
                const VSettingsObj = JSON.parse(VSettings);

                if (subtype === 'berimba' && VSettingsObj.berimbao && VSettingsObj.berimbao[subtype] === name) {
                    let VSettingsObjNew = { ...VSettingsObj };
                    delete VSettingsObjNew.berimbao;
                    const VSettingsNew = JSON.stringify(VSettingsObjNew);
                    updateMyVSettings(VSettingsNew);
                    message.info('Вы убрали предмет');
                    return;
                }
                
                if (subtype === 'cabaca' && VSettingsObj.berimbao && VSettingsObj.berimbao[subtype] === name) {
                    let VSettingsObjNew = { ...VSettingsObj };
                    delete VSettingsObjNew.berimbao[subtype];
                    const VSettingsNew = JSON.stringify(VSettingsObjNew);
                    updateMyVSettings(VSettingsNew);
                    message.info('Вы убрали предмет');
                    return;
                }

                if (subtype === 'cabaca' && ((VSettingsObj.berimbao && !VSettingsObj.berimbao.berimba) || !VSettingsObj.berimbao)) {
                    message.info('Нельзя установить кабасу без беримбы');
                    return;
                }

                let berimbao = VSettingsObj.berimbao || {};
                berimbao[subtype] = name;
                let VSettingsObjNew = { ...VSettingsObj };
                VSettingsObjNew.berimbao = berimbao;
                const VSettingsNew = JSON.stringify(VSettingsObjNew);
                updateMyVSettings(VSettingsNew);
                message.info('Настройки предмета обновлены');
            }
        }
        if (type === 'paint') {
            const VSettings = iamuser.VSettings || "{}";
            if (VSettings) {
                const VSettingsObj = JSON.parse(VSettings);

                if (VSettingsObj.paint && VSettingsObj.paint[subtype] === name) {
                    let VSettingsObjNew = { ...VSettingsObj };
                    delete VSettingsObjNew.paint[subtype];
                    const VSettingsNew = JSON.stringify(VSettingsObjNew);
                    updateMyVSettings(VSettingsNew);
                    message.info('Вы убрали предмет');
                    return;
                }

                let paint = VSettingsObj.paint || {};
                paint[subtype] = name;
                let VSettingsObjNew = { ...VSettingsObj };
                VSettingsObjNew.paint = paint;
                const VSettingsNew = JSON.stringify(VSettingsObjNew);
                updateMyVSettings(VSettingsNew);
                message.info('Настройки предмета обновлены');
            }
        }
        if (type === 'icon') {
            const VSettings = iamuser.VSettings || "{}";
            if (VSettings) {
                const VSettingsObj = JSON.parse(VSettings);

                if (VSettingsObj.icon && VSettingsObj.icon === name) {
                    let VSettingsObjNew = { ...VSettingsObj };
                    delete VSettingsObjNew.icon;
                    const VSettingsNew = JSON.stringify(VSettingsObjNew);
                    updateMyVSettings(VSettingsNew);
                    message.info('Вы убрали иконку');
                    return;
                }

                let icon = name;
                let VSettingsObjNew = { ...VSettingsObj };
                VSettingsObjNew.icon = icon;
                const VSettingsNew = JSON.stringify(VSettingsObjNew);
                updateMyVSettings(VSettingsNew);
                message.info('Вы добавили иконку');
            }
        }
    }

    ItemsBlock = () => {

        const { Items, iamuser, isMobile } = this.props;
        const { filterType } = this.state;

        const { VSettings } = iamuser;
        let VSettingsObj = null;
        if (VSettings) {
            VSettingsObj = JSON.parse(VSettings);
        } else {
            VSettingsObj = {};
        }

        const itemsInRow = isMobile ? 3 : 6;
        const minRows = isMobile ? 2 : 4;

        const { itemWidth } = this.state;

        // if (filterType === 'paint') return null;

        // let ItemsToShow = [];
        let ItemsToShow = Object.keys(Items).reduce((memo, type) => {
            if (filterType && filterType !== type) return [...memo];
            return [...memo, ...Items[type]]
        }, []);

        const len = ItemsToShow.length;

        for (let i = len; i < itemsInRow * minRows; i++) {
            ItemsToShow.push({ blankItem: true });
        }

        return <div className='person_inventar_container_items'>
            <div className='person_inventar_container_items_inside'>
                {ItemsToShow.map((Item, index) => {
                    const { name, Id, blankItem, isUsed, type, subtype } = Item;
                    if (blankItem) return <this.BlankItem key={`233333-${index}`} />

                    let clickable = null;
                    let isUser_ = null;

                    if (type === 'berimbao') {
                        clickable = [
                            {
                                iconType: 'zoom-in',
                                title: 'Осмотреть объект',
                                onClick: () => this.props.showInfoModal({ type: 'item', Item })
                            },
                            {
                                iconType: 'up-circle',
                                title: 'Применить к аватарке',
                                onClick: () => this.onItemClick(Item)
                            }]
                        if (VSettingsObj && VSettingsObj[type] && VSettingsObj[type][subtype] === name) {
                            isUser_ = true;
                        }
                    }

                    if (type === 'icon') {
                        clickable = [
                            {
                                iconType: 'zoom-in',
                                title: 'Осмотреть объект',
                                onClick: () => this.props.showInfoModal({ type: 'item', Item })
                            },
                            {
                                iconType: 'up-circle',
                                title: 'Применить к подписи',
                                onClick: () => this.onItemClick(Item)
                            }]
                        if (VSettingsObj && VSettingsObj[type] && VSettingsObj[type] === name) {
                            isUser_ = true;
                        }
                    }

                    if (type === 'paint' && subtype === 'oboi') {
                        clickable = [
                            {
                                iconType: 'zoom-in',
                                title: 'Осмотреть объект',
                                onClick: () => this.props.showInfoModal({ type: 'item', Item })
                            },
                            {
                                iconType: 'up-circle',
                                title: 'Применить обои',
                                onClick: () => this.onItemClick(Item)
                            }]
                        if (VSettingsObj && VSettingsObj[type] && VSettingsObj[type][subtype] === name) {
                            isUser_ = true;
                        }
                    }

                    if (type === 'paint' && subtype === 'cover') {
                        clickable = [
                            {
                                iconType: 'zoom-in',
                                title: 'Осмотреть объект',
                                onClick: () => this.props.showInfoModal({ type: 'item', Item })
                            },
                            {
                                iconType: 'up-circle',
                                title: 'Применить обложку',
                                onClick: () => this.onItemClick(Item)
                            }]
                        if (VSettingsObj && VSettingsObj[type] && VSettingsObj[type][subtype] === name) {
                            isUser_ = true;
                        }
                    }

                    if (type === 'paint' && subtype === 'larger') {
                        clickable = [
                            {
                                iconType: 'zoom-in',
                                title: 'Осмотреть объект',
                                onClick: () => this.props.showInfoModal({ type: 'item', Item })
                            },
                            {
                                iconType: 'up-circle',
                                title: 'Применить расширитель',
                                onClick: () => this.onItemClick(Item)
                            }]
                        if (VSettingsObj && VSettingsObj[type] && VSettingsObj[type][subtype] === name) {
                            isUser_ = true;
                        }
                    }

                    if (type === 'stuff') {
                        clickable = [{
                            iconType: 'zoom-in',
                            title: 'Осмотреть объект',
                            onClick: () => this.props.showInfoModal({ type: 'item', Item })
                        }]
                    }

                    if (type === 'nagrada') {
                        clickable = [{
                            iconType: 'zoom-in',
                            title: 'Осмотреть объект',
                            onClick: () => this.props.showInfoModal({ type: 'item', Item })
                        }]
                    }

                    return <ItemInventarComponent
                        key={'123123' + Id}
                        width={itemWidth}
                        name={name}
                        uid={Id}
                        isUsed={isUsed || isUser_}
                        clickable={clickable}
                        plashkaType='inventar'
                        Item={Item}
                    />
                })}
            </div>
        </div>
    }

    BlankItem = () => {
        const { itemWidth } = this.state;
        return <div style={{ width: itemWidth, height: itemWidth }} className='person_inventar_blank_item' />
    }

}

const PersonInventarWrapped = (props) => <PersonWrapper Wrapped={PersonInventar} personId={props.personId} withItems={true} componentProps={{ ...props }} />
export default withCapoeiraSportState('iamuser, client, isMobile, updateMyVSettings, showInfoModal')(PersonInventarWrapped);