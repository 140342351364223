import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Popover, Icon, Tooltip } from 'antd';

import './component.css';

import { MegaCover, MegaContent } from './covers/mega';
import { TrefaCover, TrefaContent } from './covers/trefa';

import { PointsCover, PointsContent } from './covers/points';
import { NightCover, NightContent } from './covers/night';

import { CloudsCover, CloudsContent } from './covers/clouds';

import { MarkinCover, MarkinContent } from './covers/markin';

import { BrazilCover, BrazilContent } from './covers/brazil';
import { BrazilCover2, BrazilContent2 } from './covers/brazil2';
import { BrazilCover3, BrazilContent3 } from './covers/brazil3';

import { SamuraiCover, SamuraiContent } from './covers/samurai';
import { FlowersCover, FlowersContent } from './covers/flowers';

import { KukinheroCover, KukinheroContent } from './covers/kukinhero';

import { PtichkaCover, PtichkaContent } from './covers/ptichka';
import { TrefafireCover, TrefafireContent } from './covers/trefafire';


/*

  Сюда мы посылаем весь элемент, и плюс к этому его ширину относительную (в wv)

*/

export class ElementCard extends Component {


    render() {


        const { sizes, element_key, is_mine, is_login, clickOnAdd, isMobile } = this.props;

        let is_selectable = true;

        if (sizes.no_select) is_selectable = false;

        if (!element_key) return null;

        let look_icon_style = 'element-card-upper-icon';
        let add_icon_style = 'element-card-upper-icon';
        let add_icon_title = 'Добавить в свои элементы';
        let add_icon_type = 'check-circle'
        if (!is_login) {
            add_icon_style = 'element-card-upper-icon-disabled';
            add_icon_title = 'Доступно после регистрации';
        }
        if (is_login && is_mine) {
            add_icon_title = 'Убрать из своих элементов';
            add_icon_type = 'close-circle';
        }

        if (isMobile) {
            look_icon_style += ' element-mobile-icon';
            add_icon_style += ' element-mobile-icon';
        }

        return <this.UpperContainer>

            <div className='element-card-upper'>
                {is_selectable &&
                    [
                        <Link to={`/technic/${element_key}`}>
                            <Tooltip title={'Подробнее'}>
                                <Icon type="zoom-in" className={look_icon_style} />
                            </Tooltip>
                        </Link>,
                        <Tooltip title={add_icon_title}>
                            <Icon onClick={() => {
                                if (!is_login || !clickOnAdd) return;
                                clickOnAdd(element_key);
                            }} type={add_icon_type} className={add_icon_style} />
                        </Tooltip>

                    ]
                }
            </div>

            <this.Content />

        </this.UpperContainer>

    }

    // это самый верхний контейнер, определяющий вид карточки, скругления, задний фон

    UpperContainer = ({ children }) => {

        const { cover_id, sizes, client, element_key, element_lvl, isMobile } = this.props;

        let show_video = true;

        if (sizes.dont_show_video) show_video = false;

        const element = client.getServerElementsAsObject()[element_key];

        const { isVideo } = element;

        let inside = null;

        /* ТО ЧТО ВЫШЕ - ОСТАЁТСЯ ДЛЯ ВСЕХ. ДАЛЕЕ МЫ ВЫБИРАЕМ ЧТО ЗАСУНУТЬ В inside  */




        if (!cover_id) {
            const { sizes } = this.props;

            let style = {};

            let div_data = {}

            if (sizes.width) {

                if (sizes.dem) {
                    style.width = `${sizes.width}${sizes.dem || ''}`
                    style.height = `${sizes.width * 1.4}${sizes.dem || ''}`
        
                    style.margin = sizes.nomargin ? 0 : `${sizes.width * 0.07}${sizes.dem || ''}`;
                    style.padding = `${sizes.width * 0.07}${sizes.dem || ''}`
        
                    style.fontSize = `${sizes.width / 8}${sizes.dem || ''}`
                    style.lineHeight = `${sizes.width / 8}${sizes.dem || ''}`;
                } else {
                    style.width = sizes.width;
                    style.height = sizes.width * 1.4;
        
                    style.margin = sizes.nomargin ? 0 : sizes.width * 0.07;
                    style.padding = sizes.width * 0.07;
        
                    style.fontSize = `${sizes.width / 8}px`;
                    style.lineHeight = `${sizes.width / 7.5}px`;
                }
            }

            if (sizes.style) {
                style = { ...style, ...sizes.style }
            }

            if (sizes.reff) {
                div_data.ref = sizes.reff;
            }

            inside = <div><div className='element-card' style={style} {...div_data} >
                {children}
            </div></div>
        }

        if (cover_id === 'usual') {
            const { sizes } = this.props;

            let style = {};

            let div_data = {}

            // if (sizes.width) {

            //     if (style.dem) {
            //         style.width = `${sizes.width}${style.dem || ''}`
            //         style.height = `${sizes.width * 1.4}${style.dem || ''}`
        
            //         style.margin = sizes.nomargin ? 0 : `${sizes.width * 0.07}${style.dem || ''}`;
            //         style.padding = `${sizes.width * 0.07}${style.dem || ''}`
        
            //         style.fontSize = `${sizes.width / 8}${style.dem || ''}`
            //         style.lineHeight = `${sizes.width / 8}${style.dem || ''}`;
            //     }
            //     style.width = sizes.width;
            //     style.height = sizes.width * 1.4;
        
            //     style.margin = sizes.nomargin ? 0 : sizes.width * 0.07;
            //     style.padding = sizes.width * 0.07;
        
            //     style.fontSize = `${sizes.width / 7}px`;
            //     style.lineHeight = `${sizes.width / 6.5}px`;
            // }

            if (sizes.width) {

                if (sizes.dem) {
                    style.width = `${sizes.width}${sizes.dem || ''}`
                    style.height = `${sizes.width * 1.4}${sizes.dem || ''}`
        
                    style.margin = sizes.nomargin ? 0 : `${sizes.width * 0.07}${sizes.dem || ''}`;
                    style.padding = `${sizes.width * 0.07}${sizes.dem || ''}`
        
                    style.fontSize = `${sizes.width / 8}${sizes.dem || ''}`
                    style.lineHeight = `${sizes.width / 8}${sizes.dem || ''}`;
                } else {
                    style.width = sizes.width;
                    style.height = sizes.width * 1.4;
        
                    style.margin = sizes.nomargin ? 0 : sizes.width * 0.07;
                    style.padding = sizes.width * 0.07;
        
                    style.fontSize = `${sizes.width / 7}px`;
                    style.lineHeight = `${sizes.width / 6.5}px`;
                }
            }

            if (sizes.style) {
                style = { ...style, ...sizes.style }
            }

            if (sizes.reff) {
                div_data.ref = sizes.reff;
            }

            let className = 'element-card element-card-usual';
            if (element_lvl && element_lvl > 0) {

                if (element_lvl === 1) {
                    className += ' element-card-usual_lvl1';
                }
                if (element_lvl === 2) {
                    className += ' element-card-usual_lvl2';
                }
                if (element_lvl === 3) {
                    className += ' element-card-usual_lvl3';
                }

            }

            inside = <div><div className={className} style={style} {...div_data} >
                {children}
            </div></div>
        }

        if (cover_id === 'mega') {
            inside = <div><MegaCover client={client} element={element} sizes={sizes} >{children}</MegaCover></div>;
        }
        if (cover_id === 'cover_trefa_2') {
            inside = <div><TrefaCover client={client} element={element} sizes={sizes} >{children}</TrefaCover></div>;
        }
        if (cover_id === 'points') {
            inside = <div><PointsCover client={client} element={element} sizes={sizes} >{children}</PointsCover></div>;
        }
        if (cover_id === 'night') {
            inside = <div><NightCover client={client} element={element} sizes={sizes} >{children}</NightCover></div>;
        }
        if (cover_id === 'clouds') {
            inside = <div><CloudsCover client={client} element={element} sizes={sizes} >{children}</CloudsCover></div>;
        }
        if (cover_id === 'markin') {
            inside = <div><MarkinCover client={client} element={element} sizes={sizes} >{children}</MarkinCover></div>;
        }
        if (cover_id === 'cover_brazil_1') {
            inside = <div><BrazilCover client={client} element={element} sizes={sizes} >{children}</BrazilCover></div>;
        }
        if (cover_id === 'cover_brazil_2') {
            inside = <div><BrazilCover2 client={client} element={element} sizes={sizes} >{children}</BrazilCover2></div>;
        }
        if (cover_id === 'cover_brazil_3') {
            inside = <div><BrazilCover3 client={client} element={element} sizes={sizes} >{children}</BrazilCover3></div>;
        }
        if (cover_id === 'samurai') {
            inside = <div><SamuraiCover client={client} element={element} sizes={sizes} >{children}</SamuraiCover></div>;
        }
        if (cover_id === 'cover_flowers_3') {
            inside = <div><FlowersCover client={client} element={element} sizes={sizes} >{children}</FlowersCover></div>;
        }
        if (cover_id === 'kukinhero') {
            inside = <div><KukinheroCover client={client} element={element} sizes={sizes} >{children}</KukinheroCover></div>;
        }
        if (cover_id === 'ptichka') {
            inside = <div><PtichkaCover client={client} element={element} sizes={sizes} >{children}</PtichkaCover></div>;
        }
        if (cover_id === 'trefafire') {
            inside = <div><TrefafireCover client={client} element={element} sizes={sizes} >{children}</TrefafireCover></div>;
        }
        return show_video && isVideo
            ?
            <Popover placement={isMobile ? 'top' : 'right'} content={this.ElementCardPoopover()}>
                {inside}
            </Popover>
            : inside;


    }

    Content = () => {
        const { cover_id } = this.props;
        if (!cover_id || cover_id === 'usual') {
            return <this.UsualContent />
        }

        if (cover_id === 'mega') {
            return <MegaContent {...this.props} />
        }
        if (cover_id === 'cover_trefa_2') {
            return <TrefaContent {...this.props} />
        }
        if (cover_id === 'points') {
            return <PointsContent {...this.props} />
        }
        if (cover_id === 'night') {
            return <NightContent {...this.props} />
        }
        if (cover_id === 'clouds') {
            return <CloudsContent {...this.props} />
        }
        if (cover_id === 'markin') {
            return <MarkinContent {...this.props} />
        }
        if (cover_id === 'cover_brazil_1') {
            return <BrazilContent {...this.props} />
        }
        if (cover_id === 'cover_brazil_2') {
            return <BrazilContent2 {...this.props} />
        }
        if (cover_id === 'cover_brazil_3') {
            return <BrazilContent3 {...this.props} />
        }
        if (cover_id === 'samurai') {
            return <SamuraiContent {...this.props} />
        }
        if (cover_id === 'cover_flowers_3') {
            return <FlowersContent {...this.props} />
        }
        if (cover_id === 'kukinhero') {
            return <KukinheroContent {...this.props} />
        }
        if (cover_id === 'ptichka') {
            return <PtichkaContent {...this.props} />
        }
        if (cover_id === 'trefafire') {
            return <TrefafireContent {...this.props} />
        }
        return <this.UsualContent />

    }


    UsualContent = () => {

        const { client, sizes, element_key, changedPoints, cardShowChanged, education_add, is_mine } = this.props;

        const element = client.getServerElementsAsObject()[element_key];

        const {
            key,
            title,
            Tier,
            Points,
        } = element;

        console.log('UsualContent', {element});

        let drawPoints = changedPoints ? changedPoints : Points;
        let stylePoints = {};
        if (changedPoints && changedPoints > Points) {
            stylePoints = {
                fontWeight: 'bold',
                color: 'green'
            }
            drawPoints = drawPoints.toFixed(2);
        }
        if (changedPoints && changedPoints < Points) {
            stylePoints = {
                fontWeight: 'bold',
                color: 'red'
            }
            drawPoints = drawPoints.toFixed(2);
        }

        let title_style = { fontSize: '110%' };

        let bottom_line_style = {};

        if (sizes.width) {

            // title_style = {
            //     marginTop: `${sizes.width / 14}vw`
            // };

            // bottom_line_style = {
            //     paddingTop: `${sizes.width / 26}vw`,
            //     paddingLeft: `${sizes.width / 9}vw`,
            //     paddingRight: `${sizes.width / 9}vw`
            // }

        }

        // let titleClassName = 'element-card-title';
        // if (education_add) titleClassName += ' solo-menu-element-div-education';

        let titleClassName = 'element-card-title_new';

        if (sizes && sizes.needed) titleClassName += ' element-card-title_new_needed';

        let mainClassName = 'element-card-content';
        if (is_mine) mainClassName += ' element-card-content_mine';

        if (!cardShowChanged)
            return <div className={mainClassName}>

                <img src={client.getTechnicLogoUrl(key)} />
                <div className={titleClassName} style={title_style}>
                    {title}
                </div>

                {(sizes.otkat !== 0 && sizes.otkat !== undefined) ? <div className='element-card-bottom_line_otkat' style={bottom_line_style}>
                    <div>ОТКАТ {sizes.otkat}</div>
                </div> : null}

                <div className='element-card-bottom_line' style={bottom_line_style}>
                    <div>TIER {Tier || 1}</div>
                    <div style={stylePoints}>{drawPoints || 1}</div>
                </div>

            </div>

        // если это режим изменения очков
        return <div className='element-card-content'>

            <img className='element-card-conten-changes-img' src={client.getTechnicLogoUrl(key)} />
            <div className='element-card-title' style={title_style}>
                {title}
            </div>

            {
                (drawPoints.toString() !== Points.toString())
                    ?
                    <div className='element-card-bottom_line-changes' style={bottom_line_style}>
                        <div style={stylePoints}>{`P:${Points} => ${drawPoints}`}</div>
                    </div>
                    :
                    <div className='element-card-bottom_line-changes' style={bottom_line_style}>
                        <div style={stylePoints}>{`P:${drawPoints || 1}`}</div>
                    </div>
            }
        </div>


    }


    ElementCardPoopover = () => {


        const { element_key, mobile } = this.props;

        const classNamePoopover = mobile ? 'element-card-poopover-mobile' : 'element-card-poopover';

        return <div className={classNamePoopover}>

            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            }} >
                <video
                    playsInline="true"
                    id="my-player"
                    class="video-js"
                    preload="auto"
                    autoPlay="true"
                    muted="true"
                    poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
                    loop="true"
                    width='100%'
                    controls=""
                    data-setup="{}">
                    <source
                        src={`https://capoeirasport.com/graphics/video_technics/${element_key}.mp4`}
                        type="video/mp4" />
                </video>
            </div>

        </div>
    }

}