import React, { PureComponent } from 'react';

import { withRoundState } from 'src/ver2/pages/round/context';
import { SoloMiniSequenceRow } from 'src/ver2/containers/challenges/components/mini-sequence-row';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class RoundISequencesPlashka
// classname: round_sequences_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RoundISequencesPlashka extends PureComponent {

    resize = (heigth) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: heigth + 100, plashkaId });
    }

    render() {

        const { UsersSequences, isUsersSequencesReady, upperStyle, client, isMobile } = this.props;
        const { width, height } = upperStyle;

        if (!isUsersSequencesReady) return <div style={{ width, height }} className='round_sequences_plashka'>
            <Loader text='Загрузка связок' />
        </div>

        if (!UsersSequences || !UsersSequences.length) return <div style={{ width, height }} className='round_sequences_plashka'>
            <span>пока нет связок</span>
        </div>

        return <div style={{ width, height }} className='round_sequences_plashka'>
            {UsersSequences.map((sequence, index) => {
                return <div className='round_sequences_plashka_sequence'>
                    <SoloMiniSequenceRow color_status key={`round_sequences_plashka_sequence${index}`} sequence={sequence} client={client} isMobile = {isMobile}/>
                </div>
            })}
        </div>
    }

}

export default withRoundState(`
UsersSequences,
isUsersSequencesReady,  
client,
isMobile
`)(RoundISequencesPlashka);