import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';
import PurposerComponent from 'src/ver2/components/purpose';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import { message } from 'antd';



// import { Link } from 'react-router-dom';

import './style.css';

// class EventContactsPlashka
// classname: event_purpose_component

/**
  Это плашка в которой мы выбираем, кому дадим плюшки

  Persons - кто уже есть
  addPerson ( Id )
  deletePerson ( index )


 */

class AddOperationPurpose extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        removeMode: false
    }

    toggleRemoveMode = () => this.setState({ removeMode: !this.state.removeMode });

    onPersonClick = (Id) => {
        const { addPerson } = this.props;
        addPerson(Id);
    }

    onRemovePersonClick = (Id) => {
        const { removeMode } = this.state;
        const { deletePerson } = this.props;

        if (!removeMode) return;

        const { Persons } = this.props;
        if (Persons) {
            for (let i = 0; i < Persons.length; i++) {
                if (String(Persons[i].Id) === String(Id)) {
                    deletePerson(i);
                    return;
                }
            }
        }
    }

    render() {

        const { Persons } = this.props;
        const { removeMode } = this.state;

        const width = 700;

        return <div className='event_purpose_component'>

            <div className='event_purpose_component_header'>Контакты</div>
            <div className='event_purpose_component_purposer'>
                {removeMode ?
                    <>Нажмите на пользователе для удаления</>
                    : <PurposerComponent mode={'contact_noclick'} onPersonClick={this.onPersonClick} allreadyPersons={Persons} />}
            </div>

            <TrefaLayout greedsX={[5, width - 10, 5]}
                components={[
                    {
                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 8888,
                        componentProps: { height: 100, Persons, autoSize: true, mode: removeMode ? 'contact_noclick' : 'contact', onPersonClick: this.onRemovePersonClick }
                    }
                ]}
            />

            <br />
            <ButtonPlashka text={removeMode ? 'ВКЛЮЧИТЬ РЕЖИМ ДОБАВЛЕНИЯ' : 'ВКЛЮЧИТЬ РЕЖИМ УДАЛЕНИЯ'} upperStyle={{ width: 270, height: 50 }} onClick={this.toggleRemoveMode} />


        </div>
    }

}


export default withCapoeiraSportState('client')(AddOperationPurpose)