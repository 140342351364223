import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import Loader from 'src/ver2/components/loader';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import {
    addBlankNotCircleHistory,
    addBlankCircleHistory,
    setCompetitorsThrueBrikets
} from 'src/ver2/pages/event/plashki/shadule/Brikets/helpers';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventContactsPlashka
// classname: storys_sender_component

/**
  
 */

class StorysSender extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        loadingPercent: 0,
        status: 'done'
    }

    onSendToServer = async () => {

        const { client, Event, Categorys } = this.props;
        this.setState({ status: 'составление: ', loadingPercent: 0 });

        const competitionId = Event.ChampionshipId;
        let resultStorys = [];

        for (let i = 0; i < Categorys.length; i++) {

            
            this.setState({ loadingPercent: ((i / Categorys.length) * 100).toFixed() });

            // что нам надо на выходе получить
            let story = []; // мы созда1м свой объект story, он чуть отличается от того, что в БД
            let competitors = []; // а это competitors_first плюс "пустые" жребии, которых не достаёт

            const category = Categorys[i];


            const selectedCategory = category.Id;

            let story_ = await client.briketsGetStory({ competitionId, selectedCategory });
            competitors = await client.briketsFirstCompetitiors({ competitionId, selectedCategory });

            const thisCompetitors = await client.getCompetitors(competitionId, selectedCategory);

            let resStory = [];
            let resCompetitors = [];

            // если это НЕ КРУГОВАЯ
            if (!category.IsCircled) {
                story = addBlankNotCircleHistory({ category, story_ })
                const setCompetitorsThrueBrikets__ = setCompetitorsThrueBrikets({ competitors, category, story });

                resCompetitors = setCompetitorsThrueBrikets__.competitors;
                resStory = setCompetitorsThrueBrikets__.story;
            }

            // КРУГОВАЯ
            if (category.IsCircled) {
                if (competitors && competitors.length > 0 && story_) {
                    const addBlankCircleHistory_ = addBlankCircleHistory({ category, story_, competitors });
                    resCompetitors = competitors;
                    resStory = addBlankCircleHistory_.story;
                }
            }

            for ( let j = 0; j < resStory.length; j++) {
                const story = resStory[j];
                // тут Red_Id и Blue_Id - это ЖРЕБИИ!
                const {
                    StoryId,
                    Red_Id,
                    Blue_Id
                } = story;

                // теперь нам нужно найти красного и синего компетитора
                const Red = resCompetitors.find ( x => x.Jrebiy === Red_Id);
                const Blue = resCompetitors.find ( x => x.Jrebiy === Blue_Id);

                if (!Red || !Red.Id || !Blue || !Blue.Id) continue;

                const RedId_ = thisCompetitors.find ( x => x.Id === Red.Id );
                const BlueId_ = thisCompetitors.find ( x => x.Id === Blue.Id );

                if (!RedId_ || !BlueId_) continue;

                resultStorys.push ({ 
                    StoryId,
                    RedId: RedId_.UserId,
                    BlueId: BlueId_.UserId
                 });

            }

        }

        console.log ('resultStorys', { resultStorys });
        this.setState({ status: 'отправка: ', loadingPercent: 0 });

        for ( let i = 0; i < resultStorys.length; i++ ) {
            await client.addStoryCompetitor({...resultStorys[i]});
            this.setState({ loadingPercent: ((i / resultStorys.length) * 100).toFixed() });
        }
        
        this.setState({ status: 'done' });

    }



    render() {

        const { Event, Categorys } = this.props;
        const { status, loadingPercent } = this.state; 

        if (!Event || !Categorys) return <div className='storys_sender_component'>
            <Loader text='категории не загружены' />
        </div>

        const { Type } = Event;

        if (Type !== 'championship') {
            return <div className='storys_sender_component'>
                Это НЕ СОРЕВНОВАНИЕ
            </div>
        }

        const disabled = status !== 'done';
        const text = disabled ? `${status} ${loadingPercent}% ` : 'ОТПРАВИТЬ';

        return <div className='storys_sender_component'>
            <span>Всего категорий - {Categorys.length}</span>
            <br />
            <ButtonPlashka upperStyle={{ width: 170, height: 45 }} onClick={this.onSendToServer} disabled = {disabled} text={text} />
        </div>
    }

}


export default withEventState(
    `
    Event,
    Championship,
    Categorys, 
    iamuser,
    client
    `
)(StorysSender)