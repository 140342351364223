import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import TPLan from 'src/ver2/pages/technic/components/plan';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class TPLanPlashka
// classname: t_plan_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class TPLanPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    /*
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    render() {

        const { upperStyle } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='t_plan_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='t_plan_plashka'>
            <TPLan/>
        </div>
    }

}

export default withCapoeiraSportState(`
client
`)(TPLanPlashka)