import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import RegionsMap from 'src/ver2/components/regions/map';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class RegionMapPlashka
// classname: region_map_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RegionMapPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { upperStyle, region } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='region_map_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='region_map_plashka'>
            <RegionsMap width={width} withOutStick region={region} scvShiftY = {-50} />
        </div>
    }

}

export default withCapoeiraSportState(`
client,
iamuser,
`)(RegionMapPlashka)