import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withCapoeiraSportState } from 'src/ver2/context';
import { sortBy } from 'lodash';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import Loader from 'src/ver2/components/loader';
/*import GroupRect from 'src/ver2/components/group/groupRect';
import SchoolRect from 'src/ver2/components/school/schoolRect'
import PersonAvatar from 'src/ver2/components/person/avatar';
*/


import './style.css';

class RaitingSubjectTableLayout extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loadedRating: null
        }

        // по сути кэш
        this.raitingsSaved = {}

    }

    get season() {
        const { season } = this.props;
        return season || '2022-2023';
    }

    setHeight = (height) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height, plashkaId });
    }

    componentDidMount() {
        this.loadRaiting();
    }

    componentDidUpdate({ School, season, type }, { loadedRating }) {

        if ((!School && this.props.School) || season !== this.props.season || type !== this.props.type) {
            this.setState({ loadedRating: 'loading' });
            this.loadRaiting();
        }
        if (loadedRating !== this.state.loadedRating) {
            if (!this.state.loadedRating) {
                this.setHeight(50);
            }
            if (this.state.loadedRating === 'loading') {
                this.setHeight(50);
            } else {
                if (this.state.loadedRating.length) {
                    this.setHeight(60 + this.state.loadedRating.length*35);
                }
            }
        }
    }

    raitingKeyed = ({ schoolId, season, type }) => `${schoolId}_${season}_${type}`;

    loadRaiting = async () => {
        const { client, School, type } = this.props;
        const id = School ? School.Id : 0;
        if (!id) return null;
        const season = this.season;

        // берём из кэша
        if (this.raitingsSaved[this.raitingKeyed({ schoolId: id, season, type })]) {
            this.setState({ loadedRating: this.raitingsSaved[this.raitingKeyed({ schoolId: id, season, type })] })
            return;
        }

        let raitings = [];
        const categorys = Object.keys(client.CategoryNames);

        // подгружаем все нужные рейтинги категорий
        for (let i = 0; i < categorys.length; i++) {
            const raiting = await client.getSubjectRaitingFromServer({ subject: 'school', id, season, type, as_sum: true, category: categorys[i] });
            if (raiting && raiting[0]) {
                raitings.push(raiting[0]);
            }
        }

        // кжшируем
        this.raitingsSaved[this.raitingKeyed({ schoolId: id, season, type })] = sortBy(sortBy(raitings, 'AllScores').reverse(), 'Place');

        this.setState({ loadedRating: sortBy(sortBy(raitings, 'AllScores').reverse(), 'Place') })

        // мы последовательно берём весь рейтинг по всем категориям
    }

    render() {

        const { Person, season, type } = this.props;

        const Raiting = this.state.loadedRating;

        // const { width, height } = upperStyle;

        return <div className='raiting_subject_plashka'>
            {
                (Raiting && Raiting !== 'loading' && Raiting.length > 0)
                    ?
                    [
                        <this.RaitingHeader />,
                        Raiting.map((currentRaiting, index) => {
                            return <this.OneRaitingBlock key={'prp' + (Person ? Person.Id : '0') + season + '-' + type + '-' + index} currentRaiting={currentRaiting} />
                        })
                    ]
                    :
                    (Raiting === 'loading')
                        ?
                        [
                            <this.RaitingHeader />,
                            <Loader />
                        ]
                        :
                        'Не было участия в соревнованиях'
            }
            <div id = 'raiting_subject_to_scroll' />
        </div>

    }

    RaitingHeader = () => {

        const height = 25;

        let koef = 0.7;
        if (this.props.isMobile) koef = 0.4;

        const fontSize = height * koef;

        return <div className='raiting_subject_plashka__oneraiting raiting_subject_plashka__oneraiting_header'>
            <div className='raiting_subject_plashka__oneraiting_place'>
                <div style={{ fontSize }} className='raiting_subject_plashka__oneraiting_place_inside'>
                    Место
                </div>
            </div>
            <div style={{ fontSize }} className='raiting_subject_plashka__oneraiting_category'>
                <span>Категория</span>
            </div>
            <div style={{ fontSize }} className='raiting_subject_plashka__oneraiting_scores'>
                <span>Очки</span>
            </div>
        </div>
    }

    OneRaitingBlock = ({ currentRaiting }) => {
        const { client, category, linkTo } = this.props;
        const { PlaceName, Place, Category, AllScores } = currentRaiting;

        const height = 25;


        let koef = 0.75;
        if (this.props.isMobile) koef = 0.55;

        const fontSize = height * koef;


        let className = 'raiting_subject_plashka__oneraiting raiting_subject_plashka__oneraiting_row ';
        if (category === Category) {
            className += 'raiting_subject_plashka__oneraiting_row_selected';
        }

        return <Link to={`./raiting${linkTo('category', Category)}`}>
            <div className={className}
            >
                <div className='raiting_subject_plashka__oneraiting_place'>
                    <div className='raiting_subject_plashka__oneraiting_place_inside'>
                        <PlaceRect {...{ height, Place, PlaceName }} />
                    </div>
                </div>
                <div style={{ fontSize }} className='raiting_subject_plashka__oneraiting_category'>
                    <span>{client.CategoryNames[Category]}</span>
                </div>
                <div style={{ fontSize }} className='raiting_subject_plashka__oneraiting_scores'>
                    <span>{AllScores}</span>
                </div>
            </div >
        </Link>
    }

}


export default withCapoeiraSportState('iamuser, getText, client, isMobile')(RaitingSubjectTableLayout);