import React, { Component } from 'react';
import { groupBy, sortBy } from 'lodash';


import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';
// import { SchoolLogo } from '../SchoolLogo';
// import { GroupLogo } from '../GroupLogo';

import { Icon, Switch } from 'antd';

import './component.css'

export class Statistics extends Component {


  constructor(props) {
    super(props);
    const { client, event, isMobile } = props;
    this.state = { groupsMode: false };

    const statisticsFunction = (event.Type === 'championship') ?
      () => client.getCompetittionStatistics(event.ChampionshipId) :
      () => client.getParticipatorStatistic(event.Id)

    statisticsFunction().then((result) => {

      if (!result || result.length === 0) return;

      let statistics = [];

      const resultBySchools = groupBy(result, 'SchoolId');

      let total = 0;
      let maximum = 0;

      Object.keys(resultBySchools).reduce((memo, key) => {

        const nowSchool = resultBySchools[key];

        statistics.push({
          Count: 0,
          SchoolId: key,
          Groups: []
        });

        let Count = 0;

        let Groups = [];

        for (let i = 0; i < nowSchool.length; i++) {
          if (nowSchool[i].GroupId !== null) {
            Count += nowSchool[i].Count;
            Groups.push({
              Count: nowSchool[i].Count,
              GroupId: nowSchool[i].GroupId
            })
          } else {
            Count += nowSchool[i].Count;
            Groups.push({
              Count: nowSchool[i].Count,
              GroupId: 0
            })
          }
        }

        total += Count;
        if (Count > maximum) maximum = Count;

        statistics[statistics.length - 1].Count = Count;
        statistics[statistics.length - 1].Groups = sortBy(Groups, item => { return item.Count }).reverse();

        return key;
      }, {});


      if (isMobile) {
        this.props.setHeight(
          240 + 50 * statistics.length
        )
      } else {
        this.props.setHeight(
          240 + 70 * statistics.length
        )
      }



      this.setState({ statistics: sortBy(statistics, item => { return item.Count }).reverse(), total, maximum: maximum * 1.2 });


    });
  }

  // componentDidMount () {

  //   this.props.setHeight(
  //     800
  //   )

  //   return;

  //   const { statistics } = this.state;
  //   const { isMobile } = this.props;

  //   if (!statistics) return;

  //   if (!isMobile) {
  //     this.props.setHeight(
  //         800
  //       )
  //     return;
  //   }


  // }

  switchGroup = () => {
    const groupsMode = !this.state.groupsMode;
    const { statistics } = this.state;
    const { isMobile } = this.props;

    const elementHeight = isMobile ? 50 : 70;

    if (groupsMode) {

      const allLength = Object.keys(statistics).reduce((memo, key) => {
        let thisHeight = elementHeight;
        thisHeight += Object.keys(statistics[key].Groups).length * elementHeight;
        return memo + thisHeight
      }, 0)
      this.props.setHeight(
        240 + allLength
      )
    } else {
      this.props.setHeight(
        240 + elementHeight * Object.keys(statistics).length
      )
    }

    this.setState({ groupsMode });

  }

  render() {

    const { statistics, total, groupsMode, maximum } = this.state;
    const { isMobile } = this.props;
    if (!statistics) return <div> Статистика формируется </div>

    return <div className='statistics-main-div'>

      <div class='statistics-main-total-div'>
        <div>Общее количество предварительно зарегистрированных участников: </div>
        <Icon style={
          isMobile ? { marginLeft: 3, marginRight: 5, fontSize: 15 } : { marginLeft: 10, marginRight: 20, fontSize: 25 }} type="user" />
        <div> {total} </div>
      </div>


      <Switch size='small'
        checked={groupsMode}
        onChange={this.switchGroup}
      /> показывать группы
        <br />


      {Object.keys(statistics).reduce((memo, key) => {

        const barWidth = 100 * statistics[key].Count / total;
        const barWidthRelative = 100 * statistics[key].Count / maximum;
        const barWidthInt = barWidth.toFixed(0);
        const barWidthIntRelative = barWidthRelative.toFixed(0);

        const schoolStyle = groupsMode ? { marginTop: 30 } : {};

        memo.push(
          <div className='statistics-main-school-div' style={schoolStyle}>
            <div className='statistics-main-school-logo-div'>
              <SchoolPlate width={isMobile ? 100 : 200} height={isMobile ? 30 : 50} SchoolId={statistics[key].SchoolId} />
            </div>
            <div className='statistics-main-school-statistics-div'>
              <div style={{ width: barWidthIntRelative + '%' }} className='statistics-main-school-statistics-bar-div'>
                <div>{barWidthInt + ' %'}</div>
              </div>
            </div>
            <div className='statistics-main-school-count-div'>
              <Icon style={
                isMobile ? { marginLeft: 3, marginRight: 5, fontSize: 14 } :
                  { marginLeft: 5, marginRight: 20, fontSize: 20 }
              } type="user" />
              <div> {statistics[key].Count} </div>
            </div>
            <br />
          </div>
        );

        // Подцепляем группы

        if (groupsMode)
          memo.push(Object.keys(statistics[key].Groups).reduce((memoGroup, keyGroup) => {

            const barWidthGroup = 100 * statistics[key].Groups[keyGroup].Count / statistics[key].Count;
            const barWidthIntGroup = barWidthGroup.toFixed(0);

            memoGroup.push(
              <div className='statistics-main-group-div'>
                <div className='statistics-main-group-inside-div'>
                  <div className='statistics-main-group-inside-logo-div'>
                    <GroupPlate width={isMobile ? 100 : 170} height={isMobile ? 25 : 40} GroupId={statistics[key].Groups[keyGroup].GroupId} />
                    {/* <GroupLogo client = {client} color = 'white' group = {client.getGroupById(statistics[key].Groups[keyGroup].GroupId)} /> */}
                  </div>
                  <div className='statistics-main-group-inside-statistics-div'>
                    <div style={{ width: barWidthIntGroup + '%' }} className='statistics-main-group-inside-statistics-bar-div'>
                      <div>{barWidthIntGroup + ' %'}</div>
                    </div>
                  </div>
                  <div className='statistics-main-group-inside-count-div'>
                    <Icon style={
                      isMobile ? { marginLeft: 3, marginRight: 5, fontSize: 12 } :
                        { marginLeft: 5, marginRight: 20, fontSize: 18 }}
                      type="user" />
                    <div> {statistics[key].Groups[keyGroup].Count} </div>
                  </div>
                  <br />
                </div>
              </div>
            );
            return memoGroup;

          }, []));

        return memo;

      }, [])}

    </div>
  }

}