import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import TrefaText from 'src/ver2/components/trefaText';

import Loader from 'src/ver2/components/loader';
import { Tabs, Progress } from 'antd';
import LittlePerson from 'src/ver2/components/person/little';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventInfoFizuhaPlashka
// classname: event_infofizuha_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

const TabPane = Tabs.TabPane;

const TYPES_VALUE = {
    otz: 100000,
    pre: 200000
}

class EventInfoFizuhaPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Event, upperStyle, Discription, Fizuha, isMobile, innerId } = this.props;
        const { width, height } = upperStyle;

        if (!Event || !Discription || !Fizuha) return <div style={{ width, height }} className='event_infofizuha_plashka'>
            <Loader />
        </div>

        console.log('Fizuha', { Fizuha });
        const {
            UsersFizuha,
            SchoolFizuha,
            GroupFizuha,
            AllFizuha
        } = Fizuha;
        const progressWidth = isMobile ? isMobile.width * 0.3 : 200;

        const Header = ({ text = '' }) => <div className='event_infofizuha_plashka_header'>
            <div className='event_infofizuha_plashka_header_title'>
                {text}
            </div>
            <div className='event_infofizuha_plashka_suffix'>
                <div className='event_infofizuha_plashka_suffix_one'>
                    ОТЖ
                </div>
                <div className='event_infofizuha_plashka_suffix_one'>
                    ПРЕСС
                </div>
            </div>
        </div>

        return <div style={{ width, height }} className='event_infofizuha_plashka'>
            <div className='event_infofizuha_plashka_discription' id={innerId}>

                <Tabs centered={true}>
                    <TabPane key='1' tab='Общая информация'>
                        <div className='event_infofizuha_plashka_content'>
                            <div className='event_infofizuha_plashka_content_title'>ОБЩАЯ ЦЕЛЬ</div>
                            <div className='event_infofizuha_plashka_progresses'>
                                <this.ProgressBar width={progressWidth} type='otz' value={AllFizuha.otz} />
                                <this.ProgressBar width={progressWidth} type='pre' value={AllFizuha.pre} />
                            </div>

                            <TrefaText trefaText={Discription.Body} onRender={this.onTrefaTextRender} />
                        </div>
                    </TabPane>
                    <TabPane key='2' tab='Участники'>
                        <Header text='УЧАСТНИК' />
                        {
                            UsersFizuha.map(person => {
                                return <LittlePerson
                                    height={isMobile ? 40 : 70}
                                    key={'plt' + person.UserId}
                                    // personId={person.Id}
                                    DirectPerson={{ ...person }}
                                    personsDirect
                                    Suffix={<div className='event_infofizuha_plashka_suffix'>
                                        <div className='event_infofizuha_plashka_suffix_one'>
                                            {person.otz}
                                        </div>
                                        <div className='event_infofizuha_plashka_suffix_one'>
                                            {person.pre}
                                        </div>
                                    </div>}
                                />
                            })
                        }
                    </TabPane>
                    <TabPane key='3' tab='Школы'>
                        <Header text='Школа' />
                        {
                            SchoolFizuha.map(({ SchoolId, otz, pre }) => {

                                return <div style={{ height: isMobile ? 40 : 70 }} className='event_infofizuha_plashka_soc_row'>
                                    <div className='event_infofizuha_plashka_soc_soc'>
                                        <SchoolPlate width={isMobile ? 100 : 200} height={isMobile ? 30 : 50} SchoolId={SchoolId} />
                                    </div>
                                    <div className='event_infofizuha_plashka_suffix'>
                                        <div className='event_infofizuha_plashka_suffix_one'>
                                            {otz}
                                        </div>
                                        <div className='event_infofizuha_plashka_suffix_one'>
                                            {pre}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </TabPane>
                    <TabPane key='4' tab='Группы'>
                        <Header text='Группа' />
                        {
                            GroupFizuha.map(({ GroupIamInId, otz, pre }) => {

                                return <div style={{ height: isMobile ? 40 : 70 }} className='event_infofizuha_plashka_soc_row'>
                                    <div className='event_infofizuha_plashka_soc_soc'>
                                        <GroupPlate width={isMobile ? 100 : 200} height={isMobile ? 30 : 50} GroupId={GroupIamInId} />
                                    </div>
                                    <div className='event_infofizuha_plashka_suffix'>
                                        <div className='event_infofizuha_plashka_suffix_one'>
                                            {otz}
                                        </div>
                                        <div className='event_infofizuha_plashka_suffix_one'>
                                            {pre}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </TabPane>
                </Tabs>
            </div>
        </div>

    }

    ProgressBar = ({ type, value, width }) => {
        return <div className='event_infofizuha_progress'>
            <Progress width={width} type="circle" percent={(100 * value / TYPES_VALUE[type])} format={() => <div><img style={{ width: width * 0.6 }} src={`/../images/ver2/scores/money/${type}.png`} /></div>} />
            <div className='event_infofizuha_progress_text'>
                {`${value} / ${TYPES_VALUE[type]}`}
            </div>
        </div>
    }


}

export default withEventState(`
Event,  
client,
isMobile,
Discription,
Fizuha,
isFizuhaReady,
isDiscriptionReady
`)(EventInfoFizuhaPlashka);