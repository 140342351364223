import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import Medal from 'src/ver2/components/medal';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventResultsMiniPersonPlashka
// classname: event_results_mini_person_plashka

/**
  Это маленькая плашка, показывающая ваш результат
 */

class EventResultsMiniPersonPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Event, iAmCompetitor, upperStyle, client } = this.props;
        const { width, height } = upperStyle;

        if (!iAmCompetitor || !iAmCompetitor.Place) return <div style={{ width, height }} className='event_results_mini_person_plashka'>
            <Loader />
        </div>

        const { Place, CategoryId } = iAmCompetitor;

        let bg = '';

        switch (iAmCompetitor.Place.toString()) {
            case '1': bg = 'gold'; break;
            case '2': bg = 'silver'; break;
            case '3': bg = 'bronze'; break;

            default: bg = null;
        }

        return <div style={{ width, height }} className='event_results_mini_person_plashka'>
            {bg ? 
            <Medal
                key={'med-in-comp'}
                Id={'m-i-c'}
                eventId={Event.Id}
                drawWidth={155}
                bg={bg}
                noRotate={false}
                shape={'circle'}
                develop={true}
            /> : null}
            <this.YoutResultText />
        </div>
    }

    YoutResultText = () => {
        const { getCategory, iAmCompetitor, client: { getPlaceName } } = this.props;
        const { Place, CategoryId } = iAmCompetitor;

        const myCategory = getCategory(CategoryId);

        return <div className= 'event_results_mini_person_plashka_result'>
            {`${getPlaceName(Place)} в категории`} <br/>
            {myCategory ? myCategory.Name : 'не определено' }
        </div>
    }

}

export default withEventState(`
Event,
Categorys, 
Results,
isResultsReady,

iAmCompetitor,

getCategory,

client,
iamuser,
`)(EventResultsMiniPersonPlashka)