import React, { Component } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import EventPlashka from 'src/ver2/plashki/event/usual';
import CalendarPlashka from 'src/ver2/plashki/calendar';
import StatisticsPlashka from 'src/ver2/plashki/statistics';
import EventLittleListPlashka from 'src/ver2/plashki/event/littleList';
import NewsLittleListPlashka from 'src/ver2/plashki/news/littleList';
import VideoLittlePlashka from 'src/ver2/plashki/video/little';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
// import NewsSomePlashka from 'src/ver2/plashki/news/some';
import SomeSliderPlashka from 'src/ver2/plashki/news/slider';
import KMOQuestPLashka from 'src/ver2/plashki/news/kmo_quest';
import OwlQuestPlashka from 'src/ver2/plashki/news/owl_quest';
import SeqMonitorPlashka from 'src/ver2/plashki/sequencesMonitor';
import { TrefaLayout } from 'src/ver2/containers/layout';
import Helmet from "react-helmet";
import { Modal } from 'antd';

import { groupBy, orderBy } from 'lodash';

class MainPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadedEvents: null,
            lastEvents: null,
            lastNews: null,
            eventsForCalendar: null,
            birthdays: [],
            showEvents: [78, 84, 76, 83]
        }

        const videoId = 253 + Math.round((3900 - 253) * Math.random());
        this.state.videoId = videoId;

    }

    startParams = () => {
        const { location } = this.props;
        const { search } = location;

        // парсим search
        let searchObj = {
            ...this.searchDefault
        }

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        return searchObj;
    }

    componentDidMount() {
        this.processBirthdays();
        if (this.props.eventsLoaded) {
            this.processLastEvents(this.props.client.getAllEvents());
            this.processCalendar();
        }
        this.loadNewNews();

        const { client } = this.props;
        client.getNewNews({ subject: 'zagla' }).then((result) => {
            if (result[0]) {
                const showEvents = JSON.parse(result[0].Title);
                this.setState({ showEvents });
            }
        });


        const { login, password } = this.startParams();
        if (login && password) {
            this.loginByEnter(login, password);
        }

    }

    loginByEnter = async (loginValue, passwordValue) => {
        await this.props.logout();
        const sucess = await this.props.login(loginValue, passwordValue);
        if (sucess) {
            Modal.success({
                content: <div>
                    <b>ВАШИ ЛОГИН И ПАРОЛЬ:</b><br/><br/>
                    {decodeURI(loginValue)}<br />
                    {passwordValue}
                </div>
            })
        }
    }


    //                prevProps, prevState
    componentDidUpdate({ eventsLoaded, birthdaysLoaded, newsLoaded, iamuser }, { lastNews }) {
        if (this.props.eventsLoaded && !eventsLoaded) {
            this.processLastEvents(this.props.client.getAllEvents());
            this.processCalendar();
        }
        if (this.props.birthdaysLoaded && !birthdaysLoaded) {
            this.processBirthdays();
        }
        if (!iamuser && this.props.iamuser) {
            this.loadNewNews();
            this.processCalendar();
        }

        // мы всё прогрузили, можно чё-нить показать
        const allLoadedNow = (this.state.lastNews && this.props.eventsLoaded && this.props.birthdaysLoaded)
        const allLoadedWas = (lastNews && eventsLoaded && birthdaysLoaded)
        if (!allLoadedWas && allLoadedNow) {
            const { videoId, newsId } = this.startParams();

            if (videoId) {
                this.props.showInfoModal({ type: 'video', videoId });
            }
            if (newsId && Number(newsId)) {
                const OneNews = this.state.lastNews.find(x => x.Id === Number(newsId));
                if (OneNews)
                    this.props.showInfoModal({ type: 'eventNewNews', OneNews });
            }
        }

    }

    processCalendar = async () => {

        const { iamuser } = this.props;

        const Events = this.props.client.getAllEvents();
        let ResultEvents = [];
        for (let i = 0; i < Events.length; i++) {

            if (Events[i].NotAnnounsed) {
                continue;
            }
            if (Events[i].Settings) {
                const SettingObj = JSON.parse(Events[i].Settings);
                // смотрим, когда мы можем не увидеть мероприятие школы в календаре
                if (SettingObj.PermissionMain && SettingObj.PermissionMain.FaceFrom === 'school' && SettingObj.PermissionMain.IsClosed) {
                    if (!iamuser) continue;
                    if (iamuser && iamuser.SchoolId !== SettingObj.PermissionMain.FaceId) continue;
                }
                if (SettingObj.PermissionMain && SettingObj.PermissionMain.FaceFrom === 'group' && SettingObj.PermissionMain.IsClosed) {
                    if (!iamuser) continue;
                    if (iamuser && iamuser.GroupIamInId !== SettingObj.PermissionMain.FaceId && iamuser.GroupId !== SettingObj.PermissionMain.FaceId) continue;
                }
            }
            ResultEvents.push(Events[i]);
        }

        const eventsForCalendar = groupBy(ResultEvents, 'Date');
        this.setState({ eventsForCalendar })
    }

    // NEWNEWS //////////////////////////////////////////
    loadNewNews = async () => {
        const { client, iamuser } = this.props;
        // const lastNewsEvents = await client.getNewNews({ subject: 'event' });
        // const lastNewsPortal = await client.getNewNews({ subject: 'portal' });
        const lastNewsEvents = await client.getNewNews({ subject: 'event_portal', limit: 8 });
        let lastNewsSchool = [];

        if (iamuser && iamuser.SchoolId) {
            lastNewsSchool = await client.getNewNews({ subject: 'school', subjectId: iamuser.SchoolId });
        }

        let lastNews = [...lastNewsEvents, ...lastNewsSchool];
        lastNews = orderBy(lastNews, ['Date'], 'desc');

        this.setState({ lastNews });

        // return NewNews;
    }

    processBirthdays = async () => {

        const { client } = this.props;
        const birthdays = client.state.birthdays;
        this.setState({ birthdays })
    }

    processLastEvents = async (Events) => {

        const { client } = this.props;

        if (!Events) return;
        const lookedEvents = [...Events].reverse();

        let lastEvents = [];
        for (let i = 0; i < lookedEvents.length; i++) {
            const event = lookedEvents[i];
            if (event.Type === 'championship') {
                const championship = await client.getChampionshipFromServer(event.ChampionshipId);
                if (championship && championship.Id && !championship.Batizado && championship.Finished && championship.ShowResults) {
                    lastEvents.push({ ...event });
                }
            }
            if (lastEvents.length >= 3) {
                break;
            }

        }

        this.setState({ lastEvents });

    }

    get isLoaded() {
        return this.state.loadedEvents;
    }

    render() {

        // const width = 1200;
        const { lastEvents, lastNews, eventsForCalendar, birthdays, showEvents, videoId } = this.state;
        const { isMobile } = this.props;

        if (isMobile) {
            const { width } = isMobile;
            return <div className='main_page'>
                <Helmet
                    title="Портал Capoeirasport: капоэйра, соревнования, роды, спорт"
                    meta={[
                        { "name": "description", "property": "description", "content": "Портал Capoeira sport является разработкой Федерации капоэйра России, освещает основные спортивные события в мире Российской капоэйра, позволяет удобно регистироваться на мероприятия, собирать статистика по капоэйристам России" },
                        { "name": "og:image", "property": "og:image", "content": "https://capoeirasport.com/images/ver2/logo.png" },
                        { "name": "og:title", "property": "og:title", "content": "Главная страница" },
                        { "name": "og:type", "property": "og:type", "content": "main" },
                        { "name": "og:url", "property": "og:url", "content": `https://capoeirasport.com/` }
                    ]}
                />
                <TrefaLayout greedsX={[5, width - 10, 5]}
                    components={[
                        {
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 10 }
                        },
                        {
                            component: StatisticsPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { lowTitle: true, height: 60 }
                        },
                        {
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 4 }
                        },

                        // {
                        //     component: NewsSomePlashka,
                        //     cell_x: 2,
                        //     collapse: 1,
                        //     componentProps: {
                        //         height: 120, ImgPart: <img src="/../images/ver2/kmo_event.png" />,
                        //         Title: 'Ивент Кубка Москвы',
                        //         Text: 'Ивент в честь Кубка Москвы 2022 окончен! Эксклюзивные предметы за ресурсы ивента открыты для покупки!', LinkTo: '/shop/5'
                        //     }
                        // },
                        // {
                        //     component:KMOQuestPLashka,
                        //     cell_x: 2,
                        //     collapse: 1,
                        //     componentProps: {
                        //         height: 120,
                        //         _title: 'ТОП группы ивента'
                        //     }
                        // },

                        // {
                        //     component: NewsSomePlashka,
                        //     cell_x: 2,
                        //     collapse: 1,
                        //     componentProps: {
                        //         height: 120, ImgPart: <img src="/../images/ver2/shark.png" />,
                        //         Title: 'Поддержка проекта',
                        //         Text: 'Уважаемые пользователи! Появилась возможность материальной поддержки портала, пройдите в новый магазин и оцените Акулу-капоэйриста!', LinkTo: '/shop/4'
                        //     }
                        // },
                        {
                            component: SomeSliderPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: {
                                height: 120, 
                            }
                        },
                        {
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 12 }
                        },
                        // {
                        //     component: OwlQuestPlashka,
                        //     cell_x: 2,
                        //     collapse: 1,
                        //     componentProps: { height: 130 }
                        // },
                        {
                            component: EventPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 130, eventId: showEvents[0], yPadding: 'little' }
                        },
                        {
                            component: EventPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 110, eventId: showEvents[1], yPadding: 'little' }
                        },
                        {
                            component: SeqMonitorPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 120, _title: 'Cвязки весеннего челленджа', yPadding: true, eventId: 219 }
                        },
                        {
                            component: NewsLittleListPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 360, _title: 'Новости', News: lastNews, type: 'portalNewNews' }
                        },
                        {
                            component: EventPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 110, eventId: showEvents[2], yPadding: 'little' }
                        },
                        {
                            component: CalendarPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 260, Events: eventsForCalendar }
                        },
                        {
                            component: EventLittleListPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { _title: 'Последние соревнования', lowTitle: true, yPadding: 'little', height: 190, Events: lastEvents ? { ...lastEvents } : [] }
                        },
                        {
                            component: PersonsLittleListPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { _title: 'Именинники сегодня', lowTitle: true, yPadding: 'little', height: 254, Persons: birthdays }
                        },
                        {
                            component: VideoLittlePlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 305, _title: 'Произвольный бой', videoId, autoplay: true }
                        },
                        {
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 50 }
                        },
                    ]
                    }
                />
            </div>
        }


        return <div className='main_page'>
            <Helmet
                title="Портал Capoeirasport: капоэйра, соревнования, роды, спорт"
                meta={[
                    { "name": "description", "property": "description", "content": "Портал Capoeira sport является разработкой Федерации капоэйра России, освещает основные спортивные события в мире Российской капоэйра, позволяет удобно регистироваться на мероприятия, собирать статистика по капоэйристам России" },
                    { "name": "og:image", "property": "og:image", "content": "https://capoeirasport.com/images/ver2/logo.png" },
                    { "name": "og:title", "property": "og:title", "content": "Главная страница" },
                    { "name": "og:type", "property": "og:type", "content": "main" },
                    { "name": "og:url", "property": "og:url", "content": `https://capoeirasport.com/` }
                ]}
            />
            <TrefaLayout greedsX={[10, 190, 10, 380, 10, 290, 10, 290, 10]}
                components={[
                    {
                        component: StatisticsPlashka,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { lowTitle: true, height: 60 }
                    },
                    {
                        component: EventLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { _title: 'Последние соревнования', lowTitle: true, yPadding: 'little', height: 190, Events: lastEvents ? { ...lastEvents } : [] }
                    },
                    {
                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { _title: 'Именинники сегодня', lowTitle: true, yPadding: 'little', height: 254, Persons: birthdays }
                    },
                    {
                        component: SomeSliderPlashka,
                        cell_x: 4,
                        collapse: 3,
                        componentProps: {
                            height: 120
                        }
                    },
                    {
                        cell_x: 4,
                        collapse: 3,
                        componentProps: { height: 12 }
                    },
                    // {
                    //     component: OwlQuestPlashka,
                    //     cell_x: 4,
                    //     collapse: 3,
                    //     componentProps: { height: 200 }
                    // },
                    {
                        component: EventPlashka,
                        cell_x: 4,
                        collapse: 3,
                        componentProps: { height: 200, eventId: showEvents[0], yPadding: true } // тут не было yPadding
                    },
                    {
                        component: EventPlashka,
                        cell_x: 4,
                        collapse: 1,
                        componentProps: { height: 130, eventId: showEvents[1], yPadding: true }
                    },
                    {
                        component: EventPlashka,
                        cell_x: 4,
                        collapse: 1,
                        componentProps: { height: 130, eventId: showEvents[2], yPadding: true }
                    },
                    {
                        component: CalendarPlashka,
                        cell_x: 6,
                        collapse: 1,
                        componentProps: { height: 260, Events: eventsForCalendar }
                    },
                    {
                        component: NewsLittleListPlashka,
                        cell_x: 8,
                        collapse: 1,
                        componentProps: { height: 460, _title: 'Новости', News: lastNews, type: 'portalNewNews' }
                    },
                    {
                        component: VideoLittlePlashka,
                        cell_x: 8,
                        collapse: 1,
                        componentProps: { height: 265, _title: 'Произвольный бой', videoId, autoplay: true }
                    },
                    {
                        component: SeqMonitorPlashka,
                        cell_x: 4,
                        collapse: 3,
                        componentProps: { height: 233, _title: 'Cвязки весеннего челленджа', yPadding: true, eventId: 219 }
                    },
                    {
                        component: EventPlashka,
                        cell_x: 4,
                        collapse: 3,
                        componentProps: { height: 220, yPadding: true, eventId: showEvents[3] }
                    },
                    {
                        cell_x: 4,
                        collapse: 1,
                        componentProps: { height: 50 }
                    },
                ]
                }
            />
        </div>
    }

}


export default withCapoeiraSportState('iamuser, getText, eventsLoaded, birthdaysLoaded, newsLoaded, client, login, logout, isMobile, showInfoModal')(MainPage);