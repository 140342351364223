import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import { SchoolPlaceRectMini } from 'src/ver2/components/place/school';
import { Link } from 'react-router-dom';

import Loader from 'src/ver2/components/loader';
import { Icon } from 'antd';
// import { Link } from 'react-router-dom';

import './style.css';

// class RegionSchoolsPlashka
// classname: region_schools_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RegionSchoolsPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        schools: []
    }

    componentDidMount = () => {
        this.loadInfo();
    }

    componentDidUpdate ({region}) {
        if (region !== this.props.region) {
            this.loadInfo();
        }
    }

    loadInfo = async () => {
        const { client, region } = this.props;
        let schools = [];
        const schools_ = await client.schoolsInRegion(region);

        if (schools_ && schools_.length) {
            schools = schools_.map ( ({SchoolId}) => SchoolId )
        }
        this.setState({schools})
    }


    render() {

        const { upperStyle, client, isMobile } = this.props;
        const { width, height } = upperStyle;
        const { schools } = this.state;

        // if (!Event) return <div style={{ width, height }} className='region_info_plashka'>
        //     <Loader />
        // </div>

        const cardHeight = isMobile ? 120 : 220;


        return <div style={{ width, height }} className='region_schools_plashka'>
                {schools.map((schoolKey, index) => {
                    const animationDelay = `${index * 0.1}s`;
                    if (schoolKey === '50') return null;

                    return <Link to={`/school/${schoolKey}/info`}>
                        <div key={`spss-${index + schoolKey}`} style={{ animationDelay }} className='schools_page_schools_one'>
                            <SchoolPlaceRectMini School={client.state.schools[schoolKey]} height={cardHeight} />
                        </div>
                    </Link>
                })}       
        </div>
    }

        // constructor(props) {
    //     super(props);
    // }

}

export default withCapoeiraSportState(`
client,
iamuser,
isMobile
`)(RegionSchoolsPlashka)