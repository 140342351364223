import React, { PureComponent } from 'react';

import { withRoundState } from 'src/ver2/pages/round/context';
import EventAvatar from 'src/ver2/components/event/avatar';

import Loader from 'src/ver2/components/loader';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import './style.css';

// class RoundBackPlashka
// classname: round_back_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RoundBackPlashka extends PureComponent {

    resize = (heigth) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: heigth + 100, plashkaId });
    }

    render() {

        const { Round, isRoundReady, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!isRoundReady) return <div style={{ width, height }} className='round_back_plashka'>
            <Loader text='Загрузка описания' />
        </div>

        const { SoloCompetitionId, UserId, Type, Settings } = Round;

        if (Type === 'daily') {
            return <div style={{ width, height }} className='round_back_plashka'>

            </div>
        }

        if (Type === 'island') {

            const Settings_ = JSON.parse(Settings);
            const { IslandId } = Settings_;

            return <Link to={`/island/` + IslandId}>
                <div style={{ width, height }} className='round_back_plashka'>
                    <span>назад к острову</span> <img src = '/../images/ver2/island/castle.png' style = {{width: 40, height: 40}}/>
                </div>
            </Link>
        }

        return <Link to={`/event/${SoloCompetitionId}/?page=rounds`}>
            <div style={{ width, height }} className='round_back_plashka'>
                <span>назад к челленджу</span> <EventAvatar eventId={SoloCompetitionId} size={40} />
            </div>
        </Link>
    }

}

export default withRoundState(`
Round,
isRoundReady,  
client,
`)(RoundBackPlashka);