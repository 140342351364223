import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import { Modal } from 'antd';

import Loader from 'src/ver2/components/loader';
import { AdmissionBlock } from 'src/ver2/components/event/admissionBlock';

import './style.css';

// class EventParticipationPlashka
// classname: event_participation_plashka

/**
  Это плашка, в которой пишется инфа по участнику, предлагается оплата/страховка и т.д., можно отменить
 */

class EventParticipationPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Event, iAmParticipator, iAmCompetitor, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!Event || (!iAmParticipator && !iAmCompetitor)) return <div style={{ width, height }} className='event_participation_plashka'>
            <Loader />
        </div>

        //console.log('iAmParticipator!!!', iAmParticipator, iAmCompetitor);

        return <div style={{ width, height }} className='event_participation_plashka'>
            {<this.MainData />}
            {<this.DeclineButton />}
        </div>
    }

    MainData = () => {
        const { Event, ParticipatorsData, iAmParticipator, iAmCompetitor, iamuser, isTherePayment, isMobile, Discriptions, Categorys } = this.props;
        const { SettingsObj } = Event;
        const { ParticipatorsMain, PaymentMain, AdmissionMain } = SettingsObj;

        let LeftPart = null
        let RightPart = [];

        let ourData = [];
        if (iamuser && ParticipatorsData && ParticipatorsData[iamuser.Id]) {
            ourData = ParticipatorsData[iamuser.Id];
        }

        let myCategory = null;
        if (iAmCompetitor && Categorys && iAmCompetitor.CategoryId && Categorys.find(x => x.Id === iAmCompetitor.CategoryId)) {
            myCategory = Categorys.find(x => x.Id === iAmCompetitor.CategoryId)
        }

        LeftPart = myCategory ?
            <span>
                ВЫ ЗАРЕГИСТРИРОВАНЫ <br />
                {myCategory.Name}
            </span> :
            <span>
                ВЫ ЗАРЕГИСТРИРОВАНЫ
            </span>

        if (PaymentMain && PaymentMain.PaymentData && isTherePayment()) {

            let Discription = null;
            let onClick = null;

            const checked = ourData.find(x => x.Type === 'payment');

            if (!checked) {

                if (Discriptions && Discriptions.length) {
                    Discription = Discriptions.find(x => x.Type === 'payment');
                }

                if (Discription) {
                    Discription.title = 'Оплата';

                    // нам еще нужны сеттинг

                    let Participator = null;
                    let ParticipatorsDataObj = null;
                    if (iAmParticipator) Participator = iAmParticipator;
                    if (iAmCompetitor) Participator = iAmCompetitor;

                    if (Participator && Participator.Settings) {
                        ParticipatorsDataObj = JSON.parse(Participator.Settings);
                        Discription.header = this.PaymentComponent({ ParticipatorsDataObj });
                    }

                    onClick = () => this.props.showInfoModal({ type: 'eventDiscription', Discription });
                }
            }

            RightPart.push(
                <AdmissionBlock onClick={onClick} height={isMobile ? 40 : 60} itemKey={'payment'} title={'Оплата'} checked={checked} />
            )
        }
        if (AdmissionMain && AdmissionMain.AdmissionData && AdmissionMain.AdmissionData.length) {
            // добавляем остальных штук
            for (let i = 0; i < AdmissionMain.AdmissionData.length; i++) {
                const { key, title, text } = AdmissionMain.AdmissionData[i];
                // надо будет проверить, стоит ли данный ключ
                if (key === 'presense') continue;

                let Discription = null;
                let onClick = null;

                const checked = ourData.find(x => x.Type === key);
                if (!checked) {

                    if (Discriptions && Discriptions.length) {
                        Discription = Discriptions.find(x => x.Type === key);
                    }

                    if (Discription) {
                        Discription.title = title;
                        onClick = () => this.props.showInfoModal({ type: 'eventDiscription', Discription });
                    }
                }

                RightPart.push(
                    <AdmissionBlock onClick={onClick} height={isMobile ? 40 : 60} key={key} title={title} text={text} checked={checked} />
                )
            }
        }

        return <div className='event_participation_plashka_maindata'>
            <div className='event_participation_plashka_maindata_left'>
                {LeftPart}
            </div>
            <div className='event_participation_plashka_maindata_right'>
                {RightPart}
            </div>
        </div>
    }

    PaymentComponent = ({ ParticipatorsDataObj }) => {
        const { howManyAwaitForParticipatorToday } = this.props;

        const howMany = howManyAwaitForParticipatorToday(ParticipatorsDataObj);

        if (!howMany) return null;

        const { allPayment, allSale, paymentRows, saleRows } = howMany;

        return <div className='event_registration_modal_conteiner_payment_main'>

            <div className='event_registration_modal_conteiner_payment_title'>
                {`ОПЛАТА НА ТЕКУЩИЙ МОМЕНТ: ${allPayment - allSale} руб.`}
            </div>
            <div className='event_registration_modal_conteiner_payments'>
                {paymentRows.map(({ name, title, paymentNow, isAccepted, paymentContent }) => {

                    if (!isAccepted) {
                        return <div key={'dfdfgdfg' + name}>
                            <span>{title}</span> уже нельзя оплатить
                    </div>
                    }

                    if (paymentNow === 0) {
                        return null;
                    }

                    return <div key={'dfdfgdfg' + name}>
                        <span> {paymentContent} </span>
                    </div>
                })}
            </div>
            {saleRows.length > 0 ? <div className='event_registration_modal_conteiner_payment_title'>
                СКИДКА:
            </div> : null}
            {saleRows.length > 0 ? <div className='event_registration_modal_conteiner_sales'>
                {saleRows.map(({ saleContent, value }, index) => {
                    return <div key={'ermcss' + index} className='event_registration_modal_conteiner_sale'>
                        <div key={'ermcsss' + index} className='event_registration_modal_conteiner_sale_block'>
                            {saleContent}
                        </div>
                        <div className='event_registration_modal_conteiner_sale_value'>
                            {` ${value} руб.`}
                        </div>
                    </div>
                })}
            </div> : null}
        </div>
    }

    // AdmissionBlock = ({ itemKey, title, text, ourData }) => {

    //     let className = 'event_participation_plashka_maindata_admission';

    //     let thisOurData = ourData.find(x => x.Type === itemKey);

    //     if (thisOurData) {
    //         className += ' event_participation_plashka_maindata_admission_check'
    //     }

    //     return <div className={className}>
    //         <div className='event_participation_plashka_maindata_admission_upper'>
    //             {thisOurData ? <CheckIcon width={25} height={25} /> : null}
    //         </div>
    //         <div className='event_participation_plashka_maindata_admission_downed'>
    //             <span>
    //                 {title}
    //             </span>
    //         </div>
    //     </div>
    // }


    onDeclineClick = () => {

        const { deleteParticipator, deleteCompetitor, iAmParticipator, iAmCompetitor } = this.props;

        if (iAmParticipator) {
            const { Id } = iAmParticipator;
            Modal.warning({
                className: 'event_participation_decline_modal',
                title: 'Отмена регистрации',
                content: 'Вы уверены, что хотите отменить свою регистрацию?',
                onOk: () => { deleteParticipator(Id) },
                onCancel: () => { },
                okText: 'Отменить',
                cancelText: 'Не отменять',
                okCancel: true,
                closable: true,
                centered: true
            })
        }

        if (iAmCompetitor) {
            const { CompetitorId } = iAmCompetitor;
            Modal.warning({
                className: 'event_participation_decline_modal',
                title: 'Отмена регистрации',
                content: 'Вы уверены, что хотите отменить свою регистрацию?',
                onOk: () => { deleteCompetitor(CompetitorId) },
                onCancel: () => { },
                okText: 'Отменить',
                cancelText: 'Не отменять',
                okCancel: true,
                closable: true,
                centered: true
            })
        }

    }

    DeclineButton = () => {

        const { upperStyle } = this.props;
        const { height } = upperStyle;

        return <div
            style={{ minWidth: height, width: height, height }}
            className='event_participation_plashka_decline'
            onClick={this.onDeclineClick}
        >
            <span>
                Отменить регистрацию
            </span>
        </div>
    }

}

export default withEventState(`
Event,

deleteParticipator,
iAmParticipator,

Categorys,

isMobile,
Discriptions,

iAmCompetitor,
deleteCompetitor,

ParticipatorsData,
isTherePayment,

howManyAwaitForParticipatorToday,

showInfoModal,
client,
iamuser,
`)(EventParticipationPlashka)