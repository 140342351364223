import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';

import { Link } from 'react-router-dom';
import GroupAvatar from 'src/ver2/components/group/avatar';
// import { Link } from 'react-router-dom';

import './style.css';
// class ShopKMOPlashka
// classname: shop_kmo_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopKMOPlashka extends PureComponent {

    state = {
        loadingQuest: null
    }

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount = async () => {

        const { client } = this.props;

        const loadingQuest = await client.getKMOQuest(null);

        this.setState({
            loadingQuest
        })
    }

    render() {

        const { upperStyle, client } = this.props;
        const { loadingQuest } = this.state;

        if (!loadingQuest || !client) return null;


        const { width, height } = upperStyle;

        return <div style={{ width, height }} className='shop_kmo_plashka'>
            {loadingQuest.map(({ GroupIamInId, Amount }) => {

                const group = client.getGroupById(GroupIamInId);

                if (!group) return null;

                const { Name } = group;

                return <Link to={`/group/${GroupIamInId}`}>
                    <div className='shop_kmo_plashka_group'>
                        <div className='shop_kmo_plashka_groupavatar'>
                            <GroupAvatar groupId={GroupIamInId} size={30} />
                        </div>
                        <div className='shop_kmo_plashka_groupname'>
                            {Name}
                        </div>
                        <div className='shop_kmo_plashka_amount'>
                            <span>{Amount}</span> <img src="/../images/ver2/scores/money/kmo.png" />
                        </div>
                    </div>
                </Link>
            })
            }
        </div>
    }

}

export default withShopState(`
client
`)(ShopKMOPlashka);