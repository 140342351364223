import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { clone } from 'lodash';
import { withCapoeiraSportState } from 'src/ver2/context';
import SchoolAvatar from 'src/ver2/components/school/avatar';
import { Icon } from 'antd';
import 'moment/locale/ru';

import RussiaMapSvg from './svg/russia';

import { REGIONS_DRAW } from './help';

import './style.css';

const SVG_KOEF = 146.785 / 416.033;
const DEFAULT_WIDTH = 1200;
const CONTAINER_KOEF = 0.6;

class RegionsMap extends PureComponent {

    constructor(props) {
        super(props);

        const {
            scvShiftY
        } = props;

        this.state = {
            svgScale: 1.7,
            scvShiftX: -50,
            scvShiftY: scvShiftY || 18,
            metkaWidth: 28,
            regionsToDraw: [
                'belgorod',
                'kaluga',
                'mo',
                'moscow',
                'krasnoyarsk',
                'spb',
                'krym',
                'sev',
                'karelia',
                'samara',
                'kazan',
                'omsk',
                'voron',
                'tula',
                'rostov',
                'dnr',
                'vladimir'
            ]
        }

        this.state.regionsData = clone(REGIONS_DRAW);
    }

    componentDidMount = async () => {
        const { client } = this.props;
        const schoolsInRegion = await client.schoolsInRegion();
        const amountInRegion = await client.amountInRegion();

        let regionMaps = {};

        let regionsData = { ...this.state.regionsData };
        if (schoolsInRegion) {
            schoolsInRegion.map(({ Region, SchoolId }) => {
                if (!regionMaps[Region]) regionMaps[Region] = {};
                if (!regionMaps[Region].schools) regionMaps[Region].schools = [];
                regionMaps[Region].schools.push(SchoolId);
                return 1;
            })
        }
        if (amountInRegion) {
            amountInRegion.map(({ Region, Amount }) => {
                if (!regionsData[Region]) return 1;
                regionsData[Region].count = Amount;
                return 1;
            })
        }

        const regionMapsKeys = Object.keys(regionMaps);
        for (let i = 0; i < regionMapsKeys.length; i++) {
            const key = regionMapsKeys[i];
            if (key && key !== 'null')
                regionsData[key].schools = regionMaps[key].schools;
        }

        this.setState({ regionsData });
    }

    render() {

        const { width, withOutStick, region } = this.props;
        const { svgScale, scvShiftX, scvShiftY, regionsToDraw, regionsData } = this.state;

        const mainContainerHeght = width * CONTAINER_KOEF;

        const svgContainerWidth = width;
        const svgContainerHeight = svgContainerWidth * SVG_KOEF;

        const svgContainerStyle = {
            width: svgContainerWidth * svgScale,
            height: svgContainerHeight * svgScale,
            left: (scvShiftX * svgScale) * width / DEFAULT_WIDTH,
            top: (mainContainerHeght - svgContainerHeight * svgScale) + (scvShiftY * svgScale)
        }

        return <div style={{ width, height: mainContainerHeght }} className='regionmap_main'>
            <div style={svgContainerStyle} className='regionmap_main_svg_container'>
                {RussiaMapSvg()}
                <svg class='map_approve_svg' width="416.033mm" height="146.785mm" version="1.1" viewBox="0 0 41603 14679" >
                    {regionsToDraw.map(item => regionsData[item].MapComponent(region ? region === item ? 'regionmap_map_thisregion' : 'regionmap_map_dashed' : ''))}
                </svg>
            </div>
            {!withOutStick ? regionsToDraw.map(item => <this.FedMetka
                key={item}
                name={item}
                {...regionsData[item]}
            />) : null}
        </div>
    }

    // x и y это для вариант scale = 1

    FedMetka = ({ name, Coords: { x, y }, upwidth = 100, upheight = 200, count = 0, accred, schools = [] }) => {

        const { width, client } = this.props;
        const { svgScale, scvShiftX, scvShiftY, metkaWidth: metkaWidthDefault } = this.state;

        const widthKoef = width / DEFAULT_WIDTH;

        const metkaWidth = metkaWidthDefault * width / DEFAULT_WIDTH;

        const mainContainerHeght = width * CONTAINER_KOEF;

        const svgContainerWidth = width;
        const svgContainerHeight = svgContainerWidth * SVG_KOEF;

        const style = {
            left: ((x * (width / DEFAULT_WIDTH) * svgScale) + (scvShiftX * svgScale * width / DEFAULT_WIDTH)) - metkaWidth / 2,
            top: (mainContainerHeght - svgContainerHeight * svgScale) + (y * (width / DEFAULT_WIDTH) * svgScale) + scvShiftY * svgScale,
            width: metkaWidth
        }

        const title = (client.regions[name] || {}).title;

        const src = accred ? "/../images/ver2/map/metka_yes.png" : "/../images/ver2/map/metka_no.png"

        return <div style={style} className='regionmap_metka'>
            <div style={{ left: metkaWidth / 2, height: upheight * widthKoef  }} className='regionmap_metka_upstick' />
            <div style={{ left: metkaWidth / 2, top: -upheight * widthKoef, width: upwidth * widthKoef }} className='regionmap_metka_horizstick' >

                <Link to={`/region/${name}`}>
                    <div className='regionmap_metka_horizstick_up'>{title}</div>
                </Link>

                {count ? <div className='regionmap_metka_horizstick_down'>
                    <span><Icon type='user' /> <span className='regionmap_metka_horizstick_down_count'>{count}</span></span> <span>{schools.map(schoolId => <SchoolAvatar schoolId={schoolId} size={20 * widthKoef} />)}</span>
                </div> : null}
            </div>
            {/* <img alt='CapoeiraSport' src={src} /> */}
        </div>

    }
}

export default withCapoeiraSportState('iamuser, client, showInfoModal')(RegionsMap);