
import React from 'react';
import './styles.css';

const ExerciseVideoPlashka = ({ upperStyle: { width, height }, Id }) =>
    <div style={{ width, height }} className='exercise_standart_plashka'>
        <div className='exercise_video_inside'>
            <video
                playsInline="true" id="my-player" class="video-js" preload="none" muted="true" controls="" data-setup="{}"
                loop="true"
                width='100%'
                height='100%'
                autoplay='true'
            >
                <source
                    src={`https://capoeirasport.com/graphics/video_technics/exercises/${Id}.mp4`}
                    type="video/mp4"
                />
            </video>
        </div>
    </div >

export default ExerciseVideoPlashka;