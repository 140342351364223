import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import NewsLittleListPlashka from 'src/ver2/plashki/news/littleList';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventNewNewsPlashka
// classname: event_new_news_plashka

/**
  Это плашка, которая херачит новости :)
 */

class EventNewNewsPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { NewNews, isNewNewsReady, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!isNewNewsReady) return <div style={{ width, height }} className='event_new_news_plashka'>
            <Loader />
        </div>

        if (!NewNews || !NewNews.length) {
            return <div style={{ width, height }} className='event_new_news_plashka'>
                <div className='event_new_news_plashka_nonews'>
                    <span>
                        ПОКА НЕТ <br/>ОПУБЛИКОВАННЫХ <br/> НОВОСТЕЙ
                    </span>
                </div>
            </div>
        }

        let NewNews_ = [...NewNews];

        return <div style={{ width, height }} className='event_new_news_plashka'>
            <NewsLittleListPlashka upperStyle={{ width, height: height - 20 }} News={NewNews_.reverse()} type = {'eventNewNews'} />
        </div>
    }

}

export default withEventState(`
NewNews, 
isNewNewsReady,

client,
iamuser,
`)(EventNewNewsPlashka)