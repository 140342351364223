import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class OTVideoPlashka
// classname: ot_video_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class OTVideoPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    /*
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    render() {

        const { upperStyle, elementKey } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='ot_video_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='ot_video_plashka'>
            <video
                playsInline="true"
                id="my-player"
                class="video-js"
                preload="none"
                muted="true"
                // poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
                loop="true"
                width='100%'
                controls=""
                data-setup="{}"
                onClick={this.onClick}
                ref={this.myRef}
                autoplay='true'
            >
                <source
                    src={`https://capoeirasport.com/graphics/video_technics/${elementKey}.mp4`}
                    type="video/mp4"
                />
            </video>
        </div>
    }
}

export default withCapoeiraSportState(`
client
`)(OTVideoPlashka)