// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { message } from 'antd';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest20 extends PureComponent {
    state = {
        data: {}
    }

    clickOnButton = (index) => {
        let data = {...this.state.data};
        if (data[index]) {
            delete data[index];
        } else {
            data[index] = 1;
        }
        this.setState({data})
    }

    render() {
        const { isMobile } = this.props;
        const { data } = this.state;
        const className = 'quest20_container'

        let rows1 = [];
        let rows2 = [];
        let rows3 = [];

        for (let i = 1; i < 1 + 6; i++) { rows1.push( this.Quest20Block({ index: i}) )}
        for (let i = 7; i < 7 + 6; i++) { rows2.push( this.Quest20Block({ index: i}) )}
        for (let i = 7 + 6; i < 7 + 12; i++) { rows3.push( this.Quest20Block({ index: i}) )}

        return <div className={className}>
            <div className='quest20_container_row'>{rows1}</div>
            <div className='quest20_container_row'>{rows2}</div>
            <div className='quest20_container_row'>{rows3}</div>
            <div className='quest20_container_send' onClick={() => {this.props.sendSpecial({data})}}><span>ОТВЕТ</span></div>
        </div>
    }

    Quest20Block = ( { index } ) => {
        
        const { data } = this.state;
        const className = data[index] ? 'quest20_button quest20_button_set' : 'quest20_button'; 
        
        return <div key = {'sfdsdg' + index} onClick={() => {this.clickOnButton(index)}} className={className}>
            <span></span>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest20);