import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class OTDiscriptionPlashka
// classname: ot_discription_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class OTDiscriptionPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    /*
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    render() {

        const { upperStyle, Discription } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='ot_discription_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='ot_discription_plashka'>
            {(Discription || 'Нет описания').split('/').map((row) => {
                return [
                    row,
                    <br />
                ]
            })}
        </div>
    }
}

export default withCapoeiraSportState(`
client
`)(OTDiscriptionPlashka)