import { 
    Belgorod, 
    Kaluga, 
    Tver,  
    Tula,
    Spb,
    Sevastopol,
    Samara,
    Moscow,
    Karelia,
    Kazan,
    Krym,
    Lenob,
    MO,
    Krasnoyarsk,
    Omsk,
    Voron,
    Rostov,
    DNR,
    Vladimir
} from './svg/regions';

export const REGIONS_DRAW = {
    belgorod: {
        MapComponent: Belgorod,
        Coords: { x: 173, y: 273 },
        upwidth: 80,
        upheight: 430,
        accred: true
    },
    kaluga: {
        MapComponent: Kaluga,
        Coords: { x: 205, y: 240 },
        upwidth: 150,
        upheight: 325,
        accred: true
    },
    moscow: {
        MapComponent: Moscow,
        Coords: { x: 230, y: 241 },
        upwidth: 70,
        upheight: 275,
        accred: true
    },
    mo: {
        MapComponent: MO,
        Coords: { x: 241, y: 237 },
        upwidth: 140,
        upheight: 215,
        accred: true
    },
    spb: {
        MapComponent: Spb,
        Coords: { x: 250, y: 192 },
        upwidth: 120,
        upheight: 83,
        accred: true
    },
    karelia: {
        MapComponent: Karelia,
        Coords: { x: 283, y: 200 },
        upwidth: 80,
        upheight: 45,
        accred: true
    },
    krasnoyarsk: {
        MapComponent: Krasnoyarsk,
        Coords: { x: 608, y: 227 },
        upwidth: 140,
        upheight: 300,
        accred: true
    },
    krym: {
        MapComponent: Krym,
        Coords: { x: 105, y: 288 },
        upwidth: 50,
        upheight: 270,
        accred: true
    },
    sev: {
        MapComponent: Sevastopol,
        Coords: { x: 87, y: 290 },
        upwidth: 80,
        upheight: 360,
        accred: true
    },
    samara: {
        MapComponent: Samara,
        Coords: { x: 267, y: 300 },
        upwidth: 130,
        upheight: 106,
        accred: true
    },
    kazan: {
        MapComponent: Kazan,
        Coords: { x: 300, y: 290 },
        upwidth: 72,
        upheight: 39,
        accred: true
    },
    omsk: {
        MapComponent: Omsk,
        Coords: { x: 447, y: 310 },
        upwidth: 133,
        upheight: 52,
        accred: true
    },
    voron: {
        MapComponent: Voron,
        Coords: { x: 187, y: 280 },
        upwidth: 72,
        upheight: 148,
        accred: true
    },
    tula: {
        MapComponent: Tula,
        Coords: { x: 211, y: 257 },
        upwidth: 42,
        upheight: 66,
        accred: true
    },
    rostov: {
        MapComponent: Rostov,
        Coords: { x: 155, y: 302 },
        upwidth: 152,
        upheight: 232,
        accred: true
    },
    dnr: {
        MapComponent: DNR,
        Coords: { x: 135, y: 292 },
        upwidth: 38,
        upheight: 92,
        accred: true
    },
    vladimir: {
        MapComponent: Vladimir,
        Coords: { x: 259, y: 261 },
        upwidth: 140,
        upheight: 82,
        accred: true
    },
}