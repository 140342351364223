import React, { Component } from 'react';
import Search from './components/search';
import Login from './components/login';
import { Link } from 'react-router-dom';
import { withCapoeiraSportState } from 'src/ver2/context';
import { DriverButton } from 'src/ver2/containers/mobile/driverButton';
import './style.css';

// const MENU_ITEMS = [
//     {
//         name: 'спортивный сезон',
//         name_en: 'sport season',
//         page: 'sportseason'
//     },
//     {
//         name: 'рейтинг',
//         name_en: 'ranking',
//         page: '/raiting?season=2021-2022&type=comp_pro&category=absolute&raitingSubject=school'
//     },
//     {
//         name: 'техника капоэйра',
//         name_en: 'capoeira technique',
//         page: 'technique'
//     },
//     {
//         name: 'школы',
//         name_en: 'schools',
//         page: 'schools'
//     },
//     {
//         name: 'online-игра',
//         name_en: 'online',
//         page: 'online'
//     },
// ]

const MENU_ITEMS = [
    {
        name: 'рейтинг',
        name_en: 'ranking',
        page: '/raiting?season=2023-2024&type=comp_pro&category=absolute&raitingSubject=school'
    },
    {
        name: 'регионы',
        name_en: 'regions',
        page: '/regions'
    },
    {
        name: 'календарь',
        name_en: 'ranking',
        page: '/calendar'
    },
    {
        name: 'спорт',
        name_en: 'ranking',
        page: '/judge'
    },
    {
        name: 'техника',
        name_en: 'ranking',
        page: '/technic/all'
    },
    {
        name: 'школы',
        name_en: 'ranking',
        page: '/schools'
    },
    {
        name: 'трансфер',
        name_en: 'transfer',
        page: '/transfer'
    }
]

class Menu extends Component {

    render() {

        const { isMobile } = this.props;

        if (isMobile) {
            return <div className='menu-main'>
                <this.MiniMenu />
                <this.MiniLogo />
                <Login />
            </div>
        }

        return <div className='menu-main'>
            <div className='menu-main-left-thing global_bg_main' />
            <this.Logo />
            <div className='menu-right-part'>
                <div className='menu-right-part-back global_bg_main' />
                <div className="menu-right-part-upper">
                    <this.MenuLinks />
                    <Search />
                    <Login />
                </div>
            </div>
        </div>
    }

    //Logo = () => <Link to={'/'}><div className="menu-logo"><img alt='CapoeiraSport' src="/../images/ver2/logo.png" /></div></Link>
    // MiniLogo = () => <Link to={'/'}><div className="menu_mini_logo"><img alt='CapoeiraSport' src="/../images/ver2/logo_mini.png" /></div></Link>
    Logo = () => <a href = 'https://capoeirasport.com' ><div className="menu-logo"><img alt='CapoeiraSport' src="/../images/ver2/logo.png" /></div></a>
    MiniLogo = () => <a href = 'https://capoeirasport.com' ><div className="menu_mini_logo"><img alt='CapoeiraSport' src="/../images/ver2/logo_mini.png" /></div></a>

    MiniMenu = () => {

        const DriverComponent = <div className='driver_menu_content'>
            <Search />
            <div className="menu-links_mini">
                {MENU_ITEMS.map(value => <this.MenuLink key={`menu-item-${value.page}`} {...value} />)}
            </div>
        </div>

        return <DriverButton
            ButtonComponent={({ onClick }) => <div className="menu-logo" onClick={onClick}><img alt='CapoeiraSport' src="/../images/ver2/menu_mini.png" /></div >}
            title={'Меню'}
            DriverComponent={DriverComponent}
        />
    }

    MenuLinks = () => {
        return <div className="menu-links">
            {MENU_ITEMS.map(value => <this.MenuLink key={`menu-item-${value.page}`} {...value} />)}
        </div>
    }

    MenuLink = ({ name, name_en, page }) => {
        return <div className="menulink">
            <Link to={page}>
                {name}
            </Link>
        </div>
    }

}


export default withCapoeiraSportState('isMobile')(Menu);