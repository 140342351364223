// это стандартный тайтл, который везде используется
import React from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withGroupState } from 'src/ver2/pages/group/context';
import GroupPurpose from 'src/ver2/pages/group/components/purpose';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';
import ImageUploader from 'src/ver2/containers/imageChanger';

import Loader from 'src/ver2/components/loader';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент смены данных для группы
 */

const GroupDataChange = ({ Group, client, isMobile, updateGroupInfo, iAmCreator, dataFlag = '1' }) => {

    const onPrivateData = async (Data) => {
        // тут у нас функция сравнения, которая заберёт только то, что поменялось
        let resultSend = compareFunction(Group, Data);

        if (Object.keys(resultSend).length > 0) {
            updateGroupInfo(resultSend).then(() => {
                message.success('Изменения приняты');
            })
        } else {
            message.warn('Нет изменений')
        }
    }

    const uploadGroupAvatar = async (data) => {
        const result = await client.updateAvatarGroup(Group.Id, data);
        if (result) {
            message.success('Автар изменён, перегрузите страницу');
        } else {
            message.warn('Ошибка на стороне сервера');
        }
    }

    return <div className='person_data_change'>
        <Tabs className='person_data_change_tabs' tabPosition={isMobile ? 'top' : 'left'} style={{ color: 'white', heightMin: 500 }} defaultActiveKey={dataFlag}>

            <TabPane tab={'Информация'} key="1">
                <DataChanger
                    header={<div>
                        Вы можете поменять название группы
                    </div>}
                    data={Group}
                    buttonText={'ИЗМЕНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={onPrivateData}
                    components={[
                        {
                            name: 'Name',
                            title: 'Имя',
                            type: 'input',
                            neededText: ' '
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Аватарка'} key="2">
                <ImageUploader
                    imageUrl={client.geGroupsUrl() + Group.Id + '.png'}
                    header='Установка аватара группы'
                    recomendations='Добавьте квадратную аватарку'
                    upload={uploadGroupAvatar} />
            </TabPane>
            {
                iAmCreator ? <TabPane tab={'Инструкторы'} key="3">
                    <GroupPurpose/>
                </TabPane> : null
            }
            {/* <TabPane tab={'Регион'} key="9">
                {isRegionDataReady ?
                    <DataChanger
                        header={<div>
                            Текущий регион, от которого Вы выступаете: {client.getRegionName(currentRegion)}  <br />
                            {GroupRegion ? `Регион Вашей группы: ${client.getRegionName(GroupRegion)}` : null}
                        </div>}
                        data={ComponentRegionData}
                        buttonState={'all'}
                        buttonText={'УСТАНОВИТЬ'}
                        onButtonClick={uploadRegion}
                        components={[
                            {
                                name: 'Region',
                                title: 'Регион',
                                type: 'regions',
                                text: GroupRegion ? 'Если вы состоите в группе, но выступаете от другого региона, иначе оставьте поле НЕ УКАЗАН' : 'Укажите свой регион, или вступите в группу'
                            }
                        ]}
                    />
                    : <Loader text='Данные о регионе' />}
            </TabPane> */}
        </Tabs>
    </div>
}

export default withGroupState(`
  iamuser, isMobile, client, iAmCreator,
  Group, updateGroupInfo
`
)(GroupDataChange);

// export default withCapoeiraSportState('iamuser, getText, client, updateMyData, isMobile')(PersonDataChange);