import React, { PureComponent } from 'react';
import EventState, { withEventState } from './context';
import { Modal, Icon, Tooltip, notification, message } from 'antd';

import ButtonPlashka from 'src/ver2/plashki/ui/button';
import AdminModal from './containers/adminModal';
import RegistrtionModal from './containers/registrtionModal';
import AddNewsModal from './containers/addNewsModal';

import moment from 'moment';

import { TrefaLayout } from 'src/ver2/containers/layout';
import EBP from './plashki/bigPlashka';
import EventAdresPlashka from './plashki/adres';
import EventContactsPlashka from './plashki/contacts';
import EventInfoPlashka from './plashki/info';
import EventDiscriptionPlashka from './plashki/discription';
import EventStatisticsPlashka from './plashki/statistics';
import EventCategorysPlashka from './plashki/categorys';
import EventShadulePlashka from './plashki/shadule';
import EventResultsPlashka from './plashki/results';
import EventSchoolResultsPlashka from './plashki/results/school';
import EventGroupResultsPlashka from './plashki/results/group';
import EventResultsMiniPersonPlashka from './plashki/results/miniPerson';
import EventNewNewsPlashka from './plashki/newNews';
import EventParticipationPlashka from './plashki/participator';
import EventPaymentPlashka from './plashki/payment';
import EventAdmissionPlashka from './plashki/admission';
import EventTablePlashka from './plashki/table';
import EventBetPlashka from './plashki/bet';
import EventBatizadoCordaosPlashka from './plashki/batizadoCordaos';

import EventParticipatorsAdminPlashka from './plashki/participatorAdmin';
import EventGroupAdminPlashka from './plashki/groupAdmin';
import EventRoundsPlashka from './plashki/rounds';
import EventSoloUsersPlashka from './plashki/soloUsers';

import PersonQuestsPlashka from './plashki/quests';
import EventSoloResults from './plashki/soloResults';
import SpringSoloResults from './plashki/soloResults/spring';

import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';

// ФИЗУХА
import EventInfoFizuhaPlashka from './plashki/infoFizuha';

import Loader from 'src/ver2/components/loader';

import UiChooser from 'src/ver2/plashki/ui/chooser';

import './styles.css';

class EventPage extends PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            page: 'info',
            showAdminModal: false,
            showRegistrtionModal: false,
            showAddNewNewsModal: false //{ title: 'Предлагаю новость!', template: `^a_^text^a_^Охуенно то как !` }
            //false // тут может быть заложенная уже информация { title, template }
        }

        this.searchDefault = {
            page: 'info',
            categoryId: null
        }

    }

    changeShowAdminModal = (showAdminModal = true) => this.setState({ showAdminModal })
    changeShowRegistrtionModal = (showRegistrtionModal = true) => this.setState({ showRegistrtionModal })
    changeAddNewNewsModal = (showAddNewNewsModal = true) => this.setState({ showAddNewNewsModal })

    registerMe = () => {
        const { addParticipator, iamuser } = this.props;
        if (!this.checkRegistrationNeededModal()) {
            return addParticipator(iamuser.Id);
        }
        this.changeShowRegistrtionModal();
        //addParticipator ( iamuser.Id );
    }

    userGonnaFollow = async () => {
        const { addFollow } = this.props;
        await addFollow();
    }

    userNotGonnaFollow = async () => {


        const { deleteFollow, iAmFollow } = this.props;

        if (iAmFollow)
            Modal.warning({
                className: 'event_participation_decline_modal',
                title: 'Отмена предварительного участия',
                content: 'Вы уверены, что хотите отменить предварительное участие? Вы больше не будете получать новости по данному мероприятию.',
                onOk: () => { deleteFollow(iAmFollow.Id) },
                onCancel: () => { },
                okText: 'Отменить',
                cancelText: 'Не отменять',
                okCancel: true,
                closable: true,
                centered: true
            })
    }

    goRegistrationOpenNotification = () => {
        notification.open({
            message: 'Создать новость?',
            description:
                <div>
                    <span>Хотите посмотреть и создать новость об открытии регистрации?</span>
                    <br />
                    <br />
                    <ButtonPlashka
                        upperStyle={{ width: 100, height: 40 }}
                        onClick={() => {
                            const { client, Event } = this.props;
                            this.changeAddNewNewsModal(client.NT_registrationOpened(Event))
                        }}
                        text={'ПОСМОТРЕТЬ'}
                    />
                </div>
            ,
            duration: 0,
        });
    }

    checkRegistrationNeededModal = () => {
        const { Event, checkMayIParticipateByNecessery } = this.props;
        if (Event.Type === 'roda') {
            return false;
        }

        if (Event.Type === 'championship') {
            return true;
        }

        const { SettingsObj } = Event;
        if (!SettingsObj) return true; // хотя это ОЧЕНЬ странно, такого не должно быть вообще

        const { PermissionMain, ParticipatorsMain } = SettingsObj;

        // нужно проверить, подходим ли мы по критериям школы и группы
        // хотя опять таки, если это закрытое мепоприятия, мы не должны вообще его увидеть
        if (PermissionMain && PermissionMain.IsClosed) {

        }

        // это проверка на обязательные штуки, типа наличия школы или наличия контактов
        if (checkMayIParticipateByNecessery().result !== 'success') return true;

        // если дошли сюда и нет категорий участников - всё отл!
        if (!ParticipatorsMain) return false; // нет данных для указания
        const { ParticipatorsData } = ParticipatorsMain;
        if (!ParticipatorsData) return false;

        // всё таки есть какой-то выбор для участия
        for (let i = 0; i < ParticipatorsData.length; i++) {
            if (ParticipatorsData[i].name !== 'participation') {
                return true;
            }
        }

        return false;


        /*
            PermissionMain: {
                IsClosed
            }
    
            ParticipatorsMain: {
                NecesserySchool
                NecesseryContacts
    
                ParicipatorsData: [ // этого может быть не быть в родах, например
                    {name: participation,
                        title, 
                        options, 
                        paymentSplit,
                        payments: [
    
                        ]                    
                    } // всегда должна быть
                ]
            }
    
    
        */
    }

    showParticipatorsList = () => {
        const { showInfoModal, Event, isParticipatorsReady, Championship, Competitors, Participators, ParticipatorsData, iamuser } = this.props;

        console.log ('showParticipatorsList');

        if (!Event || !isParticipatorsReady) return;

        let Partis = (Event.Type === 'championship' && Championship) ? Competitors : Participators;

        const { page } = this.state;

        if (page === 'group') {

            let Partis_ = [];

            let myGroups = {};
            if (iamuser && iamuser.GroupId) myGroups[iamuser.GroupId] = true;

            if (iamuser && iamuser.myGroupRole) {
                for (let i = 0; i < iamuser.myGroupRole.length; i++) {
                    const item = iamuser.myGroupRole[i];
                    if (item.Role === 'instructor') {
                        myGroups[item.SubjectId] = true;
                    }
                }
            }

            if (Partis && Partis.length) {
                const {
                    CategorysAsObjcet,
                    isCategorysReady
                } = this.props;

                for (let i = 0; i < Partis.length; i++) {
                    if (myGroups[Partis[i].GroupIamInId]) {

                        let Data = { ...Partis[i] };

                        if (Event.Type === 'championship' && isCategorysReady && CategorysAsObjcet) {
                            const {
                                CategoryId
                            } = Data;
                            if (CategoryId && CategorysAsObjcet[CategoryId]) {
                                Data.CategoryName = CategorysAsObjcet[CategoryId].Name;
                            }
                        }

                        if (ParticipatorsData && ParticipatorsData[Data.UserId]) {
                            const userPayment = ParticipatorsData[Data.UserId].find(x => x.Type === 'payment');
                            if (userPayment) {
                                Data.Payment = userPayment.Value;
                            }
                        }

                        Partis_.push(Data);
                    }
                }
            }

            showInfoModal({
                type: 'participatorsList',
                Data: {
                    Partis: Partis_,
                    ParticipatorsData,
                    Event,
                    ListType: Event.Type === 'championship' ? 'champList' : null
                }
            });
            return;
        }

        if (Event.Id.toString() === '226') {

            console.log('HERE');

            for (let i = 0; i < Partis.length; i++) {
                if (Partis[i].Settings) {
                    let isPro = JSON.parse(Partis[i].Settings);
                    isPro = (isPro.orgvalue_111.toString() === '2');
                    Partis[i].isPro = isPro ? 1 : 0;
                }

                const { BirthDay } = Partis[i];
                const Birth = moment(BirthDay, 'DD.MM.YYYY');
                let year = moment().diff(Birth, 'years', false);
                Partis[i].year = year;
            }

            showInfoModal({
                type: 'participatorsList',
                Data: {
                    Partis,
                    ParticipatorsData,
                    ListType: 'ilyaSolo',
                    Event
                }
            });
            return;
        }

        showInfoModal({
            type: 'participatorsList',
            Data: {
                Partis,
                ParticipatorsData,
                Event
            }
        }
        );
    }

    linkTo = (param, value) => {
        let { page, categoryId } = this.state;
        let linkObject = { page };
        if (page === 'shadule' || page === 'results') linkObject.categoryId = categoryId;
        linkObject[param] = value;
        return `?${Object.keys(linkObject).reduce((memo, key, index) => {
            if (!linkObject[key]) return memo;
            return memo + `${index > 0 ? '&' : ''}${key}=${linkObject[key]}`
        }, '')}`
    }

    chooseTab = () => {
        const { location } = this.props;
        const { search } = location;

        // парсим search
        let searchObj = {
            ...this.searchDefault
        }

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        this.setState({
            ...searchObj
        })

    }

    componentDidMount() {
        this.chooseTab();
        this.props.setBackground();
    }

    componentDidUpdate({ isEventReady }) {
        this.chooseTab();
        if (isEventReady !== this.props.isEventReady) {
            this.props.setBackground();
        }
    }

    componentWillUnmount() {
        this.props.setBackground(true);
    }

    render() {

        const width = 1200;
        const { isEventReady, iAmAdmin, Event, iamuser, isMobile } = this.props;
        const { showAdminModal, showRegistrtionModal, showAddNewNewsModal } = this.state;

        if (!isEventReady) return <div className='event_page'>
            <Loader />
        </div>

        const DontExist = <div className='event_page'> Мероприятия с таким адресом не существует </div>

        if (!Event || (Event.NotAnnounced && !iAmAdmin)) return DontExist;

        const { SettingsObj } = Event;

        if (SettingsObj && SettingsObj.PermissionMain && SettingsObj.PermissionMain.FaceFrom === 'school' && SettingsObj.PermissionMain.IsClosed) {
            if (!iamuser) return DontExist;
            if (String(iamuser.SchoolId) !== String(SettingsObj.PermissionMain.FaceId)) return DontExist;
        }

        if (SettingsObj && SettingsObj.PermissionMain && SettingsObj.PermissionMain.FaceFrom === 'group' && SettingsObj.PermissionMain.IsClosed) {
            if (!iamuser) return DontExist;
            if (
                String(iamuser.GroupIamInId) !== String(SettingsObj.PermissionMain.FaceId) &&
                String(iamuser.GroupId) !== String(SettingsObj.PermissionMain.FaceId)
            ) return DontExist;
        }

        let components_ = []

        // MOBILE RENDER

        if (isMobile) {
            const { width } = isMobile;
            components_ = this.isAddingBig(components_);
            components_ = this.isAddingDiscription(components_);

            components_ = this.isAddingUi(components_);

            // components_ = this.isAddingAdminButton(components_);
            components_ = this.isAddingUserFollowButton(components_);
            components_ = this.isAddingParticipationButton(components_);
            components_ = this.isAddingParticipation(components_);

            components_ = this.isAddingBet(components_);

            components_ = this.isAddingFields(components_);
            components_ = this.isAddingQuests(components_);
            components_ = this.isAddingNewNews(components_);

            components_ = this.isAddingInfo(components_);
            components_ = this.isAddingAdres(components_);

            components_ = this.isAddingPayment(components_);
            components_ = this.isAddingAdmissions(components_);

            components_ = this.isAddingContacts(components_);

            components_ = this.isAddingStatistics(components_);
            components_ = this.isAddingCategorys(components_);
            components_ = this.isAddingBatizadoCordaos(components_);
            components_ = this.isAddingShadule(components_);


            components_ = this.isAddingMiniPersonResult(components_);
            components_ = this.isAddingGroupsResults(components_);
            components_ = this.isAddingAllResults(components_);
            components_ = this.isAddingSchoolsResults(components_);
            components_ = this.isAddingResults(components_);

            components_ = this.isAddingRounds(components_);
            components_ = this.isAddingSoloUsers(components_);

            // администрация
            components_ = this.isAddinParticipatorsAdmin(components_);
            components_ = this.isAddinGroupAdmin(components_);


            return <div style={{ width }} className='event_page'>
                <Modal
                    className='adminModalClass'
                    visible={showAdminModal}
                    onCancel={() => { this.changeShowAdminModal(false) }}
                    footer={null}
                    title={'Редактировать данные'}
                    width={300}
                >
                    {showAdminModal ? <AdminModal /> : null}
                </Modal>
                <Modal
                    className='registrtionModalClass'
                    visible={showRegistrtionModal}
                    onCancel={() => { this.changeShowRegistrtionModal(false) }}
                    footer={null}
                    title={'Регистрация на мероприятие'}
                    width={300}
                >
                    {showRegistrtionModal ? <RegistrtionModal close={this.changeShowRegistrtionModal} /> : null}
                </Modal>
                <Modal
                    className='adminModalClass'
                    visible={showAddNewNewsModal}
                    onCancel={() => { this.changeAddNewNewsModal(false) }}
                    footer={null}
                    title={'Новости мероприятия'}
                    width={300}
                >
                    {showAddNewNewsModal ? <AddNewsModal close={this.changeAddNewNewsModal} modalData={showAddNewNewsModal} /> : null}
                </Modal>
                <TrefaLayout greedsX={[1, 4, width - 10, 5]}
                    components={
                        components_
                    }
                />
            </div>
        }

        components_ = this.isAddingBig(components_);
        components_ = this.isAddingDiscription(components_);

        components_ = this.isAddingAdminButton(components_);
        components_ = this.isAddingUserFollowButton(components_);
        components_ = this.isAddingParticipationButton(components_);

        components_ = this.isAddingQuests(components_);
        components_ = this.isAddingNewNews(components_);
        components_ = this.isAddingPayment(components_);
        components_ = this.isAddingAdmissions(components_);


        components_ = this.isAddingUi(components_);

        components_ = this.isAddingParticipatorsList(components_);

        components_ = this.isAddingAdres(components_);
        components_ = this.isAddingContacts(components_);

        components_ = this.isAddingParticipation(components_);
        components_ = this.isAddingBet(components_);

        components_ = this.isAddingFields(components_);

        components_ = this.isAddingInfo(components_);
        components_ = this.isAddingStatistics(components_);
        components_ = this.isAddingCategorys(components_);
        components_ = this.isAddingBatizadoCordaos(components_);
        components_ = this.isAddingShadule(components_);
        components_ = this.isAddingResults(components_);
        components_ = this.isAddingMiniPersonResult(components_);
        components_ = this.isAddingGroupsResults(components_);
        components_ = this.isAddingAllResults(components_);
        components_ = this.isAddingSchoolsResults(components_);

        components_ = this.isAddingRounds(components_);
        components_ = this.isAddingSoloUsers(components_);

        // администрация
        components_ = this.isAddinParticipatorsAdmin(components_);
        components_ = this.isAddinGroupAdmin(components_);


        return <div style={{ width }} className='event_page'>
            <Modal
                className='adminModalClass'
                visible={showAdminModal}
                onCancel={() => { this.changeShowAdminModal(false) }}
                footer={null}
                title={'Редактировать данные'}
                width={1200}
            >
                {showAdminModal ? <AdminModal /> : null}
            </Modal>
            <Modal
                className='registrtionModalClass'
                visible={showRegistrtionModal}
                onCancel={() => { this.changeShowRegistrtionModal(false) }}
                footer={null}
                title={'Регистрация на мероприятие'}
                width={900}
            >
                {showRegistrtionModal ? <RegistrtionModal close={this.changeShowRegistrtionModal} /> : null}
            </Modal>
            <Modal
                className='adminModalClass'
                visible={showAddNewNewsModal}
                onCancel={() => { this.changeAddNewNewsModal(false) }}
                footer={null}
                title={'Новости мероприятия'}
                width={900}
            >
                {showAddNewNewsModal ? <AddNewsModal close={this.changeAddNewNewsModal} modalData={showAddNewNewsModal} /> : null}
            </Modal>
            <TrefaLayout greedsX={[25, 200, 25, 650, 25, 250, 25]}
                components={
                    components_
                }
            />
        </div>
    }

    get isFizuha() {
        const { Event } = this.props;
        if (!Event) return false;

        const { SettingsObj } = Event;

        return !!(SettingsObj && SettingsObj.SpecialMain && SettingsObj.SpecialMain.FizChallenge);
    }

    isAddingBig = (components) => {
        const { Event: { Id: eventId } = { Id: 0 }, isMobile } = this.props;

        if (isMobile) {
            return [...components,
            {
                component: EBP,
                cell_x: 3,
                collapse: 1,
                id: 1,
                componentProps: {
                    goRegistrationOpenNotification: this.goRegistrationOpenNotification,
                    height: 70,
                    eventId
                }

            }
            ]
        }

        return [...components,
        {
            component: EBP,
            cell_x: 2,
            collapse: 5,
            id: 1,
            componentProps: {
                goRegistrationOpenNotification: this.goRegistrationOpenNotification,
                height: 140,
                eventId
            }

        }
        ]
    }


    isAddingAdres = (components) => {
        const { Event: { Id: eventId, Adress } = { Id: 0, Adress: '' }, isMobile } = this.props;
        const { page } = this.state;

        if (page !== 'info') return [...components]
        if (Adress === 'online') return [...components]
        // if 


        if (isMobile) return [...components,
        {
            component: EventAdresPlashka,
            cell_x: 3,
            collapse: 1,
            id: 2,
            componentProps: { height: 170, yPadding: 'mini', _title: 'Адрес', eventId }
        }
        ]

        return [...components,
        {
            component: EventAdresPlashka,
            cell_x: 2,
            collapse: 1,
            id: 2,
            componentProps: { height: 250, yPadding: 'mini', _title: 'Адрес', eventId }
        }
        ]
    }

    isAddingContacts = (components) => {
        const { Event: { Id: eventId } = { Id: 0 }, isMobile, isStuffReady, Stuff } = this.props;
        const { page } = this.state;
        if (page !== 'info' || !isStuffReady || !Stuff) return [...components]

        if (isMobile) return [...components,
        {
            component: PersonsLittleListPlashka,
            cell_x: 3,
            collapse: 1,
            id: 3,
            componentProps: { height: 100, yPadding: 'mini', _title: 'Контакты', Persons: Stuff.contacts, autoSize: true, mode: 'contact' }
            // componentProps: { height: 84, yPadding: 'mini', _title: 'Контакты', eventId }
        }
        ]

        return [...components,
        {
            component: PersonsLittleListPlashka,
            cell_x: 2,
            collapse: 1,
            id: 3,
            componentProps: { height: 100, yPadding: 'mini', _title: 'Контакты', Persons: Stuff.contacts, autoSize: true, mode: 'contact' }
            // componentProps: { height: 84, yPadding: 'mini', _title: 'Контакты', eventId }
        }
        ]
    }

    isAddingAdminButton = (components) => {
        const { page } = this.state;
        const { iAmAdmin, isMobile } = this.props;
        if (page !== 'info' || !iAmAdmin) return [...components]

        if (isMobile) return [...components,
        {
            component: ButtonPlashka,
            cell_x: 3,
            collapse: 1,
            id: 19,
            componentProps: { height: 70, yPadding: 'usual', text: 'АДМИНИСТРИРОВАТЬ', onClick: this.changeShowAdminModal }
        },
        ]

        return [...components,
        {
            component: ButtonPlashka,
            cell_x: 6,
            collapse: 1,
            id: 19,
            componentProps: { height: 70, yPadding: 'usual', text: 'АДМИНИСТРИРОВАТЬ', onClick: this.changeShowAdminModal }
        },
        ]
    }

    isAddingParticipationButton = (components) => {
        const { page } = this.state;
        const { isParticipatorsReady, iAmParticipator, iAmCompetitor, Event, eventStatus, iamuser, isMobile } = this.props;
        if (page !== 'info' || !Event || !Event.SettingsObj || !isParticipatorsReady || (iAmParticipator || iAmCompetitor)) return [...components]

        const eventStatus_ = eventStatus() || 'undef';
        if (eventStatus_ !== 'registration_open') return [...components];

        if (!iamuser) {
            if (isMobile) return [...components,
            {
                component: ButtonPlashka,
                cell_x: 3,
                collapse: 1,
                id: 119,
                componentProps: { height: 70, yPadding: 'usual', disabled: true, disabledDiscription: 'Выйдите в профиль', text: 'ЗАРЕГИСТРИРОВАТЬСЯ', onClick: this.registerMe }
            },
            ]

            return [...components,
            {
                component: ButtonPlashka,
                cell_x: 6,
                collapse: 1,
                id: 119,
                componentProps: { height: 70, yPadding: 'usual', disabled: true, disabledDiscription: 'Выйдите в профиль', text: 'ЗАРЕГИСТРИРОВАТЬСЯ', onClick: this.registerMe }
            },
            ]
        }

        if (isMobile) return [...components,
        {
            component: ButtonPlashka,
            cell_x: 3,
            collapse: 1,
            id: 119,
            componentProps: { height: 70, yPadding: 'usual', type: 'danger', text: 'ЗАРЕГИСТРИРОВАТЬСЯ', onClick: this.registerMe }
        },
        ]

        return [...components,
        {
            component: ButtonPlashka,
            cell_x: 6,
            collapse: 1,
            id: 119,
            componentProps: { height: 70, yPadding: 'usual', type: 'danger', text: 'ЗАРЕГИСТРИРОВАТЬСЯ', onClick: this.registerMe }
        },
        ]
    }

    isAddingUserFollowButton = (components) => {
        const { page } = this.state;
        const { Event, eventStatus, iamuser, iAmParticipator, iAmFollow, isMobile } = this.props;
        if (page !== 'info' || !iamuser || !Event || !Event.SettingsObj || iAmParticipator) return [...components]

        const eventStatus_ = eventStatus() || 'undef';
        if (eventStatus_ !== 'is_preliminarily' && eventStatus_ !== 'registration_not_open') return [...components];

        if (iAmFollow) {

            if (isMobile) return [...components,
            {
                component: ButtonPlashka,
                cell_x: 3,
                collapse: 1,
                id: 819,
                componentProps: { height: 70, yPadding: 'usual', type: 'dashed', text: 'Я НЕ ПЛАНИРУЮ УЧАСТВОВАТЬ', onClick: this.userNotGonnaFollow }
            },
            ]

            return [...components,
            {
                component: ButtonPlashka,
                cell_x: 6,
                collapse: 1,
                id: 819,
                componentProps: { height: 70, yPadding: 'usual', type: 'dashed', text: 'Я НЕ ПЛАНИРУЮ УЧАСТВОВАТЬ', onClick: this.userNotGonnaFollow }
            },
            ]
        }

        if (isMobile) return [...components,
        {
            component: ButtonPlashka,
            cell_x: 3,
            collapse: 1,
            id: 819,
            componentProps: { height: 70, yPadding: 'usual', text: 'Я ПЛАНИРУЮ УЧАСТВОВАТЬ', onClick: this.userGonnaFollow }
        },
        ]

        return [...components,
        {
            component: ButtonPlashka,
            cell_x: 6,
            collapse: 1,
            id: 819,
            componentProps: { height: 70, yPadding: 'usual', text: 'Я ПЛАНИРУЮ УЧАСТВОВАТЬ', onClick: this.userGonnaFollow }
        },
        ]
    }

    isAddingInfo = (components) => {
        const { Event: { Id: eventId } = { Id: 0 }, isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'info') return [...components]


        if (this.isFizuha) {
            if (isMobile) {
                return [...components,
                {
                    component: EventInfoFizuhaPlashka,
                    cell_x: 3,
                    collapse: 1,
                    id: 4,
                    componentProps: { height: 20, componentSelfHeight: true, yPadding: 'little', eventId }
                }
                ]
            }

            return [...components,
            {
                component: EventInfoFizuhaPlashka,
                cell_x: 4,
                collapse: 3,
                id: 4,
                componentProps: { height: 20, componentSelfHeight: true, yPadding: 'little', eventId }
            }
            ]
        }


        if (isMobile) return [...components,
        {
            component: EventInfoPlashka,
            cell_x: 3,
            collapse: 1,
            id: 4,
            componentProps: { height: 200, yPadding: 'little', _title: 'Основная информация', eventId }
        }
        ]

        return [...components,
        {
            component: EventInfoPlashka,
            cell_x: 4,
            collapse: 1,
            id: 4,
            componentProps: { height: 200, yPadding: 'little', _title: 'Основная информация', eventId }
        }
        ]
    }

    isAddinParticipatorsAdmin = (components) => {
        const { page } = this.state;
        const { iAmAdmin, isMobile } = this.props;
        if (page !== 'admin' || !iAmAdmin) return [...components]

        if (isMobile) return [...components,
        {
            component: EventParticipatorsAdminPlashka,
            cell_x: 3,
            collapse: 1,
            id: 91,
            componentProps: { height: 700, yPadding: 'little', _title: 'Отметка участников' }
        }
        ]

        return [...components,
        {
            component: EventParticipatorsAdminPlashka,
            cell_x: 4,
            collapse: 3,
            id: 91,
            componentProps: { height: 700, yPadding: 'little', _title: 'Отметка участников' }
        }
        ]
    }

    isAddinGroupAdmin = (components) => {
        const { page } = this.state;
        const { isMobile } = this.props;
        if (page !== 'group') return [...components]

        if (isMobile) return [...components,
        {
            component: EventGroupAdminPlashka,
            cell_x: 3,
            collapse: 1,
            id: 991,
            componentProps: { height: 700, yPadding: 'little', _title: 'Ваши ученики' }
        }
        ]

        return [...components,
        {
            component: EventGroupAdminPlashka,
            cell_x: 4,
            collapse: 3,
            id: 991,
            componentProps: { height: 700, yPadding: 'little', _title: 'Ваши ученики' }
        }
        ]
    }

    //EventParticipatorsAdminPlashka

    isAddingParticipation = (components) => {

        const { page } = this.state;
        const { Event, isParticipatorsReady, iAmParticipator, iAmCompetitor, isMobile } = this.props;
        if (page !== 'info' || !Event || !Event.SettingsObj || !isParticipatorsReady || (!iAmParticipator && !iAmCompetitor)) return [...components]

        if (this.isFizuha) {
            return [...components];
        }

        if (isMobile) return [...components,
        {
            component: EventParticipationPlashka,
            cell_x: 3,
            collapse: 1,
            id: 74,
            componentProps: { height: 100, yPadding: 'little', _title: 'Ваша регистрация' }
        }
        ]

        return [...components,
        {
            component: EventParticipationPlashka,
            cell_x: 4,
            collapse: 1,
            id: 74,
            componentProps: { height: 120, yPadding: 'little', _title: 'Ваша регистрация' }
        }
        ]
    }

    isAddingBet = (components) => {

        const { page } = this.state;
        const { Event, isMobile } = this.props;
        if (page !== 'info' || !Event || !Event.SettingsObj || (Number(Event.Id) !== 101 && Number(Event.Id) !== 258 && Number(Event.Id) !== 264 && Number(Event.Id) !== 249 && Number(Event.Id) !== 248 && Number(Event.Id) !== 247 && Number(Event.Id) !== 246 && Number(Event.Id) !== 273 && Number(Event.Id) !== 227 && Number(Event.Id) !== 225 && Number(Event.Id) !== 148 && Number(Event.Id) !== 140 && Number(Event.Id) !== 209 && Number(Event.Id) !== 210 && (Number(Event.Id) !== 129) && (Number(Event.Id) !== 133) && (Number(Event.Id) !== 136) && (Number(Event.Id) !== 142) && (Number(Event.Id) !== 151) && (Number(Event.Id) !== 149) && (Number(Event.Id) !== 152) && (Number(Event.Id) !== 147) && (Number(Event.Id) !== 138) && (Number(Event.Id) !== 139 && (Number(Event.Id) !== 150) && (Number(Event.Id) !== 153) && (Number(Event.Id) !== 244)))) return [...components]

        if (isMobile) return [...components,
        {
            component: EventBetPlashka,
            cell_x: 3,
            collapse: 1,
            id: 974,
            componentProps: { height: 130, yPadding: 'little', _title: 'Конкурс прогнозов' }
        }
        ]

        return [...components,
        {
            component: EventBetPlashka,
            cell_x: 4,
            collapse: 1,
            id: 974,
            componentProps: { height: 120, yPadding: 'little', _title: 'Конкурс прогнозов' }
        }
        ]
    }

    isAddingNewNews = (components) => {

        const { page } = this.state;
        if (page !== 'info') return [...components]

        const { iAmAdmin, iAmModerator, isMobile, Event } = this.props;
        if (Event.SettingsObj && Event.SettingsObj.SpecialMain && Event.SettingsObj.SpecialMain.LiteVersion) return [...components];

        let _title = 'Новости мероприятия';
        if (iAmAdmin || iAmModerator) {
            _title = <span> Новости мероприятия
                <Tooltip title='добавить новость' placement='top'>
                    <Icon
                        data-tooltip={'добавить'}
                        type='plus-circle'
                        style={{ cursor: 'pointer', marginLeft: 5 }}
                        onClick={this.changeAddNewNewsModal}
                        theme="filled"
                    //type="check-circle" theme="filled"
                    />
                </Tooltip>
            </span>
        }

        if (isMobile) return [...components,
        {
            component: EventNewNewsPlashka,
            cell_x: 3,
            collapse: 1,
            id: 22,
            componentProps: {
                height: 230, yPadding: 'little',
                _title
            }
        }
        ]

        return [...components,
        {
            component: EventNewNewsPlashka,
            cell_x: 6,
            collapse: 1,
            id: 22,
            componentProps: {
                height: 400, yPadding: 'little',
                _title
            }
        }
        ]
    }

    isAddingPayment = (components) => {
        const { page } = this.state;
        const { Event, eventStatus, isMobile } = this.props;
        if (page !== 'info' || !Event || !Event.SettingsObj || Event.Type === 'roda') return [...components];

        if (Event.SettingsObj && Event.SettingsObj.SpecialMain && Event.SettingsObj.SpecialMain.LiteVersion) return [...components];

        const eventStatus_ = eventStatus() || 'undef';
        if (eventStatus_ === 'is_preliminarily' || eventStatus_ === 'registration_not_open' || eventStatus_ === 'past') return [...components];

        if (isMobile) return [...components,
        {
            component: EventPaymentPlashka,
            cell_x: 3,
            collapse: 1,
            id: 322,
            componentProps: { height: 80, yPadding: 'little', _title: 'Стоимость участия' }
        }
        ]

        return [...components,
        {
            component: EventPaymentPlashka,
            cell_x: 6,
            collapse: 1,
            id: 322,
            componentProps: { height: 80, yPadding: 'little', _title: 'Стоимость участия' }
        }
        ]
    }

    isAddingAdmissions = (components) => {
        const { page } = this.state;
        const { Event, eventStatus, isMobile } = this.props;
        if (page !== 'info' || !Event || !Event.SettingsObj) return [...components]
        if (Event.SettingsObj && Event.SettingsObj.SpecialMain && Event.SettingsObj.SpecialMain.LiteVersion) return [...components];

        const eventStatus_ = eventStatus() || 'undef';
        if (eventStatus_ === 'is_preliminarily' || eventStatus_ === 'registration_not_open' || eventStatus_ === 'past') return [...components];

        if (isMobile) return [...components,
        {
            component: EventAdmissionPlashka,
            cell_x: 3,
            collapse: 1,
            id: 422,
            componentProps: { height: 80, yPadding: 'little', _title: 'Допуск к мероприятию' }
        }
        ]

        return [...components,
        {
            component: EventAdmissionPlashka,
            cell_x: 6,
            collapse: 1,
            id: 422,
            componentProps: { height: 80, yPadding: 'little', _title: 'Допуск к мероприятию' }
        }
        ]
    }

    isAddingFields = (components) => {
        const { page } = this.state;
        const { Event, isMobile, isThereTable } = this.props;
        if (page !== 'info' || !Event || !Event.SettingsObj || !isThereTable) return [...components]

        if (isMobile) return [...components,
        {
            component: EventTablePlashka,
            cell_x: 3,
            collapse: 1,
            id: 999,
            componentProps: { height: 480, yPadding: 'little', _title: 'Трансляция' }
        }
        ]

        return [...components,
        {
            component: EventTablePlashka,
            cell_x: 4,
            collapse: 1,
            id: 999,
            componentProps: { height: 800, yPadding: 'little', _title: 'Трансляция' }
        }
        ]
    }

    isAddingStatistics = (components) => {
        const { Event: { Id: eventId } = { Id: 0 }, isMobile } = this.props;

        const { page } = this.state;
        if (page !== 'statistics') return [...components]

        if (isMobile) return [...components,
        {
            component: EventStatisticsPlashka,
            cell_x: 3,
            collapse: 1,
            id: 7,
            componentProps: { height: 800, yPadding: 'little', _title: 'Статистика', eventId }
        }
        ]

        return [...components,
        {
            component: EventStatisticsPlashka,
            cell_x: 4,
            collapse: 1,
            id: 7,
            componentProps: { height: 200, yPadding: 'little', _title: 'Статистика', eventId }
        }
        ]
    }

    isAddingCategorys = (components) => {
        const { page } = this.state;
        const { isChampionshipReady, isMobile } = this.props;

        if (page !== 'categorys' || !isChampionshipReady) return [...components]

        if (isMobile) return [...components,
        {
            component: EventCategorysPlashka,
            cell_x: 3,
            collapse: 1,
            id: 9,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Категории',
            }
        }
        ]

        return [...components,
        {
            component: EventCategorysPlashka,
            cell_x: 4,
            collapse: 1,
            id: 9,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Категории',
            }
        }
        ]
    }

    isAddingRounds = (components) => {
        const { page } = this.state;
        const { isRoundsReady, isMobile } = this.props;

        if (page !== 'rounds' || !isRoundsReady) return [...components]

        if (isMobile) return [...components,
        {
            component: EventRoundsPlashka,
            cell_x: 3,
            collapse: 3,
            id: 999,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Раунды',
            }
        }
        ]

        return [...components,
        {
            component: EventRoundsPlashka,
            cell_x: 4,
            collapse: 3,
            id: 999,
            componentProps: {
                height: 1200,
                yPadding: 'little',
                _title: 'Раунды',
            }
        }
        ]
    }

    isAddingSoloUsers = (components) => {
        const { page } = this.state;
        const { isRoundsReady, isMobile } = this.props;

        if (page !== 'users' || !isRoundsReady) return [...components]

        if (isMobile) return [...components,
        {
            component: EventSoloUsersPlashka,
            cell_x: 3,
            collapse: 3,
            id: 3999,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Участники',
            }
        }
        ]

        return [...components,
        {
            component: EventSoloUsersPlashka,
            cell_x: 4,
            collapse: 3,
            id: 3999,
            componentProps: {
                height: 1200,
                yPadding: 'little',
                _title: 'Участники',
            }
        }
        ]
    }

    isAddingQuests = (components) => {
        const { page } = this.state;
        const { isMobile, ServerQuests } = this.props;

        if (page !== 'info' || (!ServerQuests || !ServerQuests.length)) return [...components]

        if (this.isFizuha) {
            if (isMobile) return [...components,
            {
                component: PersonQuestsPlashka,
                cell_x: 3,
                collapse: 1,
                id: 73999,
                componentProps: {
                    height: 200,
                    yPadding: 'little',
                    _title: 'Сдать упражнения',
                }
            }
            ]

            return [...components,
            {
                component: PersonQuestsPlashka,
                cell_x: 4,
                collapse: 3,
                id: 73999,
                componentProps: {
                    height: 84,
                    yPadding: 'little',
                    _title: 'Сдать упражнения',
                }
            }
            ]
        }

        if (isMobile) return [...components,
        {
            component: PersonQuestsPlashka,
            cell_x: 3,
            collapse: 1,
            id: 73999,
            componentProps: {
                height: 200,
                yPadding: 'little',
                _title: 'Задания',
            }
        }
        ]

        return [...components,
        {
            component: PersonQuestsPlashka,
            cell_x: 6,
            collapse: 1,
            id: 73999,
            componentProps: {
                height: 200,
                yPadding: 'little',
                _title: 'Задания',
            }
        }
        ]
    }

    isAddingBatizadoCordaos = (components) => {
        const { page } = this.state;
        const { isEventReady, isMobile } = this.props;

        if (page !== 'batizadocordaos' || !isEventReady) return [...components]

        if (isMobile) return [...components,
        {
            component: EventBatizadoCordaosPlashka,
            cell_x: 3,
            collapse: 1,
            id: 339,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Участники',
            }
        }
        ]

        return [...components,
        {
            component: EventBatizadoCordaosPlashka,
            cell_x: 4,
            collapse: 1,
            id: 339,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Участники',
            }
        }
        ]
    }


    isAddingShadule = (components) => {
        const { page, categoryId, lookedPersonId, byPersons } = this.state;
        const { isChampionshipReady, Championship, isMobile } = this.props;
        if (page !== 'shadule' || !isChampionshipReady || !Championship) return [...components];

        const {
            ShaduleReady, BriketsReady
        } = Championship;

        let _title = '';
        if (ShaduleReady && !BriketsReady) _title = 'Расписание'; else _title = 'Сетки';

        if (isMobile) return [...components,
        {
            component: EventShadulePlashka,
            cell_x: 3,
            collapse: 1,
            id: 11,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title,
                linkTo: this.linkTo,
                shaduleCategoryId: categoryId,
                byPersons,
                lookedPersonId
            }
        }
        ]

        return [...components,
        {
            component: EventShadulePlashka,
            cell_x: 4,
            collapse: 1,
            id: 11,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title,
                linkTo: this.linkTo,
                shaduleCategoryId: categoryId,
                byPersons,
                lookedPersonId
            }
        }
        ]
    }

    isAddingResults = (components) => {
        const { page, categoryId } = this.state;
        const { isChampionshipReady, Championship, isMobile, Event } = this.props;

        if (Event && Event.Type === 'challenge') {
            if (page !== 'results') { return [...components] }
            const { SettingsObj } = Event;
            if (!SettingsObj || !SettingsObj.ChallengeSettings || !SettingsObj.ChallengeSettings.DoShowResults) { return [...components] };

            let EventSoloResultsComponent = EventSoloResults;
            if (SettingsObj.ChallengeSettings.SpringResults) {
                EventSoloResultsComponent = SpringSoloResults;
            }

            if (isMobile) return [...components,
            {
                component: EventSoloResultsComponent,
                cell_x: 3,
                collapse: 1,
                id: 14,
                componentProps: {
                    height: 700,
                    yPadding: 'little',
                    _title: 'Результаты',
                    linkTo: this.linkTo
                }
            }
            ]

            return [...components,
            {
                component: EventSoloResultsComponent,
                cell_x: 4,
                collapse: 3,
                id: 14,
                componentProps: {
                    height: 700,
                    yPadding: 'little',
                    _title: 'Результаты',
                    linkTo: this.linkTo
                }
            }
            ]

        }


        if (page !== 'results' || !isChampionshipReady || !Championship) return [...components];

        const {
            ShowResults
        } = Championship;

        if (!ShowResults) return [...components];

        if (isMobile) return [...components,
        {
            component: EventResultsPlashka,
            cell_x: 3,
            collapse: 1,
            id: 14,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Результаты',
                linkTo: this.linkTo,
                shaduleCategoryId: categoryId
            }
        }
        ]

        return [...components,
        {
            component: EventResultsPlashka,
            cell_x: 4,
            collapse: 1,
            id: 14,
            componentProps: {
                height: 700,
                yPadding: 'little',
                _title: 'Результаты',
                linkTo: this.linkTo,
                shaduleCategoryId: categoryId
            }
        }
        ]
    }

    isAddingSchoolsResults = (components) => {
        const { page } = this.state;
        const { isChampionshipReady, Championship, isMobile } = this.props;
        if (page !== 'results' || !isChampionshipReady || !Championship) return [...components];

        const {
            ShowResults
        } = Championship;

        if (!ShowResults) return [...components];

        if (isMobile) return [...components,
        {
            component: EventSchoolResultsPlashka,
            cell_x: 3,
            collapse: 1,
            id: 16,
            componentProps: {
                height: 300,
                yPadding: 'little',
                _title: 'Школьный зачет'
            }
        }
        ]

        return [...components,
        {
            component: EventSchoolResultsPlashka,
            cell_x: 6,
            collapse: 1,
            id: 16,
            componentProps: {
                height: 300,
                yPadding: 'little',
                _title: 'Школьный зачет'
            }
        }
        ]
    }

    isAddingAllResults = (components) => {
        const { page } = this.state;
        const { isChampionshipReady, Championship, isMobile } = this.props;
        if (page !== 'results' || !isChampionshipReady || !Championship) return [...components];

        const {
            ShowResults
        } = Championship;

        if (Championship.Id !== 67) return [...components];
        if (!ShowResults) return [...components];

        if (isMobile) return [...components,
        {
            component: ButtonPlashka,
            cell_x: 3,
            collapse: 1,
            id: 1787879,
            componentProps: { height: 50, yPadding: 'usual', text: 'ПОЛНЫЙ ЗАЧЕТ', linkTo: '/medals/148' }
        },
        ]

        return [...components,
        {
            component: ButtonPlashka,
            cell_x: 6,
            collapse: 1,
            id: 1787879,
            componentProps: { height: 70, yPadding: 'usual', text: 'ПОЛНЫЙ ЗАЧЕТ', linkTo: '/medals/148' }
        },
        ]
    }

    isAddingGroupsResults = (components) => {
        const { page } = this.state;
        const { isChampionshipReady, Championship, iamuser, isMobile } = this.props;
        if (page !== 'results' || !isChampionshipReady || !Championship) return [...components];
        if (!iamuser || (!iamuser.GroupIamInId && !iamuser.GroupId)) return [...components];

        const {
            ShowResults
        } = Championship;

        if (!ShowResults) return [...components];

        if (isMobile) return [...components,
        {
            component: EventGroupResultsPlashka,
            cell_x: 3,
            collapse: 1,
            id: 40,
            componentProps: {
                height: 100,
                yPadding: 'little',
                _title: 'Результат вашей группы'
            }
        }
        ]

        return [...components,
        {
            component: EventGroupResultsPlashka,
            cell_x: 6,
            collapse: 1,
            id: 40,
            componentProps: {
                height: 100,
                yPadding: 'little',
                _title: 'Результат вашей группы'
            }
        }
        ]
    }

    isAddingMiniPersonResult = (components) => {
        const { page } = this.state;
        const { isChampionshipReady, Championship, iAmCompetitor, isMobile } = this.props;
        if (page !== 'results' || !isChampionshipReady || !Championship) return [...components];

        const {
            ShowResults
        } = Championship;

        if (!ShowResults) return [...components];
        if (!iAmCompetitor || !iAmCompetitor.Place) return [...components];

        if (isMobile) return [...components,
        {
            component: EventResultsMiniPersonPlashka,
            cell_x: 3,
            collapse: 1,
            id: 18,
            componentProps: {
                height: 300,
                yPadding: 'little',
                _title: 'Ваш результат'
            }
        }
        ]

        return [...components,
        {
            component: EventResultsMiniPersonPlashka,
            cell_x: 6,
            collapse: 1,
            id: 18,
            componentProps: {
                height: 300,
                yPadding: 'little',
                _title: 'Ваш результат'
            }
        }
        ]
    }

    //iAmCompetitor
    isAddingDiscription = (components) => {

        const { isMobile, Event } = this.props;
        if (Event.SettingsObj && Event.SettingsObj.SpecialMain && Event.SettingsObj.SpecialMain.LiteVersion) return [...components];

        if (isMobile) return [...components,
        {
            cell_x: 1,
            collapse: 4,
            componentProps: { height: 14 }
        },
        {
            component: EventDiscriptionPlashka,
            cell_x: 1,
            collapse: 4,
            id: 5,
            componentProps: { height: 106, yPadding: 'little' }
        }
        ]

        return [...components,
        {
            component: EventDiscriptionPlashka,
            cell_x: 1,
            collapse: 7,
            id: 5,
            componentProps: { height: 86, yPadding: 'little' }
        }
        ]
    }

    isAddingParticipatorsList = (components) => {

        const { isMobile } = this.props;

        if (isMobile) return [...components]

        const { page } = this.state;
        // const { isChampionshipReady, Championship } = this.props;
        if (page !== 'admin' && page !== 'group') return [...components];

        return [...components,
        {
            component: ButtonPlashka,
            cell_x: 2,
            collapse: 1,
            id: 3319,
            componentProps: { height: 50, yPadding: 'usual', text: 'СПИСОК УЧАСТНИКОВ', onClick: this.showParticipatorsList }
        },
        ]
    }

    isAddingUi = (components) => {

        const { page } = this.state;
        const { Event: { Id: eventId, Type, SettingsObj } = { Id: 0 }, isChampionshipReady, Championship, iAmAdmin, iamuser, eventStatus, isMobile, GroupCount } = this.props;

        let Items = [
            { Title: 'Инфо', name: 'info', icon: 'info', linkTo: `/event/${eventId}/${this.linkTo('page', 'info')}` },
        ]

        /*
          ShaduleReady
          BriketsReady
          CloseStart
          FieldsNumber
          Finished
          ShaduleReady
          ShowResults
        */

        if (Type === 'challenge' && !this.isFizuha ) {
            Items.push({ Title: 'Раунды', name: 'rounds', icon: 'categorys', linkTo: `/event/${eventId}/${this.linkTo('page', 'rounds')}` });
            Items.push({ Title: 'Участники', name: 'users', icon: 'shadule', linkTo: `/event/${eventId}/${this.linkTo('page', 'users')}` });
        }

        if (Type === 'championship' && isChampionshipReady && Championship) {

            const {
                ShaduleReady,
                BriketsReady,
                CloseStart,
                FieldsNumber,
                ShowResults,
                Finished
            } = Championship;

            if (!ShaduleReady && !BriketsReady) {
                Items.push({ Title: 'Категории', name: 'categorys', icon: 'categorys', linkTo: `/event/${eventId}/${this.linkTo('page', 'categorys')}` });
            } else {
                let Title = '';
                if (ShaduleReady && !BriketsReady) Title = 'Распиcание'; else Title = 'Сетки';
                Items.push({ Title, name: 'shadule', icon: 'shadule', linkTo: `/event/${eventId}/${this.linkTo('page', 'shadule')}` });
            }

            if (ShowResults) {
                Items.push({ Title: 'Результаты', name: 'results', icon: 'results', linkTo: `/event/${eventId}/${this.linkTo('page', 'results')}` });
            }
        }

        if (Type === 'challenge') {
            if (SettingsObj && SettingsObj.ChallengeSettings && SettingsObj.ChallengeSettings.DoShowResults) {
                Items.push({ Title: 'Результаты', name: 'results', icon: 'results', linkTo: `/event/${eventId}/${this.linkTo('page', 'results')}` });
            }
        }

        if (Type === 'batizado' && SettingsObj && SettingsObj.BatizadoMain) {
            Items.push({ Title: 'Участники', name: 'batizadocordaos', icon: 'categorys', linkTo: `/event/${eventId}/${this.linkTo('page', 'batizadocordaos')}` });
        }


        const eventStatus_ = eventStatus() || 'undef';
        if (eventStatus_ !== 'is_preliminarily' && eventStatus_ !== 'registration_not_open')
            Items.push({ Title: 'Статистика', name: 'statistics', icon: 'statistics', linkTo: `/event/${eventId}/${this.linkTo('page', 'statistics')}` });

        if (eventStatus_ !== 'is_preliminarily' && eventStatus_ !== 'registration_not_open')
            if (iAmAdmin) {
                Items.push({ Title: 'Отметить участников', name: 'admin', icon: 'admin', linkTo: `/event/${eventId}/${this.linkTo('page', 'admin')}` });
            }

        if (eventStatus_ !== 'is_preliminarily' && eventStatus_ !== 'registration_not_open') {

            let isIAmInstructor = false;
            if (iamuser && iamuser.GroupId) {
                isIAmInstructor = true;
            }

            if (!isIAmInstructor) {
                if (iamuser && iamuser.myGroupRole) {
                    isIAmInstructor = true;
                }
            }


            if (isIAmInstructor) {
                let Title = 'Ваша группа';
                if (GroupCount) Title += ` (${GroupCount})`
                Items.push({ Title, name: 'group', icon: 'admin', linkTo: `/event/${eventId}/${this.linkTo('page', 'group')}` });
            }
        }

        if (isMobile) return [...components,
        {
            component: UiChooser,
            cell_x: 1,
            collapse: 4,
            id: 6,
            componentProps: {
                height: 100,
                yPadding: 'usual',
                tab: page,
                mobileStyle: true,
                Items
            }
        }
        ]

        return [...components,
        {
            component: UiChooser,
            cell_x: 2,
            collapse: 1,
            id: 6,
            componentProps: {
                height: Items.length === 1 ? 60 : Items.length * 45, yPadding: 'usual', tab: page, Items
            }
        }
        ]
    }
}

const EventPageWithState = withEventState(`
Event,
isEventReady,
isChampionshipReady,
Championship,

isStuffReady, 
Stuff,

isThereTable,

CategorysAsObjcet,
isCategorysReady,

Competitors,
ParticipatorsData,
Participators,
isParticipatorsReady,

reloadParticipators,
addParticipator,
checkMayIParticipateByNecessery,

addFollow,
deleteFollow,

isRoundsReady,

isThereAdmissions,

GroupCount,
ServerQuests,

iAmCompetitor,
iAmParticipator,
iAmAdmin,
iAmJudge,
iAmFollow,

location,
setBackground,

eventStatus,

showInfoModal,

client,
iamuser,
isMobile
`)(EventPage);

const EventPageWithContent = (props) => <EventState {...props}> {EventPageWithState()} </EventState>

export default EventPageWithContent;