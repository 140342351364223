import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import PersonFrkAccredApplicationModal from './sendApplcation';
import Loader from 'src/ver2/components/loader';
import { Button, message } from 'antd';
import './styles.css';
import moment from 'moment';
import SocialIcon from 'src/ver2/components/ui/social/index.jsx'

const copyToClipboard = (value) => {
    const copytext = document.getElementById('copyCopyText');
    if (copytext) {
        copytext.value = value;
        copytext.select();
        document.execCommand('copy');
        message.success('Скопировано ' + value);
    }
}

class FkrAccredPage extends PureComponent {

    state = {
        fkrAccred: null,
        FkrApplication: null,
        status: 'loaded'
    }

    loadFkrAccred = async (id) => {
        if (!id) return;

        const fkrAccred = await this.props.client.getUserFkrAccred(id);
        if (fkrAccred)
            this.setState({ fkrAccred });

        const FkrApplications = await this.props.client.getApplications({ userId: id, type: 'fkr_accred' });
        const FkrApplication = (FkrApplications && FkrApplications.length && FkrApplications[FkrApplications.length - 1].Result !== 'accept') ?
            FkrApplications[FkrApplications.length - 1]
            : null;

        this.setState({ FkrApplication })
    }

    componentDidMount() {
        const { iamuser } = this.props;
        if (iamuser) {
            this.loadFkrAccred(iamuser.Id);
        }
    }

    componentDidUpdate({ iamuser }) {
        if (!iamuser && this.props.iamuser) {
            this.loadFkrAccred(this.props.iamuser.Id);
        }
    }

    onAdmitClick = async (values) => {
        const { client, iamuser } = this.props;
        this.setState({ status: 'loading' });
        await client.addApplication({ type: 'fkr_accred', values });
        await this.loadFkrAccred(iamuser.Id)
        this.setState({ status: 'loaded' });
    }

    render() {

        const { iamuser, isMobile } = this.props;
        const { fkrAccred, status } = this.state;

        if (!iamuser) {
            return <div className='fcr_accred_page_main'>
                <this.Title />
                <div className='fcr_accred_page_main_content_noenter'>
                    <span>
                        Вы не зашли в своё профиль. <br />
                        Нажмите на кнопку ВОЙТИ сверху справа
                    </span>
                </div>
            </div>
        }

        if (status === 'loading') {
            return <div className='fcr_accred_page_main'>
                <this.Title />
                <div className='fcr_accred_page_main_content_noenter'>
                    <Loader text='Подгружаю заявку' />
                </div>
            </div>
        }

        let isFkrOkey = false;
        let lastFkrAccred = null;

        if (fkrAccred) lastFkrAccred = fkrAccred[fkrAccred.length - 1];

        const FkrLogo = () => <img src="/../images/ver2/fkr_logo.png" />

        // if (!lastFkrAccred || !(lastFkrAccred.ServedTo && moment(lastFkrAccred.ServedTo, 'YYYY.MM.DDTHH:mm').isAfter(moment()))) {
        if (!lastFkrAccred || !(lastFkrAccred.ServedTo && moment(lastFkrAccred.ServedTo, 'YYYY.MM.DDTHH:mm').isAfter(moment('31.12.2024', 'DD.MM.YYYY')))) {

            const { FkrApplication } = this.state;
            if (!FkrApplication) {
                return <div className='fcr_accred_page_main'>
                    <this.Title />
                    <div className='fcr_accred_page_main_content'>
                        <PersonFrkAccredApplicationModal onAdmitClick={this.onAdmitClick} />
                    </div>
                </div>
            }

            const { Values } = FkrApplication;

            let downText = '';
            if (Values === '1h') downText = 'полсезона'; //'полсезона';
            if (Values === '2h') downText = 'сезон';

            let style = {}
            if (isMobile) {
                style.width = isMobile.width;
            }

            return <div className='fcr_accred_page_main' style={style}>
                <this.Title />
                <div className='fcr_accred_page_main_wrapper'>

                    <div className='fcr_accred_page_main_content_minititle'>
                        {Values === '1h' ? 'Ожидается оплата за полсезона 1000р' : 'Ожидается оплата за сезон 1500р'}
                    </div>
                    <div className='fcr_accred_page_main_content'>
                        Ваша заявка на членство на {downText} принята. <br />
                        Для подтверждения заявки внесите оплату.
                    </div>

                    <div className='fcr_accred_page_main_wrapper_step'>ШАГ 1</div>
                    <div className='fcr_accred_page_main_wrapper_step_little'>Внесите оплату</div>

                    <div className='fcr_accred_page_main_wrapper_step_wrapper'>
                        <div className='fcr_accred_page_main_wrapper_step_1_inner'>
                            <div className='fcr_accred_page_main_wrapper_step_1_inner_left'>
                                <span>Совершить перевод на карту <b>Сбербанк</b></span> <br />
                                <span><b>НОМЕР КАРТЫ:</b> 4817 7602 3641 7196</span>
                                <span><b>ИЛИ ПО ТЕЛЕФОНУ:</b> +7 926 014 7285</span> <br />
                                <span><b>ПОЛУЧАТЕЛЬ:</b> Анастасия Дмитриевна К</span>
                            </div>
                            <div className='fcr_accred_page_main_wrapper_step_1_inner_right'>
                                <div onClick={() => copyToClipboard('4817 7602 3641 7196')} className='fcr_accred_page_main_wrapper_step_1_inner_right_button'>
                                    КОПИРОВАТЬ <br />
                                    КАРТУ
                                </div>
                                <div onClick={() => copyToClipboard('+7 926 014 7285')} className='fcr_accred_page_main_wrapper_step_1_inner_right_button'>
                                    КОПИРОВАТЬ <br />
                                    ТЕЛЕФОН
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='fcr_accred_page_main_wrapper_step'>ШАГ 2</div>
                    <div className='fcr_accred_page_main_wrapper_step_little'>Отправьте подтверждение</div>

                    <div className='fcr_accred_page_main_wrapper_step_wrapper fcr_accred_page_main_wrapper_step_2'>
                        <div className='fcr_accred_page_main_wrapper_step_1_inner'>
                            <div className='fcr_accred_page_main_wrapper_step_1_inner_left'>
                                <span>Подтверждение платежа высылается на</span>
                                <span><b>TELEGRAM</b> или <b>WHATSAPP</b> </span>
                                <span>по номеру телефона +7 926 014 7285</span>
                            </div>
                            <div className='fcr_accred_page_main_wrapper_step_1_inner_right'>
                                <SocialIcon size={80} type='telegram' src='timida_sf' />
                                <SocialIcon size={80} type='wu' src='+79260147285' />
                            </div>
                        </div>

                        <div className='fcr_accred_page_main_wrapper_step_2_downed'>
                            В сообщении обязательно указать ФИО спортсмена за которого производится оплата.
                            <br />В течение максимум 15 часов вы получаете подтверждение оплаты, о чем свидетельствует плашка членства у Вас на странице
                            <br />В случае отсутствия подтверждения – свяжитесь с администратором портала по указанным на сайте контактам.
                        </div>
                    </div>

                    <div className='fcr_accred_page_main_wrapper_step'>ШАГ 3</div>
                    <div className='fcr_accred_page_main_wrapper_step_little'>Подпишитесь на нас ;)</div>
                    <div className='fcr_accred_page_main_wrapper_step_wrapper fcr_accred_page_main_wrapper_step_2'>
                        <div className='fcr_accred_page_main_wrapper_step_1_inner'>
                            <div className='fcr_accred_page_main_wrapper_step_1_inner_left'>
                                <span>Подпишитесь на нас в <b>TELEGRAM</b></span>
                                <span><b>Это не отнимет много времени</b></span>
                            </div>
                            <div className='fcr_accred_page_main_wrapper_step_1_inner_right'>
                                <SocialIcon size={80} type='telegram' src='capoeirasport_official' />
                            </div>
                        </div>

                        <div className='fcr_accred_page_main_wrapper_step_2_downed'>
                            Оставайтесь с нами на связи, получайте актуальные новости по мероприятиям, активностям и нововведениям портала!
                        </div>
                    </div>
                </div>
            </div>
        }

        return <div className='fcr_accred_page_main'>
            <this.Title />
            <div className='fcr_accred_page_main_allokey'>
                {FkrLogo()}
                <span>
                    Членство оформлено <br /> до {moment(lastFkrAccred.ServedTo, 'YYYY.MM.DDTHH:mm').format('DD.MM.YYYY')}
                </span>
            </div>
        </div>
    }

    Title = () => <div className='fcr_accred_page_main_title'>ОФОРМЛЕНИЕ ЧЛЕНСТВА</div>

}


export default withCapoeiraSportState('iamuser, isMobile, client')(FkrAccredPage);