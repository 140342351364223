import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import LittleOperation from 'src/ver2/components/operations/little.jsx';
import LittlePerson from 'src/ver2/components/person/little';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class ShopBuyersPlashka
// classname: shop_buyers_plashki

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopBuyersPlashka extends PureComponent {

    render() {

        const { upperStyle, isShopBuyersReady, ShopBuyers } = this.props;
        const { width, height } = upperStyle;

        if (!isShopBuyersReady) return <div style={{ width, height }} className='shop_buyers_plashki'>
            <Loader />
        </div>

        let ShopBuyers_ = ShopBuyers ? [...ShopBuyers] : [];
        ShopBuyers_.reverse();

        let max = 30;

        if (ShopBuyers_ && ShopBuyers_.length > 0) {
            return <div style={{ width, height }} className='shop_buyers_plashki'>
                {ShopBuyers_.map((Operation, index) => {

                    if (index > max) return null;

                    return <div className='shop_buyers_plashki_onerow'>
                        <div className='shop_buyers_plashki_onerow_user'>
                            <LittlePerson personId={Operation.AuthorId} height={60} />
                        </div>
                        <LittleOperation key={Operation.Id} Operation={Operation} />
                    </div>
                }
                )}
            </div>
        }

        return <div style={{ width, height }} className='shop_buyers_plashki'>
            Нет покупателей
        </div>
    }

}

export default withShopState(`

ShopBuyers,
isShopBuyersReady,

iamuser,
client
`)(ShopBuyersPlashka);