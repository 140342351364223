import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Icon, Modal, Input } from 'antd';
import { orderBy, groupBy } from 'lodash';
import './styles.css';
import { json } from 'd3-request';


/**
 * Отображает график один по входным данным Data
 * принимает
 * width
 * height
 * data: {
 * 
 *    axes : {
 *         higherY: 500,
 *         legendsX: [
 *              { title: '111'},
 *              { title: '222 }
 *          ]
 *    },
 *    data: {
 *          dots: [
 *              { x: 0, y: 100 }
 *          ],
 *          title: 'Хуева туча'
 *    }
 * 
 * }
 * size ( 'little' ) относительно
 * theme = { 'dark', 'white', 'nobg' }
 */

export default class StatsGraphics extends PureComponent {

    get width() { return this.props.width || 200; }
    get height() { return this.props.height || 200; }

    render() {

        const { size } = this.props;

        const {
            width,
            height
        } = this;

        let className = 'graph_main';
        if (size === 'little') className += ' graph_main__little' 

        return <div className={className} style={{ width, height }}>
            <svg style={{ width, height }} version="1.1" viewBox={`0 0 ${width} ${height}`} id={this.thisId}>
                <this.AxesComponent />
            </svg>
        </div>
    }

    AxesComponent = () => {

        const STEP_LONG = 1;

        const { size } = this.props;
        const r_circles =  size === 'little' ? '6' : '11';

        const {
            width,
            height
        } = this;

        const { data: { data, axes, unit = '', graphicsTitle = '' } } = this.props;
        if (!data || !data.length || !axes) return null;

        const {
            legendsX,
        } = axes;

        let higherY = axes.higherY;
        let yStep = axes.yStep;
        if (!higherY) {
            higherY = 0;
            for (let i = 0; i < data.length; i++) {
                const { dots } = data[i];
                for (let j = 0; j < dots.length; j++) {
                    const { y } = dots[j];
                    if (y > higherY) {
                        higherY = y;
                    }

                }

            }
        }

        higherY = Number((higherY * 1.1).toFixed());

        console.log ( { higherY }) 

        let Ysteps = [];
        let Xsteps = [];
        let dots = [];
        let texts = [];
        let allTextsEnds = {};
        let circles = [];

        const ONE_Y_STEP = yStep || (higherY / 5).toFixed();
        // сколько влезает полос
        const yCount = Number ( (higherY / ONE_Y_STEP).toFixed() )
        higherY = (yCount) * ONE_Y_STEP;

        const Y_VALUE_PER_PIXEL = height / higherY;

        const X_STEP_OUT = width * 0.1;
        const ALL_DRAW_WIDTH = width * 0.8;
        const X_STEP = ALL_DRAW_WIDTH / (legendsX.length - 1);


        for (let i = 1; i < (yCount + 1); i++) {
            Ysteps.push(<line className='graphics_main_line_axes_support' x1={0} y1={(height / yCount) * i} x2={width * STEP_LONG} y2={(height / yCount) * i} />)
            Ysteps.push(<text className='graphics_main_text_axes graphics_main_text_axes_y' x={width * STEP_LONG} y={ 5 + height - ((height / yCount) * i)}> {`${ONE_Y_STEP * i} ${unit}`} </text>)
        }

        for (let i = 0; i < legendsX.length; i++) {
            Ysteps.push(<line className='graphics_main_line_axes_support' x1={X_STEP_OUT + i * X_STEP} y1={height} x2={X_STEP_OUT + i * X_STEP} y2={height - height * STEP_LONG} />)
            Ysteps.push(<text className='graphics_main_text_axes graphics_main_text_axes_x' x={X_STEP_OUT + i * X_STEP} y={height}> {legendsX[i].title} </text>)
        }

        for (let i = 0; i < data.length; i++) {

            let color = 'red';
            switch (i) {
                case 0: color = 'red'; break;
                case 1: color = 'gold'; break;
                case 2: color = 'green'; break;
                case 3: color = 'blue'; break;
                case 4: color = 'purple'; break;
                case 5: color = 'orange'; break;
                case 6: color = 'lime'; break;
                default: { }
            }
            for (let j = 0; j < data[i].dots.length; j++) {

                const nowX = X_STEP_OUT + data[i].dots[j].x * X_STEP;
                let nowY = height - data[i].dots[j].y * Y_VALUE_PER_PIXEL;

                circles.push(
                    <circle className='graphics_main_circle_axes' style={{ stroke: color }} cx={nowX} cy={nowY} r={r_circles} />
                );
                texts.push(
                    <text className='graphics_main_text_axes graphics_main_text_value' x={nowX} y={nowY}>{ `${data[i].dots[j].y} ${unit}`}</text>
                );
                if (j > 0) {
                    const lastX = X_STEP_OUT + data[i].dots[j - 1].x * X_STEP;
                    const lastY = height - data[i].dots[j - 1].y * Y_VALUE_PER_PIXEL;
                    dots.push(
                        <line className='graphics_main_line_data' style={{ stroke: color }} x1={lastX} y1={lastY} x2={nowX} y2={nowY} />
                    );
                }
                if (j === 0) {
                    texts.push(
                        <text className='graphics_main_text_axes graphics_main_text_datatitle_left' x={nowX} y={nowY}>{data[i].title}</text>
                    );
                }
                if (j === data[i].dots.length - 1) {

                    const shiftY = (allTextsEnds[nowY] || 0) * 20;

                    texts.push(
                        <text className='graphics_main_text_axes graphics_main_text_datatitle_right' style={{ stroke: color }} x={nowX} y={nowY + shiftY}>{data[i].title}</text>
                    );
                    if (!allTextsEnds[nowY]) allTextsEnds[nowY] = 0;
                    allTextsEnds[nowY]++;
                }
            }

        }

        return <React.Fragment>
            <line className='graphics_main_line_axes' x1={0} y1={height} x2={0} y2={0} />
            <line className='graphics_main_line_axes' x1={0} y1={height} x2={width} y2={height} />
            <text className='graphics_main_text_axes graphics_main_text_main_title' x = {10} y = {height}>{graphicsTitle}</text>
            {Ysteps}
            {dots}
            {circles}
            {texts}
        </React.Fragment>
    }

}