import React, { Component } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import ButtonPlashka from 'src/ver2/plashki/ui/button';

import './style.css';

/*

*/

class RaitingUpdate extends Component {


    state = {
        status: 'none',
        text: ''
    }

    // сначала мы удаляем весь рейтинг nowRaiting
    startUpdateRaiting = () => {
        this.setState({ status: 'deleting' });
        const { client } = this.props;

        const nowRaiting = client.nowRaiting;

        client.deleteRaiting(nowRaiting).then(result => {
            if (result.error) {
                return this.setState({ status: 'error', text: result.error })
            }
            return this.setState({ status: 'renew', text: `` })
        })
    }

    renewRaiting = () => {
        const { client } = this.props;

        const nowRaiting = client.nowRaiting;

        const SeasonYStart = nowRaiting.slice(0, 4);
        const SeasonYEnd = nowRaiting.slice(5);

        const LookedY = '2024';
        const LookedMD = '0722'; // ЭТО МЕСЯЦ, ПОТОМ ДЕНЬ, 0231 за какой мы смотрим рейтинг 2022 02 31

        const type = 'comp_pro';
        this.setState({ text: type })

        client.renewRaiting({ SeasonYStart, SeasonYEnd, LookedY, LookedMD, type }).then(result => {
            if (result.error) {
                return this.setState({ status: 'error', text: result.error })
            }
            const type = 'comp_not_pro';
            
            this.setState({ text: type });

            client.renewRaiting({ SeasonYStart, SeasonYEnd, LookedY, LookedMD, type }).then(result => {
                if (result.error) {
                    return this.setState({ status: 'error', text: result.error })
                }
                return this.setState({ status: 'update', text: `0%` })
            })

        })
    }


    updatePlaces = async () => {
        const { client } = this.props;
        const nowRaiting = client.nowRaiting;

        this.setState({ status: 'update', text: `0%` })

        const allProgr = Object.keys(client.RaitingTypeNames).length * Object.keys(client.CategoryNames).length;
        let progr = 0;

        for (let t = 0; t < Object.keys(client.RaitingTypeNames).length; t++) {
            for (let c = 0; c < Object.keys(client.CategoryNames).length; c++) {
                const season = nowRaiting;

                const type = Object.keys(client.RaitingTypeNames)[t];
                const category = Object.keys(client.CategoryNames)[c];

                const result = await client.getSubjectRaitingFromServer({ subject: 'user', season, as_sum: true, type, category })

                for (let i = 0; i < result.length; i++) {

                    const {
                        UserId: userId,
                        Season: season,
                        Type: type,
                        Category: category,
                        Place: place,
                        PlaceName: placeName
                    } = result[i];

                    await client.setUserRaiting({ userId, season, type, category, place, placeName })
                }

                progr++;
                const localProgr = (100 * progr / allProgr).toFixed(0);
                this.setState({ text: `${localProgr}%` })
            }
        }

        this.setState({ status: 'done' })
    }

    componentDidUpdate({ }, { status }) {
        if (status !== this.state.status) {
            if (this.state.status === 'renew') {
                this.renewRaiting();
            }
            // пока заглуха
            if (this.state.status === 'update') {
                this.updatePlaces();
            }
        }
    }

    Status = () => {
        const { status, text } = this.state;

        if (status === 'none') {
            return null;
        }

        if (status === 'deleting') {
            return <div>Удаление рейтинга</div>
        }

        if (status === 'error') {
            return <div>Ошибка {text}</div>
        }

        if (status === 'renew') {
            return <div>Обновляю рейтинг компетиторов {text}</div>
        }

        if (status === 'update') {
            return <div>Обновляю места компетиторов {text}</div>
        }

        if (status === 'done') {
            return <div>Готово!</div>
        }

        return null;
    }

    render() {

        const { startUpdateRaiting } = this;
        const { status } = this.state;
        const { client } = this.props;
        const nowRaiting = client.nowRaiting;

        return <div className='raiting_update_container'>

            <div>Будет обновлён рейтинг {nowRaiting}</div>
            <br />
            <this.Status />
            <br />
            <ButtonPlashka
                upperStyle={{ width: 100, height: 40 }}
                disabled={status !== 'none' && status !== 'done'}
                disabledDiscription={'Идёт загрузка'}
                onClick={startUpdateRaiting}
                text={status === 'loading' ? 'ЗАГРУЖАЕТСЯ' : 'ОБНОВИТЬ'}
            />
        </div>
    }



}


export default withCapoeiraSportState('iamuser, client')(RaitingUpdate);