import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class JudgeVideoRulesPlashka
// classname: judge_videorules_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class JudgeVideoRulesPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { upperStyle, ytTitle, ytUrl  } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='judge_videorules_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='judge_videorules_plashka'>
            <iframe
                width="100%"
                height="100%"
                src={ytUrl}
                title={ytTitle}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe>
        </div>
    }

}

export default withCapoeiraSportState(`
client,
iamuser,
`)(JudgeVideoRulesPlashka)