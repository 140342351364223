import * as THREE from 'three';

export const createBoxFon = ( textureMaterial ) => {

    /*Хуйня */

    const parent_Mesh =  new THREE.Mesh(new THREE.BoxGeometry(0, 0, 0), new THREE.MeshBasicMaterial({
      color: "#FFFFFF00",
      wireframe: true
  }));

  const geometry = new THREE.PlaneBufferGeometry(
  1.5, 1.5, 30, 30);        

  const material = new THREE.ShaderMaterial (
  {
      uniforms: {
      u_gold: {value: textureMaterial},
      Time: {value: -4},
      goldy : {value: 0}
      },
      fragmentShader: document.getElementById("spaceTestFragmentShader3").innerHTML, 
      vertexShader: document.getElementById("spaceTestVertexShader").innerHTML
  }
  )

  material.transparent = true;

  const mesh = new THREE.Mesh(geometry, material);
 

  parent_Mesh.add(mesh);


  return parent_Mesh;

}