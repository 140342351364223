import React, { PureComponent } from 'react';

import { withRoundState } from 'src/ver2/pages/round/context';
import TrefaText from 'src/ver2/components/trefaText';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class RoundInfoPlashka
// classname: round_info_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RoundInfoPlashka extends PureComponent {

    resize = (heigth) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: heigth + 100, plashkaId });
    }

    componentDidMount() {
        const { Round } = this.props;
        const { Description } = Round;

        if (Description && Description.length > 200) this.resize(180);
    }

    render() {

        const { Round, isRoundReady, upperStyle, innerId } = this.props;
        const { width, height } = upperStyle;

        if (!isRoundReady) return <div style={{ width, height }} className='round_info_plashka'>
            <Loader text='Загрузка описания' />
        </div>

        const { Description } = Round;
        const description_arr = Description.split('/');

        return <div style={{ width, height }} className='round_info_plashka'>
            <div id={innerId} className='round_info_plashka_inner'>
                {description_arr.map(item => [item, <br />])}
            </div>
        </div>
    }

}

export default withRoundState(`
Round,
isRoundReady,  
client,
`)(RoundInfoPlashka);