import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';

import Loader from 'src/ver2/components/loader';
import { orderBy, groupBy } from 'lodash';
import { SoloMiniSequenceRow } from 'src/ver2/containers/challenges/components/mini-sequence-row';
import { getElementsScoresByJudging_arrays, getElementsScoresByJudgingOptions, parseSEquenceOptions } from 'src/ver2/containers/challenges/helpers';
import LittlePerson from 'src/ver2/components/person/little';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventSoloResults
// classname: event_solo_results

/**
  Это просто типовая плашка, копировать отсюда
 */

const ACHIVMENTS = {
    king: {
        title: 'ВЗЯТИЕ РАУНДОВ',
        text: 'Очки достижений выдаются за топ-5 нахождение в раундах'
    },
    lead: {
        title: 'КОЛИЧЕСТВО БАЛЛОВ',
        text: 'Очки достижений выдаются за топ-5 суммарно набранных баллов за все связки'
    },
    smooth: {
        title: 'СВЯЗНОСТЬ',
        text: 'Очки достижений выдаются за топ-5 сумманрно набранных баллов связности за все связки'
    },
    fly: {
        title: 'ПРЫГУЧЕСТЬ',
        text: 'Очки достижений выдаются за топ-5 сумманрно набранных баллов за все прыжковые элементы'
    },
    attack: {
        title: 'АТАКА',
        text: 'Очки достижений выдаются за топ-5 сумманрно набранных баллов за все ударные элементы'
    }
}

class EventSoloResults extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }
    state = {
        Tier: 3,
        ParticipatorsWithResult: [],
        Results: {}
    }

    usersTierResults = (Tier) => {

        const { Participators, SequncesConfirm, isParticipatorsReady, client } = this.props;
        if (!isParticipatorsReady) return;
        if (!SequncesConfirm || !SequncesConfirm.byUser) return;

        let ParticipatorsWithResult = [];

        let SequncesConfirmByRounds = groupBy(SequncesConfirm.all, 'RoundId');

        const elements = client.elements_as_object;

        for (let i = 0; i < Participators.length; i++) {
            const { Settings, UserId } = Participators[i];
            const SettingsObj = JSON.parse(Settings);
            const { tier } = SettingsObj;

            if (Tier.toString() !== tier.toString()) continue;
            if (!SequncesConfirm.byUser[UserId] || !SequncesConfirm.byUser[UserId].length) continue;

            // let Points = { SequenceSum: 0, RoundWin: 0, Points: 0, JumpPoints: 0, RoundsPlaces: [] };
            const Points = SequncesConfirm.byUser[UserId].reduce((memo, sequence) => {

                const { JudgeOptions, SequenceSum, Points, RoundId, Code } = sequence;
                // берём одну связку
                const { sequence_elements, sequence_options } = parseSEquenceOptions({ sequence })
                const { scores_element, scores_seq } = getElementsScoresByJudgingOptions({ sequence_elements, JudgeOptions });
                const elements_scores = getElementsScoresByJudging_arrays({ client, sequence_elements, scores_element, scores_seq, sequence_options })

                let JumpPoints = memo.JumpPoints;
                let AttackPoints = memo.JumpPoints;

                for ( let j = 0; j < elements_scores.length; j++ ) {
                    const { PerformPoints, element_key } = elements_scores[j];
                    const { type, subtypes } = elements[element_key] || {};
                    if (subtypes) {
                        for( let k = 0; k < subtypes.length; k++) {
                            if (subtypes[k] === 'jump') {
                                JumpPoints += PerformPoints;
                                break;
                            }
                        }
                    }
                    if (type === 'strike') {
                        if (type === 'jump') {
                            AttackPoints += PerformPoints;
                            break;
                        }            
                    }
                }

                // elements_scores => PerformPoints, element_key

                SequncesConfirmByRounds[RoundId] = orderBy(SequncesConfirmByRounds[RoundId], 'Points', 'desc');

                let RoundsPlaces = [...memo.RoundsPlaces];
                let RoundWin = 0;
                // как считать место в раунде
                if (SequncesConfirmByRounds[RoundId]) {
                    const pos = SequncesConfirmByRounds[RoundId].map(e => e.Code).indexOf(Code);
                    if (pos === 0) { RoundWin = 10; RoundsPlaces.push(1); }
                    if (pos === 1) { RoundWin = 6; RoundsPlaces.push(2); }
                    if (pos === 2) { RoundWin = 3; RoundsPlaces.push(3); }
                    if (pos === 3) { RoundWin = 2; RoundsPlaces.push(4); }
                    if (pos === 4) { RoundWin = 1; RoundsPlaces.push(5); }
                }

                return {
                    SequenceSum: memo.SequenceSum + SequenceSum,
                    Points: memo.Points + Points,
                    RoundWin: memo.RoundWin + RoundWin,
                    RoundsPlaces,
                    JumpPoints,
                    AttackPoints
                }
            }
                , { SequenceSum: 0, RoundWin: 0, Points: 0, JumpPoints: 0, AttackPoints: 0, RoundsPlaces: [] })




            const { BirthDay } = Participators[i];
            const year = BirthDay.split('.')[2];

            const Category = year > '2008' ? 'до 14 лет' : '14+';

            ParticipatorsWithResult.push({ ...Participators[i], ...Points, tier, Category })
        }

        const leadOneOD = 5;
        let lead = orderBy(ParticipatorsWithResult, 'Points', 'desc');
        lead = lead.slice(0, 5).map((item, index) => { return { ...item, lead: (5 - index) * leadOneOD } })

        const kingOneOD = 1;
        let king = orderBy(ParticipatorsWithResult, 'RoundWin', 'desc');
        king = king.slice(0, 5).map(item => { return { ...item, king: item.RoundWin * kingOneOD } })

        const smoothOneOD = 4;
        let smooth = orderBy(ParticipatorsWithResult, 'SequenceSum', 'desc');
        smooth = smooth.slice(0, 5).map((item, index) => { return { ...item, smooth: (5 - index) * smoothOneOD } })

        const flyOneOD = 2;
        let fly = orderBy(ParticipatorsWithResult, 'JumpPoints', 'desc');
        fly = fly.slice(0, 5).map((item, index) => { return { ...item, fly: (5 - index) * flyOneOD } })
  
        const attackOneOD = 2;
        let attack = orderBy(ParticipatorsWithResult, 'AttackPoints', 'desc');
        attack = attack.slice(0, 5).map((item, index) => { return { ...item, attack: (5 - index) * attackOneOD } })

        this.setState({
            ParticipatorsWithResult, Tier,
            Results: {
                lead,
                king,
                smooth,
                fly,
                attack
            }
        });
    }

    render() {

        const { isSoloSequencesReady, isParticipatorsReady, SequncesConfirm, upperStyle, client, isMobile } = this.props;
        const { width, height } = upperStyle;

        const { ParticipatorsWithResult, Results } = this.state;

        if (!isSoloSequencesReady || !isParticipatorsReady) return <div style={{ width, height }} className='event_solo_results'>
            <Loader />
        </div>

        // const SequncesConfirmArray = orderBy(SequncesConfirm.all, 'SequenceSum', 'desc');

        return <div style={{ width, height }} className='event_solo_results'>
            {/* {SequncesConfirmArray.map((sequence) => {
                // return <div>123</div>
                // return <SoloMiniSequenceRow color_status key={`round_sequences_plashka_sequence${sequence.Id}`} sequence={sequence} client={client} isMobile={isMobile} />
            })} */}

            <div className='event_solo_results_menu'>
                <div onClick={() => { this.usersTierResults(1) }}> TIER 1 </div>
                <div onClick={() => { this.usersTierResults(2) }}> TIER 2 </div>
                <div onClick={() => { this.usersTierResults(3) }}> TIER 3 </div>
                <div onClick={() => { this.usersTierResults(4) }}> TIER 4 </div>
            </div>

            <div className='event_solo_results_container'>
                {Object.keys(Results).map(key => {
                    return <this.OneResult resultKey={key} />
                })}
            </div>
        </div>
    }

    OneResult = ({ resultKey }) => {

        const { client, isMobile } = this.props;
        const { Results } = this.state;
        const oneResult = Results[resultKey];

        return <div className='event_solo_results_one_container'>
            <div className='event_solo_results_one_container_header'>
                <img src={`/../images/ver2/challenges/${resultKey}1.png`} />
                <div className='event_solo_results_one_container_header_text'>
                    <span className='event_solo_results_one_container_header_text_title'>{ACHIVMENTS[resultKey].title}</span>
                    <span className='event_solo_results_one_container_header_text_text'>{ACHIVMENTS[resultKey].text}</span>
                </div>
            </div>
            <div className=''>
                {oneResult.map((person, index) => {

                    const OD = person[resultKey];

                    let Suffix = [];

                    if (resultKey === 'king') {
                        const { RoundsPlaces } = person;
                        Suffix.push(
                            <NumberText count={RoundsPlaces.map( (item, index) => { return (index + 1 < RoundsPlaces.length) ? (item + '/') : item })} text={'МЕСТА В РАУНДАХ'} height={35} />
                        )
                    }
                    if (resultKey === 'lead') {
                        const { Points } = person;
                        Suffix.push(
                            <NumberText count={Points} text={'БАЛЛЫ'} height={35} />
                        )
                    }
                    if (resultKey === 'smooth') {
                        const { SequenceSum } = person;
                        Suffix.push(
                            <NumberText count={SequenceSum.toFixed(1)} text={'СВЯЗНОСТЬ'} height={35} />
                        )
                    }
                    if (resultKey === 'fly') {
                        const { JumpPoints } = person;
                        Suffix.push(
                            <NumberText count={JumpPoints.toFixed(1)} text={'ОЧКИ ПРЫЖКОВ'} height={35} />
                        )
                    }
                    if (resultKey === 'attack') {
                        const { AttackPoints } = person;
                        Suffix.push(
                            <NumberText count={AttackPoints.toFixed(1)} text={'ОЧКИ АТАКИ'} height={35} />
                        )
                    }
                    
                    Suffix.push(
                        <div className='event_solo_results_one_od'>
                            <img src={`/../images/ver2/challenges/${resultKey}${index + 1}.png`} />
                            <span>
                                {OD}
                            </span>
                        </div>
                    )

                    // const Suffix = <div className='event_solo_results_one_person_suffix'>
                    //     {`ОЧКИ: ${OD}`}
                    // </div>

                    return <LittlePerson
                        height={isMobile ? 40 : 70}
                        key={'plt' + person.UserId}
                        // personId={person.Id}
                        DirectPerson={{ ...person, Id: person.UserId }}
                        personsDirect
                        Suffix={Suffix}
                    />
                })}
            </div>
        </div>
    }

}

/*
                        SequncesConfirm: {
                            all: SequncesConfirm,
                            byUser: SCbyUser
                        }
                        ,
                        isSoloSequencesReady: true
*/

export default withEventState(`
SequncesConfirm,
isSoloSequencesReady,

Participators,
isParticipatorsReady,

client,
isMobile,
iamuser,
`)(EventSoloResults)