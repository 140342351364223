// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { gsap } from 'gsap';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

const SCHEME = {
    1: {
        0: 1,
        1: 1,
        3: 1
    },
    3: {
        0: 1,
        // 1: 3,
        2: 3,
        3: 3,
        4: 3,
        // 5: 3,
        6: 1
    },
    2: {
        0: 1,
        2: 1,
        4: 1,
        6: 1
    },
    0: {
        0: 1,
        2: 1,
        5: 1,
        // 6: 1
    },
    4: {
        2: 1,
        5: 1,
        6: 1
    },
}


class Quest254 extends PureComponent {
    state = {
        answer: [
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ],
        steps: 0,
    }

    send = () => {
        // const { answer } = this.state;
        // let answerString = answer.reduce( ( memo, value) => memo + value.toString(), '');
        // this.props.sendSpecial({ data: { [answerString]: 1 } })
    }

    init = () => {
        this.setState({
            answer: [
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ],
            steps: 0
        })
    }

    stepsPLus = () => {
        let steps = this.state.steps;
        steps++;
        if (steps < 8) {
            return this.setState({ steps });
        }
        // перегрев

        for (let i = 0; i < 5; i++ ) {
            gsap.fromTo(`.quest254_switcher_${i}`,
                {
                    translateX: Math.random() * 2,
                    translateY: Math.random() * 2,
                    rotate: Math.random() * 2
                },
                {
                    translateX: Math.random() * 5,
                    translateY: Math.random() * 5,
                    rotate: Math.random() * 5,
                    duration: 0.1,
                    repeat: 3,
                    yoyo: true,
                    onComplete: () => {
                        gsap.to(`.quest254_switcher_${i}`,
                            {
                                translateX: 0,
                                translateY: 0,
                                rotate: 0,
                                duration: 1
                            }
                        )
                    }
                }
            );
        }

        for (let i = 0; i < 7; i++ ) {
            gsap.fromTo(`.quest254_lamp_${i}`,
                {
                    translateX: Math.random() * 2,
                    translateY: Math.random() * 2,
                    rotate: Math.random() * 2,
                    filter: 'drop-shadow( 0px 0px 5px yellow)'
                },
                {
                    translateX: Math.random() * 2,
                    translateY: Math.random() * 2,
                    rotate: Math.random() * 2,
                    filter: 'drop-shadow( 0px 0px 15px yellow)',
                    duration: 0.1,
                    repeat: 3,
                    yoyo: true,
                    onComplete: () => {
                        gsap.to(`.quest254_lamp_${i}`,
                            {
                                translateX: 0,
                                translateY: 0,
                                rotate: 0,
                                duration: 1,
                                filter: 'drop-shadow( 0px 0px 0px yellow)'
                            }
                        )
                    }
                }
            );
        }

        this.init();

    }

    render() {
        const className = 'quest254_container'

        return <div className={className}>
            <div className='quest254_tables'>
                <this.Lamp index={0} />
                <this.Lamp index={1} />
                <this.Lamp index={2} />
                <this.Lamp index={3} />
                <this.Lamp index={4} />
                <this.Lamp index={5} />
                <this.Lamp index={6} />
            </div>
            <div className='quest254_switchers'>
                <this.Swticher index={0} />
                <this.Swticher index={1} />
                <this.Swticher index={2} />
                <this.Swticher index={3} />
                <this.Swticher index={4} />
            </div>
            {/* <div className='quest254_button' onClick={this.init}>
                ЗАНОВО
            </div> */}
        </div>
    }

    Lamp = ({ index }) => {

        let className = `quest254_lamp quest254_lamp_${index} `;
        const { answer } = this.state;
        if (answer[index]) className += 'quest254_lamp_on'

        return <div className={className}>
        </div>
    }

    Swticher = ({ index }) => {

        const { isMobile } = this.props;
        const width = (isMobile ? isMobile.width / 6.5 : 500 / 6.5) / 2;
        const height = width * 2;
        const style = {
            width,
            height
        }

        const onClick = () => {
            let answer = [...this.state.answer];

            for (let i = 0; i < Object.keys(SCHEME[index]).length; i++) {
                const thisLampIndex = Object.keys(SCHEME[index])[i]; // это индекс лампы
                const switcherType = SCHEME[index][thisLampIndex];

                if (switcherType === 1) answer[thisLampIndex] = !answer[thisLampIndex];
                if (switcherType === 2) answer[thisLampIndex] = true;
                if (switcherType === 3) answer[thisLampIndex] = false;
            }
            gsap.to(`.quest254_switcher_${index}`, {
                translateX: 2, translateY: 2, duration: 0.5,
                onComplete: () => {
                    gsap.to(`.quest254_switcher_${index}`, { translateX: 0, translateY: 0, duration: 0.5, });
                }
            });
            this.setState({ answer });
            this.stepsPLus();
        }

        return <div onClick={onClick} style={style} className={`quest254_switcher quest254_switcher_${index}`} />
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest254);