import { createContext } from 'react';
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

const context = createContext(
    {
        defVal: 'FUUUCK YEAH'
    }
);


const initialState = {
    Combo: null,
    isComboReady: false,
}

class ComboState extends PureComponent {

    constructor(props) {
        super(props);

        this.state = initialState;

        if (props.client) {
            this.client = props.client;
        }

    }

    initialLoad = async () => {
        const { comboId } = this.props;
        const Combo = await this.comboLoad({comboId});
        if (!Combo) return this.setState({ isComboReady: false });

        this.setState({ isComboReady: true, Combo });
    }

    comboLoad = async ({ comboId }) => {

        const { client } = this.props;
        if (client.getComboById(comboId)) {
            return client.getComboById(comboId);
        }

        const result = await client.comboConstructor({
            TypeData: 'combo', ActionData: 'get', TargetId: comboId, Data: null, DataExercises: null
        })
        if (!result)
            return null;

        if (result[0]) {
            client.setCombo(result[0]);
            return result[0];
        }

        return null;
    }

    componentDidMount() {
        this.initialLoad();
    }


    render() {
        const {
            props: {
                children,
                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                isMobile
            },
            state: {
                Combo,
                isComboReady,
            },
            redirect
        } = this;

        return (
            <context.Provider value={{
                Combo,
                isComboReady,

                redirect,

                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                isMobile
            }}>
                {children}
            </context.Provider>
        )

    }
}


export default withCapoeiraSportState('iamuser, client, isMobile, showInfoModal, closeInfoModal')(ComboState);


export const withComboState = (withProps = '') => {
    return (
        (Wrapped) =>
            (props) => (
                <context.Consumer>
                    {
                        (context) => (
                            <Wrapped
                                {...props}
                                {...getWhatINeed(withProps, context)}
                            />
                        )
                    }
                </context.Consumer>
            )
    );
};

const getWhatINeed = (withProps, context) => {

    if (withProps === '') return context;

    return withProps.split(',').reduce((memo, value_) => {
        const value = value_.trim();
        if (!context[value]) {
            return memo;
        }
        return { ...memo, [value]: context[value] }
    }, {})


}