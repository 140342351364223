import { createContext } from 'react';
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { getSequenceToFixed, parseRules, getRuleContent } from 'src/ver2/containers/challenges/helpers';
import { orderBy, groupBy } from 'lodash';
import moment from 'moment';

const context = createContext(
    {
        defVal: 'FUUUCK YEAH'
    }
);

/*

  Это контекст раунда
  Собственно, полностью определяет раунд и возможность создавать в нём связку

*/


const initialState = {
    Round: null,
    isRoundReady: false,

    iAmParticipator: false,
    mayIParticipate: null,
    mayIParticipateError: '',

    UsersSequences: null,
    isUsersSequencesReady: false,

    UserElements: null, //объект с ключами
    UserUsedElements: {}, // объект, который определяет, какие элементы я использовал в этом соревновании (для откатов)
    FixedSequence: null, // массив со связкой [ { element: 'armada' }, { type: 'strike', subtype: 'round' }, ... ]

    RoundRules: null,
    OutsideRules: null,
    AllRules: null, // это такие хитрожопые большие объекты, в которых записаны правила по использованию элементов, откатам и т.д.

    RoundRulesObj: null,

    isRoundDataReady: false,
    isUserDataReady: false,

    CreatedSequence: null, // это связка, которую я вот в данный момент делаю

    InsidePage: 'info' // 'info' / 'create'
}

class RoundState extends PureComponent {

    constructor(props) {
        super(props);

        this.state = initialState;

        if (props.client) {
            this.client = props.client;
        }

    }

    initialLoad = async () => {
        const Round = await this.loadRound();
        if (!Round) return this.setState({ isRoundReady: false });

        this.setState({ isRoundReady: true, Round });
    }

    componentDidMount() {
        this.initialLoad();
    }

    componentDidUpdate({ iamuser, match }, { isRoundReady }) {
        if (match.params.roundId !== this.props.match.params.roundId) {
            this.fullReload().then(() => {
                this.initialLoad();
            })
        }

        if (this.props.iamuser !== iamuser) {
            this.checkUserStuffAsync();
            this.reloadUserData();
        }

        if (!isRoundReady && this.state.isRoundReady) {
            this.reloadRoundData();
            this.reloadUserData();
            this.reloadUsersSequences();
            this.checkUserStuffAsync();
        }

    }


    fullReload = async () => {
        this.setState(initialState);
    }


    loadRound = async () => {
        const { client, match } = this.props;
        const roundId = match.params.roundId;
        console.log('loadRound', roundId);
        const Rounds = await client.getSoloRounds(null, roundId);
        if (!Rounds || !Rounds[0]) return null;
        const Round = Rounds[0];
        return Round;
    }

    // Всякие проверки юзеров /////////

    checkUserStuffAsync = () => {
        this.checkUserStuff().then(({ iAmParticipator, mayIParticipate, mayIParticipateError }) => { this.setState({ iAmParticipator, mayIParticipate, mayIParticipateError }) })
    }

    checkUserStuff = async () => {
        const { iamuser, client } = this.props;
        const { Round } = this.state;

        if (!iamuser || !Round) return { iAmParticipator: false, mayIParticipate: false };

        let iAmParticipator = false;
        let mayIParticipate = false;
        let mayIParticipateError = 'Вы не зарегистрированы в челлендж';

        const { SoloCompetitionId, Tier, UserId, Type, DateStart, DateEnd } = Round;

        if (Type === 'daily' && UserId === iamuser.Id) {
            mayIParticipate = true;
        }

        if (Type === 'island') {
            const UserId = iamuser.Id;
            const { Settings } = Round;
            const SettingsObj = JSON.parse( Settings );
            const { IslandId } = SettingsObj;

            mayIParticipate = true;

            const whereAmI = await client.getIslandWhereAmI(IslandId);
            if (whereAmI && whereAmI.IslandId) {
                console.log('whereAmI', { whereAmI })
                const { PointId } = SettingsObj;
                if ( PointId !== whereAmI.point.Id ) {
                    mayIParticipateError = 'Вы не находитесь в башне';
                    mayIParticipate = false;
                }
            } else {
                mayIParticipateError = 'Вы не находитесь в башне';
                mayIParticipate = false;
            }
        }

        if (SoloCompetitionId) {

            const Participators = await client.getParticipators({ EventId: SoloCompetitionId });
            if (Participators && Participators.length > 0) {
                const participator_ = Participators.find(x => x.UserId === iamuser.Id);
                if (participator_) iAmParticipator = participator_;
            }

            if (iAmParticipator) {
                const { Settings } = iAmParticipator;
                const SettingsObj = JSON.parse(Settings);
                const { tier } = SettingsObj;
                const myTier = Number(tier);

                if (myTier === Tier) {
                    mayIParticipate = true;
                } else {
                    mayIParticipate = false;
                    mayIParticipateError = 'Вы зарегистрированы в другой Tier';
                }
            }

            //идём дальше - может быть мы не оплатили, если есть соответ-я настройка
            // пОКА ЗАГЛУШЕЧКА, ХОТЯ И НЕ СЛОЖНАЯ :)
            // нужно будет взять isTherePayment из event/context
            if ( Number(SoloCompetitionId) === 219 ) {
                const ParticipatorsData = await client.getParticipatorData({ EventId: SoloCompetitionId });
                if (ParticipatorsData) {
                    const ParticipatorsDataResult = groupBy(ParticipatorsData, 'UserId');
                    const ourData = ParticipatorsDataResult[iamuser.Id];
                    const checked = ourData ? ourData.find(x => x.Type === 'payment') : null;
    
                    if (!checked) {
                        mayIParticipate = false;
                        mayIParticipateError = 'Вы не оплатили взнос за участие'; 
                    }
                } else {
                    mayIParticipate = false;
                }
            }
        }

        // последнее - смотрим время начала раунда 
        if (Type !== 'daily') {
            const serverTime = await client.getServerTime();

            const DateStartSafari_ = DateStart.split('.');
            const DateStartSafari = DateStartSafari_[2] + '-' + DateStartSafari_[1] + '-' + DateStartSafari_[0];

            const DateEndSafari_ = DateEnd.split('.');
            const DateEndSafari = DateEndSafari_[0] + '-' + DateEndSafari_[1] + '-' + DateEndSafari_[2];

            const timeNow = moment(new Date(serverTime));
            const timeRound = moment(new Date(DateEndSafari)).add(21, 'hours');
            const endRound = moment(new Date(DateStartSafari)).add(21, 'hours');

            const days = timeRound.diff(timeNow, 'days') - 1;
            const isStarted = endRound.diff(timeNow, 'days') - 1;

            if (isStarted >= 0) {
                mayIParticipate = false;
                mayIParticipateError = `Раунд начнётся ${DateStart}`;
            }

            if (days <= 0) {
                const hours = timeRound.diff(timeNow, 'hours');
                if (hours <= 0) {
                    const minutes = timeRound.diff(timeNow, 'minutes');
                    if (minutes <= 0) {
                        mayIParticipate = false;
                        mayIParticipateError = `Раунд окончен`;
                    }
                }
            }
        }

        return { iAmParticipator, mayIParticipate, mayIParticipateError };
    }


    // USERS SEQUENCES

    loadUsersSequences = async () => {
        const { Round } = this.state;
        const { client } = this.props;
        if (!Round) return null;

        const { Id } = Round;

        // теперь берём все связки
        let sequences = await client.getSoloUserSequences({ RoundId: Id });
        if (sequences && sequences.length) {
            sequences = orderBy(sequences, 'Points', 'desc');
            sequences = orderBy(sequences, 'Status', 'asc');
        }

        return sequences;
    }

    reloadUsersSequences = () => {
        this.setState({ isUsersSequencesReady: false });
        this.loadUsersSequences().then(
            (UsersSequences) => {
                this.setState({ isUsersSequencesReady: true, UsersSequences });
            }
        )
    }

    // ROUND DATA //////////////////////////////////////////
    loadRoundData = async () => {
        const { Round } = this.state;
        const { client } = this.props;
        if (!Round) return {};

        // клиент будет нужен для загрузки соревнования может быть
        // const { client } = this.props;

        // начианем потихоньку всё что нам нужно прокидывать
        let FixedSequence = [];

        let RoundRules = {};
        let OutsideRules = null;
        let AllRules = null;
        let RoundRulesObj = null;

        const { Sequence, Options } = Round;
        FixedSequence = getSequenceToFixed({ Sequence, Options });

        let CreatedSequence = [];
        for (let i = 0; i < FixedSequence.length; i++) {
            CreatedSequence.push(null);
        }

        /* 
            // теперь берём все связки
        let sequences = await client.getSoloUserSequences ({RoundId: Id});
        if (sequences && sequences.length) { 
         sequences = orderBy (sequences, 'Points', 'desc');
         sequences = orderBy (sequences, 'Status', 'asc');
        }
        */

        // парсим правила раунда
        const { EnterRules } = Round;

        if (EnterRules && EnterRules !== 'all') {
            RoundRules = parseRules(EnterRules);
        }

        // тут должны подгрузить из соревнования
        // OutsideRules = parseRules(Rules);

        RoundRulesObj = getRuleContent(RoundRules, client.getServerElementsAsObject());

        AllRules = RoundRules;


        return {
            FixedSequence,
            RoundRules,
            OutsideRules,
            AllRules,
            CreatedSequence,
            RoundRulesObj
        }
    }

    reloadRoundData = () => {
        this.setState({ isRoundDataReady: false });
        this.loadRoundData().then(
            (RoundData) => {
                this.setState({ isRoundDataReady: true, ...RoundData });
            }
        )
    }

    // USER DATA //////////////////////////////////////////
    loadUserData = async () => {

        /*
            UserElements: null, //объект с ключами
            UserUsedElements: {}, // объект, который определяет, какие элементы я использовал в этом соревновании (для откатов)
        */

        const { Round } = this.state;
        if (!Round) return {};
        const { iamuser } = this.props;

        // начианем потихоньку всё что нам нужно прокидывать
        let UserElements = {};
        let UserUsedElements = {};

        console.log('loadUserData', iamuser);

        if (iamuser && iamuser.myElementsObj) {
            UserElements = iamuser.myElementsObj;
        }


        return {
            UserElements,
            UserUsedElements,
        }
    }

    reloadUserData = () => {
        this.setState({ isUserDataReady: false });
        this.loadUserData().then(
            (UserData) => {
                this.setState({ isUserDataReady: true, ...UserData });
            }
        )
    }


    redirect = (link) => {
        this.props.history.push(link);
    }

    render() {
        const {
            props: {
                children,
                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                isMobile
            },
            state: {
                Round,
                isRoundReady,

                iAmParticipator,
                mayIParticipate,
                mayIParticipateError,

                UsersSequences,
                isUsersSequencesReady,

                UserElements,
                UserUsedElements,
                FixedSequence,

                RoundRules,
                OutsideRules,
                AllRules,

                RoundRulesObj,

                isRoundDataReady,
                isUserDataReady,

                CreatedSequence,

                InsidePage
            },
            redirect
        } = this;

        return (
            <context.Provider value={{
                Round,
                isRoundReady,

                UsersSequences,
                isUsersSequencesReady,

                iAmParticipator,
                mayIParticipate,
                mayIParticipateError,

                UserElements,
                UserUsedElements,
                FixedSequence,

                RoundRules,
                OutsideRules,
                AllRules,
                RoundRulesObj,

                isRoundDataReady,
                isUserDataReady,

                CreatedSequence,

                InsidePage,

                redirect,


                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                isMobile
            }}>
                {children}
            </context.Provider>
        )

    }
}

export default withCapoeiraSportState('iamuser, client, isMobile, showInfoModal, closeInfoModal')(RoundState);


export const withRoundState = (withProps = '') => {
    return (
        (Wrapped) =>
            (props) => (
                <context.Consumer>
                    {
                        (context) => (
                            <Wrapped
                                {...props}
                                {...getWhatINeed(withProps, context)}
                            />
                        )
                    }
                </context.Consumer>
            )
    );
};

const getWhatINeed = (withProps, context) => {

    if (withProps === '') return context;

    return withProps.split(',').reduce((memo, value_) => {
        const value = value_.trim();
        if (!context[value]) {
            return memo;
        }
        return { ...memo, [value]: context[value] }
    }, {})


}