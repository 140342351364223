// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input } from 'antd';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest27 extends PureComponent {
    state = {
        editText: ''
    }

    clickOnSend = () => {

        let editText = this.state.editText;
        if (!editText || editText === '') editText = '_';

        editText = editText.toUpperCase();
        if (editText === 'БЕНГЕЛА' || editText === 'BENGELA' || editText === 'BENGUELA') {
            editText = 'BENGUELA';
        }

        this.props.sendSpecial({ data: { [editText]: 1 } })
    }



    render() {
        const { isMobile, text } = this.props;
        const { editText } = this.state;
        const className = 'quest27_container'

        return <div className={className}>
            <Input value={editText} onChange={(e) => { this.setState({ editText: e.target.value }) }} />
            <div className='quest20_container_send' onClick={this.clickOnSend}>
                <span>{ text || 'ПРОКРИЧАТЬ'}</span>
            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest27);