import React, { PureComponent } from 'react';

import { withRoundState } from 'src/ver2/pages/round/context';
import { SoloMiniSequence } from 'src/ver2/containers/challenges/components/mini-sequence';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class RoundISequencePlashka
// classname: round_sequence_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RoundISequencePlashka extends PureComponent {

    resize = (heigth) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: heigth + 100, plashkaId });
    }

    render() {

        const { Round, isRoundReady, upperStyle, client, RoundRules } = this.props;
        const { width, height } = upperStyle;

        if (!isRoundReady) return <div style={{ width, height }} className='round_sequence_plashka'>
            <Loader text='Загрузка описания' />
        </div>

        const { Sequence, Options, EndingText } = Round;

        return <div style={{ width, height }} className='round_sequence_plashka'>
            <SoloMiniSequence
                client={client}
                Sequence={Sequence}
                Options={Options}
                roundRules={RoundRules}
                EndingText={EndingText}
            />
        </div>
    }

}

export default withRoundState(`
Round,
RoundRules,
isRoundReady,  
client,
`)(RoundISequencePlashka);