import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Tooltip } from 'antd';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class OTParamsPlashka
// classname: ot_params_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class OTParamsPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    /*
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    render() {

        const { upperStyle, element, client } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='ot_params_plashka'>
        //     <Loader />
        // </div>

        const { type, Tier, Points } = element;

        return <div style={{ width, height }} className='ot_params_plashka'>
            <div className='ot_params_plashka_inside'>
                <this.Plashk text='ТИП' rightPart={
                    <Tooltip title={client.getTypeDiscription(type, true)}>
                        <img style = {{width: height * 0.6, height: height*0.6}} alt='' src={client.getTechnicNeededLogo('ty_' + type)} />
                    </Tooltip>
                } />
                <this.Plashk text='TIER' rightPart={Tier} />
                <this.Plashk text='СЛОЖНОСТЬ' rightPart={Points} />

            </div>
        </div>
    }

    Plashk = ({ text, rightPart }) => {
        return <div className='ot_params_plashka_plashk'>
            <div className='ot_params_plashka_plashk_left'>
                {text}
            </div>
            <div className='ot_params_plashka_plashk_right'>
                {rightPart}
            </div>
        </div>
    }

}

export default withCapoeiraSportState(`
client
`)(OTParamsPlashka)