import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaText from 'src/ver2/components/trefaText';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class ShopInfoPlashka
// classname: event_info_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopInfoPlashka extends PureComponent {

    onTrefaTextRender = (heigth) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: heigth + 100, plashkaId });
    }

    render() {

        const { Shop, upperStyle, Discription } = this.props;
        const { width, height } = upperStyle;

        if (!Shop) return <div style={{ width, height }} className='event_info_plashka'>
            <Loader />
        </div>

        if (Discription)
            return <div style={{ width, height }} className='event_info_plashka'>
                <div className='event_info_plashka_discription'>
                    <TrefaText trefaText={Discription.Body} onRender={this.onTrefaTextRender} />
                </div>
            </div>

        return <div style={{ width, height }} className='event_info_plashka'>
            Пока информация не задана
        </div>
    }

}

export default withShopState(`
Shop,  
client,
Discription,
isDiscriptionReady
`)(ShopInfoPlashka);