// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input } from 'antd';
import { gsap } from 'gsap';

const titles = [
    '',
    'влево',
    'вверх',
    'вниз',
    'вправо',
]

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest36 extends PureComponent {
    state = {
        editText: '',
        isOver: false
    }

    init = () => {
        this.setState({ editText: '', isOver: true })
    }

    send = (index) => {
        let editText = this.state.editText;

        editText += index.toString();

        if (editText.length >= 5) {
            this.props.sendSpecial({ data: { [editText]: 1 } })
            return this.init();
        }

        this.setState({ editText })
    }



    render() {
        const className = 'quest207_container';

        const { isOver, editText } = this.state;
        if (isOver) return <div className={className}>
            <span>
                Вы никуда не пришли. Вы боитесь заблудиться, остётся только вернуться назад и попробывать еще раз.
            </span>
        </div>

        let editArray = [];
        if (editText) {
            for (let i = 0; i < editText.length; i++) {
                editArray.push(editText[i]);
            }
        }

        return <div className={className}>
            <div className='quest207_container_path'>
                {editArray.map((path) => {
                    return <span>
                        {titles[path]}
                    </span>
                })}
            </div>
            <div className='quest207_container_tables'>
                <this.Table index={1} />
                <this.Table index={2} />
                <this.Table index={3} />
                <this.Table index={4} />
            </div>
        </div>

        // const AAA =
        //     { "correctAnswers": [
        //         { "correctAnswer": { "22433": 1 }, "Message": "Сфера жужжит!", "moveTo": { "type": "point", "pointId": 209 } },
        //         { "correctAnswer": { "42124": 1 }, "Message": "Сфера жужжит!", "moveTo": { "type": "point", "pointId": 208 } }
        //     ] }
    }

    Table = ({ index }) => {

        const { isMobile } = this.props;
        const width = isMobile ? isMobile.width / 5.5 : 500 / 5.5;
        const height = width * 1.18;
        const style = {
            width,
            height
        }

        const title = titles[index];


        const onClick = () => {
            gsap.to(`.quest207_table_${index}`, {
                translateX: 2, translateY: 2, duration: 0.5,
                onComplete: () => {
                    gsap.to(`.quest207_table_${index}`, { translateX: 0, translateY: 0, duration: 0.5 });
                }
            });
            this.send(index);
        }

        return <div onClick={onClick} style={style} className={`quest207_table quest207_table_${index}`} >
            <span>
                {title}
            </span>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest36);