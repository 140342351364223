import React, { Component } from 'react';
//import logo from './logo.svg';

import { Student } from '../../../../components/student';
import { useDrag, useDrop } from 'react-dnd';
import { sortBy, groupBy } from 'lodash';
import { Header } from '../../../../components/sgHeader';
import './style.css';

export class Subgroup extends Component {

    constructor () {
        super ();
        this.state = {
            filter: 'fio'
        }
    }

    render () {
        //const className = isOver && canDrop ? 'subgroup-container subgroup-container-canDrop' : 'subgroup-container'

        
        const {subgroup} = this.props;    

        return <div key = {'sdf' + subgroup.id} className = 'subgroup-container'>
          <Header {...this.props} />
          <this.Filters/>
          <this.Students/>
        </div>
    }

    Filters = () => {
        const { filter } = this.state;
        return <div className = 'subgroup-filters-container'>
            <div className = 'subgroup-filters-fio' onClick = {()=>{this.setState({filter: 'fio'})}}>
               { filter === 'fio' ? <b>Ф И О</b> : 'Ф И О' } 
            </div>
            <div className = 'subgroup-filters-cordao' onClick = {()=>{this.setState({filter: 'cordao'})}}>
                { filter === 'cordao' ? <b>КОРДАО</b> : 'КОРДАО' } 
            </div>
        </div>

    }

    Students = () => {

        const { onClickToEditStudent } = this.props;

        const [{ isOver, canDrop }, drop] = useDrop({
            accept: 'KNIGHT',
            drop: (dragged_item) => {

              const {subgroup, moveToNewSb} = this.props;     
              const { Id, subgroupId } = dragged_item;

              moveToNewSb ({ personId: Id, subgroupFromId: subgroupId, subgroupToId: subgroup.id})
            },
            canDrop: (dragged_item) => {
              const { subgroup : { id } } = this.props;     
              const { subgroupId } = dragged_item;
              return subgroupId !== id;
            },
            collect: (monitor) => ({
              isOver: !!monitor.isOver(),
              canDrop: !!monitor.canDrop()
            })
          })
        
        const { filter } = this.state;
        const { students, client, subgroup } = this.props;
        const className = isOver && canDrop ? 'subgroup-students-container subgroup-students-container-canDrop' : 'subgroup-students-container'

        let studentsToShow = [];
        if (filter === 'fio') studentsToShow = sortBy(students, item => { return item.Surname} );
        if (filter === 'cordao') {
            const schoolGradation = Object.keys(client.getSchoolGradation(1));
            studentsToShow = sortBy(students, item => { return schoolGradation.indexOf(item.Cordao)} ).reverse();
        }

        return <div ref = {drop} className = {className}>
          {studentsToShow.map( (student, index) => {
              return <Student 
                         key = {'gcrm-sb-stud-' + student.Id} 
                         client = {client}
                         student = {student} 
                         index = {index + 1}
                         subgroupId = {subgroup.id}
                         onClickToEdit = {(student) => {onClickToEditStudent({ student, subgroup })}}
                         fields = {
                             [
                                 {type: 'index', width: 20},
                                 {type: 'avatar', width: 30},
                                 {type: 'fioac', width: 200},
                                //  {type: 'payment', width: 50},
                             ]
                         }
                         
                />
          } )}
        </div>
    }

}

