import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import ItemShopComponent from 'src/ver2/components/item/shop';
import RealShopItemComponent from './components/realItem';

import Loader from 'src/ver2/components/loader';
import { orderBy } from 'lodash';

// import { Link } from 'react-router-dom';

import './style.css';

// class ShopItemsPlashka
// classname: shop_items_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopItemsPlashka extends PureComponent {

    state = { heightSetted: false };
    setHeight = async (height) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const { heightSetted } = this.state;
        if (!heightSetted || heightSetted !== height) {
            this.setState({
                heightSetted: height
            })
            setComponentSelfHeight({ height: height, plashkaId });
        }
    }

    mayIBuyIt = (CostObj) => {

        if (!this.props.iamuser) return false;

        const { iamuser: { myResourses } } = this.props;
        const { Money } = myResourses;

        const CostObjKeys = Object.keys(CostObj);

        if (!Money || Object.keys(Money).length === 0) return false;

        for (let i = 0; i < CostObjKeys.length; i++) {
            const moneyKey = CostObjKeys[i];
            if (!Money[moneyKey]) return false;
            const amount = Money[moneyKey].all;
            if (amount < CostObj[moneyKey]) return false;
        }
        return true;
    }

    render() {

        const { client, ShopItems, isShopItemsReady, upperStyle, buyShopItems, Filter, isMobile, Shop } = this.props;
        const { width, height } = upperStyle;

        if (!isShopItemsReady) return <div style={{ width, height }} className='shop_items_plashka'>
            <Loader />
        </div>

        let itemWidth;
        let countRow = 4; // будет на 1 больше

        if (!isMobile) {
            itemWidth = 160;
            countRow = 4;
            if (Shop.Type === 1) {
                countRow = 3;
            }
        } else {
            if (isMobile.width < 500) {
                itemWidth = (isMobile.width - 30) / 2;
                countRow = 1;
            } else {
                itemWidth = (isMobile.width - 30) / 5;
                countRow = 4;
            }
            if (Shop.Type === 1) {
                itemWidth = (isMobile.width) * 0.45;
            }
        }

        const itemHeight = itemWidth * 1.5;

        // показываем реальный магазин
        if (Shop.Type === 1) {
            const {
                RealShopItems: RealShopItems_ = []
            } = this.props;

            if (!RealShopItems_ || !RealShopItems_.length) {
                return <div style={{ width, height }} className='shop_items_plashka'>
                    Нет предметов в магазине
                </div>
            }

            const { SettingsObj } = Shop;
            const { RealThemes } = SettingsObj || {};

            let arrayReturn = [];
            // const RealShopItems = [];
            // const RealShopItems = orderBy(RealShopItems_, ['Theme', 'Pos'], ['asc', 'asc']);

            
            let RealShopItems = orderBy(RealShopItems_, ['Pos'], ['asc']);
            const themeKeys = Object.keys(RealThemes);

            RealShopItems = RealShopItems.sort( (a, b) => {
                return themeKeys.indexOf(a.Theme) - themeKeys.indexOf(b.Theme)
            })

            let NowTheme = '';
            let LastTheme = RealShopItems[0].Theme;

            for (let i = 0; i < RealShopItems.length; i++) {
                const RealShopItem = RealShopItems[i];

                const { Theme } = RealShopItem;
                if (LastTheme !== Theme) {
                    if (RealThemes && RealThemes[LastTheme] && RealThemes[LastTheme].sales) {
                        arrayReturn.push(<div className='shop_items_plashka_realitems_themesales'> {RealThemes[LastTheme].sales[0].saleTitle} </div>);
                    }  
                    LastTheme = Theme;   
                }
                if (Theme !== NowTheme) {
                    NowTheme = Theme;
                    if (RealThemes && RealThemes[NowTheme]) {
                        arrayReturn.push(<div className='shop_items_plashka_realitems_themetitle'> {RealThemes[NowTheme].title} </div>);
                    }
                }

                // тут условия показа
                if (RealShopItem.ShowType > 0)
                    arrayReturn.push(<RealShopItemComponent key={'sipp' + RealShopItem.Id} width={itemWidth} RealShopItem={RealShopItem} />);
            }

            if (isMobile) {
                this.setHeight((((arrayReturn.length) || 1) / 2) * (itemHeight + 15) );
            } else 
            this.setHeight((((arrayReturn.length) || 1) / 4) * (itemHeight + 25) + 1100);

            return <div style={{ width, height }} className='shop_items_plashka'>
                <div className='shop_items_plashka_realitems'>
                    {arrayReturn}
                </div>
            </div>
        }


        if (ShopItems && ShopItems.length) {

            let arrayReturn = [];
            let rowArray = [];

            for (let i = 0; i < ShopItems.length; i++) {

                const ShopItem = ShopItems[i];
                const { CostObj } = ShopItem;

                const item = client.getAbstractItem(ShopItem.Name);
                const { type, subtype, grade } = item;

                if (Filter.filterType && Filter.filterType !== type) { continue; }
                if (Filter.filterSubtype && Filter.filterSubtype !== subtype) { continue; }
                if (Filter.gradeType && Filter.gradeType !== grade) { continue; }
                if (Filter.onlyForMe && !this.mayIBuyIt(CostObj)) { continue; }

                rowArray.push(<ItemShopComponent buyShopItems={buyShopItems} key={'sipp' + ShopItem.Name} width={itemWidth} ShopItem={ShopItem} />);

                if (rowArray.length > countRow) {
                    arrayReturn.push(<div>
                        {[...rowArray]}
                    </div>)
                    rowArray = [];
                }
            }
            if (rowArray.length > 0) {
                arrayReturn.push(<div>
                    {[...rowArray]}
                </div>)
            }

            this.setHeight(((arrayReturn.length || 1) + 1) * (itemHeight + 10));

            return <div style={{ width, height }} className='shop_items_plashka'>
                {arrayReturn}
            </div>
        }

        return <div style={{ width, height }} className='shop_items_plashka'>
            Нет предметов
        </div>
    }

}

export default withShopState(`
Shop,  
Filter,
client,
iamuser,
isMobile,
buyShopItems,
ShopItems,
isShopItemsReady,

RealShopItems,
RealShopArticules,
RealShopArticulesByItems,
RealShopArrivals

`)(ShopItemsPlashka);