// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input } from 'antd';
import { gsap } from 'gsap';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest36 extends PureComponent {
    state = {
        editText: ''
    }

    init = () => {
        this.setState({ editText: '' })
    }

    send = (index) => {
        let editText = this.state.editText;

        editText += index.toString();

        if (editText.length >= 4) {
            this.props.sendSpecial({ data: { [editText]: 1 } })
            return this.init();
        }

        this.setState({ editText })
    }



    render() {
        const className = 'quest34_container'

        return <div className={className}>
            <this.Table index = {1}/> 
            <this.Table index = {2}/> 
            <this.Table index = {3}/> 
            <this.Table index = {4}/> 
            <this.Table index = {5}/> 
        </div>
    }

    Table = ({ index }) => {

        const { isMobile } = this.props;
        const width = isMobile ? isMobile.width / 5.5 : 500 / 5.5;
        const height = width * 1.18;
        const style = {
            width,
            height
        }

        const onClick = () => {
            // gsap.to(`quest34_table_${index}`, { translateX: 5, translateY: 5, duration: 0.5 })
            //     .to(`quest34_table_${index}`, { translateX: 0, translateY: 0, duration: 0.5 });

            // gsap.fromTo(`quest34_table_${index}`, { x: 0}, { x: 15, duration: 1.5 });
            gsap.to(`.quest34_table_${index}`, { translateX: 2, translateY: 2, duration: 0.5,
                onComplete: () => {
                    gsap.to(`.quest34_table_${index}`, { translateX: 0, translateY: 0, duration: 0.5,});
                }
            });
            this.send(index);
        }

        return <div onClick={onClick} style={style} className={`quest34_table quest34_table_${index}`} />
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest36);