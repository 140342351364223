import React, { Component } from 'react';
import { Input, Button } from 'antd';

import './styles.css';

export default class ModalInvite extends Component {

    // props - addInvite

    state = {
        Name: '', 
        Surname: '', 
        Tel: ''
    }

    render() {
        const { Name, Surname, Tel } = this.state;
        return <div className="modal_invite_main">
            <div className="modal_invite_main_title">Фамилия</div>
            <Input value={Surname} onChange={(e) => this.setState({Surname: e.target.value})} />

            <div className="modal_invite_main_title">Имя</div>
            <Input value={Name} onChange={(e) => this.setState({Name: e.target.value})} />

            <div className="modal_invite_main_title">Телефон</div>
            <Input value={Tel} onChange={(e) => this.setState({Tel: e.target.value})} />

            <Button type = "primary" disabled ={!Name || !Surname || !Tel} onClick={() => {this.props.addInvite({
                Name, Surname, Tel
            })}}>ЗАПИСАТЬСЯ</Button>
        </div>
    }

}