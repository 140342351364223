// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import gsap from 'gsap';


const IMAGES = {
    'c1_body1': { width: 306, height: 294 },
    'c1_body2': { width: 306, height: 294 },
    'c1_head1': { width: 204, height: 186 },
    'c1_head2': { width: 204, height: 186 },
    'c1_head3': { width: 188, height: 179 },
    'c1_hand1': { width: 239, height: 175 },

    'c2_body1': { width: 326, height: 211 },
    'c2_head1': { width: 161, height: 222 },
    'c2_stylus1': { width: 142, height: 142 },
    'c2_stylus2': { width: 525, height: 207 },
    'c2_stuff1': { width: 149, height: 502 },
    'c2_ring1': { width: 49, height: 49 },

    'c3_body1': { width: 124, height: 104 },
    'c3_leg1': { width: 30, height: 68 },
    'c3_leg2': { width: 33, height: 56 },
    'c3_leg3': { width: 37, height: 59 },
    'c3_leg4': { width: 37, height: 64 },

    'c3_head1': { width: 151, height: 125 },
    'c3_nose1': { width: 23, height: 18 },
    'c3_ear1': { width: 74, height: 172 },
    'c3_ear2': { width: 90, height: 208 },
    'c3_tail1': { width: 159, height: 246 },

    'c4_body1': { width: 631, height: 336 },
    'c4_ear1': { width: 225, height: 153 },
    'c4_ear2': { width: 225, height: 153 },
    'c4_head1': { width: 246, height: 177 },
    'c4_tail1': { width: 295, height: 201 },
    'c4_nail1': { width: 86, height: 67 },
    'c4_nail2': { width: 93, height: 67 },
    'c4_nail3': { width: 97, height: 60 },


    'c5_1_0': { width: 372, height: 187 },
    'c5_1_1': { width: 372, height: 187 },
    'c5_2_0': { width: 198, height: 19 },
    'c5_2_1': { width: 198, height: 19 },
    'c5_3_0': { width: 299, height: 105 },
    'c5_3_1': { width: 299, height: 105 },
    'c5_3_2': { width: 299, height: 105 },
    'c5_4_0': { width: 127, height: 110 },
    'c5_4_1': { width: 127, height: 110 },
    'c5_5_0': { width: 37, height: 37 },
    'c5_5_1': { width: 37, height: 37 },


}

const CHARATCERS_ALL_NODES = {
    1: ['c1_body1', 'c1_body2', 'c1_head1', 'c1_hand1', 'c1_head2', 'c1_head3'],
    2: ['c2_body1', 'c2_head1', 'c2_stylus1'],
    3: [],
    4: [],
    5: []
}

class IslandCharacter extends PureComponent {

    componentDidMount = () => {
        this.setAnimation();
    }
    componentDidUpdate = () => {
        this.setAnimation();
    }

    setAnimation = () => {
        const { Id, Mood } = this.props;

        if (Id === 1) {
            gsap.fromTo(".islandchar_1_container", { skewX: -0.5, translateY: '-100%' }, {
                duration: 3,
                scewY: -1,
                skewX: 0.5,
                scaleY: 1.02,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            gsap.to(".islandchar_c1_head1", {
                duration: 5,
                rotate: -5,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            if (Mood === 3) {
                gsap.to(".islandchar_c1_head2", {
                    duration: 5,
                    rotate: -5,
                    repeat: -1,
                    ease: "sine.inOut",
                    yoyo: true
                });
            }

            if (Mood === 2 || Mood === 7) {
                gsap.to(".islandchar_c1_hand1", {
                    duration: 6,
                    rotate: 10,
                    repeat: -1,
                    ease: "sine.inOut",
                    yoyo: true
                });
            }

            if (Mood === 4) {
                gsap.fromTo(".islandchar_c1_hand1", { rotate: -25 }, {
                    duration: 3,
                    rotate: 70,
                    repeat: 0,
                    ease: "sine.inOut"
                });
            }

            if (Mood === 5) {
                gsap.fromTo(".islandchar_c1_hand1", { rotate: 70 }, {
                    duration: 1.5,
                    rotate: -35,
                    repeat: 0,
                    ease: "sine.inOut"
                });
            }
            if (Mood === 6 || Mood === 7) {
                gsap.fromTo(".islandchar_c1_head3", { rotate: -5 }, {
                    duration: 3.5,
                    rotate: 5,
                    repeat: -1,
                    ease: "sine.inOut",
                    yoyo: true
                });
            }
        }

        if (Id === 2) {
            gsap.fromTo(".islandchar_2_container", { translateY: '-100%' }, {});
            gsap.fromTo(".islandchar_c2_body1", { skewX: -0.5 }, {
                duration: 3,
                scewY: -1,
                skewX: 0.5,
                scaleY: 1.11,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
            gsap.fromTo(".islandchar_c2_stylus2", { skewY: 0.95 }, {
                duration: 3,
                y: '-7%',
                scaleY: 1.07,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
            gsap.to(".islandchar_2_container1", {
                duration: 3,
                y: '-7%',
                // rotate: -15,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
            gsap.fromTo(".islandchar_2_container1", { rotate: 9 }, {
                duration: 7,
                rotate: -18,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
            gsap.fromTo(".islandchar_c2_stylus1", { rotate: 3 }, {
                duration: 2,
                rotate: -6,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
            gsap.fromTo(".islandchar_2_container2", { rotate: 3 }, {
                duration: 8,
                y: '-7%',
                rotate: -6,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
            gsap.fromTo(".islandchar_c2_ring1", { rotate: 30 }, {
                duration: 3,
                rotate: -30,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
        }

        if (Id === 3) {


            gsap.fromTo(".islandchar_3_container", { skewX: -1 }, {
                duration: 3,
                skewX: 2,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            let t3 = gsap.timeline();

            //ТЕЛО
            t3.to(".islandchar_3_container1", {
                duration: 0.6,
                rotate: 7,
                ease: "sine.inOut",
            }).to(".islandchar_3_container1", {
                duration: 0.6,
                rotate: 0,
                ease: "sine.inOut",
            }).fromTo(".islandchar_3_container1", { rotate: 0 }, {
                duration: 3,
                rotate: 2,
                repeat: 3,
                ease: "sine.inOut",
                yoyo: true,
            }).repeat(-1);


            // ХВОСТ
            gsap.fromTo(".islandchar_c3_tail1", { rotate: 2 }, {
                duration: 4,
                rotate: -9,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            // ГОЛОВА
            gsap.fromTo(".islandchar_3_container2", { rotate: 2 }, {
                duration: 2,
                rotate: -6,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            let tl = gsap.timeline();

            // УХО
            tl.to(".islandchar_c3_ear1", {
                duration: 0.1,
                rotate: -3,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear1", {
                duration: 0.1,
                rotate: 0,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear1", {
                duration: 0.1,
                rotate: -3,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear1", {
                duration: 0.1,
                rotate: 0,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear1", {
                duration: 4,
                rotate: 0,
                ease: "sine.inOut",
                yoyo: true
            }).repeat(-1);

            let t2 = gsap.timeline();
            // УХО
            t2.to(".islandchar_c3_ear2", {
                duration: 0.1,
                rotate: 3,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear2", {
                duration: 0.1,
                rotate: 0,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear2", {
                duration: 0.1,
                rotate: 3,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear2", {
                duration: 0.1,
                rotate: 0,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_ear2", {
                duration: 4,
                rotate: 0,
                ease: "sine.inOut",
                yoyo: true
            }).repeat(-1);

            let t4 = gsap.timeline();
            t4.to(".islandchar_c3_leg2", {
                duration: 0.4,
                y: '-15%',
                ease: "sine.inOut",
                repeat: 1,
                yoyo: true
            }).to(".islandchar_c3_leg2", {
                duration: 0.4,
                y: 0,
                ease: "sine.inOut",
                yoyo: true
            }).repeat(-1);

            let t5 = gsap.timeline();
            t5.to(".islandchar_c3_leg1", {
                duration: 0.4,
                y: 0,
                ease: "sine.inOut",
                yoyo: true
            }).to(".islandchar_c3_leg1", {
                duration: 0.4,
                y: '-15%',
                ease: "sine.inOut",
                repeat: 1,
                yoyo: true
            }).repeat(-1);

            let t6 = gsap.timeline();
            t6.to(".islandchar_c3_nose1", {
                duration: 3,
                y: 0,
                ease: "sine.inOut"
            }).to(".islandchar_c3_nose1", {
                duration: 0.1,
                y: '-20%',
                ease: "sine.inOut",
                repeat: 3,
                yoyo: true
            }).repeat(-1);

        }

        if (Id === 4) {


            let nail1 = gsap.timeline();
            let nail2 = gsap.timeline();
            let nail3 = gsap.timeline();

            nail1.to(".islandchar_c4_nail1", {
                duration: 0.5,
                rotate: 12,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail1", {
                duration: 0.5,
                rotate: 0,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail1", {
                duration: 2,
                rotate: 0,
                ease: "sine.inOut"
            }).repeat(-1);

            nail2.to(".islandchar_c4_nail2", {
                duration: 0.3,
                rotate: 0,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail2", {
                duration: 0.5,
                rotate: 12,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail2", {
                duration: 0.5,
                rotate: 0,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail2", {
                duration: 1.7,
                rotate: 0,
                ease: "sine.inOut"
            }).repeat(-1);

            nail3.to(".islandchar_c4_nail3", {
                duration: 0.6,
                rotate: 0,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail3", {
                duration: 0.5,
                rotate: 12,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail3", {
                duration: 0.5,
                rotate: 0,
                ease: "sine.inOut"
            }).to(".islandchar_c4_nail3", {
                duration: 1.4,
                rotate: 0,
                ease: "sine.inOut"
            }).repeat(-1);


            // ХВОСТ
            gsap.fromTo(".islandchar_c4_tail1", { rotate: 2 }, {
                duration: 4,
                rotate: -9,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            // ГОЛОВА
            gsap.fromTo(".islandchar_4_container1", { rotate: 2 }, {
                duration: 6,
                rotate: -6,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            gsap.fromTo(".islandchar_c4_ear1", { rotate: -5 }, {
                duration: 3.7,
                rotate: 5,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });
            gsap.fromTo(".islandchar_c4_ear2", { rotate: -5 }, {
                duration: 3.7,
                rotate: 5,
                repeat: -1,
                ease: "sine.inOut",
                yoyo: true
            });

            // let ttl = gsap.timeline();
            /*
                        // УХО
                        ttl.to(".islandchar_c4_ear1", {
                            duration: 0.1,
                            rotate: -3,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear1", {
                            duration: 0.1,
                            rotate: 0,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear1", {
                            duration: 0.1,
                            rotate: -3,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear1", {
                            duration: 0.1,
                            rotate: 0,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear1", {
                            duration: 4,
                            rotate: 0,
                            ease: "sine.inOut",
                            yoyo: true
                        }).repeat(-1);
            
                        let tt2 = gsap.timeline();
                        // УХО
                        tt2.to(".islandchar_c4_ear2", {
                            duration: 0.1,
                            rotate: 3,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear2", {
                            duration: 0.1,
                            rotate: 0,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear2", {
                            duration: 0.1,
                            rotate: 3,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear2", {
                            duration: 0.1,
                            rotate: 0,
                            ease: "sine.inOut",
                            yoyo: true
                        }).to(".islandchar_c4_ear2", {
                            duration: 4,
                            rotate: 0,
                            ease: "sine.inOut",
                            yoyo: true
                        }).repeat(-1);
            */
        }

    }

    selectNode() {
        const { Id } = this.props;
        switch (Id) {
            case 1: return <this.Char1 />
            case 2: return <this.Char2 />
            case 3: return <this.Char3 />
            case 4: return <this.Char4 />
            case 5: return <this.Char5 />
            default: return null;
        }
    }
    hiddenImages = () => {
        const { Id } = this.props;
        if (CHARATCERS_ALL_NODES[Id]) {
            return CHARATCERS_ALL_NODES[Id].map(name => <img className='hidden_img' src={`/../images/ver2/island/Characters/${Id}/${name}.png`} />)
        }
        return null;
    }

    render() {
        return <React.Fragment>
            {this.selectNode()}
            {this.hiddenImages()}
        </React.Fragment>
    }

    styleImg = (name) => {
        const { screenWidth } = this.props;
        return { width: IMAGES[name].width * (screenWidth / 1000), height: IMAGES[name].height ? IMAGES[name].height * (screenWidth / 1000) : 'auto' }
    }

    thisImg = (name) => {
        const { Id } = this.props;
        return <img style={this.styleImg(name)} className={`islandchar_${name}`} src={`/../images/ver2/island/Characters/${Id}/${name}.png`} />
    }

    Char1 = () => {
        let body = 'c1_body1';

        const { Mood } = this.props;
        const randValue = Math.random();

        if (Mood === 2 || Mood === 4) {
            body = 'c1_body2';
            return <div style={this.styleImg(body)} className='islandchar_1_container' key={`char_${randValue}`}>
                <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/1/${body}.png`} />
                {this.thisImg('c1_hand1')}
                {this.thisImg(body)}
                {this.thisImg('c1_head1')}
            </div>
        }

        if (Mood === 5) {
            body = 'c1_body2';
            return <div style={this.styleImg(body)} className='islandchar_1_container' key={`char_${randValue}`}>
                <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/1/${body}.png`} />
                {this.thisImg('c1_hand1')}
                {this.thisImg(body)}
                {this.thisImg('c1_head3')}
            </div>
        }

        let headName = 'c1_head1';
        if (Mood === 3) headName = 'c1_head2';

        if (Mood === 6) {
            headName = 'c1_head3';
            return <div style={this.styleImg(body)} className='islandchar_1_container' key={`char_${randValue}`}>
                <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/1/${body}.png`} />
                {this.thisImg(body)}
                {this.thisImg(headName)}
            </div>
        }

        if (Mood === 7) {
            body = 'c1_body2';
            return <div style={this.styleImg(body)} className='islandchar_1_container' key={`char_${randValue}`}>
                <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/1/${body}.png`} />
                {this.thisImg('c1_hand1')}
                {this.thisImg(body)}
                {this.thisImg('c1_head3')}
            </div>
        }

        return <div style={this.styleImg(body)} className='islandchar_1_container' key={`char_${randValue}`}>
            <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/1/${body}.png`} />
            {this.thisImg(body)}
            {this.thisImg(headName)}
        </div>
    }

    Char2 = () => {
        let body = 'c2_body1';
        const { Mood } = this.props;

        const randValue = Math.random();

        let className = 'islandchar_2_container';
        if (Mood === 2) {
            className += ' islandchar_2_container_2'
        }

        return <div style={this.styleImg(body)} className={className} key={`char_${randValue}`}>
            <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/2/${body}.png`} />
            {this.thisImg(body)}
            {this.thisImg('c2_stylus2')}
            <div style={this.styleImg('c2_head1')} className='islandchar_2_container1'>
                {this.thisImg('c2_head1')}
                {this.thisImg('c2_stylus1')}
            </div>
            <div style={this.styleImg('c2_stuff1')} className='islandchar_2_container2'>
                {this.thisImg('c2_stuff1')}
                {this.thisImg('c2_ring1')}
            </div>

        </div>
    }

    Char3 = () => {
        let leg4 = 'c3_leg4';

        const randValue = Math.random();
        return <div style={this.styleImg(leg4)} className='islandchar_3_container' key={`char_${randValue}`}>
            <img style={this.styleImg(leg4)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/3/${leg4}.png`} />

            {this.thisImg('c3_leg3')}
            <div style={this.styleImg('c3_body1')} className='islandchar_3_container1'>
                {this.thisImg('c3_tail1')}
                {this.thisImg('c3_leg1')}
                {this.thisImg('c3_body1')}
                {this.thisImg('c3_leg2')}
                <div style={this.styleImg('c3_head1')} className='islandchar_3_container2'>
                    {this.thisImg('c3_ear1')}
                    {this.thisImg('c3_head1')}
                    {this.thisImg('c3_ear2')}
                    {this.thisImg('c3_nose1')}
                </div>
            </div>
            {this.thisImg(leg4)}
            {/* {this.thisImg('c2_stylus2')}
            <div style={this.styleImg('c2_head1')} className='islandchar_2_container1'>
                {this.thisImg('c2_head1')}
                {this.thisImg('c2_stylus1')}
            </div>
            <div style={this.styleImg('c2_stuff1')} className='islandchar_2_container2'>
                {this.thisImg('c2_stuff1')}
                {this.thisImg('c2_ring1')}
            </div> */}

        </div>
    }

    Char4 = () => {
        let body = 'c4_body1';

        const randValue = Math.random();
        return <div style={this.styleImg(body)} className='islandchar_4_container' key={`char_${randValue}`}>
            <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/4/${body}.png`} />

            {this.thisImg('c4_body1')}
            {this.thisImg('c4_tail1')}
            <div style={this.styleImg('c4_head1')} className='islandchar_4_container1'>
                {this.thisImg('c4_ear1')}
                {this.thisImg('c4_head1')}
                {this.thisImg('c4_ear2')}
            </div>
            {this.thisImg('c4_nail3')}
            {this.thisImg('c4_nail2')}
            {this.thisImg('c4_nail1')}
            {/* {this.thisImg('c2_stylus2')}
            <div style={this.styleImg('c2_head1')} className='islandchar_2_container1'>
                {this.thisImg('c2_head1')}
                {this.thisImg('c2_stylus1')}
            </div>
            <div style={this.styleImg('c2_stuff1')} className='islandchar_2_container2'>
                {this.thisImg('c2_stuff1')}
                {this.thisImg('c2_ring1')}
            </div> */}

        </div>
    }

    Char5 = () => {
        let body = 'c5_1_0';

        const randValue = Math.random();
        const { myActions = [] } = this.props;
        const c5_1 = myActions.find(x => x.Title === 'Вы вставили сталагмит') ? 1 : 0;
        const c5_2 = myActions.find(x => x.Title === 'Вы вставили сталагмит') ? 1 : 0;

        let c5_3 = 0;
        if (myActions.find(x => x.Title === 'Вы активировали код')) c5_3 = 1;
        if (myActions.find(x => x.Title === 'Вы распределили код')) c5_3 = 2;

        const c5_4 = myActions.find(x => x.Title === 'Вы вставили тотем') ? 1 : 0;
        const c5_5 = myActions.find(x => x.Title === 'Вы вставили иголку') ? 1 : 0;

        return <div style={this.styleImg(body)} className={'islandchar_5_container'} key={`char_${randValue}`}>
            <img style={this.styleImg(body)} className={`islandchar_cr_body0`} src={`/../images/ver2/island/Characters/5/${body}.png`} />
            {this.thisImg(`c5_1_${c5_1}`)}
            {this.thisImg(`c5_2_${c5_2}`)}
            {this.thisImg(`c5_3_${c5_3}`)}
            {this.thisImg(`c5_4_${c5_4}`)}
            {this.thisImg(`c5_5_${c5_5}`)}
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(IslandCharacter);