import React from 'react';
import TrefaText from 'src/ver2/components/trefaText';
import { Icon, Tooltip } from 'antd';

import './style.css';

export const Notification = ({ onClick, Body }) => {

    return <div className='notification-plashka-content'>
        <Tooltip class='notification-plashka-content_tooltip' placement="bottom" title={
            <div className='notification-plashka-content_texttooltip'>
                <TrefaText style={{ avatarsSize: 16, fontSize: 17, minWidth: 100, color: 'white' }} trefaText={Body} />
            </div>
        }>
            <div className='notification-plashka-content_text'>
                <TrefaText style={{ avatarsSize: 16, fontSize: 17, minWidth: 100 }} trefaText={Body} />
            </div>
        </Tooltip>
        <div onClick={onClick} className='notification_plashka_content_delete'>
            <Icon type='cross' style={{ width: 20, height: 20 }} />
        </div>
    </div>
}