import React, { Component } from 'react';
//import logo from './logo.svg';

import './style.css';
import { withCRMState } from '../../context';
import LittlePerson from 'src/ver2/components/person/little';
import { orderBy } from 'lodash';

import { Modal } from 'antd';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

class Shadule extends Component {

    /**
     * props
     * subgroups
     * shadule
     */

    state = {
        paymentsObj: [],
        status: 'loading'
    }

    componentDidMount () {

        const { payments } = this.props;

        // месяц/год
        const paymentsKeys = Object.keys(payments);

        let answer = [];

        for (let i = 0; i < paymentsKeys.length; i++) {
            const dataKey = paymentsKeys[i];
            const subgroups = Object.keys(payments[dataKey]);

            for (let j = 0; j < subgroups.length; j++) {
                const subgroupid = subgroups[j];
                const users = Object.keys(payments[dataKey][subgroupid]);
                for (let k = 0; k < users.length; k++) {
                    const userid = users[k];
                    if (payments[dataKey][subgroupid][userid].month) {
                        answer.push({
                            ...payments[dataKey][subgroupid][userid].month,
                            dateIndex: (payments[dataKey][subgroupid][userid].month.Year * 100) + payments[dataKey][subgroupid][userid].month.Month
                        })
                    }
                    if (payments[dataKey][subgroupid][userid].day) {
                        const days = Object.keys(payments[dataKey][subgroupid][userid].day);
                        for (let z = 0; z < days.length; z++) {
                            answer.push({
                                ...payments[dataKey][subgroupid][userid].day[days[z]],
                                dateIndex: (payments[dataKey][subgroupid][userid].day[days[z]].Year * 100) + payments[dataKey][subgroupid][userid].day[days[z]].Month
                            })
                        }

                    }


                }
            }

        }

        answer = orderBy(answer, ['dateIndex', 'Id'], ['desc', 'desc']);
        console.log('ОПЛАТЫ', { answer });

        let paymentsObj = [];
        let nowDataKey = null;
        for (let i = 0; i < answer.length; i++) {
            if (!nowDataKey || nowDataKey !== answer[i].dateIndex) {
                nowDataKey = answer[i].dateIndex;
                paymentsObj.push(
                    {
                        nowDataKey,
                        month: answer[i].Month,
                        year: answer[i].Year,
                        sum: 0,
                        payments: []
                    }
                );
            }
            paymentsObj[paymentsObj.length - 1].payments.push(answer[i])
            paymentsObj[paymentsObj.length - 1].sum += answer[i].Value;

        }

        this.setState({ paymentsObj, status: 'loaded' })
    }

    render() {

        const { paymentsObj, status } = this.state;

        if (status !== 'loaded') {
            return <div className='crm_payments_main'>
                <div className='crm_payments_main_nopayments'>
                    <span>
                        ЗАГРУЗКА
                    </span>
                </div>
            </div>
        }

        if (!paymentsObj || !paymentsObj.length) {
            return <div className='crm_payments_main'>
                <div className='crm_payments_main_nopayments'>
                    <span>
                        У ВАС НЕТ ВНЕСЕННЫХ ОПЛАТ
                    </span>
                </div>
            </div>
        }

        return <div className='crm_payments_main'>
            {paymentsObj.map((data) => {
                const titleText = ` ${moment(`1.${data.month}.${data.year}`, 'DD.MM.YYYY').format('MMMM YYYY').toUpperCase()} : ${data.sum} ₽`
                return <div className='crm_payments_main_month'>
                    <div className='crm_payments_main_month_title'> {titleText} </div>
                    <div className='crm_payments_main_month_payments'>
                        {data.payments.map(payment => <this.OnePayment payment={payment} />)}
                    </div>
                </div>
            })}
        </div>
    }

    OnePayment = ({ payment }) => {
        const { SubjectId, Value, Day, Month, Year, Type, Id, ObjectId } = payment;

        let dateFor = ``;
        let dateType = '';
        if (Type === 'month') {
            dateFor = `${Month}.${Year}`;
            dateType = 'МЕСЯЦ';
        }
        if (Type === 'day') {
            dateFor = `${Day}.${Month}.${Year}`;
            dateType = 'ДЕНЬ';
        }

        const onDeleteButtonClick = () => {
            Modal.confirm({
                title: 'Уверены?',
                content: 'Восстановить оплату будет невозможно, только создать заново',
                okText: 'Удалить',
                cancelText: 'Не удалять',
                onOk: () => {
                    const { deletePayment } = this.props;
                    deletePayment(Id, ObjectId, Month, Year);
                }
            })
        }

        const { isMobile } = this.props;
        let style = {};
        let className = 'crm_payments_one'
        if (isMobile && isMobile.width) {
            style = { width: isMobile.width - 20}
            className += ' crm_payments_one_mobile';
        }

        return <div key={`${Day}_${Month}_${Year}__${SubjectId}_${Value}`} style = {style} className={className}>
            <div className='crm_payments_one_date'>
                <span> {dateType} </span>
                <span> {dateFor} </span>
            </div>
            <div className='crm_payments_one_person'>
                <LittlePerson personId={SubjectId} height={60} />
            </div>
            <div className='crm_payments_one_value'>
                <span> {`${Value} ₽`}</span>
            </div>
            <div className='crm_payments_one_delete'>
                <span onClick={onDeleteButtonClick}>❌</span>
            </div>
        </div>
    }

}

export default withCRMState(
    `
        client,
        isMobile,
    
        payments,
        deletePayment,

        subgroups,
        shadule,
    `
)(Shadule)