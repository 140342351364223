import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';

import Loader from 'src/ver2/components/loader';

import { Collapse, Avatar, Icon, message, Button } from 'antd';
import { sortBy, isEqual } from 'lodash';
import { Link } from 'react-router-dom';
import { Modal, Select, Tag } from 'antd';
import { Brikets } from './Brikets';

import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';

// import { Link } from 'react-router-dom';

import './style.css';

const { Option } = Select;

// class EventShadulePlashka
// classname: event_shadule_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */


const { Panel } = Collapse;


class EventShadulePlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false,
            lookedCategoryId: null,
            timerForCategorys: null,
            categorysState: {},
            byFields: null
        }
        this.timerCategorys = null;
    }

    componentDidMount = () => {
        this.setHeight();
        if (this.props.Championship && this.props.Championship.BriketsReady && this.props.Categorys) {
            // if (this.props.Championship && this.props.Categorys) {
            this.startTimer();
        }
    }

    componentDidUpdate = () => {
        if (this.props.Championship && this.props.Championship.BriketsReady && this.props.Categorys && !this.state.timerForCategorys) {
            this.startTimer();
        }
        this.setHeight();
    }

    componentWillUnmount = () => {
        if (this.timerCategorys) {
            clearInterval(this.timerCategorys);
        }
        this.timerCategorys = null;
        delete this.timerCategorys;
    }

    startTimer = () => {
        if (this.state.timerForCategorys) return;
        this.setState({ timerForCategorys: true });
        this.timerTick().then(() => {
            this.timerCategorys = setInterval(this.timerTick, 4000);
        })
    }

    timerTick = async () => {

        this.props.reloadCategorys( true );

        // return null;
        // const { shaduleCategoryId, Categorys, Championship } = this.props;
        // if (shaduleCategoryId || !Championship || !Categorys) return;
        // let categorysState = {}
        // for (let i = 0; i < Categorys.length; i++) {
        //     const category = Categorys[i];
        //     const CatData = await this.getCategoryState({ competitionId: Championship.Id, category });
        //     if (CatData) {
        //         categorysState[category.Id] = {
        //             ...CatData
        //         }
        //     }
        // }
        // this.setState({ categorysState: { ...this.state.categorysState, ...categorysState } });
    }
/*
    getCategoryState = async ({ competitionId, category }) => {

        const { client } = this.props;
        const { categorysState } = this.state;

        if (!category || !category.ElderCircle) return null;

        // что нам надо на выходе получить
        let story = []; // мы созда1м свой объект story, он чуть отличается от того, что в БД
        let competitors = []; // а это competitors_first плюс "пустые" жребии, которых не достаёт
        let progress = 0;

        let CategoryIsDone = 0
        let CouplesClosed = 0;
        let CouplesShouldBe = 0;

        const selectedCategory = category.Id;

        let story_ = await client.briketsGetStory({ competitionId, selectedCategory });
        competitors = await client.briketsFirstCompetitiors({ competitionId, selectedCategory });

        // если не менялась стори, то как бэ и всё
        if (categorysState[selectedCategory] && categorysState[selectedCategory].story)
            if (isEqual(categorysState[selectedCategory].story, story_) && (isEqual(categorysState[selectedCategory].competitors, competitors))) {
                return null;
            }

        // если это НЕ КРУГОВАЯ
        if (!category.IsCircled) {
            story = addBlankNotCircleHistory({ category, story_ })
            const setCompetitorsThrueBrikets__ = setCompetitorsThrueBrikets({ competitors, category, story });
            CategoryIsDone = setCompetitorsThrueBrikets__.CategoryIsDone;
            CouplesClosed = setCompetitorsThrueBrikets__.CouplesClosed;
            CouplesShouldBe = setCompetitorsThrueBrikets__.CouplesShouldBe;
        }

        // КРУГОВАЯ
        if (category.IsCircled) {
            if (competitors && competitors.length > 0 && story_) {
                const addBlankCircleHistory_ = addBlankCircleHistory({ category, story_, competitors });
                CategoryIsDone = addBlankCircleHistory_.CategoryIsDone;
                CouplesClosed = addBlankCircleHistory_.CouplesClosed;
                CouplesShouldBe = addBlankCircleHistory_.CouplesShouldBe;
            }

        }

        progress = CouplesClosed / CouplesShouldBe;

        return { progress, CategoryIsDone, story: story_, competitors };
    }
*/

    setHeight = async () => {
        const { isCompetitorsCountInCategorysReady, competitorsCountInCategorys, setComponentSelfHeight, plashkaId } = this.props;
        const { heightSetted } = this.state;
        if (!heightSetted && isCompetitorsCountInCategorysReady) {
            this.setState({
                heightSetted: true
            })
            setComponentSelfHeight({ height: 50 * competitorsCountInCategorys.length + 550, plashkaId });
        }
    }

    getCountInCategory = (id) => {
        const { competitorsCountInCategorys, isCompetitorsCountInCategorysReady } = this.props;
        if (!isCompetitorsCountInCategorysReady || !competitorsCountInCategorys) return 0;
        const findedCount = competitorsCountInCategorys.find(x => x.CategoryId === id);
        if (findedCount) return findedCount.count;
        return 0;
    }

    setActiveCategory = async (key) => {
        const { client, Event } = this.props;

        if (key === undefined) {
            return this.setState({ selectedCategoryData: [], lookedCategoryId: key });
        }

        const selectedCategoryData = await client.getCompetitors(Event.ChampionshipId, key);
        this.setState({ selectedCategoryData, lookedCategoryId: key });
    }

    nowBriketsCategory = () => {
        const { shaduleCategoryId, Categorys } = this.props;
        if (!shaduleCategoryId || !Categorys) return {}
        const category = Categorys.find(x => x.Id.toString() === shaduleCategoryId.toString());
        return category || {};
    }

    // всякие утилитки
    onCopyCategoryClick = (category, e) => {
        e.stopPropagation();
        // копировать категорию

        const onOk = () => {
            const { client, reloadCategorys } = this.props;
            client.copyCategory(category).then(() => reloadCategorys());
        }

        Modal.info({
            className: 'event_participation_decline_modal',
            title: 'Копировать категорию',
            content: 'Создать новую категорию на основании текущей?',
            onOk,
            onCancel: () => { },
            okText: 'Копировать',
            cancelText: 'Отмена',
            okCancel: true,
            closable: true,
            centered: true
        })

    }

    onDeleteCategoryClick = (category, e) => {
        // надо проверить количество участников

        e.stopPropagation();

        const count = this.getCountInCategory(category.Id);
        if (count > 0) {
            message.warn('Вы не можете удалить категорию в которой есть участники');
            return;
        }

        const onOk = () => {
            const { client, reloadCategorys } = this.props;
            client.deleteCategory(category.CompetitionId, category.Id).then(() => reloadCategorys());
        }

        Modal.info({
            className: 'event_participation_decline_modal',
            title: 'Удалить категорию ' + category.Name,
            content: 'Удалить категорию, вы уверены?',
            onOk,
            onCancel: () => { },
            okText: 'Удалить',
            cancelText: 'Отмена',
            okCancel: true,
            closable: true,
            centered: true
        })
    }

    moveFromCategory = (Id, Name) => {
        this.setState({
            movedData: {
                Id,
                Name
            }
        })
    }

    // модальник про то, чтобы переместить чувака в другое место
    MoveModal = () => {
        const { movedData } = this.state;
        const { Categorys, Event } = this.props;
        if (!movedData || !movedData.Id) return null;

        const moveCompetitor = (UserId, toCategory) => {
            const { client, reloadCompetitors, reloadCategorys } = this.props;
            this.setState({ movedData: null });
            client.updateCompetitor(Event.ChampionshipId, UserId, toCategory, null).then(() => {
                reloadCompetitors();
                reloadCategorys();
                this.setActiveCategory();
            })
        }

        return <Modal
            visible={movedData}
            footer={null}
            title={'Перенести спортсмена ' + movedData.Name}
            onCancel={() => { this.setState({ movedData: null }) }}
        >
            <div className='event_shadule_plashka_moveto'>

                {Categorys.map((item, index) => {
                    return <div className='event_shadule_plashka_onecategory' onClick={() => { moveCompetitor(movedData.Id, item.Id) }}>
                        {item.Name}
                    </div>
                })}

            </div>
        </Modal>

    }

    render() {

        const { Event, upperStyle, Championship, Categorys, linkTo, shaduleCategoryId, byPersons,
            redirect, lookedPersonId, isMobile, iAmAdmin, iAmCompetitor, reloadCategorys, showInfoModal, closeInfoModal, iamuser } = this.props;
        const { lookedCategoryId, selectedCategoryData, byFields } = this.state;
        const { width, height } = upperStyle;

        if (!Event || !Categorys || !Championship) return <div style={{ width, height }} className='event_shadule_plashka'>
            <Loader />
        </div>

        console.log (Categorys);

        // теперь мы будем смотреть, что нам возвращать. У нас может быть два режима просмотра - как было раньше и новый october
        // сначала делаем то, как было раньше

        const {
            BriketsReady
        } = Championship;


        /* ТУТ НАДО БУДЕТ РАЗДЕЛИТЬ iAmAdmin и iAmJudge */

        const Categorys_ = sortBy(Categorys, byFields ? ['FieldId', 'TimeStart'] : ['TimeStart']);
        let myCategory = null;

        if (iAmCompetitor) myCategory = iAmCompetitor.CategoryId;

        let lastFieldId = -1;

        if (BriketsReady) {
            return <div style={{ width, height }} className='event_shadule_plashka'>
                <div className='event_shadule_plashka_headmenu'>
                    <Button type={byFields ? 'primary' : 'default'} onClick={() => { this.setState({ byFields: !this.state.byFields }) }} >ПО ПЛОЩАДКАМ</Button>
                    <Link to={linkTo('byPersons', iamuser ? `*${iamuser.GroupId || iamuser.GroupIamInId || ''}` : '*')}> <Button type={'default'}>ПО УЧАСТНИКАМ</Button></Link>
                </div>
                {Categorys_.map((category) => {
                    if (!category || !category.FieldId) return null;
                    return <Link to={linkTo('categoryId', category.Id)}>
                        <this.PanelHeader key={'cat' + category.Id} itsMyCategory={category.Id === myCategory} category={category} noCollapse={true} />
                    </Link>
                })}
                <Modal
                    className={isMobile ? 'ttt ttt_mobile' : 'ttt'}
                    visible={byPersons}
                    onCancel={() => { redirect(`/event/${Event.Id}/?page=shadule&byPersons=`) }}
                    footer={null}
                    title={`ОТОБРАЖЕНИЕ ПО УЧАСТНИКАМ`}
                    width={700}
                >
                    {byPersons ?
                        <this.ModalByUsers />
                        :
                        <div />
                    }
                </Modal>
                <Modal
                    className={isMobile ? 'bbb bbb_mobile' : 'bbb'}
                    visible={shaduleCategoryId}
                    onCancel={() => { redirect(`/event/${Event.Id}/?page=shadule`) }}
                    footer={null}
                    title={`Сетка категории ${this.nowBriketsCategory() ? this.nowBriketsCategory().Name : ''}`}
                    width={1200}
                >
                    {shaduleCategoryId ?
                        <Brikets
                            client={this.props.client}
                            judgeMode={iAmAdmin}
                            IsIAmAdmin={iAmAdmin}
                            updateCategorys={reloadCategorys}
                            ServerUrl='capoeirasport.com'
                            competitionId={Championship.Id}
                            category={this.nowBriketsCategory()}
                            selectedCategory={shaduleCategoryId}
                            key={shaduleCategoryId}
                            copyToClipboard={() => { }}
                            EventId={Event.Id}
                            lookedPersonId={lookedPersonId}
                            showInfoModal={showInfoModal}
                            closeInfoModal={closeInfoModal}
                        />
                        :
                        <div />
                    }
                </Modal>
            </div>
        }

        // console.log('byPersons', { byPersons });

        return <div style={{ width, height }} className='event_shadule_plashka'>
            <div className='event_shadule_plashka_headmenu'>
                <Button type={byFields ? 'primary' : 'default'} onClick={() => { this.setState({ byFields: !this.state.byFields }) }} >ПО ПЛОЩАДКАМ</Button>
                <Link to={linkTo('byPersons', iamuser ? `*${iamuser.GroupId || iamuser.GroupIamInId || ''}` : '*')}> <Button type={'default'}>ПО УЧАСТНИКАМ</Button></Link>
            </div>
            <Collapse height='fit-content' activeKey={lookedCategoryId} onChange={this.setActiveCategory} accordion>
                {Categorys_.map((item, index) => {

                    if (!lastFieldId) lastFieldId = item.FieldId;

                    const addSeparator = lastFieldId !== item.FieldId;

                    if (addSeparator) lastFieldId = item.FieldId;

                    return [(byFields && addSeparator) ? <div className='event_shadule_plashka_separator' /> : null, <Panel
                        header={<this.PanelHeader key={'cat' + item.Id} itsMyCategory={item.Id === myCategory} category={item} />}
                        key={item.Id}>
                        <TrefaLayout greedsX={[500]}
                            components={[
                                {
                                    component: PersonsLittleListPlashka,
                                    cell_x: 1,
                                    collapse: 1,
                                    componentProps: {
                                        height: 300,
                                        Persons: selectedCategoryData,
                                        autoSize: true,
                                        autoSizeMax: 7,
                                        personsDirect: true,
                                        mode: iAmAdmin ? 'categorys_admin' : 'categorys',
                                        onCompetitorChange: iAmAdmin ? this.moveFromCategory : null
                                    }
                                }
                            ]}
                        />
                    </Panel>]
                })}
            </Collapse>
            {/* Это видит только админ, если вообще будет видеть */}
            {this.MoveModal()}
            <Modal
                className={isMobile ? 'bbb bbb_mobile' : 'bbb'}
                visible={iAmAdmin && shaduleCategoryId}
                onCancel={() => { redirect(`/event/${Event.Id}/?page=shadule`) }}
                footer={null}
                title={`Сетка категории ${this.nowBriketsCategory() ? this.nowBriketsCategory().Name : ''}`}
                width={1200}
            >
                {shaduleCategoryId ?
                    <Brikets
                        client={this.props.client}
                        judgeMode={iAmAdmin}
                        IsIAmAdmin={iAmAdmin}
                        updateCategorys={reloadCategorys}
                        ServerUrl='capoeirasport.com'
                        competitionId={Championship.Id}
                        category={this.nowBriketsCategory()}
                        selectedCategory={shaduleCategoryId}
                        key={shaduleCategoryId}
                        copyToClipboard={() => { }}
                        EventId={Event.Id}
                        lookedPersonId={lookedPersonId}
                        showInfoModal={showInfoModal}
                        closeInfoModal={closeInfoModal}
                    />
                    :
                    <div />
                }
            </Modal>
            <Modal
                className={isMobile ? 'ttt ttt_mobile' : 'ttt'}
                visible={byPersons}
                onCancel={() => { redirect(`/event/${Event.Id}/?page=shadule&byPersons=`) }}
                footer={null}
                title={`ОТОБРАЖЕНИЕ ПО УЧАСТНИКАМ`}
                width={700}
            >
                {byPersons ?
                    <this.ModalByUsers />
                    :
                    <div />
                }
            </Modal>
        </div>
    }

    ModalByUsers = () => {
        const { byPersons, Results, CategorysAsObjcet, client, groupsLoaded } = this.props;
        // console.log('ModalByUsers', { Results });

        let schools = {};
        let groups = {};

        let allSchools = {};
        let allGroups = {};

        const sep = byPersons.split('*');
        if (sep[0]) {
            const schools_ = sep[0].split('+');
            for (let i = 0; i < schools_.length; i++) {
                schools[schools_[i]] = 1;
            }
        }
        if (sep[1]) {
            const groups_ = sep[1].split('+');
            for (let i = 0; i < groups_.length; i++) {
                groups[groups_[i]] = 1;
            }
        }

        const sendNewData = (schools, groups) => {
            const { redirect, Event } = this.props;
            redirect(`/event/${Event.Id}/?page=shadule&byPersons=${schools.reduce((memo, value, index) => (memo + (index > 0 ? `+${value}` : value)), '')}*${groups.reduce((memo, value, index) => (memo + (index > 0 ? `+${value}` : value)), '')}`)
        }

        const addSchool = (schoolId) => {
            if (!schoolId || schools[schoolId]) return;
            sendNewData([...Object.keys(schools), schoolId], Object.keys(groups));
        }

        const removeSchool = (schoolId) => {
            if (!schoolId || !schools[schoolId]) return;
            let schools_ = { ...schools };
            delete schools_[schoolId];
            sendNewData([...Object.keys(schools_)], Object.keys(groups));
        }

        const addGroup = (groupId) => {
            if (!groupId || groups[groupId]) return;
            sendNewData(Object.keys(schools), [...Object.keys(groups), groupId]);
        }

        const removeGroup = (groupId) => {
            if (!groupId || !groups[groupId]) return;
            let groups_ = { ...groups };
            delete groups_[groupId];
            sendNewData(Object.keys(schools), [...Object.keys(groups_)]);
        }

        let Body = <Loader />


        if (CategorysAsObjcet && Results && Results.allCompetitorsByCategorys) {
            const { allCompetitorsByCategorys } = Results;
            const categorysKeys = Object.keys(allCompetitorsByCategorys);

            Body = [];

            for (let i = 0; i < categorysKeys.length; i++) {
                const categoryId = categorysKeys[i];
                const competitorsInCategory = allCompetitorsByCategorys[categoryId];

                for (let j = 0; j < competitorsInCategory.length; j++) {
                    const {
                        Place,
                        SchoolId,
                        UserId,
                        GroupIamInId,
                        Name,
                        Surname
                    } = competitorsInCategory[j];

                    const category = CategorysAsObjcet[categoryId];
                    const { Name: CatName, FieldId, TimeStart } = category;

                    if (SchoolId && SchoolId < 49) allSchools[SchoolId] = 1;
                    if (GroupIamInId) allGroups[GroupIamInId] = 1;

                    if ((SchoolId && schools[SchoolId]) || (GroupIamInId && groups[GroupIamInId])) {
                        Body.push({ UserId, Place, Surname, Name, CatName, FieldId, TimeStart })
                    }
                }
            }

            if (Body.length) {

                Body = sortBy(Body, ['TimeStart']).map(({ UserId, Surname, Name, CatName, FieldId, TimeStart }) => {
                    return <div key={Name + Surname + UserId} className='event_shadule_plashka_modalbyusers_row'>
                        <div className='event_shadule_plashka_modalbyusers_title_big'>
                            {`${Surname} ${Name}`}
                        </div>
                        <div className='event_shadule_plashka_modalbyusers_title_big'>
                            {CatName}
                        </div>
                        <div className='event_shadule_plashka_modalbyusers_title_low'>
                            {FieldId}
                        </div>
                        <div className='event_shadule_plashka_modalbyusers_title_low'>
                            {TimeStart}
                        </div>
                    </div>
                })
            } else {
                Body = <div className='event_shadule_plashka_modalbyusers_info'>
                    <span>ДОБАВЬТЕ ШКОЛЫ ИЛИ ГРУППЫ В ФИЛЬТРАХ</span>
                </div>
            }


        }

        return <div className='event_shadule_plashka_modalbyusers'>
            <div className='event_shadule_plashka_modalbyusers_header'>
                <div>
                    <div className='event_shadule_plashka_modalbyusers_header_title'>ШКОЛЫ</div>
                    <Select
                        style={{ width: 250 }} size='large'
                        value={null}
                        onChange={(value) => { schools[value] ? removeSchool(value) : addSchool(value) }}
                    >
                        {Object.keys(allSchools).reduce((memo, key) => {
                            memo.push(
                                <Option value={key.toString()}>
                                    <div className={schools[key] ? 'event_shadule_plashka_modalbyusers_header_choosed' : ''}>
                                        <SchoolPlate width={200} height={40} SchoolId={key} linkTo={null} />
                                    </div>
                                </Option>
                            );
                            return memo;
                        }, [])}
                    </Select>
                </div>
                <div>
                    <div className='event_shadule_plashka_modalbyusers_header_title'>ГРУППЫ</div>
                    <Select
                        style={{ width: 250 }} size='large'
                        value={null}
                        onChange={(value) => { groups[value] ? removeGroup(value) : addGroup(value) }}
                    >
                        {Object.keys(allGroups).reduce((memo, key) => {
                            memo.push(
                                <Option value={key.toString()}>
                                    <div className={groups[key] ? 'event_shadule_plashka_modalbyusers_header_choosed' : ''}>
                                        <GroupPlate width={200} height={40} GroupId={key} />
                                    </div>
                                </Option>
                            );
                            return memo;
                        }, [])}
                    </Select>
                </div>
            </div>
            <div className='event_shadule_plashka_modalbyusers_middle'>
                {Object.keys(schools).map((key) => {
                    if (!client.getSchoolById(key)) return null;
                    return <Tag
                        key={key}
                        closable={true}
                        style={{ userSelect: 'none' }}
                        onClose={() => removeSchool(key)}
                    >
                        {client.getSchoolById(key).Name}
                    </Tag>
                })}
                {Object.keys(groups).map((key) => {
                    if (!client.getSchoolById(key)) return null;
                    return <Tag
                        key={key}
                        closable={true}
                        style={{ userSelect: 'none' }}
                        onClose={() => removeGroup(key)}
                    >
                        {(groupsLoaded && client.getGroupById(key)) ? client.getGroupById(key).Name : ' нет '}
                    </Tag>
                })}
            </div>
            <div className='event_shadule_plashka_modalbyusers_title'>
                <div className='event_shadule_plashka_modalbyusers_title_big'>
                    СПОРТСМЕН
                </div>
                <div className='event_shadule_plashka_modalbyusers_title_big'>
                    КАТЕГОРИЯ
                </div>
                <div className='event_shadule_plashka_modalbyusers_title_low'>
                    ПЛОЩАДКА
                </div>
                <div className='event_shadule_plashka_modalbyusers_title_low'>
                    ВРЕМЯ
                </div>
            </div>
            <div className='event_shadule_plashka_modalbyusers_body'>
                {Body}
            </div>
            <div className='event_shadule_plashka_modalbyusers_footer'>
                Вы можете скопировать ссылку из браузера и переслать её зрителям
            </div>
        </div>



    }

    //noCollapse - значит, что готовы пули для всех
    PanelHeader = ({ category, noCollapse, itsMyCategory }) => {

        let categoryInfoBlock = null;

        const { isCategorysInfoReady, Championship, iAmAdmin, isMobile, linkTo, CategorysAsObjcet } = this.props;

        if (isCategorysInfoReady) {
            const { categorysInfo, iamuser, client } = this.props;
            const {
                GroupIamInId,
                GroupId,
                SchoolId
            } = iamuser || {};

            const {
                groupHere,
                groupIamInHere,
                schoolHere,
            } = categorysInfo[category.Id] || {};

            const srcSchool = 'https://capoeirasport.com/graphics/logo_schools/' + SchoolId + '/logo.png';
            const srcGroupId = client ? client.getGroupUrl(GroupId) : 'images/groups/' + GroupId + '/logo.png';
            const srcGroupIHId = client ? client.getGroupUrl(GroupIamInId) : 'images/groups/' + GroupIamInId + '/logo.png';

            categoryInfoBlock = <div className='event_shadule_plashka_collapse_header_info'>
                {schoolHere ? <p className='global-tooltip' data-tooltip={'Есть участники из вашей школы'}><this.LogoPlusCount url={srcSchool} count={schoolHere} /></p> : null}
                {(GroupId && groupHere) ? <p className='global-tooltip' data-tooltip={'Есть Ваши ученики'}><this.LogoPlusCount url={srcGroupId} count={groupHere} /></p> : null}
                {(GroupIamInId && (GroupIamInId !== GroupId) && groupIamInHere) ? <p className='global-tooltip' data-tooltip={'Есть участники из вашей группы'}><this.LogoPlusCount url={srcGroupIHId} count={groupIamInHere} /></p> : null}
            </div>

        }

        let className = noCollapse ? 'event_shadule_plashka_collapse_header_nocollapse' : 'event_shadule_plashka_collapse_header';

        if (itsMyCategory) className += ' event_shadule_plashka_header_mycategory';

        // const { categorysState } = this.state;
        let bgClassName = '';
        let bgStyle = {};
        if (noCollapse && CategorysAsObjcet && CategorysAsObjcet[category.Id]) {

            bgClassName = 'event_shadule_plashka_collapse_header_background';
            bgStyle = { width: `${CategorysAsObjcet[category.Id].Progress * 100}%` };

            if (CategorysAsObjcet[category.Id].IsDone) {
                bgClassName = 'event_shadule_plashka_collapse_header_background_done';
            }
        }

        return <div className={className}>
            <div className={'event_shadule_plashka_collapse_header_background_blank ' + bgClassName} style={bgStyle} />
            <div className='event_shadule_plashka_collapse_header_time'>
                <div className='event_shadule_plashka_collapse_header_time_inside'>
                    <span>{category.TimeStart}</span>
                    {Championship && Championship.FieldsNumber && Championship.FieldsNumber > 1 ? <span>{category.FieldId}</span> : null}
                </div>
            </div>
            <div className='event_shadule_plashka_collapse_header_name_and_info'>{category.Name} {categoryInfoBlock} </div>
            <div> <Icon type="user" /> {this.getCountInCategory(category.Id)} </div>
            {(iAmAdmin && !isMobile && !noCollapse) && <div className='event_shadule_plashka_collapse_header_change'>
                <Link onClick={(e) => e.stopPropagation()} to={linkTo('categoryId', category.Id)}>
                    <div className='event_shadule_plashka_collapse_header_change_item'>
                        жеребьевка
                    </div>
                </Link>
                <div onClick={(e) => this.onCopyCategoryClick(category, e)} className='event_shadule_plashka_collapse_header_change_item'>
                    копировать
                </div>
                <div onClick={(e) => this.onDeleteCategoryClick(category, e)} className='event_shadule_plashka_collapse_header_change_item'>
                    удалить
                </div>
            </div>}
        </div>
    }

    LogoPlusCount = ({ url, count }) => {
        return [
            <Avatar size={20} src={url} />,
            <div className='cf-lpc'>
                {count}
            </div>
        ]
    }


}

// const EventShadulePlashkaWrapped = (props) => <EventWrapper Wrapped={EventShadulePlashka} eventId={props.eventId} componentProps={{ ...props }} />
// export default withCapoeiraSportState('client')(EventShadulePlashkaWrapped);

export default withEventState(`
Event, 
Championship, 
Categorys, 
CategorysAsObjcet,
reloadCategorys,
reloadCompetitors,
Results,
isMobile,
isCompetitorsCountInCategorysReady, 
competitorsCountInCategorys, 
client,
iamuser,
categorysInfo,
isCategorysInfoReady,
iAmAdmin,
iAmCompetitor,
redirect,
showInfoModal,
closeInfoModal,

groupsLoaded


`)(EventShadulePlashka)