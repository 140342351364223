import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class JudgeInfoPlashka
// classname: judge_info_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class JudgeInfoPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { upperStyle } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='judge_info_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='judge_info_plashka'>
            Правила соревнований по капоэйра по версии ФКР подразумевают набор баллов спортсменами во время поединка, в результате чего, спортсмен, набравший большее количество баллов, объявляется победителем. <br/>
            Вы можете ознакомиться с правилами в формате видеоролика и инфографики на этой странице, здесь же мы приведём основные моменты, которые необходимо осознавать спортсмену для результативного участия в соревнованиях.<br/><br/>
            Основными идеями при составлении правил служат:<br/><br/>
            <b className='judge_razdel_2'>Спорт</b>
            Восприятие официальных соревнований по капоэйра в первую очередь как спортивной дисциплины. <br/>
            Это подразумевает наличие конкретных баллов, которые начисляются спортсменам за совершенные действия, и максимально отсекают субъективность судейства: <br/>
            судьи не оценивают спортсменов такими критериями как красиво/не красиво, понравился/не понравился, лучше/хуже. <br/>            
            В официальных спортивных дисциплинах допустимо выявление победителя только с помощью тех критериев, которые можно выразить в числах, а алгебраическая составляющая четко регламентирована.
            <br/><br/>
            <b className='judge_razdel_2'>Унификация</b>
            Наши правила стараются взять золотую середину из всего мира капоэйра, в котором существуют как жесткие стили, включающие в свой арсенал контактные удары ногами, руками, коленями, локтями и головой и борьбу в партере, так и мягкие, ориентированные на взаимодействие, перемещениях и открытые положения. <br/>
            Почти весь арсенал капоэйра находит отражение в официальных соревнованиях: здесь оцениваются и удары, и бросковая техника, и акробатические элементы, но спортсмену нужно точно понимать, в каком именно виде можно выполнять технику и какие есть ограничения. <br/>
            Правила соревнований не ориентированы ни на одну в мире конкретную школу капоэйра, или ни на один в мире конкретный стиль. <br/>
            А значит, любой капоэйрист может успешно принять участие, зная, что именно от него требуется, и будет понимать, чего именно ожидать от соперника.<br/><br/>
            
            <b className='judge_razdel_2'>Минимальная травмоопасность</b>
            Официальные соревнования по капоэйра проходят без каких-либо защитных средств и построены таким образом, чтобы максимально сократить ущерб, который один спортсмен может причинить другому.<br/>
            Сама динамика и сложность положений данного боевого искусства не позволяет ограничить спортсменов от травм целиком, но правила нацелены на то, чтобы максимально оградить участников от получения ущерба.<br/><br/>
            
            
            <b className='judge_razdel_1'>Подготовка</b>

            Оценка происходящего на площадке происходит строго согласно правилам, не учитывается ни уровень, ни школа, ни возраст спортсменов. <br/>
            Капоэйрист с поясом контре-мештре неминуемо получит штраф и предупреждение за удар противнику в голову, а новичок с первым кордао получит положительные баллы, если проведёт результативную подсечку.<br/><br/>
            Таким образом, основной задачей спортсмена или его инструктора не является вывод на площадку максимально быстрого, опасного, ловкого или прыгучего капоэйриста, т.е. просто демонстрация тех качеств, которые могут быть оценены в роде капоэйра, в том числе по технике его родной школы. <br/>
            Мы можем выставить в пример футболиста, который виртуозно владеет мячом, но не знает правил соревнований по футболу: <br/> 
            не понимает, что надо забивать голы в ворота противника, не знает, что такое офсайд, понятия не имеет, в каком случае бьется пенальти. <br/>
            С большой вероятностью, команда с таким футболистом проиграет.<br/><br/>

            По сути, есть всего три задачи спортсмена-капоэйриста во время выступления:<br/>
            - набрать баллы самому с помощью результативных действий<br/>
            - не потерять баллы на штрафных действиях<br/>
            - не дать сопернику набрать положительные баллы<br/><br/>

            Еще раз: Не продемонстрировать свой стиль, не отыграть игру капоэйра, не перестоять противника на руках, или нанести ему контактный удар, а вот эти вот три задачи. <br/>
            И первостепенным и приоритетным для спортсмена или его инструктора является изучение официальных правил и адаптация своей техники под эти цели.


            <br /><br />
              <b className='judge_razdel_2'>текст статьи дополнятся...</b> <br /><br />


</div>
    }

}

export default withCapoeiraSportState(`
client,
iamuser,
`)(JudgeInfoPlashka)