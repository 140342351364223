import React, { Component } from 'react';
import { Input, Button, Modal, Checkbox } from 'antd';
//import logo from './logo.svg';

import { withCRMState } from '../../../../context';
import moment from 'moment';
import 'moment/locale/ru';

import './style.css';

class SBComment extends Component {

    state = {
        Comment: '',
        IsCanceled: null
    }

    commentChange = (e) => {
        this.setState({
            Comment: e.target.value
        })
    }

    isCanceledChange = (e) => {
        this.setState({
            IsCanceled: e.target.checked
        })
    }

    componentDidMount = () => {
        const { Comment, IsCanceled, Id } = this.props;
        if (Id) {
            this.setState({
                Comment,
                IsCanceled
            })
        }
    }

    onOkClick = () => {
        const { closeModal, addSbComment, updateSbComment } = this.props;
        const { SubgroupId, Day, Month, Year, Id } = this.props;
        const { Comment, IsCanceled } = this.state;

        if (!Id) {
            addSbComment({ subgroupid: SubgroupId, day: Day, comment: Comment, isCanceled: IsCanceled });
        } else {
            const updateData = {
                Comment: Comment || null,
                IsCanceled: IsCanceled ? 1 : 0
            }
            updateSbComment(Id, SubgroupId, Day, Month, Year, updateData)
        }

        closeModal();
    }

    onDeleteClick = () => {
        const { closeModal, deleteSbComment } = this.props;
        const { SubgroupId, Day, Month, Year, Id } = this.props;
        deleteSbComment(Id, SubgroupId, Day, Month, Year);
        closeModal();
    }

    render() {
        const { Day, Month, Year, Id } = this.props;
        const { Comment, IsCanceled } = this.state;

        const date = moment(`${Day}.${Month}.${Year}`, 'DD.MM.YYYY').format(`DD MMMM YYYY`);

        return <div className='sbcomment_add_main'>
            <div className='sbcomment_add_main_title'>
                {`Оставить комментарий ${date}`}
            </div>
            <this.EditWrapper title='Краткий комментарий'>
                <Input value={Comment} onChange={this.commentChange} />
            </this.EditWrapper>
            <this.EditWrapper title='Тренировка отменилась?'>
                <Checkbox checked={IsCanceled} onChange={this.isCanceledChange}>Да</Checkbox>
            </this.EditWrapper>
            <div className='sbcomment_add_main_buttons'>
                <Button type='primary' onClick={this.onOkClick}> {Id ? 'ИЗМЕНИТЬ' : 'СОХРАНИТЬ'}</Button>
                {Id ? <Button type="danger" onClick={this.onDeleteClick}> УДАЛИТЬ </Button> : null}
            </div>
        </div>
    }

    EditWrapper = ({ children, title }) => {
        return <div className='subgroup-edit-ew'>
            <div className='subgroup-edit-ew-header'>
                {title}
            </div>
            <div className='subgroup-edit-ew-content'>
                {children}
            </div>
        </div>
    }

}

export default withCRMState(
    `
        client,
        addSbComment,
        updateSbComment,
        deleteSbComment
    `
)(SBComment)