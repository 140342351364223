import React, { PureComponent } from 'react';
import ExerciseWrapper from 'src/ver2/components/exercise/exerciseWrapper';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TrefaLayout } from 'src/ver2/containers/layout';

import Plashki from './plashki';

import Loader from 'src/ver2/components/loader';

import './styles.css';

const {
    ExerciseInfoPlashka,
    ExerciseTitlePlashka,
    ExerciseVideoPlashka,
    ExerciseSchemePlashka,
    ExerciseDiscriptionPlashka
} = Plashki;

class ExercisePage extends PureComponent {

    state = {
    }

    render() {

        let width = 1200;
        const { isExerciseLoaded, Exercise, isMobile } = this.props;

        if (!isExerciseLoaded) return <div className='exercise_page'>
            <Loader text='Загрузка упражнения' />
        </div>

        if (!Exercise) return <div className='exercise_page'>
            Ошибка загрузки упражнения
        </div>

        if (isMobile) width = isMobile.width;

        // NOT MOBILE
        let components_ = [];
        components_.push(
            {
                component: ExerciseTitlePlashka,
                cell_x: 2,
                collapse: 1,
                id: 8151,
                componentProps: { componentSelfHeight: true, height: 20, ...Exercise }
            },
            {
                component: ExerciseSchemePlashka,
                cell_x: 2,
                collapse: 1,
                id: 8152,
                componentProps: {yPadding: 'little', height: 70, elementHeight: 58, ...Exercise }
            },
            {
                component: ExerciseDiscriptionPlashka,
                cell_x: 2,
                collapse: 1,
                id: 8153,
                componentProps: {yPadding: 'little', componentSelfHeight: true, height: 70, ...Exercise }
            },
            {
                component: ExerciseInfoPlashka,
                cell_x: 2,
                collapse: 1,
                id: 8156,
                componentProps: { componentSelfHeight: true, height: 20, yPadding: 'usual', _title: 'Описание', ...Exercise }
            },
            {
                component: ExerciseVideoPlashka,
                cell_x: 4,
                collapse: 1,
                id: 8157,
                componentProps: { height: 335, _title: 'Видео исполнения', ...Exercise }
            }
        )

        return <div style={{ width }} className='exercise_page'>
            <TrefaLayout greedsX={[25, 575, 25, 550, 25]}
                components={
                    components_
                }
            />
        </div>
    }
}


const componentWrapped = (props) => <ExerciseWrapper Wrapped={ExercisePage} client={props.client} exerciseId={props.match.params.exerciseId ? Number(props.match.params.exerciseId) : 0} componentProps={{ ...props }} />
export default withCapoeiraSportState('client, showInfoModal, iamuser')(componentWrapped);