import React, { PureComponent } from 'react';
import { withShopState } from 'src/ver2/pages/shop/context';
import ShopAvatar from 'src/ver2/components/shop/avatar';
import Loader from 'src/ver2/components/loader';

// import { NumberText } from 'src/ver2/containers/lowlavel/numberText';

import './style.css';

class ShopBigPlashka extends PureComponent {

    render() {

        const { Shop, client, iAmAdmin } = this.props;
        if (!Shop) return <div className='shop_big_plashka'>
            <Loader />
        </div>

        const {
            Id, Title
        } = Shop;

        const { width, height } = this.props.upperStyle;

        return <div style={{ width, height }} className='shop_big_plashka'>
            {/* <div style={{ width: height, height: height }} className='shop_big_plashka___avatar_back'>
                <ShopAvatar size={height} shopId={Id} />
            </div> */}
            <div style={{ width: height, height: height }} className='shop_big_plashka___avatar_container'>
                <ShopAvatar size={height * 0.95} shopId={Id} />
            </div>
            <div style={{ width: (width - height), height: height }} className='shop_big_plashka___info_container'>
                <div className='shop_big_plashka___info_container___name global_color_main'>
                    {Title}
                </div>
            </div>
        </div>
    }

}

export default withShopState(`
Shop,
isMobile,
iAmAdmin,
client
`)(ShopBigPlashka)