import React, { PureComponent, Fragment } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TrefaLayout } from 'src/ver2/containers/layout';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import JudgeInfoPlashka from './plashki/info';
import JudgeVideoRulesPlashka from './plashki/videoRules';
import JudgePdfRulesPlashka from './plashki/pdfRules';

import { PamyatkaRazdel, AboutTabText, RaitingTabText, CategorysTabText } from './components';

import AddEventData from 'src/ver2/containers/addEventModal';

import './styles.css';
import { Modal, Tabs, Collapse } from 'antd';
const TabPane = Tabs.TabPane;

const { Panel } = Collapse;


class JudgePage extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            showBeJudgeModal: false
        }

    }

    changeBeJudgeModal = (showBeJudgeModal = true) => this.setState({ showBeJudgeModal })

    componentDidMount() {
    }

    render() {

        const { showBeJudgeModal } = this.state;
        const { isMobile } = this.props;
        const width = isMobile ? isMobile.width - 20 : 1200;

        // if (isMobile) {

        //     const { width } = isMobile;
        //     const targetWidth = width - 20;

        //     return <div style={{ width }} className='judge_page'>

        //         <Tabs tabPosition='top' defaultActiveKey='1'>
        //             <TabPane tab='О судействе' key='1'>
        //                 <JudgeInfoPlashka upperStyle={{ width: targetWidth, height: 'fit-content' }} />
        //             </TabPane>
        //             <TabPane tab='Правила' key='2'>
        //                 <TrefaLayout greedsX={[10, targetWidth, 10]}
        //                     components={[
        //                         {
        //                             component: JudgeVideoRulesPlashka,
        //                             id: 2,
        //                             cell_x: 2,
        //                             collapse: 1,
        //                             componentProps: {
        //                                 height: 230,
        //                                 yPadding: 'little',
        //                                 ytTitle: "Видео памятка",
        //                                 ytUrl: "https://www.youtube.com/embed/Avf3wsS5gv0",
        //                                 _title: 'ВИДЕО ПАМЯТКА ПО ПРАВИЛАМ'
        //                             }
        //                         },
        //                         {
        //                             component: JudgeVideoRulesPlashka,
        //                             id: 3,
        //                             cell_x: 2,
        //                             collapse: 1,
        //                             componentProps: {
        //                                 height: 230,
        //                                 yPadding: 'little',
        //                                 ytTitle: "Видео памятка СОЛО",
        //                                 ytUrl: "https://www.youtube.com/embed/s7VqCbNF75M",
        //                                 _title: 'ВИДЕО ПАМЯТКА ПО ПРАВИЛАМ СОЛО'
        //                             }
        //                         },
        //                         {
        //                             component: JudgePdfRulesPlashka,
        //                             id: 4,
        //                             cell_x: 2,
        //                             collapse: 1,
        //                             componentProps: {
        //                                 height: 100,
        //                                 yPadding: 'little',
        //                                 _title: "Графические правила"
        //                             }
        //                         }



        //                     ]
        //                     }
        //                 />
        //             </TabPane>
        //         </Tabs>
        //     </div>
        // }


        return <div style={{ width }} className='judge_page'>

            <Tabs tabPosition='top' defaultActiveKey='1'>
                <TabPane tab='О Федерации' key='1'>
                    <this.AboutTab />
                </TabPane>
                <TabPane tab='Категории' key='2'>
                    <this.CategorysTab />
                </TabPane>
                {/* <TabPane tab='Рейтинг' key='3'>
                    <this.RaitingTab />
                </TabPane> */}
                <TabPane tab='Правила' key='4'>
                    <this.RulesTab />
                </TabPane>
                <TabPane tab='Памятки' key='5'>
                    <this.PamyatkaTab />
                </TabPane>
            </Tabs>

            <Modal
                className='judgeModalClass'
                visible={showBeJudgeModal}
                onCancel={() => { this.changeBeJudgeModal(false) }}
                footer={null}
                title={'Стать судьёй'}
                width={1200}
            >
                <AddEventData />
            </Modal>

            {/* <TrefaTextEdit /> */}

        </div>
    }

    AboutTab = () => {
        return <div className='judge_about_main'>
            <AboutTabText />
        </div>
    }

    CategorysTab = () => {
        return <div className='judge_about_main'>
            <CategorysTabText />
        </div>
    }

    RaitingTab = () => {
        return <div className='judge_about_main'>
            <RaitingTabText />
        </div>
    }

    RulesTab = () => {

        const { isMobile } = this.props;

        if (isMobile) {

            const { width } = isMobile;
            const targetWidth = width - 20;

            return <div style={{ width }} className='judge_page_mobile'>
                 <TrefaLayout greedsX={[10, targetWidth, 10]}
                            components={[
                                {
                                    component: JudgeInfoPlashka,
                                    id: 1,
                                    cell_x: 2,
                                    collapse: 1,
                                    componentProps: {
                                        height: 800,
                                        yPadding: 'little',
                                        _title: 'ОБЩАЯ ИНФОРМАЦИЯ'
                                    }
                                },
                                {
                                    component: JudgeVideoRulesPlashka,
                                    id: 2,
                                    cell_x: 2,
                                    collapse: 1,
                                    componentProps: {
                                        height: 230,
                                        yPadding: 'little',
                                        ytTitle: "Видео памятка",
                                        ytUrl: "https://www.youtube.com/embed/Avf3wsS5gv0",
                                        _title: 'ВИДЕО ПАМЯТКА ПО ПРАВИЛАМ'
                                    }
                                },
                                {
                                    component: JudgeVideoRulesPlashka,
                                    id: 3,
                                    cell_x: 2,
                                    collapse: 1,
                                    componentProps: {
                                        height: 230,
                                        yPadding: 'little',
                                        ytTitle: "Видео памятка СОЛО",
                                        ytUrl: "https://www.youtube.com/embed/s7VqCbNF75M",
                                        _title: 'ВИДЕО ПАМЯТКА ПО ПРАВИЛАМ СОЛО'
                                    }
                                },
                                {
                                    component: JudgePdfRulesPlashka,
                                    id: 4,
                                    cell_x: 2,
                                    collapse: 1,
                                    componentProps: {
                                        height: 100,
                                        yPadding: 'little',
                                        _title: "Графические правила"
                                    }
                                }



                            ]
                            }
                        />
            </div>
        }


        return <TrefaLayout greedsX={[40, 750, 20, 350, 40]}
            components={[
                {
                    component: JudgeInfoPlashka,
                    id: 1,
                    cell_x: 2,
                    collapse: 1,
                    componentProps: {
                        height: 1400,
                        yPadding: 'little',
                        _title: 'ОБЩАЯ ИНФОРМАЦИЯ'
                    }
                },
                {
                    component: JudgeVideoRulesPlashka,
                    id: 2,
                    cell_x: 4,
                    collapse: 1,
                    componentProps: {
                        height: 230,
                        yPadding: 'little',
                        ytTitle: "Видео памятка",
                        ytUrl: "https://www.youtube.com/embed/Avf3wsS5gv0",
                        _title: 'ВИДЕО ПАМЯТКА ПО ПРАВИЛАМ'
                    }
                },
                {
                    component: JudgeVideoRulesPlashka,
                    id: 3,
                    cell_x: 4,
                    collapse: 1,
                    componentProps: {
                        height: 230,
                        yPadding: 'little',
                        ytTitle: "Видео памятка СОЛО",
                        ytUrl: "https://www.youtube.com/embed/s7VqCbNF75M",
                        _title: 'ВИДЕО ПАМЯТКА ПО ПРАВИЛАМ СОЛО'
                    }
                },
                {
                    component: JudgePdfRulesPlashka,
                    id: 4,
                    cell_x: 4,
                    collapse: 1,
                    componentProps: {
                        height: 100,
                        yPadding: 'little',
                        _title: "Графические правила"
                    }
                }



            ]
            }
        />
    }

    PamyatkaTab = () => {
        return <div className='pamyatki_main'>
            <Collapse accordion>

                <Panel header={'Участник'}>
                    <PamyatkaRazdel
                        Title={'Форма'}
                        leftIcons={[
                            'spu3'
                        ]}
                        rightText={<Fragment>
                            В официальную форму спортсмена входят белые абады и белая соревновательная
футболка. На абадах допускается логотип школы/группы, спортсмен
повязывается кордао, которым он обладает на данный момент.
Соревновательная футболка обязательно должна содержать логотип ФКР на
груди, допускается название школы на груди и логотип школы/группы/спонсора
на спине.
                        </Fragment>
                        }
                    />
                    <PamyatkaRazdel
                        Title={'Участие'}
                        leftIcons={[
                            'blank'
                        ]}
                        rightText={
                            <Fragment>

                                Спортсмены проходят регистрацию на портале capoeirasport и должны в срок
                                произвести оплату и предоставить все необходимые для данного мероприятия
                                документы, указанные на странице мероприятия. Обычно, в них входит
                                страховка и медицинская справка.
<br /><br />
                                При указании категории, вариант НОВИЧКИ подразумевает стаж занятия
                                капоэйрой не более двух лет. Участники данных категорий не представляются
                                к разрядам и не попадают в основной соревновательный рейтинг.
<br /><br />
                                Время начала Вашей категории обычно становится известно за несколько дней
                                до соревнования в разделе Расписание. Следите за обновлением новостей на
                                странице мероприятия - всегда есть вероятность переноса таймингов
                                соревнования. Следует приехать как минимум за 15 - 20 минут до начала своей
                                категории, чтобы успеть размяться и, при необходимости, передать документы.
<br /><br />
                                Как только на странице мероприятия появляется раздел СЕТКИ, Вы можете не
                                только видеть время начала категории, но и отслеживать своё перемещение
                                по соревновательной сетке.
                        </Fragment>}
                    />
                    <PamyatkaRazdel
                        Title={'Правила'}
                        leftIcons={[
                            'blank'
                        ]}
                        rightText={
                            <Fragment>

                                Спешим напомнить всем спортсменам, что ФКР - это общественная организация,
                                развивающая СПОРТ на территории РФ. ФКР не является школой, клубом или
                                группой по капоэйра, в ней нет и не может быть градации поясов, списка
                                приоритетных игровых ритмов, курирующего мастера или культивируемого стиля
                                игры. ФКР занимается только организацией спортивных соревнований, которые
                                проходят по четко регламентированным правилам, как и сотни других видов
                                спорта в нашей стране.
<br /><br />
                                Взываем ко всем спортсменам внимательно изучить правила, которые
                                представлены на портале CAPOEIRASPORT в нескольких вариантах и еще раз
                                пропустить через себя эту мысль.
<br /><br />
                                Мы не оцениваем на соревнованиях игру капоэйриста. Бой спортсменов - это не
                                рода, где надо выглядеть лучше соперника, просто показать свой максимум, или
                                выполнять действия, которые приняты в вашей школе и в вашем стиле.
<br /><br />
                                ФКР не берёт в учёт школы, стили, пояса, и личную позицию спортсменов
                                относительно того, чем является капоэйра и что и как в ней можно делать. И не
                                навязывает сама эту позицию: мы ни в коем случае не говорим о том, что
                                бой спортсменов - капоэйристов на соревнованиях и есть капоэйра. Это - Спорт.
<br /><br />
                                Во время боя вы получаете положительные баллы за разрешенную технику и
                                отрицательные баллы за запрещенную. Выигрывает тот, кто набрал больше баллов.
                                Вот и всё. Никакие другие моменты, кроме описанных в правилах, в учёт
                                не берутся.
                        </Fragment>}
                    />
                </Panel>


                <Panel header={'Организатор'}>
                    <PamyatkaRazdel
                        Title={'Форма'}
                        leftIcons={[
                            'blank'
                        ]}
                        rightText={<Fragment>
                            Для всех представителей судейской коллегии существует свой регламент
                            официальной формы. Организатор должен присутствовать на мероприятии
                            строго согласно регламенту, не пренебрегая своим внешним видом:
                            форма должна быть опрятной, чистой, подходить Вам по размеру.
                            <br /><br />
                            Помните, что вы - лицо организации, небрежность во внешнем виде - первое,
                            что бросается в глаза зрителем, участникам и гостям.
                        </Fragment>
                        }
                    />
                    <PamyatkaRazdel
                        Title={'Обязанности'}
                        leftIcons={[
                            'blank'
                        ]}
                        rightText={
                            <Fragment>

                                В первую очередь необходимо помнить, что любая роль на соревнованиях -
- незаменима. Повязываете ли Вы пояса, встречаете гостей, или ведёте площадку -
Ваша работа влияет на общие тайминги мероприятия, на корректность отработки
логистики и на настроение участников и зрителей.
<br /><br />
                                Поэтому первая обязанность любого организатора - ответственно относиться к
                                своей должности, четко знать и выполнять инструкции. Если Вы чувствуете, что
                                устали или не знаете что делать в конкретной ситуации - обратитесь к
                                вышестоящему лицу. На соревнованиях ни у кого нет цели принуждать Вас
                                находиться в нервной обстановке, оценивать и отчитывать за Вашу работу или
                                любым другим образом морально воздействовать и заставлять делать то, что Вам
                                не комфортно. Все организаторы должны быть нацелены лишь на то, чтобы
                                провести лучшее мероприятия и подарить участникам новые положительные эмоции.
<br /><br />
                                На время проведения мероприятия все организаторы в первую очередь являются
                                членами ФКР, а уже во вторую - представителями своих школ и групп, а значит
                                должны быть индифферентны к спортсменам, даже если это их коллеги по школе,
                                ученики или друзья. Организаторы не имеют право открыто болеть за спортсменов:
                                кричать, громко подсказывать или подбадривать во время выступлений.
                                Максимум, что допустимо - это подойти и негромко что-то сказать во время
                                пауз между выступлениями. Если в данный момент вы свободны от своих
                                обязанностей, в крайнем случае, Вы можете переодеть официальную форму и
                                поддерживать спортсменов из зрительской зоны.
<br /><br />
                                Все организаторы должны являться в положенное время, объявленное накануне,
                                на мероприятие и заранее сообщать об опоздании или отсутствии в случае
                                непредвиденных обстоятельств. Старайтесь обеспечить связь, чтобы в любой
                                момент времени Вас можно было легко найти на мероприятии.
<br /><br />
                                Не отказывайте в просьбе выполнить какое-либо поручение: почти никогда
                                организация не проходит на 100% по плану, зачастую случаются ситуации, когда
                                нужно срочно выполнить ту или иную задачу.
<br /><br />
                                Всегда смотрите на организацию с позиции участников. Какая бы у Вас не была
                                роль - старайтесь сделать мероприятие таким, в котором Вас самим хотелось бы
                                принять участие!
                        </Fragment>}
                    />
                </Panel>

                <Panel header={'Боковой судья'}>
                    <PamyatkaRazdel
                        Title={'Форма'}
                        leftIcons={[
                            'bok_judge_forma'
                        ]}
                        rightText={`
                            В официальную форму бокового судьи входит черное судейское поло, черные 
                            брюки с символикой ФКР, темная обувь, значок, подтверждающий судейский 
                            разряд. Брюки носятся без кордао.
                            `}
                    />
                    <PamyatkaRazdel
                        Title={'Обязанности'}
                        leftIcons={[
                            'bok_judge_ob1',
                            'bok_judge_ob2',
                        ]}
                        rightText={
                            <Fragment>БС приписывается к определённой судейской бригаде или площадке. По вызову
                            руководителя площадки занимает свое судейское место.
                            До начала встречи БС заполняет в протоколе ФИО спортсменов с красным и
                            синим поясами в соответствующие графы, после чего ожидает начала поединка.
                            Во время встречи БС отмечает в протоколе результативные и штрафные действия:
                            записываются только баллы, а не названия техник. Баллы пишутся отдельно за
                            каждое произведенное действие (т.е. за проведенную мартелу, а потом кабесаду
                            записывается отдельно +1 и +2 балла, а не +3 в сумме).
                            БС во время встречи держит в уме картину движений каждого из двух
                            спортсменов, чтобы в конце поединка выставить баллы культуры движения.
                            БС записывает в протокол те техники, которые видит сам, или те, на которые
                            явно указал рефери.
                        <br />
                                <br />
                                После остановки рефери поединка, БС суммирует баллы спорстменов в протоколе
                                и отмечает победителя (у кого больше очков). После подсчета, БС берет в руки
                                флажки и устанавливает глазной контакт с руководителем площадки.
                                БС дожидается сигнала “Судьи, результат”, после чего поднимает флажок.
                        <br />
                                <br />
                                В случае возникновения вопросов любого характера БС не имеет права
                                самостоятельно покинуть свое судейское место: он поднимает руку и ждёт
                                вызова от руководителя площадки.
                        </Fragment>}
                    />
                </Panel>

                <Panel header={'Cудья при участниках'}>
                    <PamyatkaRazdel
                        Title={'Форма'}
                        leftIcons={[
                            'spu1'
                        ]}
                        rightText={`
                        В официальную форму судьи при участниках (далее - СПУ) входит белая 
                        соревновательная футболка, черные брюки с символикой ФКР. Брюки носятся
                        без кордао.
                            `}
                    />
                    <PamyatkaRazdel
                        Title={'Обязанности'}
                        leftIcons={[
                            'spu2',
                            'spu3',
                            'spu4',
                            'spu5',
                            'spu6'
                        ]}
                        rightText={
                            <Fragment>
                                СПУ приписывается к определённой площадке и перед соревнованиями должен
получить инструктаж относительно логистики вывода на площадки спортсменов
в каждом конкретном мероприятии. СПУ находится в зоне подготовки
спортсменов.
<br />
                                <br />
                                СПУ должен обладать информацией, какая пара спортсменов вызывается в
                                данный момент на его площадку, и какая готовится: или воспринимать на слух
                                объявления в микрофон, или смотреть на информационные плазмы, или на
                                мобильном устройстве на портале capoeirasport.
<br />
                                <br />
                                При объявлении спортсменов, которые сейчас готовятся, СПУ должен: <br />
                                - дождаться, когда объявленный спортсмен подойдет в зону подготовки, или
самостоятельно найти и привести спортсмена из зоны разминки. <br />
                                - переспросить фамилию и имя участника, убедиться, что спортсмен надевает
свой пояс (первый вызванный спортсмен - красный, второй - синий) <br />
                                - осмотреть участника на предмет наличия регламентированной соревновательной
формы. В случае отсутствия - отправить спортсмена заменить форму. <br />
                                - осмотреть участника на предмет отсутствия у него браслетов, цепочек,
пирсинга, которые могут привести к травмам обоих спортсменов. <br />
                                - в случае наличия таких предметов, СПУ не выпускает спортсмена на площадку
                                до того момента, пока тот не снимет их с себя. СПУ оставляет у себя снятые
предметы и возвращает их по окончании встречи. <br />
                                - если спортсмен успешно прошел осмотр внешнего вида, СПУ повязывает ему
                                пояс соответствующего цвета и проверяет, что пояс надёжно закреплён, не будет
                                мешать спортсмену двигаться и не размотается в ходе встречи.
<br />
                                <br />
                                При объявлении спортсменов, которые вызываются на площадку, СПУ должен:
                                - сопроводить спортсменов с повязанным поясом на площадку или указать им,
                                как дойти до площадки.
<br />
                                <br />
                                По окончании поединка, СПУ должен: <br />
                                - снять со спортсмена соревновательный пояс
                                - вернуть, при необходимости, снятые украшения и иные предметы

<br />
                                <br />
                                В обязанности СПУ входит также организация порядка на территории судейской
                                зоны: СПУ не должны допускать в неё посторонних людей, в том числе
                                родителей, спортсменов, которые сейчас не выступают, инструкторов.
                                Допускаются только организаторы мероприятия и представители команд, которые
                                имеют бейджики или иную форму подтверждения аккредитации.
<br />
                                <br />
                                В случае возникновения запросов, СПУ может самостоятельно передать
                                информацию Главному судье или другим организаторам или провести человека
                                в судейскую зону под личную ответственность.





                        </Fragment>}
                    />
                </Panel>

                <Panel header={'Рефери'}>
                    <PamyatkaRazdel
                        Title={'Форма'}
                        leftIcons={[
                            'ref1'
                        ]}
                        rightText={`
                        в официальную форму рефери входит красное судейское поло, черные 
                        брюки с символикой ФКР, темная обувь, значок, подтверждающий судейский 
                        разряд. Брюки носятся без кордао.
                            `}
                    />
                    <PamyatkaRazdel
                        Title={'Обязанности'}
                        leftIcons={[
                            'ref2',
                        ]}
                        rightText={
                            <Fragment>
                                РЕФЕРИ приписывается к определённой судейской бригаде или площадке. По
                                вызову руководителя площадки занимает место во главе площадки: со стороны
                                красного и синего треугольников.
<br />
                                <br />
                                После вызова спортсменов на площадку, Рефери дожидается, когда они
                                займут места в красном и синем треугольниках согласно повязанному на них
                                поясу, призывает спортсменов к рукопожатию. Осведомляется у спортсменов,
                                готовы ли они начинать бой, может дать напутственные слова.
<br />
                                <br />
                                Если спортсмены готовы, Рефери ждёт, пока все боковые судьи заполнят
                                протоколы и подадут ему сигнал взглядом, что готовы судить.
<br />
                                <br />
                                Рефери знаком руки выпускает спортсменов в круг, после чего сразу же отходит
                                в левую или правую часть площадки, чтобы не загораживать вид руководителю
                                площадки и снимающей видео камере.
<br />
                                <br />
                                В ходе встречи Рефери внимательно следит за поединком, специальными жестами
                                отмечает результативные и штрафные действия, или их отсутствие в конкретной
                                ситуации, помогает боковым судьям в случае, если их ракурс не позволяет
                                увидеть технику. При необходимости прерывает бой и выводит спортсменов
                                на треугольники, после чего продолжает его.
<br />
                                <br />
                                Рефери следит за судейским столом и, когда поднимается желтый флажок,
                                останавливает встречу, после чего провожает спортсменов на треугольники.
<br />
                                <br />
                                Дожидается команды руководителя площадки “Судьи, результат”, после чего
                                поднимает руку победившему спортсмену, исходя из решения боковых судей,
                                вновь призывает спортсменов к рукопожатию и выводит из с зоны площадки.
<br />
                                <br />
                                РЕФЕРИ может в любой момент времени прервать встречу, вернуть спортсменов
                                в треугольники и обратиться к руководителю площадки.
                        </Fragment>}
                    />
                </Panel>

                <Panel header={'Руководитель площадки'}>
                    <PamyatkaRazdel
                        Title={'Форма'}
                        leftIcons={[
                            'bok_judge_forma'
                        ]}
                        rightText={`
                        В официальную форму руководителя площадки (далее - РП) входит черное 
судейское поло, черные брюки с символикой ФКР, темная обувь, значок, 
подтверждающий судейский разряд. Брюки носятся без кордао.
                            `}
                    />
                    <PamyatkaRazdel
                        Title={'Обязанности'}
                        leftIcons={[
                            'ruk1',
                            'ref2'
                        ]}
                        rightText={
                            <Fragment>
                                РП полностью обеспечивает работу площадки, к которой он приписан. Он
                                занимает место за судейским столом площадки и с помощью ПК через
                                судейскую систему портала CAPOEIRASPORT последовательно проводит
                                приписанные площадке категории.
<br />
                                <br />
                                В начале мероприятия РП должен быть осведомлён, на какой площадке он
                                работает, кто входит в его судейскую бригаду, иметь на руках расписание
                                площадки, убедиться, что предоставленное ему оборудование исправно
                                (ПК работает и подключен к интернету, в браузере открыт и настроен на
                                судейство сайт CAPOEIRASPORT, информационная плазма настроена на показ
                                нужной площадки, микрофон исправен). В случае выявления технических
                                проблем, РП может обратиться к техническому специалисту мероприятия,
                                или решить их самостоятельно в случае наличия компетенции.
<br />
                                <br />
                                В случае, если в судейскую систему были внесены изменения с последнего
                                соревнования, РП должен пройти инструктаж до начала мероприятия.
<br />
                                <br />
                                Перед началом категории РП должен собрать и попросить занять свои места
                                судейскую бригаду, при желании может попросить выйти на площадку
                                участников категории или провести представление судейской бригады.
<br />
                                <br />
                                В ходе проведения категории РП:<br />
                                - объявляет две пары спортсменов, которые вызываются и которые готовятся,
делая при этом соответствующую пометку в судейской системе.<br />
                                - отмечает на специальном секундомере начало встречи (когда рефери выводит
спортсменов в бой) и поднимает желтый флажок, когда время боя окончено.<br />
                                - после завершения боя дожидается, когда все боковые судьи закончат считать
                                баллы, после чего произносит фразу “судьи, результат”, призывая их поднять
                                флажки, объявляет в микрофон победу красного или синего пояса единогласно
или с преимуществом в один голос.<br />
                                - после завершения всей категории нажимает кнопку “отправить результаты”,
                                после чего переходит к следующей по расписанию категории.
<br />
                                <br />
                                В случае, если следующая категория еще не должна начаться по расписанию, РП
                                объявляет паузу на площадке и на время распускает судейскую бригаду.
<br />
                                <br />
                                РП может в помощь взять себе одного судью и возложить на него часть
                                обязанностей, такие как отслеживание время секундомера, вызов спортсменов.
<br />
                                <br />
                                РП может во время паузы между боями подозвать к себе любого члена судейской
                                бригады по своему желанию, или если тот обратил внимание поднятой вверх рукой.
<br />
                                <br />
                                В крайнем случае РП может остановить схватку командой в микрофон.
<br />
                                <br />
                                Ориентируясь на специальный функционал судейской системы, РП должен
                                стараться провести свою площадку в тайминги, составленный перед соревнованием
<br />
                                <br />
                                В случае подачи апелляции, РП объявляет в микрофон имена участников встречи
                                и делает перерыв на площадке, если нет возможности вести категорию дальше
                                по пуле.




                        </Fragment>}
                    />
                </Panel>

            </Collapse>

        </div>
    }

}


export default withCapoeiraSportState('iamuser, client, getText, isMobile')(JudgePage);