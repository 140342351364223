import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { groupBy, orderBy } from 'lodash';
import { Tooltip } from 'antd';

import { TrefaLayout } from 'src/ver2/containers/layout';
import OTIconPlashka from './plashki/one-technic/icon';
import OTTitlePlashka from './plashki/one-technic/title';
import OTParamsPlashka from './plashki/one-technic/params';
import OTVideoPlashka from './plashki/one-technic/video';
import OTDiscriptionPlashka from './plashki/one-technic/discription';
import OTEducationPlashka from './plashki/one-technic/education';
import OTIsAddedPlashka from './plashki/one-technic/isadded';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import SeqMonitorPlashka from 'src/ver2/plashki/sequencesMonitor';
import TPLanPlashka from './plashki/plan';

import './styles.css';

/*

    Это страница показывает один элемент, его описание и прочее прочее

*/

class TechincPageOne extends PureComponent {

    state = {
        element: null,
        Sequences: null
    }

    componentDidMount() {
        this.checkElement();
        this.getSequences();
    }

    checkElement = (props_) => {
        const props = props_ || this.props;
        const { elementKey } = props;
        const element = props.client.getElement(elementKey);

        this.setState({ element })
    }

    clickOnAddElement = async () => {
        const { elementKey } = this.props;
        const { client, renewElements } = this.props;
        await client.addUserElement(elementKey);
        renewElements();
    }

    getSequences = async () => {
        const { client, elementKey } = this.props;
        if (elementKey) {
            let sequences = await client.getSoloSequenceByElement(elementKey);
            if (sequences && sequences.length > 0) {
                this.setState({ sequences: sequences.slice(0, 5) });
            }
        }
    }

    render() {

        const { element, sequences } = this.state;
        const { isMobile, iamuser } = this.props;
        if (!element) return null;

        const { isVideo, Discription, Tutorials } = element;

        if (isMobile) {
            let components = [
                {
                    component: OTIconPlashka,
                    cell_x: 1,
                    collapse: 3,
                    id: 1,
                    componentProps: { height: 400, elementKey: element.key }
                },
                {
                    component: OTTitlePlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 2,
                    componentProps: { height: 50, title: element.title }
                },
                {
                    component: OTParamsPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 3,
                    componentProps: { height: 30, element }
                }
            ]

            if (iamuser) {

                if (iamuser.myElementsObj && iamuser.myElementsObj[element.key]) {
                    components.push(
                        {
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 24 }
                        }
                        ,
                        {
                            component: OTIsAddedPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 21369,
                            componentProps: { height: 40, elementKey: element.key }
                        }
                    )
                } else {
                    components.push(
                        {
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 24 }
                        },
                        {
                            component: ButtonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 333,
                            componentProps: { height: 70, yPadding: 'usual', text: 'ДОБАВИТЬ ЭЛЕМЕНТ СЕБЕ', onClick: this.clickOnAddElement }
                        },
                    )
                }
            }

            if (isVideo) {
                components.push(
                    {
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: 24 }
                    }
                    ,
                    {
                        component: OTVideoPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 369,
                        componentProps: { height: isMobile.width * (9 / 16), elementKey: element.key }
                    }
                )
            }

            components.push(
                {
                    component: OTDiscriptionPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 4,
                    componentProps: { height: 400, Discription, yPadding: true, _title: 'Описание' }
                }
            )

            if (Tutorials && Tutorials.length) {
                components.push(
                    {
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: 10 }
                    },
                    {
                        component: OTEducationPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 569,
                        componentProps: { height: 300, _title: 'Обучалки', Tutorials }
                    }
                )
            }

            if (sequences && sequences.length) {
                components.push(
                    {
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: 44 }
                    },
                    {
                        component: SeqMonitorPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 69569,
                        componentProps: {
                            height: 600,
                            directHeight: isMobile ? isMobile.width * 720 / 1200 : null,
                            maxWidth: isMobile.width,
                            _title: 'Связки с элементом',
                            sequences,
                            maxSequences: sequences.length,
                            withName: true,
                            vertical: true
                        }
                    }
                )
            }

            components.push(
                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 50 }
                }
            );

            return <div className='technic_pageall'>
                <TrefaLayout greedsX={[15, isMobile.width - 30, 15]}
                    components={components}
                />
            </div>
        }

        // КОНЕЦ МОБИЛЬНОГО ВАРИАНТА

        let components = [
            {
                component: OTIconPlashka,
                cell_x: 1,
                collapse: 2,
                id: 1,
                componentProps: { height: 300, elementKey: element.key }
            },
            {
                component: OTTitlePlashka,
                cell_x: 4,
                collapse: 1,
                id: 2,
                componentProps: { height: 150, title: element.title }
            },
            {
                component: OTParamsPlashka,
                cell_x: 4,
                collapse: 1,
                id: 3,
                componentProps: { height: 50, element }
            }
        ]

        if (iamuser) {

            if (iamuser.myElementsObj && iamuser.myElementsObj[element.key]) {
                components.push(
                    {
                        cell_x: 4,
                        collapse: 1,
                        componentProps: { height: 24 }
                    }
                    ,
                    {
                        component: OTIsAddedPlashka,
                        cell_x: 4,
                        collapse: 1,
                        id: 31369,
                        componentProps: { height: 40, elementKey: element.key }
                    }
                )
            } else {
                components.push(
                    {
                        cell_x: 4,
                        collapse: 1,
                        componentProps: { height: 24 }
                    },
                    {
                        component: ButtonPlashka,
                        cell_x: 4,
                        collapse: 1,
                        id: 333,
                        componentProps: { height: 80, yPadding: 'usual', text: 'ДОБАВИТЬ ЭЛЕМЕНТ СЕБЕ', onClick: this.clickOnAddElement }
                    },
                )
            }
        }


        if (isVideo) {
            components.push(
                {
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { height: 24 }
                }
                ,
                {
                    component: OTVideoPlashka,
                    cell_x: 4,
                    collapse: 1,
                    id: 369,
                    componentProps: { height: 700 * (9 / 16), elementKey: element.key }
                }
            )
        }

        components.push(
            {
                component: OTDiscriptionPlashka,
                cell_x: 4,
                collapse: 1,
                id: 4,
                componentProps: { height: 200, Discription, yPadding: true, _title: 'Описание' }
            }
        )

        if (Tutorials && Tutorials.length) {
            components.push(
                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 340 }
                },
                {
                    component: OTEducationPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 569,
                    componentProps: { height: 500, _title: 'Обучалки', Tutorials }
                }
            )
        }

        if (sequences && sequences.length) {
            components.push(
                {
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { height: 44 }
                },
                {
                    component: SeqMonitorPlashka,
                    cell_x: 4,
                    collapse: 1,
                    id: 69569,
                    componentProps: {
                        height: 200,
                        _title: 'Связки с элементом',
                        sequences,
                        maxSequences: sequences.length
                    }
                }
            )
        }


        return <div className='technic_pageall'>
            <TrefaLayout greedsX={[35, 400, 30, 700, 35]}
                components={components}
            />
        </div>
    }

}


export default withCapoeiraSportState('iamuser, client, isMobile, renewElements')(TechincPageOne);