import React, { PureComponent } from 'react';
import TrefaText from 'src/ver2/components/trefaText';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class ComboInfoPlasha
// classname: combo_info_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ComboInfoPlasha extends PureComponent {
    render() {

        const { Text, upperStyle: { width, height }, innerId } = this.props;
        return <div style={{ width, height }} className='combo_info_plashka'>
            <div className='combo_info_plashka_discription' id={innerId}>
                <TrefaText trefaText={Text}/>
            </div>
        </div >
    }

}

export default ComboInfoPlasha;