import React, { Component } from 'react';
//import logo from './logo.svg';

import Subgroup from './components/subgroup';
import { withCRMState } from '../../context';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
import { sortBy } from 'lodash';

import './style.css';

class Visits extends Component {

    state = {
        today: {
            day: moment().date(),
            month: moment().month() + 1,
            year: moment().year()
        }
    }

    render() {

        const { subgroups, studentsBySubgroups, trainingDays } = this.props;
        const { today } = this.state;
        return <div className='visits-main'>
            <div className='visits-main_for_selfmenu'></div>
            <this.SelectedMonth />
            <this.TrainingDays />
            <div className='visits-main-subgroups'>
                {subgroups.map((subgroup, index) => {
                    const studentsBySubgroup = studentsBySubgroups[subgroup.id]

                    if (!subgroup.shadule || !Object.keys(subgroup.shadule).length ) {
                        return null;
                    }

                    const { settingsAsObj } = subgroup;
                    if ( settingsAsObj && settingsAsObj.notActive) return null;

                    return <Subgroup
                        key={'sbgr-' + index} index={index + 1}
                        today={today}
                        trainingDays={trainingDays}
                        subgroup={subgroup}
                        students={studentsBySubgroup}
                    />
                })}
            </div>
        </div>
    }

    SelectedMonth = () => {

        const { selectedMonth, selectedYear, monthShift } = this.props;

        return <div className='month-select'>
           <div className='month-select-arrow' onClick={() => { monthShift(-1)} }>◀</div> 
           <p>{moment(`1.${selectedMonth}.${selectedYear}`, `DD.MM.YYYY`).format('MMMM YYYY')}</p>
           <div className='month-select-arrow' onClick={() => { monthShift(1)} }>▶</div> 
        </div>

    }

    TrainingDays = () => {

        const { trainingDays, selectedMonth, selectedYear } = this.props;
        const { today } = this.state;

        let nowday = today.day;
        if (selectedYear > today.year) nowday -= 1000;
        if (selectedYear < today.year) nowday += 1000;

        if (selectedMonth > today.month) nowday -= 50;
        if (selectedMonth < today.month) nowday += 50;

        return <div className='visits-days'>
            {trainingDays.map((day) => {

                let className = 'visits-days-cell';
                if (Number(day) === nowday) {
                    className = 'visits-days-cell v-today';
                }
                if (Number(day) > nowday) {
                    className = 'visits-days-cell v-willbe';
                }
                return <div className={className}>
                    <p>{day}</p>
                </div>

            })}
        </div>

    }
}

export default withCRMState(
    `
        client,
    
        subgroups,
        studentsBySubgroups,

        shadule,
        students,

        selectedMonth,
        selectedYear,
        trainingDays,

        monthShift
    `
)(Visits)