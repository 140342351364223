import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { Tabs, Statistic, Icon, Progress } from 'antd';
import { ElementCard } from 'src/ver2/containers/challenges/components/element-card';
import PanteonPlashka from 'src/ver2/plashki/person/panteon';
import SeqMonitorPlashka from 'src/ver2/plashki/sequencesMonitor';
import Loader from 'src/ver2/components/loader';
// import Helmet from "react-helmet";

import './style.css';
const TabPane = Tabs.TabPane;

class StatisticsPage extends PureComponent {

    state = {
        statistics: null,
        personsKey: 'formado',
        activeKey: '3'
    };

    changeTab = (activeKey) => { this.setState({ activeKey }) }

    componentDidMount = async () => {
        const { client } = this.props;
        const statistics = await client.getStatistics('[ "au", "nu", "cd", "ae", "stat", "uel", "sel" ]');
        this.setState({ statistics });
    }

    render() {

        const { client, isMobile } = this.props;
        const { statistics, activeKey } = this.state;


        const width = isMobile ? isMobile.width : 1200;

        if (!statistics) return <div style={{ width }} className='statistics_page'>
            <Loader text='Загрузка статистика' />
        </div>

        return <div style={{ width }} className='statistics_page'>
            <Tabs style={{ width }} activeKey={activeKey} onChange={this.changeTab} className='statistics_page_tabs' tabPosition='top' defaultActiveKey={activeKey}>
                <TabPane tab={'Общая'} key="1">
                    <this.DefaultStat />
                </TabPane>
                <TabPane tab={'Социальная'} key="2">
                    <this.ScoresStat />
                </TabPane>
                <TabPane tab={'Техника'} key="3">
                    <this.TechStat />
                </TabPane>
            </Tabs>
        </div>
    }

    DefaultStat = () => {

        const { client, isMobile } = this.props;
        const { statistics } = this.state;

        const groupsCount = client.state.groups ? Object.keys(client.state.groups).length : 0;
        const schoolsCount = client.state.schools ? Object.keys(client.state.schools).length : 1;


        const { personsKey } = this.state;
        // console.log('statistics', {statistics});
        const {
            allUsersCount,
            newWeekUsersCount,
            cordaos,
            allEvents: { allEventsByType, allEventsCount }
        } = statistics;

        const { formado, monitors, instrutors, professors, mestres } = cordaos;

        return <div className='statistics_page_content'>

            <div className='statistics_page_header'>ОБЩАЯ</div>

            <div className='statistics_page_row'>
                <Statistic title="Пользователей" value={allUsersCount} prefix={<Icon type='user' />} />
                <Statistic
                    valueStyle={{ color: '#3f8600' }}
                    title="Новых за неделю" value={newWeekUsersCount} prefix={[<Icon type='user' />, <span> + </span>]} />

                <Statistic title="Регионов" value={11} />
                <Statistic title="Школ" value={schoolsCount} />
                <Statistic title="Групп" value={groupsCount} />
            </div>

            <div className='statistics_page_header'>СОСТАВ СТАРШИХ ПОЯСОВ</div>

            <div className='statistics_page_row statistics_page_row_nodown'>
                <div className={personsKey === 'formado' ? 'statistics_page_chooser_choose' : 'statistics_page_chooser'} onClick={() => { this.setState({ personsKey: 'formado' }) }}><Statistic title="Formados" value={formado.length} prefix={<Icon type='user' />} /></div>
                <div className={personsKey === 'monitors' ? 'statistics_page_chooser_choose' : 'statistics_page_chooser'} onClick={() => { this.setState({ personsKey: 'monitors' }) }}><Statistic title="Monitors" value={monitors.length} prefix={<Icon type='user' />} /></div>
                <div className={personsKey === 'instrutors' ? 'statistics_page_chooser_choose' : 'statistics_page_chooser'} onClick={() => { this.setState({ personsKey: 'instrutors' }) }}><Statistic title="Instrutors" value={instrutors.length} prefix={<Icon type='user' />} /></div>
                <div className={personsKey === 'professors' ? 'statistics_page_chooser_choose' : 'statistics_page_chooser'} onClick={() => { this.setState({ personsKey: 'professors' }) }}><Statistic title="Professors" value={professors.length} prefix={<Icon type='user' />} /></div>
                <div className={personsKey === 'mestres' ? 'statistics_page_chooser_choose' : 'statistics_page_chooser'} onClick={() => { this.setState({ personsKey: 'mestres' }) }}><Statistic title="CM/mestres" value={mestres.length} prefix={<Icon type='user' />} /></div>
            </div>
            <div className='statistics_page_row statistics_page_row_dark'>
                <PanteonPlashka upperStyle={{ width: '100%', height: 'fit-content' }} mode='high_rows' personsDirect={cordaos[personsKey]} itemHeight={isMobile ? 100 : 160} titleHeight={0} setComponentSelfHeight={() => { }} />
            </div>


            <div className='statistics_page_header'>МЕРОПРИЯТИЯ</div>
            <div className='statistics_page_row'>
                <Statistic title="Общее количество мероприятий" value={allEventsCount} />
            </div>
            <div className='statistics_page_row'>
                {allEventsByType.map(({ Type, Count }) => {
                    return <div className='statistics_page_progress'>
                        <Progress width={isMobile ? (isMobile.width / 7.2) : 120} type="circle" percent={(Count / allEventsCount * 100).toFixed()} format={() => Count} />
                        <div className='statistics_page_progress_down'>
                            {(client.eventsTypes && client.eventsTypes[Type]) ? client.eventsTypes[Type].name : ''}
                        </div>
                    </div>
                })}

            </div>

        </div>
    }

    ScoresStat = () => {

        const { client, isMobile } = this.props;
        const { statistics } = this.state;

        // console.log('statistics', {statistics});
        const {
            Scores
        } = statistics;


        return <div className='statistics_page_content'>
            {Object.keys(Scores).map((key) => {

                const persons = Scores[key];
                return <>
                    <div className='statistics_page_header'>{key}</div>
                    <div className='statistics_page_row statistics_page_row_dark'>
                        <PanteonPlashka upperStyle={{ width: '100%', height: 'fit-content' }} mode='high_rows_scores' personsDirect={persons} itemHeight={isMobile ? 120 : 160} titleHeight={0} setComponentSelfHeight={() => { }} />
                    </div>
                </>

            })}


        </div>
    }

    TechStat = () => {

        const { client, isMobile } = this.props;
        const { statistics } = this.state;

        const width = isMobile ? isMobile.width : 1200;

        // console.log('statistics', {statistics});
        const {
            Elements: { elementsCount },
            SequencesElements,
            SequencesUsers,
            TopSequences
        } = statistics;

        const sizes = {
            style: isMobile ? {
                width: 60,
                height: 60 * 1.4
            } : {
                width: 130,
                height: 130 * 1.4
            }
        }

        const SequencesElementsDesc = [...SequencesElements].reverse();

        let Sequences = [];
        let UsersUsed = {};
        let i = 0;
        while (Sequences.length < 10) {
            const { UserId } = TopSequences[i];
            if (!UsersUsed[UserId]) {
                Sequences.push(TopSequences[i]);
                UsersUsed[UserId] = 1;
            }
            i++;
        }

        return <div className='statistics_page_content'>
            <div className='statistics_page_header'>ПОЛЬЗОВАТЕЛИ</div>

            <div className='statistics_page_row'>
                <Statistic title="Добавили элементы" value={elementsCount} prefix={<Icon type='user' />} />
                <Statistic title="Заявили связки" value={SequencesUsers} prefix={<Icon type='user' />} />
                <Statistic title="Разнообразие эементов" value={SequencesElements.length} />
            </div>

            <div className='statistics_page_header'>ЭЛЕМЕНТЫ В СВЯЗКАХ</div>
            <div className='statistics_page_header'>САМЫЕ ЧАСТЫЕ</div>
            <div className='statistics_page_row statistics_page_row_elements'>
                {SequencesElements.map(({ element, count }, index) => {
                    if (index > 15) return null;
                    return <div className='statistics_page_row_element' key={`pepe-${element}`}>
                        <div className='statistics_page_row_element_count'>
                            <span><Icon type='user' /> {count} </span>
                        </div>
                        <ElementCard
                            isMobile={isMobile}
                            cover_id={'mega'}
                            is_mine={false}
                            is_login={false}
                            {...{ client, sizes, element_key: element }}
                        />
                    </div>
                }
                )}
            </div>

            <div className='statistics_page_header'>САМЫЕ РЕДКИЕ</div>
            <div className='statistics_page_row statistics_page_row_elements'>
                {SequencesElementsDesc.map(({ element, count }, index) => {
                    if (index > 15) return null;
                    return <div className='statistics_page_row_element' key={`pepe-${element}`}>
                        <div className='statistics_page_row_element_count'>
                            <span><Icon type='user' /> {count} </span>
                        </div>
                        <ElementCard
                            isMobile={isMobile}
                            cover_id={'mega'}
                            is_mine={false}
                            is_login={false}
                            {...{ client, sizes, element_key: element }}
                        />
                    </div>
                }
                )}
            </div>


            <div className='statistics_page_header'>ТОП-10 СВЯЗОК СПОРТСМЕНОВ</div>

            <SeqMonitorPlashka
                upperStyle={{ width, height: isMobile ? 450 : width * 0.52 }}
                directHeight = {isMobile ? isMobile.width * 720 / 1200 : null}
                sequences={Sequences}
                maxSequences = {10}
                maxWidth = {isMobile ? isMobile.width : 600}
                withName = {!isMobile}
                vertical = {isMobile}
            />
        </div>
    }

}


export default withCapoeiraSportState('iamuser, client, isMobile, groupsLoaded')(StatisticsPage);