// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input, message } from 'antd';

/**
 * 
 * Это компонент ISLAND 3 ввод кода
 */

class Quest335 extends PureComponent {
    state = {
        editText: ''
    }

    clickOnSend = () => {

        let editText = this.state.editText;
        if (!editText || editText === '') editText = '_';

        if (editText.length !== 5) { return message.warn('Введите 5 символов'); }

        editText = editText.toUpperCase();

        let data = {};

        for ( let i = 0; i < editText.length; i++ ) {
            let char = editText[i];
            while (data[char]) { char += '_'};
            data[char] = 1;
        }

        this.props.sendSpecial({ data })
    }

    onChange = (e) => {
        if (e.target.value.length >= 6) return;
        this.setState({ editText: e.target.value })
    }

    render() {
        const { isMobile, text } = this.props;
        const { editText } = this.state;
        const className = 'quest27_container'

        return <div className={className}>
            <Input value={editText} onChange={this.onChange} />
            <div className='quest20_container_send' onClick={this.clickOnSend}>
                <span>{ text || 'ВВЕСТИ'}</span>
            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest335);