import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

/**
 * Это враппер, который обвешивается вокруг компонента для полуучения exercise
 * он сначала пробует взять данные из client.state, если там нет - то подгружает
 * принимает exerciseId или ExerciseDirect (типа мы их откуда то подгрузили)
 */

class ExerciseWrapper extends PureComponent {
    state = {
        isExerciseLoaded: false,
        Exercise: null,
    };

    allLoad = ({ exerciseId }) => {

        const { client } = this.props;
        if (client.getExerciseById(exerciseId)) {
            return this.setState({
                isExerciseLoaded: true,
                Exercise: client.getExerciseById(exerciseId)
            })
        }

        client.comboConstructor({
            TypeData: 'exercises', ActionData: 'get', TargetId: exerciseId, Data: null, DataExercises: null
        }).then ( (result) => {

            if (!result) 
            return this.setState({
                isExerciseLoaded: true,
                Exercise: null
            })   

            if (result[0]) {
                client.setExercise( result[0] );
                return this.setState({
                    isExerciseLoaded: true,
                    Exercise: client.getExerciseById(exerciseId)
                })
            } else {
                return this.setState({
                    isExerciseLoaded: false,
                    Exercise: null
                })   
            }
        })

    }

    componentDidMount() {
        const { ExerciseDirect, client } = this.props;

        if (ExerciseDirect && ExerciseDirect.Id && ExerciseDirect.Id > 0) {
            client.setExercise( ExerciseDirect );
            return this.setState({
                isExerciseLoaded: true,
                Exercise: ExerciseDirect
            })
        }

        this.allLoad(this.props);
    }

    componentWillReceiveProps({ exerciseId }) {
        if ((exerciseId !== this.props.exerciseId)
        ) {
            this.allLoad({ exerciseId });
        }
    }

    render() {
        const {
            props: {
                Wrapped,
                componentProps
            },
            state: {
                Exercise,
                isExerciseLoaded
            }
        } = this;

        return (
            <Wrapped {...{ Exercise, isExerciseLoaded, ...componentProps }} />
        );
    }
}


export default ExerciseWrapper;
// export default withCapoeiraSportState('client')(ExerciseWrapper);