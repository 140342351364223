
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { ItemTypeComponent, ItemSubtypeComponent } from '../types';
import MoneyComponent from 'src/ver2/components/money';

import './styles.css';
import { Tooltip, Modal } from 'antd';

/* 

    // заводим сюда
    width (это всегда квадрат)
    name 
    uid (чтобы дать индивидуальность)

    withType (иконка снизу)
    withSubtype (ионнка снизу)

*/

class ItemShopComponent extends PureComponent {

    state = {
        butItemId: null
    }

    setButItemId = (butItemId = false) => this.setState({ butItemId });

    mayIBuyIt = (CostObj) => {

        if (!this.props.iamuser) return false;

        const { iamuser: { myResourses } } = this.props;
        const { Money } = myResourses;

        const CostObjKeys = Object.keys(CostObj);

        if (!Money || Object.keys(Money).length === 0) return false;


        for (let i = 0; i < CostObjKeys.length; i++) {
            const moneyKey = CostObjKeys[i];
            if (!Money[moneyKey]) return false;
            const amount = Money[moneyKey].all;
            if (amount < CostObj[moneyKey]) return false;
        }
        return true;
    }

    buyItem = () => {
        this.setButItemId();
        const { butItemId } = this.state;
        const { buyShopItems } = this.props;
        buyShopItems(butItemId);
    }

    render() {

        const { width, client, ShopItem, iamuser } = this.props;

        const { butItemId } = this.state;

        const style = {
            width,
            height: width * 1.6
        }

        const {
            Id,
            Name,
            Discription,
            Cost,
            TimeOut,
            IsOff,
            Count
        } = ShopItem

        const item = client.getAbstractItem(Name);
        const { src, srcType, title, discription } = item;

        const SubtypeComponent = ItemSubtypeComponent({ itemName: Name }) ?
            <div className='item_shop_components_subtype'>
                <ItemSubtypeComponent itemName={Name} />
            </div>
            : null;

        const TimeOutComponent = TimeOut ?
            <Tooltip title={'Скрок действия предмета, после чего он пропадёт'}>
                <div className='item_shop_components_timeout'>
                    {`${TimeOut} Д.`}
                </div>
            </Tooltip>
            : null;

        const CostObj = JSON.parse(Cost);
        const CostObjKeys = Object.keys(CostObj);

        let CostArrya = CostObjKeys.map(moneyKey => <MoneyComponent size={25} type={moneyKey} amount={CostObj[moneyKey]} />)
        let CostArryaModal = CostObjKeys.map(moneyKey => <MoneyComponent size={65} type={moneyKey} amount={CostObj[moneyKey]} />)

        let BuyButton = null;

        if (IsOff) {
            BuyButton = <Tooltip title={'Данный предмет не подлежит продаже в данный момент'}>
                <div className='item_shop_components_tobuy item_shop_components_cannotbuy'>
                    <p>НЕТ</p>
                </div>
            </Tooltip>
        } else
            if (!iamuser) {
                BuyButton = <Tooltip title={'Авторизируйтесь для возможности покупки'}>
                    <div className='item_shop_components_tobuy item_shop_components_cannotbuy'>
                        <p>КУПИТЬ</p>
                    </div>
                </Tooltip>
            } else {

                // проверяем, можем ли мы купить
                if (!this.mayIBuyIt(CostObj)) {
                    BuyButton = <Tooltip title={'Нет необходимых ресурсов'}>
                        <div className='item_shop_components_tobuy item_shop_components_cannotbuy'>
                            <p>КУПИТЬ</p>
                        </div>
                    </Tooltip>
                } else
                    BuyButton = <div onClick={() => this.setButItemId(Id)} className='item_shop_components_tobuy'>
                        <p>КУПИТЬ</p>
                    </div>
            }

        return <div style={style} className='item_shop_components'>
            <Modal
                visible={butItemId}
                onCancel={() => this.setButItemId()}
                footer={null}
                title={`Купить предмет ${title}?`}
            >
                {butItemId ?
                    <div className='item_shop_components_modal'>
                        <Tooltip title={discription || Discription || ''}>
                            <img className='item_shop_components_modal_img' src={src} />
                        </Tooltip>
                        <div className='item_shop_components_modal_right'>
                            <div className='item_shop_components_modal_cost'>
                                {CostArryaModal}
                            </div>
                            <div className='item_shop_components_modal_timeout'>
                                {TimeOut ? `Срок действия предмета в днях: ${TimeOut}` : `Предмет приобретается навсегда`}
                                {Count ? [<br />, <br />, `Можно приобрести не более: ${Count}`] : ``}
                            </div>
                            <div onClick={this.buyItem} className='item_shop_components_modal_tobuy'>
                                <p>ДА</p>
                            </div>
                        </div>
                    </div>
                    : null}
            </Modal>
            <Tooltip title={discription || Discription || ''}>
                <img onClick={() => this.props.showInfoModal({ type: 'item', Item: { ...item, name: Name } })} src={src} />
            </Tooltip>
            <div className='item_shop_components_type'>
                <ItemTypeComponent itemName={Name} />
            </div>
            {SubtypeComponent}
            {TimeOutComponent}
            <div className='item_shop_components_title'>
                <p>{title}</p>
            </div>
            {BuyButton}
            <div className='item_shop_components_cost'>
                {CostArrya}
            </div>
        </div>
    }

}

export default withCapoeiraSportState('client, iamuser, showInfoModal')(ItemShopComponent);