// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { gsap } from 'gsap';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest222 extends PureComponent {
    state = {
        answer: [
            1,
            1,
            1,
            1,
            1,
            1,
        ]
    }

    send = () => {
        const { answer } = this.state;
        let answerString = answer.reduce( ( memo, value) => memo + value.toString(), '');
        this.props.sendSpecial({ data: { [answerString]: 1 } })
    }



    render() {
        const className = 'quest222_container'

        return <div className={className}>
            <div className='quest222_tables'>
                <this.Table index={0} />
                <this.Table index={1} />
                <this.Table index={2} />
                <this.Table index={3} />
                <this.Table index={4} />
                <this.Table index={5} />
            </div>
            <div className='quest20_container_send' onClick={() => { this.send() }}><span>ОТВЕТ</span></div>
        </div>
    }

    Table = ({ index }) => {

        const { isMobile } = this.props;
        const width = isMobile ? isMobile.width / 6.5 : 500 / 6.5;
        const height = width;
        const style = {
            width,
            height
        }

        const onClick = () => {
            let answer = [...this.state.answer];
            answer[index] = (answer[index] >= 6) ? 1 : answer[index] + 1;
            gsap.fromTo(`.quest222_table_${index}`, {rotate: 0}, { rotate: 360, duration: 1});
            this.setState({ answer });
        }

        const { answer } = this.state;

        return <div onClick={onClick} style={style} className={`quest222_table quest222_table_${index} quest222_${answer[index]}`} />
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest222);