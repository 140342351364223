// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import { groupBy } from 'lodash';
import { withCapoeiraSportState } from 'src/ver2/context';
import OperationsModal from 'src/ver2/pages/person/plashki/dataPlashka/modal';
import './style.css';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest27 extends PureComponent {
    state = {
        teamresults: null,
        status: 'loading',
        operationsModalShow: false
    }

    loadResults = () => {
        const { client, IslandId } = this.props;
        this.setState({ status: 'loading' })
        client.islandGetTeamResults({ IslandId, Money: 'is2' }).then((result) => {
            this.setState({ teamresults: groupBy(result, 'TeamId'), status: 'loaded' })
        })
    }

    loadMyResults = () => {
        const { renewResourses } = this.props;
        renewResourses();
    }

    componentDidMount() {
        this.loadResults();
        this.loadMyResults();
    }

    closeOperationModal = () => {
        this.setState({ operationsModalShow: false })
    }
    openOperationModal = () => {
        this.setState({ operationsModalShow: true })
    }

    render() {
        const { isMobile, iamuser } = this.props;
        const { operationsModalShow } = this.state;
        const className = 'quest27_container';

        const Person = { Id: iamuser ? iamuser.Id : 0 }
        const operationModalData = 'is2';
        const { closeOperationModal } = this;



        return <div className={className}>
            <this.HowerGlasses />
            <this.YourTeamAndResult />
            {(operationsModalShow && iamuser) && <OperationsModal {...{ Person, operationModalData, closeOperationModal }} />}
        </div>
    }

    HowerGlasses = () => {

        const { teamresults } = this.state;
        if (!teamresults) return <div className='results89_howerglasses'></div>
        let teamresultsShow = {};

        teamresultsShow[1] = teamresults[1] ? teamresults[1][0].Amount : 0;
        teamresultsShow[2] = teamresults[2] ? teamresults[2][0].Amount : 0;
        teamresultsShow[3] = teamresults[3] ? teamresults[3][0].Amount : 0;
        teamresultsShow[4] = teamresults[4] ? teamresults[4][0].Amount : 0;

        const maxAmount = Math.max(teamresultsShow[1], teamresultsShow[2], teamresultsShow[3], teamresultsShow[4]);

        const column = (index) => {
            let className = 'results89_howerglasses_column ';
            className += ` results89_howerglasses_column${index}`;

            const amount = teamresultsShow[index];
            const colHeight = amount ? (amount / maxAmount) : 0.1;

            return <div style={{ height: `${colHeight * 100}%` }} className={className}>
                {amount}
            </div>
        }

        return <div className='results89_howerglasses'>
            {column(1)}
            {column(2)}
            {column(3)}
            {column(4)}
        </div>
    }

    YourTeamAndResult = () => {

        const { MyTeamId, iamuser } = this.props;

        if (!iamuser) return <div className='results89_teamandresult'>
        </div>

        console.log('YourTeamAndResult', iamuser);
        const amount = (iamuser.myResourses && iamuser.myResourses.Money && iamuser.myResourses.Money.is2) ? iamuser.myResourses.Money.is2.all : 0;

        let text = 'У Вас нет команды';
        if (MyTeamId === 1) text = 'Вы в красной команде';
        if (MyTeamId === 2) text = 'Вы в синей команде';
        if (MyTeamId === 3) text = 'Вы в зелёной команде';
        if (MyTeamId === 4) text = 'Вы в фиолетовой команде';

        return <div className='results89_teamandresult' onClick={this.openOperationModal}>
            <div className='results89_teamandresult_row'>
                {`Вы заработали ${amount} ★`}
            </div>
            <div className='results89_teamandresult_row'>
                {text}
            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile, renewResourses')(Quest27);