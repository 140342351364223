import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import Loader from 'src/ver2/components/loader';
import AddOperation from 'src/ver2/containers/addOperation';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import LittleOperation from 'src/ver2/components/operations/little';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventContactsPlashka
// classname: event_operations_component

/**
  Это плашка в которой мы отображаем операции 
 */

class EventOperations extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
    }

    render() {

        const { isOperationsReady, Operations, ParticipatorsData, Event, iamuser } = this.props;

        if (!isOperationsReady) return <div className='event_operations_component'>
            <Loader text='загрузка операций' />
        </div>

        let presenseCount = 0;
        if (ParticipatorsData && Object.keys(ParticipatorsData).length > 0) {
            presenseCount = Object.keys(ParticipatorsData).reduce((memo, userId) => {
                if (ParticipatorsData[userId].length && ParticipatorsData[userId].find(x => x.Type === "presense")) {
                    return memo + 1;
                }
                return memo;
            }, 0)
        }


        const width = 700;

        const releaseOperation = (Code) => {
            this.props.releaseOperation(Code);
        }

        const onAddOperation = (Data) => {
            this.props.addOperation(Data)
        }

        let resourses = null;
        let operationsTypes = 'usual';

        if (Event && Event.Id === 127) {
            resourses = {
                scores: {
                    social: 0,
                },
                money: {
                    bra: 0
                }
            }
        }
        if (Event && Event.Id === 219) {
            resourses = {
                scores: {
                    social: 0,
                    challenge: 0,
                },
                money: {
                    spw: 0,
                    tri: 0,
                    squ: 0
                }
            }
        }

        const { Type } = Event;

        if (Type === 'championship') {
            operationsTypes = 'championship';
        }

        return <div className='event_operations_component'>
            {(Operations && Operations.length) ? Operations.map((operation) => {

                const { Status, Code } = operation;

                return <div key={operation.Id} className='event_operations_component_onerow'>
                    <LittleOperation Operation={operation} withPersons={true} />
                    {Status === 'ready' && <ButtonPlashka
                        upperStyle={{ width: 150, height: 35 }}
                        text={'ПРИНЯТЬ'}
                        onClick={() => { releaseOperation(Code) }}
                    />}
                </div>
            })
                :
                <div> Нет операций </div>}

            <br />
            <br />
            {(iamuser && iamuser.myPortalRoles && iamuser.myPortalRoles.creator) &&
                <AddOperation presenseCount={presenseCount} onAddOperation={onAddOperation} resourses={resourses} operationsTypes={operationsTypes} />
            }

        </div>
    }

}


export default withEventState('Event, iamuser, isOperationsReady, Operations, releaseOperation, ParticipatorsData, addOperation')(EventOperations)