import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import PersonWrapper from 'src/ver2/components/person/personWrapper.js';
import MoneyComponent from 'src/ver2/components/money';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class ShopPersonMoneyPlashka
// classname: shop_person_money_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopPersonMoneyPlashka extends PureComponent {

    render() {

        const { Shop, isShopMoneyReady, ShopMoney, upperStyle, iamuser: { myResourses } } = this.props;
        const { width, height } = upperStyle;

        if (!Shop || !isShopMoneyReady || !myResourses) return <div style={{ width, height }} className='shop_person_money_plashka'>
            <Loader />
        </div>

        let Answer = [];

        const { Money } = myResourses;

        if (Money && Object.keys(Money).length > 0) {

            const moneyKeys = Object.keys(Money);
            Answer = moneyKeys.map((moneyKey) => {

                const isInShop = ShopMoney.find( x => x === moneyKey);
                if (!isInShop) return null;

                const amount = Money[moneyKey].all;
                return <MoneyComponent size={35} type={moneyKey} amount={amount} />
            })
        }

        return <div style={{ width, height }} className='shop_person_money_plashka'>
            <div className='shop_person_money_plashka_discription'>
                {Answer}
            </div>
        </div>
    }

}

export default withShopState(`
Shop,  
isShopMoneyReady, ShopMoney,
iamuser,
client
`)(ShopPersonMoneyPlashka);