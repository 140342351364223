import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventAdmissionPlashka
// classname: event_admission_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventAdmissionPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false
        }
    }

    setHeight = () => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const element = document.getElementById('event_admission_plashka_id');
        if (!element) return;
        if (this.state.heightSetted === element.clientHeight) return;
        this.setState({ heightSetted: element.clientHeight });
        setComponentSelfHeight({ height: element.clientHeight + 50, plashkaId });
    }

    componentDidMount() {
        this.setHeight();
    }

    componentDidUpdate() {
        this.setHeight();
    }

    render() {

        const { Event, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_admission_plashka'>
            <Loader />
        </div>
        
        const { SettingsObj } = Event;
        const { AdmissionMain } = SettingsObj;


        let RightPart = [];
        if (AdmissionMain && AdmissionMain.AdmissionData && AdmissionMain.AdmissionData.length) {
            // добавляем остальных штук
            for (let i = 0; i < AdmissionMain.AdmissionData.length; i++) {
                const { key, title, text } = AdmissionMain.AdmissionData[i];
                if ( key === 'presense') continue;
                RightPart.push(
                    <this.AdmissionBlock key={key} itemKey={key} title={title} text={text} />
                )
            }
        }

        return <div style={{ width, height }} className='event_admission_plashka'>
            <div id='event_admission_plashka_id'>
                {RightPart.length ?  
                    RightPart
                    : <span>Нет требований для допуска</span>}
            </div>
        </div>
    }

    AdmissionBlock = ({ itemKey, title, text }) => {
        if (text) {
            return <div className='event_admission_plashka_maindata_admission'>
            <span className = 'global-tooltip' data-tooltip = {text}>
                {title}
            </span>
    </div>
        }

        return <div className='event_admission_plashka_maindata_admission'>
                <span>
                    {title}
                </span>
        </div>
    }

}

export default withEventState(`

Event,

Categorys, 
Results,
isResultsReady,

client,
iamuser,
`)(EventAdmissionPlashka)