import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import AddItemArrival from '../../containers/addItemArrival';
import ChangeOrderStatus from '../../containers/changeOrderStatus';

import LittlePerson from 'src/ver2/components/person/little';
import { Button, Tabs, Modal, Checkbox } from 'antd'

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

import './style.css';

// class RealShopAdminPlashka
// classname: realshop_admin_plashki

/**
  Это просто типовая плашка, копировать отсюда
 */

const TabPane = Tabs.TabPane;

class RealShopAdminPlashka extends PureComponent {

    state = {
        activeKey: '1',
        checkBoxOnlyInWork: true,
        checkBoxOnlyInBatizado: false,
        RealArticuleIdChoosed: null,
        RealOrderIdStatusChoosed: null,

        BatizadoUsers: []
    }

    changeShowAddArrivalModal = (RealArticuleIdChoosed) => { this.setState({ RealArticuleIdChoosed }) }
    changeRealOrderStatusModal = (RealOrderIdStatusChoosed) => { this.setState({ RealOrderIdStatusChoosed }) }
    changeCheckBoxOnlyInWork = (e) => {
        this.setState({ checkBoxOnlyInWork: e.target.checked })
    }
    changeCheckBoxOnlyInBatizado = (e) => {
        this.setState({ checkBoxOnlyInBatizado: e.target.checked })
    }

    componentDidUpdate = ({ }, { checkBoxOnlyInBatizado }) => {
        const LoadBU = async () => {
            const { client } = this.props;
            let BatizadoUsers = [];
            // const Participators254 = await client.getParticipators({ EventId: 254 });
            // const Participators290 = await client.getParticipators({ EventId: 290 });
            const Participators298 = await client.getParticipators({ EventId: 298 });
            const Participators300 = await client.getCompetitors( 131 );

            BatizadoUsers = [...Participators298, ...Participators300];
            this.setState({ BatizadoUsers })

        }
        if (!checkBoxOnlyInBatizado && this.state.checkBoxOnlyInBatizado && !this.state.BatizadoUsers.length) {
            LoadBU();
        }
    }



    render() {

        const {
            upperStyle,
            isShopItemsReady,
            client,
            innerId
        } = this.props;

        const {
            activeKey,
            RealArticuleIdChoosed,
            RealOrderIdStatusChoosed
        } = this.state;

        const { changeShowAddArrivalModal, changeRealOrderStatusModal } = this;

        const { width, height } = upperStyle;

        if (!isShopItemsReady) return <div style={{ width, height }} className='realshop_admin_plashki'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='realshop_admin_plashki'>
            <div className='exercise_title_plashka_inside' id={innerId}>
                <Tabs
                    activeKey={activeKey}
                    style={{ color: 'black' }}
                    defaultActiveKey={1}
                    onTabClick={(key) => { this.setState({ activeKey: key }) }}

                >

                    <TabPane tab={<span>Заказы</span>} key="1">
                        <this.Orders />
                    </TabPane>
                    <TabPane tab={<span>Таблица</span>} key="4">
                        <this.OrdersTable />
                    </TabPane>
                    <TabPane tab={<span>Наличие</span>} key="2">
                        <this.Tovars />
                    </TabPane>
                    <TabPane tab={<span>Приходы</span>} key="3">
                        <this.Arrivals />
                    </TabPane>
                </Tabs>
                <Modal
                    className='adminModalClass2'
                    visible={RealArticuleIdChoosed}
                    onCancel={() => { changeShowAddArrivalModal(null) }}
                    footer={null}
                    title={'Поступление артикула'}
                    width={600}
                >
                    {RealArticuleIdChoosed ? <AddItemArrival RealArticuleId={RealArticuleIdChoosed} closeModal={() => { changeShowAddArrivalModal(null) }} /> : null}
                </Modal>
                <Modal
                    className='adminModalClass2'
                    visible={RealOrderIdStatusChoosed}
                    onCancel={() => { changeRealOrderStatusModal(null) }}
                    footer={null}
                    title={'Изменение статуса'}
                    width={600}
                >
                    {RealOrderIdStatusChoosed ? <ChangeOrderStatus RealOrderId={RealOrderIdStatusChoosed} closeModal={() => { changeRealOrderStatusModal(null) }} /> : null}
                </Modal>
            </div>
        </div>

        // return <div style={{ width, height }} className='realshop_admin_plashki'>
        //     Нет покупателей
        // </div>
    }

    Tovars = () => {

        const {
            RealShopItems = [],
            RealShopArticulesByItems,
            changeAddItemModalModal
        } = this.props;

        return <div className='realshop_admin_plashki_main'>
            {RealShopItems.map(({ Id, Title }) => {
                return <div className='realshop_admin_plashki_tovars_row' key={`raptr_${Id}`}>
                    <div className='realshop_admin_plashki_tovars_row_title'>
                        {Title}
                    </div>
                    {RealShopArticulesByItems[Id].map(({ Name, Count, Id }) => {
                        let className = 'realshop_admin_plashki_tovars_row_articule';
                        if (!Count || Count < 1) className += ' realshop_admin_plashki_tovars_row_articule_not';

                        return <div className={className} onClick={() => { this.changeShowAddArrivalModal(Id) }}>
                            {`${Name} : ${Count} шт.`}
                        </div>
                    })}
                </div>
            })}
            <Button onClick={changeAddItemModalModal}>ДОБАВИТЬ ТОВАР</Button>
        </div>
    }

    Arrivals = () => {
        const {
            RealShopItems = [],
            RealShopArticules,
            RealShopArrivals = [],
        } = this.props;

        const RealShopArrivals_ = [...RealShopArrivals].reverse();

        return <div className='realshop_admin_plashki_main'>
            {RealShopArrivals_.map(({ RealArticuleId, Count, Id, RealItemId, TimeStamp }) => {

                const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
                const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
                const {
                    Name
                } = RealArticule;

                return <div className='realshop_admin_plashki_tovars_row' key={`raptr_${Id}`}>
                    <div className='realshop_admin_plashki_tovars_row_title'>
                        {RealItem.Title}
                    </div>
                    <div className='realshop_admin_plashki_tovars_row_title'>
                        {Name}
                    </div>
                    <div className='realshop_admin_plashki_tovars_row_title'>
                        {Count}
                    </div>
                    <div className='realshop_admin_plashki_tovars_row_timestamp'>
                        {moment(TimeStamp).format('DD.MM.YYYY')}
                    </div>
                </div>
            })}
        </div>
    }

    calculateSales = (dataObj = {}) => {
        // мы берём заказ, он нужен нам как список { RealItemId: Count }

        const { Shop } = this.props;
        if (!Shop || !Shop.SettingsSales || !Shop.SettingsSales.length) return;

        let minusCost = 0;

        for (let i = 0; i < Shop.SettingsSales.length; i++) {
            const { Ids, Minus } = Shop.SettingsSales[i];
            let someSaleMaxCount = 999;
            for (let j = 0; j < Ids.length; j++) {
                const neededId = Ids[j];
                if (dataObj[neededId]) {
                    someSaleMaxCount = Math.min(someSaleMaxCount, dataObj[neededId]);
                } else { someSaleMaxCount = 0; continue; }
            }

            if (someSaleMaxCount > 0) {
                minusCost += Minus * someSaleMaxCount;
            }
        }

        return minusCost;
    }

    Orders = () => {
        const {
            RealShopOrders,
            RealShopItems = [],
            RealShopArticules,
            statusData,
            isMobile
        } = this.props;

        if (!RealShopOrders || !RealShopOrders.length) {
            return <div>Нет заказов</div>
        }

        let Answer = [];

        for (let i = 0; i < RealShopOrders.length; i++) {
            const { Payment, UserId, Status, TimeStamp, Orders, Code, HighId } = RealShopOrders[i];

            // let FinalCost = 0;
            // let realOrderWithIds = {};
            // for (let j = 0; j < Orders.length; j++) {
            //     const { RealArticuleId, Count } = Orders[j];
            //     const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
            //     const {
            //         RealItemId
            //     } = RealArticule;
            //     const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
            //     const { Cost } = RealItem;
            //     FinalCost += Cost * Count;
            //     realOrderWithIds[RealItemId] = realOrderWithIds[RealItemId] || 0;
            //     realOrderWithIds[RealItemId] += Count;
            // }

            // const sale = this.calculateSales(realOrderWithIds);
            // FinalCost -= sale;

            let paymentStyle = {};
            let paymentValue = 'не оплачен';

            if (Payment !== null) {
                paymentStyle = { background: 'rgb(28, 117, 28)', color: 'white' };
                paymentValue = Payment;
            }

            Answer.push(
                <div className='realshop_myorders_plashki_main_lastorders_oneorder' key={`ork_${Code}`}>
                    <div className='realshop_admin_plashki_tovars_user'>
                        <LittlePerson personId={UserId} height={50} mode='contact' />
                    </div>
                    <div className='realshop_myorders_plashki_main_lastorders_title'>
                        <span>
                            {`${TimeStamp} / Заказ ${Code}`}
                        </span>
                        <div
                            onClick={() => { this.changeRealOrderStatusModal(HighId) }}
                            style={statusData(Status).style}
                            className='realshop_admin_plashki_tovars_row_lowtitle realshop_admin_plashki_tovars_row_lowtitle_status'>
                            {statusData(Status).title}
                        </div>
                        <div style={paymentStyle} className='realshop_admin_plashki_tovars_row_lowtitle realshop_admin_plashki_tovars_row_lowtitle_status '>
                            {paymentValue}
                        </div>
                    </div>
                    <div className='realshop_myorders_plashki_tovars_row realshop_myorders_plashki_tovars_row_header'>
                        <div className='realshop_myorders_plashki_tovars_row_title'>
                            Товар
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            Артикул
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            {isMobile ? 'Кол-во' : 'Количество'}
                        </div>
                        <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                            Цена
                        </div>
                    </div>
                    <div className='realshop_myorders_plashki_main_lastorders_orders'>
                        {Orders.map(({ RealArticuleId, Count, Id, RealItemId }) => {
                            const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
                            const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
                            const {
                                Name
                            } = RealArticule;
                            return <div className='realshop_myorders_plashki_tovars_row' key={`raptrrr_${Id}`}>
                                <div className='realshop_myorders_plashki_tovars_row_title'>
                                    {RealItem.Title}
                                </div>
                                <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                                    {Name}
                                </div>
                                <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                                    {`x${Count}`}
                                </div>
                                <div className='realshop_myorders_plashki_tovars_row_lowtitle'>
                                    {`${Count * RealItem.Cost}₽`}
                                </div>
                            </div>
                        })}
                    </div>
                </div >
            )
        }

        return <div className='realshop_realadmin_orders'>
            {Answer}
        </div>

        /*
                const RealShopOrders_ = [...RealShopOrders].reverse();
        
                return <div className='realshop_admin_plashki_main'>
                    {RealShopOrders_.map(({ RealArticuleId, UserId, Count, Id, RealItemId, Status, CreationStamp, Payment }) => {
        
                        const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
                        const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
                        const {
                            Name
                        } = RealArticule;
        
                        let paymentStyle = {};
                        let paymentValue = 'не оплачен';
        
                        if (Payment !== null) {
                            paymentStyle = { background: 'rgb(28, 117, 28)', color: 'white' };
                            paymentValue = Payment;
                        }
        
                        return <div className='realshop_admin_plashki_tovars_row' key={`raptrrr_${Id}`}>
                            <div className='realshop_admin_plashki_tovars_user'>
                                <LittlePerson personId={UserId} height={30} />
                            </div>
                            <div className='realshop_admin_plashki_tovars_row_title'>
                                {RealItem.Title}
                            </div>
                            <div className='realshop_admin_plashki_tovars_row_lowtitle'>
                                {Name}
                            </div>
                            <div className='realshop_admin_plashki_tovars_row_lowtitle'>
                                {`x${Count}`}
                            </div>
                            <div
                                onClick={() => { this.changeRealOrderStatusModal(Id) }}
                                style={statusData(Status).style}
                                className='realshop_admin_plashki_tovars_row_lowtitle realshop_admin_plashki_tovars_row_lowtitle_status'>
                                {statusData(Status).title}
                            </div>
                            <div style={paymentStyle} className='realshop_admin_plashki_tovars_row_lowtitle realshop_admin_plashki_tovars_row_lowtitle_status '>
                                {paymentValue}
                            </div>
                            <div className='realshop_admin_plashki_tovars_row_timestamp'>
                                {moment(CreationStamp).format('DD.MM.YYYY')}
                            </div>
                        </div>
                    })}
                </div>*/
    }

    OrdersTable = () => {
        const {
            RealShopOrders: RealShopOrdersInner,
            RealShopItems = [],
            RealShopArticules,
            statusData,
            client,
            isMobile
        } = this.props;

        if (!RealShopOrdersInner || !RealShopOrdersInner.length) {
            return <div>Нет заказов</div>
        }

        let Answer = [];
        const RealShopOrders = [...RealShopOrdersInner].reverse();

        const { checkBoxOnlyInWork, checkBoxOnlyInBatizado, BatizadoUsers } = this.state;
        const { changeCheckBoxOnlyInWork, changeCheckBoxOnlyInBatizado } = this;

        for (let i = 0; i < RealShopOrders.length; i++) {
            const { Payment, UserId, Status, TimeStamp, Orders, Code, HighId, UserName, UserSurname, Tel, GroupIamInId } = RealShopOrders[i];
            let paymentStyle = {};
            let paymentValue = 'не оплачен';

            if (checkBoxOnlyInWork && Status !== 3 && Status !== 4) continue;
            if (checkBoxOnlyInBatizado && BatizadoUsers && BatizadoUsers.length) {
                if (!BatizadoUsers.find(x => x.UserId === UserId)) continue;
            }

            if (Payment !== null) {
                paymentStyle = { background: 'rgb(28, 117, 28)', color: 'white' };
                paymentValue = Payment;
            }

            for (let j = 0; j < Orders.length; j++) {
                const { RealArticuleId, Count, Id, RealItemId } = Orders[j];
                const RealItem = RealShopItems.find(x => x.Id === RealItemId) || {};
                const RealArticule = RealShopArticules.find(x => x.Id === RealArticuleId) || {};
                const {
                    Name
                } = RealArticule;
                const {
                    Title
                } = RealItem;

                const Status = (Id === HighId) ? RealShopOrders[i].Status : null;

                const Diff = moment().diff(moment(TimeStamp, 'DD.MM.YYYY HH:mm'), 'days');

                Answer.push({
                    UserName, UserSurname, GroupIamInId, Tel, Name, Title, Status, TimeStamp, HighId, isFirst: Id === HighId, Diff
                })

            }
        }

        return <div className='realshop_realadmin_orders'>
            <Checkbox checked={checkBoxOnlyInWork} onChange={changeCheckBoxOnlyInWork}> Только в работе </Checkbox>
            <Checkbox checked={checkBoxOnlyInBatizado} onChange={changeCheckBoxOnlyInBatizado}> Только на батизаде </Checkbox>
            <table className='realshop_realadmin_orders_table'>
                {Answer.map((item) => {
                    const {
                        UserName, UserSurname, Tel, Name, Title, HighId, Status, TimeStamp, isFirst, Diff, GroupIamInId
                    } = item;

                    const group = (GroupIamInId && client.getGroupById(GroupIamInId)) || {}
                    const groupTitle = group ? group.Name : GroupIamInId;
                    console.log (group)

                    const trClassName = isFirst ? 'realshop_realadmin_orders_table_tr_top' : '';
                    let classNameDiff = '';
                    if (Diff > 20) classNameDiff = 'realshop_realadmin_orders_table_tel_diff_20';
                    if (Diff > 30) classNameDiff = 'realshop_realadmin_orders_table_tel_diff_30';
                    if (Diff > 40) classNameDiff = 'realshop_realadmin_orders_table_tel_diff_40';

                    return <tr className={trClassName}>
                        <td className='realshop_realadmin_orders_table_name'>{`${UserSurname} ${UserName}`}</td>
                        <td className='realshop_realadmin_orders_table_group'>{`${groupTitle}`}</td>
                        <td className='realshop_realadmin_orders_table_tel' title={Tel}> {(isFirst && Tel) && 'Тел'}</td>
                        <td className='realshop_realadmin_orders_table_tel_timestamp'>{isFirst && moment(TimeStamp, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY')}</td>
                        <td className={'realshop_realadmin_orders_table_tel_diff ' + classNameDiff}>
                            {(isFirst && (Status === 3 || Status === 4)) && `дней: ${Diff}`}
                        </td>
                        <td className='realshop_realadmin_orders_table_tel_title'>{Title}</td>
                        <td className='realshop_realadmin_orders_table_tel_size'>{Name}</td>
                        <td className='realshop_realadmin_orders_table_tel_status'>
                            {(Status) && <div
                                onClick={() => { this.changeRealOrderStatusModal(HighId) }}
                                style={statusData(Status).style}
                                className='realshop_admin_plashki_tovars_row_lowtitle realshop_admin_plashki_tovars_row_lowtitle_status'>
                                {statusData(Status).title}
                            </div>}
                        </td>
                    </tr>
                })}
            </table>
        </div>
    }

}

export default withShopState(`

isShopItemsReady,
RealShopOrders,  
RealShopItems,
RealShopArticules,
RealShopArticulesByItems,
RealShopArrivals,

statusData,

isMobile,
iamuser,
client
`)(RealShopAdminPlashka);