import React, { Component } from 'react';
//import logo from './logo.svg';

import { withCRMState } from '../../context';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
import { sortBy } from 'lodash';
import { Collapse, Radio, Modal, message } from 'antd';
import { Student } from '../../components/student';
import { DISCIPLINE_SMILES } from '../../components/disciplineSmiles';
import PaymentModal from '../../components/addPaymentModal';
import PersonFastRegistration from 'src/ver2/containers/personFastRegistration';
import './style.css';

const Panel = Collapse.Panel;

class VisitsMobile extends Component {

    state = {
        today: {
            day: moment().date(),
            month: moment().month() + 1,
            year: moment().year()
        },
        selectedDay: moment().date(),
        studentToAddSbId: null
    }

    onCountClick = (e) => {
        e.stopPropagation();
    }

    onAddPerson = async (newperson) => {

        const { moveToNewSb, renewStudents } = this.props;

        if (!newperson || !newperson.UserId) {
            message.error('Ошибка сервера')
        }
        message.success('Ученик добавлен')

        await renewStudents();
        await moveToNewSb({ personId: newperson.UserId, subgroupFromId: 0, subgroupToId: this.state.studentToAddSbId })

        this.setState({
            studentToAddSbId: null
        })
    }

    setStudentToAddSbId = (studentToAddSbId) => {
        this.setState({ studentToAddSbId })
    }

    render() {

        // const { subgroups, studentsBySubgroups, trainingDays } = this.props;
        // const { today } = this.state;
        const { subgroups, visits, selectedMonth, selectedYear, iamuser, groupId } = this.props;
        const { selectedDay, studentToPayment, studentToAddSbId } = this.state;

        let defaultActiveKey = null;
        if (subgroups && subgroups.length) {
            for (let i = 0; i < subgroups.length; i++) {
                const { id, shadule = {} } = subgroups[i];
                if (shadule[selectedDay]) {
                    if (defaultActiveKey !== null) {
                        defaultActiveKey = null;
                        break;
                    }
                    defaultActiveKey = id;
                }
            }
        }

        return <div className='visits-main-mobile'>
            <this.SelectedMonth />
            <Collapse key={defaultActiveKey} defaultActiveKey={String(defaultActiveKey)}>
                {subgroups.map((subgroup, index) => {

                    const { id, title, color, shadule = {}, settingsAsObj } = subgroup;
                    if ( settingsAsObj && settingsAsObj.notActive) return null;
                    
                    if (shadule[selectedDay]) {
                        const month_key = `${selectedMonth}_${selectedYear}`
                        const count = (visits[month_key] && visits[month_key][id] && visits[month_key][id][selectedDay] && visits[month_key][id][selectedDay].length) || 0
                        return <Panel
                            key={id}
                            header={
                                <div className='visits-main-mobile-subheader'>
                                    <div style={{ background: `#${color}` }} className='visits-main-mobile-subheader-index'>
                                        <div>{index + 1}</div>
                                    </div>
                                    <div className='visits-main-mobile-subheader-title'>
                                        <div>{title}</div>
                                    </div>
                                    <div className='visits-main-mobile-subheader-count' onClick={this.onCountClick}>
                                        <div>{count || ''}</div>
                                    </div>
                                </div>
                            }>
                            <this.SubgroupStudens subgroup={subgroup} />
                            <div className='visits-main-mobile-addperson'>
                                <div className='visits-main-mobile-inside' onClick={() => { this.setStudentToAddSbId(id) }}>
                                    <span>
                                        СОЗДАТЬ НОВОГО УЧЕНИКА
                                    </span>
                                </div>
                            </div>
                        </Panel>
                    }
                    return null;
                })}
            </Collapse>
            <Modal
                visible={studentToPayment}
                title='Добавить оплату'
                footer={null}
                onCancel={() => { this.setStudentToPayment() }}
            >
                {studentToPayment && <PaymentModal closeModal={() => { this.setStudentToPayment() }} subgroup={studentToPayment.subgroup} student={studentToPayment.student} />}
            </Modal>
            <Modal footer={null} title={'Добавить нового ученика'} style={{ width: 'fit-content' }} onCancel={() => { this.setState({ studentToAddSbId: null }) }} visible={studentToAddSbId} >
                {studentToAddSbId ? <PersonFastRegistration onAddPerson={this.onAddPerson} schoolId={iamuser.SchoolId} groupId={groupId} /> : null}
            </Modal>
        </div >
    }

    setStudentToPayment = (studentToPayment = null) => {
        this.setState({ studentToPayment })
    }

    addSubgroupVisit = ({ subgroupid, userid, day, isDiscipline }) => {
        const { addVisit } = this.props;
        let discipline = 2;

        const disciplineChange = (e) => discipline = e.target.value;
        const DiscComponent = () => <Radio.Group onChange={disciplineChange} defaultValue={2}>
            <Radio key={`asvis_1`} value={1}>{DISCIPLINE_SMILES[1]}</Radio>
            <Radio key={`asvis_2`} value={2}>{DISCIPLINE_SMILES[2]}</Radio>
            <Radio key={`asvis_3`} value={3}>{DISCIPLINE_SMILES[3]}</Radio>
        </Radio.Group>

        if (!isDiscipline) {
            addVisit({ subgroupid, userid, day })
        } else {
            Modal.confirm({
                title: 'Отметка дисциплины',
                content: <DiscComponent discipline={discipline} />,
                okText: 'ОТМЕТИТЬ',
                cancelText: 'ОТМЕНА',
                onOk: () => {
                    addVisit({ subgroupid, userid, day, discipline })
                }
            })
        }

    }

    // оплачена ли разовая тренировка у данного
    isMonthPayed = ({ studentid, subgroupid, month, year }) => {
        const { payments } = this.props;
        const dateKey = `${month}_${year}`;
        return payments[dateKey] && payments[dateKey][subgroupid] && payments[dateKey][subgroupid][studentid] && payments[dateKey][subgroupid][studentid]['month'];
    }
    // оплачена ли разовая тренировка у данного
    isDayPayed = ({ studentid, subgroupid, day, month, year }) => {
        const { payments } = this.props;
        const dateKey = `${month}_${year}`;
        return payments[dateKey] && payments[dateKey][subgroupid]
            && payments[dateKey][subgroupid][studentid]
            && payments[dateKey][subgroupid][studentid]['day']
            && payments[dateKey][subgroupid][studentid]['day'][day];
    }

    SubgroupStudens = ({ subgroup }) => {

        const { studentsBySubgroups, client, selectedMonth, selectedYear, visits, addVisit, deleteVisit } = this.props;
        const { selectedDay } = this.state;

        const students = studentsBySubgroups[subgroup.id] || [];
        let studentsToShow = sortBy(students, item => { return item.Surname });

        const month_key = `${selectedMonth}_${selectedYear}`;

        const { id } = subgroup;

        return <div key={`sssss-${id}`} className='visits-main-mobile-srudents'>
            {studentsToShow.map((student, index) => {

                const visit_day = (visits[month_key] && visits[month_key][id] && visits[month_key][id][selectedDay]) ? visits[month_key][id][selectedDay] : [];
                const visit_student = visit_day.find(x => x.UserId === student.Id);

                const payment = { 
                    isMonthPayed: this.isMonthPayed({ studentid: student.Id, subgroupid: id, month: selectedMonth, year: selectedYear }),
                    isDayPayed: this.isDayPayed({ studentid: student.Id, subgroupid: id, month: selectedMonth, year: selectedYear, day: selectedDay }),
                    visit_student
                };

                return <div className='visits-main-mobile-srudent'>
                    <div className='visits-main-mobile-srudent-left'>
                        <Student
                            client={client}
                            student={student}
                            index={index + 1}
                            payment={payment}
                            clickToPayment={(student) => this.setStudentToPayment({ student, subgroup })}
                            fields={
                                [
                                    { type: 'index', width: 20 },
                                    { type: 'avatar', width: 30 },
                                    { type: 'fioac', width: 180 },
                                    { type: 'payment_mobile', width: 30 },
                                ]
                            }
                            notDragged
                        />
                    </div>
                    <div className='visits-main-mobile-srudent-right'
                        onClick={
                            () => {
                                if (visit_student) {
                                    deleteVisit({ VisitId: visit_student.Id, Month: selectedMonth, Year: selectedYear, Day: selectedDay, subgroupid: id });
                                } else
                                    this.addSubgroupVisit({ subgroupid: id, userid: student.Id, day: selectedDay, isDiscipline: subgroup.discipline })
                            }
                        }
                    >
                        <div>
                            {visit_student ?
                                this.DisciplineText(visit_student.Discipline, subgroup.discipline)
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            })}
        </div>

    }

    DisciplineText = (discipline, sbDisc) => {
        if (!sbDisc || discipline === 5) return '✖';
        return DISCIPLINE_SMILES[discipline] || '✖';
    }

    SelectedMonth = () => {
        const { selectedMonth, selectedYear, dayShift } = this.props;
        const { selectedDay, today } = this.state;

        let text = '';
        if (today.day === selectedDay && today.month === selectedMonth && today.year === selectedYear) {
            text = 'СЕГОДНЯ'
        } else text = moment(`${selectedDay}.${selectedMonth}.${selectedYear}`, `DD.MM.YYYY`).format('D MMMM');

        let seeRight = true;
        if (today.month === selectedMonth && today.year === selectedYear && selectedDay >= today.day) {
            seeRight = false;
        }

        return <div className='month-select-mobile'>
            <div className='month-select-arrow' onClick={async () => {
                const newDay = await dayShift(selectedDay, -1);
                this.setState({ selectedDay: newDay })
            }}>◀</div>
            <p>{text}</p>
            {seeRight
                ? <div className='month-select-arrow' onClick={async () => {
                    const newDay = await dayShift(selectedDay, 1);
                    this.setState({ selectedDay: newDay });
                }}>▶</div>
                : <div className='month-select-arrow'></div>
            }
        </div>
    }
}

export default withCRMState(
    `
        client,
        isMobile,

        iamuser,
        groupId,
        moveToNewSb,
        renewStudents,
    
        subgroups,
        studentsBySubgroups,

        shadule,
        students,

        visits,
        addVisit,
        deleteVisit,

        selectedMonth,
        selectedYear,
        trainingDays,

        payments,

        dayShift
    `
)(VisitsMobile)