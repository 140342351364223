import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import { Input, message } from 'antd';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import MoneyComponent from 'src/ver2/components/money';
// import { Link } from 'react-router-dom';

import './style.css';

// class PersonFrkAccredPlashka
// classname: person_fkraccred_plashka


class ExchangeModal extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        isChecked: false,
        inputValue: '1',
        value: 1
    }

    onClick = () => {
        const { value } = this.state;
        this.props.onChangeClick(value);
    }

    handleChange = (e) => {

        const { Shop: { SettingsObj }, iamuser } = this.props;
        const { Exchange: { MoneyFrom } } = SettingsObj;

        const { value: inputValue } = e.target;
        const reg = /^(\d*)$/;

        let myResourses = {};
        if (iamuser) myResourses = iamuser.myResourses;
        const { Money } = myResourses;

        if (reg.test(inputValue) || inputValue === '') {

            const moneyTo = Number(inputValue);
            if (moneyTo > Money[MoneyFrom].all) {
                message.error('У Вас недостаточно ресурсов');
                return;
            }

            this.setState({ inputValue, value: Number(inputValue) })
        }
    };

    render() {

        const { inputValue, value } = this.state;

        
        const { Shop: { SettingsObj } } = this.props;
        const { Exchange: { MoneyFrom, MoneyTo, Rate } } = SettingsObj;

        const mayExchange = (!value || value < 1) ? false : true;

        return <div className='shop_exchanger_plashka_modal'>

            <Input
                value={inputValue}
                onChange={this.handleChange}
            />
            <div className='shop_exchanger_plashka_modal_money'>
                <MoneyComponent size={35} type={MoneyFrom} amount={value} />
                <span>=</span>
                <MoneyComponent size={35} type={MoneyTo} amount={Rate * value} />
            </div>

            <ButtonPlashka
                upperStyle={{ width: 200, height: 40 }}
                disabled={!mayExchange}
                disabledDiscription={'не корректно'}
                onClick={() => this.onClick()}
                text={'ОБМЕНЯТЬ'}
            />
        </div>
    }



}

export default withShopState(`
Shop,  
isShopMoneyReady, ShopMoney,
iamuser,
client
`)(ExchangeModal);
