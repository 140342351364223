// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withEventState } from 'src/ver2/pages/event/context';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';
import ImageUploader from 'src/ver2/containers/imageChanger';
import EventPurpose from 'src/ver2/pages/event/components/purpose';
import EventOperations from 'src/ver2/pages/event/components/operations';
import StorysSender from 'src/ver2/pages/event/components/storysSender';
import ChampionshipManager from 'src/ver2/pages/event/components/championship';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент администрирования мероприятия
 */

const AdminModal = ({ Event, Championship, Discription, iAmAdmin, client, updateDiscription, addDiscription, uploadEventMain, uploadEventPayementsData, uploadEventSpacial }) => {

    if (!iAmAdmin) return <div>ЧО ТЫ ТУТ ДЕЛАЕШЬ???</div>

    const mainInformationChange = async (EventMain) => {

        // тут у нас функция сравнения
        const resultSend = compareFunction(Event, EventMain);

        if (Object.keys(resultSend).length > 0) {
            uploadEventMain(resultSend);
        }
    }

    const uploadEventAvatar = async (data) => {
        const result = await client.updateAvatarEvent(Event.Id, data);
        if (result) {
            message.success('Автар изменён, перегрузите страницу');
        } else {

            message.warn('Ошибка на стороне сервера');
        }
    }

    const uploadEventBackground = async (data) => {
        const result = await client.updateAvatarEvent(Event.Id, data, true);
        if (result) {
            message.success('Баннер изменён, перегрузите страницу');
        } else {

            message.warn('Ошибка на стороне сервера');
        }
    }

    const onPaymentData = async (data) => {
        console.log('onPaymentData', { data });
        const { PaymentData } = data;
        if (PaymentData) {
            uploadEventPayementsData(PaymentData);
        }
    }

    const onSpecialData = async (data) => {
        uploadEventSpacial(data);
    }

    console.log('EVENT', { Event });
    const PaymentMain = (Event && Event.SettingsObj && Event.SettingsObj.PaymentMain) || null
    const SpecialMain = (Event && Event.SettingsObj && Event.SettingsObj.SpecialMain) || {};

    return <div className='admin_modal'>
        <Tabs className='admin_modal_tabs' tabPosition='left' style={{ color: 'white', heightMin: 500 }} defaultActiveKey="1">

            <TabPane tab={'Основные данные'} key="1">
                <DataChanger
                    header={<div>
                        Основные данные мероприятия
                    </div>}
                    data={Event}
                    buttonText={'СОХРАНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={mainInformationChange}
                    components={[
                        {
                            name: 'Title',
                            title: 'Название',
                            type: 'input',
                            style: {
                                width: 600
                            },
                            neededText: ' '
                        },
                        {
                            name: 'ShortTitle',
                            title: 'Короткое название',
                            text: 'ПРИМЕР: Чемпионат России 2022 -> ЧР2022',
                            type: 'input',
                            nextGroup: { title: 'Даты мероприятия' }
                        },
                        {
                            name: 'IsPreliminarily',
                            title: 'Использовать предварительные даты',
                            text: 'Выберете, если вы еще не знаете точные даты мероприятия',
                            type: 'checkbox',
                            nextGroup: { title: 'Место проведения' },
                            style: {
                                width: 600
                            },
                            blockByValue: {
                                false: [
                                    {
                                        name: 'Date',
                                        title: 'Дата проведения',
                                        type: 'date',
                                        neededText: ' '
                                    },
                                    {
                                        name: 'EndDate',
                                        title: 'Дата окончания',
                                        type: 'date',
                                        text: 'Оставьте пустым, если мероприятие проходит в один день'
                                    },
                                ]
                                ,
                                true: [
                                    {
                                        name: 'StartPreliminarilyDate',
                                        title: 'Начало периода',
                                        type: 'dateMonth',
                                        neededText: ' '
                                    },
                                    {
                                        name: 'EndPreliminarilyDate',
                                        title: 'Окончание периода',
                                        type: 'dateMonth',
                                        text: 'Оставьте пустым, если вы ожидаете мероприятие в конкретный месяц'
                                    },
                                ]
                            }

                        },
                        {
                            name: 'City',
                            title: 'Город',
                            type: 'input',
                            text: 'Город проведения мероприятия',
                        },
                        {
                            name: 'Adress',
                            title: 'Адрес',
                            text: 'Можно не указывать в адресе город',
                            type: 'input',
                            style: {
                                width: 500
                            },
                        }
                    ]}
                />
            </TabPane>
            <TabPane tab={'Информация'} key="2">
                {Discription ?
                    <InfoChanger text={Discription.Body} onCallback={updateDiscription} /> :
                    <InfoAdder onCallback={addDiscription} />
                }
            </TabPane>
            <TabPane tab={'Лейбл мероприятия'} key="3">
                <ImageUploader
                    imageUrl={client.getEventsUrl() + Event.Id + '_logo.png'}
                    header='Установка аватара мероприятия'
                    recomendations='Добавьте квадратную аватарку, желательно круглый png с прозрачным фоном'
                    upload={uploadEventAvatar} />
            </TabPane>
            <TabPane tab={'Баннер мероприятия'} key="4">
                <ImageUploader
                    imageUrl={client.getEventsUrl() + Event.Id + '.png'}
                    header='Установка баннера мероприятия'
                    recomendations='Добавьте баннер формата 1300х340'
                    upload={uploadEventBackground} />
            </TabPane>
            {(PaymentMain) && <TabPane tab={'Оплата'} key='8'>
                <DataChanger
                    header={<div>
                        Задайте цену для разных вариантов регистрации в зависимости от даты и выбранного варианта <br />
                        или оставьте цену пустой, если данный вариант не оплачивается
                    </div>}
                    data={PaymentMain}
                    buttonText={'СОХРАНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={onPaymentData}

                    components={[
                        {
                            name: 'PaymentData',
                            title: 'Оплата мероприятия',
                            type: 'payment',
                            neededText: ' ',
                            text: 'Должны быть заполнены все поля дат или удалены пустые',
                            style: {
                                width: 800
                            },
                        }
                    ]}
                />
            </TabPane>
            }
            {(SpecialMain) && <TabPane tab={'Специальное'} key='9'>
                <DataChanger
                    header={<div>
                        Специальные условия <br />
                    </div>}
                    data={SpecialMain}
                    buttonText={'СОХРАНИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={onSpecialData}
                    components={[
                        {
                            name: 'LiteVersion',
                            title: 'Лайт версия',
                            text: 'Не показывает всякие разные штуки',
                            type: 'checkbox',
                        }
                    ]}
                />
            </TabPane>
            }
            <TabPane tab={'Персонал'} key='5'>
                <EventPurpose />
            </TabPane>
            <TabPane tab={'Операции'} key='6'>
                <EventOperations />
            </TabPane>
            {Event && Event.Type === 'championship' && <TabPane tab={`Бои`} key='7'>
                <StorysSender />
            </TabPane>}
            {Event && Event.Type === 'championship' && Championship && <TabPane tab={`Соревнование`} key='10'>
                <ChampionshipManager />
            </TabPane>}
        </Tabs>
    </div>
}



/*
    ChildrenComponent - какой компонент использовать
    CholdrenProps - что туда прокинуть
    onCallback - функция, которую вызывать при триггере компонента
    callbackName - имя функции, которую тригерить у компонента
    callbackParam - имя параметра, в котором к компоненты улетит callbackStatus
*/

class InfoAdder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callbackStatus: ''
        }
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { callbackStatus } = this.state;
        return <TrefaTextEdit buttonText={'Добавить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Добавить' }} onButtonClick={this.onCallback} />
    }

}

class InfoChanger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callbackStatus: ''
        }
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { text } = this.props;
        const { callbackStatus } = this.state;
        return <TrefaTextEdit text={text} buttonText={'Сохранить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Сохранение' }} onButtonClick={this.onCallback} />
    }

}

export default withEventState(`
Event, 
isEventReady, 
isChampionshipReady, 
Championship, 
Discription,
addDiscription,
updateDiscription,

uploadEventMain,
uploadEventPayementsData,
uploadEventSpacial,

iAmCompetitor,
iAmAdmin,
iAmJudge,

client

`)(AdminModal);