// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { withEventState } from 'src/ver2/pages/event/context';
import Loader from 'src/ver2/components/loader';
import { Link } from 'react-router-dom';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

/**
 * 
 * Это компонент регистрации на мероприятие
 * принимает close от events
 * 
 * Сначала он проверяет - можешь ли ты вообще зарегаться по Necessery ( есть ли школа, контакты, что там еще надо)
 * 
 * Далее выдаёт тебе плашку с данными 
 * 
 */

class RegistrtionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 'loading',
        }
    }

    findCategoryForUser(userData) {

        const { Categorys, Event } = this.props;

        if (!Event) return [];
        if (!Categorys) return [];

        const userBirth = moment(userData.BirthDay, 'DD.MM.YYYY');
        const competitionStart = moment(Event.Date, 'DD.MM.YYYY');

        const userAgeAtChampionshipDate = competitionStart.diff(userBirth, 'years');

        let findedCategorys = [];

        for (let i = 0; i < Categorys.length; i++) {
            const item = Categorys[i];
            if (
                userAgeAtChampionshipDate >= item.AgeFrom
                &&
                userAgeAtChampionshipDate <= item.AgeTo
                &&
                (userData.Gender === item.Gender || item.Gender === 3)
            ) {
                findedCategorys.push(
                    {
                        key: Categorys[i].Id,
                        title: Categorys[i].Name
                    }
                );
            }
        }

        return findedCategorys;

    }

    componentWillMount() {
        const { Event, close } = this.props;
        const { SettingsObj } = Event;
        if (!SettingsObj) return close(false);

        const { ParticipatorsMain } = SettingsObj;
        if (!ParticipatorsMain) return close(false);
        const { ParticipatorsData } = ParticipatorsMain;
        if (!ParticipatorsData) return close(false);

        let myParticipatorsData = [];
        let ParticipatorsDataObj = {};

        for (let i = 0; i < ParticipatorsData.length; i++) {
            if (ParticipatorsData[i].name === 'participation') {
                continue;
            }
            myParticipatorsData.push(ParticipatorsData[i]);
            ParticipatorsDataObj[ParticipatorsData[i].name] = null;
            if (ParticipatorsData[i].options && ParticipatorsData[i].options[0].title === '-') {
                ParticipatorsDataObj[ParticipatorsData[i].name] = ParticipatorsData[i].options[0].key;
            }
        }

        this.setState({
            status: 'loaded',
            ParticipatorsData: myParticipatorsData,
            ParticipatorsDataObj
        })

        /*
            {
                name: participation,
            title, 
            options, 
            paymentSplit,
                        payments: [

                        ]                    
                    } // всегда должна быть
        */

    }

    onRegistrationClick = () => {
        const { addParticipator, addCompetitor, iamuser, close, Event, Championship } = this.props;
        const { ParticipatorsDataObj } = this.state;

        // если соревнованием, но у нас другое немного поведение
        if (Event && Event.ChampionshipId && Championship) {
            let participatorDataObj_ = {};
            for (let i = 0; i < Object.keys(ParticipatorsDataObj).length; i++) {
                const key = Object.keys(ParticipatorsDataObj)[i];
                if (key !== 'category') {
                    participatorDataObj_[key] = ParticipatorsDataObj[key];
                }
            }
            const participatorData = JSON.stringify(participatorDataObj_);

            addCompetitor(
                {
                    UserId: iamuser.Id,
                    SchoolId: iamuser.SchoolId,
                    GroupIamInId: iamuser.GroupIamInId,
                    CompetitionId: Championship.Id,
                    CategoryId: ParticipatorsDataObj.category,
                    Settings: participatorData
                }
            );
            close(false);
            return;
        }

        const participatorData = JSON.stringify(ParticipatorsDataObj)

        //console.log('onRegistrationClick', participatorData);

        addParticipator(iamuser.Id, participatorData);
        close(false);


        return;
    }

    onChangeData = (ParticipatorsDataObj) => {
        this.setState({ ParticipatorsDataObj })
    }

    render() {

        const { status, ParticipatorsDataObj, ParticipatorsData } = this.state;
        const { Event, Championship, Categorys, iamuser, mayIParticipateByFkr } = this.props;
        if (status === 'loading') {
            return <div className='event_registration_modal_conteiner'>
                <Loader />
            </div>
        }

        let components = [];
        const { SettingsObj } = Event || {};
        const { IgnoreFkr } = SettingsObj || {};

        console.log ('REGISTRATION!!!');

        if (Championship && !IgnoreFkr) {
            if (!mayIParticipateByFkr) {
                return <div>
                    У Вас не оформлено членство в ФКР на дату текущего соревнования. <br/><br/>
                    Для возможности участия в сорванованиях, пожалайста, пройдите в Ваш профиль и оформите членство в ФКР на соответствующей плашке. <br/><br/>
                    <Link to = {`/fkraccred`}>ПЕРЕЙТИ К ОФОРМЛЕНИЮ</Link> <br/><br/>
                    Если у Вас возникают вопросы при оформлении - свяжитесь по телефону <br/><br/>  
                    <a href = 'tel:+79260147285'>+7 926 014 72 85</a> Анастасия
                </div>
            }

        }

        if (Championship && Categorys && Categorys.length) {
            components.push({
                name: 'category',
                title: 'Категория',
                type: 'select',
                options: this.findCategoryForUser(iamuser),
                neededText: ' '
            })
        }

        components.push(
            ...ParticipatorsData.map(({ name, title, options }) => {
                return {
                    name,
                    title,
                    type: 'select',
                    options,
                    neededText: ' '
                }
            }
            )
        )

        return <div className='event_registration_modal_conteiner'>

            <DataChanger
                header={<div> Заполните данные для регистрации </div>}
                footer={<this.PaymentComponent />}
                data={ParticipatorsDataObj}
                buttonText={'ЗАРЕГИСТРИРОВАТЬЯ'}
                buttonState={'allNeeded'}
                onButtonClick={this.onRegistrationClick}
                changeData={this.onChangeData}
                components={components}
            />

        </div>
    }

    PaymentComponent = () => {
        const { ParticipatorsDataObj } = this.state;
        const { howManyAwaitForParticipatorToday } = this.props;

        const howMany = howManyAwaitForParticipatorToday(ParticipatorsDataObj);

        if (!howMany) return null;

        const { allPayment, allSale, paymentRows, saleRows } = howMany;
        if (allPayment === 0) return null;

        return <div className='event_registration_modal_conteiner_payment_main'>

            <div className='event_registration_modal_conteiner_payment_title'>
                {`ОПЛАТА НА ТЕКУЩИЙ МОМЕНТ: ${allPayment - allSale} руб.`}
            </div>
            <div className='event_registration_modal_conteiner_payments'>
                {paymentRows.map(({ name, title, paymentNow, isAccepted, paymentContent }) => {

                    if (!isAccepted) {
                        return <div key={'dfdfgdfg' + name}>
                            <span>{title}</span> уже нельзя оплатить
                    </div>
                    }

                    if (paymentNow === 0) {
                        return null;
                    }

                    return <div key={'dfdfgdfg' + name}>
                        <span> {paymentContent} </span>
                    </div>
                })}
            </div>
            {saleRows.length > 0 ? <div className='event_registration_modal_conteiner_payment_title'>
                СКИДКА:
            </div> : null}
            {saleRows.length > 0 ? <div className='event_registration_modal_conteiner_sales'>
                {saleRows.map(({ saleContent, value }, index) => {
                    return <div key={'ermcss' + index} className='event_registration_modal_conteiner_sale'>
                        <div key={'ermcsss' + index} className='event_registration_modal_conteiner_sale_block'>
                            {saleContent}
                        </div>
                        <div className='event_registration_modal_conteiner_sale_value'>
                            {` ${value} руб.`}
                        </div>
                    </div>
                })}
            </div> : null}
        </div>
    }
}

export default withEventState(`
Event, 
Championship, 
Categorys,

addParticipator,
addCompetitor,

mayIParticipateByFkr,

checkMayIParticipateByNecessery,
howManyAwaitForParticipatorToday,

iamuser,
iAmCompetitor,
iAmParticipator,

`)(RegistrtionModal);