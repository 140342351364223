import React, { Component } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import GroupPlate from 'src/ver2/components/group/groupPlate';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventGroupResultsPlashka
// classname: event_results_group_plashka

/**
  Это большая плашка с результатами
  У неё два режима - это показывать 
 */

class EventGroupResultsPlashka extends Component {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false,
        }
    }

    setHight = () => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const element = document.getElementById('event_results_group_plashka_inside_id');
        if (!element) return;
        if (this.state.heightSetted === element.clientHeight) return;
        this.setState({ heightSetted: element.clientHeight });
        setComponentSelfHeight({ height: element.clientHeight + 50, plashkaId });
    }

    componentDidMount() {
        this.setHight();
    }

    componentDidUpdate() {
        this.setHight();
    }

    render() {

        const { isResultsReady, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!isResultsReady) return <div style={{ width, height }} className='event_results_group_plashka'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='event_results_group_plashka'>
            <div id='event_results_group_plashka_inside_id' className='event_results_group_plashka_inside'>
                <this.GroupsRender />
            </div>
        </div>
    }

    GroupsRender = () => {

        const { isResultsReady, Results, showInfoModal, Event, iamuser, isMobile } = this.props;

        if (!isResultsReady || !iamuser) return <Loader />

        let Answer = [];
        const { groupPlaces } = Results;

        for (let i = 0; i < groupPlaces.length; i++) {

            const groupsPlace_ = groupPlaces[i];
            const {
                GroupId,
                Gold,
                Silver,
                Bronze,
            } = groupsPlace_;

            if (iamuser.GroupIamInId !== GroupId && iamuser.GroupId !== GroupId) continue;

            // eslint-disable-next-line no-loop-func
            const onClick = () => {
                showInfoModal({ type: 'eventGroupResults', Data: {
                    GroupId, EventTitle: Event.Title, EventId: Event.Id, Gold, Silver, Bronze
                }, width: isMobile ? isMobile.width - 100 : 450 });
                return;
            }

            Answer.push(
                <div key={'cr-gr-' + GroupId} onClick={onClick} className='event_results_group_plashka_mini_cat_container'>

                    <GroupPlate width={140} height={40} GroupId={GroupId} />
                    <div className='event_results_group_plashka_medals'>
                        <PlaceRect height={40 * 0.65} Place={1} PlaceName={Gold} asMedal = {true} />
                        <PlaceRect height={40 * 0.65} Place={2} PlaceName={Silver} asMedal = {true} />
                        <PlaceRect height={40 * 0.65} Place={3} PlaceName={Bronze} asMedal = {true} />
                    </div>
                </div>
            )
        }
        return Answer;
    }
}

export default withEventState(`
Event,
Categorys, 
Results,
isResultsReady,
showInfoModal,

isMobile,

client,
iamuser,
`)(EventGroupResultsPlashka)