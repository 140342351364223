import React, { Component } from 'react';
//import logo from './logo.svg';

import './style.css';
import { withCRMState } from '../../context';
import { Select } from 'antd';

const { Option } = Select;

const REQUEST_CODES = {
    0: 'Не видел',
    1: 'Видел',
    2: 'Связался',
    3: 'Не смог связаться',
    4: 'Пришел',
    5: 'Не пришел'
}

class RequestPage extends Component {

    render () {

        const { requests } = this.props;
        if (!requests || !requests.length) {
            return <div className='crm_requests_main'>
                <span>
                    НЕТ ЗАЯВОК
                </span>
            </div>     
        }

        const requests_ = requests.reverse();

        return <div className='crm_requests_main'>
            {requests_.map( request => <this.Request request = {request} />)}
        </div>
    }

    Request = ( { request } ) => {
        const { updateSbRequest } = this.props;
        const { Id, Name, Surname, Tel, Status } = request;
        return <div key = {`crm_req_${Id}`} className='crm_requests_one_request'>
            <div className='crm_requests_one_request_title'>
                {`${Surname} ${Name}`}
            </div>
            <div className='crm_requests_one_request_title'>
                {`${Tel}`}
            </div>
            <div className='crm_requests_one_request_title'>
                <Select style = {{ width: 150}} value={Status} onChange={(value) => {
                    updateSbRequest({ RequestId: Id, Status: value });
                }}>
                    {Object.keys(REQUEST_CODES).map( v => {
                        const num = Number(v)
                        return <Option key={num} value={num}>{REQUEST_CODES[num]}</Option>
                    })}
                </Select>
            </div>
        </div>
    }

}

export default withCRMState(
    `
        client,
    
        subgroups,
        shadule,

        requests,
        updateSbRequest
    `
)(RequestPage)