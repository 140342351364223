import React, { PureComponent } from 'react';

import LittlePerson from 'src/ver2/components/person/little';
import { withEventState } from 'src/ver2/pages/event/context';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';

import Loader from 'src/ver2/components/loader';
import { Modal } from 'antd';

import { sortBy } from 'lodash';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventParticipatorsAdminPlashka
// classname: event_participators_admin_plashka

/**
  Это плашка, в которой у нас отображаются партисипаторы / компетиторы
  и где администратор может отмечать их оплаты, явки и всё прочее
 */

class EventGroupAdminPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            clickedData: null,
            filter: 'f_NUMBER',
            filterAdmission: ''
        }
    }

    changeShowModal = (showModal = true) => this.setState({ showModal });
    setFilter = (filter) => this.setState({ filter });
    setFilterAdmission = (filterAdmission) => {
        if (filterAdmission === this.state.filterAdmission) {
            return this.setState({ filterAdmission: '' })
        }
        this.setState({ filterAdmission })
    }

    render() {

        const { Event, iamuser, isParticipatorsReady, upperStyle, Participators, Competitors, Championship, isTherePayment, ParticipatorsData } = this.props;
        const { width, height } = upperStyle;

        const { showModal, filter, filterAdmission } = this.state;

        if (!Event || !isParticipatorsReady) return <div style={{ width, height }} className='event_participators_admin_plashka'>
            <Loader />
        </div>

        let Admissions = []; // { key, title }
        if (isTherePayment()) {
            Admissions.push({ key: 'payment', title: 'Оплата' })
        }

        const { SettingsObj } = Event;
        if (SettingsObj && SettingsObj.AdmissionMain && SettingsObj.AdmissionMain.AdmissionData) {
            // добавляем остальных штук
            for (let i = 0; i < SettingsObj.AdmissionMain.AdmissionData.length; i++) {
                Admissions.push(SettingsObj.AdmissionMain.AdmissionData[i]); //{ key, title, text }
            }
        }

        let Partis_ = (Event.Type === 'championship' && Championship) ? Competitors : Participators;
        let Partis = [];

        let myGroups = {};
        if (iamuser && iamuser.GroupId) myGroups[iamuser.GroupId] = true;

        if (iamuser && iamuser.myGroupRole) {
            for (let i = 0; i < iamuser.myGroupRole.length; i++) {
                const item = iamuser.myGroupRole[i];
                if (item.Role === 'instructor') {
                    myGroups[item.SubjectId] = true;
                }
            }
        }

        if (Partis_ && Partis_.length) {
            for (let i = 0; i < Partis_.length; i++) {
                if (myGroups[Partis_[i].GroupIamInId]) {
                    Partis.push(Partis_[i]);
                }
            }
        }


        if (filter === 'f_FIO') {
            Partis = sortBy(Partis, ['Surname']);
        }

        if (filterAdmission && filterAdmission !== '' && Admissions && Admissions.find(x => x.key === filterAdmission)) {
            //let PartisAll = [...Partis]
            let PartisHave = [];
            let PartisDont = [];
            for (let i = 0; i < Partis.length; i++) {
                if (ParticipatorsData && ParticipatorsData[Partis[i].UserId] && ParticipatorsData[Partis[i].UserId].find(x => x.Type === filterAdmission)) {
                    PartisHave.push(Partis[i]);
                } else {
                    PartisDont.push(Partis[i]);
                }
            }
            Partis = [...PartisHave, ...PartisDont];
        }

        // 

        // тут у нас будет идти фильтрация

        return <div style={{ width, height }} className='event_participators_admin_plashka'>
            <this.HeaderBlock Admissions={Admissions} />
            <this.HeaderInfoBlock Admissions={Admissions} Participators={Partis} />
            {Partis.map((Participator) => {
                return <this.OneParticipatorRow key={Participator.Id} Participator={Participator} Admissions={Admissions} />
            })}
        </div>
    }

    HeaderBlock = ({ Admissions }) => {
        const { filter, filterAdmission } = this.state;
        return <div className='event_participators_admin_plashka_participator'>
            <div className='event_participators_admin_plashka_participator_left'>
                <span onClick={() => { this.setFilter('f_FIO') }} className={filter === 'f_FIO' ? 'event_participators_admin_plashka_participator_admission_selected' : ''}>ФИО</span>
                <span onClick={() => { this.setFilter('f_NUMBER') }} className={filter === 'f_NUMBER' ? 'event_participators_admin_plashka_participator_admission_selected' : ''}>Порядок</span>
            </div>
            <div className='event_participators_admin_plashka_participator_separator' />
            {Admissions.map(({ key, title, text }) => {
                return <div
                    key={'epappa' + key}
                    onClick={() => { this.setFilterAdmission(key) }}
                    className={'event_participators_admin_plashka_participator_admission ' + (filterAdmission === key ? 'event_participators_admin_plashka_participator_admission_selected' : '')}>
                    {title}
                </div>
            })}
        </div>
    }

    HeaderInfoBlock = ({ Admissions, Participators, ValueOnly = false }) => {

        const { ParticipatorsData } = this.props;

        if (!ParticipatorsData || !Participators || !Participators.length) return null;

        let AdmsissionWithKeys = {};
        for (let i = 0; i < Admissions.length; i++) {
            AdmsissionWithKeys[Admissions[i].key] = {
                value: 0,
                count: 0
            };
        }

        for (let i = 0; i < Object.keys(ParticipatorsData).length; i++) {
            const key = Object.keys(ParticipatorsData)[i];
            const PData = ParticipatorsData[key];

            if (!Participators.find(x => String(x.Id) === key)) continue;

            for (let j = 0; j < Admissions.length; j++) {
                const admissionKey = Admissions[j].key;

                const PDataAdmission = PData.find(x => x.Type === admissionKey);

                if (PDataAdmission && PDataAdmission.Value) {
                    if (admissionKey === 'payment') {
                        AdmsissionWithKeys[admissionKey].count++;
                        AdmsissionWithKeys[admissionKey].value += Number(PDataAdmission.Value);
                    } else {
                        AdmsissionWithKeys[admissionKey].count++;
                    }
                }
            }
        }

        return <div className='event_participators_admin_plashka_participator'>
            <div className='event_participators_admin_plashka_participator_left'>

            </div>
            <div className='event_participators_admin_plashka_participator_separator' />
            {Object.keys(AdmsissionWithKeys).map((key) => {

                const { value, count } = AdmsissionWithKeys[key];

                return <div
                    key={'epappap' + key}
                    className={'event_participators_admin_plashka_participator_admission '}>
                    {ValueOnly ? value ? `∑ = ${value}` : '' : `${count} / ${Participators.length}`}
                </div>
            })}
        </div>
    }

    OneParticipatorRow = ({ Participator, Admissions }) => {

        const DirectPerson = {
            ...Participator,
            Id: Participator.UserId
        }
        const height = 50;

        const { getParticipatorsSelectName, ParticipatorsData } = this.props;
        const Selections = getParticipatorsSelectName(Participator);

        return <div className='event_participators_admin_plashka_participator' style={{ height }}>
            <div className='event_participators_admin_plashka_participator_left'>
                <LittlePerson personsDirect={true} mode='participators_admin' DirectPerson={DirectPerson} height={height} />
            </div>
            <div className='event_participators_admin_plashka_participator_separator' />
            {Admissions.map(({ key, title, text }) => {

                let onePRClassName = 'event_participators_admin_plashka_participator_admission_person';
                let Comment = null;
                let Value = null;
                let inside = <span>
                    {title}
                </span>

                let finded = null;

                if (ParticipatorsData && ParticipatorsData[Participator.UserId]) {
                    finded = ParticipatorsData[Participator.UserId].find(x => x.Type === key);
                    if (finded) {
                        onePRClassName += ' event_participators_admin_plashka_participator_admission_person_check';
                        Comment = finded.Comment;
                        Value = finded.Value;
                        if (key === 'payment') {
                            inside = <span>
                                {Value}
                                <br />
                                {title}
                            </span>
                        }
                    }
                }

                return <div key={'epappappp' + key} className={onePRClassName}>
                    <span onClick={() => { this.setState({ clickedData: { Participator, admissionKey: key, admissionTitle: title, finded }, showModal: true }) }}>
                        {inside}
                    </span>
                </div>
            })}

            {(Selections && Selections.length) ?
                Selections.map((item) => {
                    return <div className='event_participators_admin_plashka_participator_category_person'>
                        {item.chooseName}
                    </div>
                }) : null
            }

        </div>
    }

}

export default withEventState(`
        
        Event,
        Championship,
        
        Participators,
        isParticipatorsReady,

        Competitors,
        isCompetitorsReady,
        
        ParticipatorsData,
        isParticipatorsDataReady,
        getParticipatorsSelectName,

        addParticipatorsData,
        
        isTherePayment,
        howManyAwaitForParticipatorToday,
        
        client,
        iamuser,
`)(EventGroupAdminPlashka)