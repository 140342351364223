// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import { groupBy } from 'lodash';
import './style.css';
// import { Tabs, message } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import PersonWrapper from 'src/ver2/components/person/personWrapper';
import ItemInventarComponent from 'src/ver2/components/item/inventar';
import OperationsModal from 'src/ver2/pages/person/plashki/dataPlashka/modal';
import { message } from 'antd';
import { ItemTypeComponent, ItemSubtypeComponent } from 'src/ver2/components/item/types';

/**
 * 
 * Это компонент инвентаря пользователя
 */

class PersonInventar extends PureComponent {
    constructor(props) {
        super()

        const { isMobile } = props;

        this.state = {
            itemWidth: 110,
            operationsModalShow: false
        }

        if (isMobile) {
            this.state.itemWidth = isMobile.width / 4;
        }

    }

    componentDidMount() {
        console.log('PersonInventar componentDidUpdate');
    }

    componentDidUpdate() {
    }

    closeOperationModal = () => {
        this.setState({ operationsModalShow: false })
    }
    openOperationModal = () => {
        this.setState({ operationsModalShow: true })
    }

    render() {
        const { isMobile, iamuser } = this.props;
        const { operationsModalShow } = this.state;
        const className = isMobile ? 'person_inventar_container person_inventar_container_mobile' : 'person_inventar_container';

        const Person = { Id: iamuser ? iamuser.Id : 0 }
        const operationModalData = 'is2';
        const { closeOperationModal } = this;

        return <div className={className}>
            <this.ItemsBlock />
            {(operationsModalShow && iamuser) && <OperationsModal {...{ Person, operationModalData, closeOperationModal }} />}
        </div>
    }

    ItemsBlock = () => {

        const { Items, isMobile, combineMode, IslandId } = this.props;

        const itemsInRow = isMobile ? 3 : 2;
        const minRows = isMobile ? 2 : 2;

        const { itemWidth } = this.state;

        let ItemsToShow = [...Items];

        if (Number(IslandId) === 2) {
            ItemsToShow.unshift({ starsItem: true })
        }

        const len = ItemsToShow.length;

        for (let i = len; i < itemsInRow * minRows; i++) {
            ItemsToShow.push({ blankItem: true });
        }

        return <div className='person_inventar_container_items'>
            <div className='person_island_inventar_container_items_inside'>
                {ItemsToShow.map((Item, index) => {
                    const { name, Id, blankItem, starsItem, isUsed } = Item;
                    if (blankItem) return <this.BlankItem key={`233333-${index}`} />
                    if (starsItem) return <this.StarsItem key={`233333-${index}`} />

                    let isUser_ = null;

                    const clickable = combineMode ?
                        [
                            {
                                iconType: 'plus',
                                title: 'Комбинировать',
                                onClick: () => this.props.addCombineItem({ ItemId: Id })
                            }

                        ]
                        :
                        [{
                            iconType: 'zoom-in',
                            title: 'Осмотреть объект',
                            onClick: () => this.props.showInfoModal({ type: 'item', Item })
                        },
                        {
                            iconType: 'up-circle',
                            title: 'Применить тут',
                            onClick: () => this.props.useItem({ ItemId: Id })
                        }
                        ]

                    let innerStyle = {};
                    if (combineMode && combineMode[Id]) {
                        innerStyle.background = 'green';
                    }

                    return <ItemInventarComponent
                        key={'123123' + Id}
                        width={itemWidth}
                        name={name}
                        uid={Id}
                        isUsed={isUsed || isUser_}
                        clickable={clickable}
                        plashkaType='inventar'
                        Item={Item}
                        innerStyle={innerStyle}
                        showSubtype={true}
                    />
                })}
            </div>
        </div>
    }

    BlankItem = () => {
        const { itemWidth } = this.state;
        return <div style={{ width: itemWidth, height: itemWidth }} className='person_inventar_blank_item' />
    }
    StarsItem = () => {
        const { itemWidth } = this.state;
        const { iamuser } = this.props;
        const { openOperationModal } = this;
        const amount = (iamuser && iamuser.myResourses && iamuser.myResourses.Money && iamuser.myResourses.Money.is2) ? iamuser.myResourses.Money.is2.all : 0;
        return <div onClick={openOperationModal} style={{ width: itemWidth, height: itemWidth }} className='person_inventar_blank_item' >
            <div className='person_data_moneystatus' style={{ width: itemWidth * 0.7, height: itemWidth * 0.7 }}>
                <img src={`/../images/ver2/scores/money/is2.png`} />
                <div className='person_data_moneystatus_amount'>{amount}</div>
            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile, showInfoModal')(PersonInventar);