// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { gsap } from 'gsap';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest211 extends PureComponent {
    state = {
        isOver: false,
        x: 0.1,
        y: 0.1,
        clickedX: 0.1,
        clickedY: 0.1,
        crX: 0.6,
        crY: 0.65,
        crL: 0.0
    }

    radius = 0.17;
    crradius = 0.04;
    timer = null;

    init = () => {
        this.setState({ isOver: true })
    }

    send = () => {
        this.props.sendSpecial({ data: { '1111': 1 } })
        this.init();
    }

    timerFunc = () => {
        const { x, y, clickedX, clickedY, crX, crY, isOver } = this.state;

        if (isOver) return;

        const xd = Math.abs ( clickedX - x );
        const yd = Math.abs ( clickedY - y);
        const hip = xd*xd + yd*yd;

        const xcrd = Math.abs ( crX - x );
        const ycrd = Math.abs ( crY - y );
        const crhip = xcrd*xcrd + ycrd*ycrd;

        if ( crhip <= 0.0125 ) {
            let crL = this.state.crL;
            if (crL < 1) {
                crL += 0.001;
            }
            if (crL >= 0.99) {
                this.send();
                this.setState({ isOver: true});
                return;
            }
            this.setState( { crL });
        } else {
            let crL = this.state.crL;
            if (crL >= 0.002) {
                crL -= 0.002;
            } else {
                crL = 0;
            }
            this.setState( { crL });       
        }

        if ( hip >= 0.001 ) {
            const xSpeed = 0.0015 * ( xd*xd / hip );
            const ySpeed = 0.0015 * ( yd*yd / hip );
            let newX, newY;
            if (x < clickedX) newX = x + xSpeed; else newX = x - xSpeed;
            if (y < clickedY) newY = y + ySpeed; else newY = y - ySpeed;

            this.setState({ x : newX, y : newY })
        }

    }

    componentDidMount = () => {
        this.timer = setInterval ( this.timerFunc, 10 );
    }
    componentWillUnmount = () => {
        clearInterval( this.timer );
        this.timer = null;
        delete this.timer;
    }

    onClick = (e) => {
        const main = document.getElementsByClassName('quest211_container');
        const element = main[0].getBoundingClientRect();

        const x = e.clientX - element.left;
        const y = e.clientY - element.top;

        const { screenWidth } = this.props;
        const screenHeight = screenWidth * 0.5;

        this.setState({
            clickedX: x / screenWidth,
            clickedY: y / screenHeight
        })

    }

    render() {
        const className = 'quest211_container';
        const { screenWidth } = this.props;
        const screenHeight = screenWidth * 0.5;

        const { x, y, crL, crX, crY } = this.state;
        const { radius, crradius } = this;

        const style = {
            width: screenWidth,
            height: screenHeight
        }

        const lightStyle = {
            top: screenHeight * y,
            left: screenWidth * x,
            width: screenWidth*radius,
            height: screenWidth*radius
        }

        const crystallStyle = {
            top: screenHeight * crY,
            left: screenWidth * crX,
            width: screenWidth*crradius,
            height: screenWidth*crradius,
            opacity: crL
        }
        
        return <div onClick={this.onClick} style = {style} className={className}>
            <div style = {lightStyle} className='quest211_container_light'/>
            <div style = {crystallStyle} className='quest211_container_light quest211_container_light2'/>
        </div>

        // const AAA =
        //     { "correctAnswers": [
        //         { "correctAnswer": { "22433": 1 }, "Message": "Сфера жужжит!", "moveTo": { "type": "point", "pointId": 209 } },
        //         { "correctAnswer": { "42124": 1 }, "Message": "Сфера жужжит!", "moveTo": { "type": "point", "pointId": 208 } }
        //     ] }
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest211);