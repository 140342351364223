import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import EventWrapper from 'src/ver2/components/event/eventWrapper';

import Loader from 'src/ver2/components/loader';
import { Statistics } from './statistics';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventStatisticsPlashka
// classname: event_statistics_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventStatisticsPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    setHeight = (height) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height, plashkaId });
    }

    render() {

        const { Event, upperStyle, client, isMobile } = this.props;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_statistics_plashka'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='event_statistics_plashka'>
            <Statistics client = {client} event = {Event} isMobile = {isMobile} setHeight = {this.setHeight} /> 
        </div>
    }

}

const EventStatisticsPlashkaWrapped = (props) => <EventWrapper Wrapped={EventStatisticsPlashka} eventId={props.eventId} componentProps={{ ...props }} />
export default withCapoeiraSportState('client, isMobile')(EventStatisticsPlashkaWrapped);