import React, { Component } from 'react';
//import logo from './logo.svg';

import './style.css';
import { Header } from '../../components/sgHeader';
import { ShaduleEdit } from './components/edit';
import { withCRMState } from '../../context';

import { Modal } from 'antd';

class Shadule extends Component {

    /**
     * props
     * subgroups
     * shadule
     */

    constructor(props) {
        super(props);

        this.shaduleHeight = 600;
        this.shaduleWidth = 700;

        // тут мы вводим разные штуки для расписания
        this.state = {
            selectedSubgroup: undefined
        };

    }

    componentDidMount() {

        const { shadule } = this.props;
        this.createShaduleState(shadule);
    }

    createShaduleState = (shadule) => {

        let firstHour = 30;
        let lastHour = 0;

        for (let i = 0; i < shadule.length; i++) {

            const { timeStart, timeEnd } = shadule[i];

            const startHour_ = Number(timeStart.slice(0, 2));
            //const startMinutes = Number(timeStart.slice(3));

            const endHour = Number(timeEnd.slice(0, 2));
            const endMinutes = Number(timeEnd.slice(3));

            if (firstHour > startHour_) firstHour = startHour_;
            if (lastHour < endHour) {
                lastHour = endHour;
                if (endMinutes > 0) lastHour++;
            }

        }

        const startHour = Math.min(11, firstHour);//firstHour;
        const range = lastHour - startHour;
        const oneHourHeight = this.shaduleHeight / range;

        /*let shaduleState = {

            range: 0, // рендж между самой первой треной и последней в часах
            startHour: 0, // самый первый час
            oneHourHeight: 0, // высота одного часа

        }*/

        let shaduleState = {
            range,
            startHour,
            oneHourHeight
        }

        this.setState({ shaduleState });

    }

    shaduleUpdate = (shaduleSaveObject) => {

        if (!shaduleSaveObject || shaduleSaveObject.length === 0) this.setState(this.setState({ selectedSubgroup: undefined }));

        this.props.shaduleUpdate(shaduleSaveObject);
        this.setState(this.setState({ selectedSubgroup: undefined }));

    }

    render() {

        const { subgroups, shadule } = this.props;
        const { selectedSubgroup } = this.state;

        return <div className='shadule-main'>
            <Modal footer={null} title={'Настроить расписание'} style={{ width: 'fit-content' }} onCancel={() => { this.setState({ selectedSubgroup: undefined }) }} visible={selectedSubgroup !== undefined} >
               {selectedSubgroup !== undefined ? <ShaduleEdit save={this.shaduleUpdate} shadule={shadule} subgroup={subgroups[selectedSubgroup]} selectedSubgroup={selectedSubgroup} /> : null }
            </Modal>
            <this.SubgroupsColumn />
            <this.ShaduleMain />
        </div>
    }

    SubgroupsColumn = () => {

        const { subgroups } = this.props;
        return <div className='shadule-left-container'>
            {
                subgroups.map((subgroup, index_) => {
                    const index = index_ + 1;

                    const { settingsAsObj } = subgroup;
                    if ( settingsAsObj && settingsAsObj.notActive) return null;

                    return <div className='shadule-left-container-header' onClick={() => { this.setState({ selectedSubgroup: index_ }) }}>
                        <Header {...{ subgroup, index }} />
                    </div>
                })
            }
        </div>
    }

    ShaduleMain = () => {
        return <div className='shadule-right-container'>
            <this.ShaduleMainHeader />
            <this.ShaduleMainHoursColumn />
            <this.ShaduleMainTable />
        </div>
    }

    // тут у нас просто сверху пн-вс
    ShaduleMainHeader = () => {

        const days = [
            'ПН',
            'ВТ',
            'СР',
            'ЧТ',
            'ПТ',
            'СБ',
            'ВС',
        ]

        return <div className='shadule-right-container-header' style={{ width: this.shaduleWidth }}>
            {days.map((day, index) => {

                return <div key={'srchd' + index} className='shadule-right-container-header-day'>
                    <p>
                        {day}
                    </p>
                </div>

            })}
        </div>
    }

    ShaduleMainHoursColumn = () => {

        const { shaduleState } = this.state;
        if (!shaduleState) return null;
        const {
            range,
            startHour,
            oneHourHeight
        } = shaduleState;

        let hours = [];
        for (let i = 0; i < range + 1; i++) {

            const hourNumber = startHour + i;
            const hourString = hourNumber < 10 ? `0${hourNumber}` : `${hourNumber}`;

            hours.push(`${hourString}:00`)
        }

        return <div className='shadule-right-container-hours'>
            {hours.map((hour, index) => {

                const style = {
                    top: oneHourHeight * index
                }

                return <div key={'srchh' + index} className='shadule-right-container-hours-hour' style={style}>
                    {hour}
                </div>
            })}
        </div>
    }

    ShaduleMainTable = () => {

        const { shaduleState } = this.state;
        if (!shaduleState) return null;
        const {
            range,
            oneHourHeight,
            startHour
        } = shaduleState;

        let row = [];
        let column = [];

        for (let i = 0; i < range - 1; i++) {
            row.push(1)
        }

        for (let i = 0; i < 7; i++) {
            column.push(1)
        }

        const { shadule, subgroups } = this.props;

        let subgroups_styles = [];
        for (let i = 0; i < shadule.length; i++) {


            const { timeStart, timeEnd, day, subgroupId } = shadule[i];
            const subgroup = subgroups.find(x => x.id === subgroupId) || {};
            const { color, settingsAsObj } = subgroup;
            if ( settingsAsObj && settingsAsObj.notActive) continue;

            const startHour_ = Number(timeStart.slice(0, 2));
            const startMinutes = Number(timeStart.slice(3));

            const endHour = Number(timeEnd.slice(0, 2));
            const endMinutes = Number(timeEnd.slice(3));

            const hoursLength = ((endHour * 60 + endMinutes) - (startHour_ * 60 + startMinutes)) / 60;
            const hourTop = ((startHour_ * 60 + startMinutes) - startHour * 60) / 60;

            subgroups_styles.push({
                width: this.shaduleWidth / 7,
                height: oneHourHeight * hoursLength,
                top: oneHourHeight * hourTop,
                left: (day - 1) * this.shaduleWidth / 7,
                background: color[0] === "#" ? color : `#${color}`,
                timeStart,
                timeEnd
            })
        }

        const rowStyle = {
            width: this.shaduleWidth,
            height: oneHourHeight
        }

        const columnStyle = {
            width: this.shaduleWidth / 7,
            height: this.shaduleHeight
        }

        return <div className='shadule-right-container-table'>
            {row.map((oneRow, index) => {
                return <div style={{ top: index * oneHourHeight, ...rowStyle }} key={'srctr-r-' + index} className='shadule-right-container-table-row' />
            })}
            {column.map((oneRow, index) => {
                return <div style={{ left: index * columnStyle.width, ...columnStyle }} key={'srctr-c-' + index} className='shadule-right-container-table-column' />
            })}
            {subgroups_styles.map((subgroups_style, index) => {

                const { timeStart, timeEnd } = subgroups_style;

                return <div style={subgroups_style} key={'srctr-sg-' + index} className='shadule-right-container-table-supgroup'>
                    <p> {timeStart} </p>
                    <p> - </p>
                    <p> {timeEnd} </p>
                </div>
            })}
        </div>
    }

}

export default withCRMState(
    `
        client,
    
        subgroups,
        shadule,

        shaduleUpdate
    `
)(Shadule)