import React from 'react';
import ExerciseScheme from 'src/ver2/components/exercise/scheme';
import { SidesIcon, TimerIcon } from 'src/ver2/components/ui/icons'

import './styles.css';

const bigFontSize = (schemeHeight) => schemeHeight * 0.7;
const littleFontSize = (schemeHeight) => schemeHeight * 0.55;

const ComboSchemeComponentSides = ({ oneSide }) => <div className={`combo_scheme_component_main_sides ${oneSide ? 'combo_scheme_component_main_sides_one' : ''}`}>
    <SidesIcon />
</div>
const ComboSchemeComponentCount = ({ count, schemeHeight }) => <div className='combo_scheme_component_main_count'>
    <span style={{ fontSize: littleFontSize(schemeHeight) }}>x<span style={{ fontSize: bigFontSize(schemeHeight) }}>{count}</span></span>
</div>
const ComboSchemeComponentTimer = ({ time, schemeHeight }) => <div className='combo_scheme_component_main_count'>
    <TimerIcon />
    <span style={{ fontSize: bigFontSize(schemeHeight) }}>{time}<span style={{ fontSize: littleFontSize(schemeHeight) }}>s</span></span>
</div>

const CoomboSchemeComponent = ({ Body, BodyObj, schemeHeight = 50 }) => {
    const BodyObj_ = BodyObj || JSON.parse(Body);

    const { Exercises, AllTime, AllCount } = BodyObj_ || {};
    if (!Exercises) return null;

    return <div className='combo_scheme_component_main'>
        <div className='combo_scheme_component_scheme'>
            {Exercises.map(({ exerciseId }) => {
                return <ExerciseScheme height={schemeHeight} exerciseId={exerciseId} />;
            })}
        </div>
        <div className='combo_scheme_component_rules'>
            {Exercises.map(({ oneSide, count, time }) => {
                return <div className='combo_scheme_component_rules_row' style={{ height: schemeHeight }}>
                    {!!count && ComboSchemeComponentCount({ count, schemeHeight })}
                    {ComboSchemeComponentSides({ oneSide, schemeHeight })}
                    {!!time && ComboSchemeComponentTimer({ time, schemeHeight })}
                </div>
            })}
        </div>
        {(Exercises.length > 1) && <div className='combo_scheme_component_semicolon'>
            <div style={{ transform: `scaleY( ${Exercises.length} )` }}>
                <span style={{ fontSize: schemeHeight * 0.9 }}>
                    {`}`}
                </span>
            </div>
        </div>}
        <div className='combo_scheme_component_allpart'>
            {!!AllCount && ComboSchemeComponentCount({ count: AllCount, schemeHeight })}
            {!!AllTime && ComboSchemeComponentTimer({ time: AllTime, schemeHeight })}
        </div>
    </div>

}

export default CoomboSchemeComponent;