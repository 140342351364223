import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import VideoWrapper from 'src/ver2/components/video/videoWrapper';

import LittlePerson from 'src/ver2/components/person/little';
import LinkButton from 'src/ver2/components/ui/buttons/link'

import './style.css';

class VideoLittlePlashka extends PureComponent {

    // constructor(props) {
    //     super(props);

    // }

    render() {

        const { upperStyle, mode, onLinkClick, autoplay } = this.props;

        const { width, height } = upperStyle;

        const { Video } = this.props;

        if (!Video || !Video.Url) return <div className='video_little_plashka'>
            загрузка...
        </div>

        const { Title, Url, Id, red_user_id, blue_user_id } = Video;

        // ЧАСТЬ СТАЙЛА
        const padding = 10;
        const widthForElement = width - padding * 2;
        const heightForIframe = widthForElement * 9 / 16;

        const heightFroDiscription = height - heightForIframe - padding * 3;

        let linkStuff = {
            link: `/?videoId=${Id}`,
            style: {
                position: 'absolute',
                top: 10,
                left: 'calc(100% - 120px)'
            }
        }

        if (mode === 'trefatext') {
            linkStuff.type = 'button';
            linkStuff.onClick = () => onLinkClick( `/?videoId=${Id}` )
        }

        return <div className='video_little_plashka' style={{ width, height }}>
            <LinkButton {...linkStuff} />
            <div style={{ width: widthForElement, height: heightForIframe }} className='video_little_plashka_video'>
                <iframe
                    id={'video-iframe-' + Id}
                    title={Title}
                    width={widthForElement}
                    height={heightForIframe}
                    src={ autoplay ? (Url + '&autoplay=1&mute=1') : Url}
                    frameBorder="1"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    allowFullScreen>
                </iframe>
            </div>
            {mode === 'trefatext' ?
                <div style={{ width: widthForElement, height: heightFroDiscription }} className='video_little_plashka-discription'>
                    <LittlePerson mode='video_trefatext' onLinkClick = {onLinkClick} height={(heightFroDiscription - 2) / 2} personId={red_user_id} />
                    <LittlePerson mode='video_trefatext' onLinkClick = {onLinkClick} height={(heightFroDiscription - 2) / 2} personId={blue_user_id} />
                </div>
                :
                <div style={{ width: widthForElement, height: heightFroDiscription }} className='video_little_plashka-discription'>
                    <LittlePerson mode='categorys' height={(heightFroDiscription - 2) / 2} personId={red_user_id} />
                    <LittlePerson mode='categorys' height={(heightFroDiscription - 2) / 2} personId={blue_user_id} />
                </div>
            }
        </div>

    }

}

const VideoLittlePlashkaWrapped = (props) => <VideoWrapper Wrapped={VideoLittlePlashka} videoId={props.videoId} componentProps={{ ...props }} />
export default withCapoeiraSportState('client')(VideoLittlePlashkaWrapped);