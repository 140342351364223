import React, { PureComponent } from 'react';

import { withGroupState } from 'src/ver2/pages/group/context';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';
import { groupBy, orderBy } from 'lodash';

import StudentDataChanger from 'src/ver2/containers/studentDataChanger';

import Loader from 'src/ver2/components/loader';
import { Modal } from 'antd';
// import { Link } from 'react-router-dom';
import moment from 'moment';

import './style.css';

// class GroupStructurePlashka
// classname: group_structure_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

const FILTERS = {
    BY_CORDAO: 'кордао',
    BY_FKR: 'членство',
    BY_MEDALS: 'медали',
    BY_SPORT: 'разряды',
    BY_JUDGE: 'судьи',
    BY_RAITING: 'рейтинг'
}
const SEASON_FILTER = {
    ALL: 'всё время',
    SEASON20222023: '2023-2024',
}

class GroupStructurePlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showChangeStudentData: false,
            filter: FILTERS.BY_CORDAO,
            seasonFilter: SEASON_FILTER.ALL
        }
    }

    changeStudentData = (showChangeStudentData = true) => { this.setState({ showChangeStudentData }) }

    setHeight = () => {
        const { setComponentSelfHeight, plashkaId, Students } = this.props;
        if (!Students || !Students.length) return;

        const height = Math.max(200, 50 + Students.length * 52);

        setComponentSelfHeight({ height, plashkaId });
    }

    componentDidMount() {
        this.setHeight();
    }

    componentDidUpdate({ Students }) {

        const prevStudentLength = (Students && Students.length) ? Students.length : 0;
        const StudentLength = (this.props.Students && this.props.Students.length) ? this.props.Students.length : 0;

        if (prevStudentLength !== StudentLength)
            this.setHeight();
    }


    render() {

        const { Students, isStudentsReady, upperStyle, updateStudent } = this.props;
        const { width, height } = upperStyle;
        const { showChangeStudentData, StudentId, studentData } = this.state;

        if (!isStudentsReady || !Students) return <div style={{ width, height }} className='group_structure_plashka'>
            <Loader />
        </div>

        const onChangeData = async (data) => {
            updateStudent(StudentId, data);
        }

        return <div style={{ width, height }} className='group_structure_plashka'>
            <this.Students />
            <Modal
                className='group_structure_plashka_modal'
                visible={showChangeStudentData}
                onCancel={() => { this.changeStudentData(false) }}
                footer={null}
                title={'Редактировать данные студента'}
            // width={1200}
            >
                {(showChangeStudentData && StudentId) ? <StudentDataChanger studentData={studentData} onChangeData={onChangeData} /> : null}
            </Modal>
        </div>
    }

    Students = () => {

        const { Group, Students, upperStyle, client, iAmCreator, iAmInstructor, isMobile } = this.props;
        const { width } = upperStyle;
        const { filter } = this.state;

        let Answer = [];
        // если по кордао

        if (filter === FILTERS.BY_CORDAO) {
            const { SchoolId } = Group;
            const allCordaos = Object.keys(client.getSchoolGradation(SchoolId)).reverse();

            const StudentsByCordao = groupBy(Students, 'Cordao');

            for (let j = 0; j < allCordaos.length; j++) {
                const cordao = allCordaos[j];
                if (StudentsByCordao[cordao]) {
                    for (let i = 0; i < StudentsByCordao[cordao].length; i++) {
                        let Student = { ...StudentsByCordao[cordao][i] }
                        Answer.push(
                            Student
                        )
                    }
                }
            }
        }

        if (filter === FILTERS.BY_FKR) {
            Answer = Students.filter(x => (x.ServedTo && moment(x.ServedTo, 'YYYY.MM.DDTHH:mm').isAfter(moment('02.09.2023', 'DD.MM.YYYY'))))
            Answer = orderBy(Answer, 'Surname', 'asc');
        }

        if (filter === FILTERS.BY_MEDALS) {
            const { seasonFilter } = this.state;
            if (seasonFilter === SEASON_FILTER.ALL) {
                Answer = Students.filter(x => (x.Gold || x.Silver || x.Bronze));
                Answer = orderBy(Answer, 'Bronze', 'desc');
                Answer = orderBy(Answer, 'Silver', 'desc');
                Answer = orderBy(Answer, 'Gold', 'desc');
            }
            if (seasonFilter === SEASON_FILTER.SEASON20222023) {
                Answer = Students.map(student => ({ ...student, Gold: student.GoldThisSeason, Silver: student.SilverThisSeason, Bronze: student.BronzeThisSeason }))
                Answer = Answer.filter(x => (x.Gold || x.Silver || x.Bronze));
                Answer = orderBy(Answer, 'Bronze', 'desc');
                Answer = orderBy(Answer, 'Silver', 'desc');
                Answer = orderBy(Answer, 'Gold', 'desc');
            }
        }
        if (filter === FILTERS.BY_SPORT) {
            const { client } = this.props;
            const sportRanks = Object.keys(client.sportRanks);
            Answer = Students.filter(x => (x.SportRank))
            Answer = Answer.sort((a, b) => { return sportRanks.indexOf(b.SportRank) - sportRanks.indexOf(a.SportRank) })
        }
        if (filter === FILTERS.BY_JUDGE) {
            const judgeRanks = {
                '3': 1,
                '2': 2,
                '1': 3,
                'vseross': 4
              }

            Answer = Students.filter(x => (x.JudgeRank))
            Answer = Answer.sort((a, b) => { return judgeRanks[b.JudgeRank] - judgeRanks[a.JudgeRank] })
        }
        if (filter === FILTERS.BY_RAITING) {
            Answer = Students.filter(x => (x.NowProPlace || x.NowNotProPlace));
            Answer = Answer.sort((a, b) => {

                let ATakePro = 1;
                if (a.NowProPlace < 1) ATakePro = 0;

                if (a.NowProPlace >= 1 && a.NowNotProPlace >= 1) {
                    if ((a.NowNotProPlace < a.NowProPlace)) ATakePro = 0;
                }

                let ANowPlace = a.NowProPlace;
                if (!ATakePro) {
                    ANowPlace = a.NowNotProPlace;
                }

                let BTakePro = 1;
                if (b.NowProPlace < 1) BTakePro = 0;

                if (b.NowProPlace >= 1 && b.NowNotProPlace >= 1) {
                    if ((b.NowNotProPlace < b.NowProPlace)) BTakePro = 0;
                }

                let BNowPlace = b.NowProPlace;
                if (!BTakePro) {
                    BNowPlace = b.NowNotProPlace;
                }

                return ANowPlace - BNowPlace;
            })
        }

        const changeStudent = (StudentId, isDelete) => {

            if (isDelete) {
                const { deleteStudent } = this.props;
                const studentData = Students.find(x => x.Id === StudentId);
                if (studentData)
                    Modal.warning({
                        className: 'event_participation_decline_modal',
                        title: 'Удалить ученика из группы',
                        content: `Вы уверены, что хотите удалить ученика ${studentData.Surname} ${studentData.Name} из группы?`,
                        onOk: () => { deleteStudent(StudentId) },
                        onCancel: () => { },
                        okText: 'Удалить',
                        cancelText: 'Не удалять',
                        okCancel: true,
                        closable: true,
                        centered: true
                    })
                return;
            }


            const studentData = Students.find(x => x.Id === StudentId);
            if (studentData)
                this.setState({
                    StudentId,
                    studentData,
                    showChangeStudentData: true
                })
        }

        return <div className='group_structure_plashka_students'>
            <this.Filter />
            <this.SeasonFilter />
            <TrefaLayout greedsX={[12, width - 24, 12]}
                components={
                    [
                        {
                            component: PersonsLittleListPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 200,
                            componentProps: {
                                height: Answer.length * 50,
                                Persons: Answer,
                                autoSize: false,
                                noMaxPersons: false,
                                personsDirect: true,
                                loadCount: isMobile ? 20 : 50,
                                studentsRankRace: filter === FILTERS.BY_RAITING,
                                mode: 'students',
                                onStudentChange: (iAmCreator || iAmInstructor) ? changeStudent : null
                            }
                        }
                    ]
                }
            />
        </div>
    }

    Filter = () => {

        const { filter } = this.state;

        return <div className='group_structure_plashka_filter'>
            {Object.values(FILTERS).map(filterName => {
                return <div key={`gspf_${filterName}`}
                    className={'group_structure_plashka_filter_one ' + ((filter === filterName) ? ' group_structure_plashka_filter_one_selected' : '')}
                    onClick={() => { this.setState({ filter: filterName }) }}
                >
                    {filterName}
                </div>
            })}
        </div>
    }

    SeasonFilter = () => {

        const { filter, seasonFilter } = this.state;
        if (filter !== FILTERS.BY_MEDALS) return null;

        return <div className='group_structure_plashka_filter'>
            {Object.values(SEASON_FILTER).map(filterName => {
                return <div key={`gspff_${filterName}`}
                    className={'group_structure_plashka_filter_one ' + ((seasonFilter === filterName) ? ' group_structure_plashka_filter_one_selected' : '')}
                    onClick={() => { this.setState({ seasonFilter: filterName }) }}
                >
                    {filterName}
                </div>
            })}
        </div>
    }

}

export default withGroupState(`

Group,

Students,
isStudentsReady,

iAmCreator,
iAmInstructor,

updateStudent,
deleteStudent,

client,
iamuser,
isMobile
`)(GroupStructurePlashka)