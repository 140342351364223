import React, { Component } from 'react';

import { Avatar, Modal, Button, Input, Tabs, Radio } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { clone, orderBy } from 'lodash';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

import PersonAvatar from 'src/ver2/components/person/avatar';

import {
  kostylDouble,
  couplesInCircle,
  addBlankNotCircleHistory,
  addBlankCircleHistory,
  setCompetitorsThrueBrikets,
  getElderCircleByCompetitorsNumber,
  getBlockIndexAndSizeByCircle
} from './helpers';

import { SetTimeStamp, TrefaTimer, SetWinTo } from './helpers/components';

import {
  returnStrickSticks, returnUpperSticks, returnBottomSticks, returnShiftStrickSticks,
  placesDraw, placesDrawCircle
} from './helpers/draw';

import { sendPlacesToServer } from './helpers/send';

import './component.css';

import axios from 'axios';

const TabPane = Tabs.TabPane;

const MaleCircle = [<div>

  Категория проходит в два этапа: Групповой этап и Плей-Офф.
  <br />
  Спортсмены будут разделены случайным образом на 4 Группы по 5 и 6 человек, каждая группа сыграет круговые поединки.
  <br /><br />
  Из каждой группы выходит по 4 человека и по системе "сильный-слабый" заполняют сетку Плей-Офф, которая пройдет по олимпийской
  системе и начнется с 1/8 финала.
  <br /><br />
  Жеребьевка групп состоится 5 апреля, после чего будут точно известны время выступления каждой группы.
  <br /><br />
  Участники категории:

</div>
]


const FemaleCircle = [<div>

  Категория проходит в два этапа: Групповой этап и Плей-Офф.
  <br />
  Спортсменки будут разделены случайным образом на 4 Группы по 3 и 4 человека, каждая группа сыграет круговые поединки.
  <br /><br />
  Из каждой группы выходит по 2 человека и по системе "сильный-слабый" заполняют сетку Плей-Офф, которая пройдет по олимпийской
  системе и начнется с 1/4 финала.
  <br /><br />
  Жеребьевка групп состоится 5 апреля, после чего будут точно известны время выступления каждой группы.
  <br /><br />
  Участницы категории:

</div>
]


/* 
    КАК ЭТА ВСЯ ХУЕТА ВООБЩЕ РАБОТАЕТ!

    приходит 
    selectedCategory (это по сути просто id)
    category (а это весь объкт категории)

    Есть основная функция getCompetitors, которая вызывается раз в какое-то время
    её задача - выдать story, главный кирпичик всего дела

*/





export class Brikets extends Component {

  constructor(props) {
    super(props);

    var all_height = 500;

    this.couplesInCircle = couplesInCircle;

    const { category } = props;
    const { TimeStart, Name } = category;

    this.state = {
      story: [],
      competitors: [],
      modalVideoSelected: 0,
      videoUrl: '',
      videoTitle: '',
      selectedPersonId: -1,
      IsVideo1Ch: '',
      IsVideo2Ch: '',
      IsScores1Ch: '',
      IsScores2Ch: '',
      TimeStartValue: TimeStart,
      NameValue: Name,
      videoTimeStamp: 0,
      inquirysIds: {},

      judgeKey: '3'
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {

      this.state.mobile = true;

    } else this.state.mobile = false;

    this.placesDraw = placesDraw.bind(this);
    this.placesDrawCircle = placesDrawCircle.bind(this);

    this.getCompetitors(props.selectedCategory);
    this.getNowNext(props.selectedCategory);
    this.MyTimer = setInterval(this.timerReload, 5000);
  }

  componentWillUnmount() {
    this.setState({ modalVideoSelected: 0 });
    clearTimeout(this.MyTimer);
  }

  componentDidMount = () => {

  }

  timerReload = async () => {

    if (!this.props || !this.props.category) return;
    const { selectedCategory } = this.props;

    if (selectedCategory !== null) {
      this.getCompetitors(selectedCategory);
      this.getNowNext(selectedCategory);
    }
  }

  async getNowNext(selectedCategory) {
    const { client, category, EventId } = this.props;
    const { FieldId } = category;
    const fields = await client.getFields();
    const ourField = fields.find(x => x.FieldId === FieldId && x.EventId === EventId);
    if (ourField) {
      this.setState({
        YouTubeKey: ourField.YouTubeKey,
        nowCouple: {
          couple_id: ourField.NowCoupleId,
          circle_id: ourField.NowCircleId,
          category_id: ourField.NowCategoryId,
        },
        nextCouple: {
          couple_id: ourField.NextCoupleId,
          circle_id: ourField.NextCircleId,
          category_id: ourField.NextCategoryId,
        }
      })
    }
  }

  // это основная функция, которая по таймеру вызывается и обновляет всё состояние и рисовку
  async getCompetitors(selectedCategory) {

    const { competitionId, category, client } = this.props;

    if (selectedCategory === null || !category) return;

    /* 
      competitors_first- это просто список тех, кто в данной категории
      а competitors - это дополненный список, забитый пустыми жребиями
    */
    if (!category.ElderCircle) {
      if (!this.state.competitors_first) {
        const competitors_first = await client.briketsFirstCompetitiors({ competitionId, selectedCategory })
        this.setState({ competitors_first });
      }
      return;
    }

    // что нам надо на выходе получить
    let story = []; // мы созда1м свой объект story, он чуть отличается от того, что в БД
    let competitors_first = []; // это изначально все компетиторы, которые в данной категории (с их жребиями)
    let competitors = []; // а это competitors_first плюс "пустые" жребии, которых не достаёт
    let scores = {}; // это нужно только для КРУГОВОЙ темы - это очки, набранные компетиторами
    let wins = {};
    let progress = 0;

    let CategoryIsDone = 0
    let CouplesClosed = 0;
    let CouplesShouldBe = 0;


    let story_ = await client.briketsGetStory({ competitionId, selectedCategory });
    competitors_first = await client.briketsFirstCompetitiors({ competitionId, selectedCategory });
    competitors = clone(competitors_first);

    // если это НЕ КРУГОВАЯ
    if (!category.IsCircled) {
      story = addBlankNotCircleHistory({ category, story_ })
      const setCompetitorsThrueBrikets__ = setCompetitorsThrueBrikets({ competitors, category, story });
      competitors = setCompetitorsThrueBrikets__.competitors;
      story = setCompetitorsThrueBrikets__.story;
      CategoryIsDone = setCompetitorsThrueBrikets__.CategoryIsDone;
      CouplesClosed = setCompetitorsThrueBrikets__.CouplesClosed;
      CouplesShouldBe = setCompetitorsThrueBrikets__.CouplesShouldBe;
    }

    // КРУГОВАЯ
    if (category.IsCircled) {
      if (competitors && competitors.length > 0 && story_) {
        const addBlankCircleHistory_ = addBlankCircleHistory({ category, story_, competitors });
        story = addBlankCircleHistory_.story;
        scores = addBlankCircleHistory_.scores;
        wins = addBlankCircleHistory_.wins;
        CategoryIsDone = addBlankCircleHistory_.CategoryIsDone;
        CouplesClosed = addBlankCircleHistory_.CouplesClosed;
        CouplesShouldBe = addBlankCircleHistory_.CouplesShouldBe;
      }

    }

    let inquirysIds = {};
    for (let i = 0; i < story.length; i++) {
      if (story[i].IsInquiry) {
        inquirysIds[story[i].Red_Id] = 1;
        inquirysIds[story[i].Blue_Id] = 1;
      }
    }

    progress = CouplesClosed / CouplesShouldBe;

    // console.log({ progress, CategoryIsDone })

    const { judgeMode } = this.props;
    if (judgeMode)
      client.updateCategoryProgress(competitionId, selectedCategory, progress, CategoryIsDone);

    return this.setState({ story, competitors, scores, wins, inquirysIds, competitors_first, progress, CategoryIsDone, CouplesClosed, CouplesShouldBe });
  }

  setNextNow = async (isNow, circle_id, couple_id, red_id, blue_id) => {
    const { selectedCategory, judgeMode, EventId, category } = this.props;
    if (!judgeMode) return;

    const { client } = this.props;
    const { FieldId } = category;

    await client.updateField(EventId, FieldId, isNow, selectedCategory, couple_id, circle_id, red_id, blue_id);
    this.getNowNext(selectedCategory);

  }

  setInquiry = async (circle_id, couple_id, inquiry) => {
    const { competitionId, selectedCategory, judgeMode } = this.props;
    const { judgeKey } = this.state;

    if (!judgeMode) return;
    if (judgeKey !== '3' && judgeKey !== '2') return;

    const uri = 'https://' + this.props.ServerUrl + '/setinquiry?competition_id=' + competitionId
      + '&category_id=' + selectedCategory
      + '&circle_id=' + circle_id
      + '&couple_id=' + couple_id
      + '&inquiry=' + inquiry;

    try {
      await axios.get(uri);
    }
    catch (err) {
    }

    this.timerReload()
  }

  setWinner = async (circle_id, couple_id, winto, WinTo1, WinTo2, Jrebiy) => {
    const { competitionId, selectedCategory, judgeMode, category } = this.props;
    const { judgeKey } = this.state;

    // пока закрываем это дело
    //return;

    if (!judgeMode) return;
    if (judgeKey !== '3' && judgeKey !== '2') return;

    if (!selectedCategory) return;

    // это мы в жеребьёвке тыкнули
    if (judgeKey === '2') {

      const { jrebiySelectedNumber } = this.state;
      // если тыкнули в того же
      if (jrebiySelectedNumber === Jrebiy) {
        return this.setState({ jrebiySelectedNumber: null })
      }

      // если тыкнули в первый раз
      if (!jrebiySelectedNumber) {
        return this.setState({ jrebiySelectedNumber: Jrebiy });
      }

      // если тыкнули куда-то, то меняем жребии местами
      const { client } = this.props;
      const { competitors_first } = this.state;
      // ищем компетитора с первым жребием
      const competitorFirst = competitors_first.find(x => x.Jrebiy === jrebiySelectedNumber);
      const competitorSecond = competitors_first.find(x => x.Jrebiy === Jrebiy);

      if (competitorFirst)
        await client.updateCompetitor(competitionId, competitorFirst.Id, undefined, Jrebiy);
      if (competitorSecond)
        await client.updateCompetitor(competitionId, competitorSecond.Id, undefined, jrebiySelectedNumber);

      this.setState({ jrebiySelectedNumber: null });

      this.timerReload();

      return;
    }

    // а если тут - то это мы уже непосредственно 


    let winto_ = winto;

    if ((WinTo1 && winto === 1) || (WinTo2 && winto === 2)) {
      winto_ = 0;
    }

    let title = '';
    if (winto_ === 0) title = 'Снять победу?';
    if (winto_ === 1) title = 'Победил красный?';
    if (winto_ === 2) title = 'Победил синий?';

    // обнуляем выбиралку
    this.setState({ selectedWin: 0 });

    Modal.confirm({
      className: 'modal_judging_confirm',
      title,
      okText: 'Да',
      cancelText: 'Нет',
      content: (category.IsCircled && winto_)
        ?
        <SetWinTo setSelectedWin={this.setSelectedWin} />
        :
        null,
      onOk: async () => {

        let realWinTo = winto_;
        if (category.IsCircled && winto_) {
          if (this.state.selectedWin) {
            realWinTo += 2;
          }
        }

        const uri = 'https://' + this.props.ServerUrl + '/sethistory?competition_id=' + competitionId
          + '&category_id=' + selectedCategory
          + '&circle_id=' + circle_id
          + '&couple_id=' + couple_id
          + '&winto=' + realWinTo;

        try {
          await axios.get(uri);
        }
        catch (err) {
        }

        this.timerReload()
      }
    })

  }

  setSelectedWin = (selectedWin) => {
    console.log('МЕНЯЕМ')
    this.setState({ selectedWin });
  }

  setVideoTimeStamp = (videoTimeStamp) => {
    this.setState({ videoTimeStamp });
  }

  setAlertMode = (alertMode) => {
    this.setState({ alertMode })
  }

  clickOnSetVideo = (circle_id, couple_id, title, PersonId1, PersonId2) => {
    Modal.confirm({
      className: 'modal_videostamp_confirm',
      title: 'Видео TimeStamp',
      okText: 'Отправить',
      cancelText: 'Нет',
      content: <SetTimeStamp setVideoTimeStamp={this.setVideoTimeStamp} />,
      onOk: async () => {


        const { competitionId, selectedCategory, client, EventId } = this.props;

        const uri = 'https://' + this.props.ServerUrl + '/setvideohistory?competition_id=' + competitionId
          + '&category_id=' + selectedCategory
          + '&circle_id=' + circle_id
          + '&couple_id=' + couple_id
          + '&title=' + title
          + '&timestart=' + this.state.videoTimeStamp;

        let result = null;

        try {
          result = await axios.get(uri);
        }
        catch (err) {
        }

        if (result && result.data && result.data.VideoId) {
          const uri = 'https://' + this.props.ServerUrl + '/setvideousers?videoId=' + result.data.VideoId
            + '&redUserId=' + PersonId1
            + '&blueUserId=' + PersonId2
            + '&categoryId=' + selectedCategory
            + '&title=' + title
            + '&YouTubeKey=' + this.state.YouTubeKey;
          try {
            await axios.get(uri);
          }
          catch (err) {
          }

          client.addNotification({
            UserId: PersonId1,
            Body: `^a_^text^a_^У вас размечено новое видео в мероприятии ^e_${EventId}^. ^n_^ Вы можете увидеть его у вас на странице или в сетках мероприятия`
          })

          client.addNotification({
            UserId: PersonId2,
            Body: `^a_^text^a_^У вас размечено новое видео в мероприятии ^e_${EventId}^. ^n_^ Вы можете увидеть его у вас на странице или в сетках мероприятия`
          })

        }

        // мы записали видео, теперь его надо сразу же обновить
        //setvideousers
        // videoId, redUserId, blueUserId, categoryId, title, YouTubeKey

        this.timerReload()
      }
    })
  }

  clickOnVideo(videoId) {
    const { client, showInfoModal } = this.props;
    showInfoModal({ type: 'video', videoId });
  }

  /////////////////////////////////////
  //////////////////////////////////////
  // РИСОВАНИЕ /////////////////////////
  /////////////////////////////////////
  ////////////////////////////////////

  render() {

    const { selectedCategory, category, judgeMode } = this.props;
    const { alertMode } = this.state;

    if (!selectedCategory || !category) return <div className='categorys-div'>
      укажите категорию
    </div>

    let className = 'categorys-div ';
    if (alertMode === 'orange') {
      className += ' categorys-div_orangealert';
    }
    if (alertMode === 'red') {
      className += ' categorys-div_redalert';
    }

    return <div className={className}>
      <this.VideoModal />
      <this.SoloVideoModal />
      <br /><br /><br />
      {judgeMode ? <this.JudgePanel /> : null}
      <this.MainDraw />
      <br /><br /><br /><br />
    </div>
  }

  MainDraw = () => {

    const { category } = this.props;
    const { IsCircled, IsDouble } = category;

    if (IsCircled)
      return <this.DrawFullCircleCategory />

    if (IsDouble)
      return <this.DrawFullDoubleCategory />

    // ЕСЛИ НЕ КРУГОВАЯ
    return <this.DrawFullOlympicCategory />
  }

  DrawFullOlympicCategory = () => {

    const { category } = this.props;
    const { ElderCircle } = category;

    this.all_height = this.couplesInCircle(ElderCircle) * 100;
    let isDouble = (kostylDouble(category.Id)) ? true : false;

    if (this.all_height < 500) this.all_height = 500;

    let Circles = [];
    for (let i = ElderCircle; i > isDouble; i--) {
      Circles.push(<this.DrawOneOlympicCircle key={'dowc' + i} circle_id={i} ElderCircle={ElderCircle} />);
    }

    if (isDouble) {
      let CirclesLowerBrikets = [];
      CirclesLowerBrikets.push(this.returnLowBirketCircle(1));
      CirclesLowerBrikets.push(this.returnLowBirketCircle(2));
      CirclesLowerBrikets.push(this.returnLowBirketCircle(3));
      CirclesLowerBrikets.push(this.returnLowBirketCircle(4));

      let CirclesLowerBriketsFinal = [];
      CirclesLowerBriketsFinal.push(this.returnLowBirketCircle(5));
      CirclesLowerBriketsFinal.push(this.returnLowBirketCircle(6));
      return <div className='flex-container'>
        <div className='double-elemenation-main-div' style={{ height: this.all_height * 2.5 + 60 }}>
          <p className='double-elemenation-main-p' >Верхняя сетка</p>
          <div className='all-field-inside-double' style={{ height: this.all_height + 30 }}>
            {Circles}
          </div>
          <p className='double-elemenation-main-p'>Нижняя сетка</p>
          <div className='all-field-inside-double' style={{ height: this.all_height * 0.7 + 30 }}>
            {CirclesLowerBrikets}
          </div>
          <p className='double-elemenation-main-p'>Финал</p>
          <div className='all-field-inside-double' style={{ height: this.all_height * 0.5 }}>
            {CirclesLowerBriketsFinal}
          </div>
        </div>
      </div>
    }

    return <div className='flex-container'>
      <div className='all-field' style={{ height: this.all_height + 30 }}>
        {Circles}
      </div>
    </div>
  }

  DrawFullDoubleCategory = () => {

    const { category } = this.props;
    const { ElderCircle } = category;

    this.all_height = this.couplesInCircle(ElderCircle) * 100;

    if (this.all_height < 500) this.all_height = 500;

    let Circles = [];
    for (let i = ElderCircle; i > 0; i--) {
      Circles.push(<this.DrawOneDoubleUpperCircle key={'dowc' + i} circle_id={i} ElderCircle={ElderCircle} />);
      if (i !== ElderCircle && i !== 1) {
        Circles.push(
          <this.DrawOneBlankDoubleUpperCircle key={'dowc-b-' + i} circle_id={i} />
        )
      }
      if (i === 1) {
        Circles.push(
          <this.DrawOneBlankDoubleUpperCircleFinal key={'dowc-b-' + i} circle_id={i} />
        )
      }
    }


    let CirclesLowerBrikets = [];
    const DoubleCircleId = ElderCircle + 1; // это откуда мы будем тащить всё это дело
    const separations = (ElderCircle * 2) - 1; // сколько разных кругов у нас будет в дабле

    let CircleFromId = ElderCircle - 1;
    let isEven = -1;

    for (let i = separations - 1; i >= 0; i--) {
      CirclesLowerBrikets.push(this.DrawLowBriketsCircle(i, DoubleCircleId, CircleFromId));
      isEven++;
      if (isEven === 1) {
        CircleFromId--;
        isEven = -1;
      }
    }

    // теперь надо заполнить круги low brikets


    // CirclesLowerBrikets.push ( this.returnLowBirketCircle(1));
    // CirclesLowerBrikets.push ( this.returnLowBirketCircle(2));
    // CirclesLowerBrikets.push ( this.returnLowBirketCircle(3));
    // CirclesLowerBrikets.push ( this.returnLowBirketCircle(4));

    // let CirclesLowerBriketsFinal = [];
    // CirclesLowerBriketsFinal.push ( this.returnLowBirketCircle(5));
    // CirclesLowerBriketsFinal.push ( this.returnLowBirketCircle(6));

    return <div className='flex-container'>

      <p className='double-elemenation-main-p' >Верхняя сетка</p>
      <div className='all-field' style={{ height: this.all_height + 30 }}>
        {Circles}
      </div>
      <p className='double-elemenation-main-p' >Нижняя сетка</p>
      <div className='all-field' style={{ height: this.all_height / 2 + 130 }}>
        {CirclesLowerBrikets}
      </div>
    </div>

    // return <div className = 'flex-container'>
    //   <div className = 'double-elemenation-main-div all-field' style = {{ height: this.all_height * 2.5 + 60}}>
    //     <p className = 'double-elemenation-main-p' >Верхняя сетка</p>
    //     <div className = 'all-field-inside-double all-field' style = {{ height: this.all_height + 30}}>        
    //         {Circles}
    //     </div>
    //     <p className = 'double-elemenation-main-p'>Нижняя сетка</p>
    //     <div className = 'all-field-inside-double all-field' style = {{ height: this.all_height * 0.7 + 30}}>        
    //         {CirclesLowerBrikets}
    //     </div>
    //   </div>
    // </div>

  }

  DrawFullCircleCategory = () => {
    const { story } = this.state;

    let Answer = [];
    Answer.push(<div style={{ color: 'white' }}> Участники: </div>);
    Answer.push(<br />);
    Answer.push(this.placesDrawCircle());
    Answer.push(<br />);
    Answer.push(<div style={{ color: 'white' }}> Схватки: </div>);
    Answer.push(<br />);

    for (let i = 0; i < story.length; i++) {
      Answer.push(this.returnCouple(story[i].Circle_id, story[i].Couple_id, true));
      Answer.push(<br />);
    }

    return <div className="div-for-circle">
      <div className="div-for-circle-test" >
        {Answer}
      </div>
    </div>
  }

  /// ИСХОДЯ ИЗ ID круга мы понимаем количество пар
  DrawOneOlympicCircle = ({ circle_id, ElderCircle }) => {

    let Couples = [];

    const { category } = this.props;

    let isDouble = (kostylDouble(category.Id)) ? true : false;

    for (let i = 0; i < this.couplesInCircle(circle_id); i++) {
      if (circle_id === 2 && isDouble)
        Couples.push(this.returnCouple(circle_id, i, true));
      else
        Couples.push(this.returnCouple(circle_id, i, ElderCircle));
    }


    return <div className="circle-div" style={{ height: this.all_height }}>
      {Couples}
    </div>
  }

  /// рисуем один верхний круг для дабла
  DrawOneDoubleUpperCircle = ({ circle_id, ElderCircle }) => {

    let Couples = [];

    for (let i = 0; i < this.couplesInCircle(circle_id); i++) {
      if (circle_id === 1)
        Couples.push(this.returnCouple(circle_id, i, true, null, true));
      else
        Couples.push(this.returnCouple(circle_id, i));
    }

    return <div className="circle-div" style={{ height: this.all_height }}>
      {Couples}
    </div>
  }

  /// рисуем пустое местов в верхней части для дабла (где идут прямые длинные линии)
  DrawOneBlankDoubleUpperCircle = ({ circle_id }) => {
    let Couples = [];
    let MyHeight = this.all_height / this.couplesInCircle(circle_id);
    for (let i = 0; i < this.couplesInCircle(circle_id); i++) {
      Couples.push(<div key={'bbbbb' + i} className="circle-couple-div" style={{ height: MyHeight }} >
        {returnShiftStrickSticks(i)}
      </div>);
    }

    return <div className="circle-div" style={{ height: this.all_height }}>
      {Couples}
    </div>
  }

  /// рисуем самую последнюю штуку вверху - это палка вниз к нижней сетки в гранд финал
  DrawOneBlankDoubleUpperCircleFinal = ({ circle_id }) => {
    return <div className="circle-div" style={{ height: this.all_height }}>
      <div className='circle_double_upper_last_stick_blank'>
        {this.placesDraw()}
      </div>
      <div className='circle_double_upper_last_stick' />
    </div>
  }

  // в данном случае separationIndex идет с учётом того, что это сепарейшены
  DrawLowBriketsCircle = (separationIndex, DoubleCircleId, CircleFromId) => {

    let Couples = [];
    let marginTop = 0;


    // т.е. это финал
    if (separationIndex === 0) {
      Couples.push(this.returnCouple(DoubleCircleId, 0, true, 300));
      return <div className="circle-div" style={{ height: this.all_height * 0.6, marginTop: -80, paddingBottom: '100%' }}>
        {Couples}
      </div>
    }

    // надо понять - это левая или правая часть
    const isRight = separationIndex % 2; // если есть остаток от деления, то это правая часть
    if (isRight) marginTop = -20;

    const { index, size } = getBlockIndexAndSizeByCircle(CircleFromId);
    const couplesCount = size / 2;
    const startIndex = isRight ? index : index + couplesCount;

    for (let i = startIndex; i < startIndex + couplesCount; i++) {
      if (separationIndex === 1)
        Couples.push(this.returnCouple(DoubleCircleId, i, isRight, 1200, !isRight, !isRight));
      else
        Couples.push(this.returnCouple(DoubleCircleId, i, isRight, 1200, !isRight, isRight));
    }

    return <div className="circle-div" style={{ height: this.all_height * 0.6, marginTop: marginTop }}>
      {Couples}
    </div>
  }

  /// рисуем все нижние пары ( КОГДА КОСТЫЛЬ !!!)
  returnLowBirketCircle(index) {

    let Couples = [];
    let marginTop = 0;

    if (index === 1) {
      Couples.push(this.returnCouple(5, 0, true, 150, true));
      Couples.push(this.returnCouple(5, 1, true, 150, true));
      Couples.push(this.returnCouple(5, 2, true, 150, true));
      Couples.push(this.returnCouple(5, 3, true, 150, true));
      marginTop = 20;
    }

    if (index === 2) {
      Couples.push(this.returnCouple(5, 4, false, 150));
      Couples.push(this.returnCouple(5, 5, false, 150));
      Couples.push(this.returnCouple(5, 6, false, 150));
      Couples.push(this.returnCouple(5, 7, false, 150));
    }

    if (index === 3) {
      Couples.push(this.returnCouple(5, 8, true, 300, true));
      Couples.push(this.returnCouple(5, 9, true, 300, true));
    }

    if (index === 4) {
      Couples.push(this.returnCouple(5, 10, true, 300));
      Couples.push(this.returnCouple(5, 11, true, 300));
      marginTop = -20;
    }

    if (index === 5) {
      Couples.push(this.returnCouple(5, 12, false, 300));
      Couples.push(this.returnCouple(5, 13, false, 300));
    }

    if (index === 6) {
      Couples.push(this.returnCouple(5, 14, true, 600));
    }



    return <div className="circle-div" style={{ height: this.all_height * 0.6, marginTop: marginTop }}>
      {Couples}
    </div>
  }

  returnCouple(circle_id, couple_id, notail = false, height = null, strickStick = false, stickByCoupleShift = 0) {
    let MyHeight = height ? height : this.all_height / this.couplesInCircle(circle_id);

    let Name1 = '';
    let Name2 = '';

    let PersonId1 = 0;
    let PersonId2 = 0;

    let GroupIAmIn1 = 0;
    let GroupIAmIn2 = 0;

    let SchoolId1 = 0;
    let SchoolId2 = 0;

    let ScoresPro1 = 0;
    let ScoresPro2 = 0;

    let ScoresNotPro1 = 0;
    let ScoresNotPro2 = 0;

    let Jrebiy1 = 0;
    let Jrebiy2 = 0;

    let WinTo1 = false;
    let WinTo2 = false;

    const { story, competitors, nowCouple, nextCouple, judgeKey, inquirysIds } = this.state;
    const { judgeMode, selectedCategory, category } = this.props;


    let D = story.find(x => (x.Circle_id === circle_id) && (x.Couple_id === couple_id));

    if (!D) return <div></div>

    if (D && (D.WinTo === 1 || D.WinTo === 3)) {
      WinTo1 = true;
    }

    if (D && (D.WinTo === 2 || D.WinTo === 4)) {
      WinTo2 = true;
    }

    let A = competitors.find(x => x.Jrebiy === D.Red_Id);
    if (A) {
      Name1 = A.Surname + ' ' + A.Name;
      PersonId1 = A.Id;
      GroupIAmIn1 = A.GroupIamInId;
      SchoolId1 = A.SchoolId;
      ScoresPro1 = A.ScoresPro;
      ScoresNotPro1 = A.ScoresNotPro;
      Jrebiy1 = A.Jrebiy;
    }

    let B = competitors.find(x => x.Jrebiy === D.Blue_Id);
    if (B) {
      Name2 = B.Surname + ' ' + B.Name;
      PersonId2 = B.Id;
      GroupIAmIn2 = B.GroupIamInId;
      SchoolId2 = B.SchoolId;
      ScoresPro2 = B.ScoresPro;
      ScoresNotPro2 = B.ScoresNotPro;
      Jrebiy2 = B.Jrebiy;
    }

    // если в паре есть спортсмен, и есть его победа, но нет противника, то не рисуем её

    // const { IsIAmAdmin } = this.props;

    // if (!IsIAmAdmin && (WinTo1 || WinTo2)) {
    //   if ((!PersonId1 && PersonId2) || (PersonId1 && !PersonId2)) {
    //     return <div className="circle-couple-div" style={{ height: MyHeight }} ></div>
    //   }
    // }



    let className = 'circle-couple-div';
    const isInquiryed = !category.IsCircled && (inquirysIds[D.Red_Id] || inquirysIds[D.Blue_Id]);
    if (isInquiryed && judgeMode) { className += ' circle-couple-div-inquiryed'; }
    if (D.IsInquiry) { className += ' circle-couple-div-inquiry'; }


    return <div className={className} style={{ height: MyHeight }} >

      {circle_id === 1 && <div className='places-div'>
        {this.placesDraw(notail)}
      </div>}

      <div className="circle-couple-around-div">
        <div className="one-couple-div">

          <div onClick={() => { this.setWinner(circle_id, couple_id, 1, WinTo1, WinTo2, Jrebiy1) }}>
            {this.returnOneSportsmen(Name1, PersonId1, GroupIAmIn1, SchoolId1, WinTo1, ScoresPro1, ScoresNotPro1, Jrebiy1)}
          </div>

          <div onClick={() => { this.setWinner(circle_id, couple_id, 2, WinTo1, WinTo2, Jrebiy2) }}>
            {this.returnOneSportsmen(Name2, PersonId2, GroupIAmIn2, SchoolId2, WinTo2, ScoresPro2, ScoresNotPro2, Jrebiy2)}
          </div>

          {/* Вызываются / приготовиться */}

          {
            nextCouple && String(nextCouple.category_id) === String(selectedCategory) && nextCouple.circle_id === circle_id && nextCouple.couple_id === couple_id ?
              <div className='one-couple-div-next'>Готовятся</div>
              : null
          }
          {
            nowCouple && String(nowCouple.category_id) === String(selectedCategory) && nowCouple.circle_id === circle_id && nowCouple.couple_id === couple_id ?
              <div className='one-couple-div-now'>Идёт бой</div>
              : null
          }
          {
            D.IsInquiry ?
              <div className='one-couple-div-inquiry'>Апелляция</div>
              : null
          }
          {
            (!isInquiryed && judgeMode && judgeKey === '3' && !WinTo1 && !WinTo2 && !(!PersonId1 && !PersonId2)) ?
              <div onClick={() => { this.setNextNow(true, circle_id, couple_id, PersonId1, PersonId2) }} className='one-couple-div-now n_dashed'>Вызываются</div>
              : null
          }
          {
            (!isInquiryed && judgeMode && judgeKey === '3' && !WinTo1 && !WinTo2 && !(!PersonId1 && !PersonId2)) ?
              <div onClick={() => { this.setNextNow(false, circle_id, couple_id, PersonId1, PersonId2) }} className='one-couple-div-next n_dashed n_dashed-next'>Готовятся</div>
              : null
          }
          {
            (judgeMode && judgeKey === '3' && (WinTo1 || WinTo2) && !(!PersonId1 && !PersonId2)) ?
              <div onClick={() => { this.setInquiry(circle_id, couple_id, D.IsInquiry ? 0 : 1) }} style={{ width: 'auto' }} className='one-couple-div-now n_dashed'>
                {D.IsInquiry ? 'Снять апелляцию' : 'Подана апелляция'}
              </div>
              : null
          }
          {
            D.VideoId ?
              <div onClick={() => { this.clickOnVideo(D.VideoId) }} className='videoButton' />
              :
              <div />
          }

          {
            !D.VideoId && judgeMode ?
              <div onClick={() => { this.clickOnSetVideo(circle_id, couple_id, Name1 + ' vs ' + Name2, PersonId1, PersonId2) }} className='videoButton videoButtonJudging' />
              :
              <div />
          }

          {
            D.IsVideo1 || D.IsVideo2 || D.IsScores1 || D.IsScores2 ?
              <div onClick={() => { this.clickOnSoloVideo(D) }} className='videoButton' />
              :
              <div />
          }

        </div>
      </div>

      {
        (strickStick) ?
          returnStrickSticks() :
          ((couple_id + stickByCoupleShift) % 2 === 0) ? returnUpperSticks(circle_id, notail) : returnBottomSticks(circle_id, notail)
      }

    </div>
  }

  returnOneSportsmen(Name, PersonId, GroupIAmIn, SchoolId, IsWin, ScoresPro = 0, ScoresNotPro = 0, Jrebiy = 0) {

    const { client } = this.props;
    const { selectedPersonId, judgeKey, jrebiySelectedNumber } = this.state;
    const { lookedPersonId } = this.props;

    const school = client.getSchoolById(SchoolId);
    const group = client.getGroupById(GroupIAmIn);
    const avatarGroupUrl = client.getGroupUrl(GroupIAmIn);
    // const avatarUrl = client.getUsersUrl() + PersonId +'.png';

    const AnnBorder = (IsWin ? 'borderer ' : ' ');
    let selectedPersonIdCSS = (selectedPersonId === PersonId ? 'selectedPesron ' : ' ');
    selectedPersonIdCSS = ((lookedPersonId && PersonId && lookedPersonId.toString() === PersonId.toString()) ? 'lookedPerson ' : ' ');
    const isJudgingStyle = judgeKey === '3' ? ' person-judging ' : '';
    const jrebiySelectedNumberStyle = (judgeKey === '2' && Jrebiy > 0 && jrebiySelectedNumber === Jrebiy) ? ' person-jrebiy-selected ' : ''

    return <div onMouseLeave={() => { this.setState({ selectedPersonId: -1 }) }} onMouseEnter={() => { this.setState({ selectedPersonId: PersonId }) }} className={"one-sportsmen-div " + AnnBorder + selectedPersonIdCSS + isJudgingStyle + jrebiySelectedNumberStyle}>
      <div className="one-sportsmen-div-forSchool">
        {judgeKey === '2'
          ?
          PersonId ? <PersonAvatar size={32} personId={PersonId} DrawRaiting={true} /> : null
          :
          PersonId ? <PersonAvatar size={32} personId={PersonId} /> : null
        }
      </div>
      <div className="one-sportsmen-div-forSportsmen">
        {(judgeKey !== '2' && judgeKey !== '3')
          ?
          <div className="one-sportsmen-div-forSportsmen-sportsmen">
            <Link to={`/person/${PersonId}`}>
              {Name}
            </Link>
          </div>
          :
          <div className="one-sportsmen-div-forSportsmen-sportsmen">
            {Name}
          </div>
        }
        <div className="one-sportsmen-div-forSportsmen-instructor">
          {group ? "Группа: " + group.Name : ""}
        </div>
      </div>
      <div className="one-sportsmen-div-forSchool">
        {school ? <Avatar size={32} src={client ? client.getSchoolUrl(school.Id) : 'images/schools/' + school.Id + '/logo.png'} /> : <div />}
      </div>
      <div className="one-sportsmen-div-forSchool">
        {group ? <Avatar size={32} src={avatarGroupUrl} /> : <div />}
      </div>

    </div>
  }



  // СУДЕЙСКАЯ ПАНЕЛЬ //////////////////////////////////////////////

  setAllJrebiys = async () => {

    const { competitionId, selectedCategory, client, updateCategorys } = this.props;
    const { competitors_first } = this.state;

    const elderCircle = getElderCircleByCompetitorsNumber(competitors_first.length);

    await client.updateCategory(competitionId, selectedCategory, elderCircle);
    updateCategorys();

    //затем каждому спортсмену определяем его жребий
    let now_jrebiy = 1;
    for (let i = 0; i < competitors_first.length; i++) {
      await client.updateCompetitor(competitionId, competitors_first[i].Id, undefined, now_jrebiy);
      now_jrebiy++;
    }

    this.timerReload();

  }

  JudgePanel = () => {

    const { judgeKey } = this.state;
    const style = judgeKey === '3' ? { height: 200 } : {};

    return <div className='judge-panel' style={style}>

      <Tabs
        activeKey={judgeKey || '1'}
        className='tabssuperid'
        style={{ color: 'white' }}
        defaultActiveKey={'1'}
        onTabClick={(key) => { this.setState({ judgeKey: key }) }}
      >
        <TabPane tab={<span>Инфо</span>} key="1">
          <this.JudgePanelInfo />
        </TabPane>
        <TabPane tab={<span>Жеребьевка</span>} key="2">
          <this.JudgePanelJrebiy />
        </TabPane>
        <TabPane tab={<span>Судейство</span>} key="3">
          <this.JudgePanelJudging />
        </TabPane>
      </Tabs>
    </div>
  }

  JudgePanelInfo = () => {
    const { competitors_first, TimeStartValue, NameValue, CouplesClosed } = this.state;
    const { category } = this.props;
    const changeFunction = async (e) => {
      const { competitionId, selectedCategory, client, updateCategorys } = this.props;
      await client.updateCategory(competitionId, selectedCategory,
        e.target.value === 0 ? getElderCircleByCompetitorsNumber(competitors_first.length) : competitors_first.length,
        undefined, e.target.value === 1 ? e.target.value : 0);
      updateCategorys();
      this.timerReload();
    }

    const changeFunctionIsPro = async (e) => {
      const { competitionId, selectedCategory, client, updateCategorys } = this.props;
      await client.updateCategoryIsPro ( competitionId, selectedCategory, e.target.value );
      updateCategorys();
      this.timerReload();
    }

    let categoryTypeValue = 0;
    if (category.IsDouble) categoryTypeValue = 2;
    if (category.IsCircled) categoryTypeValue = 1;

    const { IsPro } = category;

    return <div className='judge-panel-info'>
      <span>Количество участников: {competitors_first && competitors_first.length}</span>
      <br />
      {CouplesClosed > 0 ? null : <Radio.Group onChange={changeFunction} value={categoryTypeValue}>
        <Radio value={0}>Олимпийка</Radio>
        <Radio value={1}>Круговая</Radio>
        <Radio value={2}>Дабл</Radio>
      </Radio.Group>}
      <br />
      {<Radio.Group onChange={changeFunctionIsPro} value={IsPro}>
        <Radio value={0}>НОВИЧКИ</Radio>
        <Radio value={1}>ПРО</Radio>
      </Radio.Group>}
      <br />
      Время начала <br />
      <Input
        value={TimeStartValue}
        onChange={(e) => { this.setState({ TimeStartValue: e.target.value }) }}
        onBlur={async () => {
          const { competitionId, selectedCategory, client, updateCategorys } = this.props;
          const { TimeStartValue } = this.state;
          await client.updateCategory(competitionId, selectedCategory, null, TimeStartValue);
          updateCategorys();
        }}
      />
      Название категории <br />
      <Input
        value={NameValue}
        onChange={(e) => { this.setState({ NameValue: e.target.value }) }}
        onBlur={async () => {
          const { competitionId, selectedCategory, client, updateCategorys } = this.props;
          const { NameValue } = this.state;
          await client.updateCategory(competitionId, selectedCategory, null, undefined, undefined, client.lastSessionId, undefined, NameValue);
          updateCategorys();
        }}
      />
    </div>
  }

  JudgePanelJrebiy = () => {

    const { CouplesClosed } = this.state;

    const goFunction = async (e) => {
      const { competitors_first } = this.state;
      const { competitionId, selectedCategory, client, updateCategorys, category } = this.props;
      await client.updateCategory(
        competitionId,
        selectedCategory,
        category.IsCircled === 0 ? getElderCircleByCompetitorsNumber(competitors_first.length) : competitors_first.length
      );

      //затем каждому спортсмену определяем его жребий
      let now_jrebiy = 1;
      for (let i = 0; i < competitors_first.length; i++) {
        await client.updateCompetitor(competitionId, competitors_first[i].Id, undefined, now_jrebiy);
        now_jrebiy++;
      }

      updateCategorys();
      this.timerReload();
    }

    return <div className='judge-panel-jrebiy'>
      {CouplesClosed > 0 ? 'категория началась' : <Button onClick={goFunction} >Произвести жеребьевку</Button>}
    </div>
  }

  JudgePanelJudging = () => {

    const { category } = this.props;
    const { alertMode, progress, CategoryIsDone, CouplesClosed } = this.state;
    const MinutesPerCouple = category ? category.MinutesPerCouple : null;

    // const text = CategoryIsDone ? 'категория завершена' : `Прогресс: ${ progress ? (progress*100).toFixed(0) : 0}%`;


    let grafic = '';

    if (category.TimeStart && !CategoryIsDone) {
      const standartMinutesPerCouple = MinutesPerCouple || 2.5;
      const minutesPast = standartMinutesPerCouple * CouplesClosed;

      const categoryStart = category.TimeStart;
      const momentCategoryNowShouldBe = moment(categoryStart, 'HH:mm').add(minutesPast, 'minutes').format('HH:mm');
      const nowDate = moment().format('HH:mm');

      const otnositelno = moment.duration(nowDate) - moment.duration(momentCategoryNowShouldBe);
      if (otnositelno < 0) {
        const diff = moment.utc(-otnositelno).format('H:m');
        grafic = <React.Fragment><span> {`Нач: ${categoryStart} / `} </span> <span className='judge_panel_judging_progress_main__oper'> {`/ опережение ${diff} мин.`} </span> </React.Fragment>
      } else {
        const diff = moment.utc(otnositelno).format('H:m');
        grafic = <React.Fragment><span> {`Нач: ${categoryStart} / `} </span> <span className='judge_panel_judging_progress_main__otes'> {`/ отставание ${diff} мин.`} </span> </React.Fragment>
      }
    }

    const getWinners = () => {

      const onOk = () => {
        // const { client, reloadCategorys } = this.props;
        // client.copyCategory(category).then(() => reloadCategorys());
      }

      const { category: { Name: CategoryName } } = this.props;
      const { competitors } = this.state;

      const competitors_ = orderBy(competitors, 'Place', 'asc');

      const content = <div className='event_participation_winners_modal'>
        <table className='event_participation_winners_modal_table'>
          <tbody>
            <tr>
              <td>Фамилия</td>
              <td>Имя</td>
              <td>Место</td>
              <td>Категория</td>
            </tr>
            {competitors_.map(({ Id, Name, Surname, Place }) => {

              if (!Id) return null;
              if (!Place || Place > 3) return null;

              return <tr key={`cccccc-${Id}`}>
                <td>{Surname}</td>
                <td>{Name}</td>
                <td>{Place}</td>
                <td>{CategoryName}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      Modal.info({
        className: 'brikets_competitors_modal',
        title: 'ПОБЕДИТЕЛИ КАТЕГОРИИ',
        content,
        onOk,
        onCancel: () => { },
        okText: 'Ок',
        closable: true,
        centered: true
      })
    }


    // те кто без медалей
    const getPartis = () => {

      const onOk = () => {
        // const { client, reloadCategorys } = this.props;
        // client.copyCategory(category).then(() => reloadCategorys());
      }
      const { category: { Name: CategoryName } } = this.props;
      const { competitors } = this.state;

      const content = <div className='event_participation_winners_modal'>
        <table className='event_participation_winners_modal_table'>
          <tbody>
            <tr>
              <td>Фамилия</td>
              <td>Имя</td>
              <td>Категория</td>
            </tr>
            {competitors.map(({ Id, Name, Surname, Place }) => {

              if (!Id) return null;
              if (!Place || Place < 4) return null;

              return <tr key={`cccccc-${Id}`}>
                <td>{Surname}</td>
                <td>{Name}</td>
                <td>{CategoryName}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

      Modal.info({
        className: 'brikets_competitors_modal',
        title: 'ПОБЕДИТЕЛИ КАТЕГОРИИ',
        content,
        onOk,
        onCancel: () => { },
        okText: 'Ок',
        closable: true,
        centered: true
      })
    }

    return <div className='judge-panel-judging'>
      {!CategoryIsDone ? <div className='judge_panel_judging_progress_main'>
        <div className='judge_panel_judging_progress_main_diff'>
          {grafic}
        </div>
        <div className='judge_panel_judging_progress_main_progress'>
          <div style={{ width: ` ${progress * 100}%` }} className={'judge_panel_judging_progress_main_progress_inside ' + (progress === 1 ? 'judge_panel_judging_progress_main_progress_inside_all' : '')} />
        </div>
      </div>
        :
        <div className='judge_panel_judging_progress_main_done'>
          <span>КАТЕГОРИЯ ЗАВЕРШЕНА</span> <br />
          <div style={{ display: 'flex', flexDirection: 'row', columnGap: 15 }}>
            <div onClick={() => { getWinners() }} style={{ background: 'white', color: 'black', padding: 10, cursor: 'pointer' }}>ПОБЕДИЛТЕЛИ</div>
            <div onClick={() => { getPartis() }} style={{ background: 'white', color: 'black', padding: 10, cursor: 'pointer' }}>УЧАСТНИКИ</div>
          </div>
        </div>
      }
      {(!CategoryIsDone && progress === 1) ?
        <div className='judge_panel_judging_progress_main_done_send'>
          <Button onClick={() => sendPlacesToServer(this.props, this.state)} > Отправить места </Button>
        </div>
        : null}
      {(CategoryIsDone || progress === 1) ? null : <TrefaTimer MinutesPerCouple={MinutesPerCouple} alertMode={alertMode} setAlertMode={this.setAlertMode} />}
    </div>
  }



  // МОДАЛЬНИКИ //////////////////////////////////////////////

  VideoModal = () => {

    const { modalVideoSelected, videoUrl, videoTitle } = this.state;

    return <Modal
      title={videoTitle}
      visible={modalVideoSelected > 0 && videoUrl}
      onCancel={() => { this.setState({ modalVideoSelected: 0, videoUrl: null }) }}
      footer={[
      ]}
      width='90%'
      background='black'
    >
      <div style={{ height: '70vh' }}>
        <iframe
          title={videoTitle}
          width='90%'
          height='100%'
          src={videoUrl + '&autoplay=1&mute=1'}
          frameborder="1"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen>
        </iframe>

        <Button onClick={() => { this.props.copyToClipboard('https://capoeirasport.com/?videoId=' + modalVideoSelected) }} type='primary'>
          СКОПИРОВАТЬ ССЫЛКУ
        </Button>
      </div>
    </Modal>
  }

  // ВСЕ ЧТО КАСАЕТСЯ СОЛО //////////////////////////////////////////////

  SoloVideoModal = () => {
    const { lookedStory } = this.state;
    return <Modal
      title={'Видео'}
      visible={lookedStory !== undefined}
      onCancel={() => { this.setState({ lookedStory: undefined }) }}
      footer={[
      ]}
      width='90%'
      background='black'
    >
      <this.SoloVideoModalContent />
    </Modal>
  }

  SoloVideoModalContent = () => {


    const { lookedStory,
      IsVideo1Ch,
      IsVideo2Ch,
      IsScores1Ch,
      IsScores2Ch,
      mobile
    } = this.state;

    const { IsIAmAdmin } = this.props;

    if (!IsIAmAdmin) {
      return <div className='brikets-div-modal-lookedvideo'>
        <div className='brikets-div-modal-onevideo'>
          {lookedStory && lookedStory.IsVideo1 ? <iframe
            title={''}
            width={mobile ? '220px' : '350px'}
            height={mobile ? '120px' : '280px'}
            src={`https://www.youtube.com/embed/${lookedStory.IsVideo1}`}
            frameborder="1"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen>
          </iframe> : null}
          <br /><br />
          <b>Очки:</b>
          {lookedStory && lookedStory.IsScores1 ? lookedStory.IsScores1 : 'еще не добавлены'}
        </div>
        <div className='brikets-div-modal-lookedvideo-vs'>
          VS
        </div>
        <div className='brikets-div-modal-onevideo'>
          {lookedStory && lookedStory.IsVideo2 ? <iframe
            title={''}
            width={mobile ? '220px' : '350px'}
            height={mobile ? '120px' : '280px'}
            src={`https://www.youtube.com/embed/${lookedStory.IsVideo2}`}
            frameborder="1"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen>
          </iframe> : null}
          <br /><br />
          <b>Очки:</b>
          {lookedStory && lookedStory.IsScores2 ? lookedStory.IsScores2 : 'еще не добавлены'}
        </div>


      </div>
    }

    return <div className='brikets-div-modal-lookedvideo'>
      <div className='brikets-div-modal-onevideo'>
        {lookedStory && lookedStory.IsVideo1 ? <iframe
          title={''}
          width={mobile ? '220px' : '350px'}
          height={mobile ? '120px' : '280px'}
          src={`https://www.youtube.com/embed/${lookedStory.IsVideo1}`}
          frameborder="1"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen>
        </iframe> : null}
        <Input value={IsVideo1Ch} onChange={(e) => { this.setState({ IsVideo1Ch: e.target.value }) }} />
        <br />
        <b>Очки</b>
        <Input value={IsScores1Ch} onChange={(e) => { this.setState({ IsScores1Ch: e.target.value }) }} />
      </div>
      <div className='brikets-div-modal-lookedvideo-vs'>
        VS
        <br />
        <Button type='primary' onClick={() => { this.saveSoloHistory() }}> СОХРАНИТЬ </Button>
      </div>
      <div className='brikets-div-modal-onevideo'>
        {lookedStory && lookedStory.IsVideo2 ? <iframe
          title={''}
          width={mobile ? '220px' : '350px'}
          height={mobile ? '120px' : '280px'}
          src={`https://www.youtube.com/embed/${lookedStory.IsVideo2}`}
          frameborder="1"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowfullscreen>
        </iframe> : null}
        <Input value={IsVideo2Ch} onChange={(e) => { this.setState({ IsVideo2Ch: e.target.value }) }} />
        <br />
        <b>Очки</b>
        <Input value={IsScores2Ch} onChange={(e) => { this.setState({ IsScores2Ch: e.target.value }) }} />
      </div>


    </div>
  }

  saveSoloHistory = async () => {
    const { competitionId, selectedCategory } = this.props;
    const {
      lookedStory,
      IsVideo1Ch,
      IsVideo2Ch,
      IsScores1Ch,
      IsScores2Ch,
    } = this.state;

    const { Circle_id, Couple_id } = lookedStory;
    const uri = 'https://' + this.props.ServerUrl + '/sethistorysolothing?competition_id=' + competitionId
      + '&category_id=' + selectedCategory
      + '&circle_id=' + Circle_id
      + '&couple_id=' + Couple_id
      + '&IsVideo1=' + IsVideo1Ch
      + '&IsVideo2=' + IsVideo2Ch
      + '&IsScores1=' + IsScores1Ch
      + '&IsScores2=' + IsScores2Ch
    try {
      await axios.get(uri);
    }
    catch (err) {
    }
  }

  clickOnSoloVideo(story) {

    this.setState({
      lookedStory: { ...story },
      IsVideo1Ch: story.IsVideo1,
      IsVideo2Ch: story.IsVideo2,
      IsScores1Ch: story.IsScores1,
      IsScores2Ch: story.IsScores2,
    });
  }



}
