import React, { Component } from 'react';
import LittlePerson from 'src/ver2/components/person/little';
import Loader from 'src/ver2/components/loader';

import './style.css';

//getEventById

/**
  Это маленький список людей
  Сюда приходит массив людей Persons ([ {...Person}, ... ]), достаточно, чтобы в Person был Id

  Если personsDirect - это значит мы уже передали персон и их не надо подгружать поновой

  noMaxPersons - если не надо ограничивать в 15 чел

 */

export default class PersonsLittleListPlashka extends Component {

    constructor(props) {
        super(props);

        const { loadCount } = props;

        this.state = {
            maxPersons: loadCount || 15
        }
    }

    shouldComponentUpdate({ Persons, upperStyle }, { maxPersons }) {
        if (Persons !== this.props.Persons) return true;
        if (upperStyle.height !== this.props.upperStyle.height) return true;
        if (maxPersons !== this.state.maxPersons) return true;
        return false;
    }

    addMorePersons = () => {
        const { maxPersons } = this.state;
        const { loadCount } = this.props;
        this.setState({ maxPersons: maxPersons + (loadCount || 15)})
        // this.setState((({ maxPersons }) => { return { maxPersons: maxPersons + 15 } }));
    }

    render() {

        const { Persons, itemHeight, upperStyle, autoSize, autoSizeMax, setComponentSelfHeight, plashkaId, mode, personsDirect, noMaxPersons, onPersonClick } = this.props;
        const { width, height } = upperStyle;

        if (!Persons) return <div style={{ width, height: Math.max(height, 100) }} className='persons_little_plashka'>
            <Loader />
        </div>


        /*const style = {
            ...height ? { height } : {}
        }*/

        // const { width, height } = this.props.upperStyle;

        const littlePersonHeight = itemHeight || 50;

        if (autoSize) {
            if (autoSizeMax) {
                setComponentSelfHeight({ height: Math.min(50 + littlePersonHeight * Persons.length, 50 +  littlePersonHeight * autoSizeMax), plashkaId });
            } else
                setComponentSelfHeight({ height: 50 +  littlePersonHeight * Persons.length, plashkaId });
        }

        const { maxPersons } = this.state;
        const isThereMorePersons = Persons.length > maxPersons;

        return <div style={{ width, height }} className='persons_little_plashka'>
            {Object.keys(Persons).map((personKey, index) => {

                if (!noMaxPersons && (index > maxPersons)) return null;

                const person = Persons[personKey];
                const { Id } = person;

                if (personsDirect) {
                    if (mode === 'students') {
                        return <LittlePerson
                            height={littlePersonHeight} key={'plt' + Id}
                            DirectPerson={person}
                            mode={mode}
                            personsDirect={personsDirect}
                            studentsRankRace={this.props.studentsRankRace}
                            onStudentChange={this.props.onStudentChange}
                        />
                    }
                    if (mode === 'categorys_admin') {
                        return <LittlePerson
                            height={littlePersonHeight} key={'plt' + Id}
                            DirectPerson={person}
                            mode={mode}
                            personsDirect={personsDirect}
                            onCompetitorChange={this.props.onCompetitorChange}
                        />
                    }
                    return <LittlePerson height={littlePersonHeight} key={'plt' + Id} DirectPerson={person} mode={mode} personsDirect={personsDirect} />
                }

                if (mode === 'group_instructors') {
                    return <LittlePerson height={littlePersonHeight} key={'plt' + Id} personId={Id} mode={mode} Suffix={person.groupType} />
                }

                return <LittlePerson height={littlePersonHeight} key={'plt' + Id} personId={Id} mode={mode} onPersonClick = {onPersonClick} />
            })}
            {(isThereMorePersons && !noMaxPersons) ?
                <div onClick={this.addMorePersons} className='persons_little_plashka_addmore'>
                    <span>
                        загрузить еще
                    </span>
                </div>
                : null}
        </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);