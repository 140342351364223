import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import MoneyComponent from 'src/ver2/components/money';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class ShopMoneyPlashka
// classname: shop_money_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopMoneyPlashka extends PureComponent {

    render() {

        const { isShopMoneyReady, ShopMoney, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!isShopMoneyReady) return <div style={{ width, height }} className='shop_money_plashka'>
            <Loader />
        </div>

        let Answer = [];

        if (ShopMoney && ShopMoney.length > 0) {
            Answer = ShopMoney.map((moneyKey) => {
                return <MoneyComponent size={55} type={moneyKey} noAmount />
            })
        }

        return <div style={{ width, height }} className='shop_money_plashka'>
            <div className='shop_money_plashka_discription'>
                {Answer}
            </div>
        </div>
    }

}

export default withShopState(`
Shop,  
client,
ShopMoney,
isShopMoneyReady,
`)(ShopMoneyPlashka);