import React, { PureComponent } from 'react';
import { withEventState } from 'src/ver2/pages/event/context';
import EventAvatar from 'src/ver2/components/event/avatar';
import Loader from 'src/ver2/components/loader';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import { UpperStatus } from 'src/ver2/components/event/upperStatus';

import { Modal } from 'antd';

// import { NumberText } from 'src/ver2/containers/lowlavel/numberText';

import './style.css';

class EventBigPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            publishedModal: false,
            registrationModal: false
        }
    }

    changeShowPublishedModalModal = (publishedModal = true) => this.setState({ publishedModal })
    changeShowRegistrationModalModal = (registrationModal = true) => this.setState({ registrationModal })

    render() {

        const { Event, client, eventStatus, iAmAdmin } = this.props;
        const { publishedModal, registrationModal } = this.state;
        if (!Event) return <div className='event_big_plashka'>
            <Loader />
        </div>

        const {
            Id, Title
        } = Event;

        const imageUrl = client.getEventsUrl() + Id + '.png';
        const style = {
            background: `url(${imageUrl})`,
        }

        const { width, height } = this.props.upperStyle;

        let stylizationBackground = {};
        let stylizationColor = {};

        if (Id === 2 || Id === 76) {
            stylizationBackground = { background: 'linear-gradient(rgb(66, 0, 46), rgb(104, 0, 73))' };
            stylizationColor = { color: 'rgb(255, 196, 86)' }
        }

        if (Id === 84) {
            stylizationBackground = { background: 'linear-gradient(rgb(255, 230, 6), yellow)' };
            stylizationColor = { color: 'rgb(0, 41, 0)' }
        }

        if (Id === 101) {
            stylizationBackground = { background: 'linear-gradient(darkred, black)' };
            stylizationColor = { color: 'rgb(255, 255, 255)' }
        }

        const { changeShowPublishedModalModal, changeShowRegistrationModalModal } = this;


        return <div style={{ width, height, ...stylizationBackground }} className='event_big_plashka'>
            <div style={{ width: height, height: height }} className='event_big_plashka___avatar_back'>
                <EventAvatar size={height} eventId={Id} />
            </div>
            <div style={{ width: height, height: height }} className='event_big_plashka___avatar_container'>
                <EventAvatar size={height * 0.75} eventId={Id} />
            </div>
            <div style={{ width: (width - height), height: height }} className='event_big_plashka___info_container'>
                <div style={{ ...stylizationColor }} className='event_big_plashka___info_container___name global_color_main'>
                    {Title}
                </div>
            </div>
            <UpperStatus {...{ client, eventStatus, className: 'event_big_plashka__status', iAmAdmin, changeShowPublishedModalModal, changeShowRegistrationModalModal} } />
            <Modal
                className='adminModalClass'
                visible={publishedModal}
                onCancel={() => { this.changeShowPublishedModalModal(false) }}
                footer={null}
                title={'Опубликовать мероприятие?'}
                width={1200}
            >
                {publishedModal ? <this.PublishContainer /> : null}
            </Modal>
            <Modal
                className='changeRegistrationModalClass'
                visible={registrationModal}
                onCancel={() => { this.changeShowRegistrationModalModal(false) }}
                footer={null}
                title={'Изменить регистрацию'}
                width={600}
            >
                {registrationModal ? <this.RegistrationContainer /> : null}
            </Modal>
        </div>
    }

    PublishContainer = () => {

        const goPublishEvent = () => {
            const { publishEvent } = this.props;
            publishEvent();
            this.changeShowPublishedModalModal(false);
        }

        return <div>
            <span>
                Уверены, что хотите опубликовать мероприятие? <br />
                Вернуть его обратно в неопубликованное состояние будет нельзя, только обратившись к администрации портала
            </span>
            <br /><br />
            <ButtonPlashka
                upperStyle={{ width: 240, height: 40 }}
                onClick={goPublishEvent}
                text={'ОПУБЛИКОВАТЬ МЕРОПРИЯТИЕ'}
            />
        </div>
    }

    RegistrationContainer = () => {


        const { eventStatus, Event } = this.props;
        const status_ = eventStatus() || 'undef';
        if (status_ === 'ubdef') {
            this.changeShowRegistrationModalModal(false);
            return null;
        }

        const goOpenRegistration = () => {
            const { openRegistration, goRegistrationOpenNotification } = this.props;
            openRegistration();
            goRegistrationOpenNotification();
            this.changeShowRegistrationModalModal(false);
        }
        const goCancelOpenRegistration = () => {
            const { cancelOpenRegistration } = this.props;
            cancelOpenRegistration();
            this.changeShowRegistrationModalModal(false);
        }
        const goCloseRegistration = () => {
            const { closeRegistration } = this.props;
            closeRegistration();
            this.changeShowRegistrationModalModal(false);
        }
        const goCancelCloseRegistration = () => {
            const { cancelCloseRegistration } = this.props;
            cancelCloseRegistration();
            this.changeShowRegistrationModalModal(false);
        }

        if (status_ === 'registration_not_open') {
            return <div>
                <span>
                    Открыть регистрацию ? <br />
                    Убедитесь, что вы задали оплату и требования к участникам
            </span>
                <br /><br />
                <ButtonPlashka
                    upperStyle={{ width: 240, height: 40 }}
                    onClick={goOpenRegistration}
                    text={'ОТКРЫТЬ РЕГИСТРАЦИЮ'}
                />
            </div>
        }

        if (status_ === 'registration_open') {
            return <div>
                <span>
                    Закрыть регистрацию? <br />
                    Пользователи больше не смогут подавать заявку на регистрацию
            </span>
                <br /><br />
                <ButtonPlashka
                    upperStyle={{ width: 280, height: 40 }}
                    onClick={goCloseRegistration}
                    text={'ЗАКРЫТЬ РЕГИСТРАЦИЮ'}
                />
                <br /><br /><br /><br />
                <span>
                    Отменить открытие регистрации? <br />
                    Мероприятия вернётся в статус ожидания открытия регистрации, если вы открыли её по ошибке
            </span>
                <br /><br />
                <ButtonPlashka
                    upperStyle={{ width: 280, height: 40 }}
                    type={'danger'}
                    onClick={goCancelOpenRegistration}
                    text={'ОТМЕНИТЬ ОТКРЫТИЕ РЕГИСТРАЦИИ'}
                />
            </div>
        }

        if (status_ === 'registration_closed') {
            return <div>
                <span>
                    Отменить закрытие регистрации? <br />
                    Мероприятия вернётся в статус открытой регистрации, если вы закрыли её по ошибке
            </span>
                <br /><br />
                <ButtonPlashka
                    upperStyle={{ width: 280, height: 40 }}
                    type={'danger'}
                    onClick={goCancelCloseRegistration}
                    text={'ОТМЕНИТЬ ЗАКРЫТИЕ РЕГИСТРАЦИИ'}
                />
            </div>
        }

        if (status_ === 'past' && !Event.RegistrationClosed) {
            return <div>
                <span>
                    Мероприятие уже прошло, но вы не закрыли регистрацию.
                    Лучше выполнить закрытие регистрации
            </span>
                <br /><br />
                <ButtonPlashka
                    upperStyle={{ width: 280, height: 40 }}
                    onClick={goCloseRegistration}
                    text={'ЗАКРЫТЬ РЕГИСТРАЦИЮ'}
                />
            </div>
        }

        this.changeShowRegistrationModalModal(false);
        return null;
    }

}

// export const UpperStatus = () => {

//     let addingClassName = '';

//     const { client, eventStatus, iAmAdmin } = this.props;
//     // if (Event.NotAnnounced) {
//     //     status = 'не опубликовано';
//     //     addingClassName = 'event_big_plashka__status_notAnnounsed';
//     // }

//     const status_ = eventStatus() || 'undef';
//     const status = client.eventStatuses[status_];
//     addingClassName = `event_big_plashka__status_${status_}`;

//     let onClick = null;
//     if (status_ === 'not_published') {
//         if (iAmAdmin) {
//             onClick = this.changeShowPublishedModalModal;
//             addingClassName += ' event_big_plashka__status_cliced';
//         }
//     }

//     if (status_ === 'registration_not_open' || status_ === 'registration_open' || status_ === 'registration_closed' || status_ === 'past') {
//         if (iAmAdmin) {
//             onClick = this.changeShowRegistrationModalModal;
//             addingClassName += ' event_big_plashka__status_cliced';
//         }
//     }

//     return <div className={'event_big_plashka__status ' + addingClassName} >
//         <div onClick={onClick} data-tooltip={status.text} className='global-tooltip event_big_plashka__status_text'>
//             {status.title}
//         </div>
//     </div>
// }


//eventStatus

export default withEventState(`
Event,
eventStatus,
publishEvent,

isMobile,

openRegistration,
cancelOpenRegistration,
closeRegistration,
cancelCloseRegistration,

iAmAdmin,
client
`)(EventBigPlashka)