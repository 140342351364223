import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import Loader from 'src/ver2/components/loader';
import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventPaymentPlashka
// classname: event_payment_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventPaymentPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false
        }
    }

    setHeight = () => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const element = document.getElementById('event_payment_plashka_id');
        if (!element) return;
        if (this.state.heightSetted === element.clientHeight) return;
        this.setState({ heightSetted: element.clientHeight });
        setComponentSelfHeight({ height: element.clientHeight + 50, plashkaId });
    }

    componentDidMount() {
        this.setHeight();
    }

    componentDidUpdate() {
        this.setHeight();
    }

    render() {

        const { Event, upperStyle, isTherePayment } = this.props;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_payment_plashka'>
            <Loader />
        </div>

        if (!isTherePayment()) {
            return <div style={{ width, height }} className='event_payment_plashka'>
                <div className='event_payment_plashka_today'>
                    Мероприятие бесплатно
                </div>
            </div>
        }

        const { paymentNormalize } = this.props;
        const paymentNormalize_ = paymentNormalize();

        let answer = [];
        for (let i = 0; i < paymentNormalize_.length; i++) {
            const { name, header, rows, isAllFree } = paymentNormalize_[i];
            if (isAllFree) continue;

            // находим нужную дату
            let neededHeaderIndex = null;

            for (let j = 2; j < header.length; j++) {
                const momentHeaderDate = moment(header[j], 'DD.MM.YYYY').add(1, 'day');
                if (moment().isAfter(momentHeaderDate)) continue;
                neededHeaderIndex = j;
                break;
            }

            if (!rows[0][1]) {
                answer.push(
                    <div className='event_payment_plashka_one_payment'>
                        <div className='event_payment_plashka_one_payment_left'>
                            <div className='event_payment_plashka_one_payment_left_title'>
                                {rows[0][0]}
                            </div>
                        </div>
                        <div className='event_payment_plashka_one_payment_right'>
                            <div className={'event_payment_plashka_one_payment_right_title ' + (neededHeaderIndex ? '' : 'event_payment_plashka_one_payment_right_title_closed')}>
                                {neededHeaderIndex ? `${rows[0][neededHeaderIndex]} руб.` : `закрыто`}
                            </div>
                        </div>
                    </div>
                )
            }

            if (rows[0][1]) {
                answer.push(
                    <div className='event_payment_plashka_one_payment_title'>
                        <span>{rows[0][0]}</span>
                    </div>
                )
                for (let i = 0; i < rows.length; i++) {
                    answer.push(
                        <div className='event_payment_plashka_one_payment'>
                            <div className='event_payment_plashka_one_payment_left'>
                                <div className='event_payment_plashka_one_payment_left_title'>
                                    {rows[i][1].title}
                                </div>
                            </div>
                            <div className='event_payment_plashka_one_payment_right'>
                                <div className={'event_payment_plashka_one_payment_right_title ' + (neededHeaderIndex ? '' : 'event_payment_plashka_one_payment_right_title_closed')}>
                                    {neededHeaderIndex ?
                                        rows[i][neededHeaderIndex] ?
                                            `${rows[i][neededHeaderIndex]} руб.` :
                                            `бесплатно`
                                        : 'закрыто'
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            }

        }

        return <div style={{ width, height }} className='event_payment_plashka'>
            <div id='event_payment_plashka_id'>
                {answer}
                {/* <div className='event_payment_plashka_today'>
                    Полняя таблица оплаты
                </div> */}
            </div>
        </div>
    }

}

export default withEventState(`
Event,

isTherePayment,
paymentNormalize,

client,
iamuser,
`)(EventPaymentPlashka)