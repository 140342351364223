import React from 'react';
import './style.css';

export const Header = (props) => {

    const { subgroup, index, setSubgroupToEdit, setStudentToAddSbId } = props;
    const { title, adres, color, settingsAsObj } = subgroup;

    const color_ = color[0] === "#" ? color : `#${color}`

    if (index === 0)
        return <div className='subgroup-header-container'>
            <div className='subgroup-header-nogroup'>
                <p>БЕЗ ПОДГРУППЫ</p>
            </div>
        </div>

let className='subgroup-header-color ';
let numberStyle={ background: color_ };
if ( settingsAsObj && settingsAsObj.notActive) { 
    numberStyle.background = '#888888' 
    className += ' subgroup-header-color-dashed'
}

    return <div className='subgroup-header-container'>
        <div className={className} style={numberStyle}>
            <p>{index}</p>
        </div>
        <div className='subgroup-header-info '>
            <div className='subgroup-header-info-row'>
                <b>Название: </b> {title}
            </div>
            <div className='subgroup-header-info-row'>
                <b>Адрес: </b> {adres}
            </div>
            {setStudentToAddSbId ? <div onClick={() => { setStudentToAddSbId(subgroup.id) }} className='subgroup-header-edit subgroup-header-edit_add'>
                <img src='http://capoeirasport.com/graphics/crm/crm_plus.png' />
            </div> : null}
            {setSubgroupToEdit ? <div onClick={() => { setSubgroupToEdit(subgroup) }} className='subgroup-header-edit'>
                <img src='http://capoeirasport.com/graphics/crm/crm_edit.png' />
            </div> : null}
        </div>
    </div>
}