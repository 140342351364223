import React, { Component } from 'react';
import { Input, Icon } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';

import EventLittleListPlashka from 'src/ver2/plashki/event/littleList';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';

import { TrefaLayout } from 'src/ver2/containers/layout';

import './style.css';

class Search extends Component {


    constructor(props) {
        super(props);
        this.state = {
            value: '',
            resultLoading: false,
            results: null
        }
    }
    //prevState
    componentDidUpdate({ }, { value }) {
        // что-то поменялось
        if (this.state.value !== value) {
            if (!this.state.value || this.state.value.length < 3) return this.setState({ results: null, resultLoading: false });

            this.getResultFromServer();
            this.setState({ resultLoading: true })

        }

    }

    getResultFromServer = () => {
        const { client } = this.props;
        client.getSearch(this.state.value).then(
            results => {
                ;
                this.setState({ results, resultLoading: false })
            }
        )
    }

    onChange = (e) => { this.setState({ value: e.target.value }) };
    // onBlur = () => { this.setState({ value: '' }) }

    render() {

        const { value } = this.state;

        return <div className='menu_search_conatiner'>
            <Input onChange={this.onChange} onBlur={this.onBlur} value={value} placeholder='поиск...' height={22} className='menu_search_conatiner__input' />
            <this.ResultsContainer />
        </div>
    }

    ResultsContainer = () => {
        const { results } = this.state;
        if (!results
            || (
                (!results.persons || !results.persons.length)
                && (!results.groups || !results.persons.length)
                && (!results.events || !results.events.length)
            )
        ) return null;

        const { persons, events, groups } = results;
        const { noResultMode } = this.props; 

        //if (events.length === 0) return null;

        const elementHeight = 36;

        let componentsHeight = 0;
        let components = [];

        if (persons.length > 0) {

            const componentHeight = Math.min(200, persons.length * elementHeight) + 68;
            componentsHeight += componentHeight;

            components.push({
                component: PersonsLittleListPlashka,
                cell_x: 2,
                collapse: 1,
                componentProps: { _title: 'Капоэйристы', lowTitle: true, yPadding: 'little', height: componentHeight, Persons: persons }
            })
        }

        if (events.length > 0 && !noResultMode) {

            const componentHeight = Math.min(200, events.length * elementHeight) + 68;
            componentsHeight += componentHeight;

            components.push({
                component: EventLittleListPlashka,
                cell_x: 2,
                collapse: 1,
                componentProps: { _title: 'Мероприятия', lowTitle: true, yPadding: 'little', height: componentHeight, Events: [...events].reverse() }
            })
        }

        return <div className='menu_search_conatiner__results'>
            <div className='menu_search_conatiner__results_close' onClick = {() => { this.setState({ value: '' }) }}>
                <Icon type='cross' />
            </div>
            {components.length && <TrefaLayout greedsX={[10, 200, 10]} greedsY={componentsHeight}
                components={components}
            />}
        </div>

    }

}

export default withCapoeiraSportState('client')(Search);