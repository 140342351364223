import React, { Component } from 'react';
import { Input, Button, Modal, Checkbox, message } from 'antd';
import { withCRMState } from '../../../../context';
import { HuePicker } from 'react-color';
//import logo from './logo.svg';

import './style.css';

const copyToClipboard = (value) => {
    const copytext = document.getElementById('copyCopyText');
    if (copytext) {
        copytext.value = value;
        copytext.select();
        document.execCommand('copy');
        message.success('Скопировано ' + value);
    }
}

//const sb_visits = await client.getSubgroupVisits(subgroups[i].id, selectedYear, selectedMonth) || [];

class SubgroupEditComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            city: '',
            adres: '',
            color: '#FF0000',
            settings: null,
            mayDelete: false
        }

        if (props.subgroupToEdit && props.subgroupToEdit.id) {
            this.state = {
                title: props.subgroupToEdit.title,
                city: props.subgroupToEdit.city,
                adres: props.subgroupToEdit.adres,
                color: '#' + props.subgroupToEdit.color,
                settings: props.subgroupToEdit.settings
            }
        }

    }

    componentDidMount = async () => {

        const { subgroupToEdit, studentsBySubgroups, client } = this.props;
        if (subgroupToEdit && subgroupToEdit.id) {
            const { id } = subgroupToEdit;
            if (studentsBySubgroups[id] && studentsBySubgroups[id].length) {
                return;
            }

            const sb_visits = await client.getSubgroupVisits(id) || [];
            if (sb_visits && sb_visits.length) {
                return;
            }
            this.setState({ mayDelete: true })
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.subgroupToEdit && nextProps.subgroupToEdit.id) {
            this.setState({
                title: nextProps.subgroupToEdit.title,
                adres: nextProps.subgroupToEdit.adres,
                city: nextProps.subgroupToEdit.city,
                color: '#' + nextProps.subgroupToEdit.color,
                settings: nextProps.subgroupToEdit.settings,
            })
        } else {
            this.setState({
                title: '',
                city: '',
                adres: '',
                color: '#FF0000',
                settings: null
            })
        }
    }

    titleChange = (e) => {
        this.setState({
            title: e.target.value
        })
    }

    adresChange = (e) => {
        this.setState({
            adres: e.target.value
        })
    }

    cityChange = (e) => {
        this.setState({
            city: e.target.value
        })
    }

    colorChange = (color) => {
        this.setState({
            color: color.hex
        })
    }

    settingsChange = (settings) => {
        this.setState({
            settings
        })
    }

    paymentChange = (type, title, value) => {
        const { settings } = this.state;
        let settingsObj = {};
        if (settings) {
            settingsObj = JSON.parse(settings);
        };

        if (!settingsObj.payment) settingsObj.payment = [];

        const typeObj = settingsObj.payment.find(x => (x.type === type && x.title === title));
        if (typeObj) {
            const typeObjIndex = settingsObj.payment.indexOf(typeObj);
            settingsObj.payment[typeObjIndex].value = value;
        } else {
            settingsObj.payment.push({ type, title, value });
        }
        this.settingsChange(JSON.stringify(settingsObj));
    }

    settingsKeyChange = (key, value) => {
        const { settings } = this.state;
        let settingsObj = {};
        if (settings) {
            settingsObj = JSON.parse(settings);
        };
        settingsObj[key] = value;
        this.settingsChange(JSON.stringify(settingsObj));
    }

    getSettingsKey = (key) => {
        const { settings } = this.state;
        let settingsObj = {};
        if (settings) {
            settingsObj = JSON.parse(settings);
        };
        return settingsObj[key];
    }

    onButtonClick = () => {
        const { title, adres, color, city, settings } = this.state;
        const { subgroupToEdit } = this.props;
        if (!subgroupToEdit.id) {
            this.props.addSubgroup({
                title,
                adres,
                city,
                color,
                settings
            });
        } else {
            this.props.updateSubgroup({
                ...subgroupToEdit,
                title,
                city,
                adres,
                color,
                settings
            });
        }
    }

    onDeleteButtonClick = () => {
        Modal.confirm({
            title: 'Уверены?',
            content: 'Восстановить подгруппу будет невозможно, только создать заново',
            okText: 'Удалить',
            cancelText: 'Не удалять',
            onOk: () => {
                const { subgroupToEdit } = this.props;
                this.props.deleteSubgroup({
                    ...subgroupToEdit
                });
            }
        })
    }

    render() {

        const {
            title,
            adres,
            city,
            color
        } = this.state;

        return <div className='subgroup-edit'>
            <this.InviteWrapper />
            <this.SubgroupNaborWrapper />
            <this.EditWrapper title='Название группы'>
                <Input value={title} onChange={this.titleChange} />
            </this.EditWrapper>
            <this.EditWrapper title='Город'>
                <Input value={city} onChange={this.cityChange} />
            </this.EditWrapper>
            <this.EditWrapper title='Адрес'>
                <Input value={adres} onChange={this.adresChange} />
            </this.EditWrapper>
            <this.EditWrapper title='Ближайшее метро'>
                <Input value={this.getSettingsKey('metro')} onChange={(e) => { this.settingsKeyChange('metro', e.target.value) }} />
            </this.EditWrapper>
            <this.EditWrapper title='Цвет'>
                <HuePicker disableAlpha={true} color={color} onChange={this.colorChange} />
            </this.EditWrapper>
            <div style={{ width: 70, height: 30, background: color, marginBottom: 15 }} />
            <this.EditWrapper title='Возраст от'>
                <Input value={this.getSettingsKey('agefrom')} onChange={(e) => { this.settingsKeyChange('agefrom', e.target.value) }} />
            </this.EditWrapper>
            <this.EditWrapper title='Возраст до'>
                <Input value={this.getSettingsKey('ageto')} onChange={(e) => { this.settingsKeyChange('ageto', e.target.value) }} />
            </this.EditWrapper>
            <this.EditWrapper title='Отмечать дисциплину?'>
                <Checkbox checked={this.getSettingsKey('setDiscipline')} onChange={(e) => { this.settingsKeyChange('setDiscipline', e.target.checked) }}>Да</Checkbox>
            </this.EditWrapper>
            <this.EditWrapper title='Выдётся набор?'>
                <Checkbox checked={this.getSettingsKey('notOngoing')} onChange={(e) => { this.settingsKeyChange('notOngoing', e.target.checked) }}>Нет</Checkbox>
            </this.EditWrapper>
            <this.EditWrapper title='Группа не активна'>
                <Checkbox checked={this.getSettingsKey('notActive')} onChange={(e) => { this.settingsKeyChange('notActive', e.target.checked) }}>Да</Checkbox>
            </this.EditWrapper>
            <this.EditWrapper title='Оплата'>
                <this.PaymentWrapper />
            </this.EditWrapper>
            <this.ButtonWrapper />
        </div>
    }

    PaymentWrapper = () => {
        const { settings } = this.state;
        let day = null;
        let month = null;

        let settingsObj = {};
        if (settings) {
            settingsObj = JSON.parse(settings);
        };

        const ONCE_TITLE = 'Разовое';
        const MONTH_TITLE = 'Месяц';

        if (settingsObj.payment) {
            const dayObj = settingsObj.payment.find(x => x.title === ONCE_TITLE);
            if (dayObj) day = dayObj.value;
            const monthObj = settingsObj.payment.find(x => x.title === MONTH_TITLE);
            if (monthObj) month = monthObj.value;
        }

        return <React.Fragment>
            <div className='subgroup-edit-payment'>
                <div className='subgroup-edit-payment-left'>
                    МЕСЯЦ
                </div>
                <div className='subgroup-edit-payment-right'>
                    <Input style={{ width: 80 }} value={month} onChange={(e) => { this.paymentChange('month', MONTH_TITLE, e.target.value) }} />
                    <div>РУБ</div>
                </div>
            </div>
            <div className='subgroup-edit-payment'>
                <div className='subgroup-edit-payment-left'>
                    РАЗОВОЕ
                </div>
                <div className='subgroup-edit-payment-right'>
                    <Input style={{ width: 80 }} value={day} onChange={(e) => { this.paymentChange('day', ONCE_TITLE, e.target.value) }} />
                    <div>РУБ</div>
                </div>
            </div>
        </React.Fragment>

    }

    EditWrapper = ({ children, title }) => {
        return <div className='subgroup-edit-ew'>

            <div className='subgroup-edit-ew-header'>
                {title}
            </div>
            <div className='subgroup-edit-ew-content'>
                {children}
            </div>
        </div>
    }



    ButtonWrapper = () => {

        const { subgroupToEdit } = this.props;
        const { id } = subgroupToEdit || {};
        const { title, adres, city, mayDelete } = this.state;
        const disabled = !title || !adres || !city;

        return <div className='subgroup-edit-button'>
            {id ? <Button type="danger"
                disabled={!mayDelete}
                title={mayDelete ? 'Удалить' : 'Нельзя удалить, в подгруппе есть участники или посещения'}
                onClick={this.onDeleteButtonClick} >
                {"Удалить"}
            </Button> : null}
            <Button type='primary' disabled={disabled} onClick={this.onButtonClick} > {id ? 'Изменить' : "Добавить"} </Button>
        </div>
    }

    InviteWrapper = () => {

        const { subgroupToEdit } = this.props;
        const { id } = subgroupToEdit || {};
        if (!id) return null;

        if (id) return <div className='subgroup-edit-button' style={{ marginBottom: 10 }}>
            Ссылка на приглашение <br />
            {`https://capoeirasport.com/crm/invite${id}`}<br />
            <Button type='primary' onClick={() => {
                copyToClipboard(`https://capoeirasport.com/crm/invite${id}`)
            }} > КОПИРОВАТЬ </Button>
        </div>

    }

    SubgroupNaborWrapper = () => {

        const { subgroupToEdit } = this.props;
        const { id } = subgroupToEdit || {};
        if (!id) return null;

        if (id) return <div className='subgroup-edit-button' style={{ marginBottom: 10 }}>
            Ссылка на набор <br />
            {`https://capoeirasport.com/subgroup/${id}`}<br />
            <Button type='primary' onClick={() => {
                copyToClipboard(`https://capoeirasport.com/subgroup/${id}`)
            }} > КОПИРОВАТЬ </Button>
        </div>

    }

}

const SubgroupEdit = withCRMState(
    `
    client,
    studentsBySubgroups
`
)(SubgroupEditComponent)

export { SubgroupEdit }