import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import { Table } from 'src/ver2/components/Table/index';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventTablePlashka
// classname: event_table_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventTablePlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        const { isThereTable } = this.props;

        if (isThereTable) {
            const { setComponentSelfHeight, plashkaId, upperStyle } = this.props;
            const { width, height } = upperStyle;

            const oneTableHeight = (width * 0.562); //+ (width * 0.56);

            setComponentSelfHeight({ height: oneTableHeight * isThereTable.length + 145, plashkaId });
        }

    }

    render() {

        const { Event, client, upperStyle, isThereTable } = this.props;
        const { width, height } = upperStyle;

        if (!Event || !isThereTable) return <div style={{ width, height }} className='event_table_plashka'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='event_table_plashka'>
            {isThereTable.map((tableNow) => {
                return <this.OneTable tableNow={tableNow} />
            })}
        </div>
    }

    OneTable = ({ tableNow }) => {

        const { Event, client, upperStyle } = this.props;
        const { width } = upperStyle;

        const { YouTubeKey, VK_Key, FieldId } = tableNow;
        const scale = width / 1000;

        let VK_Key_segments = VK_Key ? VK_Key.split('_') : [];

        return <React.Fragment>
            {/* <div style={{ width, height: width * 0.562 }}>
                <div className='event_table_plashka_table' style={{ transform: `scale(${scale})` }}>
                    <Table client={client} EventId={Event.Id} FieldId={FieldId} />
                </div>
            </div> */}
            <br />
            {YouTubeKey ?
                <div className=''>
                    <iframe
                        id={12354 + FieldId}
                        title={'видео'}
                        width={width}
                        height={width * 0.56}
                        src={`https://www.youtube.com/embed/${YouTubeKey}`}
                        frameBorder="1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen>
                    </iframe>
                </div> : null
            }
            {VK_Key ?
                <div className=''>
                    <iframe
                        id={12354 + FieldId}
                        src={`https://vk.com/video_ext.php?oid=-${VK_Key_segments[0]}&id=${VK_Key_segments[1]}&hd=2`}
                        width={width}
                        height={width * 0.56}
                        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                        frameborder="0"
                        allowfullscreen>
                    </iframe>
                </div> : null
            }
        </React.Fragment>
    }

}



export default withEventState(`

Event,

Categorys, 
Results,
isResultsReady,

isThereTable,

client,
iamuser,
`)(EventTablePlashka)