// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Tabs, Button } from 'antd';
import { withCapoeiraSportState } from 'src/ver2/context';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';
import Search from '../menu/components/search';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент смены данных для пользователя
 */

class PersonFastRegistration extends Component {

    state = {
        activeKey: '1'
    }

    randomString = (i) => {
        let rnd = '';
        while (rnd.length < i)
            rnd += Math.random().toString(36).substring(2);
        return rnd.substring(0, i);
    };

    onData = async (data_) => {
        const { client, schoolId, groupId, onAddPerson } = this.props;
        let data = {
            name: data_.Name.trim(),
            surname: data_.Surname.trim(),
            patronymic: '_',
            gender: data_.Gender,
            birthday: data_.BirthDay || '01.01.2023',

            email: '_',

            schoolId: schoolId,
            groupId: groupId,
            cordao: '- - -'
        }

        data.login = `${data.surname}${data.name}`;
        data.password = this.randomString(5);

        if (data_.Tel) data.tel = data_.Tel.trim();

        const result = await client.serverRegisterNewPerson(data);
        onAddPerson(result);
    }

    changeTab = (activeKey) => { this.setState({ activeKey }) }

    goCheckOk = () => {
        this.setState({ activeKey: '2' })
    }

    render() {

        const { activeKey } = this.state;

        return <div className='person_registrtion'>
            <Tabs
                activeKey={activeKey} onChange={this.changeTab}
                className='person_registrtion_tabs'
                tabPosition={'top'}
                style={{ color: 'white', heightMin: 500 }}
                defaultActiveKey={activeKey}
            >
                <TabPane tab={'Проверка'} key="1">
                    <div className='person_fastregistrtion_lookat'>
                        <div className='person_fastregistrtion_lookat_title'>
                            Для начала убедитесь, что такой пользователь уже не добавлен на портал<br />
                            Если нет - нажмите кнопку далее
                        </div>
                        <div className='person_fastregistrtion_lookat_search'>
                            <Search noResultMode/>
                        </div>
                        <Button onClick={this.goCheckOk} type="primary">ДАЛЕЕ</Button>
                    </div>
                </TabPane>
                <TabPane tab={'Добавление'} key="2">
                    <DataChanger
                        header={<div>
                            Данные ученика
                        </div>}
                        data={{Gender: 1}}
                        buttonText={'ДОБАВИТЬ'}
                        buttonState={'allNeeded'}
                        onButtonClick={this.onData}
                        components={[
                            {
                                name: 'Surname',
                                title: 'Фамилия',
                                type: 'input',
                                neededText: 'Обязательное поле',
                                nextGroup: { title: '' },
                            },
                            {
                                name: 'Name',
                                title: 'Имя',
                                type: 'input',
                                neededText: 'Обязательное поле',
                                nextGroup: { title: '' },
                            },
                            {
                                name: 'Gender',
                                title: 'Пол',
                                type: 'select',
                                options: [
                                    { title: 'Мужской', key: '1' },
                                    { title: 'Женский', key: '0' }
                                ],
                                neededText: 'Обязательное поле',
                                nextGroup: { title: '' },
                            },
                            {
                                name: 'BirthDay',
                                title: 'Дата рождения',
                                text: 'Не обязательно, но надо будет внести потом',
                                type: 'date',
                                nextGroup: { title: '' },
                            },
                            {
                                name: 'Tel',
                                title: 'Телефон',
                                type: 'input',
                                text: 'Не обязательно',
                            }
                        ]}
                    />
                </TabPane>
            </Tabs>
        </div>
    }
}

export default withCapoeiraSportState('client, isMobile')(PersonFastRegistration);