import React, { Component } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';

import Loader from 'src/ver2/components/loader';
import { sortBy } from 'lodash';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventSchoolResultsPlashka
// classname: event_results_school_plashka

/**
  Это большая плашка с результатами
  У неё два режима - это показывать 
 */

class EventSchoolResultsPlashka extends Component {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false,
        }
    }

    setHight = () => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const element = document.getElementById('event_results_school_plashka_inside_id');
        if (!element) return;
        if (this.state.heightSetted === element.clientHeight) return;
        this.setState({ heightSetted: element.clientHeight });
        setComponentSelfHeight({ height: element.clientHeight + 50, plashkaId });
    }

    componentDidMount() {
        this.setHight();
    }

    componentDidUpdate() {
        this.setHight();
    }

    render() {

        const { isResultsReady, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!isResultsReady) return <div style={{ width, height }} className='event_results_school_plashka'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='event_results_school_plashka'>
            <div id='event_results_school_plashka_inside_id' className='event_results_school_plashka_inside'>
                <this.SchoolsRender />
            </div>
        </div>
    }

    SchoolsRender = () => {

        const { isResultsReady, Results, showInfoModal, Event, isMobile } = this.props;

        if (!isResultsReady) return <Loader />

        let Answer = [];
        const { schoolsPlaces } = Results;

        for (let i = 0; i < schoolsPlaces.length; i++) {

            const schoolPlace = schoolsPlaces[i];
            const {
                SchoolId,
                Gold,
                Silver,
                Bronze,
            } = schoolPlace;

            // eslint-disable-next-line no-loop-func
            const onClick = () => {
                showInfoModal({ type: 'eventSchoolResults', Data: {
                    SchoolId, EventTitle: Event.Title, EventId: Event.Id, Gold, Silver, Bronze, Place: i + 1
                }, width: isMobile ? isMobile.width - 100 : 450 });
                return;
            }

            Answer.push(
                <div key={'cr-' + SchoolId} onClick={onClick} className='event_results_school_plashka_mini_cat_container'>

                    <SchoolPlate linkTo = {null} width={140} height={40} SchoolId={SchoolId} />
                    <div className='event_results_school_plashka_medals'>
                        <PlaceRect height={40 * 0.65} Place={1} PlaceName={Gold} asMedal = {true} />
                        <PlaceRect height={40 * 0.65} Place={2} PlaceName={Silver} asMedal = {true}  />
                        <PlaceRect height={40 * 0.65} Place={3} PlaceName={Bronze} asMedal = {true}  />
                    </div>
                </div>
            )
        }
        return Answer;
    }
}

export default withEventState(`
Event,
Categorys, 
Results,
isResultsReady,
showInfoModal,

isMobile,

client,
iamuser,
`)(EventSchoolResultsPlashka)