import React, { Component } from 'react';
import { Input, Button, Modal, Avatar, Select } from 'antd';
import { withCRMState } from '../../../../context';
// import { Cordao as CordaoThing } from '../../../../../Cordao';

//import logo from './logo.svg';

import './style.css';

const { Option } = Select;

class StudentEditContainer extends Component {

    constructor(props) {
        super(props);

        const { studentToEdit } = props;
        const { student } = studentToEdit;

        this.state = {
            ...student
        }

    }

    componentWillReceiveProps(nextProps) {

        const { studentToEdit } = nextProps;
        const { student } = studentToEdit;
        this.setState({
            ...student
        })

    }

    nameChange = (e) => {
        this.setState({
            Name: e.target.value
        })
    }

    surnameChange = (e) => {
        this.setState({
            Surname: e.target.value
        })
    }

    apelidoChange = (e) => {
        this.setState({
            Apelido: e.target.value
        })
    }

    onButtonClick = () => {
        /*const { title, adres } = this.state;
        const { subgroupToEdit } = this.props;
        if (!subgroupToEdit.id) {
            this.props.addSubgroup ({
                title,
                adres
            });
        } else {
            this.props.updateSubgroup ({
                ...subgroupToEdit,
                title,
                adres
            });          
        }*/
    }

    onDeleteButtonClick = () => {

        /*const { subgroupToEdit } = this.props;
        this.props.updateSubgroup ({
            ...subgroupToEdit,
            title,
            adres
        });   */

        Modal.confirm({
            title: 'Уверены?',
            content: 'Удалить ученика из подгруппы?',
            okText: 'Удалить',
            cancelText: 'Не удалять',
            onOk: () => {
                const { moveToNewSb, studentToEdit, close } = this.props;
                const { student, subgroup } = studentToEdit;

                const { Id } = student;
                const { id } = subgroup;
                moveToNewSb({ personId: Id, subgroupFromId: id, subgroupToId: 0 } );
                close();

                // const { subgroupToEdit } = this.props;
                // this.props.deleteSubgroup ({
                //     ...subgroupToEdit
                // });  
            }
        })
    }

    render() {

        const {
            Id,
            Name,
            Surname,
            Apelido,
            Cordao,
            Foreigner
        } = this.state;

        const { client } = this.props;

        const SchoolId = 1; // ЭТО ЗАГЛУУУУШКА

        let Options = [];
        const avatarUrl = client.getUsersUrl() + Id + '.png';
        const school = client.getSchoolById(SchoolId);
        const gradation = school.Gradation; // это ассоциативный массив

        Object.keys(gradation).reduce((memo, key) => {
            Options.push(
                <Option value={key}>
                    {/* <CordaoThing width = {120} key = {`GMP-${key}`} noclick color = 'black' cordao = {client.getSchoolGradation(school.Id)[key]} /> */}
                </Option>
            );
            return memo;
        }, {});

        if (Foreigner) {
            return <div className='student-edit-main'>

                <div className='student-avatar'>
                    <Avatar size={160} src={avatarUrl} />
                </div>
                <this.EditWrapper title='Из другой группы'>
                    <div>Ученик из другой группы<br />Вы не можете его редактировать</div>
                </this.EditWrapper>
                <this.EditWrapper title='Имя'>
                    <div>{Name}</div>
                </this.EditWrapper>
                <this.EditWrapper title='Фамилия'>
                    <div>{Surname}</div>
                </this.EditWrapper>
                <this.EditWrapper title='Аелидо'>
                    <div>{Apelido}</div>
                </this.EditWrapper>
                <this.ButtonWrapper />
            </div>
        }

        return <div className='student-edit-main'>

            <div className='student-avatar'>
                <Avatar size={160} src={avatarUrl} />
            </div>

            <this.EditWrapper title='Имя'>
                <Input value={Name} onChange={this.nameChange} />
            </this.EditWrapper>
            <this.EditWrapper title='Фамилия'>
                <Input value={Surname} onChange={this.surnameChange} />
            </this.EditWrapper>
            <this.EditWrapper title='Аелидо'>
                <Input value={Apelido} onChange={this.apelidoChange} />
            </this.EditWrapper>
            {/* <this.EditWrapper>
                <Select style={{ width: 250, height: 60 }} size='large'
                    value={Cordao}
                    onChange={(value) => { this.setState({ Cordao: value }) }}
                >
                    {Options}
                </Select>
            </this.EditWrapper> */}
            <this.ButtonWrapper />
        </div>
    }

    EditWrapper = ({ children, title }) => {
        return <div className='student-edit-ew'>
            <div className='student-edit-ew-header'>
                {title}
            </div>
            <div className='student-edit-ew-content'>
                {children}
            </div>
        </div>
    }

    ButtonWrapper = () => {

        const { studentToEdit } = this.props;
        const { student, subgroup } = studentToEdit;
        const { title } = subgroup;
        const { Id } = student || {};
        const { Name, Surname } = this.state;
        const disabled = !Name || !Surname;
        return <div className='student-edit-button'>
            {Id ? <div className='student-edit-button-row'>
                <Button type="danger" onClick={this.onDeleteButtonClick} > {"Удалить из подгруппы "} </Button>
                <span>
                    {title}
                </span>
            </div> : null}
            {/* <Button type='primary' disabled={true} onClick={this.onButtonClick} > {'Изменить'} </Button> */}
        </div>
    }

}

const StudentEdit = withCRMState(
    `
    client,
    studentsBySubgroups,
    moveToNewSb
`
)(StudentEditContainer)

export { StudentEdit }