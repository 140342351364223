export const widthByCount = (count) => {

    const max_x = 10;

    const h = 0.5; // Это отступ от левого и правого края
    const z = 0.07; // это расстояние между карточками

    const x = 100 / (2*h + count + z * (count - 1));

    return Math.min(x, max_x); // возвращает x относительно экрана, т.е. ширина карточки - `x wh`

}

export const widthByCountMobile = (count) => {

    const max_x = 20;

    const h = 0.5; // Это отступ от левого и правого края
    const z = 0.07; // это расстояние между карточками

    const x = 100 / (2*h + count + z * (count - 1));

    return Math.min(x, max_x); // возвращает x относительно экрана, т.е. ширина карточки - `x wh`

}