import React, { Component } from 'react';
import './component.css';
import { groupBy, orderBy } from 'lodash'

import { ElementCard } from '../element-card';

import { withCapoeiraSportState } from 'src/ver2/context';

import { ICON_VIDEO, ICON_MINE, ICON_TIER1, ICON_TIER2, ICON_TIER3 } from '../icons';
import { Tooltip } from 'antd';

class SoloElements extends Component {
  
  constructor (props) {
    super (props);

    this.elements = props.client.getServerElements();

    
    this.state = {}

    
    this.groupElements = groupBy (this.elements, 'type');
    const element_keys = Object.keys(this.groupElements);

    this.state.typesFilter = {};

    for ( let i = 0; i < element_keys.length; i++ ) {

      this.state.typesFilter[element_keys] = null;

      this.groupElements[element_keys[i]] = orderBy(this.groupElements[element_keys[i]], 'Points', 'asc');
      this.groupElements[element_keys[i]] = orderBy(this.groupElements[element_keys[i]], 'Tier', 'asc');
    }

    this.state.filters = {
      onlyWithVideoFilter: false,
      onlyMineFilter: false,
      tier1Filter: false,
      tier2Filter: false,
      tier3Filter: false,
    }

    this.state.filtersMobileVisible = false;

    this.UserId = null;

    this.loadData();

  }

  loadData () {
    // this.props.client.getMySoloUserOptionsProxy( ).then ( result => {      
    //   this.UserId = this.props.client.loggedUserId();
    //   this.setState ({userOptions: result})
    // })
  }

  componentWillUpdate ( ) {

    // const { client } = this.props;
    // if (this.props.client.loggedUserId() !== this.UserId) {
    //   this.loadData()
    // }

  }

  Sortirovka = () => {
    return <div className='solo-elements-sort-and-filters-div'>
          
      <div className = 'solo-elements-sort-and-filters-title-div'>
        СОРТИРОВКА
      </div>

      <div className = 'solo-elements-sort-and-filters-body-div'>
        по алфавиту <br/>
        по уровню
      </div>

    </div>
  }

  Filter = () => {

    const { userOptions, filtersMobileVisible } = this.state;
    
    const { isMobile } = this.props;

    if ( isMobile ) {

      return <div className='solo-elements-sort-and-filters-div-mobile'>
        <div onClick = {() => {
          const { filtersMobileVisible } = this.state;
          this.setState({filtersMobileVisible: !filtersMobileVisible})
        }} className = 'solo-elements-sort-and-filters-title-div-mobile'>
          ФИЛЬТРЫ 
        </div>
        { filtersMobileVisible ? <div className = 'solo-mobile-filters-content'>
        <this.OneFilter 
          stateFilterName = 'onlyWithVideoFilter'
          ICON = {ICON_VIDEO}
          title = 'только с видео'
        />
        {userOptions && userOptions.myCover ? <this.OneFilter 
          stateFilterName = 'onlyMineFilter'
          ICON = {ICON_MINE}
          title = 'только мои'
        /> : null}
        <this.OneFilter 
          stateFilterName = 'tier1Filter'
          ICON = {ICON_TIER1}
          title = 'показывать Tier 1'
        />
        <this.OneFilter 
          stateFilterName = 'tier2Filter'
          ICON = {ICON_TIER2}
          title = 'показывать Tier 2'
        />
        <this.OneFilter 
          stateFilterName = 'tier3Filter'
          ICON = {ICON_TIER3}
          title = 'показывать Tier 3'
        />
        </div> : null }
      </div>

    }

    return <div className='solo-elements-sort-and-filters-div'>
          
      <div className = 'solo-elements-sort-and-filters-title-div'>
        ФИЛЬТРЫ 
      </div>
      
      <div className = 'solo-elements-sort-and-filters-body-div'>
        <this.OneFilter 
          stateFilterName = 'onlyWithVideoFilter'
          ICON = {ICON_VIDEO}
          title = 'только с видео'
        />
        {userOptions && userOptions.myCover ? <this.OneFilter 
          stateFilterName = 'onlyMineFilter'
          ICON = {ICON_MINE}
          title = 'только мои'
        /> : null}
        <this.OneFilter 
          stateFilterName = 'tier1Filter'
          ICON = {ICON_TIER1}
          title = 'показывать Tier 1'
        />
        <this.OneFilter 
          stateFilterName = 'tier2Filter'
          ICON = {ICON_TIER2}
          title = 'показывать Tier 2'
        />
        <this.OneFilter 
          stateFilterName = 'tier3Filter'
          ICON = {ICON_TIER3}
          title = 'показывать Tier 3'
        />
      </div>

    </div>
  }

  changeFiler = (filterName) => {
    const filterValue = this.state.filters[filterName];
    this.setState ({
      filters: {
        ...this.state.filters,
        [filterName]: !filterValue
      }
    })
  }

  OneFilter = ({stateFilterName, ICON, title}) => {

    const filterValue = this.state.filters[stateFilterName];
    const className = filterValue ? 'solo-elements-sort-and-filters-one-filter-title-div solo_filter_checked' : 'solo-elements-sort-and-filters-one-filter-title-div'

    return <div onClick = {(e) => {  
      this.changeFiler(stateFilterName)
    }} className = 'solo-elements-sort-and-filters-one-filter-div'>

      {ICON(filterValue)} <div className = {className} >{title}</div>

    </div>

  }

  Elements = () => {

    const {client, isMobile, iamuser} = this.props;
    const {filters, typesFilter, userOptions} = this.state;

    let myCover = null;
    let myElements = {}

    if (userOptions) {
      myCover = userOptions.myCover ? userOptions.myCover : null;
      myElements = userOptions.myElements ? userOptions.myElements : {};
    }

    const {onlyWithVideoFilter, onlyMineFilter, tier1Filter, tier2Filter, tier3Filter} = filters;

    const element_keys = Object.keys(this.groupElements);

    const sizes = {
      width: isMobile ? 20 : 10
    }

    let arrayStyle = 'solo-elements-array-div';
    if (isMobile) arrayStyle = 'solo-elements-array-div-mobile'

    return <div className={arrayStyle}>
          
       {element_keys.map (element_key => {  

          const subtypes = client.getSubtypesByType(element_key);

          return <div key = {`sead-${element_key}`} className = 'solo-elements-one-container-div'>

             <div className = { isMobile ? 'solo-elements-one-container-title-div-mobile' : 'solo-elements-one-container-title-div'} >
              { client.getTypeDiscription(element_key, true)}
              { subtypes.map ( subtype => {

                let card_src = `st_${subtype}`;

                if (subtype === 'jump') card_src = `st_${element_key}_${subtype}`;

                const style = typesFilter[element_key] === subtype ? { opacity: 1 } : {};

                return <Tooltip title = {client.getSubtypeName(subtype)}>
                  <img style = {style} onClick = {(e) => {

                    if (typesFilter[element_key] === subtype) {
                        this.setState({ typesFilter: {...this.state.typesFilter, [element_key] : null }  })
                    } else {
                      this.setState({ typesFilter: {...this.state.typesFilter, [element_key] : subtype }  })
                    }

                  }} src = {client.getTechnicLogoUrl(card_src)} />
                </Tooltip>  
                } ) }

             </div>

             <div className = { isMobile ? 'solo-elements-one-container-elements-div-mobile' : 'solo-elements-one-container-elements-div'}>
             {
                   this.groupElements[element_key].map ( 
                     element => {

                        // а тут мы применяем фильтры

                        if (onlyWithVideoFilter && !element.isVideo) return null;
                        if (onlyMineFilter && !myElements[element.key]) return null;
                        if (tier1Filter || tier2Filter || tier3Filter) {
                          if (!tier1Filter && element.Tier === 1 ) return null;
                          if (!tier2Filter && element.Tier === 2 ) return null;
                          if (!tier3Filter && element.Tier === 3 ) return null;
                        }


                        if (typesFilter[element_key]) {
                          const { subtypes } = element;
                          if (!subtypes || !subtypes.find(x => x === typesFilter[element_key])) return null;
                        }

                        return <ElementCard 
                          key = {`seocd-${element.key}`}
                          isMobile = {isMobile}
                          cover_id = {myCover && myElements[element.key] ? myCover : null}
                          is_mine = {true}
                          is_login = {iamuser}
                          clickOnAdd = {this.clickOnAddElement}
                          {...{client, sizes, element_key: element.key}}
                        />
                     }  
                   )
             }
             
             </div>
          </div>
       })}

    <div className = 'solo-elements-one-container-div'>
      <div style = {{minHeight:150}}>
      </div>
    </div>



   </div> 
  }

  clickOnAddElement = (element_key) => {

    return null;

    const { client } = this.props;
    const { userOptions } = this.state;
    const { myElements } = userOptions;

    let MyElements = '';
    const keys = Object.keys(myElements)

    // если такой есть - убираем

    if (myElements[element_key]) {

      for ( let i = 0; i < keys.length; i++ ) {
        if (keys[i] !== element_key) {
          MyElements += keys[i];
          if ( i < keys.length - 1) MyElements += '/';
        }
      }

    } else {
      MyElements = this.state.userOptions.MyElements + '/' + element_key;
    }


    client.setMySoloUserOptions({MyElements}).then (
      result => {
        client.getMySoloUserOptionsProxy(true).then ( result => {
          this.setState ({userOptions: result});
        })
      }
    )

  }

  render () { 

    const { isMobile } = this.props;

    if (!isMobile) {
      return <div className='solo-elements-div'>
          
        <div className='solo-elements-leftblock-div'>       
           <this.Filter/>
        </div>

        <div className='solo-elements-rightblock-div'>
          
          <this.Elements/>

        </div>

      </div>
    }

    return (
        <div className='solo-elements-div-mobile'>
            <this.Filter/>
            <this.Elements/>            
        </div>
    );
   }




}

export default withCapoeiraSportState(`
client,
isMobile,
iamuser,
`)(SoloElements)