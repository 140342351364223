import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import EventWrapper from 'src/ver2/components/event/eventWrapper';

import Map from './map';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class EventAdresPlashka
// classname: event_adres_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventAdresPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.mapId = Math.round(Math.random() * 1000);
    }

    geolokation() {
        const { Event } = this.props;
        return <div className='event_adres_plashka_map' >
            {Event.City + ' , ' + Event.Adress}
        </div>
    }

    render() {

        const { Event, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_adres_plashka'>
            <Loader />
        </div>

        if (!Event.Adress) {
            return <div style={{ width, height }} className='event_adres_plashka'>
                <div className = 'event_adres_plashka_no_adres'>
                    <span>
                        АДРЕС ПОКА НЕ ЗАДАН
                    </span>
                </div>
            </div>
        }

        return <div style={{ width, height }} className='event_adres_plashka'>
            {this.geolokation()}
            <Map mapId={'event_mapid' + this.mapId} width={width * 0.9} height={width * 0.9} adres={Event.City + ', ' + Event.Adress} />
        </div>
    }

}

const EventAdresPlashkaWrapped = (props) => <EventWrapper Wrapped={EventAdresPlashka} eventId={props.eventId} componentProps={{ ...props }} />
export default withCapoeiraSportState('client')(EventAdresPlashkaWrapped);