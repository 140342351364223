import React, { Component } from 'react';
import './component.css';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

/*

  Тут мы непосредственно создаём связку. Сюда приходит ID раунда

*/

export class SequenceConfirm extends Component {


  constructor(props) {

    super(props);

    this.state = {}

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {

      this.state.mobile = true;

    } else this.state.mobile = false;

    this.state.code = null;
    this.state.reloading = false;

  }

  render() {

    const { reloading } = this.state;
    const { our_svyazka, rules, points } = this.props;

    if (reloading) {
      return <div>Перехожу на связку</div>
    }

    // ЭТО УСТАРЕЛО !
    // if (code) {
    //   return (
    //     <div className='dndconfirm-div'>
    //        Ваш код - {code} <br/>
    //       <a href={`whatsapp://send?text=КОД СВЯЗКИ: ${code}&phone=${'+79267674316'}&abid=${'+79267674316'}`}>ОТПРАВИТЬ ВИДЕО НА WHATSUP</a> 
    //       <br/>
    //        <Link to = {`/solo/round/${RoundId}`}>
    //         <Button onClick = {() => {reloadPage()}} type = 'primary'>
    //             Вернуться назад
    //         </Button>
    //        </Link>
    //     </div>
    // );  
    // }

    // проверяем дополнительные еще условия

    console.log('SequenceConfirm', { rules, points })
    if (rules && rules.MinPoints && points < rules.MinPoints) {
      return <div className={'dndconfirm-div dndtry-div-mobile-svyazka-title-less'}>
        Не набраны необходимые базовые очки
      </div>
    }

    if (rules && rules.NeededElements && rules.NeededElements.length > 0) {
      for (let i = 0; i < rules.NeededElements.length; i++) {
        if (!our_svyazka.find(x => x && x.element_id === rules.NeededElements[i])) {
          return <div className={'dndconfirm-div dndtry-div-mobile-svyazka-title-less'}>
            Нет всех необходимых элементов
          </div>
        }
      }
    }

    return (
      <div className='dndconfirm-div'>
        <Button type='primary' onClick={() => {
          this.setState({ reloading: true })
          this.onClick()
        }}>
          ОТПРАВИТЬ
        </Button>
      </div>
    );
  }

  onClick = async () => {

    const { client, RoundId, our_svyazka, sendSequence, setOktatAndChangedPoints } = this.props;

    const iamuser = client.iamuser;

    if (!iamuser || !iamuser.data || !iamuser.data.Id) return;

    const UserId = iamuser.data.Id;

    let Sequence = '';
    let Options = '';

    for (let i = 0; i < our_svyazka.length; i++) {

      Sequence += our_svyazka[i].element_id;
      if (i < our_svyazka.length - 1) {
        Sequence += '/';
      }

      const element = client.elements_as_object[our_svyazka[i].element_id];
      const { changedPoints } = setOktatAndChangedPoints(element);

      if (changedPoints) {
        if (Options !== '') Options += '/';
        Options += `en_${i}*${changedPoints.toFixed(2)}`;
      }

    }

    if (Options === '') Options = ' ';

    sendSequence();

    const code = await client.setSoloUserSequences({ UserId, RoundId, Sequence, Options });

    document.location.href = "https://capoeirasport.com/sequence/" + code;

  }

}