import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
import { Icon } from 'antd';
// import { Link } from 'react-router-dom';

import './style.css';

// class RegionInfoPlashka
// classname: region_info_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RegionInfoPlashka extends PureComponent {

    state = {
        count: 0
    }

    componentDidMount = () => {
        this.loadInfo();
    }

    componentDidUpdate ({region}) {
        if (region !== this.props.region) {
            this.loadInfo();
        }
    }

    loadInfo = async () => {
        const { client, region } = this.props;
        let count = 0;
        const count_ = await client.amountInRegion(region);

        if (count_ &&  count_[0]) {
            count = count_[0].Amount;
        }
        this.setState({count})
    }


    render() {

        const { upperStyle, client, region } = this.props;
        const { width, height } = upperStyle;
        const { count } = this.state;

        const title = client.regions[region] ? client.regions[region].title : '';

        // if (!Event) return <div style={{ width, height }} className='region_schools_plashka'>
        //     <Loader />
        // </div>


        return <div style={{ width, height }} className='region_info_plashka'>
            <div className='region_info_plashka_title'>
                {title}
            </div>
            {count ? <span><Icon type='user' /> {count} </span> : null }
        </div>
    }

}

export default withCapoeiraSportState(`
client,
iamuser,
`)(RegionInfoPlashka)