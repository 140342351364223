/* 

  эта штука возвращает ОДНУ карточку в зависимости от заданных параметров:

  если задан element_id, значит это карточка с определённым точно элементом
  element_id - конкретное id элемента

  // если !element_id, значит эта карточка в связке под расположение
  type - тип
  subtype - [] - субтипы

  // дополнительные параметры

  isDragging - перетаскивается

  mayDropElement - мы можем бросить сюда элемент
  hoverElement - мы навелись


*/

import React from 'react';

import { getCardKey } from 'src/ver2/containers/challenges/helpers';
// import { ElementCard } from 'src/ver2/pages/technic/components/element-card';
import { ElementCard } from 'src/ver2/containers/challenges/components/element-card';

export const Card = (props) => {

    const { element_id } = props;
    if (element_id) return <Element {...props} />

    if (!element_id)
        return <SvyazkaBlank {...props} />
}

const Element = ({ client, element_id, elements, reff, isDragging, sizes, coverId }) => {
    const className = 'dndtry-div-mobile-svyazka-row-card our';


    const { width, is_selected } = sizes;

    const div_data = {
        ref: reff,
        style: {
            opacity: isDragging ? 0.5 : 1,
            width: `${width}vw`,
            minWidth: `${width}vw`,
            height: `${width * 1.4}vw`,
            ...is_selected ? { border: '4px solid green' } : {}
        },
        className
    }

    // const sizes = {
    //     style: {
    //         width: `${width}vw`,
    //         minWidth: `${width}vw`,
    //         height: `${width * 1.4}vw`,
    //     },
    //     no_select: true
    // }

    return <div {...div_data}>
        <ElementCard
            cover_id={coverId}
            is_mine={true}
            is_login={true}
            sizes = {{...sizes, dem: 'vw', no_minus: true, no_select: true}}
            {...{ client, element_key: element_id }}
        />
    </div>
}

const SvyazkaBlank = ({
    client,
    type,
    subtype,
    element,
    reff,
    mayDropElement,
    hoverElement,
    sizes
}) => {

    const className = 'dndtry-div-mobile-svyazka-row-card'

    const { width, is_selected } = sizes;

    const style = {
        ...(mayDropElement) ? { background: 'green' } : {},
        ...(hoverElement && mayDropElement) ? { background: 'yellow' } : {},
        width: `${width}vw`,
        minWidth: `${width}vw`,
        height: `${width * 1.4}vw`,
        ...is_selected ? { border: '4px solid green' } : {}
    }

    const div_data = {
        className,
        ref: reff,
        style
    }

    return <div {...div_data}>

        {/* <div className='dndtry-div-mobile-svyazka-row-card-background'>
            <img src='../../images/cover.png' />
        </div> */}

        <div className='dndtry-div-mobile-svyazka-row-card-content'>
            <img src={client.getTechnicLogoUrl(element ? element : getCardKey({ type, subtype }))} />
            <div className='dndtry-div-mobile-svyazka-row-card-content-title'>
                {client.getCardName({ type, subtype, element })}
            </div>
        </div>
    </div>
}

