import React, { PureComponent } from 'react';
import PersonState, { withPersonState } from './context';
import { Modal, message } from 'antd';
import Loader from 'src/ver2/components/loader';

import PBP from 'src/ver2/plashki/person/bigPlashka';
import PBPMobile from './plashki/bigPlashka';

// import PersonRaitingPlashka from 'src/ver2/plashki/person/raitingPlashka';
import PersonRaitingPlashka from './plashki/raitingPlashka';
import MedalShalvePlashka from 'src/ver2/plashki/medal/medalShalve/byPerson';
import PersonVideoBigPlashka from 'src/ver2/plashki/person/videoBigPlashka';
import PersonRaitingHistoryPlashka from 'src/ver2/plashki/person/raitingHistory';

import ButtonPlashka from 'src/ver2/plashki/ui/button';
import PersonDataChange from 'src/ver2/containers/personDataChange';
import PersonInventar from 'src/ver2/containers/personInventar';



import PersonDataPlashka from './plashki/dataPlashka';
import PersonVideoMiniPlashka from './plashki/videoMiniPlashka';
import PersonMyEventsList from './plashki/myEvents';
import PersonWall from './plashki/wall';

import PersonFrkAccredPlashka from './plashki/fkrAccred';
import PersonElementsPlashka from './plashki/elements';
import PersonNoDataPlashka from './plashki/nodata';

import PersonQuestsPlashka from './plashki/quests';
import PersonFightsPlashka from './plashki/fights';
import PersonEnemysPlashka from './plashki/enemys';
import SeqMonitorPlashka from 'src/ver2/plashki/sequencesMonitor';

import PersonAchivmentsPlashka from './plashki/achivments';

import { TrefaLayout } from 'src/ver2/containers/layout';


import './styles.css';

const copyToClipboard = (value) => {
    const copytext = document.getElementById('copyCopyText');
    if (copytext) {
        copytext.value = value;
        copytext.select();
        document.execCommand('copy');
        message.success('Скопировано ' + value);
    }
}

class PersonPage extends PureComponent {

    state = {
        dataFlag: false,
        competitionVideosFlag: false
    }

    //                prevProps, prevState
    componentDidUpdate({ eventsLoaded }) {
        // if (this.props.eventsLoaded && !eventsLoaded) {
        //     console.log(this.props.client.getEventsForSlider());
        // }
        // if (this.props.match.params.personId !== this.state.personId) {
        //     this.setState({ personId: this.props.match.params.personId })
        // }
    }

    changeDataFlag = (dataFlag = true) => this.setState({ dataFlag })
    changeInventarFlag = (inventarFlag = true) => this.setState({ inventarFlag })
    changeCompetitionVideosFlag = (competitionVideosFlag = true) => this.setState({ competitionVideosFlag })

    render() {

        const width = 1200;
        const { dataFlag, inventarFlag, competitionVideosFlag } = this.state;
        const { Person, noSuchPerson, isPersonReady, iAmPerson, isMobile,
            Sequences, isSequencesReady, iamuser, client } = this.props;

        if (!Person && noSuchPerson) {
            return <div style={{ width }} className='person_page'>
                <span>
                    Такого пользователя не существует
                </span>
            </div>
        }

        if (!isPersonReady) {
            return <div style={{ width }} className='person_page'>
                <Loader text='Загрузка пользователя' />
            </div>
        }

        const personId = Person.Id;

        const { changeDataFlag, changeInventarFlag } = this;

        if (isMobile) return <this.MobileRender />

        // тут не мобильный рендеринг

        let components_ = [];

        if (iamuser && (iamuser.Id === 2 || iamuser.Id === 83)) {
            components_.push(
                {
                    component: ButtonPlashka,
                    cell_x: 5,
                    collapse: 1,
                    id: 15151515,
                    componentProps: {
                        height: 45, text: 'ДАННЫЕ УЧЕНИКА', onClick: () => {

                            client.userRecoveryPassword(personId).then(result => {
                                if (result && !result.error && result.Login && result.Password) {

                                    const { BirthDay } = Person;

                                    Modal.info({
                                        content: <div>
                                            ДАТА РОЖДЕНИЯ: {BirthDay} <br />
                                            ВОССТАНОВЛЕНИЕ: <br />
                                            <span onClick={copyToClipboard(`https://capoeirasport.com?login=${result.Login}&password=${result.Password}`)}>
                                                {`https://capoeirasport.com?login=${result.Login}&password=${result.Password}`}
                                            </span>
                                        </div>
                                    })
                                } else {
                                    Modal.error({
                                        content: <div>
                                            ЧТО-ТО ПОШЛО НЕ ТАК: <br />
                                            {(result && result.error) ? result.error : 'Ошибка не определена'} <br />
                                        </div>
                                    })
                                }
                            })


                        }
                    }
                }
            );
        }

        components_.push(
            {
                component: ButtonPlashka,
                cell_x: 5,
                collapse: 1,
                id: 131415777,
                componentProps: { height: 45, text: 'МОИ ДОСТИЖЕНИЯ 2022-2023', linkTo: `/seasonresult/${personId}` }
            },
            {
                cell_x: 5,
                collapse: 1,
                componentProps: { height: 14 }
            }
        );

        if (iAmPerson) {
            components_.push(
                {
                    component: ButtonPlashka,
                    cell_x: 5,
                    collapse: 1,
                    id: 15,
                    componentProps: { height: 45, text: 'РЕДАКТИРОВАТЬ ДАННЫЕ', onClick: changeDataFlag }
                },
                {
                    cell_x: 5,
                    collapse: 1,
                    componentProps: { height: 14 }
                },
                {
                    component: ButtonPlashka,
                    cell_x: 5,
                    collapse: 1,
                    id: 99915,
                    componentProps: { height: 45, text: 'ВАШИ ПРЕДМЕТЫ', onClick: changeInventarFlag }
                },
                {
                    cell_x: 5,
                    collapse: 1,
                    componentProps: { height: 14 }
                },
                {
                    component: PersonFrkAccredPlashka,
                    cell_x: 5,
                    collapse: 1,
                    id: 6915,
                    componentProps: { height: 1, personId }
                },
                {
                    cell_x: 5,
                    collapse: 1,
                    componentProps: { height: 14 }
                },
                {
                    component: PersonNoDataPlashka,
                    cell_x: 5,
                    collapse: 1,
                    id: 25,
                    componentProps: { height: 0, personId, changeDataFlag }
                },
                {
                    cell_x: 5,
                    collapse: 1,
                    componentProps: { height: 14 }
                }
            );
        }

        components_.push(
            {
                component: PBP,
                cell_x: 2,
                collapse: 2,
                id: 1,
                componentProps: { height: 300, personId, changeDataFlag }
            },
            {
                component: PersonAchivmentsPlashka,
                cell_x: 2,
                collapse: 2,
                id: 253,
                componentProps: { componentSelfHeight: true, height: 2, yPadding: true, _title: 'Достижения' }
            },
            {
                component: PersonRaitingPlashka,
                cell_x: 2,
                collapse: 2,
                id: 2,
                componentProps: { height: 130, yPadding: true, _title: 'Рейтинг по сезонам' }
            },
            {
                component: PersonWall,
                cell_x: 2,
                collapse: 1,
                id: 6969,
                componentProps: { height: 700, personId, yPadding: true }
            },

            (iAmPerson ? {
                component: PersonQuestsPlashka,
                cell_x: 3,
                collapse: 1,
                id: 696969,
                componentProps: { height: 700, personId, yPadding: true, _title: 'Задания' }
            } :
                {
                    cell_x: 3,
                    collapse: 1,
                    componentProps: { height: 2 }
                })

            ,

            {
                component: PersonElementsPlashka,
                cell_x: 2,
                collapse: 2,
                id: 692,
                componentProps: { height: 250, yPadding: true, _title: 'Статистика связок' }
            }
        );

        if (isSequencesReady && Sequences && Sequences.length) {
            components_.push({
                component: SeqMonitorPlashka,
                cell_x: 2,
                collapse: 2,
                id: 6969699,
                componentProps: { height: 250, _title: 'Топ связок', yPadding: true, sequences: Sequences, maxSequences: Sequences.length }
            })
        }


        if (iAmPerson)
            components_.push({
                component: PersonMyEventsList,
                cell_x: 2,
                collapse: 2,
                id: 125,
                componentProps: { yPadding: true, _title: 'Вы участвуете', height: 155 }
            });

        components_.push(
            {
                component: PersonDataPlashka,
                cell_x: 5,
                collapse: 1,
                id: 3,
                componentProps: { height: 0, personId }
            }
        );

        if (iAmPerson)
            components_.push({
                component: ButtonPlashka,
                cell_x: 5,
                collapse: 1,
                id: 66125,
                componentProps: { yPadding: true, text: 'МАГАЗИН', height: 75, linkTo: '/shop/1' }
            });

        components_.push(
            // {
            //     component: MedalShalvePlashka,
            //     cell_x: 5,
            //     collapse: 1,
            //     id: 5,
            //     componentProps: { height: 150, yPadding: true, _title: 'Медали', personId }
            // },
            {
                component: PersonFightsPlashka,
                cell_x: 5,
                collapse: 1,
                id: 666867,
                componentProps: { height: 110, yPadding: true, _title: 'Статистика боёв', personId }
            },
            {
                component: PersonVideoMiniPlashka,
                cell_x: 5,
                collapse: 1,
                id: 4,
                componentProps: { videoHeight: 250, height: 150, yPadding: true, _title: 'Видео боёв', titleOnClick: this.changeCompetitionVideosFlag, personId }
            },
            {
                component: PersonEnemysPlashka,
                cell_x: 5,
                collapse: 1,
                id: 666868,
                componentProps: { height: 1, yPadding: true, _title: 'Главные конкуренты' }
            },
            {
                component: PersonRaitingHistoryPlashka,
                cell_x: 2,
                collapse: 2,
                id: 6,
                componentProps: { height: 150, yPadding: true, _title: 'Рейтинг и выступления', personId }
            },


        )

        return <div style={{ width }} className='person_page'>
            <Modal
                className='aaa'
                visible={dataFlag}
                onCancel={() => { this.changeDataFlag(false) }}
                footer={null}
                title={'Редактировать данные'}
                width={1200}
            >
                {dataFlag ? <PersonDataChange dataFlag={dataFlag} /> : null}
            </Modal>
            <Modal
                className='aaa person_page_inventar_modal'
                visible={inventarFlag}
                onCancel={() => { this.changeInventarFlag(false) }}
                footer={null}
                title={'Инвентарь'}
                width={800}
            >
                {inventarFlag ? <PersonInventar personId={personId} /> : null}
            </Modal>
            <Modal
                className='aaa'
                visible={competitionVideosFlag}
                onCancel={() => { this.changeCompetitionVideosFlag(false) }}
                footer={null}
                title={'Видео боёв'}
                width={1200}
            >
                <PersonVideoBigPlashka personId={personId} />
            </Modal>
            <TrefaLayout greedsX={[24, 490, 340, 24, 1200 - 24 - 830 - 24 - 24, 24]}
                components={
                    components_
                }
            />
        </div>
    }

    MobileRender = () => {

        const { dataFlag, competitionVideosFlag, inventarFlag } = this.state;
        const { client, Person, isMobile, iAmPerson, iamuser, isSequencesReady, Sequences } = this.props;
        const { changeDataFlag, changeInventarFlag } = this;

        const { width } = isMobile;
        let components_ = [];

        const personId = Person.Id;

        components_.push(
            {
                cell_x: 2,
                collapse: 1,
                componentProps: { height: 24 }
            },
            {
                component: PBPMobile,
                cell_x: 2,
                collapse: 1,
                id: 1,
                componentProps: { height: 150, personId, changeDataFlag }
            },
            {
                cell_x: 2,
                collapse: 1,
                componentProps: { height: 24 }
            },
            {
                component: PersonWall,
                cell_x: 2,
                collapse: 1,
                id: 6969,
                componentProps: { height: 530, personId, yPadding: true }
            }
        );

        if (iamuser && (iamuser.Id === 2 || iamuser.Id === 83)) {
            components_.push(
                {
                    component: ButtonPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 15151515,
                    componentProps: {
                        height: 45, text: 'ДАННЫЕ УЧЕНИКА', onClick: () => {

                            client.userRecoveryPassword(personId).then(result => {
                                if (result && !result.error && result.Login && result.Password) {

                                    const { BirthDay } = Person;

                                    Modal.info({
                                        content: <div>
                                            ДАТА РОЖДЕНИЯ: {BirthDay} <br />
                                            ВОССТАНОВЛЕНИЕ: <br />
                                            <span onClick={copyToClipboard(`https://capoeirasport.com?login=${result.Login}&password=${result.Password}`)}>
                                                {`https://capoeirasport.com?login=${result.Login}&password=${result.Password}`}
                                            </span>
                                        </div>
                                    })
                                } else {
                                    Modal.error({
                                        content: <div>
                                            ЧТО-ТО ПОШЛО НЕ ТАК: <br />
                                            {(result && result.error) ? result.error : 'Ошибка не определена'} <br />
                                        </div>
                                    })
                                }
                            })


                        }
                    }
                }
            );
        }

        components_.push(
            {
                component: ButtonPlashka,
                cell_x: 2,
                collapse: 1,
                id: 131415777,
                componentProps: { height: 45, text: 'МОИ ДОСТИЖЕНИЯ 2022-2023', linkTo: `/seasonresult/${personId}` }
            },
            {
                cell_x: 2,
                collapse: 1,
                componentProps: { height: 14 }
            }
        );

        if (iAmPerson) {
            components_.push(
                {
                    component: ButtonPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 15,
                    componentProps: { height: 45, text: 'РЕДАКТИРОВАТЬ ДАННЫЕ', onClick: changeDataFlag }
                },
                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 24 }
                },

                {
                    component: ButtonPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 99915,
                    componentProps: { height: 45, text: 'ВАШИ ПРЕДМЕТЫ', onClick: changeInventarFlag }
                },

                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 24 }
                },
                {
                    component: PersonFrkAccredPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 6915,
                    componentProps: { height: 1, personId }
                },
                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 24 }
                },
                {
                    component: PersonNoDataPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 7725,
                    componentProps: { height: 0, personId, changeDataFlag }
                },
                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 24 }
                },
                {
                    component: PersonMyEventsList,
                    cell_x: 2,
                    collapse: 1,
                    id: 125,
                    componentProps: { yPadding: true, _title: 'Вы участвуете', height: 155 }
                },
                {
                    component: PersonQuestsPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 696969,
                    componentProps: { height: 300, personId, yPadding: true, _title: 'Задания' }
                }
            );
        }

        components_.push(
            {
                component: PersonDataPlashka,
                cell_x: 2,
                collapse: 1,
                id: 3,
                componentProps: { height: 0, personId }
            })

        if (iAmPerson)
            components_.push(

                {
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { height: 24 }
                },
                {
                    component: ButtonPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 66125,
                    componentProps: { yPadding: true, text: 'МАГАЗИН', height: 75, linkTo: '/shop/1' }
                });

        components_.push(
            {
                cell_x: 2,
                collapse: 1,
                componentProps: { height: 24 }
            },
            {
                component: PersonElementsPlashka,
                cell_x: 2,
                collapse: 2,
                id: 692,
                componentProps: { height: 170, yPadding: true, _title: 'Статистика связок' }
            })

        if (isSequencesReady && Sequences && Sequences.length) {
            components_.push({
                component: SeqMonitorPlashka,
                cell_x: 2,
                collapse: 2,
                id: 6969699,
                componentProps: { height: 115, _title: 'Топ связок', yPadding: true, sequences: Sequences, maxSequences: Sequences.length }
            })
        }

        components_.push(
            {
                cell_x: 2,
                collapse: 1,
                componentProps: { height: 24 }
            },
            {
                component: PersonRaitingPlashka,
                cell_x: 2,
                collapse: 1,
                id: 2,
                componentProps: { height: 105, personId, yPadding: true }
            },
            {
                cell_x: 2,
                collapse: 1,
                componentProps: { height: 24 }
            },
            {
                component: PersonFightsPlashka,
                cell_x: 2,
                collapse: 2,
                id: 666867,
                componentProps: { height: 95, yPadding: true, _title: 'Статистика боёв', personId }
            },
            {
                component: PersonEnemysPlashka,
                cell_x: 2,
                collapse: 2,
                id: 666868,
                componentProps: { height: 1, yPadding: true, _title: 'Главные конкуренты' }
            },
            {
                component: PersonRaitingHistoryPlashka,
                cell_x: 2,
                collapse: 1,
                id: 6,
                componentProps: { height: 150, yPadding: true, _title: 'Рейтинг и выступления', personId }
            },
            {
                component: PersonVideoMiniPlashka,
                cell_x: 2,
                collapse: 1,
                id: 4,
                componentProps: { videoHeight: 280, height: 220, yPadding: true, _title: 'Видео боёв', titleOnClick: this.changeCompetitionVideosFlag, personId }
            }
        )

        return <div style={{ width }} className='person_page'>
            <Modal
                className='aaa'
                visible={dataFlag}
                onCancel={() => { this.changeDataFlag(false) }}
                footer={null}
                title={'Редактировать данные'}
                width={330}
            >
                {dataFlag ? <PersonDataChange dataFlag={dataFlag} /> : null}
            </Modal>

            <Modal
                className='aaa person_page_inventar_modal_mobile'
                visible={inventarFlag}
                onCancel={() => { this.changeInventarFlag(false) }}
                footer={null}
                title={'Инвентарь'}
                width={300}
            >
                {inventarFlag ? <PersonInventar personId={personId} /> : null}
            </Modal>
            <Modal
                className='aaa'
                visible={competitionVideosFlag}
                onCancel={() => { this.changeCompetitionVideosFlag(false) }}
                footer={null}
                title={'Видео боёв'}
                width={330}
            >
                <PersonVideoBigPlashka personId={personId} />
            </Modal>

            <TrefaLayout greedsX={[10, width - 20, 10]}
                components={
                    components_
                }
            />
        </div>

    }

}

const PersonPageWithState = withPersonState(`
Person,
isPersonReady,

noSuchPerson,

iAmPerson,

Sequences,
isSequencesReady,

isMobile,
client,
iamuser
`)(PersonPage);

const PersonStatePageWithContent = (props) => <PersonState {...props}> {PersonPageWithState()} </PersonState>

export default PersonStatePageWithContent;

// export default withCapoeiraSportState('iamuser, getText, isMobile')(PersonPage);