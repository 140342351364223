import React, { Component } from 'react';
//import logo from './logo.svg';
import { Button } from 'antd';

import './style.css';
import { Header } from '../../../../components/sgHeader';

const days = [
    'ПН',
    'ВТ',
    'СР',
    'ЧТ',
    'ПТ',
    'СБ',
    'ВС',
]

export class ShaduleEdit extends Component {

    /* props 
    
        subgroup
        shadule

        changeShadule
        closeModal

    */

    constructor(props) {

        super(props);

        const {
            shadule,
            subgroup
        } = props;

        let shaduleInside = [];

        for (let i = 0; i < shadule.length; i++) {
            if (shadule[i].subgroupId === subgroup.id) {
                shaduleInside.push({ ...shadule[i] });
            }
        }

        this.state = {
            shaduleInside
        };

    }

    save = () => {
        const { save, shadule, subgroup } = this.props;
        const { shaduleInside } = this.state;

        // нам нужно сформировать объект shaduleSaveObject, который включает ТОЛЬКО изменения

        let shaduleSaveObject = [];

        let shaduleFirst = [];

        // смотрим, какие id мы просмотрели. Если в изначальном расписании такой id есть, а тут нет - значит мы его удалили
        let shaduleThatBeenLooked = {};

        for (let i = 0; i < shadule.length; i++) {
            if (shadule[i].subgroupId === subgroup.id) {
                shaduleFirst.push({ ...shadule[i] });
            }
        }

        // сначала ищем изменения
        for (let i = 0; i < shaduleInside.length; i++) {
            const shaduleNow = shaduleInside[i];
            const shaduleWas = shaduleFirst.find(x => x.id === shaduleNow.id);

            let thingsToChange = {}

            // нашли, начинаем сравнивать
            if (shaduleWas) {

                shaduleThatBeenLooked[shaduleNow.id] = true;

                if (shaduleNow.day !== shaduleWas.day) thingsToChange.day = shaduleNow.day;
                if (shaduleNow.timeStart !== shaduleWas.timeStart) thingsToChange.timeStart = shaduleNow.timeStart;
                if (shaduleNow.timeEnd !== shaduleWas.timeEnd) thingsToChange.timeEnd = shaduleNow.timeEnd;

                // если были изменения - то кидаем их туда
                if (Object.keys(thingsToChange).length > 0) {
                    shaduleSaveObject.push(
                        {
                            id: shaduleNow.id,
                            ...thingsToChange
                        }
                    )
                }
            } else {
                // НЕ НАШЛИ
                shaduleSaveObject.push(
                    {
                        ...shaduleNow,
                        subgroupId: subgroup.id,
                        isNew: true
                    }
                )

            }
        }
        // теперь смотрим, не удаляли ли мы что-нибудь
        for (let i = 0; i < shaduleFirst.length; i++) {
            if (!shaduleThatBeenLooked[shaduleFirst[i].id]) {
                shaduleSaveObject.push(
                    {
                        ...shaduleFirst[i],
                        subgroupId: subgroup.id,
                        isDelete: true
                    }
                )
            }
        }
        save(shaduleSaveObject);
    }

    render() {

        const { subgroup, selectedSubgroup } = this.props;
        if (selectedSubgroup === undefined) return null;

        const { shaduleInside } = this.state;

        return <div className='shadule-edit-container'>
            <div className='shadule-edit-header'>
                <Header {...{ subgroup, index: selectedSubgroup + 1 }} />
                {shaduleInside.map((shadule, index) => {
                    return <this.OneDay key={'sh-ed-' + index} index={index} {...shadule} />
                })}
                <this.AddNewDay />
            </div>
            <this.Save />
        </div>
    }

    Save = () => {

        return <div className='shadule-editor-container'>
            <Button type='primary' onClick={() => { this.save() }} >Сохранить</Button>
        </div>
    }

    // одна строчка тренировок
    OneDay = ({ index, day, timeStart, timeEnd }) => {

        return <div className='shadule-editor-oneday'>
            <div className='shadule-editor-oneday-day'>
                <p onClick={() => { this.chandeOneDay(index, -1) }}>
                    <img src='http://capoeirasport.com/graphics/crm/crm_minus.png' />
                </p>
                {days[day - 1]}
                <p onClick={() => { this.chandeOneDay(index, 1) }}>
                    <img src='http://capoeirasport.com/graphics/crm/crm_plus.png' />
                </p>
            </div>
            <div className='shadule-editor-oneday-time'>
                <p onClick={() => { this.chandeTime(index, 1, 1) }} className='seo-top' style={{ left: 2 }}><img src='http://capoeirasport.com/graphics/crm/crm_up.png' /></p>
                <p onClick={() => { this.chandeTime(index, 2, 1) }} className='seo-top' style={{ left: 24 }}><img src='http://capoeirasport.com/graphics/crm/crm_up.png' /></p>
                <p onClick={() => { this.chandeTime(index, 3, 1) }} className='seo-top' style={{ left: 51 }}><img src='http://capoeirasport.com/graphics/crm/crm_up.png' /></p>
                <p onClick={() => { this.chandeTime(index, 4, 1) }} className='seo-top' style={{ left: 73 }}><img src='http://capoeirasport.com/graphics/crm/crm_up.png' /></p>
                {`${timeStart} - ${timeEnd}`}
                <p onClick={() => { this.chandeTime(index, 1, -1) }} className='seo-bottom' style={{ left: 2 }}><img src='http://capoeirasport.com/graphics/crm/crm_down.png' /></p>
                <p onClick={() => { this.chandeTime(index, 2, -1) }} className='seo-bottom' style={{ left: 24 }}><img src='http://capoeirasport.com/graphics/crm/crm_down.png' /></p>
                <p onClick={() => { this.chandeTime(index, 3, -1) }} className='seo-bottom' style={{ left: 51 }}><img src='http://capoeirasport.com/graphics/crm/crm_down.png' /></p>
                <p onClick={() => { this.chandeTime(index, 4, -1) }} className='seo-bottom' style={{ left: 73 }}><img src='http://capoeirasport.com/graphics/crm/crm_down.png' /></p>
            </div>
            <div className='shadule-editor-oneday-day'>
                <p onClick={() => { this.deleteDay(index) }} className='seod_delete'>
                    удалить
                </p>
            </div>
        </div>
    }

    chandeTime = (index, timePos, shift) => {
        const { shaduleInside } = this.state;
        if (!shaduleInside[index]) return;

        const timeSeparate = timePos < 3 ? shaduleInside[index].timeStart.split(':') : shaduleInside[index].timeEnd.split(':');
        const timeName = timePos < 3 ? 'timeStart' : 'timeEnd';
        let shaduleInside_ = [...shaduleInside];

        if (timePos === 1 || timePos === 3) {
            let timeToChange = Number(timeSeparate[0]);
            timeToChange += shift;
            if (timeToChange < 7) timeToChange = 23;
            if (timeToChange > 23) timeToChange = 7;

            const stringTime = timeToChange < 10 ? `0${timeToChange}` : `${timeToChange}`;
            shaduleInside_[index][timeName] = `${stringTime}:${timeSeparate[1]}`;
        }

        if (timePos === 2 || timePos === 4) {
            let timeToChange = Number(timeSeparate[1]);
            timeToChange += 5 * shift;
            if (timeToChange < 0) timeToChange = 55;
            if (timeToChange > 55) timeToChange = 0;

            const stringTime = timeToChange < 10 ? `0${timeToChange}` : `${timeToChange}`;
            shaduleInside_[index][timeName] = `${timeSeparate[0]}:${stringTime}`;
        }

        this.setState({ shaduleInside: shaduleInside_ });
    }

    chandeOneDay = (index, shift) => {
        const { shaduleInside } = this.state;
        if (!shaduleInside[index]) return;

        let shaduleInside_ = [...shaduleInside];
        shaduleInside_[index].day += shift;
        if (shaduleInside_[index].day < 1) shaduleInside_[index].day = 7;
        if (shaduleInside_[index].day > 7) shaduleInside_[index].day = 1;

        this.setState({ shaduleInside: shaduleInside_ });
    }

    AddNewDay = () => {
        return <div className='shadule-editor-addoneday' onClick={() => { this.addNewDay() }}>
            <p>ДОБАВИТЬ ДЕНЬ</p>
        </div>
    }

    addNewDay = () => {
        this.setState({
            shaduleInside: [...this.state.shaduleInside,
            {
                id: 0,
                day: 3,
                timeStart: '15:00',
                timeEnd: '16:00'
            }
            ]
        })
    }

    deleteDay = (index) => {

        const { shaduleInside } = this.state;
        let shaduleInside_ = [];
        for (let i = 0; i < shaduleInside.length; i++) {
            if (i !== index) shaduleInside_.push({ ...shaduleInside[i] })
        }

        this.setState({
            shaduleInside: shaduleInside_
        })
    }


}