import React, { Component } from 'react';
import './component.css';
import { Button, Popover } from 'antd';

/*import { PersonElementCard } from '../../../../components/person-element-card'*/
import { ElementCard } from 'src/ver2/containers/challenges/components/element-card';

import { getElementsScoresByJudging_arrays, getElementsScoresByJudgingOptions, parseSEquenceOptions } from 'src/ver2/containers/challenges/helpers';
// import { getElementsScoresByJudging_arrays, getElementsScoresByJudgingOptions, parseSEquenceOptions } from '../../../../helpers';
export class SoloSequenceRow extends Component {

  constructor(props) {
    super(props);

    this.MAX_ELEMENT_SCORE = 1.5;
    this.MAX_SEQ_SCORE = 6.0;

    // сюда мы передаем Sequence

    const { sequence, client, competition } = props;
    const { UserId } = sequence;

    this.state = {};

    const parseSEquenceOptionsResult = parseSEquenceOptions({ sequence })

    this.state.sequence_elements = parseSEquenceOptionsResult.sequence_elements;
    this.state.sequence_options = parseSEquenceOptionsResult.sequence_options;

    const { Status } = sequence;

    const { sequence_elements } = parseSEquenceOptionsResult;

    let scores_element = [];
    let scores_seq = [];

    // подгружаем текущие оценки, если он уже оценён

    if (Status === 'confirm') {

      const { JudgeOptions } = sequence;

      const scores = getElementsScoresByJudgingOptions({ sequence_elements, JudgeOptions });
      scores_element = scores.scores_element;
      scores_seq = scores.scores_seq;

    } else {
      for (let i = 0; i < this.state.sequence_elements.length; i++) {
        scores_element.push(1);
        if (i < this.state.sequence_elements.length - 1) scores_seq.push(0);
      }
    }

    this.state.scores_element = scores_element;
    this.state.scores_seq = scores_seq;
    this.state.judging = false;

    let isJudge = false;

    const ThisUserId = client.loggedUserId();
    // пока дикая заглушка
    if (ThisUserId === 2) {
      isJudge = true;
    }


    // if (competition && competition.Judges) {
    //     const UserId = client.loggedUserId();
    //     if (UserId) {
    //       const Judges_ =  competition.Judges.split('/');
    //       if (Judges_.find( x => x === UserId.toString())) {
    //         isJudge = true;
    //       }
    //     }
    // }


    if (Status === 'waiting' && isJudge) this.state.judging = true;

    // client.getSomeSoloUserOptions(UserId).then(result => {
    //   this.setState({ userOptions: result })
    // })
    client.getUserFromServer(UserId, {}).then((result) => {
      
      const { VSettings = '' } = result || {};
      const VSettingsObj = JSON.parse(VSettings);
      const cover_id = (VSettingsObj &&  VSettingsObj.paint && VSettingsObj.paint.cover) ? VSettingsObj.paint.cover : null;

      this.setState({ cover_id });
    });

  }

  render() {

    const { client, sequence, mobile } = this.props;
    const { scores_element, scores_seq, sequence_elements, judging, cover_id, sequence_options } = this.state;
    const { Sequence, Status } = sequence;

    const seq_length = Sequence.split('/').length;

    let width = 950 / (seq_length + 1);
    // if (width > 30) width = 30;

    if (mobile) {
      width = 300 / (seq_length + 1)
    }

    const sizes = {
      width,
      nomargin: true
    }

    const styles_for_plasha_top = {
      width: sizes.width,
      height: sizes.width * 0.6
    }

    const styles_for_plasha_bottom = {
      width: sizes.width,
      height: sizes.width * 0.6,
      top: sizes.width * 2,
      left: sizes.width * 0.58,
    }

    const elements = client.getServerElements();
    const elements_scores = getElementsScoresByJudging_arrays({ client, sequence_elements, scores_element, scores_seq, sequence_options })

    let BasicSum = 0;
    let SequenceSum = 0;

    for (let i = 0; i < elements_scores.length; i++) {
      BasicSum += elements_scores[i].Points;
      SequenceSum += elements_scores[i].SequencePoints;
    }

    SequenceSum = SequenceSum / (elements_scores.length - 2);

    return (
      <div className='solo-sequence-row-content'>
        <div className='solo-sequence-row'>

          {sequence_elements.map((item, index) => {

            const element = elements.find(x => x.key === item)

            return <div className='solo-sequence-one-plashka' style={{ margin: sizes.width * 0.07 }}>

              {
                Status !== 'waiting' || judging
                  ? <this.UpperPlashkaPart style={styles_for_plasha_top} elements_scores={elements_scores} score_index={index} />
                  : null
              }

              <ElementCard
                key={`ssdd${index}`}
                client={client}
                sizes={sizes}
                cover_id={cover_id}
                element_key={element ? element.key : null}
              />

              {index < (seq_length - 1) && <this.BottomPlashkaPart elements_scores={elements_scores} style={styles_for_plasha_bottom} score_index={index} />}

            </div>

          })}
        </div>

        {(judging || Status === 'confirm') && <div className='solo-sequence-one-plashka-scoresresult '>
          <span>{`Техника: ${BasicSum.toFixed()}`}</span>
          <span>{`Связность: ${SequenceSum.toFixed()}`}</span>
          <span>{`ИТОГ: ${(BasicSum + SequenceSum).toFixed()}`}</span>
        </div>}

        {judging && <Button style={{ margin: 50 }} type='primary' onClick={(e) => { this.saveJudgePoints() }}> ОТПРАВИТЬ ОЦЕНКИ </Button>}


      </div>
    );
  }

  saveJudgePoints = () => {

    const { scores_element, scores_seq, sequence_elements, sequence_options } = this.state;
    const { client, judgeSavePoint } = this.props;

    const elements_scores = getElementsScoresByJudging_arrays({ client, sequence_elements, scores_element, scores_seq, sequence_options })

    let BasicSum = 0;
    let SequenceSum = 0;

    for (let i = 0; i < elements_scores.length; i++) {
      BasicSum += elements_scores[i].Points;
      SequenceSum += elements_scores[i].SequencePoints;
    }

    SequenceSum = SequenceSum / (elements_scores.length - 2);

    const Points = Number((BasicSum + SequenceSum).toFixed(1));

    judgeSavePoint({ scores_element, scores_seq }, Points);

  }

  UpperPlashkaPart = ({ style, score_index, elements_scores }) => {

    const { scores_element, judging } = this.state;
    const score = scores_element[score_index];

    const JudgeElement = judging ? this.ScoreWithJudging : this.ScoreWithoutJudging;

    let classNamePoint = 'solo-sequence-one-plashka-score-big';

    let error_points_text = '';
    if (elements_scores[score_index].ErrorPoints) {
      if (elements_scores[score_index].ErrorPoints < elements_scores[score_index].BasicPoints) {
        error_points_text = 'Очки после штрафа: ';
      } else {
        error_points_text = 'Очки после повышения: ';
      }
    }

    if (elements_scores[score_index].isWeak) {
      classNamePoint += ' solo-sequence-one-plashka-score-big-weak';
      error_points_text = 'Сбавка самому простому элементу 60%: '
    }

    // теперь нужно понять оценку этого дела

    return <div className='solo-sequence-one-plashka-top' {...{ style }} >
      <div className='solo-sequence-one-plashka-score'>
        <JudgeElement {...{ score, score_index }} />
        <Popover placement='top' content={
          <div>
            Базовая цена (БЦ) : {elements_scores[score_index].BasicPoints.toFixed(1)}
            <br />
            {elements_scores[score_index].ErrorPoints ? [error_points_text + elements_scores[score_index].ErrorPoints.toFixed(1), <br />] : null}
            {!elements_scores[score_index].isWeak && `Очки за исполнение ( * ${scores_element[score_index].toFixed(1)} ) : ${elements_scores[score_index].PerformPoints.toFixed(1)} `}

            <br /><br />
            <b>ИТОГО: {elements_scores[score_index].Points.toFixed(1)} </b>
          </div>
        }>
          <div className={classNamePoint}>
            {elements_scores[score_index].Points.toFixed(1)}
          </div>
        </Popover>
      </div>
    </div>

  }


  BottomPlashkaPart = ({ style, score_index, elements_scores }) => {

    const { scores_seq, judging } = this.state;
    const score = scores_seq[score_index];

    const JudgeElement = judging ? this.ScoreWithJudging : this.ScoreWithoutJudging;

    if (!judging && score === 0) {
      return <div className='solo-sequence-one-plashka-bottom solo-sequence-one-plashka-bottom-no-height' {...{ style }} />
    }

    return <div className='solo-sequence-one-plashka-bottom' {...{ style }}>
      <div className='solo-sequence-one-plashka-score-absolute-left' />
      <div className='solo-sequence-one-plashka-score-absolute-right' />
      <div className='solo-sequence-one-plashka-score'>
        {judging && <div className='solo-sequence-one-plashka-score-big'>
          {elements_scores[score_index + 1].SequencePoints.toFixed(1)}
        </div>}
        <JudgeElement {...{ score, score_index, is_bottom: true }} />
      </div>
    </div>
  }

  onUpperScoreClick = ({ score_index, plus }) => {

    const { scores_element } = this.state;
    let score = scores_element[score_index];
    score += plus;
    if (score > this.MAX_ELEMENT_SCORE) score = this.MAX_ELEMENT_SCORE;
    if (score < 0) score = 0;

    let scores_element_ = [...scores_element];
    scores_element_[score_index] = score;

    this.setState({ scores_element: scores_element_ });

  }

  onDownScoreClick = ({ score_index, plus }) => {

    const { scores_seq } = this.state;
    let score = scores_seq[score_index];
    score += plus;
    if (score > this.MAX_SEQ_SCORE) score = this.MAX_SEQ_SCORE;
    if (score < 0) score = 0;

    let scores_seq_ = [...scores_seq];
    scores_seq_[score_index] = score;

    this.setState({ scores_seq: scores_seq_ });

  }

  chooseStyleToKoef = (score) => {

    if (score === 1) return '';

    if (score < 0.5) return ' ssons-color-bad';
    if (score < 1) return ' ssons-color-notgood';
    if (score < 1.4) return ' ssons-color-good';
    return ' ssons-color-super';

  }

  ScoreWithoutJudging = ({ score, is_bottom }) => {

    let text = score;

    if (is_bottom && score === 0) text = null;
    if (!is_bottom && score === 1) text = null;

    return <div className='solo-sequence-one-plashka-score-little'>
      <div className={`${!is_bottom ? this.chooseStyleToKoef(score) : ''}`}>{text ? `(${text})` : ''}</div>
    </div>
  }

  ScoreWithJudging = ({ score, score_index, is_bottom }) => {

    const plus_funct = is_bottom ? this.onDownScoreClick : this.onUpperScoreClick;

    const plusScore = is_bottom ? 0.5 : 0.1;

    return <div className='solo-sequence-one-plashka-score-little'>
      <div onClick={(e) => { plus_funct({ score_index, plus: -plusScore }) }} className='solo-sequence-one-plashka-score-little-plus'>-</div>
      <div className={`${!is_bottom ? this.chooseStyleToKoef(score) : ''}`}>{`(${score.toFixed(1)})`}</div>
      <div onClick={(e) => { plus_funct({ score_index, plus: plusScore }) }} className='solo-sequence-one-plashka-score-little-plus'>+</div>
    </div>
  }


}