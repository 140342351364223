import React, { Component } from 'react';
import './component.css';
import { SoloMiniSequence } from '../mini-sequence';
import moment from 'moment';
import { SoloSpecial } from '../special';
import { parseRules, getRuleContent } from '../../helpers';
import { withCapoeiraSportState } from 'src/ver2/context';
import EventAvatar from 'src/ver2/components/event/avatar';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';

import { Link } from 'react-router-dom';


export class SoloRoundRow extends Component {

  constructor(props) {
    super(props);

    const { round, client, isMobile, iamuser } = props;

    const { Id, EnterRules } = round;

    this.state = {};

    this.state.scaling = 1;
    this.state.sequencesCount = 0;

    if (iamuser) {

      const UserId = iamuser.Id;

      client.getSoloUserSequences({ UserId, RoundId: Id }).then(result => {

        if (result && result[0]) {
          this.setState({ sequence: result[0] });
        }

      });
    }

    client.getSoloUserSequences({ RoundId: Id }).then(result => {
      if (result && result.length > 0) {
        this.setState({ sequencesCount: result.length });
      }
    });

    if (isMobile) {
      this.state.scaling = this.setScaling();
    }

    if (EnterRules && EnterRules !== 'all') {
      this.state.roundRules = parseRules(EnterRules);
    }

  }

  setScaling = () => {

    const allWidth = document.documentElement.clientWidth;
    const weNeed = 770;

    return (allWidth / weNeed);

  }


  render() {

    const { scaling } = this.state;
    const { serverTime, round, isMobile } = this.props;

    let is_over_style = '';

    if (serverTime) {

      const DateEndSafari_ = round.DateEnd.split('.');
      const DateEndSafari = DateEndSafari_[0] + '-' + DateEndSafari_[1] + '-' + DateEndSafari_[2];

      const timeNow = moment(new Date(serverTime));
      const timeRound = moment(new Date(DateEndSafari)).add(21, 'hours');

      if (timeRound.diff(timeNow, 'minutes') < 0) {
        is_over_style = ' solo-round-row-div-over';
      }

    }

    let className = 'solo-round-upper-container';

    return (
      <div className={className} style={
        {
          width: `${710 * scaling}px`,
          height: `${70 * scaling}px`
        }
      }>
        <div
          className={'solo-round-row-div ' + is_over_style}
          style={
            isMobile ?
              {
                transform: `scale(${scaling}) translateX( ${-1 * scaling}px) translateY( ${-1 * scaling}px)`
              }
              :
              {}
          }
        >
          <this.CompetitionLogo />
          <this.RoundTier />
          <this.RoundNumber />
          <this.RoundSequence />
          <this.RoundDate />
          <this.RoundPersonStatus />
          <this.RoundSequencesCount />
        </div>
      </div>
    );
  }

  CompetitionLogo = () => {
    const { client, round } = this.props;
    const { SoloCompetitionId } = round;
    const { scaling } = this.state;
    return <div className='solo-round-row-div-competition-logo'>
      <EventAvatar eventId={SoloCompetitionId} size={50 * scaling} />
    </div>
  }

  RoundTier = () => {
    const { round } = this.props;
    return <div className='solo-round-row-div-number'>
      <NumberText count={round.Tier} text={'TIER'} height={45} />
    </div>
  }

  RoundNumber = () => {
    const { round } = this.props;
    return <div className='solo-round-row-div-number'>
      <NumberText count={round.Number} text={'РАУНД'} height={45} />
    </div>
  }

  RoundSequence = () => {
    const { round } = this.props;
    const { roundRules } = this.state;
    const { Sequence, Options, EndingText } = round;
    return <Link to={`/round/${round.Id}`}>
      <div className='solo-round-row-div-sequence'>
        <SoloMiniSequence
          client={this.props.client}
          Sequence={Sequence}
          Options={Options}
          roundRules={roundRules}
          EndingText={EndingText}
        />
      </div>
    </Link>
  }

  RoundDate = () => {
    const { round, serverTime } = this.props;

    if (!serverTime) {
      return <div className='solo-round-row-div-date'>
        <div className='solo-round-row-div-tworows-upper sr-upper-minus-text'>{`ДОСТУПЕН`}</div>
        <div className='solo-round-row-div-tworows-lower'>{`до ${round.DateEnd}`}</div>
      </div>
    }

    // теперь вот тут надо сделать адень - время сервера перевести в дату, потом врея окончания перевести в дату, добавить день, и вычесть

    const { DateStart } = round;

    const DateStartSafari_ = round.DateStart.split('.');
    const DateStartSafari = DateStartSafari_[2] + '-' + DateStartSafari_[1] + '-' + DateStartSafari_[0];

    const DateEndSafari_ = round.DateEnd.split('.');
    const DateEndSafari = DateEndSafari_[0] + '-' + DateEndSafari_[1] + '-' + DateEndSafari_[2];

    const correctDateEnd = DateEndSafari_[2] + '.' + DateEndSafari_[1] + '.' + DateEndSafari_[0];

    const timeNow = moment(new Date(serverTime));
    const timeRound = moment(new Date(DateEndSafari)).add(21, 'hours');
    const endRound = moment(new Date(DateStartSafari)).add(21, 'hours');

    const days = timeRound.diff(timeNow, 'days') - 1;
    const isStarted = endRound.diff(timeNow, 'days') - 1;

    if (isStarted >= 0) {
      return <div className='solo-round-row-div-date'>
        <div className='solo-round-row-div-tworows-upper sr-upper-minus-text'>{`НАЧНЁТСЯ`}</div>
        <div className='solo-round-row-div-tworows-lower'>{`${DateStart}`}</div>
      </div>
    }

    if (days <= 0) {

      const hours = timeRound.diff(timeNow, 'hours');

      if (hours <= 0) {

        const minutes = timeRound.diff(timeNow, 'minutes');

        if (minutes <= 0) {
          return <div className='solo-round-row-div-date'>
            <div className='solo-round-row-div-tworows-upper sr-upper-minus-text'>{`ЗАКОНЧЕН`}</div>
            <div className='solo-round-row-div-tworows-lower'>{`${correctDateEnd}`}</div>
          </div>
        }

        return <div className='solo-round-row-div-date'>
          <div className='solo-round-row-div-tworows-upper sr-upper-minus-text'>{`${minutes} м.`}</div>
          <div className='solo-round-row-div-tworows-lower'>{`до ${correctDateEnd}`}</div>
        </div>
      }

      return <div className='solo-round-row-div-date'>
        <div className='solo-round-row-div-tworows-upper sr-upper-minus-text'>{`${hours} ч.`}</div>
        <div className='solo-round-row-div-tworows-lower'>{`до ${correctDateEnd}`}</div>
      </div>

    }

    return <div className='solo-round-row-div-date'>
      <div className='solo-round-row-div-tworows-upper sr-upper-minus-text'>{`${days} дн.`}</div>
      <div className='solo-round-row-div-tworows-lower'>{`до ${correctDateEnd}`}</div>
    </div>


  }

  RoundPersonStatus = () => {
    const { sequence } = this.state;
    const { iSawItForCompetitions, round, client } = this.props;

    // если ты сюда не заявил связку
    if (!sequence) {

      const { EndingText } = round;

      if (EndingText) {

        const parts = EndingText.split('/');

        for (let i = 0; i < parts.length; i++) {

          const part = parts[i];

          const first_part = part.substring(0, 3);
          const second_part = part.substring(3);

          if (first_part === 'sp_') {
            return <div className='solo-round-row-div-status'>
              <SoloSpecial client={client} special_type={second_part} is_mini />
            </div>
          }

        }

      }

      return <div className='solo-round-row-div-status'>
      </div>
    }
    const { Status } = sequence;

    if (Status === 'waiting')
      return <div className='solo-round-row-div-status'>
        <div className='solo-round-row-div-tworows-upper' > _ </div>
        <div className='solo-round-row-div-tworows-lower'>ожидает</div>
      </div>

    if (Status === 'confirm') {

      // if (iSawItForCompetitions && iSawItForCompetitions.find(x => x.ThingId === sequence.Id)) {
      //   needISaw = true;
      // }

      const { Points } = sequence;

      return <div className='solo-round-row-div-status'>
        {/* {needISaw ? <div className='solo-round-row-div-status-wave'></div> : null}
        <div className='solo-round-row-div-tworows-upper' style={needISaw ? { color: 'green' } : {}}> + </div>
        <div className='solo-round-row-div-tworows-lower'> Очки: {Points}</div> */}
        <NumberText count={Points} text={'ОЧКИ'} height={45} />


      </div>
    }

  }

  RoundSequencesCount = () => {
    const { sequencesCount } = this.state;
    return <div className='solo-round-row-div-status'>
      <NumberText count={sequencesCount} text={'ЧЕЛ'} height={45} />
    </div>
  }





}


export default withCapoeiraSportState('iamuser, client, isMobile')(SoloRoundRow);