import ExerciseInfoPlashka from './info';
import ExerciseTitlePlashka from './title';
import ExerciseVideoPlashka from './video';
import ExerciseSchemePlashka from './scheme';
import ExerciseDiscriptionPlashka from './discription';

const exportt = {
    ExerciseInfoPlashka,
    ExerciseTitlePlashka,
    ExerciseVideoPlashka,
    ExerciseSchemePlashka,
    ExerciseDiscriptionPlashka
}

export default exportt;