import React, { Fragment } from 'react';

export const TECHNIC_HEADER_TEXT = <Fragment>

    В этом разделе представлены разнообразные варианты элементов, используемых в капоэйра.
    <br/><br/>
    Стоит принимать во внимание, что в капоэйра не существует общепринятого стандарта по наименованию и исполнению техники. В разных школах один и тот же элемент может называться и исполняться по разному, или вообще отсутствовать.
    <br/><br/>
    Вы можете перейти на страничку каждого элемента, прочитать подробную информацию, для большинства техник сняты специльные видео с исполнением, собраны обучающие материалы, подготовлены подсказки по порядку изучения элементов.
    <br/><br/>
    Все элементы на портале разделены на атаки, перемещения и защиты. Внутри идёт разделения на подтипы (например, прямой или круговой удар) и градацию сложности. <br/>
    <b>TIER 1</b> - базовые элементы. <b>TIER 2</b> - требуют углубления. <b>TIER 3</b> - продвинутые.<br/><br/>
    Естественно, данное разделение носит условный характер: многие элементы капоэйра могут выступать и как защита и как атака одновременно, а сложность - относительная вещь. 
    <br/>
</Fragment>

export const TECHNIC_HEADER_LIST = <Fragment>


    
</Fragment>

export const CHOOSE_BUTTONS_LIST_LABELS = {
    list: 'СПИСОК',
    matrix: 'КАРТОЧКИ',
    plan: 'КАРТА'
}