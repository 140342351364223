import React, { Component } from 'react';
import { withCRMState } from '../../../../context';
//import logo from './logo.svg';

import { Student } from '../../../../components/student';
import { sortBy } from 'lodash';
import { Header } from '../../../../components/sgHeader';
import PaymentModal from '../../../../components/addPaymentModal';
import SBComment from '../../components/sbcomment';
import { DISCIPLINE_SMILES } from '../../../../components/disciplineSmiles';

import { Modal, Radio } from 'antd';

import './style.css';

class Subgroup extends Component {

    constructor() {
        super();
        this.state = {
            filter: 'fio',
            unpaidVisits: {},
            studentToPayment: null,
            commentModalData: null
        }
    }

    componentDidUpdate = ({ selectedMonth, selectedYear }) => {
        if (`${this.props.selectedMonth}_${this.props.selectedYear}` !== `${selectedMonth}_${selectedYear}`) {
            this.setState({ unpaidVisits: {} })
        }
    }

    changeCommentModalData = commentModalData => this.setState({ commentModalData })

    render() {
        const { subgroup } = this.props;
        const { studentToPayment, commentModalData } = this.state;
        return <div key={'sdf' + subgroup.id} className='visits-subgroup-container'>
            <Header {...this.props} />
            <this.Filters />
            <this.Students />
            <this.Visits />
            <Modal
                visible={studentToPayment}
                title='Добавить оплату'
                footer={null}
                onCancel={() => { this.setStudentToPayment() }}
            >
                {studentToPayment && <PaymentModal closeModal={() => { this.setStudentToPayment() }} subgroup={subgroup} student={studentToPayment} />}
            </Modal>
            <Modal
                visible={commentModalData}
                title='Добавить комментарий'
                footer={null}
                onCancel={() => { this.changeCommentModalData(null) }}
            >
                {commentModalData && <SBComment closeModal={() => { this.changeCommentModalData(null) }} {...commentModalData} />}
            </Modal>
        </div>
    }

    Filters = () => {
        const { filter } = this.state;
        return <div className='visits-subgroup-filters-container'>
            <div className='visits-subgroup-filters-fio' onClick={() => { this.setState({ filter: 'fio' }) }}>
                {filter === 'fio' ? <b>Ф И О</b> : 'Ф И О'}
            </div>
            <div className='visits-subgroup-filters-cordao' onClick={() => { this.setState({ filter: 'cordao' }) }}>
                {filter === 'cordao' ? <b>КОРДАО</b> : 'КОРДАО'}
            </div>
        </div>

    }

    Visits = () => {
        const { visits, subgroup, trainingDays, selectedMonth, selectedYear, sbcomments } = this.props;
        const { shadule, id } = subgroup;

        if (!shadule) return null;

        const month = selectedMonth;
        const year = selectedYear;
        const month_key = `${month}_${year}`;

        return <div className='visits-subgroup-studentdays visits-subgroup-studentdays_upper'>
            {
                trainingDays.map((day) => {
                    if (!shadule[day]) {
                        return <div className={'visits-subgroup-studentdays-cell empty'} >
                        </div>
                    }

                    const sbcomment = sbcomments && sbcomments[id] && sbcomments[id][month_key] && sbcomments[id][month_key][day];
                    const count = (visits[month_key] && visits[month_key][id] && visits[month_key][id][day] && visits[month_key][id][day].length) || 0
                    let className = 'visits-subgroup-studentdays-cell';

                    let text = count || '';
                    if (sbcomment && sbcomment.IsCanceled) text = '❌';

                    const onClick = () => {
                        if (sbcomment) {
                            this.changeCommentModalData({
                                ...sbcomment
                            })
                        } else {
                            this.changeCommentModalData({
                                Day: day,
                                Month: month,
                                Year: year,
                                SubgroupId: id
                            })
                        }
                    }

                    return <div className={className}>
                        {sbcomment && <div onClick={onClick} className='visits-subgroup-studentdays-cell_uppercomment'>
                            <img alt='CapoeiraSport' src="/../images/ver2/idea.png" />
                        </div>}
                        {(!sbcomment) && <div
                            className='visits-subgroup-studentdays-cell_uppercomment visits-subgroup-studentdays-cell_uppercomment_unsee'
                            onClick={onClick}
                        >
                            <img alt='CapoeiraSport' src="/../images/ver2/idea.png" />
                        </div>}
                        <p> {text} </p>
                    </div>

                })
            }
        </div>
    }

    Students = () => {

        const { filter, unpaidVisits } = this.state;
        const { students, client, selectedMonth, selectedYear } = this.props;
        const className = 'visits-subgroup-students-container';

        let studentsToShow = [];
        if (filter === 'fio') studentsToShow = sortBy(students, item => { return item.Surname });
        if (filter === 'cordao') {
            const schoolGradation = Object.keys(client.getSchoolGradation(1));
            studentsToShow = sortBy(students, item => { return schoolGradation.indexOf(item.Cordao) }).reverse();
        }



        return <div className={className}>
            {studentsToShow.map((student, index) => {
                const { subgroup, studentVisits } = this.props;
                const payment = {
                    isThereUnpaid: unpaidVisits[student.Id],
                    isMonthPayed: this.isMonthPayed({ studentid: student.Id, subgroupid: subgroup.id, month: selectedMonth, year: selectedYear })
                }
                const { id } = subgroup;
                let visits_student_count = 0;
                const date_key = `${selectedMonth}_${selectedYear}`;
                visits_student_count = (studentVisits[date_key] && studentVisits[date_key][id] && studentVisits[date_key][id][student.Id]) || 0;

                return <div key={'gcrm-sb-studd-' + student.Id} className='visits-subgroup-student-large'>
                    <Student
                        key={'gcrm-sb-stud-' + student.Id}
                        client={client}
                        student={student}
                        index={index + 1}
                        payment={payment}
                        visits={visits_student_count}
                        clickToPayment={this.setStudentToPayment}
                        fields={
                            [
                                { type: 'index', width: 20 },
                                { type: 'avatar', width: 30 },
                                { type: 'fioac', width: 200 },
                                { type: 'payment', width: 20 },
                                { type: 'visits', width: 20 },
                            ]
                        }
                        notDragged
                    />
                    <this.StudentDays
                        client={client}
                        student={student}
                        index={index + 1}
                    />
                </div>
            })}
        </div>
    }

    addSubgroupVisit = ({ subgroupid, userid, day, isDiscipline }) => {
        const { addVisit } = this.props;
        let discipline = 2;

        const disciplineChange = (e) => discipline = e.target.value;
        const DiscComponent = () => <Radio.Group onChange={disciplineChange} defaultValue={2}>
            <Radio key={`asvis_1`} value={1}>{DISCIPLINE_SMILES[1]}</Radio>
            <Radio key={`asvis_2`} value={2}>{DISCIPLINE_SMILES[2]}</Radio>
            <Radio key={`asvis_3`} value={3}>{DISCIPLINE_SMILES[3]}</Radio>
        </Radio.Group>

        if (!isDiscipline) {
            addVisit({ subgroupid, userid, day })
        } else {
            Modal.confirm({
                title: 'Отметка дисциплины',
                content: <DiscComponent discipline={discipline} />,
                okText: 'ОТМЕТИТЬ',
                cancelText: 'ОТМЕНА',
                onOk: () => {
                    addVisit({ subgroupid, userid, day, discipline })
                }
            })
        }

    }

    StudentDays = ({ student }) => {
        const { visits, today, deleteVisit, subgroup, trainingDays, selectedMonth, selectedYear, sbcomments } = this.props;
        const { shadule, id } = subgroup;

        if (!shadule) return null;

        const month = selectedMonth;
        const year = selectedYear;
        const month_key = `${month}_${year}`;

        let nowday = today.day;
        if (selectedYear > today.year) nowday -= 1000;
        if (selectedYear < today.year) nowday += 1000;

        if (selectedMonth > today.month) nowday -= 50;
        if (selectedMonth < today.month) nowday += 50;

        return <div className='visits-subgroup-studentdays'>
            {
                trainingDays.map((day) => {

                    if (!shadule[day]) {
                        return <div className={'visits-subgroup-studentdays-cell empty'} >
                        </div>
                    }

                    let willbe = 0;
                    let className = 'visits-subgroup-studentdays-cell';
                    if (Number(day) === nowday) {
                        className += ' sv-today';
                    }
                    if (Number(day) > nowday) {
                        className += ' sv-willbe';
                        willbe = 1;
                    }
                    if (sbcomments && sbcomments[id] && sbcomments[id][month_key] && sbcomments[id][month_key][day] && sbcomments[id][month_key][day].IsCanceled) {
                        className += ' sv-willbe';
                    }

                    const visit_day = (visits[month_key] && visits[month_key][id] && visits[month_key][id][day]) ? visits[month_key][id][day] : [];
                    const visit_student = visit_day.find(x => x.UserId === student.Id);

                    const isDayPayed = this.isDayPayed({ studentid: student.Id, subgroupid: id, day, month, year });
                    const isMonthPayed = this.isMonthPayed({ studentid: student.Id, subgroupid: id, month, year });
                    return <div
                        className={className}
                        onClick={
                            () => {
                                if (willbe) return;
                                if (visit_student) {
                                    deleteVisit({ VisitId: visit_student.Id, Month: month, Year: year, Day: day, subgroupid: id });
                                } else {
                                    this.addSubgroupVisit({ subgroupid: id, userid: student.Id, day, isDiscipline: subgroup.discipline })
                                }

                            }
                        }
                    >
                        {visit_student ?
                            <this.OneDayVisiting studentid={student.Id} day={day} discipline={visit_student.Discipline} isDayPayed={isDayPayed} isMonthPayed={isMonthPayed} />
                            :
                            null
                        }
                    </div>

                })
            }
        </div>
    }

    setStudentToPayment = (studentToPayment = null) => {
        this.setState({ studentToPayment })
    }

    // оплачена ли разовая тренировка у данного
    isDayPayed = ({ studentid, subgroupid, day, month, year }) => {
        const { payments } = this.props;
        const dateKey = `${month}_${year}`;
        return payments[dateKey] && payments[dateKey][subgroupid]
            && payments[dateKey][subgroupid][studentid]
            && payments[dateKey][subgroupid][studentid]['day']
            && payments[dateKey][subgroupid][studentid]['day'][day];
    }
    // оплачена ли разовая тренировка у данного
    isMonthPayed = ({ studentid, subgroupid, month, year }) => {
        const { payments } = this.props;
        const dateKey = `${month}_${year}`;
        return payments[dateKey] && payments[dateKey][subgroupid] && payments[dateKey][subgroupid][studentid] && payments[dateKey][subgroupid][studentid]['month'];
    }

    setUnpaidVisit = ({ studentid, day }) => {
        //unpaidVisits
        let unpaidVisits = { ...this.state.unpaidVisits };
        if (!unpaidVisits[studentid]) unpaidVisits[studentid] = {};
        if (unpaidVisits[studentid][day]) return;
        unpaidVisits[studentid][day] = true;
        this.setState({ unpaidVisits })
    }

    OneDayVisiting = ({ studentid, day, discipline, isDayPayed, isMonthPayed }) => {
        let text = discipline;
        let paidComponent = null;
        let mark = '✖';
        let isUnpaidVisit = false;
        if (!isDayPayed) { mark = '❌'; isUnpaidVisit = true }
        if (isDayPayed) { mark = '❎'; isUnpaidVisit = false }
        if (isMonthPayed) { mark = '❎'; isUnpaidVisit = false }

        if (isUnpaidVisit)
            this.setUnpaidVisit({ studentid, day })
        //❌ ✖ ❎

        if (!discipline || discipline === 5) {
            text = mark;
        } else {
            text = DISCIPLINE_SMILES[discipline];
            if (!isDayPayed && !isMonthPayed)
                paidComponent = <div className='one_visiting_inside_mark'>{mark}</div>
        }

        let className = 'one_visiting_inside';
        if (isDayPayed) className += ' one_visiting_inside_payedday'

        return <div className={className}>
            {paidComponent}
            <span>{text}</span>
        </div>
    }

}

export default withCRMState(`

    client,

    payments,

    visits,
    studentVisits,
    selectedMonth,
    selectedYear,
    trainingDaysAsObj, 
    sbcomments,   

    addVisit,
    deleteVisit

`)(Subgroup)