import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Checkbox } from 'antd';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
// import { Link } from 'react-router-dom';

import './style.css';

// class PersonFrkAccredPlashka
// classname: person_fkraccred_plashka

/**
  Это плашка, которая показывает, что у нас не заполнены какие-то поля
  и даёт возможность их заполнить
 */
const ACCRED_TEXT = <>
    <b>Спортивное членство</b>
    Спортивное членство ФКР оформляется и действует до определённой даты (обычно это 31 декабря или 31 августа текущего сезона) и даёт возможность оформившему спортсмену
    регистрироваться на все официальные соревнования по капоэйра через портал capoeirasport на период действия.
    <br/>
    <br/>В подавляющем большинстве случаев спортсмен не имеет возможности зарегистироваться в соревнования без оплаты членства. В ряде случаев такая возможность есть, но
    будет увеличен стартовый взнос за соревнование.
    <br/><br/>
    Членство необходимо продлять после завершения срока действия. Делается это каждый раз через форму на данной странице.
    <br/>
    <b>ВНИМАНИЕ!</b>
    В период до 8 сентября на оформление членства действуют льготные цены, после чего цена увеличивается:
    <br/>
    <br/>
    До 8 сентября 2024 года:
    <br/>Полсезона (до 31 декабря 2024) - 750р
    <br/>Весь сезон (до 31 августа 2025) - 1000р
    <br/>
    <br/>После 8 сентября 2024 года:
    <br/>Полсезона (до 31 декабря 2024) - 1000р
    <br/>Весь сезон (до 31 августа 2025) - 1500р

    <br/>
    <b>Став Членом Федерации, Вы имеете право:</b>
    •	Принимать участие во всех спортивных мероприятиях Федерации Капоэйра России и ее региональных отделений;
    <br />•	Получать спортивные разряды и звания при помощи специалистов Федерации Капоэйра России с льготными условиями;
    <br />•	Получать содействие Федерации Капоэйра России в спортивной и организационной деятельности, в том числе и по своей инициативе;
    <br />•	Участвовать в работе выборных органов Федерации и выдвигать любые инициативы в рамках целей и задач Федерации;
    <br />•	Принимать участие во всех семинарах, научно-методической и информационной работе Федерации Капоэйра России;
    <br />•	Стать официальным спортивным Судьей по виду спорта капоэйра;
    <br />•	Свободно выйти из Федерации, если Вам вдруг не понравится;
    <br />
    <br /><b>Члены Федерации обязаны:</b>
    •	Соблюдать нормы требований Федерации;
    <br />•	Содействовать реализации целей и задач Федерации;
    <br />•	Своевременно уплачивать членские взносы;
    <br />•	Бережно относиться к имуществу Федерации.
    <br />
    <br /><b>Членство в Федерации прекращается:</b>
    •	При выходе из состава членов Федерации по собственному желанию;
    <br />•	При исключении из состава членов Федерации;
    <br />•	При неуплате членских взносов без уважительной причины.
    <br />
    <br /><b>Даты оформления:</b>
    Текущий спортивный сезон в ФКР длится с 1 сентября 2024 по 1 сентября 2025
    <br />•	Подать заявку можно до 31 декабря 2024 (полсезона)
    <br />(Даже если заявка на полсезона подана 20 декабря, она истечёт 1 января)
    <br />•	Или до 1 сентября 2025 (весь сезон)
    {/* <br />•	Подать заявку можно до 1 сентября 2024 (весь сезон) */}
</>


class PersonFrkAccredApplicationModal extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        isChecked: false,

    }

    onClick = (Values) => {
        this.props.onAdmitClick( Values );
    }

    render() {
        // this.setComponentHeight(50 + attentions.length * 30);
        const { isChecked } = this.state;
        const FkrLogo = () => <img src="/../images/ver2/fkr_logo.png" />

        return <div className='person_fkraccred_plashka_modal_container'>
            <FkrLogo />
            {ACCRED_TEXT}
            <br />
            <Checkbox isChecked={isChecked} onChange={(e) => this.setState({ isChecked: e.target.checked })} >Я принимаю условия оформления членства</Checkbox>
            <ButtonPlashka
                upperStyle={{ width: 300, height: 40 }}
                disabled={!isChecked}
                disabledDiscription={'Нажмите подтверждение'}
                onClick={() => this.onClick('1h')}
                // text={'ЗАЯВКА ДО 31 ДЕК 2024 1000р'}
                text={'ЗАЯВКА ДО 31 ДЕК 2024 1000р'}
            />
            <ButtonPlashka
                upperStyle={{ width: 300, height: 40 }}
                disabled={!isChecked}
                disabledDiscription={'Нажмите подтверждение'}
                onClick={() => this.onClick('2h')}
                // text={'ЗАЯВКА ДО 1 СЕНТ 2024 1500р'}
                text={'ЗАЯВКА ДО 31 АВГ 2025 1500р'}
            />
        </div>
    }



}

export default withCapoeiraSportState(`
client,
iamuser,
`)(PersonFrkAccredApplicationModal)