import React, { Component } from 'react';
import * as THREE from 'three';
import { createBoxFon } from './objects'
import './component.css';


export class TabloFon3DBack extends Component {


    /*
      
         props:
         id
         is_group
         label
         caption
         texturesSchools
         texturesGroups

         statistics

    */

  constructor (props) {
    super(props)
  }

  componentDidMount (props) {

    this.canvas = document.getElementById(this.myCanvasId);

    this.prepareThree();
    this.three_render();

    /* СИСТЕМА ДВИЖЕНИЯ */

    this.camera_koef_now = 0.5; // это кэф от 0 до 1, движение влево-вправо
    this.camera_koef_i_want = 0.5;
    this.camera_go_left = true;

    this.last_mouse_move = 30;

    this.interval = setInterval ( this.rotate, 10 );

  }

  prepareThree = () => {

    const { drawWidth, drawHeight, develop } = this.props;

    this.renderer = new THREE.WebGLRenderer({canvas: this.canvas, alpha: true});

    const aspect = 1;

    this.renderer.setSize(drawWidth, drawHeight);
    
    this.renderer.setPixelRatio(window.devicePixelRatio);

    const fov = 60;
    //const aspect = 2;  // значение для canvas по умолчанию
    const near = 0.1;
    const far = 25;

    this.three_camera = new THREE.PerspectiveCamera(fov, aspect, near, far); 
    this.three_camera.position.z = 11;

    this.three_scene = new THREE.Scene();


    this.three_light = new THREE.DirectionalLight(0xFFFFFF, 1);
    this.three_light.position.set(0, 1, 2);

    this.three_light_2 = new THREE.DirectionalLight(0xFFFFFF, 0.35);
    this.three_light_2.position.set(0, 0, 4);

    this.three_scene.add(this.three_light);
    this.three_scene.add(this.three_light_2);

    this.info_fon = createBoxFon ( 
      //new THREE.TextureLoader().load('../../images/' +  this.materialTexture())
      develop ?
      new THREE.TextureLoader().load('../../images/info_fon.jpg')
      :
      new THREE.TextureLoader().load('https://capoeirasport.com/graphics/info_fon.jpg')
     ) 

     this.info_fon.position.z = 10;
     this.info_fon.position.x = 0;

    this.three_scene.add (this.info_fon);

    if (this.props.noRotate) {     
      this.three_camera.position.set(-4 + 0.3*8, 0, 11);
      this.three_camera.lookAt(0, 0, 0);
  
      this.three_light.position.set(-3 + 0.3*6, 1, 2);
    }

    //this.three_scene.fog = new THREE.FogExp2(0xFFFFFF, 0.1);
}  

  rotate = () => {
    if (!this.info_fon) return;

    /* это местный таймер */

    /*

    
    this.camera_koef_now = 0.5; // это кэф от 0 до 1, движение влево-вправо
    this.camera_koef_i_want = 0.5;
    this.camera_go_left = true;

    */

    let camera_step = 0.0009;

    if (Math.abs(this.camera_koef_now - this.camera_koef_i_want) > 0.0009) {
      camera_step = Math.abs(this.camera_koef_now - this.camera_koef_i_want) / 45;
    }

    if (camera_step < 0.0009) camera_step =  0.0009;

    this.last_mouse_move--;

    // т.е. камера будет ходить сама по себе
    if (this.last_mouse_move < 0) {
      
      if (this.camera_go_left) this.camera_koef_i_want -= camera_step;
      if (!this.camera_go_left) this.camera_koef_i_want += camera_step;

      if ( this.camera_koef_i_want > 1) this.camera_go_left = true;
      if ( this.camera_koef_i_want < 0) this.camera_go_left = false;

    }

    if (this.camera_koef_now < this.camera_koef_i_want) this.camera_koef_now += camera_step;
    if (this.camera_koef_now > this.camera_koef_i_want) this.camera_koef_now -= camera_step;
    
    if (Math.abs(this.camera_koef_now - this.camera_koef_i_want) < camera_step) {
      this.camera_koef_now = this.camera_koef_i_want;
    }


    //this.golden_medal.material.uniforms.goldy.value += 0.005;

    if (!this.three_camera) return;

    if (!this.info_fon) {return;} else
    {
      if (!this.info_fon.children[0].material.uniforms) return;
      this.info_fon.children[0].material.uniforms.Time.value += 0.01;

    }

    /*if (this.props.noRotate) {     
      this.three_render();
      return;
    }

    this.three_camera.position.set(-4 + this.camera_koef_now*8, 0, 11);
    this.three_camera.lookAt(0, 0, 0);*/

    this.three_light.position.set(-3 + this.camera_koef_now*6, 1, 2);

    this.three_render();

  }

  three_render = () => {
    if ( this.props.noRender ) return;
      this.renderer.render(this.three_scene, this.three_camera);
  }

  onClick = () => {
  }

  onMouseMove = (e) => {
    const { layerX } = e.nativeEvent;
    const { drawWidth } = this.props;
    if (layerX > 0 && layerX < drawWidth)
    this.camera_koef_i_want = layerX / drawWidth;
    this.last_mouse_move = 220;
  }

  get myCanvasId () {
      return `solo-info_fon-canvas-${this.props.Id}`
  }

  render () { 

    //if (this.renderer)
      //this.three_render()

    const {
        drawWidth,
        drawHeight
    } = this.props;

    const style = {
      width: drawWidth,
      height: drawHeight
    }

    return (
        <div style = {style} className='info_fon-div' onClick = {this.onClick} onMouseMove = {this.onMouseMove}>  

          <canvas style = {style} id = {this.myCanvasId} className = 'info_fon-div-canvas'>

          </canvas>
        </div>
    );
   }

}