import React, { Component } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import LittlePerson from 'src/ver2/components/person/little';
import Loader from 'src/ver2/components/loader';
import { PersonPlaceRectMini } from 'src/ver2/components/place/person';
import ScoreComponent from 'src/ver2/components/scores';

import './style.css';

//getEventById

/**
  Это Пантеон - здесь указывается список людей с несколькими вариантами оформления
  может подгружать участников сам и формировать таким образом сам себя

  /// title
  autoTitle - если true, то выбираем сами исходя из mode
  imgSrc - картинка (если нет, не рисовать)
  title - название
  titleHeight - высота

  /// panteon mode - кого будем отображать
  panteonMode - { type: "regionJudges", region, judgeRank }, { type: "regionSport", region, sportRank }

  /// mode - как рисовать плашки
  mode- "high"

  itemHeight - высота одного элемента

  Сюда приходит массив людей Persons ([ {...Person}, ... ]), достаточно, чтобы в Person был Id

  Если personsDirect - это значит мы уже передали персон и их не надо подгружать поновой

  Если определённый mode, то мы грузим людей сами

 */

class PanteonPlashka extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Persons: [], // кого будем отображать
            status: 'loading'
        }
    }

    shouldComponentUpdate({ personsDirect, upperStyle, panteonMode }, { Persons }) {
        if (personsDirect !== this.props.personsDirect) return true;
        if (Persons !== this.state.Persons) return true;
        if (panteonMode !== this.props.panteonMode) return true;
        if (upperStyle.height !== this.props.upperStyle.height) return true;
        return false;
    }

    componentDidMount() {
        this.checkInfo();
    }

    componentDidUpdate({ panteonMode, personsDirect }) {
        if (panteonMode !== this.props.panteonMode || personsDirect !== this.props.personsDirect) {
            this.checkInfo();
        }
    }

    checkInfo = async () => {
        const { personsDirect } = this.props;
        if (!personsDirect) {
            const { client, panteonMode } = this.props;
            if (panteonMode.type === 'regionJudges') {
                const { region, judgeRank } = panteonMode;
                const Persons = await client.ranksInRegion({ Region: region, Judges: 1, JudgeRank: judgeRank });
                this.setState({ Persons, status: 'loaded' });
            }
            if (panteonMode.type === 'regionSport') {
                const { region, sportRank } = panteonMode;
                const Persons = await client.ranksInRegion({ Region: region, Sport: 1, SportRank: sportRank });
                this.setState({ Persons, status: 'loaded' });
            }
        }

        if (personsDirect && personsDirect.length > 0)
            this.setState({ Persons: personsDirect, status: 'loaded' });

    }


    render() {

        const { upperStyle } = this.props;
        const { width, height } = upperStyle;
        const { Persons } = this.state;

        const { status } = this.state;

        if (height < 5 && (!Persons || Persons.length === 0)) return null;

        if (status !== 'loaded') return <div style={{ width, height: Math.max(height, 100) }} className='panteon_plashka'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='panteon_plashka'>
            <this.Header />
            <this.Content />
        </div>

    }

    Header = () => {
        const { imgSrc: imgSrc_, title: title_, titleHeight, autoTitle, panteonMode, client } = this.props;

        let imgSrc = imgSrc_;
        let title = title_;

        if (autoTitle) {
            if (panteonMode.type === 'regionJudges') {
                const rankInfo = client.rankInfo(panteonMode.judgeRank);
                imgSrc = rankInfo.imgSrc;
                title = rankInfo.text;
            }
            if (panteonMode.type === 'regionSport') {
                const rankInfo = client.rankInfo(panteonMode.sportRank);
                imgSrc = rankInfo.imgSrc;
                title = rankInfo.text;
            }
        }

        if (!title) return null;

        return <div style={{ height: titleHeight }} className='panteon_plashka_title'>
            {imgSrc ?
                <div style={{ height: titleHeight, width: titleHeight }} className='panteon_plashka_title_img'>
                    <img src={imgSrc} />
                </div>
                : null
            }
            <div className='panteon_plashka_title_content'>
                <span style={{ fontSize: titleHeight * 0.5 }}>{title}</span>
            </div>
        </div>
    }

    Content = () => {
        const { panteonMode, mode, setComponentSelfHeight, plashkaId, itemHeight, titleHeight, client } = this.props;
        const { Persons } = this.state;
        let personHeight;

        if (Persons.length === 0) {
            setComponentSelfHeight({ height: 3, plashkaId });
            return null;
        }

        if (mode === 'high') {

            personHeight = itemHeight || 240;

            setComponentSelfHeight({ height: titleHeight + personHeight + 30, plashkaId });

            return <div className='panteon_plashka_persons_high'>
                {Persons.map((person) => {
                    return <div key={'plt' + person.Id} className='panteon_person_rect_one'>
                        <PersonPlaceRectMini
                            height={personHeight}
                            personId={person.Id}
                        />
                    </div>
                })}
            </div>
        }

        if (mode === 'high_rows') {

            personHeight = itemHeight || 240;

            return <div className='panteon_plashka_persons_high_rows'>
                {Persons.map((person) => {
                    return <div key={'plt' + person.Id} className='panteon_person_rect_one'>
                        <PersonPlaceRectMini
                            height={personHeight}
                            personId={person.Id}
                        />
                    </div>
                })}
            </div>
        }

        if (mode === 'high_rows_scores') {

            personHeight = itemHeight || 240;

            let lvlNow = null;

            return <div className='panteon_plashka_persons_high_rows'>
                {Persons.map((person) => {
                    const { Label, Amount } = person;

                    const { lvl } = client.scoresLvlByType({ type: Label, score: { all: Amount || 1 } });

                    if (!lvlNow || lvl !== lvlNow) {
                        lvlNow = lvl;
                        return <>
                            <div className='panteon_person_rect_one_divider'> {`Уровень ${lvlNow}`} </div>
                            <div key={'plt' + person.Id} className='panteon_person_rect_one'>
                                <PersonPlaceRectMini
                                    height={personHeight}
                                    // personId={person.Id}
                                    personDirect={person}
                                    rightSuffix={<ScoreComponent size={personHeight * 0.252} type={Label} amount={Amount} withLvl={true} />}
                                />
                            </div>
                        </>
                    }

                    return <div key={'plt' + person.Id} className='panteon_person_rect_one'>
                        <PersonPlaceRectMini
                            height={personHeight}
                            // personId={person.Id}
                            personDirect={person}
                            rightSuffix={<ScoreComponent size={personHeight * 0.252} type={Label} amount={Amount} withLvl={true} />}
                        />
                    </div>
                })}
            </div>
        }

        personHeight = itemHeight || 70;

        setComponentSelfHeight({ height: titleHeight + personHeight * (Persons.length + 1), plashkaId });

        return <div className='panteon_plashka_persons'>
            {Persons.map((person) => {
                return <LittlePerson
                    height={personHeight}
                    key={'plt' + person.Id}
                    // personId={person.Id}
                    DirectPerson={person}
                    personsDirect
                />
            })}
        </div>
    }

}

export default withCapoeiraSportState('client, isMobile')(PanteonPlashka);