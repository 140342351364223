import React from 'react';
import { sortBy, orderBy } from 'lodash';
import { sendPlacesToServer } from './send';
import { Button } from 'antd';

const PLACES_CONST = {
    1: {
        outside: 'brikets-div-gold-outside',
        inside: 'brikets-div-gold-inside',
    },
    2: {
        outside: 'brikets-div-silver-outside',
        inside: 'brikets-div-silver-inside',
    },
    3: {
        outside: 'brikets-div-bronze-outside',
        inside: 'brikets-div-bronze-inside',
    }
}

// прямая палка
export const returnStrickSticks = () => {
    return <div className="circle-couple-sticks-div">
        <div className="strickStick1" />
        <div className="strickStick1" />
    </div>
}

// прямая палка
export const returnShiftStrickSticks = (circle_id) => {
    if ((circle_id % 2) === 1) return <div className="circle-couple-sticks-div circle-couple-sticks-div-full">
        <div className="strickStickDown" />
    </div>

    return <div className="circle-couple-sticks-div circle-couple-sticks-div-full">
        <div className="strickStickUpper" />
    </div>
}

// палка верхняя
export const returnUpperSticks = (circle_id, notail) => {

    if (circle_id === 1 || notail === true) return <div />

    return <div className="circle-couple-sticks-div">
        <div className="upperStick1" />
        <div className="upperStick2" />
    </div>
}

// палка нижняя
export const returnBottomSticks = (circle_id, notail) => {

    if (circle_id === 1 || notail === true) return <div />

    return <div className="circle-couple-sticks-div">
        <div className="bottomStick1" />
        <div className="bottomStick2" />
    </div>
}

// БОЛЬШОЕ РИСОВАНИЕ


// нарисовать места для обычной категории
export const placesDraw = function (notail) {

    if (notail === true) return <div />;

    const { competitors } = this.state;
    const { IsIAmAdmin } = this.props;
    const competitors_ = sortBy(competitors, x => { return x.Place });

    let Answer = [];

    for (let i = 0; i < competitors_.length; i++) {
        if (competitors_[i].Place > 0 && competitors_[i].Place < 4) {
            Answer.push(
                <div className='briket-competitor-winner'>
                    <div className={PLACES_CONST[competitors_[i].Place].outside}>
                        <div className={PLACES_CONST[competitors_[i].Place].inside}>
                            {competitors_[i].Place}
                        </div>
                    </div>

                    {this.returnOneSportsmen(competitors_[i].Surname + ' ' + competitors_[i].Name, competitors_[i].Id, competitors_[i].GroupIamInId, competitors_[i].SchoolId, false)}

                </div>
            );
        }

    }

    if (IsIAmAdmin)
        Answer.push(
            // <Button onClick={() => sendPlacesToServer(this.props, this.state)} > Отправить места </Button>
        );

    return Answer;
}


// нарисовать места для круговой категории
export const placesDrawCircle = function () {
    const { competitors, scores, wins, story } = this.state;
    const { IsIAmAdmin } = this.props;
    // снчала по жребию
    let competitors__ = sortBy(competitors, x => { return x.Jrebiy });

    //потом дописываем очки
    for (let i = 0; i < competitors__.length; i++) {
        competitors__[i].circleScores = 0;
        competitors__[i].winsScores = 0;
        competitors__[i].tieScores = 0;
        if (scores[i + 1]) competitors__[i].circleScores = scores[i + 1];
        if (wins[i + 1]) competitors__[i].winsScores = wins[i + 1];
    }

    let competitors_ = orderBy(competitors__, ['winsScores', 'circleScores'], ['desc', 'desc']);

    // теперь смотрим тай-брейки
    for (let i = 0; i < competitors_.length - 1; i++) {
        const wins1 = competitors_[i].winsScores;
        const wins2 = competitors_[i + 1].winsScores;

        const scores1 = competitors_[i].circleScores;
        const scores2 = competitors_[i + 1].circleScores;

        if (wins1 && (wins1 === wins2) && (scores1 === scores2)) {
            // теперь надо проверить, что нет тая со следйющим участников
            if (competitors_[i + 2] && competitors_[i + 2].winsScores === wins1 && competitors_[i + 2].circleScores === scores1) {

            } else {
                // смотрим тай
                // ищем пару
                const neededStory = story.find(
                    x =>
                    (
                        (x.Red_Id === competitors_[i].Jrebiy && x.Blue_Id === competitors_[i + 1].Jrebiy) || 
                        (x.Red_Id === competitors_[i+1].Jrebiy && x.Blue_Id === competitors_[i].Jrebiy)
                    )
                );
                if ( neededStory && neededStory.WinTo ) {
                    let neededJrebiy = 0;
                    if (neededStory.WinTo === 1 || neededStory.WinTo === 3) {
                        neededJrebiy = neededStory.Red_Id;
                    } else {
                        neededJrebiy = neededStory.Blue_Id;
                    }

                    for ( let j = 0; j < competitors_.length; j++) {
                        if (competitors_[j].Jrebiy === neededJrebiy) {
                            competitors_[j].tieScores = 1;
                            continue;
                        }
                    }
                }
            }
        }
    }

    
    competitors_ = orderBy(competitors_, ['winsScores', 'circleScores', 'tieScores'], ['desc', 'desc', 'desc']);

    let Answer = [];

    for (let i = 0; i < competitors_.length; i++) {
        if (competitors_[i].Place > 0 && competitors_[i].Place < 4) {
            Answer.push(
                <div className='briket-competitor-winner'>

                    <div className={'brikets-div-blank-outside brikets-div-blank-outside-wins'}>
                        {`${competitors_[i].winsScores} П`}<br />
                        {`${competitors_[i].circleScores} Ф`}
                    </div>

                    <div className={PLACES_CONST[competitors_[i].Place].outside}>
                        <div className={PLACES_CONST[competitors_[i].Place].inside}>
                            {competitors_[i].Place}
                        </div>
                    </div>

                    {this.returnOneSportsmen(competitors_[i].Surname + ' ' + competitors_[i].Name, competitors_[i].Id, competitors_[i].GroupIamInId, competitors_[i].SchoolId, false)}

                </div>
            );
        } else {
            Answer.push(
                <div className='briket-competitor-winner'>

                    <div className={'brikets-div-blank-outside brikets-div-blank-outside-wins'}>
                        {`${competitors_[i].winsScores} П`}<br />
                        {`${competitors_[i].circleScores} Ф`}
                    </div>
                    {this.returnOneSportsmen(competitors_[i].Surname + ' ' + competitors_[i].Name, competitors_[i].Id, competitors_[i].GroupIamInId, competitors_[i].SchoolId, false)}

                </div>
            );
        }

    }

    if (IsIAmAdmin)
        Answer.push(
            // <Button onClick={() => sendPlacesToServer(this.props, this.state)} > Опубликовать места </Button>
        );

    return Answer;
}