import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import LittlePerson from 'src/ver2/components/person/little';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import { SoloMiniSequenceRow } from 'src/ver2/containers/challenges/components/mini-sequence-row';

import Loader from 'src/ver2/components/loader';

import { Collapse, Icon } from 'antd';
import { orderBy, groupBy } from 'lodash';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventSoloUsersPlashka
// classname: event_solousers_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */


class EventSoloUsersPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false,
            selectedPerson: 0
        }

        props.client.getServerTime().then((result) => {
            this.setState({ serverTime: result })
        });
    }

    componentDidMount = () => {
        // this.setHeight()
    }

    componentDidUpdate = () => {
        // this.setHeight()
    }

    setHeight = async (height) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const { heightSetted } = this.state;

        console.log('heightSetted', { heightSetted, height });

        if (heightSetted !== height) {
            this.setState({
                heightSetted: height
            })
            setComponentSelfHeight({ height, plashkaId });
        }
    }


    render() {

        const { Event, Rounds, isRoundsReady, Participators, isParticipatorsReady, upperStyle, client, iAmParticipator, isMobile, isSoloSequencesReady, SequncesConfirm, SequncesWaiting } = this.props;
        const { serverTime, selectedPerson } = this.state;
        const { width, height } = upperStyle;

        if (!isParticipatorsReady || !isSoloSequencesReady || !isRoundsReady) return <div style={{ width, height }} className='event_solousers_plashka'>
            <Loader />
        </div>

        const { SettingsObj } = Event;
        let TierNames = {};
        let SequencesConfirmMode = 'confirmed';
        let SplitYear = '2008';
        if (SettingsObj) {

            if ( SettingsObj.ChallengeSettings ) {
                if (SettingsObj.ChallengeSettings.SplitYear) {
                    SplitYear = SettingsObj.ChallengeSettings.SplitYear;
                }
                if (SettingsObj.ChallengeSettings.SequencesConfirmMode) {
                    SequencesConfirmMode = SettingsObj.ChallengeSettings.SequencesConfirmMode;
                }
            }

            if (SettingsObj.ParticipatorsMain && SettingsObj.ParticipatorsMain.ParticipatorsData) {
                const tierNames = SettingsObj.ParticipatorsMain.ParticipatorsData.find(x => x.name === 'tier');
                if (tierNames && tierNames.options) {
                    TierNames = tierNames.options.reduce((memo, value) => {
                        return { ...memo, [value.key]: value.title }
                    }, {})
                }
            }
        }

        const getTierName = (tier) => {
            if (TierNames[tier]) {
                return TierNames[tier];
            }
            return `Tier ${tier}`;
        }

        const RoundsGrouped = groupBy ( Rounds, 'Tier' );

        // let ParticipatorsByTiers = {
        //     4: [],
        //     3: [],
        //     2: [],
        //     1: []
        // }

        let Participators_ = [];
        for (let i = 0; i < Participators.length; i++) {
            const { Settings, UserId } = Participators[i];
            const SettingsObj = JSON.parse(Settings);
            const { tier } = SettingsObj;

            let Points = 0;
            if (SequncesConfirm && SequncesConfirm.byUser && SequncesConfirm.byUser[UserId]) {
                Points = SequncesConfirm.byUser[UserId].reduce((memo, { Points }) => { return memo + Points }, 0)
            }

            const { BirthDay } = Participators[i];
            const year = BirthDay.split('.')[2];

            let Category = '';
            if (SplitYear !== 'nosplit') {
                Category = year > String(SplitYear) ? 'до 14 лет' : '14+';
            }

            Participators_.push({ ...Participators[i], tier, Points, Category })
        }

        const heightToSet = Math.max((isMobile ? 700 : 950), 400 + Participators_.length * (isMobile ? 50 : 80));
        this.setHeight(heightToSet);

        // Participators_ = sortBy(Participators_, ['tier', 'Points'], ['desc', 'asc']);

        Participators_ = orderBy(Participators_, ['Category', 'tier', 'Points'], ['asc', 'asc', 'desc']);
        let TierNow = 0;
        let CategoryNow = '';

        return <div style={{ width, height }} className='event_solousers_plashka'>
            {Participators_.map((person) => {

                let seqConfirmCount = 0;

                if (SequencesConfirmMode === 'confirmed') {
                    if (SequncesConfirm && SequncesConfirm.byUser && SequncesConfirm.byUser[person.UserId]) {
                        seqConfirmCount = SequncesConfirm.byUser[person.UserId].length;
                    }
                }

                if (SequencesConfirmMode === 'withvideo') {
                    if (SequncesConfirm && SequncesConfirm.byUser && SequncesConfirm.byUser[person.UserId]) {
                        seqConfirmCount = SequncesConfirm.byUser[person.UserId].length;
                    }
                    if (SequncesWaiting && SequncesWaiting.byUser && SequncesWaiting.byUser[person.UserId]) {
                        seqConfirmCount += SequncesWaiting.byUser[person.UserId].reduce ( (memo, {IsVideo}) => IsVideo ? memo + 1 : memo , 0)
                    }
                }

                let Points = 0;
                if (SequncesConfirm && SequncesConfirm.byUser && SequncesConfirm.byUser[person.UserId]) {
                    Points = SequncesConfirm.byUser[person.UserId].reduce((memo, { Points }) => { return memo + Points }, 0)
                }

                const { tier, Category } = person;

                const Suffix = <div className='event_solousers_plashka_suffix'>
                    <NumberText count={`${seqConfirmCount} / ${RoundsGrouped[tier] ? RoundsGrouped[tier].length : '?'}`} text={'СВЯЗКИ'} height={isMobile ? 30 : 45} />
                    <NumberText count={Points} text={'БАЛЛЫ'} height={isMobile ? 30 : 45} />
                    <div className={selectedPerson === person.UserId ? 'event_solousers_plashka_suffix_end event_solousers_plashka_suffix_end_looked' : 'event_solousers_plashka_suffix_end'}
                        onClick={() => {
                            if (selectedPerson === person.UserId) return this.setState({ selectedPerson: 0 });
                            this.setState({ selectedPerson: person.UserId });
                        }}
                    >
                        <Icon type='right-circle' />
                    </div>
                </div>

                const Sequences = (selectedPerson === person.UserId) ?
                    <>
                        {(SequncesConfirm && SequncesConfirm.byUser && SequncesConfirm.byUser[person.UserId]) && SequncesConfirm.byUser[person.UserId].map(sequence => {
                            return <SoloMiniSequenceRow color_status key={`round_sequences_plashka_sequence${sequence.Id}`} sequence={sequence} client={client} isMobile={isMobile} />
                        })}
                        {(SequncesWaiting && SequncesWaiting.byUser && SequncesWaiting.byUser[person.UserId]) && SequncesWaiting.byUser[person.UserId].map(sequence => {
                            return <SoloMiniSequenceRow do5judges color_status key={`round_sequences_plashka_sequence${sequence.Id}`} sequence={sequence} client={client} isMobile={isMobile} />
                        })}
                    </>
                    : null

                // const Sequences = SequncesConfirm.byUser[person.UserId].map(sequence => {
                //     return <SoloMiniSequenceRow color_status key={`round_sequences_plashka_sequence${sequence.Id}`} sequence={sequence} client={client} isMobile={isMobile} />
                // })

                if (tier !== TierNow || CategoryNow !== Category) {
                    TierNow = tier;
                    CategoryNow = Category;
                    return <>
                        <div className='event_rounds_plashka_title'>{`${getTierName(tier)} • ${Category}`}</div>
                        <LittlePerson
                            height={isMobile ? 40 : 70}
                            key={'plt' + person.UserId}
                            // personId={person.Id}
                            DirectPerson={{ ...person, Id: person.UserId }}
                            personsDirect
                            Suffix={Suffix}
                        />
                        {Sequences}
                    </>
                }

                return <><LittlePerson
                    height={isMobile ? 40 : 70}
                    key={'plt' + person.UserId}
                    // personId={person.Id}
                    DirectPerson={{ ...person, Id: person.UserId }}
                    personsDirect
                    Suffix={Suffix}
                />
                    {Sequences}

                </>
            })
            }
        </div>
    }

}

// const EventSoloUsersPlashkaWrapped = (props) => <EventWrapper Wrapped={EventSoloUsersPlashka} eventId={props.eventId} componentProps={{ ...props }} />
// export default withCapoeiraSportState('client')(EventSoloUsersPlashkaWrapped);

export default withEventState(`
Event, 

Rounds,
isRoundsReady, 

iAmParticipator,

Participators,
isParticipatorsReady,

SequncesConfirm, 
SequncesWaiting, 
isSoloSequencesReady,

client,
iamuser,
isMobile

`)(EventSoloUsersPlashka)