import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { SoloMiniSequenceRow } from 'src/ver2/containers/challenges/components/mini-sequence-row';
import './style.css';


// мы показываем мониторчик и в нём по очереди показываем видео разхныъ связок


class SeqMonitorPlashka extends PureComponent {

    state = {
        seqNowNumber: 0,
        status: 'loading'
    }

    videoRef = React.createRef();

    componentDidMount = async () => {
        const { eventId, personId, groupId, sequences, client, maxSequences = 3 } = this.props;

        if (sequences) {
            this.setState({ sequences, status: 'loaded' });
        }

        if (eventId || groupId) {
            let sequencesAnswer = [];
            if (eventId)
                sequencesAnswer = await client.getSoloCompetitionSequences({ SoloCompetitionId: eventId, Status: 'confirm' });

            if (groupId)
                sequencesAnswer = await client.getSoloGroupSequences({ GroupId: groupId, Status: 'confirm' });

            let sequences = [];
            if (sequencesAnswer.length > maxSequences) {
                while (sequences.length < maxSequences) {
                    const index = Math.floor(sequencesAnswer.length * Math.random());
                    if (index < sequencesAnswer.length) {
                        if (!sequences.find(x => x.Id === sequencesAnswer[index].Id)) {
                            sequences.push(sequencesAnswer[index]);
                        }
                    }
                }
            }

            this.setState({ sequences, status: 'loaded' });
        }
    }

    onEnded = () => {
        const { maxSequences = 3 } = this.props;
        const { seqNowNumber, sequences } = this.state;

        let newSeqNumber = seqNowNumber + 1;
        if (newSeqNumber > maxSequences - 1) newSeqNumber = 0;

        const source = document.getElementById('videoMP4');
        const { Code } = sequences[newSeqNumber];

        this.videoRef.current.pause()
        source.setAttribute('src', `https://capoeirasport.com/graphics/video_technics/sequences/${Code}.mp4`);
        this.videoRef.current.load();
        this.videoRef.current.play();

        return this.setState({ seqNowNumber: newSeqNumber })
    }

    changeIndex = (index) => {
        const { sequences } = this.state;
        const source = document.getElementById('videoMP4');
        const { Code } = sequences[index];

        this.videoRef.current.pause()
        source.setAttribute('src', `https://capoeirasport.com/graphics/video_technics/sequences/${Code}.mp4`);
        this.videoRef.current.load();
        this.videoRef.current.play();

        return this.setState({ seqNowNumber: index })

    }

    componentDidUpdate({ }, { seqNowNumber }) {
        if (seqNowNumber !== this.state.seqNowNumber) {
            const element = document.getElementsByClassName(`seqmonitor_plashka_sequences`);
            if (element && element[0]) element[0].scrollTop = this.state.seqNowNumber * 58;
        }
    }

    render() {

        const { upperStyle, isMobile, client, maxWidth, withName, vertical, directHeight, maxWidthStretch } = this.props;
        const { status, sequences, seqNowNumber } = this.state; //это просто некие связки 

        const { width, height } = upperStyle;


        if (status === 'loading') return <div style={{ width, height }} className='seqmonitor_plashka'>

        </div>

        if (!sequences.length) {
            return <div>Нет связок</div>
        }

        let monitorWidth = maxWidth || height * 1280 / 720;
        if (maxWidthStretch) monitorWidth = width;
        const { Code } = sequences[seqNowNumber];

        return <div style={{ width, height }} className={'seqmonitor_plashka ' + (vertical ? 'seqmonitor_plashka_vertical' : '')}>
            <div className='seqmonitor_plashka_sequences'>
                {sequences.map((sequence, index) => {
                    return <div id={`seqmonitor_plashka_seqnumber_${index}`} key={`seqmonitor_plashka_seq_${index}`} onClick={() => { this.changeIndex(index) }}>
                        < SoloMiniSequenceRow color_status isSelected={seqNowNumber === index} noName={withName ? false : true} sequence={sequence} client={client} isMobile={isMobile} />
                    </div>
                })
                }
            </div>
            <div style={{ minWidth: monitorWidth, width: monitorWidth, height: directHeight || height }} className='seqmonitor_plashka_monitor'>
                <video
                    ref={this.videoRef}
                    playsInline="true"
                    id={`my-player-${Code}`}
                    class="video-js"
                    preload="auto"
                    autoPlay="true"
                    muted="true"
                    poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
                    // width={monitorWidth * 0.92}
                    style={{
                        maxWidth: monitorWidth * 0.92,
                        maxHeight: (directHeight || height) * 0.92
                    }
                    }
                    controls=""
                    data-setup="{}"
                    onEnded={this.onEnded}
                >
                    <source
                        id="videoMP4"
                        src={`https://capoeirasport.com/graphics/video_technics/sequences/${Code}.mp4`}
                        type="video/mp4" />
                </video>
            </div>
        </div>

    }

}

export default withCapoeiraSportState('client, isMobile')(SeqMonitorPlashka);