import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import { REGIONS_MAIN_TEXT, REGIONS_HOW_TO_ADD, MY_REGION } from './text';
import SomeInfo from 'src/ver2/components/info';

import RegionsMap from 'src/ver2/components/regions/map';

import './styles.css';

class RegionsPage extends PureComponent {

    state = {
        status: 'loaded'
    }

    render() {

        const { isMobile, client } = this.props;

        
        const allWidth = isMobile ? isMobile.width : 1200; // это весь контейнер
        let mapWidth = isMobile ? isMobile.width : 1200;
        if (mapWidth < 650) mapWidth = 650;
        // const width = 900;

        const { status } = this.state;

        if (status === 'loading') return <div style={{ width: allWidth, height: 700 }} className='regions_page'>
            <Loader />
        </div>

        const regions = client.regions;

        return <div style={{ allWidth }} className='regions_page'>
            <div className='regions_page_text global_discribe_text'>
                {REGIONS_MAIN_TEXT(Object.keys(regions).length - 1)}
            </div>
            <SomeInfo content={REGIONS_HOW_TO_ADD.title} {...REGIONS_HOW_TO_ADD} size ={'mini'} isMobile = {isMobile} />
            <SomeInfo content={MY_REGION.title} {...MY_REGION} size ={'mini'} isMobile = {isMobile} />
            <div style={{ width: allWidth }} className='regions_page_map'>
                <div style={{ width: mapWidth }} className='regions_page_map_inside'>
                    <RegionsMap width={mapWidth} />
                </div>
            </div>
        </div>
    }
}


export default withCapoeiraSportState('client, showInfoModal, isMobile')(RegionsPage);