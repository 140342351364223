import React, { Component } from 'react';
import './component.css';
import { withRoundState } from 'src/ver2/pages/round/context';

import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { LowerRow } from './components/lower-row'
import { MobileSvyazka } from './components/svyazka'

import { parseRules, getSequenceToFixed } from 'src/ver2/containers/challenges/helpers';

/*

  Тут мы непосредственно создаём связку. Сюда приходит ID раунда

  <SequenceCreator 
        client = {client} 
        round = {round} 
        soloCompetition = {soloCompetition}
        sendSequence = {this.sendSequence} 
        sequenceSended = {sequenceSended}
        reloadPage = {this.reloadPage}
        roundRules = {roundRules}        
    />

    из round нам нужно 
    Sequence - строка, содержащая связку el_hole/ty_any/ty_strike/ty_movement 
    el_ - элемент  ty_  - тип элемента

    Options - строка, содержащая добавочные опции для каких-то пунктов связки st_0*strick/st_4*jump
    st_[номер элемента]*[подтипы, которые могут быть]

    из soloCompetition нам нужно
    Rules - по ним идёт формирование правил текущего соревнования, к ним подцепляются roundRules
    SoloCompetitionId - нужно только для того, чтобы найти связки с этого же соревнования и сделать откаты

    sendSequence - это функция отправки факта того, что мы отправили связку
    sendSequence - флаг, что отправили
    reloadPage - просто перегрузить страницу

    roundRules - это определённые правила для раунда, парсятся из Round.EnterRules, записанные на такой манер
    ti_0ti_0oe_movement+fintcomp+ponte+ponwh+ponlokot/ti_0ti_0er_1
    ti_0 - это вроде как первое определение, что это для всех ТИРов участиков
    ti_0 - дальше это говорит, к какому ТИРу элементов относится дело
    oe_movement - что то типа разрешенные элементы в movement
    через + идёт перечисление

    er_1 - elements repeat - запрещает повторение

*/

export class SequenceCreator extends Component {


  constructor(props) {

    super(props);

    console.log ('SequenceCreator CONSTRUCTOR');

    this.state = {}
    this.state.svyazka_to_show = undefined;


    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {

      this.state.mobile = true;

    } else this.state.mobile = false;





    this.state.round = props.Round;

    const fixed = props.FixedSequence;

    let our_svyazka = []

    for (let i = 0; i < fixed.length; i++) {
      our_svyazka.push(null);
    }

    // потом будут браться из другого места

    const { client, soloCompetition, roundRules } = props;

    //this.state.element = client.getServerElements()

    this.state.elements = client.getServerElements().reduce(
      (result, item) => {

        const element = {
          ...item,
          titles: item.title,
          id: item.key,
          subtypes: item.subtypes || [],
          basic_points: item.basic_points || 1
        }


        return { ...result, [item.key]: { ...element } }


      },
      {}
    )

    const { Rules, Id: SoloCompetitionId } = soloCompetition || {};

    let rules = {};

    if (Rules) {
      rules = parseRules(Rules);

      if (roundRules && roundRules.OnlyElements) {
        if (roundRules.OnlyElements) rules.OnlyElements = roundRules.OnlyElements;
        if (roundRules.ElementsRepeat) rules.ElementsRepeat = roundRules.ElementsRepeat;
      }
    }

    if (!Rules && roundRules) {
      rules = roundRules;
    }

    console.log ('SequenceCreator', { rules });

    this.state.status = 'loading';
    this.state.our_svyazka = our_svyazka;

    this.state.sequenceSelectedNumber = undefined;
    this.state.cardSelectedElement = undefined;

    this.state.rules = rules;

    this.state.status = 'loaded';
    this.state.userUsedElements = {};

    return;

    // эта штука была раньше для откатов элементов
    const UserId = client.loggedUserId();
    if (UserId) {
      // подгружаем все связки из данного соревнования данного юзера
      client.getSoloUserCompetitionSequences({ SoloCompetitionId, UserId }).then(resultSeq => {
        let userUsedElements = {};
        if (resultSeq && resultSeq.length > 0) {
          for (let i = 0; i < resultSeq.length; i++) {
            const elements = resultSeq[i].Sequence.split('/');
            for (let j = 0; j < elements.length; j++) {
              const element = elements[j];

              /* Теперь нам надо понять, как ДАВНО использовался элемент

                Мы идём сверху вниз, т.е. давность - это resultSeq.length - i
                Если всего связок три, мы на нулевой связке нашли элемент значит мы использовали элемен resultSeq.length - i + 1
                Т.е. мы сейчас будем делать ЧЕТВЁРТУЮ

                Если мы находим элемент ниже, то просто переписываем его еще раз

              */

              userUsedElements[element] = resultSeq.length - i - 1; // это то, сколько раундов ПРОШЛО, с тех пор как мы использовали
              //т.е. если использовали в предыдущей связке, то это будет 0

            }
          }
        }

        this.setState({ status: 'loaded', userUsedElements })

      });

    }

  }

  // мы тыкнули на какую то карточку в верхней связке
  // она считается выбранной и дальше мы можем выбрать технику, которую на неё положить
  selectSvyazkaNumber = (number) => {

    if (number === this.state.sequenceSelectedNumber) return this.setState({ sequenceSelectedNumber: undefined });

    this.setState({ sequenceSelectedNumber: number })
  }

  // мы тыкнули на какую то карточку в нижней части
  // она считается выбранной и дальше мы можем выбрать верхнюю карточку, чтобы на неё положить
  selectCardElement = (element) => {

    if (element === this.state.cardSelectedElement) return this.setState({ cardSelectedElement: undefined });

    this.setState({ cardSelectedElement: element })
  }

  formSvyazkaToShow = () => {

    const { round, our_svyazka } = this.state;
    const { FixedSequence } = this.props;
    const { Sequence, Options } = round;

    const fixed = FixedSequence;

    const svyazka_options = {
      limits: [
      ],
      // это значит что элементы должны быть именно в этом порядке
      fixed,
    }

    // также есть уже набранная нами связка, она хранится в стейте

    // то что мы показываем
    let svyazka_to_show = []

    let points = 0;

    if (svyazka_options.fixed && svyazka_options.fixed.length) {
      for (let i = 0; i < svyazka_options.fixed.length; i++) {

        let data = {}
        // если мы уже добавили
        if (our_svyazka[i]) {

          const {
            element_id
          } = our_svyazka[i]

          data = {
            our: true, // этот элемент мы уже добавили
            element_id
          }

          // тут может быть еще своеобразная система счета
          points += this.getPointToElement(element_id)

        } else {

          // мы не добавили этот элемент

          data = {
            our: false, // этот элемент мы уже добавили
            fixed: svyazka_options.fixed[i],
            ...svyazka_options.fixed[i]
          }

        }

        data.index = i;

        svyazka_to_show.push(
          { ...data }
        )

      }
    }

    this.setState({
      points,
      svyazka_to_show,
      last_our_svyazka: our_svyazka
    })


  }


  //могу ли я дропнуть сюда элемент ?
  mayDropHere = ({ element_id, card }) => {
    const { client } = this.props;
    const { element, type, subtype } = card;

    const elements = client.getServerElementsAsObject();

    const coming_element = elements[element_id]

    // пришел в задании конкретный элемент
    if (element) {
      if (coming_element.key === element) return true;
      return false;
    }

    if (type === 'any') {

      if (!subtype || subtype === '') return true;
      if (coming_element.subtypes && coming_element.subtypes.find(x => x === subtype)) return true;

      return false;

    }
    if (type && type !== coming_element.type) return false;
    if (subtype && coming_element.subtypes && !coming_element.subtypes.find(x => x === subtype)) return false;
    if (subtype && !coming_element.subtypes) return false;

    return true;
  }

  render() {
    const opts = {}

    const {
      mobile,
      elements,
      our_svyazka,
      status,
      round,
      sequenceSelectedNumber,
      cardSelectedElement,
      rules,
      userUsedElements,
      points,
      svyazka_to_show,
      last_our_svyazka

    } = this.state;
    const { client, sequenceSended, sendSequence, reloadPage, UserElements, iamuser } = this.props;

    const backend = mobile ? TouchBackend : HTML5Backend;

    if (status === 'loading') return <div>Загрузка...</div>

    console.log('UserElements', UserElements)

    return (
      <div className='dndtry-div'>
        <DndProvider backend={backend} options={opts}>
          {!sequenceSended && <MobileSvyazka
            iamuser={iamuser}
            client={client}
            elements={elements}
            round={round}
            mobile={mobile}
            our_svyazka={our_svyazka}
            getPointToElement={this.getPointToElement}
            newOurSvyazka={this.newOurSvyazka}
            selectSvyazkaNumber={this.selectSvyazkaNumber}
            mayDropHere={this.mayDropHere}
            formSvyazkaToShow={this.formSvyazkaToShow}
            {...{
              points,
              rules,
              svyazka_to_show,
              last_our_svyazka,
              sequenceSelectedNumber,
              cardSelectedElement
            }}
          />}
          <LowerRow
            selectCardElement={this.selectCardElement}
            setOktatAndChangedPoints={this.setOktatAndChangedPoints}
            mayDropHere={this.mayDropHere}
            UserElements = {UserElements}
            {...{
              points,
              RoundId: round.Id,
              userUsedElements,
              RoundTier: round.Tier,
              rules, elements,
              client, mobile,
              our_svyazka,
              sendSequence,
              reloadPage,
              cardSelectedElement,
              sequenceSelectedNumber,
              svyazka_to_show
            }} />
        </DndProvider>

        {/*Это проба DnD {a}

            <DndProvider backend={backend} options={opts}>
    
               <this.knight/> 

               <this.board/>

    </DndProvider>*/}

      </div>
    );
  }


  // обновить связку
  newOurSvyazka = ({ our_svyazka, reset }) => {
    if (!reset)
      this.setState({ our_svyazka })

    this.setState({ our_svyazka, sequenceSelectedNumber: undefined, cardSelectedElement: undefined })
  }

  // очки за элемент
  getPointToElement = (element_id) => {

    if (!element_id || !this.state.elements[element_id])
      return 0;

    return this.getChengedPointsToElementKey(element_id)

  }

  getChengedPointsToElementKey = (element_id) => {


    const { client } = this.props;

    const element = client.elements_as_object[element_id];

    const { changedPoints } = this.setOktatAndChangedPoints(element);

    if (changedPoints && changedPoints > 0) return changedPoints;

    return element.Points;
  }

  setOktatAndChangedPoints = (element) => {

    //еще мы тут смотрим откат элементов userUsedElements
    const otkat_object = this.setOtkatToElement(element);
    let otkat = 0;
    let percent = null;

    if (otkat_object) {
      otkat = otkat_object.otkat;
      percent = otkat_object.percent;
    }

    // это есл мы запретили вообще откат, тогда для oktak = 0 percent = 0
    if (otkat === 0 && percent === 0) return { skip: true, otkat: 0, percent: 0, changedPoints: null }

    // и еше смотрим измененные очки в ТИР-е
    let changedPoints = this.setChangedPointsToElement(element);

    // теперь если есть percent 
    if (percent) {
      if (changedPoints) changedPoints = changedPoints * percent * 0.01;
      if (!changedPoints) changedPoints = element.Points * percent * 0.01;
    }

    return { otkat, percent, changedPoints }

  }

  setOtkatToElement = (element) => {

    const { userUsedElements, round, rules } = this.state;

    const RoundTier = round.Tier;

    if (!userUsedElements || userUsedElements[element.key] === undefined) return undefined;

    const ruleTier = rules.Tier[RoundTier];
    const { Otkat } = ruleTier;

    // если в целом есть откат для всех
    if (Otkat.Tier[0]) {

      if (Otkat.Tier[0].count > userUsedElements[element.key]) {
        return {
          otkat: Otkat.Tier[0].count - userUsedElements[element.key],
          percent: Otkat.Tier[0].percent
        };
      }
      // это когда откат бесконечный, т.е. нельзя вообще использовать элемент
      if (Otkat.Tier[0].count === 0 && Otkat.Tier[0].percent === 0) {
        return {
          otkat: 0,
          percent: 0
        }
      }

      return undefined;

    }

    return undefined;

  }

  // Это если у нас есть изменённые очки для элемента
  setChangedPointsToElement = (element) => {

    const { round, rules } = this.state;

    const RoundTier = round.Tier;

    return null;

    const ruleTier = rules.Tier[RoundTier];

    const {
      Percents
    } = ruleTier;

    const { Tier } = element;

    // есть приценты на данный тир
    if (Percents.Tier[Tier]) {
      return element.Points * Percents.Tier[Tier] * 0.01;
    }

    return null;

  }

}


export default withRoundState(`
Round,
isRoundReady,  
UserElements,
FixedSequence,
iamuser,
client,
`)(SequenceCreator);