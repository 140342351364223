import React, { Component } from 'react';
//import logo from './logo.svg';

import { Avatar, Icon, Tooltip, Button } from 'antd';
import { useDrag } from 'react-dnd'

// import { Cordao } from '../../../Cordao';

import './style.css';

export class Student extends Component {

    constructor() {
        super()
    }

    render() {

        const { student, fields, index, client, onClickToEdit, clickToPayment = () => { } } = this.props;

        let Answer = [];

        const schoolGradation = client.getSchoolGradation(1);

        /*                                 {type: 'index', width: 10},
                                 {type: 'avatar', width: 30},
                                 {type: 'fioa', width: 150},
                                 {type: 'cordao', width: 75},
                                 {type: 'payment', width: 50},*/

        for (let i = 0; i < fields.length; i++) {
            const { type, width } = fields[i];
            switch (type) {

                case 'index':
                    Answer.push(<div className='student-container-cell student-container-cell-index' style={{ width, minWidth: width }}>
                        <p>
                            {index}
                        </p>
                    </div>)
                    break;

                // eslint-disable-next-line no-fallthrough
                case 'avatar':
                    const avatarUrl = client.getUsersUrl() + student.Id + '.png';
                    Answer.push(<div className='student-container-cell' style={{ width, minWidth: width }}>
                        <Avatar size={width * 0.9} src={avatarUrl} />
                    </div>)
                    break;

                // eslint-disable-next-line no-fallthrough
                case 'fioa':

                    onClickToEdit ?
                        Answer.push(<a onClick={() => { onClickToEdit(student) }} className='student-container-cell' style={{ width, minWidth: width }}>
                            <p>
                                {(student.Apelido && student.Apelido !== ' ')
                                    ?
                                    `${student.Surname} ${student.Name} (${student.Apelido})`
                                    :
                                    `${student.Surname} ${student.Name}`
                                }
                            </p>
                        </a>)
                        :

                        Answer.push(<div className='student-container-cell' style={{ width, minWidth: width }}>
                            <p>
                                {(student.Apelido && student.Apelido !== ' ')
                                    ?
                                    `${student.Surname} ${student.Name} (${student.Apelido})`
                                    :
                                    `${student.Surname} ${student.Name}`
                                }
                            </p>
                        </div>)
                    break;

                case 'fioac':

                    onClickToEdit ?

                        Answer.push(
                            <div className='student-container-cell' style={{ width, minWidth: width }}>
                                <a onClick={() => { onClickToEdit(student) }} className={'student-container-cell-fioac-name ' + (student.Foreigner ? 'student-container-cell-fioac-name-foreigner' : '')}>
                                    {(student.Apelido && student.Apelido !== ' ')
                                        ?
                                        `${student.Surname} ${student.Name} (${student.Apelido})`
                                        :
                                        `${student.Surname} ${student.Name}`
                                    }
                                </a>
                                <div className='student-container-cell-fioac-cordao'>
                                    {/* {schoolGradation?<Cordao scale = {0.5} noname cordao = {schoolGradation[student.Cordao]}/>:<div/>} */}
                                </div>
                            </div>
                        )

                        :

                        Answer.push(
                            <div className='student-container-cell' style={{ width, minWidth: width }}>
                                <div className='student-container-cell-fioac-name'>
                                    {(student.Apelido && student.Apelido !== ' ')
                                        ?
                                        `${student.Surname} ${student.Name} (${student.Apelido})`
                                        :
                                        `${student.Surname} ${student.Name}`
                                    }
                                </div>
                                <div className='student-container-cell-fioac-cordao'>
                                    {/* {schoolGradation?<Cordao scale = {0.5} noname cordao = {schoolGradation[student.Cordao]}/>:<div/>} */}
                                </div>
                            </div>
                        )

                    break;

                case 'cordao':
                    Answer.push(<div className='student-container-cell' style={{ width, minWidth: width }}>
                        {/* {schoolGradation?<Cordao scale = {0.5} noname cordao = {schoolGradation[student.Cordao]}/>:<div/>} */}
                    </div>)
                    break;

                case 'payment': {
                    const { payment } = this.props;
                    const { isThereUnpaid, isMonthPayed } = payment;

                    let text = '';
                    if (isThereUnpaid) text = '❌';
                    if (isMonthPayed) text = '❎';

                    let className = 'student-container-cell student-container-paymentkey';

                    if (!isThereUnpaid && !isMonthPayed) className += ' student-container-paymentkey_nulled'

                    Answer.push(<div className={className} onClick={() => { clickToPayment(student) }} title='Добавить оплату' style={{ width, minWidth: width }}>
                        {text}
                    </div>)
                    break;
                }

                case 'payment_mobile': {
                    const { payment } = this.props;
                    const { isDayPayed, isMonthPayed, visit_student } = payment;

                    let text = '';
                    if (visit_student && !isDayPayed && !isMonthPayed) text = '🔴';
                    if (isDayPayed) text = '🟢';
                    if (isMonthPayed) text = '❎';

                    let className = 'student-container-cell student-container-paymentkey';

                    if (!text) className += ' student-container-paymentkey_nulled-mobile'

                    Answer.push(<div className={className} onClick={() => { clickToPayment(student) }} title='Добавить оплату' style={{ width, minWidth: width }}>
                        {text}
                    </div>)
                    break;
                }

                case 'visits':
                    const { visits } = this.props;
                    Answer.push(<div className={'student-container-cell student-container-cell-index'} title='Посещений в месяце' style={{ width, minWidth: width }}>
                        <p>
                            {visits || ''}
                        </p>
                    </div>)
                    break;

                default: ;
            }
        }

        return <this.Stud>
            {Answer}
        </this.Stud>
    }

    Stud = ({ children }) => {


        const { student, notDragged, subgroupId } = this.props;

        if (notDragged) {
            return <div className='student-container'>
                {children}
            </div>
        }


        const [{ isDragging }, drag] = useDrag({
            item: { type: 'KNIGHT', ...student, subgroupId },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
        })

        return <div ref={drag} className='student-container'>
            {children}
        </div>

    }

}