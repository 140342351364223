
export default class CRMClient {

  constructor({ standartDataResult, getLastSessionId }) {
    this.standartDataResult = standartDataResult;
    this.getLastSessionId = getLastSessionId;
  }

  get lastSessionId() {
    return this.getLastSessionId;
  }

  async getSubgroup(subgroupid) {
    let uri = 'https://' + this.ServerUrl + '/getSubgroup?subgroupid=' + subgroupid;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {

      let result = [];

      for (let i = 0; i < answer.result.length; i++) {

        const subgroup = answer.result[i];
        const settingsAsObj = (subgroup.Settings && JSON.parse(subgroup.Settings)) || {};

        result.push(
          {
            id: subgroup.Id,
            title: subgroup.Title,
            adres: subgroup.Adres,
            color: subgroup.Color,
            city: subgroup.City,
            groupId: subgroup.GroupId,
            payment_type: subgroup.PaymentType,
            coords: subgroup.Coords,
            settings: subgroup.Settings,
            settingsAsObj: settingsAsObj,
            discipline: settingsAsObj.setDiscipline || false
          }
        )

      }

      return result;
    }
    return [];
  }

  async getSubgroups(groupId) {
    let uri = 'https://' + this.ServerUrl + '/getSubgroups?groupid=' + groupId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {

      let result = [];

      for (let i = 0; i < answer.result.length; i++) {

        const subgroup = answer.result[i];
        const settingsAsObj = (subgroup.Settings && JSON.parse(subgroup.Settings)) || {};

        result.push(
          {
            id: subgroup.Id,
            title: subgroup.Title,
            adres: subgroup.Adres,
            color: subgroup.Color,
            city: subgroup.City,
            payment_type: subgroup.PaymentType,
            settings: subgroup.Settings,
            settingsAsObj: settingsAsObj,
            discipline: settingsAsObj.setDiscipline || false
          }
        )

      }

      return result;
    }
    return [];
  }

  async updateSubgroup(subgroupid, Color, Title, City, Adres, PaymentType, Settings) {
    let uri = 'https://' + this.ServerUrl + '/updateSubgroup?subgroupid=' + subgroupid;

    if (Color) uri += `&Color=${Color}`;
    if (Title) uri += `&Title=${Title}`;
    if (City) uri += `&City=${City}`;
    if (Adres) uri += `&Adres=${Adres}`;
    if (PaymentType) uri += `&PaymentType=${PaymentType}`;
    if (Settings) uri += `&Settings=${Settings}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;
  }

  async addSubgroup(groupid, Color, Title, City, Adres, PaymentType, Settings) {
    let uri = 'https://' + this.ServerUrl + '/addSubgroup?groupid=' + groupid;

    if (Color) uri += `&Color=${Color}`;
    if (Title) uri += `&Title=${Title}`;
    if (City) uri += `&City=${City}`;
    if (Adres) uri += `&Adres=${Adres}`;
    if (PaymentType) uri += `&PaymentType=${PaymentType}`;
    if (Settings) uri += `&Settings=${Settings}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;
  }

  async deleteSubgroup(subgroupid) {
    let uri = 'https://' + this.ServerUrl + '/deleteSubgroup?subgroupid=' + subgroupid;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;
  }



  async getSubgroupUsers(subgroupid) {
    let uri = 'https://' + this.ServerUrl + '/getSubgroupUsers?subgroupid=' + subgroupid;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async addSubgroupUser(userid, subgroupid) {
    let uri = 'https://' + this.ServerUrl + '/addSubgroupUser?subgroupid=' + subgroupid + '&userid=' + userid;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async deleteSubgroupUser(userid, subgroupid) {
    let uri = 'https://' + this.ServerUrl + '/deleteSubgroupUser?subgroupid=' + subgroupid + '&userid=' + userid;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async getShadule(supgroupId) {
    let uri = 'https://' + this.ServerUrl + '/getShadule?subgroupid=' + supgroupId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      let result = [];

      for (let i = 0; i < answer.result.length; i++) {

        const shadule = answer.result[i];

        result.push(
          {
            id: shadule.Id,
            day: shadule.Day,
            subgroupId: shadule.SubgroupId,
            timeStart: shadule.TimeStart,
            timeEnd: shadule.TimeEnd,
          }
        )

      }

      return result;
    }
    return [];
  }

  async updateShadule(shaduleId, day, timestart, timeend) {
    let uri = 'https://' + this.ServerUrl + '/updateShadule?shaduleid=' + shaduleId;

    if (day) uri += `&Day=${day}`;
    if (timestart) uri += `&TimeStart=${timestart}`;
    if (timeend) uri += `&TimeEnd=${timeend}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;
  }

  async addShadule(subgroupId, day, timestart, timeend) {
    let uri = 'https://' + this.ServerUrl + '/addShadule?subgroupid=' + subgroupId;

    if (day) uri += `&Day=${day}`;
    if (timestart) uri += `&TimeStart=${timestart}`;
    if (timeend) uri += `&TimeEnd=${timeend}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;
  }

  async deleteShadule(shaduleId) {
    let uri = 'https://' + this.ServerUrl + '/deleteShadule?shaduleid=' + shaduleId;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return null;
  }

  // VISITS

  async getSubgroupVisits(subgroupid, year, month) {
    let uri = year ? 'https://' + this.ServerUrl + `/getSubgroupVisits?subgroupid=${subgroupid}&year=${year}&month=${month}` :
    'https://' + this.ServerUrl + `/getSubgroupVisits?subgroupid=${subgroupid}`

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async groupVisitsStatistics (groupId) {
      let uri = 'https://' + this.ServerUrl + `/groupVisitsStatistics?groupid=${groupId}`;
      const answer = await this.standartDataResult(uri);
      if (!answer.error) {
        return answer.result;
      }
      return [];
  }

  async addSubgroupVisit(subgroupid, userid, year, month, day, discipline) {
    let uri = 'https://' + this.ServerUrl + '/addSubgroupVisit?subgroupid=' + subgroupid;
    uri += `&userid=${userid}`;
    uri += `&year=${year}`;
    uri += `&month=${month}`;
    uri += `&day=${day}`;
    uri += `&discipline=${discipline}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async deleteSubgroupVisit(visitid) {
    let uri = 'https://' + this.ServerUrl + '/deleteSubgroupVisit?visitid=' + visitid;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async getSubgroupsForeigns(groupid) {
    let uri = 'https://' + this.ServerUrl + '/getSubgroupsForeigns?groupid=' + groupid;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async getCRMPersonStat(userId) {
    let uri = 'https://' + this.ServerUrl + '/getCRMPersonStat?userId=' + userId;
    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  // SB_COMMENTS
  async getSubgroupComments(subgroupid, year, month) {
    let uri = 'https://' + this.ServerUrl + `/getSubgroupComments?subgroupid=${subgroupid}`;
    if (year) uri += `&year=${year}`;
    if (month) uri += `&month=${month}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async addSubgroupComment(subgroupid, year, month, day, comment = null, isCanceled = null) {
    let uri = 'https://' + this.ServerUrl + '/addSubgroupComment?subgroupid=' + subgroupid;
    if (comment) uri += `&comment=${comment}`;
    uri += `&year=${year}`;
    uri += `&month=${month}`;
    uri += `&day=${day}`;
    if (isCanceled) uri += `&isCanceled=${1}`;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async deleteSubgroupComment(sbcommentid) {
    let uri = 'https://' + this.ServerUrl + '/deleteSubgroupComment?sbcommentid=' + sbcommentid;
    uri += `&sessionId=${this.lastSessionId}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async updateSubgroupComment(sbcommentid, updateDataObj = {}) {
    let uri = 'https://' + this.ServerUrl + '/updateSubgroupComment?sbcommentid=' + sbcommentid;
    uri += `&sessionId=${this.lastSessionId}`;

    const updateData = JSON.stringify(updateDataObj);
    uri += `&updateData=${updateData}`;

    const answer = await this.standartDataResult(uri);
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  //SB_REQUEST

  async addRequest({SchoolId, GroupId, SubgroupId, Name = '123', Surname, Tel}) {
    let uri = 'https://' + this.ServerUrl + '/addCRMRequest?Name=' + Name;
    uri += `&Surname=${Surname}`;
    uri += `&Tel=${Tel}`;
    if (SchoolId) uri += `&SchoolId=${SchoolId}`;
    if (GroupId) uri += `&GroupId=${GroupId}`;
    if (SubgroupId) uri += `&SubgroupId=${SubgroupId}`;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async getRequests({GroupId, SchoolId, SubgroupId, StatusNull}) {
    let uri = 'https://' + this.ServerUrl + '/getCRMRequests';

    if (SchoolId) uri += '?SchoolId=' + SchoolId;
    if (GroupId) uri += '?GroupId=' + GroupId;
    if (SubgroupId) uri += '?SubgroupId=' + SubgroupId;
      

    if (StatusNull) uri += `&StatusNull=1`;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

  async updateRequest ({RequestId, Status, Comment, IsHide }) {
    let uri = 'https://' + this.ServerUrl + '/updateCRMRequest?RequestId=' + RequestId;
    if (Status) uri += `&Status=${Status}`;
    if (Comment) uri += `&Comment=${Comment}`;
    if (IsHide) uri += `&IsHide=${IsHide}`;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
      return answer.result;
    }
    return [];
  }

}