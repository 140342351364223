
import React from 'react';
import LinkButton from 'src/ver2/components/ui/buttons/link'
import StarIcon from 'src/ver2/components/ui/icons/star';
import './styles.css';

const ComboTitlePlashka = ({ upperStyle: { width, height }, Title, Difficult, Id }) =>
    <div style={{ width, height }} className='combo_title_plashka'>

        <div style={{ height: height * 0.3, fontSize: height * 0.22 }} className='combo_title_plashka_top'>
            КОМБО
        </div>
        <div style={{ height: height * 0.6 }} className='combo_title_plashka_bottom'>
            <div style={{ fontSize: height * 0.32 }} className='combo_title_plashka_bottom_title'>
                <span>
                    {Title}
                </span>
            </div>
            <div style={{ width: height * 0.6, height: height * 0.6 }} className='combo_title_plashka_bottom_difficult'>
                <div style={{ width: height * 0.6, height: height * 0.6 }} className='combo_title_plashka_bottom_difficult_star'>
                    <StarIcon />
                </div>
                <span>
                    {Difficult}
                </span>
            </div>
            <LinkButton type='button' text='ССЫЛКА' saveText={`https://capoeirasport.com/combo/${Id}`} horizontal />
        </div>
    </div >

export default ComboTitlePlashka;