// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input, message } from 'antd';

/**
 * 
 * Это компонент ISLAND 3 распределения кода
 */

class Quest339 extends PureComponent {
    state = {
        editText: '',
        count: 0
    }

    clickOnSend = () => {
        let editText = this.state.editText;
        if (!editText || editText === '') editText = '_';

        if (editText.length !== 5) { return message.warn('Введите 5 символов'); }
        this.props.sendSpecial({ data: { [editText]: 1 } })
    }

    onChange = (e) => {

        let innerText = e.target.value;
        innerText = innerText.toUpperCase();

        if (innerText.length > 5) {
            return;
        }

        let cdd = {
            3: 2,
            4: 1,
            R: 1,
            G: 1
        }

        for (let i = 0; i < innerText.length; i++) {
            if (!cdd[innerText[i]] || cdd[innerText[i]] <= 0 ) return;
            cdd[innerText[i]]--;
        }

        
        const cddd = 'R43G3';
        let count = 0;
        if ( innerText.length === 5) { 
            for ( let i = 0; i < 5; i ++ ) {
                if ( cddd[i] === innerText[i]) count++;
            }
        }

        this.setState({ editText: innerText, count })
    }

    render() {
        const { isMobile } = this.props;
        const { editText, count } = this.state;
        const className = 'quest27_container'

        return <div className={className}>
            <Input style={{ width: 200 }} value={editText} onChange={this.onChange} />
            <div> лампочек: {count} </div>
            <div className='quest20_container_send' onClick={this.clickOnSend}>
                <span>{'ВВЕСТИ КОД'}</span>
            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest339);