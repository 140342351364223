
import React from 'react';
import LinkButton from 'src/ver2/components/ui/buttons/link'
import './styles.css';

const ExerciseTitlePlashka = ({ upperStyle: { width, height }, innerId, Title, Id }) =>
    <div style={{ width, height }} className='exercise_standart_plashka'>
        <div className='exercise_title_plashka_inside' id={innerId}>
            <div className='exercise_title_plashka_left'>
                <div className='exercise_title_plashka_leftup'>
                    УПРАЖНЕНИЕ
                </div>
                <div className='exercise_title_plashka_leftdown global_color_main'>
                    {Title}
                </div>
            </div>
            <div className='exercise_title_plashka_right'>
                <LinkButton type = 'button' text = 'ССЫЛКА' saveText = {`https://capoeirasport.com/exercise/${Id}`} horizontal/>
            </div>
        </div>
    </div >

export default ExerciseTitlePlashka;