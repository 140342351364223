import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TrefaLayout } from 'src/ver2/containers/layout';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import ApplicationsLittleListPlashka from 'src/ver2/plashki/application/littleList';

import AddEventData from 'src/ver2/containers/addEventModal';
import RaitingUpdate from './containers/raitingUpdate';

import LittleLogger from 'src/ver2/components/logger/little';

import { Modal, Tabs, Input, Button, message } from 'antd';

import './styles.css';

const TabPane = Tabs.TabPane;

class AdminPage extends PureComponent {

    state = {
        Applications: null,
        Logs: [],
        showEventAddModal: false,
        showUploadRaitingModal: false,
        showAddCostModal: false,
        lookedTab: 'standart',
        Cost: 0,
        AcceptedFkr2024_2025: 0,
        PayedFkr2024_2024: 0,
        FinalCost: 0
    }

    changeShowEventAddModal = (showEventAddModal = true) => this.setState({ showEventAddModal })
    changeShowUploadRaitingModal = (showUploadRaitingModal = true) => this.setState({ showUploadRaitingModal })
    changeShowAddCostModal = (showAddCostModal = true) => this.setState({ showAddCostModal })
    changeCost = (e) => this.setState({ Cost: e.target.value })

    getApplications = async () => {
        const { client } = this.props;

        const Applications = await client.getApplications();
        // console.log('getApplications', Applications);

        let FinalCost = 0;
        let AcceptedFkr2024_2025 = 0;
        let PayedFkr2024_2024 = 0;

        for (let i = 0; i < Applications.length; i++) {
            const { Id, Type, Result, Comment } = Applications[i];
            if ( Id >= 1833 && Type === 'fkr_accred' && Result === 'accept') {
                AcceptedFkr2024_2025++;
                if (Comment && Comment !== '0') {
                    FinalCost += Number(Comment);
                    PayedFkr2024_2024++;
                } else {

                }
            }
        }

        this.setState({ Applications, FinalCost, AcceptedFkr2024_2025, PayedFkr2024_2024 });
    }

    getLogs = async () => {
        const { client } = this.props;

        let Logs = await client.getLogger({ Events: 1 });
        if (!Logs || !Logs.length) return;
        Logs.reverse();
        Logs = Logs.slice(0, 20);
        // console.log('getApplications', Applications);
        this.setState({ Logs });

    }

    componentDidMount() {
        // this.getAllPlaces();
        this.getApplications();
        this.getLogs();
    }

    onAccept = async (applicationId) => {
        const { client } = this.props;

        const { Applications } = this.state;
        const thisApplication = Applications.find(x => x.Id === applicationId);
        if (!thisApplication) {
            message.error('Что-то не так')
            return;
        }

        const { UserId, Type, Values } = thisApplication;

        if (Type === 'fkr_accred') {

            if (Values === '1h') this.changeCost({ target: { value: 1000 } })
            if (Values === '2h') this.changeCost({ target: { value: 1500 } })

            this.changeShowAddCostModal(thisApplication);
            return;
        }

        await client.updateApplication({ applicationId, result: 'accept' });
        await client.processApplication({ applicationId });


        if (Type === 'sport' || Type === 'judge')
            client.addNotification({
                UserId,
                Body: `^a_^text^a_^Ваша заявка на разряд принята^!`
            })

        if (Type === 'fkr_accred')
            client.addNotification({
                UserId,
                Body: `^a_^text^a_^Заявка на членство ФКР принята^!`
            })

        this.getApplications();
    }

    onAcceptWithCost = async () => {
        const { client } = this.props;
        const { Cost } = this.state;
        const application = { ...this.state.showAddCostModal };
        this.changeShowAddCostModal(false);

        const applicationId = application.Id;

        await client.updateApplication({ applicationId, result: 'accept', comment: (Cost || 0) });
        await client.processApplication({ applicationId });

        const { UserId, Type } = application;

        if (Type === 'fkr_accred')
            client.addNotification({
                UserId,
                Body: `^a_^text^a_^Заявка на членство ФКР принята^!`
            })

        this.getApplications();
        message.success('Заявка одобрена');
    }

    onDecline = async (applicationId) => {
        const { client } = this.props;

        const Applications = await client.getApplications();
        const thisApplication = Applications.find(x => x.Id === applicationId);
        if (!thisApplication) return;

        const { UserId } = thisApplication;

        client.addNotification({
            UserId,
            Body: `^a_^text^a_^Ваша заявка на разряд отклонена^!`
        })

        await client.updateApplication({ applicationId, result: 'decline' });
        this.getApplications();
    }


    render() {

        const width = 1200;
        const { Applications, Logs, showEventAddModal, showUploadRaitingModal, lookedTab, showAddCostModal,
            FinalCost, AcceptedFkr2024_2025, PayedFkr2024_2024
        } = this.state;

        const { AddCostModal, onAccept, onDecline } = this;
        const { iamuser, isMobile } = this.props;

        if (!iamuser) {
            return <div style={{ width }} className='admin_page'>
                Такой страницы не существует
            </div>
        }

        const { myPortalRoles = {} } = iamuser;

        if (!myPortalRoles.creator && !myPortalRoles.admin) {
            return <div style={{ width }} className='admin_page'>
                Такой страницы не существует
            </div>
        }

        if (isMobile) {

            const { width } = isMobile;
            let components = [
                {
                    component: ApplicationsLittleListPlashka,
                    id: 1,
                    cell_x: 2,
                    collapse: 1,
                    componentProps: { _title: 'Заявки на разряды', height: 600, Applications, onAccept, onDecline, onlyNotAccepted: true }
                }
            ]

            return <div style={{ width }} className='admin_page'>
                <Modal
                    className='addCoseModalClass'
                    visible={showAddCostModal}
                    onCancel={() => { this.changeShowAddCostModal(false) }}
                    footer={null}
                    title={'Оплата заявки'}
                    width={1200}
                >
                    <AddCostModal />
                </Modal>
                <div className='admin_page_appl_stat'>
                    <span>{`Всего ФКР 2024-2025: ${AcceptedFkr2024_2025}`}</span>
                    <span>{`Оплаченных ФКР 2024-2025: ${PayedFkr2024_2024}`}</span>
                    <span>{`Оплата: ${FinalCost}`}</span>
                </div>
                <TrefaLayout greedsX={[2, width - 4, 2]}
                    components={components
                    }
                />
            </div>


        }

        let components = [
            {
                component: ApplicationsLittleListPlashka,
                id: 1,
                cell_x: 2,
                collapse: 1,
                componentProps: { _title: 'Заявки на разряды', height: 600, Applications, onAccept, onDecline, onlyNotAccepted: true }
            },
            {
                component: ButtonPlashka,
                id: 2,
                cell_x: 4,
                collapse: 1,
                componentProps: { height: 60, text: 'СОЗДАТЬ МЕРОПРИЯТИЕ', onClick: this.changeShowEventAddModal }
            }
        ]

        if (myPortalRoles.creator) {
            components.push(
                {
                    component: ButtonPlashka,
                    id: 2,
                    cell_x: 4,
                    collapse: 1,
                    componentProps: { yPadding: 'usual', height: 60, text: 'ОБНОВИТЬ РЕЙТИНГ', onClick: this.changeShowUploadRaitingModal }
                }
            )
        }

        return <div style={{ width }} className='admin_page'>
            <Tabs activeKey={lookedTab} onChange={(lookedTab) => this.setState({ lookedTab })}>
                <TabPane key='standart' tab='Заявки'>
                    <div className='admin_page_appl_stat'>
                        <span>{`Всего ФКР 2024-2025: ${AcceptedFkr2024_2025}`}</span>
                        <span>{`Оплаченных ФКР 2024-2025: ${PayedFkr2024_2024}`}</span>
                        <span>{`Оплата: ${FinalCost}`}</span>
                    </div>
                    <TrefaLayout greedsX={[50, 700, 25, 200, 50]}
                        components={components
                        }
                    />
                </TabPane>
                <TabPane key='logs' tab='Логи'>
                    <div className='admin_page_logs'>
                        {Logs.map((Log) => <LittleLogger Log={Log} />)}
                    </div>
                </TabPane>
            </Tabs>
            <Modal
                className='addCoseModalClass'
                visible={showAddCostModal}
                onCancel={() => { this.changeShowAddCostModal(false) }}
                footer={null}
                title={'Оплата заявки'}
                width={1200}
            >
                <AddCostModal />
            </Modal>
            <Modal
                className='adminModalClass'
                visible={showEventAddModal}
                onCancel={() => { this.changeShowEventAddModal(false) }}
                footer={null}
                title={'Редактировать данные'}
                width={1200}
            >
                <AddEventData />
            </Modal>
            <Modal
                className='adminModalClass'
                visible={showUploadRaitingModal}
                onCancel={() => { this.changeShowUploadRaitingModal(false) }}
                footer={null}
                title={'Обновить рейтинг'}
                width={1200}
            >
                <RaitingUpdate />
            </Modal>

        </div>
    }

    AddCostModal = () => {
        const { changeCost, onAcceptWithCost } = this;
        const { Applications, showAddCostModal, Cost } = this.state;

        const { Id } = showAddCostModal;

        const thisApplication = Applications.find(x => x.Id === Number(Id));
        if (!thisApplication) {
            return <div>Какая-то ошибка</div>
        }

        return <div className='admin_page_modal_cost'>
            <span>
                Введите сумму
            </span>
            <Input onChange={changeCost} value={Cost} />
            <Button onClick={onAcceptWithCost} type="primary">ОТПРАВИТЬ</Button>
        </div>
    }
}


export default withCapoeiraSportState('iamuser, client, isMobile')(AdminPage);