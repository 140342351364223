import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class OTTitlePlashka
// classname: ot_title_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class OTTitlePlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    /*
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    render() {

        const { upperStyle, title } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='ot_title_plashka'>
        //     <Loader />
        // </div>

        if (title[0].length > 10) 
        return <div style={{ width, height }} className='ot_title_plashka ot_title_plashka_short'>
            <span>
                {title[0]}
            </span>
        </div>

        return <div style={{ width, height }} className='ot_title_plashka'>
            <span>
                {title[0]}
            </span>
        </div>
    }
}

export default withCapoeiraSportState(`
client
`)(OTTitlePlashka)