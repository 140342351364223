import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import './style.css';

import { Select } from 'antd';
const { Option } = Select;


// class OTEducationPlashka
// classname: ot_education_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class OTEducationPlashka extends PureComponent {

    state = {
        tutorial_index: 0
    }

    render() {

        const { upperStyle } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='ot_education_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='ot_education_plashka'>
            <this.ObuchalkiInfo />
        </div>
    }

    ObuchalkiInfo = () => {

        const { Tutorials } = this.props;
        const { tutorial_index } = this.state;

        const title = <div className='element-tutorials-select-dev'>
                <Select
                    onChange={(value) => { this.setState({ tutorial_index: value }) }}
                    size={'small'}
                    value={tutorial_index}
                    key='element-tutorials-select'>

                    {Tutorials.map((tutorial, index) => {
                        return <Option value={index}> {tutorial.title} </Option>
                    })}

                </Select>
            </div>

        const content = [
            <div className='solo-element-block-content-row-div row-all-over row-no-margin' >
                <iframe
                    width="90%"
                    height="250px"
                    src={`https://www.youtube.com/embed/${Tutorials[tutorial_index].yt_tail}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"
                    >
                </iframe>
            </div>
        ]

        return <>
            {title}
            {content}
        </>
    }

}

export default withCapoeiraSportState(`
client
`)(OTEducationPlashka)