import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';
import Loader from 'src/ver2/components/loader';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';
import { LockIcon } from 'src/ver2/components/ui/icons';
import { Tooltip } from 'antd';

import { datesRange, monthRange } from 'src/ver2/utils';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventDiscriptionPlashka
// classname: event_discription_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventDiscriptionPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        
        const { Event, Championship, Competitors, Participators, Follows, upperStyle, client, eventStatus, isMobile } = this.props;
        const { width, height } = upperStyle;

        if (!Event) return <div style={{ width, height }} className='event_discription_plashka'>
            <Loader />
        </div>

        const { Date: Date_, City, Type, EndDate, IsPreliminarily, StartPreliminarilyDate, EndPreliminarilyDate, SettingsObj } = Event;

        const getEventType = () => {
            if (Type === 'roda' || Type === 'seminar' || Type === 'judge' || Type === 'challenge') return client.eventsTypes[Type];
            if (Type === 'championship') {
                if (Championship) {
                    if (Championship.Batizado) return client.eventsTypes['batizado'];
                    return client.eventsTypes['competition'];
                }
            }
            return null;
        }

        const eventType = getEventType();

        let statistics = null;
        if (Type === 'championship' && Championship) statistics = Competitors ? Competitors.length : 0 //Championship.CompetitorsCount;
        if (Type !== 'championship' && Participators) statistics = Participators.length;

        const eventStatus_ = eventStatus() || 'undef';
        if (eventStatus_ === 'is_preliminarily' || eventStatus_ === 'registration_not_open') {
            statistics = Follows ? Follows.length : 0;
        }
        const isDateValid = (someDate) => {
            if (!someDate || someDate === '' || someDate === 'null' || someDate === 'undefined' || someDate === 'Invalid date') return false;
            return true;
        }

        let DatesComponent = null;
        if (IsPreliminarily) {
            if (isDateValid(StartPreliminarilyDate)) {
                if (isDateValid(EndPreliminarilyDate)) {
                    DatesComponent = <this.MiniDiscription drawStyle={'orange'} discription={'предварительно'}
                        component={monthRange(StartPreliminarilyDate, EndPreliminarilyDate)} />
                } else {
                    DatesComponent = <this.MiniDiscription drawStyle={'orange'} discription={'предварительно'}
                        component={`${moment(StartPreliminarilyDate, 'MM.YYYY').locale('ru').format('MMM YYYY')}`} />
                }
            }
        } else {
            if (isDateValid(Date_)) {
                if (isDateValid(EndDate)) {
                    DatesComponent = <this.MiniDiscription discription={'даты'}
                        component={datesRange(Date_, EndDate)}
                    />
                } else {
                    DatesComponent = <this.MiniDiscription discription={'дата'}
                        component={`${moment(Date_, 'DD.MM.YYYY').locale('ru').format('(ddd) DD MMM YYYY')}`} />
                }
            }
        }

        // ОПРЕДЕЛЯЕМ ОРГАНИЗАТОРА
        let organizator = null;
        if (SettingsObj && SettingsObj.PermissionMain && SettingsObj.PermissionMain.FaceFrom === 'school' && SettingsObj.PermissionMain.FaceId) {
            if (SettingsObj.PermissionMain.IsClosed) {
                organizator = <div className='event_discription_plashka_row'>
                    <SchoolPlate width={isMobile ? 70 : 140} height={isMobile ? 15 : 35} SchoolId={SettingsObj.PermissionMain.FaceId} />
                    <Tooltip title='мероприятия видят только члены школы' placement='top'>
                        <span>
                            <LockIcon width={isMobile ? 10 : 20} height={ isMobile ? 10 : 20} />
                        </span>
                    </Tooltip>
                </div>

            } else
                organizator = <SchoolPlate width={isMobile ? 70 : 140} height={isMobile ? 15 : 35} SchoolId={SettingsObj.PermissionMain.FaceId} />
        }
        if (SettingsObj && SettingsObj.PermissionMain && SettingsObj.PermissionMain.FaceFrom === 'group' && SettingsObj.PermissionMain.FaceId) {
            if (SettingsObj.PermissionMain.IsClosed) {
                organizator = <div className='event_discription_plashka_row'>
                    <GroupPlate width={140} height={35} GroupId={SettingsObj.PermissionMain.FaceId} />
                    <Tooltip title='мероприятия видят только члены группы' placement='top'>
                        <span>
                            <LockIcon width={20} height={20} />
                        </span>
                    </Tooltip>
                </div>

            } else
                organizator = <GroupPlate width={140} height={35} GroupId={SettingsObj.PermissionMain.FaceId} />
        }

        return <div style={{ width, height }} className='event_discription_plashka'>
            <this.MiniDiscription discription={'организатор'} component={organizator} />
            <this.MiniDiscription discription={'тип'} component={eventType ? this.TypeDiv(eventType) : null} />
            {DatesComponent}
            <this.MiniDiscription discription={City && City === 'Online' ? 'проведение' : 'город'} component={City} />
            <this.MiniDiscription discription={eventStatus_ === 'is_preliminarily' || eventStatus_ === 'registration_not_open' ? 'планирует человек' : 'участники'} component={statistics} />
        </div>
    }

    TypeDiv = ({ name, imgUrl }) => {
        return <span className='event_discription_plashka_type'>
            <img src={imgUrl} />
            <span>{name}</span>
        </span>
    }

    MiniDiscription = ({ drawStyle, component, discription }) => {

        if (!component) return null;

        let className = 'event_discription_plashka_one';
        if (drawStyle === 'orange') className += ' event_discription_plashka_one_orange'

        return <div className={className}>
            <div className='event_discription_plashka_one_upper'>
                {discription}
            </div>
            <div className='event_discription_plashka_one_downed'>
                {component}
            </div>
        </div>
    }

}

export default withEventState('Event, Championship, Competitors, Participators, Follows, client, eventStatus, isMobile')(EventDiscriptionPlashka)