import React from 'react';
import ExerciseWrapper from 'src/ver2/components/exercise/exerciseWrapper';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';
import { MiniSequenceOnlyTechnics } from 'src/ver2/containers/challenges/components/mini-sequence';
import { Popover } from 'antd';
import './styles.css';

const ExerciseContainer = ({ children, height }) => <div style={{ height }} className='ex_scheme_container'>
    {children}
</div>

const ExercisePoopover = ({ isMobile, Exercise: { Id, Title } }) => {
    const classNamePoopover = isMobile ? 'element-card-poopover-mobile' : 'element-card-poopover';
    return <div className={classNamePoopover}>
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }} >
            <video
                playsInline="true" id="my-player" class="video-js" preload="none" muted="true" controls="" data-setup="{}"
                loop="true"
                width='100%'
                height='100%'
                autoplay='true'
            >
                <source
                    src={`https://capoeirasport.com/graphics/video_technics/exercises/${Id}.mp4`}
                    type="video/mp4"
                />
            </video>
            <div>
                {Title}
            </div>
        </div>

    </div>
}


const ExerciseScheme = ({ Exercise, isExerciseLoaded, height = 50, isMobile, client }) => {

    if (!isExerciseLoaded) return <ExerciseContainer height={height}>
        <Loader text='упражнение' />
    </ExerciseContainer>

    if (!Exercise) return <ExerciseContainer height={height}>
        <span>Ошибка</span>
    </ExerciseContainer>

    const { Scheme } = Exercise;
    return <Popover placement={"top"} content={ExercisePoopover({ isMobile, Exercise, client })}>
        <div className='ex_scheme_container_wrapper'>
            <ExerciseContainer height={height}>
                <MiniSequenceOnlyTechnics height={height} elements={Scheme} noPoopup={true} />
            </ExerciseContainer >
        </div>
    </Popover>

}

const componentWrapped = (props) => <ExerciseWrapper Wrapped={ExerciseScheme} client={props.client} exerciseId={props.exerciseId} componentProps={{ ...props }} />
export default withCapoeiraSportState('client, isMobile, showInfoModal, iamuser')(componentWrapped);