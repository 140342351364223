import React, { PureComponent } from 'react';

import LittlePerson from 'src/ver2/components/person/little';
import { withEventState } from 'src/ver2/pages/event/context';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';

import Loader from 'src/ver2/components/loader';
import { Modal, Button, message } from 'antd';

import { sortBy, clone } from 'lodash';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventParticipatorsAdminPlashka
// classname: event_participators_admin_plashka

/**
  Это плашка, в которой у нас отображаются партисипаторы / компетиторы
  и где администратор может отмечать их оплаты, явки и всё прочее
 */

class EventParticipatorsAdminPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            clickedData: null,
            filter: 'f_NUMBER',
            filterAdmission: ''
        }
    }

    changeShowModal = (showModal = true) => this.setState({ showModal });
    setFilter = (filter) => this.setState({ filter });
    setFilterAdmission = (filterAdmission) => {
        if (filterAdmission === this.state.filterAdmission) {
            return this.setState({ filterAdmission: '' })
        }
        this.setState({ filterAdmission })
    }

    render() {

        const { Event, isParticipatorsReady, upperStyle, Participators, Competitors, Championship, isTherePayment, ParticipatorsData, iAmAdmin } = this.props;
        const { width, height } = upperStyle;

        const { showModal, filter, filterAdmission } = this.state;

        if (!iAmAdmin) return <div>Вы не администратор</div>

        if (!Event || !isParticipatorsReady) return <div style={{ width, height }} className='event_participators_admin_plashka'>
            <Loader />
        </div>

        console.log('EventParticipatorsAdminPlashka', this.props);

        let Admissions = []; // { key, title }
        if (isTherePayment()) {
            Admissions.push({ key: 'payment', title: 'Оплата' })
        }

        const { SettingsObj } = Event;
        if (SettingsObj && SettingsObj.AdmissionMain && SettingsObj.AdmissionMain.AdmissionData) {
            // добавляем остальных штук
            for (let i = 0; i < SettingsObj.AdmissionMain.AdmissionData.length; i++) {
                Admissions.push(SettingsObj.AdmissionMain.AdmissionData[i]); //{ key, title, text }
            }
        }

        let Partis = (Event.Type === 'championship' && Championship) ? Competitors : Participators;

        if (filter === 'f_FIO') {
            Partis = sortBy(Partis, ['Surname']);
        }

        let ParticipatorsDataOuters = clone(ParticipatorsData);

        if (filterAdmission && filterAdmission !== '' && Admissions && Admissions.find(x => x.key === filterAdmission)) {
            //let PartisAll = [...Partis]
            let PartisHave = [];
            let PartisDont = [];
            for (let i = 0; i < Partis.length; i++) {
                if (ParticipatorsData && ParticipatorsData[Partis[i].UserId] && ParticipatorsData[Partis[i].UserId].find(x => x.Type === filterAdmission)) {
                    PartisHave.push(Partis[i]);
                } else {
                    PartisDont.push(Partis[i]);
                }

                // оставляем только тех, кого нет среди партиципаторов
                if (ParticipatorsData && ParticipatorsData[Partis[i].UserId]) {
                    delete ParticipatorsDataOuters[Partis[i].UserId];
                }

            }
            Partis = [...PartisHave, ...PartisDont];
        } else {
            ParticipatorsDataOuters = null;
        }

        // 

        // тут у нас будет идти фильтрация

        return <div style={{ width, height }} className='event_participators_admin_plashka'>
            <this.HeaderBlock Admissions={Admissions} />
            <this.HeaderInfoBlock Admissions={Admissions} Participators={Partis} />
            {isTherePayment() ? <this.HeaderInfoBlock Admissions={Admissions} Participators={Partis} ValueOnly={true} /> : null}
            {Partis.map((Participator) => {
                return <this.OneParticipatorRow key={Participator.Id} Participator={Participator} Admissions={Admissions} />
            })}
            {
                (ParticipatorsDataOuters && Object.keys(ParticipatorsDataOuters) && Object.keys(ParticipatorsDataOuters).length > 0) ?
                    Object.keys(ParticipatorsDataOuters).map((UserId) => {
                        return <this.OneParticipatorRow key={UserId} Participator={{ UserId, Settings: '{}' }} Admissions={Admissions} isOuter={true} />
                    })
                    :
                    null
            }
            <Modal
                // className='adminModalClass'
                visible={showModal}
                onCancel={() => { this.changeShowModal(false) }}
                footer={null}
                title={'Отметка участника'}
                width={900}
            >
                {showModal ? <this.ModalBlock /> : null}
            </Modal>
        </div>
    }

    ModalBlock = () => {
        const { clickedData: { Participator, admissionKey, admissionTitle, finded } } = this.state;
        const { isMobile, deleteParticipatorData } = this.props;

        const onDeleteButtonClick = () => {
            Modal.confirm({
                title: 'Уверены?',
                content: 'Восстановить данные будет невозможно, только создать заново',
                okText: 'Удалить',
                cancelText: 'Не удалять',
                onOk: () => {
                    // удаляем оплату
                    deleteParticipatorData(finded.UserId, finded.Id);
                    message.success(`Запись ${finded.Id} удалена`);
                    this.changeShowModal(false);
                }
            })
        }

        if (finded) return <div className='event_registration_modal_finded'>
            <div>
                {`${admissionTitle}`}
            </div>
            <div>
                {`Участники: ${Participator.Surname} ${Participator.Name}`}
            </div>
            <div>
                {`Отметка: ${moment(finded.CreateDate, 'YYYY-MM-DDThh:mm:ss').format('hh:mm:ss DD.MM.YYYY')}`}
            </div>
            <div>
                {`Автор ID: ${finded.AuthorId}`}
            </div>
            {admissionKey === 'payment' ?
                <div>
                    {`Оплата: ${finded.Value}`}
                </div>
                : null
            }
            {(finded.Comment && finded.Comment !== '' && finded.Comment !== ' ') ?
                <div>
                    {`Комментарий: ${finded.Comment}`}
                </div>
                : null
            }
            <Button type="danger" onClick={onDeleteButtonClick}>
                УДАЛИТЬ
            </Button>
        </div>

        const processParticiparotDate = async ({ Value, Comment }) => {

            const { Event, client, addParticipatorsData } = this.props;
            const EventId = Event.Id;
            const { UserId } = Participator;

            let resultData = {
                EventId,
                UserId,
                Type: admissionKey,
                Value: Value || '1'
            }

            if (Comment && Comment !== '') {
                resultData.Comment = Comment;
            }

            // client.addParticipatorData(resultData);
            addParticipatorsData(resultData);

            this.changeShowModal(false);

        }

        if (admissionKey === 'payment') {
            const Data = {
                Comment: null,
                Value: 0
            }

            const ParticipatorsDataObj = JSON.parse(Participator.Settings);

            return <div>
                <DataChanger
                    header={<div>
                        {`Отметить оплату у участника: ${Participator.Surname} ${Participator.Name}`}
                    </div>}
                    footer={<this.PaymentComponent ParticipatorsDataObj={ParticipatorsDataObj} />}
                    data={Data}
                    buttonText={'ОТМЕТИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={processParticiparotDate}
                    components={[
                        {
                            name: 'Value',
                            title: 'Оплата',
                            type: 'input',
                            style: {
                                width: 200
                            },
                            text: 'Сколько участник внёс',
                            neededText: ' '
                        },
                        {
                            name: 'Comment',
                            title: 'Комментарий',
                            type: 'input',
                            style: {
                                width: isMobile ? 200 : 600
                            },
                            text: 'Вы можете добавить какой-либо комментарий'
                        },
                    ]}
                ></DataChanger>
            </div>
        }

        const Data = {
            Comment: null,
            Value: 1
        }

        return <div>
            <DataChanger
                header={<div>
                    {`Отметить ${admissionTitle} у участника: ${Participator.Surname} ${Participator.Name}`}
                </div>}
                data={Data}
                buttonText={'ОТМЕТИТЬ'}
                buttonState={'all'}
                onButtonClick={processParticiparotDate}
                components={[
                    {
                        name: 'Comment',
                        title: 'Комментарий',
                        type: 'input',
                        style: {
                            width: 600
                        },
                        text: 'Вы можете добавить какой-либо комментарий'
                    },
                ]}
            ></DataChanger>
        </div>
    }

    PaymentComponent = ({ ParticipatorsDataObj }) => {
        const { howManyAwaitForParticipatorToday } = this.props;

        const howMany = howManyAwaitForParticipatorToday(ParticipatorsDataObj);

        if (!howMany) return null;

        const { allPayment, allSale, paymentRows, saleRows } = howMany;

        return <div className='event_registration_modal_conteiner_payment_main'>

            <div className='event_registration_modal_conteiner_payment_title'>
                {`ОПЛАТА НА ТЕКУЩИЙ МОМЕНТ: ${allPayment - allSale} руб.`}
            </div>
            <div className='event_registration_modal_conteiner_payments'>
                {paymentRows.map(({ name, title, paymentNow, isAccepted, paymentContent }) => {

                    if (!isAccepted) {
                        return <div key={'dfdfgdfg' + name}>
                            <span>{title}</span> уже нельзя оплатить
                        </div>
                    }

                    if (paymentNow === 0) {
                        return null;
                    }

                    return <div key={'dfdfgdfg' + name}>
                        <span> {paymentContent} </span>
                    </div>
                })}
            </div>
            {saleRows.length > 0 ? <div className='event_registration_modal_conteiner_payment_title'>
                СКИДКА:
            </div> : null}
            {saleRows.length > 0 ? <div className='event_registration_modal_conteiner_sales'>
                {saleRows.map(({ saleContent, value }, index) => {
                    return <div key={'ermcss' + index} className='event_registration_modal_conteiner_sale'>
                        <div key={'ermcsss' + index} className='event_registration_modal_conteiner_sale_block'>
                            {saleContent}
                        </div>
                        <div className='event_registration_modal_conteiner_sale_value'>
                            {` ${value} руб.`}
                        </div>
                    </div>
                })}
            </div> : null}
        </div>
    }

    HeaderBlock = ({ Admissions }) => {
        const { filter, filterAdmission } = this.state;
        return <div className='event_participators_admin_plashka_participator'>
            <div className='event_participators_admin_plashka_participator_left'>
                <span onClick={() => { this.setFilter('f_FIO') }} className={filter === 'f_FIO' ? 'event_participators_admin_plashka_participator_admission_selected' : ''}>ФИО</span>
                <span onClick={() => { this.setFilter('f_NUMBER') }} className={filter === 'f_NUMBER' ? 'event_participators_admin_plashka_participator_admission_selected' : ''}>Порядок</span>
            </div>
            <div className='event_participators_admin_plashka_participator_separator' />
            {Admissions.map(({ key, title, text }) => {
                return <div
                    key={'epappa' + key}
                    onClick={() => { this.setFilterAdmission(key) }}
                    className={'event_participators_admin_plashka_participator_admission ' + (filterAdmission === key ? 'event_participators_admin_plashka_participator_admission_selected' : '')}>
                    {title}
                </div>
            })}
        </div>
    }

    HeaderInfoBlock = ({ Admissions, Participators, ValueOnly = false }) => {

        const { ParticipatorsData } = this.props;

        if (!ParticipatorsData || !Participators || !Participators.length) return null;

        let AdmsissionWithKeys = {};
        for (let i = 0; i < Admissions.length; i++) {
            AdmsissionWithKeys[Admissions[i].key] = {
                value: 0,
                count: 0
            };
        }

        for (let i = 0; i < Object.keys(ParticipatorsData).length; i++) {
            const key = Object.keys(ParticipatorsData)[i];
            const PData = ParticipatorsData[key];
            for (let j = 0; j < Admissions.length; j++) {
                const admissionKey = Admissions[j].key;

                const PDataAdmission = PData.find(x => x.Type === admissionKey);

                if (PDataAdmission && PDataAdmission.Value) {
                    if (admissionKey === 'payment') {
                        AdmsissionWithKeys[admissionKey].count++;
                        AdmsissionWithKeys[admissionKey].value += Number(PDataAdmission.Value);
                    } else {
                        AdmsissionWithKeys[admissionKey].count++;
                    }
                }
            }
        }

        return <div className='event_participators_admin_plashka_participator'>
            <div className='event_participators_admin_plashka_participator_left'>

            </div>
            <div className='event_participators_admin_plashka_participator_separator' />
            {Object.keys(AdmsissionWithKeys).map((key) => {

                const { value, count } = AdmsissionWithKeys[key];

                return <div
                    key={'epappap' + key}
                    className={'event_participators_admin_plashka_participator_admission '}>
                    {ValueOnly ? value ? `∑ = ${value}` : '' : `${count} / ${Participators.length}`}
                </div>
            })}
        </div>
    }

    deleteCompetitor = (Participator, Name) => {
        // надо проверить количество участников

        const onOk = () => {
            // const { client, reloadCategorys } = this.props;
            // client.deleteCategory(category.CompetitionId, category.Id).then(() => reloadCategorys());
            // this.props.deleteCompetitor(Id);
            if (Participator.CompetitorId)
                this.props.deleteCompetitor(Participator.CompetitorId);
        }

        Modal.info({
            className: 'event_participation_decline_modal',
            title: 'Удалить участника ' + Name,
            content: 'Удалить участника из соревнований, вы уверены?',
            onOk: onOk,
            onCancel: () => { },
            okText: 'Удалить',
            cancelText: 'Отмена',
            okCancel: true,
            closable: true,
            centered: true
        })
    }

    OneParticipatorRow = ({ Participator, Admissions, isOuter }) => {

        const { isMobile, Event } = this.props;

        const DirectPerson = {
            ...Participator,
            Id: Participator.UserId
        }
        const height = 50;

        const { getParticipatorsSelectName, ParticipatorsData } = this.props;

        const Selections = isOuter ? null : getParticipatorsSelectName(Participator);

        const className = isOuter ? 'event_participators_admin_plashka_participator event_participators_admin_plashka_participator_outer' : 'event_participators_admin_plashka_participator';

        return <div className={className} style={{ height }}>
            <div className='event_participators_admin_plashka_participator_left'>
                {isOuter
                    ?
                    <LittlePerson mode='participators_admin' personId={Participator.UserId} height={height} />
                    :
                    <LittlePerson personsDirect={true} mode='participators_admin' DirectPerson={DirectPerson} height={height} />}
            </div>
            <div className='event_participators_admin_plashka_participator_separator' />
            {Admissions.map(({ key, title, text }) => {

                let onePRClassName = 'event_participators_admin_plashka_participator_admission_person';
                let Comment = null;
                let Value = null;
                let inside = <span>
                    {title}
                </span>

                let finded = null;

                if (ParticipatorsData && ParticipatorsData[Participator.UserId]) {
                    finded = ParticipatorsData[Participator.UserId].find(x => x.Type === key);
                    if (finded) {
                        onePRClassName += ' event_participators_admin_plashka_participator_admission_person_check';
                        onePRClassName += ` event_participators_admin_plashka_participator_admission_person_check_${finded.AuthorId}`;
                        Comment = finded.Comment;
                        Value = finded.Value;
                        if (key === 'payment') {
                            inside = <span>
                                {Value}
                                <br />
                                {title}
                            </span>
                        }
                    }
                }

                return <div key={'epappappp' + key} className={onePRClassName}>
                    <span onClick={() => { this.setState({ clickedData: { Participator, admissionKey: key, admissionTitle: title, finded }, showModal: true }) }}>
                        {inside}
                    </span>
                </div>
            })}

            {(Selections && Selections.length) ?
                Selections.map((item) => {
                    return <div className='event_participators_admin_plashka_participator_category_person'>
                        {item.chooseName}
                    </div>
                }) : null
            }

            {(!isMobile && Event.ChampionshipId && !isOuter) ? <div onClick={() => { this.deleteCompetitor(Participator, DirectPerson.Surname + ' ' + DirectPerson.Name) }} className='event_participators_admin_plashka_participator_delete'>
                удалить
            </div> : null}
        </div>
    }

}

export default withEventState(`
        
        Event,
        Championship,
        
        Participators,
        isParticipatorsReady,

        Competitors,
        isCompetitorsReady,
        deleteCompetitor,
        
        ParticipatorsData,
        isParticipatorsDataReady,
        getParticipatorsSelectName,

        addParticipatorsData,
        deleteParticipatorData,
        
        isTherePayment,
        howManyAwaitForParticipatorToday,
        
        iAmAdmin,
        client,
        iamuser,
        isMobile
`)(EventParticipatorsAdminPlashka)