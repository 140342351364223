import React, { Fragment, PureComponent } from 'react';
import { withPersonState } from 'src/ver2/pages/person/context';
import { Statistic, Progress } from 'antd';
import Loader from 'src/ver2/components/loader';
import { Modal } from 'antd';

import { orderBy } from 'lodash';

import './style.css';

class PersonAchivmentsPlashka extends PureComponent {

    state = {
    }

    render() {

        const { isMobile, isAchivmentsReady, Achivments, innerId } = this.props;
        const { width, height } = this.props.upperStyle;

        if (!isAchivmentsReady) {
            return <div style={{ width, height }} className='soforca_plashka'>
                <div className='combo_scheme_plashka_inner' id={innerId}>
                    <div className='person_achivments_plashka'>
                        <Loader text='Загрузка достижеий' />
                    </div>
                </div>
            </div>
        }

        if (!Achivments || !Achivments.length) {
            return <div style={{ width, height }} id={innerId} className='person_achivments_plashka'>
                <span>ПОКА НЕТ ДОСТИЖЕНИЙ</span>
            </div>
        }

        console.log('Achivments', Achivments);

        return <div style={{ width, height }} className='soforca_plashka'>
            <div className='combo_scheme_plashka_inner' id={innerId}>
                <div className='person_achivments_plashka'>
                    {Achivments.map(({ Name, Lvl, Progress }) =>
                        <div className='person_achivments_plashka_achiv' key={`ach_${Name}`}>
                            <img src={`/../images/ver2/achivs/${Name}/${Lvl}.png`} />
                            <div className='person_achivments_plashka_achiv_bar'>
                                <div style={{ width: `${Progress}%` }} className='person_achivments_plashka_achiv_bar_inner' />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

        // return <div className='person_achivments_plashka' id={innerId}>
        //     <div>
        //         ДОСТИЖЕНИЯ!
        //     </div>
        // </div>
    }
}

export default withPersonState(
    `
    Achivments,
    isAchivmentsReady,
    personId,
    client,
    isMobile
    `
)(PersonAchivmentsPlashka)