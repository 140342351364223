import React, { Component } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';
import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';
import TrefaText from 'src/ver2/components/trefaText';

import { Collapse } from 'antd';

import Loader from 'src/ver2/components/loader';
import { sortBy, orderBy } from 'lodash';
// import { Link } from 'react-router-dom';

import './style.css';

const { Panel } = Collapse;
// class EventResultsPlashka
// classname: event_results_plashka

/**
  Это большая плашка с результатами
  У неё два режима - это показывать 
 */


const PLACES = {
    1: '1',
    2: '2',
    3: '3',
    4: '5-8',
    5: '9-16',
    6: '17-32',
    7: '33-64'
}


class EventResultsPlashka extends Component {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false,
            filter: {
                schoolResult: false,
                groupResult: false
            }
        }
    }

    setHight = () => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const element = document.getElementById('event_results_plashka_inside_id');
        if (!element) return;
        if (this.state.heightSetted === element.clientHeight) return;
        this.setState({ heightSetted: element.clientHeight });
        setComponentSelfHeight({ height: element.clientHeight + 400, plashkaId });
    }

    componentDidMount() {
        this.setHight();
    }

    componentDidUpdate() {
        this.setHight();
    }

    render() {

        const { isResultsReady, upperStyle } = this.props;
        const { width, height } = upperStyle;

        if (!isResultsReady) return <div style={{ width, height }} className='event_results_plashka'>
            <Loader />
        </div>

        return <div style={{ width, height }} className='event_results_plashka'>
            <this.ResultsMany />
            <this.FiltersRender />
            <div id='event_results_plashka_inside_id' className='event_results_plashka_inside'>
                <this.СaegorysRender />
            </div>
        </div>
    }

    ResultsMany = () => {

        const { iamuser } = this.props;
        if (!iamuser) return null;

        return <Collapse accordion>
            <Panel header = 'РЕЗУЛЬТАТ ГРУППЫ'>
                {this.MyGroupResultsText({ isGroup: 1 })}
            </Panel>
            <Panel header = 'РЕЗУЛЬТАТ ШКОЛЫ'>
                {this.MyGroupResultsText({ isSchool: 1 })}
            </Panel>
        </Collapse>
    }
    MyGroupResultsText = ( {isGroup = 0, isSchool = 0} ) => {


        const { Categorys, isResultsReady, Event, iamuser } = this.props;
        const { Id: EventId } = Event;

        let Text = `^a_^text^a_^Состоялись соревнования ^e_${EventId}^ ^n_^`

        if (!iamuser) return null;
        const schoolResult = isSchool;
        const groupResult = isGroup;

        if (schoolResult) {
            Text += `От нашей школы ^s_${iamuser.SchoolId}^ выступали: ^n_^`
        }
        if (groupResult) {
            Text += `От нашей группы выступали: ^n_^`
        }

        if (!isResultsReady || !Categorys || Categorys.length === 0) return <Loader />

        let Answerr = ``;

        const Categorys_ = orderBy(Categorys, ['AgeFrom', 'IsPro', 'Gender']);

        const { Results: { allCompetitorsByCategorys } } = this.props;


        for (let i = 0; i < Categorys_.length; i++) {
            const { Id: categoryId, Name } = Categorys_[i];
            const competitors = allCompetitorsByCategorys[categoryId];
            if (!competitors) continue;

            const competitors_ = sortBy(competitors, x => { return x.Place });

            let Answer = [];

            if (schoolResult) {
                for (let i = 0; i < competitors_.length; i++) {
                    if (competitors_[i].SchoolId === iamuser.SchoolId) {
                        Answer.push(competitors_[i]);
                    }
                }
            } else
                if (groupResult) {
                    for (let i = 0; i < competitors_.length; i++) {
                        if (competitors_[i].GroupIamInId === iamuser.GroupIamInId) {
                            Answer.push(competitors_[i]);
                        }
                    }
                }

            if (!Answer.length) continue;

            console.log(Answer);

            Text += `^n_^ ^b_${Name}^: ^n_^`
            for (let j = 0; j < Answer.length; j++) {
                const { Name: SPName, Place, Surname } = Answer[j];
                Text += `^b_${Surname} ${SPName}^ - ${PLACES[Place]} место ^n_^`
            }
            Text += `^n_^`;
        }
        return <TrefaText trefaText={Text} />
    }

    FiltersRender = () => {

        const { iamuser, isResultsReady, Results } = this.props;
        const { filter } = this.state;

        let components = [];
        if (!isResultsReady || !iamuser || !iamuser.SchoolId) return null;

        const { schoolsPlaces, groupPlaces } = Results;

        if (schoolsPlaces && schoolsPlaces.find(x => x.SchoolId === iamuser.SchoolId)) {
            components.push(
                {
                    name: 'schoolResult',
                    title: '',
                    type: 'checkbox',
                    text: 'результаты школы',
                    style: { width: 150 }
                }
            )
        }
        if (groupPlaces && groupPlaces.find(x => x.GroupId === iamuser.GroupIamInId)) {
            components.push(
                {
                    name: 'groupResult',
                    title: '',
                    type: 'checkbox',
                    text: 'результаты группы'
                }
            )
        }

        if (!components.length) return null;

        const onChangeData = (filter) => {
            if (!this.state.filter.schoolResult && filter.schoolResult) {
                return this.setState({
                    filter: {
                        schoolResult: true,
                        groupPlaces: false
                    }
                })
            }
            if (!this.state.filter.groupPlaces && filter.groupPlaces) {
                return this.setState({
                    filter: {
                        schoolResult: false,
                        groupPlaces: true
                    }
                })
            }
            this.setState({ filter })
        }

        return <DataChanger
            header={<div>
                Вы можете смотреть результаты школы или группы
            </div>}
            data={filter}
            noButton={true}
            changeData={onChangeData}
            components={components}
        />
    }

    СaegorysRender = () => {

        const { Categorys, isResultsReady } = this.props;

        if (!isResultsReady || !Categorys || Categorys.length === 0) return <Loader />

        let Answer = [];

        const Categorys_ = orderBy(Categorys, ['AgeFrom', 'IsPro', 'Gender']);

        for (let i = 0; i < Categorys_.length; i++) {
            Answer.push(
                <this.CategoryMiniCompetitorsPedestal key={'cr-' + Categorys_[i].Name} Name={Categorys_[i].Name} categoryId={Categorys_[i].Id} />
            )
        }
        return Answer;
    }

    CategoryMiniCompetitorsPedestal = ({ categoryId, Name }) => {
        const { Results: { allCompetitorsByCategorys }, iamuser, isMobile } = this.props;
        const { filter } = this.state;
        const competitors = allCompetitorsByCategorys[categoryId];
        if (!competitors) return null;


        const competitors_ = sortBy(competitors, x => { return x.Place });

        let Answer = [];

        if (filter.schoolResult) {
            for (let i = 0; i < competitors_.length; i++) {
                if (competitors_[i].SchoolId === iamuser.SchoolId) {
                    Answer.push(competitors_[i]);
                }
            }
        } else
            if (filter.groupResult) {
                for (let i = 0; i < competitors_.length; i++) {
                    if (competitors_[i].GroupIamInId === iamuser.GroupIamInId) {
                        Answer.push(competitors_[i]);
                    }
                }
            } else
                for (let i = 0; i < competitors_.length; i++) {
                    if (competitors_[i].Place > 0 && competitors_[i].Place < 4) {
                        Answer.push(competitors_[i]);
                    }
                }

        if (!Answer.length) return null;

        return <div className='event_results_plashka_mini_cat_container'>
            <p>{Name}</p>
            <div className='event_results_plashka_mini_cat_competitors'>
                <TrefaLayout greedsX={[isMobile ? isMobile.width - 10 : 500]}
                    components={[
                        {
                            component: PersonsLittleListPlashka,
                            cell_x: 1,
                            collapse: 1,
                            id: 200,
                            componentProps: {
                                itemHeight: 45,
                                height: 300,
                                Persons: Answer,
                                autoSize: true,
                                autoSizeMax: 7,
                                personsDirect: true,
                                mode: 'results'
                            }
                        }
                    ]}
                />
            </div>
        </div>

    }

}

export default withEventState(`
            Event,
            Categorys,
            Results,
            isResultsReady,

            isMobile,

            client,
            iamuser
            `)(EventResultsPlashka)