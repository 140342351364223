import { createContext } from 'react';
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { groupBy } from 'lodash';
import moment from 'moment';
import { message } from 'antd';

const context = createContext(
    {
        defVal: 'FUUUCK YEAH'
    }
);

const initialState = {
    pageNow: 'subgroups',
    status: 'loading',
    students: null,
    studentsBySubgroups: {},
    groupId: null, // ID группы 9 - Маркин
    groupsChoose: undefined,
    subgroups: [
        /*      {
                  id: 1,
                  title: 'Группа 12-15 лет',
                  adres: 'ул.Халтуринская, 14к2',
                  color: '#FFFF00',
                  payment_type: 'by_month',
                  discipline: true
              },
              {
                  id: 2,
                  title: 'Группа 18+ лет',
                  adres: 'ул.Маршала бирюзова, 2',
                  color: '#00FF00',
                  payment_type: 'by_month',
                  discipline: false
              }*/
    ],
    visits: {
        /*<month_year>: {
            <subgroupid> : {
                <day> : [
                    {
                        UserId
                        Disciplene
                    }
                ]
            }
        }
        */
    },
    studentVisits: {
        /* month_year>: {
                <subgroupid> : {
                    <userId>: ...
                }
            }
        */
    },
    payments: {
        /*<month_year>: {
            <subgroupid> : {
                <userId> : 
                    {
                        day: {
                            <day> : {
                                ...payment
                            }
                        },
                        month: {
                                ...payment
                        }
                    }
                
            }
        }
        */
    },
    sbcomments: {
        /* 
            <subgroupId>: {
                <month_year>: {
                    <day>: {...sbcomment}
                }
            }
        */
    },
    requests: [],
    isNullRequests: false,
    selectedMonth: moment().month() + 1,
    selectedYear: moment().year(),
    trainingDays: []
}

class CRMState extends PureComponent {

    constructor(props) {
        super(props);

        this.state = initialState;
    }

    upperInitialLoad = () => {
        const { iamuser } = this.props;
        const { groupId } = this.state;
        if (iamuser && !groupId) {
            // this.initialLoad();
            let groupsChoose = [];
            if (iamuser.GroupId) {
                groupsChoose.push(iamuser.GroupId)
            }
            if (iamuser.myGroupRole) {
                for (let i = 0; i < iamuser.myGroupRole.length; i++) {
                    const role = iamuser.myGroupRole[i];
                    if (role.Role === 'instructor') {
                        groupsChoose.push(role.SubjectId)
                    }
                }
            }

            if (groupsChoose.length === 1) {
                this.setState({ groupId: groupsChoose[0] })
                this.initialLoad(groupsChoose[0]);
                return;
            }

            this.setState({ groupsChoose })

            // let groupsChoose = 

            return;
        }

        // if (iamuser &&)

    }

    setGroupId = (groupId) => {
        this.setState({ groupId })
        this.initialLoad(groupId);
    }

    initialLoad = async (groupId_) => {
        const { client } = this.props;
        const groupId = groupId_ || this.state.groupId;
        // сначала подгружаем субгруппы
        // потом будем брать из другого места :)
        const { selectedMonth, selectedYear } = this.state;
        if (!groupId) return;

        const subgroups = await client.getSubgroups(groupId);
        let students = await client.getStudentsFromServer(groupId) || [];
        const foreigners = await client.getSubgroupsForeigns(groupId) || [];

        students = [...students, ...foreigners];

        let shadule = [];
        const requests = await client.getCRMRequests({ GroupId: groupId}) || [];
        const isNullRequests = requests.find ( x => x.Status === 0);

        let studentsBySubgroups = {
            0: []
        };

        let studentsInAnyGroups = {};

        let visits = {};
        visits[`${selectedMonth}_${selectedYear}`] = {};

        for (let i = 0; i < subgroups.length; i++) {
            studentsBySubgroups[subgroups[i].id] = [];
            const studentsBySubgroupsIds = await client.getSubgroupUsers(subgroups[i].id);

            // получаем учеников в данной подгруппе
            for (let j = 0; j < studentsBySubgroupsIds.length; j++) {
                const { UserId } = studentsBySubgroupsIds[j];

                // вот тут в теории может и не быть нужного студента
                const student = students.find(x => x.Id === UserId);
                if (!student) {
                    message.error(`Пользователь ${UserId} удалён из подгруппы`)
                   await client.deleteSubgroupUser(UserId, subgroups[i].id);
                } else {
                    // отмечаем, что он есть хоть в какой то группе
                    studentsInAnyGroups[UserId] = true;
                    studentsBySubgroups[subgroups[i].id].push({
                        ...student
                    })
                }
            }

            const sb_visits = await client.getSubgroupVisits(subgroups[i].id, selectedYear, selectedMonth) || [];
            this.setSubgroupStudentsVisits(subgroups[i].id, sb_visits, `${selectedMonth}_${selectedYear}`);
            const sbcomments = await client.getSubgroupComments(subgroups[i].id) || [];
            this.setSubgroupComments(subgroups[i].id, sbcomments);
            const sb_visits_grouper = groupBy(sb_visits, 'Day');
            visits[`${selectedMonth}_${selectedYear}`][subgroups[i].id] = { ...sb_visits_grouper };

            const sb_shadule = await client.getShadule(subgroups[i].id);
            shadule.push(...sb_shadule);

            // добавляем оплаты в state
            await this.getSubgroupPayments(subgroups[i].id);

        }



        for (let i = 0; i < students.length; i++) {
            const { Id } = students[i];
            if (!studentsInAnyGroups[Id]) {
                studentsBySubgroups[0].push(
                    {
                        ...students[i]
                    }
                )
            }
        }

        // обрабатываем visits
        // const visits = await client.getSubgroupVisits ());

        this.setState({ visits, subgroups, shadule, students, studentsBySubgroups, requests, isNullRequests, status: 'loaded' });
        this.setSubgroupsShadule();

    }

    renewStudents = async () => {
        const { client } = this.props;
        const { subgroups, groupId } = this.state;
        let students = await client.getStudentsFromServer(groupId) || [];
        const foreigners = await client.getSubgroupsForeigns(groupId) || [];

        console.log('renewStudents', { students });

        students = [...students, ...foreigners];

        let studentsBySubgroups = {
            0: []
        };
        let studentsInAnyGroups = {};

        for (let i = 0; i < subgroups.length; i++) {
            studentsBySubgroups[subgroups[i].id] = [];
            const studentsBySubgroupsIds = await client.getSubgroupUsers(subgroups[i].id);

            // получаем учеников в данной подгруппе
            for (let j = 0; j < studentsBySubgroupsIds.length; j++) {
                const { UserId } = studentsBySubgroupsIds[j];

                // вот тут в теории может и не быть нужного студента
                const student = students.find(x => x.Id === UserId);

                // отмечаем, что он есть хоть в какой то группе
                studentsInAnyGroups[UserId] = true;
                studentsBySubgroups[subgroups[i].id].push({
                    ...student
                })
            }
        }

        for (let i = 0; i < students.length; i++) {
            const { Id } = students[i];
            if (!studentsInAnyGroups[Id]) {
                studentsBySubgroups[0].push(
                    {
                        ...students[i]
                    }
                )
            }
        }


        this.setState({ students, studentsBySubgroups });
    }

    componentDidMount() {
        this.upperInitialLoad();
    }

    componentDidUpdate({ iamuser, match }, { isShopReady, isShopItemsReady }) {
        if (this.props.iamuser !== iamuser) {
            this.upperInitialLoad();
        }
    }

    // ОПЛАТА
    getSubgroupPayments = async (subgroupId, payments_ = null) => {
        // смотрим, есть ли уже оплаты по данному месяцу, если есть - ничего не делаем
        const { client } = this.props;
        let payments = payments_ ? { ...payments_ } : { ...this.state.payments };

        const paymentSubgroup = await client.getPayments({ PaymentKey: 'subgroup_payment', ObjectId: subgroupId, ObjectType: 'subgroup' }) || [];
        console.log('paymentSubgroup', { paymentSubgroup });

        for (let i = 0; i < paymentSubgroup.length; i++) {
            const payment = paymentSubgroup[i];
            const { Day, Month, Year, SubjectId, Type } = payment;
            const dateKey = `${Month}_${Year}`;
            if (!payments[dateKey]) payments[dateKey] = {};
            if (!payments[dateKey][subgroupId]) payments[dateKey][subgroupId] = {};
            if (!payments[dateKey][subgroupId][SubjectId]) payments[dateKey][subgroupId][SubjectId] = {};

            if (Type === 'month') {
                payments[dateKey][subgroupId][SubjectId][Type] = { ...payment };
            }
            if (Type === 'day') {
                if (!payments[dateKey][subgroupId][SubjectId][Type]) payments[dateKey][subgroupId][SubjectId][Type] = {};
                payments[dateKey][subgroupId][SubjectId][Type][Day] = { ...payment };
            }
        }
        this.setState({ payments });
    }

    addPayment = async ({ subgroupid, userid, day, month, year, type, value }) => {

        const { client } = this.props;

        const SubjectType = 'user';
        const SubjectId = userid;
        const ObjectType = 'subgroup';
        const ObjectId = subgroupid;
        const Day = day || 1;
        const Month = month;
        const Year = year;
        const Type = type;
        const Value = value;
        const PaymentKey = 'subgroup_payment';

        const result = await client.addPayment({ SubjectType, SubjectId, ObjectType, ObjectId, Day, Month, Year, Type, PaymentKey, Value });

        //SubjectType, SubjectId, ObjectType, ObjectId, Day, Month, Year, Type, PaymentKey, Value

        if (!result || !result.Message) {
            message.error('Ошибка сервера');
            return;
        }
        await this.getSubgroupPayments(subgroupid);
    }

    deletePayment = async (PaymentId, subgroupid, Month, Year) => {
        const { client } = this.props;
        const result = await client.deletePayment({ PaymentId });
        if (!result || !result.Message) {
            message.error('Ошибка сервера');
            return;
        }

        let payments = { ...this.state.payments };
        const dateKey = `${Month}_${Year}`;
        if (payments[dateKey][subgroupid]) {
            delete payments[dateKey][subgroupid];
            payments[dateKey][subgroupid] = {};
        }
        await this.getSubgroupPayments(subgroupid, payments);
    }

    // ОБНОВЛЕНИЕ РАСПИСАНИЯ
    shaduleUpdate = async (shaduleSaveObject) => {
        this.setState({ status: 'loading' });
        this.shaduleUpdateInside(shaduleSaveObject);
    }

    shaduleUpdateInside = async (shaduleSaveObject) => {

        const { client } = this.props;

        // по очереди кидает на сервер все изменения в расписании
        for (let i = 0; i < shaduleSaveObject.length; i++) {
            const { id, subgroupId, day, timeStart, timeEnd, isNew, isDelete } = shaduleSaveObject[i];
            if (!isNew && !isDelete) {
                await client.updateShadule(id, day, timeStart, timeEnd);
            }
            if (isNew) {
                await client.addShadule(subgroupId, day, timeStart, timeEnd);
            }
            if (isDelete) {
                await client.deleteShadule(id);
            }
        }

        const { subgroups } = this.state;

        let shadule = [];

        for (let i = 0; i < subgroups.length; i++) {
            const sb_shadule = await client.getShadule(subgroups[i].id);
            shadule.push(...sb_shadule);
        }

        this.setState({ shadule, status: 'loaded' });
        this.updateVisitsAndTrainings({ supgroupId: 1 }); // тут можно любую SB вбивать по факту
    }

    monthShift = async (plus) => {
        let selectedMonth = this.state.selectedMonth + plus;
        let selectedYear = this.state.selectedYear;

        if (selectedMonth < 1) {
            selectedMonth = 12;
            selectedYear--;
        }
        if (selectedMonth > 12) {
            selectedMonth = 1;
            selectedYear++;
        }

        return await this.updateVisitsAndTrainings({ month: selectedMonth, year: selectedYear });
    }

    dayShift = async (selectedDay, plus) => {
        // мы находим текущий индекс
        const { trainingDays } = this.state;
        let nextIndex = undefined;
        for (let i = 0; i < trainingDays.length; i++) {
            // нашли этот или следующий индекс
            if (Number(trainingDays[i]) >= selectedDay) {
                nextIndex = i + plus;
                break;
            }
        }

        // значит, мы в самом конце
        if (nextIndex === undefined) {
            if (plus > 0) {
                nextIndex = trainingDays.length;
            }
            if (plus < 0) {
                nextIndex = trainingDays.length - 1;
            }
        }

        // значит нам надо идти на следующий месяц
        if (nextIndex > (trainingDays.length - 1)) {
            const newTrainings = await this.monthShift(1);
            if (!newTrainings.length) { return 1 };
            return (Number(newTrainings[0]))
        }

        // значит нам надо идти на предыдущий месяц
        if (nextIndex < 0) {
            const newTrainings = await this.monthShift(-1);
            if (!newTrainings.length) { return 1 };
            return (Number(newTrainings[newTrainings.length - 1]))
        }

        // никуда идти не надо
        return Number(trainingDays[nextIndex]);
    }

    // эта штука обновляет посещения
    // если мы вбили month и year, он или берёт из стейта, или дописывает новый
    // если мы вбили supgroupid - значит надо обновить посещения по супгруппам
    updateVisitsAndTrainings = async ({ month, year, supgroupId }) => {

        const { client } = this.props;
        let visits = { ...this.state.visits };
        let selectedMonth = month || this.state.selectedMonth;
        let selectedYear = year || this.state.selectedYear;

        // проверяем, надо ли что-то подгрузить
        // если надо подгрузить, но с сервера мы и так получим нормальное расписание по всем подгруппам, так что их потом не паресчитыаем
        if (!visits[`${selectedMonth}_${selectedYear}`]) {
            const { subgroups } = this.state;
            visits[`${selectedMonth}_${selectedYear}`] = {};

            for (let i = 0; i < subgroups.length; i++) {
                const sb_visits = await client.getSubgroupVisits(subgroups[i].id, selectedYear, selectedMonth) || [];
                this.setSubgroupStudentsVisits(subgroups[i].id, sb_visits, `${selectedMonth}_${selectedYear}`);
                const sb_visits_grouper = groupBy(sb_visits, 'Day');
                visits[`${selectedMonth}_${selectedYear}`][subgroups[i].id] = { ...sb_visits_grouper };
            }

            this.setState({ visits, selectedMonth, selectedYear });
            // this.setSubgroupsShadule(selectedMonth, selectedYear);
            return this.setSubgroupsShadule(selectedMonth, selectedYear);
        }

        // ничего подгружать не пришлось

        if (supgroupId) {
            this.setSubgroupsShadule();
            return;
        }

        this.setState({ selectedMonth, selectedYear });
        return this.setSubgroupsShadule(selectedMonth, selectedYear);
    }

    // тут мы каждой супгруппе пишем именно её расписание
    // для данного месяца ( или введённого месяца )
    setSubgroupsShadule = (selectedMonth_, selectedYear_) => {

        const selectedMonth = selectedMonth_ || this.state.selectedMonth;
        const selectedYear = selectedYear_ || this.state.selectedYear;

        const momentLooked = moment(`1.${selectedMonth}.${selectedYear}`, 'DD.MM.YYYY');
        const firstDayInMonth = momentLooked.startOf('month').day();
        const daysTotal = momentLooked.daysInMonth();

        // console.log('setSubgroupsShadule', { momentLooked, month})

        let allShadule = {};

        const { shadule, subgroups } = this.state;

        let subgroups_ = [...subgroups];
        let shadule_ = {};

        for (let i = 0; i < shadule.length; i++) {
            if (!shadule_[shadule[i].subgroupId]) {
                shadule_[shadule[i].subgroupId] = []
            }
            shadule_[shadule[i].subgroupId].push(
                { ...shadule[i] }
            )
        }


        for (let i = 0; i < subgroups_.length; i++) {
            
            
            const { settingsAsObj } = subgroups_[i];
            if ( settingsAsObj && settingsAsObj.notActive) continue;

            const shadule__ = shadule_[subgroups_[i].id];
            if (shadule__ && shadule__.length > 0) {
                const shaduleForSubgroup = this.getSubgroupShadule({
                    firstDayInMonth,
                    daysTotal,
                    shadule: shadule__
                })
                subgroups_[i].shadule = shaduleForSubgroup;

                allShadule = { ...allShadule, ...shaduleForSubgroup };

            }
        }

        let trainingDays = Object.keys(allShadule);
        this.setState({ subgroups: subgroups_, trainingDays, trainingDaysAsObj: allShadule });
        return trainingDays;
    }

    // выдаёт тренировочные дни для конкретного расписания конкретной сабгруппы
    getSubgroupShadule = ({ firstDayInMonth, daysTotal, shadule }) => {

        let trainingDays = {};


        for (let i = 1; i <= daysTotal; i++) {

            let i_ = i;
            if (i_ + firstDayInMonth > 8) i_ -= 7;
            if (i_ + firstDayInMonth > 8) i_ -= 7;
            if (i_ + firstDayInMonth > 8) i_ -= 7;
            if (i_ + firstDayInMonth > 8) i_ -= 7;
            if (i_ + firstDayInMonth > 8) i_ -= 7;



            const day = i_ + firstDayInMonth - 1; // номер дня
            if (shadule.find(x => x.day === day)) {
                // const fullDay = i < 10 ? `0${i}` : `${i}`;
                // const fullDate = `${fullDay}.${month < 10 ? '0' + month : month}.${year}`;
                trainingDays[i] = true;
            }

        }

        return trainingDays;

    }

    // SUBGROUPS
    // работа с сапгруппами
    addSubgroup = async (subgroupObject) => {

        const { client } = this.props;
        const { groupId } = this.state;

        this.setState({ status: 'loading' });

        const {
            title: Title,
            adres: Adres,
            city: City,
            color,
            settings
        } = subgroupObject;

        await client.addSubgroup(
            groupId,
            color.substring(1),
            Title,
            City,
            Adres,
            'by_month',
            settings || null
        );

        this.initialLoad();
    }

    updateSubgroup = async (subgroupObject) => {

        const { client } = this.props;
        this.setState({ status: 'loading' });
        const {
            id,
            color,
            title,
            city,
            adres,
            payment_type,
            settings
        } = subgroupObject;

        await client.updateSubgroup(
            id,
            color.substring(1),
            title,
            city,
            adres,
            payment_type,
            settings || null
        );

        this.initialLoad();
    }

    deleteSubgroup = async (subgroupObject) => {

        const { client } = this.props;
        this.setState({ status: 'loading' });

        const {
            id
        } = subgroupObject;

        await client.deleteSubgroup(id);
        this.initialLoad();
    }

    //SB COMMENTS

    setSubgroupComments = (subgroupId, thissbcomments) => {
        let sbcomments = { ...this.state.sbcomments };

        console.log('setSubgroupComments', thissbcomments);

        if (thissbcomments && thissbcomments.length) {

            if (!sbcomments[subgroupId]) sbcomments[subgroupId] = {};

            for (let i = 0; i < thissbcomments.length; i++) {
                const thissbcomment = thissbcomments[i];
                const { Year, Month, Day } = thissbcomment;
                const date_key = `${Month}_${Year}`;
                if (!sbcomments[subgroupId][date_key]) sbcomments[subgroupId][date_key] = {}
                sbcomments[subgroupId][date_key][Day] = { ...thissbcomment }
            }

            this.setState({ sbcomments })
        };
    }


    addSbComment = async ({ subgroupid, day, comment = null, isCanceled = null }) => {

        const { client } = this.props;
        const { selectedMonth, selectedYear } = this.state;

        let sbcomments = { ...this.state.sbcomments };

        const result = await client.addSubgroupComment(subgroupid, selectedYear, selectedMonth, day, comment, isCanceled);

        if (!result || !result.SbCommentId) {
            message.error('Ошибка сервера');
            return;
        }

        const Id = result.SbCommentId;
        const date_key = `${selectedMonth}_${selectedYear}`;

        // по идее должны проверить результат тут
        if (!sbcomments[subgroupid]) sbcomments[subgroupid] = {}
        if (!sbcomments[subgroupid][date_key]) sbcomments[subgroupid][date_key] = {};

        sbcomments[subgroupid][date_key][day] = {
            Id,
            Day: day,
            Month: selectedMonth,
            Year: selectedYear,
            SubgroupId: subgroupid,
            IsCanceled: isCanceled,
            Comment: comment
        }

        message.success('Добавлен комментарий');
        this.setState({ sbcomments });
    }

    updateSbComment = async (sbcommentid, subgroupid, day, month, year, updateData) => {
        const { client } = this.props;
        const result = await client.updateSubgroupComment(sbcommentid, updateData);
        if (!result || !result.Message) {
            message.error('Ошибка сервера');
            return;
        }
        let sbcomments = { ...this.state.sbcomments };
        const dateKey = `${month}_${year}`;
        if (sbcomments[subgroupid] && sbcomments[subgroupid][dateKey] && sbcomments[subgroupid][dateKey][day]) {
            sbcomments[subgroupid][dateKey][day] = { ...sbcomments[subgroupid][dateKey][day], ...updateData }
        }
        message.success('Комментарий изменён');
        this.setState({ sbcomments })
    }

    deleteSbComment = async (sbcommentid, subgroupid, day, month, year) => {
        const { client } = this.props;
        const result = await client.deleteSubgroupComment(sbcommentid);
        if (!result || !result.Message) {
            message.error('Ошибка сервера');
            return;
        }

        let sbcomments = { ...this.state.sbcomments };
        const dateKey = `${month}_${year}`;
        if (sbcomments[subgroupid] && sbcomments[subgroupid][dateKey]) {
            delete sbcomments[subgroupid][dateKey][day];
        }
        message.success('Комментарий удалён');
        this.setState({ sbcomments })
    }

    // VISITS

    setSubgroupStudentsVisits = (subgroupId, visits = [], date_key) => {
        let studentVisits = { ...this.state.studentVisits };

        if (!studentVisits[date_key]) studentVisits[date_key] = {};

        let sb_visits = groupBy(visits, 'UserId') || {};

        studentVisits[date_key][subgroupId] = Object.keys(sb_visits).reduce((memo, userId) => ({ ...memo, [userId]: sb_visits[userId].length }), {})
        this.setState({ studentVisits })
    }

    addVisit = async ({ subgroupid, userid, day, discipline = 5 }) => {

        const { client } = this.props;
        const { selectedMonth, selectedYear } = this.state;

        let visits = { ...this.state.visits };

        const result = await client.addSubgroupVisit(subgroupid, userid, selectedYear, selectedMonth, day, discipline);

        if (!result || !result.VisitId) {
            message.error('Ошибка сервера');
            return;
        }

        const Id = result.VisitId;
        const date_key = `${selectedMonth}_${selectedYear}`;

        // по идее должны проверить результат тут
        if (!visits[date_key]) visits[date_key] = {}
        if (!visits[date_key][subgroupid]) visits[date_key][subgroupid] = {};
        if (!visits[date_key][subgroupid][day]) visits[date_key][subgroupid][day] = [];

        visits[date_key][subgroupid][day].push({
            Id,
            UserId: userid,
            Discipline: discipline,
            Month: selectedMonth,
            Year: selectedYear,
            SubgroupId: subgroupid
        });


        let studentVisits = { ...this.state.studentVisits };
        if (!studentVisits[date_key]) studentVisits[date_key] = {};
        if (!studentVisits[date_key][subgroupid]) studentVisits[date_key][subgroupid] = [];
        if (!studentVisits[date_key][subgroupid][userid]) studentVisits[date_key][subgroupid][userid] = 0;
        studentVisits[date_key][subgroupid][userid]++;


        this.setState({ visits, studentVisits });
    }

    deleteVisit = async ({ VisitId, Month, Year, Day, subgroupid }) => {
        const { client } = this.props;

        let visits = { ...this.state.visits };

        const result = await client.deleteSubgroupVisit(VisitId);

        if (!result || !result.Message) {
            message.error('Ошибка сервера');
            return;
        }

        const selectedMonth = Month;
        const selectedYear = Year;
        const date_key = `${selectedMonth}_${selectedYear}`;

        if (!visits[date_key]) visits[date_key] = {}
        if (!visits[date_key][subgroupid]) visits[date_key][subgroupid] = {};
        if (!visits[date_key][subgroupid][Day]) visits[date_key][subgroupid][Day] = [];

        let userId = null;

        for (let i = 0; i < visits[date_key][subgroupid][Day].length; i++) {
            const vis = visits[date_key][subgroupid][Day][i];
            if (vis.Id === VisitId) {
                userId = vis.UserId;
                visits[date_key][subgroupid][Day].splice(i, 1);
                break;
            }
        }

        let studentVisits = { ...this.state.studentVisits };

        if (userId) {
            if (studentVisits[[date_key]] && studentVisits[date_key][subgroupid] && studentVisits[date_key][subgroupid][userId]) {
                studentVisits[date_key][subgroupid][userId]--;
            }
        }

        this.setState({ visits, studentVisits });
    }

    moveToNewSb = async ({ personId, subgroupFromId, subgroupToId }) => {

        const { client } = this.props;

        const { studentsBySubgroups } = this.state;
        const subgroup = [...studentsBySubgroups[subgroupFromId]];
        const subgroupToInitial = [...studentsBySubgroups[subgroupToId]];
        if (!subgroup || !subgroupToInitial) return;

        const student = subgroup.find(x => x.Id === personId);
        if (!student) return;

        const studentAllready = subgroupToInitial.find(x => x.Id === personId);
        if (studentAllready) return;

        const studentIndex = subgroup.indexOf(student);
        if (studentIndex < 0) return;

        let subgroupFrom = [...subgroup];
        if (subgroupFromId === 0 || subgroupToId === 0)
            subgroupFrom.splice(studentIndex, 1);
        let subgroupTo = studentsBySubgroups[subgroupToId] ? [...studentsBySubgroups[subgroupToId]] : [];
        subgroupTo.push({ ...student, SubgroupId: subgroupToId });

        // т.е. до этого он не был в сапгруппе - мы добавляем нового
        if (subgroupFromId === 0) {
            client.addSubgroupUser(personId, subgroupToId);
        }

        // т.е. мы перекидываем его в тех, кто не в сапгруппе
        if (subgroupToId === 0) {
            client.deleteSubgroupUser(personId, subgroupFromId);
        }

        if (subgroupToId !== 0 && subgroupFromId !== 0) {
            client.addSubgroupUser(personId, subgroupToId);
        }

        this.setState({ studentsBySubgroups: { ...studentsBySubgroups, [subgroupFromId]: subgroupFrom, [subgroupToId]: subgroupTo } })


    }

    pageUpdate = (pageNow) => {
        this.setState({ pageNow });
    }

    // REQUESTS

    updateSbRequest = async ( { RequestId, Status, Comment }) => {
        const { client } = this.props;
        const { groupId } = this.state;

        const result = await client.updateCRMRequest({ RequestId, Status, Comment });
        // if (!result || !result.Message) {
        //     message.error('Ошибка сервера');
        //     return;
        // }
        const requests = await client.getCRMRequests({ GroupId: groupId}) || [];
        const isNullRequests = requests.find ( x => x.Status === 0);

        message.success('Заявка изменена');
        this.setState({ requests, isNullRequests })
    }

    render() {
        const {
            props: {
                children,
                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                isMobile,
                doShowFooter
            },
            state: {

                groupId,
                groupsChoose,

                status,
                pageNow,
                subgroups,
                studentsBySubgroups,

                shadule,
                students,

                selectedMonth,
                selectedYear,
                visits,
                studentVisits,
                trainingDays,
                trainingDaysAsObj,

                payments,
                sbcomments,

                requests,
                isNullRequests,

                iAmAdmin
            },

            setGroupId,

            addSubgroup,
            updateSubgroup,
            deleteSubgroup,

            shaduleUpdate,
            renewStudents,

            addVisit,
            deleteVisit,
            monthShift,
            dayShift,

            addPayment,
            deletePayment,

            addSbComment,
            updateSbComment,
            deleteSbComment,

            updateSbRequest,

            pageUpdate,

            moveToNewSb,
        } = this;

        return (
            <context.Provider value={{
                iAmAdmin,

                groupId,
                groupsChoose,

                status,
                pageNow,

                subgroups,
                studentsBySubgroups,

                shadule,

                students,

                selectedMonth,
                selectedYear,
                visits,
                studentVisits,
                trainingDays,
                trainingDaysAsObj,

                payments,
                sbcomments,

                setGroupId,

                pageUpdate,

                addSubgroup,
                updateSubgroup,
                deleteSubgroup,
                moveToNewSb,
                shaduleUpdate,
                renewStudents,

                addVisit,
                deleteVisit,
                monthShift,
                dayShift,

                addPayment,
                deletePayment,

                addSbComment,
                updateSbComment,
                deleteSbComment,

                requests,
                isNullRequests,
                updateSbRequest,

                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                isMobile,
                doShowFooter
            }}>
                {children}
            </context.Provider>
        )
    }
}

export default withCapoeiraSportState('iamuser, client, isMobile, showInfoModal, closeInfoModal, renewResourses, doShowFooter')(CRMState);


export const withCRMState = (withProps = '') => {
    return (
        (Wrapped) =>
            (props) => (
                <context.Consumer>
                    {
                        (context) => (
                            <Wrapped
                                {...props}
                                {...getWhatINeed(withProps, context)}
                            />
                        )
                    }
                </context.Consumer>
            )
    );
};

const getWhatINeed = (withProps, context) => {
    if (withProps === '') return context;
    return withProps.split(',').reduce((memo, value_) => {
        const value = value_.trim();
        if (!context[value]) {
            return memo;
        }
        return { ...memo, [value]: context[value] }
    }, {})
}