import React, { Component } from 'react';
import './component.css';

import { Popover } from 'antd';

/*

*/

const SPECIAL_CONTENT = {
  oneleg: `
   Начиная с момента выполнения ПЕРВОГО движения Вы можете касаться пола одновременно только одной ногой./
   Связку необходимо завершить также стоя на одной ноге!/
   При нарушении этих правил, видео связки приниматься не будет!/
   Во время выполнения связки ноги можно менять, но только одна должна касаться пола./
  `,
  twoside: `
   Вам необходимо выслать видео, где вы выполняете связку сначала в одну сторону, а потом в другую.
   /Все элементы и варианты связности должны быть полностью отзеркалены при выполнении.
   /Оценка за карточку элемента ставится исходя из ХУДШЕГО выполнения этого элемента в первой или второй связке.
   /Вы должны выполнить связку влево и вправо в одном видео, а не двумя отдельными видео!
   /Между двумя связками вы можете делать жингу или стоять в кадейре, чтобы настроиться.
  `,
  shuffle: `
   Вам даются на выбор несколько элементов, из которых нужно составить связку, разместив их в любом порядке./
   Если количество ячеек меньше, чем количество элементов - вы можете сами выбрать, что использовать, а что - нет.
  `,
  newyear: `
   Связка должна быть выполнена с применением любой атрибутики Нового Года!/
   Колпак Деда Мороза на голове, наряд снегурочки, обмотаться гирляндой, с мандаринами в руке, ёлочка на заднем плане - решать Вам :)/
   Чем креативнее и явнее читается атрибутика Нового Года - тем лучше :)
  `

}


export class SoloSpecial extends Component {
  
  render () { 
    const { client, special_type, is_mini } = this.props;

    if (is_mini) {
      return (
        <Popover placement = {"top"} content ={this.SpecialPoopover()}>
          <div className='solo-special-div-mini'>
            <img src = {client.getTechnicLogoUrl(`/special/${special_type}`)} />
          </div>
        </Popover>
      );  
    }

    return (
      <div className='solo-special-div'>

            <img src = {client.getTechnicLogoUrl(`/special/${special_type}`)} />
            <div className='solo-special-div-content'>
              {SPECIAL_CONTENT[special_type].split('/').map ( row => {return [row, <br/>]})}
            </div>   

      </div>
    );
   }

   SpecialPoopover = () => {

    const { special_type } = this.props;

    return <div className = 'solo-minisequencte-div-seq-name'>
       {SPECIAL_CONTENT[special_type].split('/').map ( row => {return [row, <br/>]})}
    </div>

   }

}