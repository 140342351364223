import React, { PureComponent, Fragment } from 'react';
//import logo from './logo.svg';

import { withCRMState } from '../../context';

import { Student } from '../student';

import Loader from 'src/ver2/components/loader';
import { Button, Radio, Input } from 'antd';


import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

import './style.css';

/* Мы сюда передаем
    subgroup
    student

*/
class AddVisitModal extends PureComponent {

    state = {
        today: {
            day: moment().date(),
            month: moment().month() + 1,
            year: moment().year()
        },

        isLoaded: false,

        paymentKey: 0,
        paymentValue: 0,

        choosedDay: -1,

        visitsData: {
            isMonthPayed: false,
            unpaidDays: []
        }
    }

    componentDidMount = () => {
        this.firstSubgroupPayments();
        this.firstUnpaid();
    }

    setChoosedDay = (choosedDay) => {
        this.setState({ choosedDay })
    }

    firstSubgroupPayments = () => {
        const { subgroup } = this.props;
        const { settings } = subgroup;

        if (!settings) return;
        const settingsObj = JSON.parse(settings);

        if (!settingsObj || !settingsObj.payment || !settingsObj.payment.length) return;
        const { value } = settingsObj.payment[0];
        this.setState({ paymentValue: value });
    }

    /*
        Тут надо понять, 
        1 - оплатил ли человек месяц (не важно, ходил или нет)
        2 - какие у человек не оплаченные тренировки (т.е. там, где он ходил в этом месяце, но не оплатил)
    */
    firstUnpaid = () => {
        let visitsData = {
            isMonthPayed: false,
            unpaidDays: []
        }

        const isMonthPayed = this.isMonthPayed();
        visitsData.isMonthPayed = isMonthPayed
        if (visitsData.isMonthPayed) {
            return this.setState({ visitsData })
        }

        // ищем неоплаченные дни
        const { visits } = this.props;

        const { selectedMonth, selectedYear, subgroup, student } = this.props;
        const dateKey = `${selectedMonth}_${selectedYear}`;
        const subgroupid = subgroup.id;
        const studentid = student.Id;

        if (visits && visits[dateKey] && visits[dateKey][subgroupid]) {
            // находим дни, далее ищем посещение данного ученика в этот день
            const days = Object.keys(visits[dateKey][subgroupid]) || [];
            for (let i = 0; i < days.length; i++) {
                const day = Number(days[i]);
                if (visits[dateKey][subgroupid][day].find(x => Number(x.UserId) === Number(studentid))) {
                    const isThereUnpaidDay = this.isDayPayed({ day });
                    if (!isThereUnpaidDay) {
                        visitsData.unpaidDays.push(
                            {
                                day,
                                fullDay: moment(`${day}.${selectedMonth}.${selectedYear}`, 'DD.MM.YYYY').format('DD MMM').toUpperCase(),
                                fullDayName: moment(`${day}.${selectedMonth}.${selectedYear}`, 'DD.MM.YYYY').format('dddd').substring(0, 3).toUpperCase()
                            }
                        )
                    }
                }
            }
        }

        return this.setState({ visitsData })
    }

    /*
        visits: {
        /*<month_year>: {
            <subgroupid> : {
                <day> : [
                    {
                        UserId
                        Disciplene
                    }
                ]
            }
        }
        */

    // оплачена ли разовая тренировка у данного
    isDayPayed = ({ day }) => {
        const { selectedMonth, selectedYear, subgroup, student, payments } = this.props;
        const dateKey = `${selectedMonth}_${selectedYear}`;
        const subgroupid = subgroup.id;
        const studentid = student.Id;
        return payments[dateKey] && payments[dateKey][subgroupid]
            && payments[dateKey][subgroupid][studentid]
            && payments[dateKey][subgroupid][studentid]['day']
            && payments[dateKey][subgroupid][studentid]['day'][day];
    }

    // оплачена ли разовая тренировка у данного
    isMonthPayed = () => {
        const { selectedMonth, selectedYear, subgroup, student, payments } = this.props;
        const dateKey = `${selectedMonth}_${selectedYear}`;
        const subgroupid = subgroup.id;
        const studentid = student.Id;
        return payments[dateKey] && payments[dateKey][subgroupid] && payments[dateKey][subgroupid][studentid] && payments[dateKey][subgroupid][studentid]['month'];
    }

    onChangePaymentKey = (e) => {
        const paymentKey = e.target.value;

        const { subgroup } = this.props;
        const { settings } = subgroup;
        const settingsObj = JSON.parse(settings);
        const { value } = settingsObj.payment[paymentKey];

        this.setState({ paymentKey, paymentValue: value });
    };

    onChangePaymentValue = (e) => {
        this.setState({ paymentValue: e.target.value });
    }

    render() {

        const { client, student, subgroup } = this.props;
        const { settings } = subgroup;

        const { isLoaded } = this.state;
        if (isLoaded) return <div className='crm_add_payment_modal_main'>
            <div className='crm_add_payment_modal_nodate'>
                <Loader text='загружаю оплату' />
            </div>
        </div>

        const nopayments = <div className='crm_add_payment_modal_main'>
            <div className='crm_add_payment_modal_nodate'>
                <span>
                    НЕТ ДАННЫХ ПО ОПЛАТАМ В ПОДГРУППЕ <br /><br />
                    ПЕРЕЙДИТЕ ВО ВКЛАДКУ ПОДГРУППЫ <br />
                    ОТКРОЙТЕ НАСТРОЙКИ ДАННОЙ ПОДГРУППЫ <br />
                    И ЗАПОЛНИТЕ ПОЛЯ ОПЛАТ
                </span>
            </div>
        </div>

        if (!settings) { return nopayments }
        const settingsObj = JSON.parse(settings);

        if (!settingsObj || !settingsObj.payment || !settingsObj.payment.length) { return nopayments }
        const { paymentKey } = this.state;

        const { type } = settingsObj.payment[paymentKey];
        let downComponent = null;

        if (type === 'day') downComponent = <this.DownDayed />
        if (type === 'month') downComponent = <this.DownMonth />

        const {
            isMonthPayed
        } = this.state.visitsData;

        return <div className='crm_add_payment_modal_main'>
            <div className='crm_add_payment_modal_student'>
                <Student
                    key={'gcrm-addpayment-stud-' + student.Id}
                    client={client}
                    student={student}
                    fields={
                        [
                            { type: 'avatar', width: 30 },
                            { type: 'fioac', width: 200 },
                        ]
                    }
                    notDragged
                />
            </div>
            {(!isMonthPayed) && <div className='crm_add_payment_modal_radio'>
                <Radio.Group onChange={this.onChangePaymentKey} value={paymentKey}>
                    {
                        settingsObj.payment.map(({ title, type }, index) => {
                            return <Radio key={`capm_radio_${index}_${type}`} value={index}>{title}</Radio>
                        })
                    }
                </Radio.Group>
            </div>
            }
            {downComponent}
        </div>
    }

    addPayment = async () => {
        this.setState({ isLoaded: true })

        const { selectedMonth, selectedYear, subgroup, student } = this.props;
        const subgroupid = subgroup.id;
        const userid = student.Id;

        const { 
            paymentKey,
            paymentValue,
            choosedDay
        } = this.state;

        const { settings } = subgroup;
        if (!settings) { return; }
        const settingsObj = JSON.parse(settings);

        if (!settingsObj || !settingsObj.payment || !settingsObj.payment.length) { return; }
        const { type } = settingsObj.payment[paymentKey];

        const day = ( choosedDay && choosedDay > 0) ? choosedDay : 1;
        await this.props.addPayment({ subgroupid, userid, day, month: selectedMonth, year: selectedYear, type, value: paymentValue })
        this.props.closeModal();
    }

    DownDayed = () => {

        const { paymentValue, visitsData, choosedDay } = this.state;
        const {
            isMonthPayed,
            unpaidDays,
            selectedMonth,
            selectedYear
        } = visitsData;

        if (isMonthPayed) return <Fragment>
            <div className='crm_add_payment_modal_donepayment'>
                ✓ ЭТОТ МЕСЯЦ УЖЕ ОПЛАЧЕН
            </div>
        </Fragment>

        if (!unpaidDays || !unpaidDays.length) return <Fragment>
            <div className='crm_add_payment_modal_donepayment'>
                НЕТ НЕОПЛАЧЕННЫХ ПОСЕЩЕНИЙ
            </div>
        </Fragment>

        let buttonText = '';
        const isDayChoosed = (choosedDay && choosedDay > 0);

        if (isDayChoosed) {
            buttonText = `ДОБАВИТЬ ОПЛАТУ ${moment(`${choosedDay}.${selectedMonth}.${selectedYear}`, 'DD.MM.YYYY').format('DD MMM').toUpperCase()}`;
        } else {
            buttonText = `ВЫБЕРИТЕ ДЕНЬ ДЛЯ ОПЛАТЫ`;
        }


        return <Fragment>
            <div className='crm_add_payment_modal_choosedate'>
                {unpaidDays.map((data) => {
                    const {
                        day, fullDay, fullDayName
                    } = data;

                    let className = 'crm_add_payment_modal_choosedate_day';
                    if (choosedDay === Number(day)) {
                        className += ' crm_add_payment_modal_choosedate_day_choosed';
                    }

                    return <div onClick={() => { this.setChoosedDay(day) }} className={className}>
                        <div className='crm_add_payment_modal_choosedate_upper'>
                            <span>{fullDay}</span>
                        </div>
                        <div className='crm_add_payment_modal_choosedate_down'>
                            <span>{fullDayName}</span>
                        </div>
                    </div>
                })}
            </div>
            <div className='crm_add_payment_modal_value'>
                <Input onChange={this.onChangePaymentValue} value={paymentValue} suffix={`₽`} />
            </div>
            <div className='crm_add_payment_modal_savebutton'>
                <Button onClick={this.addPayment} disabled={!isDayChoosed} type='primary'>{buttonText}</Button>
            </div>
        </Fragment>
    }

    DownMonth = () => {
        const { paymentValue, visitsData } = this.state;
        const {
            isMonthPayed
        } = visitsData;

        const { selectedMonth, selectedYear } = this.props;

        if (isMonthPayed) return <Fragment>
            <div className='crm_add_payment_modal_donepayment'>
                ✓ ЭТОТ МЕСЯЦ УЖЕ ОПЛАЧЕН
            </div>
        </Fragment>

        return <Fragment>
            <div className='crm_add_payment_modal_value'>
                <Input onChange={this.onChangePaymentValue} value={paymentValue} suffix={`₽`} />
            </div>
            <div className='crm_add_payment_modal_savebutton'>
                <Button onClick={this.addPayment} type='primary'>ДОБАВИТЬ ОПЛАТУ {moment(`1.${selectedMonth}.${selectedYear}`, 'DD.MM.YYYY').format('MMMM').toUpperCase()}</Button>
            </div>
        </Fragment>
    }

}

export default withCRMState(
    `
        client,
    
        subgroups,
        studentsBySubgroups,

        shadule,
        students,

        payments,
        visits,

        addPayment,

        selectedMonth,
        selectedYear,
        trainingDays,

        monthShift
    `
)(AddVisitModal)