import React, { Component } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { withCRMState } from './context';
import GroupPlate from 'src/ver2/components/group/groupPlate';
//import logo from './logo.svg';

import { Drawer, Icon } from 'antd';

import './style.css';
import Subgroups from './pages/subgroups';
import Shadule from './pages/shadule';
import Visits from './pages/visits';
import VisitsMobile from './pages/visitsMobile';
import Payemnts from './pages/payments';
import Stats from './pages/stats';
import Requests from './pages/requests';

const PAGES = [
    { key: 'subgroups', title: 'Подгруппы' },
    { key: 'shadule', title: 'Расписание' },
    { key: 'visits', title: 'Посещения' },
    { key: 'payments', title: 'Оплаты' },
    { key: 'stats', title: 'Статистика' },
    { key: 'requests', title: 'Запись' },
]

const PAGES_MOBILE = [
    { key: 'visits', title: 'Посещения' },
    { key: 'payments', title: 'Оплаты' },
    { key: 'stats', title: 'Статистика' },
    { key: 'requests', title: 'Запись' },
]

class GroupCRM extends Component {

    state = {
        drawerVisible: false
    }

    componentDidMount() {
        this.props.doShowFooter(false);
    }

    componentWillUnmount() {
        this.props.doShowFooter(true);
    }

    render() {

        const { status, groupId, groupsChoose, setGroupId, isMobile, iamuser } = this.props;

        if (!groupId) {

            if (groupsChoose && groupsChoose.length) {
                return <div className='groupscrm-main'>
                    <div className='groupscrm-main-choosegroup'>
                        <div className='groupscrm-main-choosegroup-header'>выбрать группу </div>
                        {groupsChoose.map((groupId) => {
                            return <div className='groupscrm-main-choosegroup-group' onClick={() => { setGroupId(groupId) }}>
                                <GroupPlate width={300} height={80} GroupId={groupId} />
                            </div>

                        })}
                    </div>
                </div>
            }

            if (!iamuser) {
                return <div className='groupscrm-main'>
                    <div className='groupscrm-main-choosegroup'>
                        Вы не вошли в систему<br />
                        Вход сверху справа
                    </div>
                </div>
            }

            return <div className='groupscrm-main'>
                <div className='groupscrm-main-choosegroup'>
                    Вы или не инструктор, или идёт загрузка групп
                </div>
            </div>
        }

        if (status === 'loading') {
            return <div className='groupscrm-main'>
                загружается...
            </div>
        }

        if (isMobile) {
            return <div className='groupscrm-main-mobile' style={{ height: isMobile.height }}>
                <this.HeaderMobile />
                <this.PagesMobile />
            </div>
        }

        return <div className='groupscrm-main'>
            <this.Header />
            <this.Pages />
        </div>
    }

    HeaderMobile = () => {
        const { groupId, pageNow, pageUpdate, isNullRequests } = this.props;
        const { drawerVisible } = this.state;

        const showDrawer = () => {
            this.setState({
                drawerVisible: true,
            });
        };

        const onClose = () => {
            this.setState({
                drawerVisible: false,
            });
        };

        return <div className='gcrm-header-mobile'>
            <div className='gcrm-header-menu-icon'>
                {(isNullRequests) && <div className='gcrm-header-dot-request' />}
                <Icon type="menu" onClick={showDrawer} />
            </div>
            <div className='gcrm-header-grouplogo'>
                <GroupPlate width={200} height={45} GroupId={groupId} />
            </div>
            <div className='gcrm-header-menu'>
                {(PAGES_MOBILE.find(x => x.key === pageNow) || {}).title}
            </div>
            <Drawer
                title="CRM меню"
                placement={'left'}
                closable={false}
                onClose={onClose}
                visible={drawerVisible}
            >
                {PAGES_MOBILE.map((item) => {
                    const className = pageNow === item.key ? 'gcrm_mob-header-menu-item gcrm_mob-header-menu-item-selected' : 'gcrm_mob-header-menu-item'
                    return <div onClick={() => { pageUpdate(item.key); onClose() }} className={className}>
                        {(item.key === 'requests' && isNullRequests) && <div className='gcrm-header-dot-request' />}
                        {item.title}
                    </div>
                })}
            </Drawer>
        </div>
    }

    PagesMobile = () => {
        const { pageNow } = this.props;
        switch (pageNow) {
            case 'visits': {
                return <VisitsMobile />
            }
            case 'payments': {
                return <Payemnts />
            }
            case 'stats': {
                return <Stats />
            }
            case 'requests': {
                return <Requests />
            }
            default: {
                return <VisitsMobile />
            }
        }
    }

    Header = () => {

        const { pageNow, pageUpdate, groupId, isNullRequests } = this.props;

        return <div className='gcrm-header'>
            <div className='gcrm-header-grouplogo'>
                <GroupPlate width={200} height={45} GroupId={groupId} />
            </div>
            <div className='gcrm-header-menu'>
                {PAGES.map((item) => {
                    const className = pageNow === item.key ? 'gcrm-header-menu-item gcrm-header-menu-item-selected' : 'gcrm-header-menu-item'
                    return <div onClick={() => { pageUpdate(item.key) }} className={className}>
                        {(item.key === 'requests' && isNullRequests) && <div className='gcrm-header-dot-request' />}
                        {item.title}
                    </div>
                })}
            </div>
        </div>
    }

    Pages = () => {
        return <div className='gcrm-pages-container'>
            <this.PagesContainer />
        </div>
    }

    PagesContainer = () => {
        const { pageNow } = this.props;
        switch (pageNow) {
            case 'subgroups': {
                return <DndProvider backend={HTML5Backend} options={{}}>
                    <Subgroups />
                </DndProvider>
            }
            case 'shadule': {
                return <Shadule />
            }
            case 'visits': {
                return <Visits />
            }
            case 'payments': {
                return <Payemnts />
            }
            case 'stats': {
                return <Stats />
            }
            case 'requests': {
                return <Requests />
            }
            // case 'visits': {
            //     const { shadule, studentsBySubgroups, visits, students, trainingDays, trainingDaysAsObj } = this.props;
            //     return <Visits 
            //       subgroups = {subgroups} 
            //       studentsBySubgroups = {studentsBySubgroups}
            //       shadule = {shadule}
            //       students = {students} 
            //       visits = {visits}    
            //       client = {client}   
            //       addVisit = {this.addVisit}   
            //       trainingDays = {trainingDays}       
            //       trainingDaysAsObj = {trainingDaysAsObj}
            //       />
            // }
            default: {
                return <div> ОШИБКА СТРАНИЦЫ </div>
            }
        }
    }


}

export default withCRMState(`

status,

groupId,
groupsChoose,

pageNow, 

pageUpdate,
setGroupId,

doShowFooter,
client,
iamuser,
isNullRequests,
isMobile

`)(GroupCRM);