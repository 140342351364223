import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import { SchoolPlaceRectMini } from 'src/ver2/components/place/school';
import LittleEvent from 'src/ver2/components/event/little';
import EventUsualPlashka from 'src/ver2/plashki/event/usual';
import { Link } from 'react-router-dom';

import Loader from 'src/ver2/components/loader';
import { Icon } from 'antd';
// import { Link } from 'react-router-dom';

import './style.css';

// class RegionEventsPlashka
// classname: region_events_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class RegionEventsPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        events: [],
        heightSetted: 0
    }

    setHeight = async (height) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        const { heightSetted } = this.state;
        console.log ('setHeight', heightSetted, height );
        if (heightSetted !== height ) {
            this.setState({
                heightSetted: height
            })
            setComponentSelfHeight({ height: height, plashkaId });
        }
    }


    componentDidMount = () => {
        this.loadInfo();
    }

    componentDidUpdate({ region, eventsLoaded }) {
        if (region !== this.props.region) {
            this.loadInfo();
        }
        if (eventsLoaded !== this.props.eventsLoaded) {
            this.loadInfo();
        }
    }

    loadInfo = async () => {
        const { client, region } = this.props;
        let events = [];
        const events_ = await client.eventsInRegion(region);

        if (events_ && events_.length) {
            events_.map(({ EventId }) => {
                const event = client.getEventById(EventId);
                if (event) events.push(event);
                return 1;
            })
        }
        this.setState({ events: events.reverse() })
    }


    render() {

        const { upperStyle, client } = this.props;
        const { width, height } = upperStyle;
        const { events } = this.state;

        // if (!Event) return <div style={{ width, height }} className='region_info_plashka'>
        //     <Loader />
        // </div>

        const elementHeight = 120;

        this.setHeight(events.length * (elementHeight + 30));

        return <div style={{ width, height }} className='region_events_plashka'>
            {events.map((event, index) => {
                return <div key={'elttr' + event.Id} style = {{height: elementHeight }} className='region_events_plashka_one'>
                    <EventUsualPlashka upperStyle={{ height: elementHeight }} Event={event} />
                </div>
            })}
        </div>
    }

    // constructor(props) {
    //     super(props);
    // }

}

export default withCapoeiraSportState(`
client,
eventsLoaded,
iamuser,
`)(RegionEventsPlashka)