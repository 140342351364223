import React, { PureComponent } from 'react';
import { withEventState } from 'src/ver2/pages/event/context';
import LittleQuest from 'src/ver2/components/quest/little';

import './style.css';

class PersonQuestsPlashka extends PureComponent {

    state = {
        listModalData: null,
        lastHeight: -1
    }

    componentDidMount = () => {
        this.timerR = setInterval(() => {
            const comp = document.getElementsByClassName('person_quests_plashka_inner')
            if (comp && comp[0]) {
                this.setComponentSelfHeight({ height: comp[0].clientHeight + 40 })
            }
        }, 500)
    }

    componentWillUnmount() {
        if (this.timerR) {
            clearInterval(this.timerR);
            delete this.timerR;
        }
    }

    setComponentSelfHeight = ({ height }) => {
        const { setComponentSelfHeight, plashkaId } = this.props;

        if (height === this.state.lastHeight) return;
        this.setState({ lastHeight: height })
        setComponentSelfHeight({ plashkaId, height });

    };

    isReleased = () => {
        // this.props.reloadScores();
        this.props.reloadServerQuests();
        this.props.reloadFizuha();
    }

    render() {

        const { isMobile, client, ServerQuests, isServerQuestsReady, iamuser, Event } = this.props;

        const { width, height } = this.props.upperStyle;

        if (!isServerQuestsReady) {
            return <div style={{ width, height }} className='person_quests_plashka'>
            </div>
        }

        let myQuests = [];
        if (iamuser && iamuser.myQuests && ServerQuests) {
            for (let i = 0; i < iamuser.myQuests.length; i++) {
                const { QuestId } = iamuser.myQuests[i];
                if (QuestId && ServerQuests.find(x => x.Id === QuestId)) {
                    myQuests.push({ ...iamuser.myQuests[i] })
                }
            }
        }

        const { SettingsObj } = Event || {};

        if (!isMobile && SettingsObj && SettingsObj.SpecialMain && SettingsObj.SpecialMain.FizChallenge) {
            return <div style={{ width, height }} className='person_quests_plashka'>
                <div className='person_quests_plashka_inner_fizuha'>
                    {(ServerQuests || []).map(({ Id }) => <LittleQuest key={`pqp-${Id}`} QuestId={Id} />)}
                    {(myQuests || []).map(({ Id }) => <LittleQuest isReleased={this.isReleased} key={`pqp-${Id}`} UserQuestId={Id} />)}
                </div>
            </div>
        }

        return <div style={{ width, height }} className='person_quests_plashka'>
            <div className='person_quests_plashka_inner'>
                {(ServerQuests || []).map(({ Id }) => <LittleQuest key={`pqp-${Id}`} QuestId={Id} />)}
                {(myQuests || []).map(({ Id }) => <LittleQuest isReleased={this.isReleased} key={`pqp-${Id}`} UserQuestId={Id} />)}
            </div>
        </div>
    }
}

export default withEventState(
    `
    Event,
    iamuser,
    client,
    isMobile,
    ServerQuests,
    isServerQuestsReady,
    reloadFizuha,

    reloadServerQuests,
    `
)(PersonQuestsPlashka)