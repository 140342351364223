import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
/*import CordaoRect from 'src/ver2/components/cordao/cordaoRect';
import GroupRect from 'src/ver2/components/group/groupRect';
import SchoolRect from 'src/ver2/components/school/schoolRect'
import PersonAvatar from 'src/ver2/components/person/avatar';
*/
import BSP from 'src/ver2/plashki/school/bigPlashka';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import UiPlashka from 'src/ver2/plashki/ui';

import SchoolRaitingPlashka from 'src/ver2/plashki/school/raitingPlashka';
import MedalShalvePlashka from 'src/ver2/plashki/medal/medalShalve';

import RaitingSubjectLayout from 'src/ver2/plashki/raiting/subjectLayout';
import CordaoSubjectLayout from 'src/ver2/plashki/cordao/subjectLayout';

import GroupsBigListPlashka from 'src/ver2/plashki/group/bigList';

import SchoolNewNewsPlashka from './plashki/newNews';

import { TrefaLayout } from 'src/ver2/containers/layout';


import './styles.css';

class SchoolPage extends PureComponent {

    constructor(props) {
        super(props);

        const schoolId = props.match.params.schoolId;

        this.searchDefault = {
            season: '2021-2022',
            type: 'comp_pro',
            category: null,
            cord: null
        }

        this.state = {
            schoolId,
            tab: 'info',
            OtvPersons: [],
            ...this.searchDefault
        }
    }

    chooseTab = () => {
        const { location } = this.props;
        const { pathname, search } = location;

        const pathnameArray = pathname.split('/');
        const schoolId = pathnameArray[2];
        const tabComming = pathnameArray[3];

        // парсим search

        let searchObj = {
            ...this.searchDefault
        }

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        let tab = '';

        switch (tabComming) {
            case 'raiting': tab = 'raiting'; break;
            case 'cordao': tab = 'cordao'; break;
            case 'news': tab = 'news'; break;
            case 'info':
            default: tab = 'info';
        }

        this.setState({
            schoolId,
            tab,
            ...searchObj
        })


    }

    componentDidMount() {
        this.chooseTab();
        this.findOtvPersons();
    }

    findOtvPersons = async () => {
        const { schoolId } = this.state;
        const { client } = this.props;
        const otv = await client.getSchoolRoles(schoolId);
        let OtvPersons = [];
        if (otv && otv.length) {
            OtvPersons = otv.map(({ UserId }) => {
                return {
                    Id: UserId
                }
            })
        }
        this.setState({OtvPersons})
    }

    componentDidUpdate({ }, { category }) {
        this.chooseTab();
        if (this.props.isMobile) {
            if (category !== this.state.category && this.state.category && this.state.category !== '') {
                const element = document.getElementById('raiting_subject_to_scroll');
                if (!element) {
                }
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }
    }

    render() {

        // const width = 1200;
        const { schoolId, tab, season, type, category, cord, OtvPersons } = this.state;
        const { isMobile } = this.props;
        const width = isMobile ? isMobile.width : 1200;

        // 24 + 12 + 12 + 24 = 72   +28

        // let OtvPersons = []

        // if (schoolId === '1') {
        //     OtvPersons = [{ Id: 19 }, { Id: 2 }];
        // }
        // if (schoolId === '2') {
        //     OtvPersons = [{ Id: 53 }, { Id: 51 }];
        // }
        // if (schoolId === '3') {
        //     OtvPersons = [{ Id: 31 }, { Id: 212 }];
        // }
        // if (schoolId === '12') {
        //     OtvPersons = [{ Id: 765 }];
        // }
        // if (schoolId === '13') {
        //     OtvPersons = [{ Id: 1273 }];
        // }
        // if (schoolId === '15') {
        //     OtvPersons = [{ Id: 409 }];
        // }

        // MOBILE PART /////////////////////////

        if (isMobile) {

            if (tab === 'news') {
                return <div style={{ width }} className='school_page'>
                    <TrefaLayout greedsX={[10, width - 20, 10]}
                        components={[
                            {
                                component: BSP,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: { _title: 'Школа', height: 150, schoolId }
                            },
                            {
                                component: UiPlashka,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: {
                                    height: 90, tab, isMobile,
                                    UiItems: [
                                        { icon: 'info', text: 'Общая информация' },
                                        { icon: 'cordao', text: 'Состав и градация' },
                                        { icon: 'raiting', text: 'Соревновательный рейтинг' },
                                        { icon: 'news', text: 'Новости школы' }

                                    ]
                                }
                            },
                            {
                                component: SchoolNewNewsPlashka,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: { _title: 'Новости школы', height: 500, schoolId }
                            }
                        ]
                        }
                    />
                </div>
            }

            if (tab === 'cordao') {
                return <div style={{ width }} className='school_page'>
                    <TrefaLayout greedsX={[10, width - 20, 10]}
                        components={[
                            {
                                component: BSP,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: { _title: 'Школа', height: 150, schoolId }
                            },
                            {
                                component: UiPlashka,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: {
                                    height: 90, tab, isMobile,
                                    UiItems: [
                                        { icon: 'info', text: 'Общая информация' },
                                        { icon: 'cordao', text: 'Состав и градация' },
                                        { icon: 'raiting', text: 'Соревновательный рейтинг' },
                                        { icon: 'news', text: 'Новости школы' }

                                    ]
                                }
                            },
                            {
                                component: CordaoSubjectLayout,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: { yPadding: 'little', height: 900, schoolId, cord }
                            }
                        ]
                        }
                    />
                </div>
            }

            if (tab === 'raiting') {
                return <div style={{ width }} className='school_page'>
                    <TrefaLayout greedsX={[10, width - 20, 10]}
                        components={[
                            {
                                component: BSP,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: { _title: 'Школа', height: 150, schoolId }
                            },
                            {
                                component: UiPlashka,
                                cell_x: 2,
                                collapse: 1,
                                componentProps: {
                                    height: 90, tab, isMobile,
                                    UiItems: [
                                        { icon: 'info', text: 'Общая информация' },
                                        { icon: 'cordao', text: 'Состав и градация' },
                                        { icon: 'raiting', text: 'Соревновательный рейтинг' },
                                        { icon: 'news', text: 'Новости школы' }

                                    ]
                                }
                            },
                            {
                                component: RaitingSubjectLayout,
                                cell_x: 2,
                                collapse: 1,
                                id: 777,
                                componentProps: { yPadding: 'little', season, type, category, schoolId }
                            }
                        ]
                        }
                    />
                </div>
            }


            return <div style={{ width }} className='school_page'>
                <TrefaLayout greedsX={[10, width - 20, 10]}
                    components={[
                        {
                            component: BSP,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { _title: 'Школа', height: 150, schoolId }
                        },
                        {
                            component: UiPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: {
                                height: 90, tab, isMobile,
                                UiItems: [
                                    { icon: 'info', text: 'Общая информация' },
                                    { icon: 'cordao', text: 'Состав и градация' },
                                    { icon: 'raiting', text: 'Соревновательный рейтинг' },
                                    { icon: 'news', text: 'Новости школы' }

                                ]
                            }
                        },
                        {
                            component: SchoolRaitingPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 110, schoolId, yPadding: true }
                        },
                        {
                            component: MedalShalvePlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 150, yPadding: true, _title: 'Медали за сезон', Medals: [{ eventId: 80, place: 2 }], asCount: true, schoolId }
                        },
                        {
                            component: PersonsLittleListPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { _title: 'Ответственные', yPadding: true, height: (OtvPersons.length * 65) + 50, itemHeight: 65, Persons: OtvPersons, mode: 'contact' }
                        },
                        {
                            component: GroupsBigListPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 123,
                            componentProps: { height: 150, yPadding: true, _title: 'Группы', mode: { name: 'school', schoolId } }
                        }
                    ]
                    }
                />
            </div>
        }


        // DESCTOP PART /////////////////////////

        if (tab === 'cordao') {
            return <div style={{ width }} className='school_page'>
                <TrefaLayout greedsX={[24, 628, 12, 250, 12, 250, 24]}
                    components={[
                        {
                            component: BSP,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { _title: 'Школа', height: 300, schoolId }
                        },
                        {
                            component: PersonsLittleListPlashka,
                            cell_x: 4,
                            collapse: 1,
                            componentProps: { _title: 'Ответственные', height: 300, itemHeight: 65, Persons: OtvPersons, mode: 'contact' }
                        },
                        {
                            component: UiPlashka,
                            cell_x: 6,
                            collapse: 1,
                            componentProps: {
                                height: 250, tab,
                                UiItems: [
                                    { icon: 'info', text: 'Общая информация' },
                                    { icon: 'cordao', text: 'Состав и градация' },
                                    { icon: 'raiting', text: 'Соревновательный рейтинг' },
                                    // { icon: 'news', text: 'Новости школы' }

                                ]
                            }
                        },
                        {
                            component: CordaoSubjectLayout,
                            cell_x: 2,
                            collapse: 5,
                            componentProps: { yPadding: 'little', height: 1200, schoolId, cord }
                        }
                    ]
                    }
                />
            </div>
        }

        if (tab === 'raiting') {
            return <div style={{ width }} className='school_page'>
                <TrefaLayout greedsX={[24, 628, 12, 250, 12, 250, 24]}
                    components={[
                        {
                            component: BSP,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { _title: 'Школа', height: 300, schoolId }
                        },
                        {
                            component: PersonsLittleListPlashka,
                            cell_x: 4,
                            collapse: 1,
                            componentProps: { _title: 'Ответственные', height: 300, itemHeight: 65, Persons: OtvPersons, mode: 'contact' }
                        },
                        {
                            component: UiPlashka,
                            cell_x: 6,
                            collapse: 1,
                            componentProps: {
                                height: 250, tab,
                                UiItems: [
                                    { icon: 'info', text: 'Общая информация' },
                                    { icon: 'cordao', text: 'Состав и градация' },
                                    { icon: 'raiting', text: 'Соревновательный рейтинг' },
                                    // { icon: 'news', text: 'Новости школы' }

                                ]
                            }
                        },
                        {
                            component: RaitingSubjectLayout,
                            cell_x: 2,
                            collapse: 5,
                            componentProps: { yPadding: 'little', season, type, category, height: 600, schoolId }
                        }
                    ]
                    }
                />
            </div>
        }


        return <div style={{ width }} className='school_page'>
            <TrefaLayout greedsX={[24, 628, 12, 250, 12, 250, 24]}
                components={[
                    {
                        component: BSP,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { _title: 'Школа', height: 300, schoolId }
                    },
                    {
                        component: PersonsLittleListPlashka,
                        cell_x: 4,
                        collapse: 1,
                        componentProps: { _title: 'Ответственные', height: 300, itemHeight: 65, Persons: OtvPersons, mode: 'contact' }
                    },
                    {
                        component: UiPlashka,
                        cell_x: 6,
                        collapse: 1,
                        componentProps: {
                            height: 250, tab,
                            UiItems: [
                                { icon: 'info', text: 'Общая информация' },
                                { icon: 'cordao', text: 'Состав и градация' },
                                { icon: 'raiting', text: 'Соревновательный рейтинг' },
                                // { icon: 'news', text: 'Новости школы' }

                            ]
                        }
                    },
                    {
                        cell_x: 6,
                        collapse: 1,
                        componentProps: { height: 74 }
                    },
                    {
                        component: SchoolNewNewsPlashka,
                        cell_x: 6,
                        collapse: 1,
                        componentProps: { _title: 'Новости школы', height: 500, schoolId }
                    },
                    {
                        component: SchoolRaitingPlashka,
                        cell_x: 2,
                        collapse: 1,
                        componentProps: { height: 130, schoolId, yPadding: true }
                    },
                    {
                        component: MedalShalvePlashka,
                        cell_x: 4,
                        collapse: 1,
                        componentProps: { height: 130, yPadding: true, _title: 'Медали за сезон', Medals: [{ eventId: 80, place: 2 }], asCount: true, schoolId }
                    },
                    {
                        component: GroupsBigListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 123,
                        componentProps: { height: 150, yPadding: true, _title: 'Группы', mode: { name: 'school', schoolId } }
                    }

                ]
                }
            />
        </div>
    }
}


export default withCapoeiraSportState('iamuser, client, isMobile')(SchoolPage);