import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { TrefaLayout } from 'src/ver2/containers/layout';

import ButtonPlashka from 'src/ver2/plashki/ui/button';

import TechnicAllPlashka from './plashki/all';

import TechincPageAll from './all-technics';
import TechincPageOne from './one-technics';

import { Modal } from 'antd';


import './styles.css';

class TechincPage extends PureComponent {

    constructor(props) {
        super(props);

        const technicname = props.match.params.technicname;

        this.state = {
            technicname
        }

    }


    componentDidMount() {
    }

    //                prevProps, prevState
    componentDidUpdate( ) {
        if (this.props.match.params.technicname !== this.state.technicname) {
            this.setState({ technicname: this.props.match.params.technicname })
        }
    }

    render() {

        const width = 1200;
        const { iamuser } = this.props;
        const { technicname } = this.state;

        if ( technicname === 'all' ) {
            return <TechincPageAll />
        }
        
        return <TechincPageOne elementKey = {technicname} />;
    }
}


export default withCapoeiraSportState('iamuser, client')(TechincPage);