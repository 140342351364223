import React, { Component } from 'react';

import { useDrag } from 'react-dnd';

import { groupBy, orderBy } from 'lodash';

import { ElementCard } from 'src/ver2/containers/challenges/components/element-card';

import './component.css';

export class MobileElements extends Component {

  constructor(props) {

    super(props)

    const { client } = props;

    const elements = client.elements_as_object;

    if (!elements) client.formServerElementsAsObjectWithKeys();

    let groupedElements = groupBy(client.elements_as_object, 'type');

    const element_keys = Object.keys(groupedElements);

    element_keys.map((key) => {
      groupedElements[key] = orderBy(groupedElements[key], 'Points', 'asc');
      return;
    })

    const choosed_element_key = element_keys[0];

    this.state = {
      groupedElements,
      element_keys,
      choosed_element_key,
      elements_as_object: client.elements_as_object
    }

    client.getMySoloUserOptionsProxy().then(result => {
      this.setState({ userOptions: result })
    })

  }

  componentDidUpdate(prevProps) {

    const { sequenceSelectedNumber, client } = this.props;

    if (prevProps.sequenceSelectedNumber !== sequenceSelectedNumber && sequenceSelectedNumber !== undefined) {

      const { svyazka_to_show } = this.props;
      const card = svyazka_to_show[sequenceSelectedNumber];

      const { element, type } = card;
      if (type === 'movement' || type === 'strike' || type === 'defence') {
        return this.setState({ choosed_element_key: type })
      }
      if (element) {
        const elements = client.getServerElementsAsObject();
        const element_object = elements[element];
        if (element_object && element_object.type) {
          return this.setState({ choosed_element_key: element_object.type })
        }
      }

    }

  }

  render() {

    const { mobile } = this.props;

    if (mobile) {
      return <div className='seq-creator-elements-div-mobile'>
        <this.MenuElements />
        <this.Elements />
      </div>
    }

    return <div className='seq-creator-elements-div'>
      <this.MenuElements />
      <this.Elements />
    </div>
  }

  MenuElements = () => {

    const { mobile } = this.props;
    const { element_keys } = this.state;

    if (mobile) {
      return <div className='seq-creator-elements-div-menu-mobile'>
        {element_keys.map(item => { return <this.MenuItem key={`scedv ${item}`} option={item} /> })}
      </div>
    }

    return <div className='seq-creator-elements-div-menu'>
      {element_keys.map(item => { return <this.MenuItem key={`scedv ${item}`} option={item} /> })}
    </div>
  }

  MenuItem = ({ option }) => {


    const { mobile, client } = this.props;
    const { choosed_element_key } = this.state;

    let className = 'seq-creator-elements-div-menu-item';

    if (choosed_element_key === option) className += ' seq-creator-elements-div-menu-item_choosed'

    return <div className={className} onClick={(e) => { this.setState({ choosed_element_key: option }) }}>
      <span>{client.getTypeDiscription(option, true)}</span>
      <img style={{ width: 30, height: 30 }} alt='' src={client.getTechnicNeededLogo('ty_' + option)} />
    </div>

  }

  Elements = () => {

    const {
      mobile,
      setOktatAndChangedPoints,
      our_svyazka,
      UserElements
    } = this.props;
    const { choosed_element_key, groupedElements, userOptions } = this.state;

    // if (!userOptions || !userOptions.myElements ) return <div>элементы загружаются</div>;

    // const { myElements } = userOptions;

    const myElements = UserElements;


    let element_number_real = 0;

    const elements_ = Object.keys(groupedElements[choosed_element_key]).reduce(
      (result, element_number) => {

        const element = groupedElements[choosed_element_key][element_number]

        /* ВОТ ТУТ У НАС ПРОВЕРКА ТОГО, МОЖНО ЛИ ДОБАВЛЯТЬ ЭЛЕМЕНТ */
        if (!myElements[element.key]) return [...result]
        if (!this.mayAddElement(element)) return [...result]

        // skip - это значит что надо пропустить элемент
        const { otkat, changedPoints, skip } = setOktatAndChangedPoints(element);
        if (skip) return [...result]

        //if (our_svyazka.find( item => item && item.element_id === element.key)) return [...result]

        element_number_real++;

        const { rules } = this.props;
        const needed = (rules && rules.NeededElements && rules.NeededElements.find( x => x === element.key));

        return [...result,

        <this.MobileElement key={'mbcccc' + element.key}
          {...{ element_key: element.key, element_number: element_number_real, otkat, changedPoints, needed }}
        />

        ]

      },
      []
    )

    if (mobile) return <div className='seq-creator-elements-div-elements-mobile-upper'>
      <div id='seq-cr-element' className='seq-creator-elements-div-elements-mobile'>
        {elements_}
      </div>
      <div className='seq-creator-elements-div-elements-mobile-left-right'>
        <this.MobileButtonToLeft />
        <this.MobileButtonToRight />
      </div>
      <div style={{ minHeight: 50 }} />

    </div>

    return <div className='seq-creator-elements-div-elements'>
      {elements_}
    </div>
  }

  mayAddElement = (element) => {

    const { rules, RoundTier, our_svyazka } = this.props;

    // и еще мы смотрим mayDropHere

    const { mayDropHere, sequenceSelectedNumber } = this.props;

    if (sequenceSelectedNumber !== undefined) {
      // нужно вытащить карточку, на которую кидаем
      const { svyazka_to_show } = this.props;
      const card = svyazka_to_show[sequenceSelectedNumber];

      if (!mayDropHere({ element_id: element.key, card })) {
        return false;
      }
    }

    // return true;

    console.log('mayAddElement');
    if (!rules || !rules.Tier) return true;

    // сначала смотрим общие правила, потом смотрим правила для конкретного тира

    const ruleAll = rules;
    const ruleTier = rules.Tier[RoundTier];


    const { OnlyElements, ElementsRepeat } = rules;

    if (!this.mayAddByThisTier(ruleAll, element) || !this.mayAddByThisTier(ruleTier, element)) return false;

    // смотрим, сколько раз мы повторили элемент

    if (our_svyazka && ElementsRepeat) {
      let countRepeat = 0;
      for (let i = 0; i < our_svyazka.length; i++) {
        if (our_svyazka[i] && our_svyazka[i].element_id === element.key) {
          countRepeat++;
        }
        if (countRepeat >= ElementsRepeat) return false;
      }
    }

    if (OnlyElements[element.type] || OnlyElements.all) {
      // ищем по типу
      let isThereElement = null;
      if (OnlyElements[element.type])
        isThereElement = OnlyElements[element.type].find(x => x === element.key);
      // ищем по всем
      if (!isThereElement && OnlyElements.all)
        isThereElement = OnlyElements.all.find(x => x === element.key);


      if (isThereElement) {
        return true;
      }
      return false;
    }

    return true;

  }

  mayAddByThisTier = (rule, element) => {
    if (!rule) return true;
    const { our_svyazka, client } = this.props;
    const { Tier } = element;
    const {
      InSequence,
      OnlyElements,
      DenyElements
    } = rule;

    // первое, смотрим по Tier элемента сколько раз такие можно использовать
    if (InSequence.Tier[Tier]) {

      let mayAddCount = InSequence.Tier[Tier]; // например можно только 1 элемент такого тира

      // смотрим ВСЕ элементы
      for (let i = 0; i < our_svyazka.length; i++) {
        if (our_svyazka[i] && our_svyazka[i].element_id) {
          const element = client.elements_as_object[our_svyazka[i].element_id];
          if (element && element.Tier === Tier) {
            mayAddCount--;
          }
          if (mayAddCount <= 0) return false;
        }
      }
    }

    if (DenyElements.Tier[Tier]) return false;

    console.log('OnlyElements', OnlyElements);

    // теперь еще смотрим, есть ли в rules OnlyElements
    if (OnlyElements[element.type] || OnlyElements.all) {
      // ищем по типу
      let isThereElement = null;
      if (OnlyElements[element.type])
        isThereElement = OnlyElements[element.type].find(x => x === element.key);
      // ищем по всем
      if (!isThereElement && OnlyElements.all)
        isThereElement = OnlyElements.all.find(x => x === element.key);


      if (isThereElement) {
        return true;
      }
      return false;
    }

    return true;

  }

  MobileButtonToRight = () => {

    return <div onClick={() => {
      const el = document.getElementById('seq-cr-element');
      if (el) el.scrollLeft += 35;
    }} className='seq-creator-elements-div-elements-monile-rightleft'> {'>'} </div>

  }

  MobileButtonToLeft = () => {

    return <div onClick={() => {
      const el = document.getElementById('seq-cr-element');
      if (el) el.scrollLeft -= 35;
    }} className='seq-creator-elements-div-elements-monile-rightleft'> {'<'} </div>

  }

  MobileElement = ({ element_key, element_number, otkat, changedPoints, needed }) => {


    const { client, mobile, cardSelectedElement, selectCardElement } = this.props;

    const [{ isDragging }, drag] = useDrag({
      item: { type: 'KNIGHT', element_id: element_key },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    })

    let sizes = {
      width: mobile ? 50 : 80,
      no_select: true,
      dont_show_video: true,
      style: {
        animationDelay: `${element_number * 50}ms`
      },
      otkat,
      reff: drag
    }

    if (needed) sizes.needed = 1;

    return <div
      onClick={(e) => { selectCardElement(element_key) }}
      style={cardSelectedElement === element_key ? { border: '3px green solid' } : {}}
      className='seq-cr-element-upper'
    >
      <ElementCard
        key={`seocd-${element_key}`}
        cover_id={'usual'}
        {...{
          client,
          sizes,
          element_key,
          mobile,
          ...changedPoints ? { changedPoints } : {}
        }}
      />
    </div>
  }


}
