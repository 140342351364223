import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export const PamyatkaRazdel = ({
    Title = '',
    leftIcons = [],
    rightText = null
}) => {
    return <div className='pamyatka_razdel_container'>
        <div className='pamyatka_left_part'>
            {leftIcons.map(item => {
                return <img src={`/../images/ver2/pamyatka/${item}.png`} />;
            })}
        </div>
        <div className='pamyatka_right_part'>
            <div className='pamyatka_right_part_header'>
                {Title}
            </div>
            <div className='pamyatka_right_part_text'>
                {rightText}
            </div>
        </div>
    </div>
}


export const AboutTabText = () => <Fragment>
    <img src='https://kapoeira.ru/images/MainLogo.png' style={{ width: '100%', height: 'auto' }} />
    <b className='judge_razdel_1'>О деятельности ФКР.</b> <br /><br />
    Федерация капоэйра России – общественная организация, развивающая капоэйру как спорт на территории нашей страны. <br /><br />
    ФКР не является школой капоэйра, не проводит тренировок, не имеет градации поясов, курирующего мастера, списка приоритетных ритмов и всего прочего, что принято ассоциировать с этим видом боевого искусства. <br /><br />
    ФКР не имеет позиции о том, какой капоэйра должна быть, и никаким образом не пытается указать приверженцам данного культурного феномена, как им строить тренировочный процесс, как двигаться в кругу и как организовывать собственные внутренние мероприятия. <br /><br />
    Единственными двумя официальными задачами ФКР, как структуры, служит: <br /><br />
    - Организация соревнований по капоэйра, как по виду спорта, официально признанному на территории РФ<br />
    - Выдача судейских и спортивных разрядов по данному виду спорта<br />
    <br />

    Так как большинство членов ФКР сами являются капоэйристами различных школ, то, по мере возможности, мы стараемся проводить работы, нацеленные на создание общих открытых площадок для всех капоэйристов страны, нацеленных на объединение и общий рост. К таким мероприятиям можно причислить создание этого самого портала, проведение многочисленных мастер-классов в рамках соревнований в Москве и Санкт-Петербурге с привлечением инструкторов из школ SoForca, Marinheiro, Axe Capoeira, Muzenza, Alcateia, Senzala, РЦК, Cdo, Arte Capoeira и многими другими, создание открытых видеоматериалов обучающего и информационного характера, организация открытых online челленджей и соло-соревнований.

<br /><br /><br />

    <b className='judge_razdel_1'>О членстве в ФКР.</b> <br />
    Членство ФКР можно условно разделить на два вида:<br /><br />


    <b className='judge_razdel_2'>Спортивное членство.</b> <br /><br />

    Каждый спортсмен, желающий выступать на официальных спортивных соревнованиях по капоэйра, должен оформить спортивное членство в ФКР. <br /><br />
    Члены оплачивают ежегодный взнос и получают возможность участвовать в соревнованиях, получать спортивный и судейский разряд и занимать позицию в общем рейтинге портала capoeirasport. <br /><br />
    На момент введения карантина мы отменили членские взносы в рамках помощи спортсменам и планируем ввести функционал оплаты через портал в грядущем спортивном сезоне.

<br /><br />
    <b className='judge_razdel_2'>Создание региональной федерации.</b> <br /><br />

    Любое лицо, желающие возложить на себя такую ответственность, может подать заявку на создание региональной спортивной федерации по капоэйра. <br /><br />
    Региональная ФК занимается регулированием процессов организации спортивных мероприятий на своей территории и присвоением массовых спортивных разрядов спортсменам региона. <br /><br />
    Опять таки, данная работа никаким образом не влияет и не вмешивается в непосредственный тренировочный процесс, идеологию школ капоэйра, организацию собственных внутренних соревнований, аттестаций, семинаров и род в регионе и относится по большей части к документообороту. <br />
    Этой работой, в целом, не обязательно должен заниматься капоэйрист, а может делать любое заинтересованное лицо.
Для подробной информации Вы можете обратиться к администрации ФКР: <br /><br />
    Хромов Николай <br />
    Григорий Криворуцкий <br />

    <br /><br />
    <b className='judge_razdel_2'>текст статьи дополнятся...</b> <br /><br />


</Fragment>

export const RaitingTabText = () => <Fragment>
    <br /><br />
    <b className='judge_razdel_2'>страница пока не добавлена</b> <br /><br />
</Fragment>

export const CategorysTabText = () => <Fragment>
    <b className='judge_razdel_1'>Категории.</b> <br />
    Категории спортсменов разделяются по возрастам участников согласно ЕВСК России и условно делятся на три категории:<br /><br />
    <b className='judge_razdel_2'>-Фестивальные (не приставляются к разрядам) </b> <br />
    <b className='judge_razdel_2'>-Категории Первенств </b>  <br />
    <b className='judge_razdel_2'>-Категории Чемпионатов </b>  <br />

    <br />

    На большинстве официальных соревнованиях мы также разделяем категории на Новичков и Про.<br />
    Новичковые категории подразумевают стаж занятия капоэйрой не более двух лет и являются “ознакомительными”, чтобы новички могли попробовать себя.<br />
    ПРО - это основные спортивные категории.

<br /><br />

    <b className='judge_razdel_2'>Фестивальные</b> <br />
    К фестивальным категориям относятся возраста 4-5 лет, 6-7 лет, 8-9 лет, и все Новичковые категории. <br />
    Они не представляются к спортивным разрядам и для них на портале ведётся отдельный Новичковый рейтинг.
<br /><br />

    <b className='judge_razdel_2'>Первенства</b> <br />
    К категориям первенств относятся возраста 10-12 лет, 13-15 лет и 16-17 лет.<br />
    Максимальный спортивный разряд, который можно получить в первенствах – это Кандидат в Мастера Спорта.

<br /><br />
    <b className='judge_razdel_2'>Чемпионаты</b> <br />
    К категории чемпионата относится возраст от 18 лет. Это основная спортивная категория.<br />
    Максимальный спортивный разряд, который можно получить в чемпионатах – это Мастер Спорта.

<br /><br /><br />

    <b className='judge_razdel_1'>Системы проведения</b> <br />
    Категории проводятся в четырёх основных системах: <br /><br />

    <b className='judge_razdel_2'>Олимпийская</b> <br />
    Спортсмены идут по стандартной сетке и выбывают из соревнования после первого поражения.<br />
    <Link to='/event/106/?page=shadule&categoryId=885'>пример олимпийской сетки</Link>

    <br /><br />
    <b className='judge_razdel_2'>Double-elimination</b> <br />
    Спортсмены изначально стартуют в “верхней” сетке, но после одного проигрыша падают в “нижнюю” сетку, где продолжают участие. <br />
    Обе сетки строятся подобно олимпийской системе с той разницей, что один раз в два этапа в нижнюю сетку добавляются проигравшие в верхней сетке спортсмены. <br />
    Закрытием категории считается “гранд-финал”, где бьются победители верхней и нижней сетки, таким образом, проигравший один раз спортсмен всё равно может претендовать на любое место пьедестала вплоть до первого
<br />
    <Link to='/event/106/?page=shadule&categoryId=895'>пример сетки Double-elimination</Link>

    <br /><br />
    <b className='judge_razdel_2'>Круговая система</b> <br />
    Каждый спортсмен проводит бой с каждым спортсменом в категории. <br />
    За один судейский голос спортсмен получает один балл (т.е. в случае выигрыша единогласно спортсмен получает 3 балла, в случае победы с преимуществом, выигравший – 2 балла, проигравший – 1 балл), <br />
    места в категории распределяются исходя из набранных баллов. <br /><br />
    Если количество баллов у спортсменов равны, то рассматривается количество побед или результат личной встречи.
<br />
    <Link to='/event/106/?page=shadule&categoryId=896'>пример круговой сетки</Link>

    <br /><br />
    <b className='judge_razdel_2'>Групповая или смешанная система</b> <br />
    Комбинация из вышеописанных систем, например, категория в 16 человек разбивается на четыре круговые по 4 человека, и далее по одному победителю из каждой группы играют полуфиналы по олимпийской системе.

<br /><br />
    Все фестивальные категории (4-5, 6-7 и 8-9 лет и все категории Новички) проводятся по олимпийской системе.<br /><br />

    Все официальные категории (Первенство и Чемпионат ПРО) проводятся по системам Double-elimination и круговым системам, если это позволяет тайминг мероприятия.
<br /><br /><br />
    <b className='judge_razdel_1'>Распределение</b> <br />
    Во всех системах, кроме круговой, происходит жеребьевка спортсменов. <br />
    Жеребьевка влияет на стартовые пары спортсменов, их вертикальную позицию и на возможность автоматического прохождения в следующий круг, если
категория не набралась полностью:<br /><br />
    например, если в олимпийскую сетку набирается 16 участников то играется<br />
    1/8 финала (отсеивается 8 участников)<br />
    1/4 финала (отсеивается 4 участника)<br />
    1/2 финала (полуфинал) (остается 2 участника)<br />
    финал (выявляется победитель)<br />
    <br />
    Однако, если спортсменов набирается 14, а не 16, то двое автоматически проходят в 1/4 финала <br />
    <Link to='/event/106/?page=shadule&categoryId=885'>пример сетки на 14 человек</Link> <br /><br />

    На жеребьевку влияет два основных фактора: <br /><br />
    <b className='judge_razdel_2'>Позиция в рейтинге</b> <br />
    Каждая сетка строится таким образом, что, чем выше у спортсмена рейтинг, тем более приоритетную позицию он имеет.<br />
    Во-первых, в случае недобора полной категории, в следующий круг автоматически проходят спортсмены с более высоким рейтингом<br />
    Во-вторых, сетки выстраивается так, чтобы в стартовых парах сильный соперник встречался со слабым, а наиболее сильные имели возможность
пересечься только ближе к финалам. <br /><br />
    <b className='judge_razdel_2'>Школа и группа</b> <br />
    Стартовые пары распределяются так, чтобы спортсмены из одной школы и одной группы преимущественно шли по разным частям и имели возможность
    выйти друг на друга как можно позже. Исключение составляет, когда в категорию заявлено более 50% участников одной школы или группы, тогда
их пересечение в первом кругу неминуемо.<br />
    Естественно, правило действует, если спортсмен указал в профиле портала школу и группу.

<br /><br />
    <b className='judge_razdel_2'>текст статьи дополнятся...</b> <br /><br />


</Fragment>