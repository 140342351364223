import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class OTIconPlashka
// classname: ot_icon_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class OTIconPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    /*
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    render() {

        const { upperStyle, elementKey, client } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='ot_icon_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='ot_icon_plashka'>
            <img className = {'ot_icon_plashka_bg'} src = '/../images/ver2/technics/spot_fon.png' />
            <img className = {'ot_icon_plashka_icon'} src={client.getTechnicLogoUrl(elementKey)} />
        </div>
    }
}

export default withCapoeiraSportState(`
client
`)(OTIconPlashka)