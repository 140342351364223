import { createContext } from 'react';
import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { sortBy, groupBy } from 'lodash';
import { message } from 'antd';
import { getElementsScoresByJudging_arrays, getElementsScoresByJudgingOptions, parseSEquenceOptions } from 'src/ver2/containers/challenges/helpers';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

const context = createContext(
    {
        defVal: 'FUUUCK YEAH'
    }
);

/*

  Это контекст, в который приходит eventId, он его подгружает и всё выдает наследникам контекста
  в пропсы ожидает eventId, client, iamuser

*/


const initialState = {
    Event: null, // это рассматриваемое в данный момент событие
    isEventReady: false,

    Stuff: null,
    isStuffReady: false,

    Discription: null, // это рассматриваемое в данный момент событие
    isDiscriptionReady: false,

    Discriptions: null, // это рассматриваемое в данный момент событие
    isDiscriptionsReady: false,

    NewNews: null,
    isNewNewsReady: false,

    Operations: null,
    isOperationsReady: false,

    Championship: null,
    isChampionshipReady: false,

    Rounds: null,
    isRoundsReady: false,
    Combos: null,
    isCombosReady: false,

    SequncesConfirm: null,
    SequncesWaiting: null,
    isSoloSequencesReady: false,

    isThereTable: null,

    Categorys: null,
    isCategorysReady: false,

    Participators: null,
    isParticipatorsReady: false,

    ParticipatorsData: null,
    isParticipatorsDataReady: false,

    Competitors: [],
    CompetitorsAsObject: {},
    isCompetitorsReady: false,

    competitorsCountInCategorys: [],
    isCompetitorsCountInCategorysReady: false,

    Follows: null,
    isFollowsReady: false,
    iAmFollow: null, // { ...follow },

    categorysInfo: {},
    isCategorysInfoReady: false,

    Results: null,
    isResultsReady: false,

    ServerQuests: null,
    isServerQuestsReady: false,

    iAmCompetitor: null, // { Place, CategoryId }
    iAmAdmin: null, // { role }
    iAmJudge: null, // { role }
    iAmParticipator: null, // { ...participator, participatorData }
    mayIParticipateByFkr: null, // true, false
    myBets: null,

    Bets: null,

    Fizuha: null,
    isFizuhaReady: false,

}

class EventState extends PureComponent {


    constructor(props) {
        super(props);

        this.state = initialState;

        if (props.client) {
            this.client = props.client;
        }

    }

    initialLoad = () => {
        const Event = this.loadEvent();
        const { client } = this.props;
        console.log('Пробую подгрузить событие')
        if (!Event) return this.setState({ isEventReady: false });

        if (!Event.ChampionshipId) {
            this.setState({ isEventReady: true, Event });
        }

        // загрузка челленджа (пока просто раунды)
        if (Event.Type === 'challenge') {
            this.setState({ isRoundsReady: false, isCombosReady: false });
            client.getSoloRounds(Event.Id).then((Rounds) => {
                this.setState({ Rounds, isRoundsReady: true })
            })
            client.comboConstructor({
                TypeData: 'combo', ActionData: 'get', TargetId: null, Data: { ChallengeId: Event.Id }, DataExercises: null
            }).then((Combos) => {
                this.setState({ Combos, isCombosReady: true })
            })
            client.getSoloCompetitionSequences({ SoloCompetitionId: Event.Id, Status: 'confirm' }).then((SequncesConfirm_) => {
                client.getSoloCompetitionSequences({ SoloCompetitionId: Event.Id, Status: 'waiting' }).then((SequncesWaiting) => {

                    let SCbyUser = {};

                    // пока мы делаем тут обработку связок по очкам
                    let SequncesConfirm = SequncesConfirm_.map((sequence) => {
                        const { sequence_elements, sequence_options } = parseSEquenceOptions({ sequence })
                        const { JudgeOptions } = sequence;
                        const { scores_element, scores_seq } = getElementsScoresByJudgingOptions({ sequence_elements, JudgeOptions });
                        const elements_scores = getElementsScoresByJudging_arrays({ client, sequence_elements, scores_element, scores_seq, sequence_options })
                        // PerformPoints, element_key

                        let BasicSum = 0;
                        let SequenceSum = 0;
                        for (let i = 0; i < elements_scores.length; i++) {
                            BasicSum += elements_scores[i].Points;
                            SequenceSum += elements_scores[i].SequencePoints;
                        }
                        // Именно так считается связность - делится вот на такую штуку
                        SequenceSum = SequenceSum / (elements_scores.length - 2);

                        return { ...sequence, BasicSum, SequenceSum };

                    })

                    if (SequncesConfirm) {
                        SCbyUser = groupBy(SequncesConfirm, 'UserId');
                    }

                    let SWbyUser = {};
                    if (SequncesWaiting) {
                        SWbyUser = groupBy(SequncesWaiting, 'UserId');
                    }

                    this.setState({
                        SequncesConfirm: {
                            all: SequncesConfirm,
                            byUser: SCbyUser
                        }
                        ,
                        SequncesWaiting: {
                            all: SequncesWaiting,
                            byUser: SWbyUser
                        },
                        isSoloSequencesReady: true
                    })
                })
            })
        }

        this.setState({ isChampionshipReady: false });

        if (Event.Type === 'championship')
            this.loadChampionship(Event).then(
                (Championship) => {
                    this.setState({ isChampionshipReady: true, isEventReady: true, Event, Championship });
                }
            )

        this.reloadDiscriptions();
        this.reloadDiscription();
        this.reloadNewNews();
        this.reloadBets();
        this.reloadStuff();
        this.reloadServerQuests();
    }

    componentDidMount() {
        this.initialLoad();
    }

    reloadEvent = async () => {
        this.setState(initialState);
    }

    componentDidUpdate({ eventsLoaded, iamuser, match }, { isEventReady, isParticipatorsReady, isFollowsReady, isChampionshipReady, isCategorysReady, isCompetitorsCountInCategorysReady }) {

        if (match.params.eventId !== this.props.match.params.eventId) {
            this.reloadEvent().then(() => {
                this.initialLoad();
                this.checkIfAmAdminAsync();
            })
        }

        //подгрузили ивенты
        if (!eventsLoaded && this.props.eventsLoaded && !this.state.isEventReady) {
            this.initialLoad();
            this.checkIfAmAdminAsync();
        }

        // подгрузили мероприятие        
        if (!isEventReady && this.state.isEventReady) {
            this.reloadDiscriptions();
            this.reloadDiscription();
            this.reloadNewNews();
            this.reloadOperations();
            this.reloadParticipators();
            this.reloadParticipatorsData();
            this.reloadFollows();
            this.reloadBets();
            this.reloadStuff();
            this.reloadFizuha();
            this.reloadServerQuests();
            this.checkIfAmAdminAsync();
        }

        // подгрузили фолловеров        
        if (!isFollowsReady && this.state.isFollowsReady) {
            this.checkIfAmFollowAsync();
        }

        // подгрузили партисипаторов        
        if (!isParticipatorsReady && this.state.isParticipatorsReady) {
            this.checkIfAmParticipatorAsync();
        }

        // подгрузили соревнование
        if (!isChampionshipReady && this.state.isChampionshipReady) {
            this.reloadFields();
            this.reloadCategorysAsync();
            this.checkMayIParticipateByFkrAsync();
        }
        if (!isCategorysReady && this.state.isCategorysReady) {
            this.loadCompetitorsCountInCategorysAsync();
        }

        if (!isChampionshipReady && this.state.isChampionshipReady) {
            this.loadCompetitorsAndResultsAsync();
        }

        if (this.props.iamuser && this.state.isCategorysReady && !this.state.isCategorysInfoReady) {
            this.loadCategorysInfoAsync();
        }

        if (this.props.iamuser !== iamuser) {
            this.checkIfAmAdminAsync();
            // this.checkIfAmAdminAsync();
            this.checkIfAmParticipatorAsync();
            if (this.state.isChampionshipReady) {
                this.loadCompetitorsAndResultsAsync();
                this.checkMayIParticipateByFkrAsync();
            }
        }

    }

    loadEvent = () => {
        const { client } = this.props;
        const eventId = this.props.match.params.eventId;
        const Event = client.getEventById(eventId);

        if (!Event) return null;

        const { Settings } = Event;
        if (!Settings) return Event;

        const SettingsObj = JSON.parse(Settings);

        return { ...Event, SettingsObj };
    }

    checkIfAmAdminAsync = () => {
        this.checkIfAmAdmin().then((iAmAdmin) => { this.setState({ iAmAdmin }) })
        this.getMyBets().then((myBets) => { this.setState({ myBets }) })
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать роли
    checkIfAmAdmin = async () => {
        const { iamuser } = this.props;
        const { Event, Championship } = this.state;

        console.log('checkIfAmAdmin', { Event, iamuser });

        if (!iamuser || !Event) return null;

        if (iamuser.myPortalRoles && (iamuser.myPortalRoles.creator || iamuser.myPortalRoles.admin)) {
            return { role: 'admin' };
        }

        if (Event.AuthorId === iamuser.Id) return { role: 'admin' };

        if (!Championship) return null;

        if (Championship.CreatorId === iamuser.Id) return { role: 'admin' };
    }

    // DISCRIPTIONS (оплыты и т.д.) //////////////////////////////////////////
    loadDiscriptions = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const Discriptions = await client.getDiscriptions({ subject: 'event', subjectId: Event.Id });
        return Discriptions;
    }

    reloadDiscriptions = () => {
        this.setState({ isDiscriptionsReady: false });
        this.loadDiscriptions().then(
            (Discriptions) => {
                this.setState({ isDiscriptionsReady: true, Discriptions });
            }
        )
    }

    // DISCRIPTION //////////////////////////////////////////
    loadDiscription = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const Discription = await client.getDiscriptions({ subject: 'event', subjectId: Event.Id, type: 'info' });
        return Discription ? Discription[0] : null;
    }

    reloadDiscription = () => {
        this.setState({ isDiscriptionReady: false });
        this.loadDiscription().then(
            (Discription) => {
                this.setState({ isDiscriptionReady: true, Discription });
            }
        )
    }

    addDiscription = async (body) => {
        const { client } = this.props;
        const { Event } = this.state;
        await client.addDiscription({ body, subject: 'event', subjectId: Event.Id, type: 'info' });
        this.reloadDiscription();
        message.success('Добавлено инфо');
    }

    updateDiscription = async (body) => {
        const { client } = this.props;
        const { Discription } = this.state;
        if (!Discription) return;
        const discriptionId = Discription.Id;
        await client.updateDiscription({ discriptionId, body });
        this.reloadDiscription();
        message.success('Изменения сохранены');
    }

    // BETS /////////////////////////////////////////////

    addUserBet = async ({ Settings }) => {
        const { iamuser, client } = this.props;
        const { Event } = this.state;

        if (!iamuser || !Event) return null;

        await client.addUserBet({ EventId: Event.Id, Settings });
        message.success('Прогноз добавлен');
        this.reloadBets();
    }

    updateUserBet = async ({ BetId, Settings }) => {
        const { iamuser, client } = this.props;
        const { Event } = this.state;

        if (!iamuser || !Event) return null;

        await client.updateUserBet({ BetId, Settings });
        message.success('Прогноз изменён');
        this.reloadBets();
    }

    loadBets = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;

        this.getMyBets().then((myBets) => { this.setState({ myBets }) })
        const Bets = await client.getAllBets({ EventId: Event.Id });
        return Bets;
    }

    reloadBets = () => {
        this.loadBets().then(
            (Bets) => {
                this.setState({ Bets });
            }
        )
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать роли
    getMyBets = async () => {
        const { iamuser, client } = this.props;
        const { Event } = this.state;

        if (!iamuser || !Event) return null;

        const myBets = await client.getUserBet({ EventId: Event.Id });

        console.log('getMyBets', myBets);

        return myBets;
    }

    // NEWNEWS //////////////////////////////////////////
    loadNewNews = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const NewNews = await client.getNewNews({ subject: 'event', subjectId: Event.Id });
        return NewNews;
    }

    reloadNewNews = () => {
        this.setState({ isNewNewsReady: false });
        this.loadNewNews().then(
            (NewNews) => {
                this.setState({ isNewNewsReady: true, NewNews });
            }
        )
    }

    addNewNews = (data) => {
        this.setState({ isNewNewsReady: false });
        const { client } = this.props;
        const { Event } = this.state;
        client.addNewNews(
            {
                subject: 'event', subjectId: Event.Id, ...data
            }
        ).then(() => {
            this.reloadNewNews();
        })
    }

    // OPERATIONS //////////////////////////////////////

    loadOperations = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const Operations = await client.getOperationsByEvent(Event.Id);
        return Operations;
    }

    reloadOperations = () => {
        this.setState({ isOperationsReady: false });
        this.loadOperations().then(
            (Operations) => {
                this.setState({ isOperationsReady: true, Operations });
            }
        )
    }

    releaseOperation = async (Code) => {

        const { client } = this.props;
        await client.releaseOperation(Code);

        this.reloadOperations();
    }

    addOperation = async (Data) => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;

        const {
            type,
            persons,
            text,
            payload,
            medals
        } = Data;

        let AllowedTo = null;
        if (type === 'reward') {
            AllowedTo = JSON.stringify(persons)
        }

        let Settings_ = {};
        for (let i = 0; i < Object.keys(payload).length; i++) {
            const key1 = Object.keys(payload)[i];
            for (let j = 0; j < Object.keys(payload[key1]).length; j++) {
                const key2 = Object.keys(payload[key1])[j];
                if (payload[key1][key2] && payload[key1][key2] !== 0 && payload[key1][key2] !== '0') {
                    if (!Settings_[key1]) Settings_[key1] = {}
                    Settings_[key1][key2] = payload[key1][key2];
                }
            }
        }

        if (type === 'medals') {
            const { medalName, medalPlace } = medals;
            const medalPlace_ = Number(medalPlace);
            Settings_.item = {
                [medalName]:
                {
                    Modyfied: { EventId: Event.Id }
                }
            }
            Settings_.medal = {
                place: medalPlace_
            }
        }

        const Settings = JSON.stringify(Settings_);
        const Discription = text;


        await client.addOperation({ EventId: Event.Id, Type: type, Settings, AllowedTo, Discription });
        this.reloadOperations();
    }


    // PARTICIPATORS //////////////////////////////////////////
    loadParticipators = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const Participators = await client.getParticipators({ EventId: Event.Id });
        return Participators;
    }

    reloadParticipators = () => {
        this.setState({ isParticipatorsReady: false });
        this.loadParticipators().then(
            (Participators) => {
                this.setState({ isParticipatorsReady: true, Participators });
            }
        )
    }

    addParticipator = (UserId, participatorData = null) => {
        this.setState({ isParticipatorsReady: false });
        const { client } = this.props;
        const { Event } = this.state;
        client.addParticipator(
            {
                EventId: Event.Id, UserId, Settings: participatorData || '{}'
            }
        ).then(() => {
            this.reloadParticipators();
            this.props.renewParticipation();
        })
    }

    deleteParticipator = (ParticipatorId) => {
        this.setState({ isParticipatorsReady: false });
        const { client } = this.props;
        client.deleteParticipator({ ParticipatorId }).then(() => {
            this.reloadParticipators();
            this.reloadParticipatorsData();
            this.props.renewParticipation();
        })
    }

    checkIfAmParticipatorAsync = () => {
        this.checkIfAmParticipator().then((iAmParticipator) => { this.setState({ iAmParticipator }) })
    }

    // потом это будет асинхронная хрен, т.к. будем подгружать участник ли ты
    checkIfAmParticipator = async () => {
        const { iamuser } = this.props;
        const { Event, Participators } = this.state;

        if (!iamuser || !Event || !Participators) return null;

        // по хорошему еще должны быть 

        // ищем среди партисипаторов iamuser.Id
        const participator_ = Participators.find(x => x.UserId === iamuser.Id);
        if (participator_) return participator_;

        return null;
    }

    // FKR ACCRES 

    checkMayIParticipateByFkrAsync = () => {
        this.checkMayIParticipateByFkr().then((mayIParticipateByFkr) => { this.setState({ mayIParticipateByFkr }) })
    }

    checkMayIParticipateByFkr = async () => {
        const { iamuser, client } = this.props;
        const { Event } = this.state;

        if (!iamuser || !Event) return null;

        const result = await client.mayUserParticipateByFkr({ EventId: Event.Id, UserId: iamuser.Id });

        if (!result) return null;

        return result.MayParticipate;

    }

    // PARTICIPATORS DATA //////////////////////////////////////////
    loadParticipatorsData = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const ParticipatorsData = await client.getParticipatorData({ EventId: Event.Id });

        let ParticipatorsDataResult = null

        if (ParticipatorsData) {
            ParticipatorsDataResult = groupBy(ParticipatorsData, 'UserId');
        }

        return ParticipatorsDataResult;
    }

    reloadParticipatorsData = () => {
        this.setState({ isParticipatorsDataReady: false });
        this.loadParticipatorsData().then(
            (ParticipatorsData) => {
                this.setState({ isParticipatorsDataReady: true, ParticipatorsData });
            }
        )
    }

    addParticipatorsData = async (participatorData) => {
        const { client } = this.props;

        await client.addParticipatorData(participatorData);

        const { EventId, UserId, Type } = participatorData;

        const newParticipatorData = await client.getParticipatorData({ EventId, UserId, Type });

        const { ParticipatorsData } = this.state;

        // берём последнюю и добавляем
        if (newParticipatorData && newParticipatorData.length && newParticipatorData[0]) {

            // мы тут точно что-то добавим
            client.addNotification({
                UserId,
                Body: client.NTT_participatorData(this.state.Event, Type)
            })

            if (!ParticipatorsData) {
                this.setState({
                    ParticipatorsData: {
                        [UserId]: [
                            newParticipatorData[0]
                        ]
                    }
                })
                return;
            }
            if (!ParticipatorsData[UserId]) {
                this.setState({
                    ParticipatorsData: {
                        ...ParticipatorsData,
                        [UserId]: [
                            newParticipatorData[0]
                        ]
                    }
                })
                return;
            }
            this.setState({
                ParticipatorsData: {
                    ...ParticipatorsData,
                    [UserId]: [
                        ...ParticipatorsData[UserId],
                        newParticipatorData[0]
                    ]
                }
            })
            return;
        }
    }

    deleteParticipatorData = async (UserId, ParticipatorDataId) => {
        const { client } = this.props;

        await client.deleteParticipatorData(ParticipatorDataId);

        const { ParticipatorsData } = this.state;

        if (ParticipatorsData && ParticipatorsData[UserId] && ParticipatorsData[UserId].length) {

            let newUserData = [];

            for (let i = 0; i < ParticipatorsData[UserId].length; i++) {
                const { Id } = ParticipatorsData[UserId][i];
                if (String(Id) === String(ParticipatorDataId)) {

                } else {
                    newUserData.push({ ...ParticipatorsData[UserId][i] })
                }
            }

            this.setState({
                ParticipatorsData: {
                    ...ParticipatorsData,
                    [UserId]: [...newUserData]
                }
            })
        }
    }

    // STUFF //////////////////////////////////////////
    loadStuff = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const Stuff_ = await client.getEventRoles(Event.Id);

        let Stuff = {
            contacts: [{ Id: Event.AuthorId }]
        }

        if (!Stuff_) return Stuff;
        let contacts = [];
        for (let i = 0; i < Stuff_.length; i++) {
            const { Role, UserId, Id } = Stuff_[i];
            if (Role === 'contact') {
                contacts.push({ Id: UserId, RoleId: Id })
            }
        }

        if (contacts.length > 0) Stuff.contacts = contacts;

        return Stuff;
    }

    reloadStuff = () => {
        this.setState({ isStuffReady: false });
        this.loadStuff().then(
            (Stuff) => {
                this.setState({ isStuffReady: true, Stuff });
            }
        )
    }

    // FIZUHA
    loadFizuha = async () => {
        const { Event } = this.state;
        if (!Event) return null;

        const { SettingsObj } = Event;
        if (!(SettingsObj && SettingsObj.SpecialMain && SettingsObj.SpecialMain.FizChallenge)) {
            return null;
        }

        const { client } = this.props;
        const Fizuha = await client.getFizuhaScores();

        const { UsersFizuha, SchoolFizuha, GroupFizuha } = Fizuha;
        let AllFizuha = { pre: 0, otz: 0 };

        const schoolRecords = Object.values(SchoolFizuha);
        for (let i = 0; i < schoolRecords.length; i++) {
            const { pre, otz } = schoolRecords[i];
            AllFizuha.pre += pre;
            AllFizuha.otz += otz;
        }


        return {
            UsersFizuha,
            SchoolFizuha,
            GroupFizuha,
            AllFizuha
        };
    }

    reloadFizuha = () => {
        this.setState({ isFizuhaReady: false });
        this.loadFizuha().then(
            (Fizuha) => {
                this.setState({ isFizuhaReady: true, Fizuha });
            }
        )
    }

    // это добавить stuff именно как юзера
    addStuff = ({ UserId, Role }) => {
        this.setState({ isStuffReady: false });
        const { client } = this.props;
        const { Event } = this.state;
        // ТУТ ХЗ ЧТО И КАК
        client.addEventRoles(
            {
                EventId: Event.Id,
                UserId,
                Role
            }
        ).then(() => {
            this.reloadStuff();
            message.info(`Добавлен персонал мероприятия ${Role}`);
        })
    }

    deleteStuff = (RoleId) => {
        this.setState({ isStuffReady: false });
        const { client } = this.props;
        // ТУТ ХЗ ЧТО И КАК
        client.removeRole(RoleId).then(() => {
            this.reloadStuff();
        })
    }

    // SERVER QUESTS (Это просто те квесты, которые приходят с сервера для person) /////
    loadServerQuests = async () => {
        const { client } = this.props;
        const { Event } = this.state;
        if (!Event) return;
        const ServerQuests = await client.getQuests({ PlaceType: 'event', PlaceId: Event.Id });
        return ServerQuests;
    }

    reloadServerQuests = () => {
        this.setState({ isServerQuestsReady: false });
        this.loadServerQuests().then(
            (ServerQuests) => {
                this.setState({ isServerQuestsReady: true, ServerQuests });
            }
        )
    }

    // checkIfAmFollowAsync = () => {
    //     this.checkIfAmFollow().then((iAmFollow) => { this.setState({ iAmFollow }) })
    // }

    // checkIfAmFollow = async () => {
    //     const { iamuser } = this.props;
    //     const { Event, Follows } = this.state;

    //     console.log('checkIfAmFollow', Follows);

    //     if (!iamuser || !Event || !Follows) return null;

    //     // ищем среди партисипаторов iamuser.Id
    //     const follow_ = Follows.find(x => ((x.SubjectId === iamuser.Id) && (x.Subject === 'user')));
    //     if (follow_) return follow_;

    //     return null;
    // }


    // FOLLOWS //////////////////////////////////////////
    loadFollows = async () => {
        const { Event } = this.state;
        if (!Event) return;
        const { client } = this.props;
        const Follows = await client.getFollows({ ToSubject: 'event', ToSubjectId: Event.Id });
        return Follows;
    }

    reloadFollows = () => {
        this.setState({ isFollowsReady: false });
        this.loadFollows().then(
            (Follows) => {
                this.setState({ isFollowsReady: true, Follows });
            }
        )
    }

    // это добавить follow именно как юзера
    addFollow = () => {
        this.setState({ isFollowsReady: false });
        const { client, renewFollows } = this.props;
        const { Event } = this.state;
        client.addFollow(
            {
                Subject: 'user',
                ToSubject: 'event',
                ToSubjectId: Event.Id
            }
        ).then(() => {
            this.reloadFollows();
            renewFollows();
            message.info('Вы будете видеть новости по данному мероприятию');
        })
    }

    deleteFollow = (FollowId) => {
        this.setState({ isFollowsReady: false });
        const { client, renewFollows } = this.props;
        client.deleteFollow({ FollowId }).then(() => {
            this.reloadFollows();
            renewFollows();
        })
    }

    checkIfAmFollowAsync = () => {
        this.checkIfAmFollow().then((iAmFollow) => { this.setState({ iAmFollow }) })
    }

    checkIfAmFollow = async () => {
        const { iamuser } = this.props;
        const { Event, Follows } = this.state;

        console.log('checkIfAmFollow', Follows);

        if (!iamuser || !Event || !Follows) return null;

        // ищем среди партисипаторов iamuser.Id
        const follow_ = Follows.find(x => ((x.SubjectId === iamuser.Id) && (x.Subject === 'user')));
        if (follow_) return follow_;

        return null;
    }


    // CHAMPIONSHIP //////////////////////////////////////////
    loadChampionship = async (Event, forced = false) => {

        const { ChampionshipId } = Event;
        const { client } = this.props;

        const Championship = await client.getChampionshipFromServer(ChampionshipId, forced);
        const CompetitorsCount = await this.countStatistics(ChampionshipId);

        return { ...Championship, CompetitorsCount };
    }

    reloadChampionship = async () => {
        const { Event } = this.state;
        this.loadChampionship(Event, true).then(
            (Championship) => {
                this.setState({ Championship });
            }
        )
    }

    countStatistics = async (ChampionshipId) => {
        const { client } = this.props;
        const Statistics = await client.getCompetittionStatistics(ChampionshipId);
        if (!Statistics || !Statistics.length) return null;

        const result = Statistics.reduce((sum, item) => { return sum + item.Count }, 0);
        return result;
    }

    reloadFields = async () => {
        const { client } = this.props;
        const { Event } = this.state;
        const fields = await client.getFields();
        console.log(fields);
        if (!fields || !Event) return this.setState({ isThereTable: false });

        const fields_ = fields.reduce((memo, field) => {
            if (field.EventId.toString() === Event.Id.toString()) {
                if (field.IsOn) {
                    return [...memo, field];
                }
                return memo;
            }
            return memo;
        }, [])

        const isThereTable = fields_.length > 0 ? fields_ : null;

        this.setState({ isThereTable });
    }

    // CATEGORYS /////////

    loadCategorys = async () => {
        const { Event } = this.state;
        const { ChampionshipId } = Event;
        const { client } = this.props;

        const Categorys = await client.getCategorysFromServer(ChampionshipId);

        return Categorys;
    }

    loadCompetitorsCountInCategorysAsync = () => {
        this.setState({ isCompetitorsCountInCategorysReady: false });
        this.loadCompetitorsCountInCategorys().then(
            (competitorsCountInCategorys) => {
                this.setState({ isCompetitorsCountInCategorysReady: true, competitorsCountInCategorys });
            }
        )
    }

    loadCompetitorsCountInCategorys = async () => {
        const { Event } = this.state;
        const { ChampionshipId } = Event;
        const { client } = this.props;

        const competitorsCountInCategorys = await client.getCompetitorsCountInCategorys(ChampionshipId);

        return competitorsCountInCategorys || [];
    }

    loadCategorysInfoAsync = () => {
        this.loadCategorysInfo().then(
            (categorysInfo) => {
                if (categorysInfo)
                    this.setState({ isCategorysInfoReady: true, categorysInfo });
            }
        )
    }

    // категори инфо - это то, сколько человек относительно школы, группы и т.д. в ней участвуют
    loadCategorysInfo = async () => {
        const { Event, Categorys } = this.state;
        const { ChampionshipId } = Event;
        const { client, iamuser } = this.props;

        if (!iamuser) return null;

        const {
            GroupIamInId,
            GroupId,
            SchoolId,
            Id
        } = iamuser;

        let categorysInfo_ = await client.getCategoryInfo(
            null,
            Id,
            SchoolId,
            GroupIamInId,
            GroupId,
            ChampionshipId
        );

        if (!categorysInfo_) return null;

        const categorysInfo = {};

        for (let i = 0; i < Categorys.length; i++) {
            const catInfo = categorysInfo_.find(x => x.CategoryId === Categorys[i].Id);
            if (catInfo)
                categorysInfo[Categorys[i].Id] = catInfo;
        }

        return categorysInfo;

    }

    // мы апдейтим категорию и перегружаем её
    // может быть тут надо добавить isCategorysInfoReady: false
    reloadCategorysAsync = (NoState = false) => {
        if (!NoState)
            this.setState({ isCategorysReady: false });
        this.loadCategorys().then(
            (Categorys) => {
                let CategorysAsObjcet = {};
                for (let i = 0; i < Categorys.length; i++) {
                    CategorysAsObjcet[Categorys[i].Id] = Categorys[i];
                }
                if (!NoState) {
                    this.setState({ isCategorysReady: true, Categorys, CategorysAsObjcet });
                } else {
                    this.setState({ Categorys, CategorysAsObjcet });
                }
            }
        )
        if (!NoState)
            this.checkIfAmAdminAsync();
    }

    // async updateCategory(competition_id, categoryId, elderCircle, timeStart = undefined, isCircled = undefined, sessionId = this.lastSessionId, FieldId = undefined) {

    // мы апдейтим категорию и перегружаем её
    updateCategory = () => {

    }

    /*

                Competitors: [],
            CompetitorsAsObject: {},
            isCompetitorsReady: false,

            */

    // COMPETITORS ///////////////////////////////////////////


    //   async deleteCompetitor(competition_id, userId, categoryId, sessionId = this.lastSessionId) {

    //   async updateCompetitor(competition_id, userId, categoryId, jrebiy, sessionId = this.lastSessionId) {

    addCompetitor = (data) => {
        this.setState({ isCompetitorsReady: false });
        const { client } = this.props;
        client.addCompetitor(data).then(() => {
            this.loadCompetitorsAndResultsAsync();
            this.loadCompetitorsCountInCategorysAsync();
            this.loadCategorysInfoAsync();
            this.props.renewCompetitor();
        })
    }

    deleteCompetitor = (CompetitorId) => {
        this.setState({ isCompetitorsReady: false });
        const { client } = this.props;
        client.deleteCompetitorNew({ CompetitorId }).then(() => {
            this.loadCompetitorsAndResultsAsync();
            this.loadCompetitorsCountInCategorysAsync();
            this.loadCategorysInfoAsync();
            this.props.renewCompetitor();
        })
    }

    reloadCompetitors = () => {
        this.setState({ isCompetitorsReady: false });
        this.loadCompetitorsAndResultsAsync();
        this.loadCompetitorsCountInCategorysAsync();
        this.loadCategorysInfoAsync();
        this.props.renewCompetitor();
    }

    loadCompetitorsAndResultsAsync = () => {
        this.setState({ isCompetitorsReady: false });
        this.loadCompetitorsAndResults().then(
            (competitorsAndResults) => {
                this.setState({ isCompetitorsReady: true, ...competitorsAndResults });
            }
        )
    }

    loadCompetitorsAndResults = async () => {
        const { Event, Championship } = this.state;
        if (!Event) return;
        const { ChampionshipId } = Event;
        const { ShowResults } = Championship;
        const { client, iamuser } = this.props;

        // ВОТ ЭТО БУДЕТ ВАЖНАЯ ШТУКА!
        const mainCategorys = null;

        const Competitors = await client.getCompetitors(ChampionshipId);
        console.log('loadCompetitorsAndResults', Competitors)
        if (!Competitors || !Competitors.length) return {
            Competitors: [],
            CompetitorsAsObject: {},
            GroupCount: 0,
            iAmCompetitor: null
        }

        let CompetitorsAsObject = {};

        let allCompetitorsObject = {};
        let schoolsPlaces = {};
        let groupPlaces = {};
        let iAmCompetitor = null;
        let GroupCount = 0;

        for (let i = 0; i < Competitors.length; i++) {

            const competitor = Competitors[i];

            CompetitorsAsObject[competitor.Id] = { ...competitor };

            if (iamuser && iamuser.Id === competitor.Id) {
                iAmCompetitor = { ...competitor };
            }
            if (iamuser && iamuser.GroupId && String(competitor.GroupIamInId) === String(iamuser.GroupId)) {
                GroupCount++;
            }

            // если не показываются результаты, нам пока не нужна оставшаяся часть   
            if (!ShowResults) continue;

            if (allCompetitorsObject[competitor.CategoryId]) {
                allCompetitorsObject[competitor.CategoryId].push({ ...competitor });
            } else
                allCompetitorsObject[competitor.CategoryId] = [{ ...competitor }]

            if (!schoolsPlaces[competitor.SchoolId]) {
                schoolsPlaces[competitor.SchoolId] = {
                    SchoolId: competitor.SchoolId,
                    Gold: 0,
                    Silver: 0,
                    Bronze: 0,
                    Score: 0
                }
            }

            if (!mainCategorys || mainCategorys[competitor.CategoryId]) {
                if (competitor.Place === 1) schoolsPlaces[competitor.SchoolId].Gold++;
                if (competitor.Place === 2) schoolsPlaces[competitor.SchoolId].Silver++;
                if (competitor.Place === 3) schoolsPlaces[competitor.SchoolId].Bronze++;
            }

            if (competitor.GroupIamInId) {
                if (!groupPlaces[competitor.GroupIamInId]) {
                    groupPlaces[competitor.GroupIamInId] = {
                        GroupIamInId: competitor.GroupIamInId,
                        GroupId: competitor.GroupIamInId,
                        Gold: 0,
                        Silver: 0,
                        Bronze: 0,
                        Score: 0
                    }
                }

                if (!mainCategorys || mainCategorys[competitor.CategoryId]) {
                    if (competitor.Place === 1) groupPlaces[competitor.GroupIamInId].Gold++;
                    if (competitor.Place === 2) groupPlaces[competitor.GroupIamInId].Silver++;
                    if (competitor.Place === 3) groupPlaces[competitor.GroupIamInId].Bronze++;
                }
            }
        }

        if (!ShowResults) {
            return {
                Competitors,
                CompetitorsAsObject,
                iAmCompetitor,
                GroupCount
            }
        } else {

            let schoolsPlaces_ = [];
            let groupPlaces_ = [];

            Object.keys(schoolsPlaces).reduce((key, index) => {
                const item = schoolsPlaces[index];
                return schoolsPlaces_.push({ ...item, Score: item.Gold * 3 + item.Bronze * 1 + item.Silver * 2 });
            }, {});

            Object.keys(groupPlaces).reduce((key, index) => {
                const item = groupPlaces[index];
                return groupPlaces_.push({ ...item, Score: item.Gold * 3 + item.Bronze * 1 + item.Silver * 2 });
            }, {});

            return {
                Competitors,
                CompetitorsAsObject,
                Results: {
                    allCompetitorsByCategorys: allCompetitorsObject,
                    schoolsPlaces: sortBy(schoolsPlaces_, x => { return x.Score }).reverse(),
                    groupPlaces: sortBy(groupPlaces_, x => { return x.Score }).reverse()
                },
                isResultsReady: true,
                iAmCompetitor,
                GroupCount
            }
        }
    }

    getCategory = (categoryId) => {
        const { Categorys, isCategorysReady } = this.state;
        if (!isCategorysReady || !Categorys) return null;

        const category = Categorys.find(x => x.Id === categoryId);
        return category;
    }

    setBackground = (clear = false) => {

        if (clear) {
            const back = document.getElementById('main-backimage-id');
            if (back)
                back.style.background = 'none';
            return;
        }

        const { Event } = this.state;
        if (!Event) return;

        // прокидка фона
        const back = document.getElementById('main-backimage-id');
        if (back) {
            const imageUrl = this.props.client.getEventsUrl() + Event.Id + '.png';
            const style = {
                background: `url(${imageUrl})`
            }
            back.style.background = style.background;
            return;
        }
    }

    redirect = (link) => {
        this.props.history.push(link);
    }

    // ВСЯКАЯ УПРАВЛЕНЧЕСКАЯ ХЕРНЯ ////////////////////////

    // это изменить основные данные мероприятия - типа оно временно, не временно и т.д.
    uploadEventMain = async (EventMain) => {
        const { client } = this.props;
        const { Event } = this.state;

        const keys = Object.keys(EventMain);
        let sendedObj = {};

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (key === 'IsPreliminarily') { sendedObj[key] = EventMain.IsPreliminarily ? 1 : 0; continue; }
            if (key === 'StartPreliminarilyDate') { sendedObj[key] = EventMain.StartPreliminarilyDate || null; continue; }
            if (key === 'EndPreliminarilyDate') { sendedObj[key] = EventMain.EndPreliminarilyDate || null; continue; }
            if (key === 'Adress') { sendedObj[key] = (EventMain.Adress && EventMain.Adress !== '') ? EventMain.Adress : null; continue; }

            sendedObj[key] = EventMain[key];
        }

        const EventId = Event.Id;

        await client.updateEventMain(
            sendedObj,
            EventId
        )

        // теперь надо его же получить с сервака
        const newedEvent = await client.getEventData({ EventId });
        if (newedEvent && newedEvent[0] && newedEvent[0].Id === EventId) {
            // по идее он поменяет все крому SettingsObj, который не трогается
            this.setState({
                Event: { ...this.state.Event, ...newedEvent[0] }
            })
            message.success('Данные изменены');
            return;
        }
        message.warn('Что то пошло не так');
    }

    // это изменить основные данные мероприятия - типа оно временно, не временно и т.д.
    uploadEventPayementsData = async (PaymentData) => {
        const { client } = this.props;
        const { Event } = this.state;

        const EventId = Event.Id;
        let SettingsObj = { ...Event.SettingsObj } || {};
        SettingsObj.PaymentMain = { PaymentData };

        const Settings = JSON.stringify(SettingsObj);
        await client.updateEventSettings(
            Settings,
            EventId
        )

        // теперь надо его же получить с сервака
        const newedEvent = await client.getEventData({ EventId });
        if (newedEvent && newedEvent[0] && newedEvent[0].Id === EventId) {
            const SettingsObj = JSON.parse(newedEvent[0].Settings);

            this.setState({
                Event: { ...this.state.Event, ...newedEvent[0], SettingsObj }
            })
            message.success('Данные изменены');
            return;
        }
        message.warn('Что то пошло не так');
    }

    // это изменить основные данные мероприятия - типа оно временно, не временно и т.д.
    uploadEventSpacial = async (SpecialMainData = {}) => {
        const { client } = this.props;
        const { Event } = this.state;

        const EventId = Event.Id;
        let SettingsObj = { ...Event.SettingsObj } || {};
        SettingsObj.SpecialMain = { ...SpecialMainData };

        const Settings = JSON.stringify(SettingsObj);
        await client.updateEventSettings(
            Settings,
            EventId
        )

        // теперь надо его же получить с сервака
        const newedEvent = await client.getEventData({ EventId });
        if (newedEvent && newedEvent[0] && newedEvent[0].Id === EventId) {
            const SettingsObj = JSON.parse(newedEvent[0].Settings);

            this.setState({
                Event: { ...this.state.Event, ...newedEvent[0], SettingsObj }
            })
            message.success('Данные изменены');
            return;
        }
        message.warn('Что то пошло не так');
    }

    publishEvent = async () => {
        const { client } = this.props;
        const { Event } = this.state;

        const EventId = Event.Id;

        await client.updateEventMain(
            { NotAnnounced: 0 },
            EventId
        )

        // теперь надо его же получить с сервака
        const newedEvent = await client.getEventData({ EventId });
        if (newedEvent && newedEvent[0] && newedEvent[0].Id === EventId) {
            // по идее он поменяет все крому SettingsObj, который не трогается
            this.setState({
                Event: { ...this.state.Event, ...newedEvent[0] }
            })
            message.success('Мероприятие опубликовано!');
            return;
        }
        message.warn('Что то пошло не так');
    }

    openRegistration = () => { this.changeRegistrationEvent({ RegistrationOpened: 1, RegistrationClosed: 0 }); }
    cancelOpenRegistration = () => { this.changeRegistrationEvent({ RegistrationOpened: 0, RegistrationClosed: 0 }); }
    closeRegistration = () => { this.changeRegistrationEvent({ RegistrationOpened: 1, RegistrationClosed: 1 }); }
    cancelCloseRegistration = () => { this.changeRegistrationEvent({ RegistrationOpened: 1, RegistrationClosed: 0 }); }

    changeRegistrationEvent = async (RegistrationObj) => {
        const { client } = this.props;
        const { Event } = this.state;

        const EventId = Event.Id;

        await client.updateEventMain(
            RegistrationObj,
            EventId
        )

        // теперь надо его же получить с сервака
        const newedEvent = await client.getEventData({ EventId });
        if (newedEvent && newedEvent[0] && newedEvent[0].Id === EventId) {
            // по идее он поменяет все крому SettingsObj, который не трогается
            this.setState({
                Event: { ...this.state.Event, ...newedEvent[0] }
            })
            message.success('Статус мероприятия изменен');
            return;
        }
        message.warn('Что то пошло не так');
    }


    render() {
        const {
            props: {
                children,
                location,
                client,
                iamuser,
                showInfoModal,
                closeInfoModal,
                groupsLoaded,
                isMobile
            },
            state: {
                Event,
                isEventReady,
                Stuff,
                isStuffReady,
                Championship,
                isChampionshipReady,
                Categorys,
                CategorysAsObjcet,
                isCategorysReady,
                Competitors,
                CompetitorsAsObject,
                isCompetitorsReady,
                competitorsCountInCategorys,
                isCompetitorsCountInCategorysReady,
                categorysInfo,
                isCategorysInfoReady,
                Results,
                isResultsReady,
                Discription,
                isDiscriptionReady,

                isThereTable,

                Operations,
                isOperationsReady,

                Discriptions,
                isDiscriptionsReady,

                Combos, 
                isCombosReady,
                Rounds,
                isRoundsReady,
                SequncesConfirm,
                SequncesWaiting,
                isSoloSequencesReady,

                NewNews,
                isNewNewsReady,

                Participators,
                isParticipatorsReady,
                ParticipatorsData,
                isParticipatorsDataReady,

                Follows,
                isFollowsReady,

                GroupCount,
                Bets,

                ServerQuests,
                isServerQuestsReady,

                iAmFollow,
                iAmParticipator,
                iAmCompetitor,
                iAmAdmin,
                iAmJudge,
                mayIParticipateByFkr,

                Fizuha,
                isFizuhaReady,

                myBets
            },
            redirect,
            getCategory,
            reloadCategorysAsync,
            reloadDiscription,

            reloadStuff,
            addStuff,
            deleteStuff,

            reloadOperations,
            releaseOperation,
            addOperation,

            reloadParticipators,
            addParticipator,
            deleteParticipator,
            checkMayIParticipateByNecessery,
            addParticipatorsData,
            deleteParticipatorData,

            addCompetitor,
            deleteCompetitor,
            reloadCompetitors,

            addFollow,
            deleteFollow,

            reloadChampionship,

            addDiscription,
            updateDiscription,

            uploadEventMain,
            uploadEventPayementsData,
            uploadEventSpacial,

            addNewNews,

            reloadServerQuests,

            isThereAdmissions,
            isTherePayment,
            paymentNormalize,
            howManyAwaitForParticipatorToday,
            getParticipatorsSelectName,

            publishEvent,
            openRegistration,
            cancelOpenRegistration,
            closeRegistration,
            cancelCloseRegistration,

            addUserBet,
            updateUserBet,

            reloadFizuha,

            eventStatus,
            setBackground
        } = this;

        return (
            <context.Provider value={{
                Event,
                isEventReady,

                Stuff,
                isStuffReady,
                reloadStuff,
                addStuff,
                deleteStuff,

                Operations,
                isOperationsReady,
                reloadOperations,
                releaseOperation,
                addOperation,

                Championship,
                isChampionshipReady,
                Categorys,
                CategorysAsObjcet,
                isCategorysReady,
                categorysInfo,
                isCategorysInfoReady,
                Results,
                isResultsReady,

                reloadCategorys: reloadCategorysAsync,

                isThereTable,

                Competitors,
                CompetitorsAsObject,
                isCompetitorsReady,
                competitorsCountInCategorys,
                isCompetitorsCountInCategorysReady,
                addCompetitor,
                deleteCompetitor,
                reloadCompetitors,

                GroupCount,

                Combos, 
                isCombosReady,
                Rounds,
                isRoundsReady,
                SequncesConfirm,
                SequncesWaiting,
                isSoloSequencesReady,

                Participators,
                isParticipatorsReady,
                addParticipator,
                reloadParticipators,
                deleteParticipator,
                checkMayIParticipateByNecessery,

                Follows,
                isFollowsReady,
                addFollow,
                deleteFollow,

                reloadChampionship,

                ParticipatorsData,
                isParticipatorsDataReady,
                addParticipatorsData, // это когда администратор выставляет плату, посещение и т.д.
                deleteParticipatorData,

                Discription,
                isDiscriptionReady,
                addDiscription,
                updateDiscription,
                reloadDiscription,

                Discriptions,
                isDiscriptionsReady,

                uploadEventMain,
                uploadEventPayementsData,
                uploadEventSpacial,
                publishEvent,
                openRegistration,
                cancelOpenRegistration,
                closeRegistration,
                cancelCloseRegistration,

                NewNews,
                isNewNewsReady,
                addNewNews,


                reloadFizuha,
                Fizuha,
                isFizuhaReady,

                isThereAdmissions,
                isTherePayment,
                paymentNormalize,
                howManyAwaitForParticipatorToday,

                getParticipatorsSelectName,

                reloadServerQuests,
                ServerQuests,
                isServerQuestsReady,

                iAmCompetitor,
                iAmParticipator,
                iAmAdmin,
                iAmJudge,
                iAmFollow,
                mayIParticipateByFkr,

                getCategory,
                setBackground,
                eventStatus,

                Bets,
                myBets,
                addUserBet,
                updateUserBet,

                location,
                client,
                iamuser,
                redirect,
                showInfoModal,
                closeInfoModal,
                groupsLoaded,
                isMobile
            }}>
                {children}
            </context.Provider>
        );
    }

    // UTILS

    eventStatus = () => { return eventStatus(this.state.Event) }

    getParticipatorsSelectName = (Participator) => {
        return getParticipatorsSelectName(this.state.Event, this.state.CategorysAsObjcet, Participator);
    }
    // в Event => SettingObj => ParticipatorsMain могут быть NecesserySchool и NecesseryContacts
    // тут надо проверить - проходим ли мы по ним
    checkMayIParticipateByNecessery = () => {
        return { result: 'success' };
    }

    isThereAdmissions = () => {
        const { Event } = this.state;
        if (!Event) return false;
        const { SettingsObj } = Event;
        if (!SettingsObj) return false;
        const { AdmissionMain } = SettingsObj;

        if (AdmissionMain && AdmissionMain.AdmissionData && AdmissionMain.AdmissionData.length) {
            return true;
        }
        return false;
    }

    isTherePayment = () => { return isTherePayment(this.state.Event) }

    // выдаёт по отдельным оплатам { name, header, rows, isAllFree }
    // header     null,             null,             date1,             date2 ...
    // rows
    // row       title   { key, title } || null     payment || null    payment || null
    // row       null        { key, title } || null     payment || null    payment || null

    paymentNormalize = () => { return paymentNormalize(this.state.Event) }

    /*
     {
        allPayment // 2250
        allSale // 500 
        paymentRows = []; // { isAccepted, paymentNow, name, title, content  }
        saleRows = []; // {saleContent, value}
     }
     */

    howManyAwaitForParticipatorToday = (ParticipatorsDataObj) => {

        const { Event } = this.state;

        if (!Event || !Event.SettingsObj) return;

        const { Event: { SettingsObj: { PaymentMain: { PaymentData } } } } = this.state;

        const { participatorsCategorys, participatorsSales } = PaymentData;

        let AllParticipatorsCategorysData = [];

        // по идее надо с сервака получать
        const nowDate = moment().locale('ru');

        for (let i = 0; i < participatorsCategorys.length; i++) {

            const participatorsCategory = participatorsCategorys[i];
            const { name, title, payments, paymentSplit } = participatorsCategory;

            if (name !== 'participation' && !ParticipatorsDataObj[name]) continue;

            let participatorsCategorysData = {
                name,
                title,
                paymentNow: 0,
                isAccepted: false,
                tillDate: null
            }
            // т.е. по данной штуке не нужна оплата, раз нет варианта цен
            if (!payments || payments.length === 0) {
                participatorsCategorysData.isAccepted = true;
                AllParticipatorsCategorysData.push(participatorsCategorysData);
                continue;
            }

            for (let j = 0; j < payments.length; j++) {

                const { date, keysWithPayment, oncePayment } = payments[j];
                const momentDate = moment(date, 'DD.MM.YYYY');
                const momentDateAdded = moment(date, 'DD.MM.YYYY').add(1, 'day');
                // если мы уже ушли за эту дату
                if (nowDate.isAfter(momentDateAdded)) continue;

                participatorsCategorysData.tillDate = momentDate;

                if (!paymentSplit) {
                    participatorsCategorysData.paymentNow = oncePayment;
                } else {
                    // берём выбранную нами опцию по данной оплате
                    const ourOption = ParticipatorsDataObj[name];
                    if (keysWithPayment[ourOption]) {
                        participatorsCategorysData.paymentNow = Number(keysWithPayment[ourOption]);
                    }
                }
                participatorsCategorysData.isAccepted = true;
                AllParticipatorsCategorysData.push(participatorsCategorysData);
                break;
            }
            // мы прочесали все оплаты, и не нашли актуальную (проооооебали)
            if (!participatorsCategorysData.tillDate) {
                participatorsCategorysData.isAccepted = false;
                AllParticipatorsCategorysData.push(participatorsCategorysData);
            }
        }

        const allPayment = AllParticipatorsCategorysData.reduce((payment, { paymentNow }) => { return payment + Number(paymentNow) }, 0)

        let sales = [];
        //теперь парсим
        if (participatorsSales && participatorsSales.length > 0) {
            for (let i = 0; i < participatorsSales.length; i++) {
                let sale = [];
                const { options, value } = participatorsSales[i];
                if (!options[0] || !value) continue;

                for (let j = 0; j < options.length; j++) {
                    if (!options[j]) continue;
                    const saleSplit = options[j].split('@');
                    if (saleSplit.length > 1) {
                        const ourOption = ParticipatorsDataObj[saleSplit[0]];
                        if (ourOption && ourOption === saleSplit[1]) {
                            sale.push({
                                name: saleSplit[0],
                                itemKey: saleSplit[1],
                            })
                        } else {
                            sale = null;
                            break;
                        }
                    } else {
                        continue;
                    }
                }

                if (sale) sales.push({ sale, value });
            }
        }

        const allSale = sales.length > 0 ? sales.reduce((sale, { value }) => { return sale + Number(value) }, 0) : 0;

        let paymentRows = []; // { isAccepted, paymentNow, name, title, paymentContent  }
        let saleRows = []; // {saleContent, value}

        AllParticipatorsCategorysData.map(({ name, title, paymentNow, isAccepted, tillDate }) => {
            paymentRows.push({ isAccepted: true, paymentNow, name, title, paymentContent: '' })
            if (!isAccepted) {
                paymentRows[paymentRows.length - 1].isAccepted = isAccepted;
                return null;
            }
            paymentRows[paymentRows.length - 1].paymentContent = `${title}: ${paymentNow} руб. (до ${moment(tillDate, 'DD.MM.YYYY').locale('ru').format('DD MMM')})`;
            return null;
        });

        if (sales && sales.length > 0) {
            sales.map(({ sale, value }, index) => {
                let saleContent = sale.reduce((saleConten_, { name, itemKey }, index) => {
                    let optionTitle = '';
                    let titleName = '';
                    const participatorsCategory = participatorsCategorys.find(x => x.name === name);
                    if (participatorsCategory) {
                        titleName = participatorsCategory.title;
                        const option = participatorsCategory.options.find(x => x.key.toString() === itemKey.toString());
                        if (option) optionTitle = option.title;
                    }
                    return saleConten_ + `${titleName} / ${optionTitle} ${index < sale.length - 1 ? '+' : '='} `
                }, '');

                saleRows.push({ saleContent, value });
                return null;
            })
        }

        return {
            allPayment,
            allSale,
            paymentRows,
            saleRows
        }

    }


}

export default withCapoeiraSportState('iamuser, client, isMobile, eventsLoaded, groupsLoaded, showInfoModal, closeInfoModal, renewFollows, renewParticipation, renewCompetitor')(EventState);


// определяет статус мероприятия исходя из настроек и текущей даты
// this.eventStatuses = {
//     'undef': { title: 'не определено', text: 'Нет возможности определить статус'},
//     'not_published': { title: 'не опубликовано', text: 'Мероприятие не опубликовано, его видит только администрация'},
//     'is_preliminarily': { title: 'предварительно', text: 'Мероприятие опубликовано с предварительными датами, регистрация не доступна'},
//     'registration_not_open': { title: 'ожидает регистрации', text: 'Мероприятие ожидает открытия регистрации'},
//     'registration_open': { title: 'идет регистрация', text: 'Регистрация официально открыта'},
//     'registration_closed': { title: 'регистрация окончена', text: 'Регистрация официально закрыта, свяжитесь с администрацией, если хотите участвовать'},
//     'past': { title: 'окончено', text: 'Мероприятие завершено'},
//   }
export const eventStatus = (Event) => {
    if (!Event) return 'undef';
    //это старое мероприятие
    if (!Event.Settings) {
        // потому что все старые - закончены
        return 'past';
    }


    if (Event.NotAnnounced) { return 'not_published'; }
    if (Event.IsPreliminarily) { return 'is_preliminarily' }

    // тут уже точно не преждевременно, и есть дата окончания
    if (Event.EndDate && Event.EndDate !== 'null' && Event.EndDate !== 'undefined' && Event.EndDate !== 'invalid data') {
        const nowMoment = moment().locale('ru');
        const endMoment = moment(Event.EndDate, 'DD.MM.YYYY').add(1, 'day').locale('ru');
        if (nowMoment.isAfter(endMoment)) {
            return 'past';
        }
        // полузаглушка такая
        if (Event.Discription && Event.Discription === 'online') { return 'online' }

        if (!Event.RegistrationOpened) { return 'registration_not_open' }
        if (!Event.RegistrationClosed) { return 'registration_open' }
        if (Event.RegistrationClosed) { return 'registration_closed' }
    }

    // тут уже точно не преждевременно, и нет даты окончания
    if (Event.Date && Event.Date !== 'null' && Event.Date !== 'undefined' && Event.Date !== 'invalid data') {
        const nowMoment = moment().locale('ru');
        const endMoment = moment(Event.Date, 'DD.MM.YYYY').add(1, 'day').locale('ru');
        if (nowMoment.isAfter(endMoment)) {
            return 'past';
        }
    }

    // полузаглушка такая
    if (Event.Discription && Event.Discription === 'online') { return 'online' }

    if (!Event.RegistrationOpened) { return 'registration_not_open' }
    if (!Event.RegistrationClosed) { return 'registration_open' }
    if (Event.RegistrationClosed) { return 'registration_closed' }


}

// нам нужно получить названия того, что выбрал спортсмен С УЧЁТОМ КАТЕГОРИИ
// КАТЕГОРИЯ ПОЙДЁТ В ПЕРВУЮ ОЧЕРЕДЬ
export const getParticipatorsSelectName = (Event, CategorysAsObjcet, Participator) => {

    if (!Event) return null;
    const { SettingsObj } = Event;
    if (!SettingsObj) return null;
    const { ParticipatorsMain } = SettingsObj;
    if (!ParticipatorsMain) return null;

    const { ParticipatorsData } = ParticipatorsMain;
    if (!ParticipatorsData) return null;

    if (!Participator) return null;

    let Answer = [];

    if (Event.ChampionshipId) {
        if (!CategorysAsObjcet || !Participator.CategoryId || !CategorysAsObjcet[Participator.CategoryId]) {
            Answer.push({
                name: 'category',
                title: 'категория',
                choose: null,
                chooseName: null
            })
        } else {
            Answer.push({
                name: 'category',
                title: 'категория',
                choose: Participator.CategoryId,
                chooseName: CategorysAsObjcet[Participator.CategoryId].Name
            })
        }
    }

    if (Participator.Settings) {
        // они тут могут быть в разном порядке
        const Settings = JSON.parse(Participator.Settings);
        for (let i = 0; i < ParticipatorsData.length; i++) {
            const EventParticipatorsData = ParticipatorsData[i];
            const { title, name, options } = EventParticipatorsData;
            if (name === 'participation' || !options) continue;
            if (Settings[name]) {
                const option = options.find(x => String(x.key) === String(Settings[name]));
                if (option)
                    Answer.push({
                        name,
                        title,
                        choose: Settings[name],
                        chooseName: option.title
                    })
                else continue;
            }
        }
    }
    return Answer;
}

// выдаёт по отдельным оплатам { name, header, rows, isAllFree }
// header     null,             null,             date1,             date2 ...
// rows
// row       title   { key, title } || null     payment || null    payment || null
// row       null        { key, title } || null     payment || null    payment || null

export const paymentNormalize = (Event) => {

    if (!Event) return false;
    const { SettingsObj } = Event;
    if (!SettingsObj) return false;
    const { PaymentMain } = SettingsObj;
    if (!PaymentMain) return false;
    const { PaymentData } = PaymentMain;
    if (!PaymentData) return false;

    const { participatorsCategorys } = PaymentData;
    if (!participatorsCategorys) return false;

    const addBlankRows = (arr, count) => {
        const arr_ = [...arr];
        for (let i = 0; i < count; i++) {
            arr_.push(null);
        }
        return arr_;
    }

    let result = [];

    for (let i = 0; i < PaymentMain.PaymentData.participatorsCategorys.length; i++) {
        const participatorCategorys = PaymentMain.PaymentData.participatorsCategorys[i];

        let header = [null, null];
        let rows = [];
        let isAllFree = false;

        // берём одну категорию оплаты
        const { name, title, payments, options, paymentSplit } = participatorCategorys;

        const paymentsLength = payments.length || 1;
        if (paymentSplit) {
            for (let u = 0; u < (options.length || 1); u++) {
                rows.push(addBlankRows([], paymentsLength + 2));
            }
        } else {
            rows.push(addBlankRows([], paymentsLength + 2));
        }

        rows[0][0] = title;

        if (payments && payments.length) {
            for (let k = 0; k < payments.length; k++) {
                const { date, keysWithPayment, oncePayment } = payments[k];
                header.push(date);

                if (paymentSplit) {
                    for (let u = 0; u < options.length; u++) {
                        rows[u][1] = { title: options[u].title, key: options[u].key };
                        rows[u][2 + k] = keysWithPayment[options[u].key];
                    }
                } else {
                    rows[0][2 + k] = oncePayment;
                }

            }
        } else {
            // раз цен нет, значит всё бесплатно
            header.push(null);
            rows[0][2] = null;
            isAllFree = true;
        }

        result.push({
            name,
            header,
            rows,
            isAllFree
        })

    }
    return result;
}

export const isTherePayment = (Event) => {
    if (!Event) return false;
    const { SettingsObj } = Event;
    if (!SettingsObj) return false;
    const { PaymentMain } = SettingsObj;
    if (!PaymentMain) return false;
    const { PaymentData } = PaymentMain;
    if (!PaymentData) return false;

    const { participatorsCategorys } = PaymentData;
    if (!participatorsCategorys) return false;

    const paymentNormalize_ = paymentNormalize(Event);
    if (!paymentNormalize_ || paymentNormalize_.length === 0) return false;

    for (let i = 0; i < paymentNormalize_.length; i++) {
        if (paymentNormalize_[i].isAllFree) continue;
        if (paymentNormalize_[i].rows.length === 0) continue;
        for (let j = 0; j < paymentNormalize_[i].rows.length; j++) {
            // нашли хоть одну штуку с оплатой
            const row = paymentNormalize_[i].rows[j];
            for (let z = 2; z < row.length; z++) {
                if (row[z]) return true;
            }
        }
    }
    return false;
}


// ТУТ У НАС ИДЁТ ДЕКОРАТОР, КОТОРЫЙ ПОЗВОЛЯЕТ ПОЛУЧАТЬ ИЗ СТЕЙТА НУЖНЫЕ ДАННОМУ КОМПОНЕНТУ ЗНАЧЕНИЯ

export const withEventState = (withProps = '') => {
    return (
        (Wrapped) =>
            (props) => (
                <context.Consumer>
                    {
                        (context) => (
                            <Wrapped
                                {...props}
                                {...getWhatINeed(withProps, context)}
                            />
                        )
                    }
                </context.Consumer>
            )
    );
};

const getWhatINeed = (withProps, context) => {

    if (withProps === '') return context;

    return withProps.split(',').reduce((memo, value_) => {
        const value = value_.trim();
        if (!context[value]) {
            return memo;
        }
        return { ...memo, [value]: context[value] }
    }, {})


}

