import React, { Component } from 'react';
import './component_.css';
import axios from 'axios';

import { TabloFon3DBack } from './tablo-fon-3dback';

// await client, FieldId, EventId

export class Table extends Component {

  constructor ( props ) {

    super(props)

    this.state = {
      nowWinner: 0,
      //gotCategorys
      status: 'waiting'
    }

    this.getCategorys();
  }

  componentDidUpdate() {

    const { status } = this.state;

    if (status === 'waiting') {

      const { client, EventId } = this.props;
      if (!client) return;
      const event = client.getEventById (EventId); 

      if (event)
      client.getCategorysFromServer (event.ChampionshipId).then ( (result) => {
        this.setState ({categorys: result, status: 'loaded'});       
        this.timerTime(); 
        this.timer = setInterval( this.timerTime, 2000 )    
      })

    }

  }

  timerTime = () => {

    const { client, FieldId, EventId } = this.props;
    client.getFields().then ( fields => {

      if (!fields) return;

      const field = fields.find( x => x.FieldId.toString() === FieldId.toString() && x.EventId.toString() === EventId.toString())

      if (field) {

        if ( field.NowCircleId !== this.state.NowCircleId || field.NowCoupleId !== this.state.NowCoupleId ) {
          this.setState({ nowWinner: 0, ...field})
        } else
        {
          this.loadWinner();  
          this.setState(
            {...field}
          )
        }
      }
    })

  }

  getCategorys = () => {

    const { client, EventId } = this.props;

    if (!client) return;

    const event = client.getEventById (EventId); 

    if (event)
    client.getCategorysFromServer (event.ChampionshipId).then ( (result) => {
      this.setState ({categorys: result});
    })
  }

  getNowCategory = () => {

    const { categorys } = this.state;

    if (this.state.categorys) {
      const { NowCategoryId } = this.state;
      if (NowCategoryId ) {
        const category = categorys.find ( x => x.Id === NowCategoryId);
        if (category)
        return category;
      }
    }
    return {};
  }

  getNextCategory = () => {

    const { categorys } = this.state;

    if (this.state.categorys) {
      const { NextCategoryId } = this.state;
      if (NextCategoryId ) {
        const category = categorys.find ( x => x.Id === NextCategoryId);
        if (category)
        return category;
      }
    }
    return {};
  }

  loadWinner = async () => {
    
    const nowCategory = this.getNowCategory();
    if (!nowCategory.Id) return;

    const { Id } = nowCategory;
    const { EventId, client } = this.props;
    const { NowCircleId, NowCoupleId } = this.state;
    const event = client.getEventById (EventId);

    const uri = 'https://capoeirasport.com/getstory?competitionId=' + event.ChampionshipId + '&categoryId=' + Id;
    let story_;
  
    try {
      const res = await axios.get(uri);
      const { data } = res || [];
      if (!data) {
        story_ = [];
      }
  
      if (data.status === 'error') {
        story_ = [];
      }
  
      story_ = data.result.data.result || [];
    }
    catch (err) {
      story_ = [];
    }

    const stor = story_.find ( x => x.Circle_id === NowCircleId && x.Couple_id === NowCoupleId);
    if ( stor ) {
      this.setState({ nowWinner: stor.WinTo })
    }

  }

  render () { 

    const { status } = this.state;

    if (status === 'waiting')
      return <div className = 'cs_table-main'>
        <p></p>
      </div>


    return (
        <div className='tablefield-div'>
          <this.Background/>
          <this.Header/>
          <this.NowOnField/>
          <this.NextOnField/>
        </div>
    );
   }

   Background = () => {

    
    const { FieldId } = this.props;

     return <div className = 'tablefield-background'>
      <TabloFon3DBack 
          develop
          Id = {2021 + (FieldId || 34)}
          // drawWidth = {window.innerWidth}
          // drawHeight = {window.outerHeight}
          
          drawWidth = {1000}
          drawHeight = {576}
      />
     </div>
   }


   Header = () => {

     const {
       FieldColor,
       FieldId
     } = this.state;

     
      const nowCategory = this.getNowCategory();

     return <div className = 'tablefield-header'>
        <div className = 'tablefield-content'>
          {/* <img src = 'images/CM_logo.png'/> */}
        </div>
        <div className = 'tablefield-number' style = {{background: FieldColor || 'black'}}>
          <p>
            {FieldId}
          </p>
        </div>
        <div className = 'tablefield-category'>
          <p>
             {(nowCategory && nowCategory.Name) ? nowCategory.Name : ''}
          </p>
        </div>
     </div>
   }

   NowOnField = () => {

    const nowCategory = this.getNowCategory();
    if (!nowCategory.Id) return null;
    const { Name, Id } = nowCategory;

    const { client } = this.props;

    const { NowRed, NowBlue, NowCoupleId } = this.state;
    const { nowWinner } = this.state;

    let redstatus = 0;
    let bluestatus = 0;

    if ( nowWinner === 1 || nowWinner === 3 ) {
      redstatus = 1;
      bluestatus = -1;
    }

    if ( nowWinner === 2 || nowWinner === 4 ) {
      redstatus = -1;
      bluestatus = 1;
    }

    return [ 
      <PersonNowCard client = {client} status = {redstatus} personId = {NowRed ? Number(NowRed) : null} isRed />,
      <PersonNowCard client = {client} status = {bluestatus} personId = {NowBlue ? Number(NowBlue) : null}  />
  ]   

 /*   return [ 
        <div key = { `of-${NowRed}-${NowCoupleId}`} className = {rednowcn}>
        <PersonNowCard client = {client} personId = {NowRed ? Number(NowRed) : null} isRed />
      </div>
      <div key = { `of-${NowBlue}-${NowCoupleId}`} className = {bluednowcn}>
        <PersonNowCard client = {client} personId = {NowBlue ? Number(NowBlue) : null}  />
      </div> 
    ]*/
   }

   NextOnField = () => {

    const nowCategory = this.getNextCategory();
    if (!nowCategory.Id) return null;

    const { Name, Id } = nowCategory;
    const { client } = this.props;
    const { NextRed, NextBlue, NextCoupleId } = this.state;

    return [ 
      <PersonNextCard client = {client}  personId = {NextRed ? Number(NextRed) : null}/>,
      <PersonNextCard client = {client}  personId = {NextBlue ? Number(NextBlue) : null}/>
    ]
   }
}


// это голубая или красная плашки, которые показывают имена спортсменов, школу и группу (при наличии)
// могут плюс к этому подсвечивать выигрывшего спортсмена

class PersonNowCard extends Component {

  constructor (props) {
    super(props);
    const { client, personId } = props;
    this.state = {person:null}

    if ( personId !== 0 )
    client.getUserFromServer(personId).then ( (result) => {
        this.setState ( {person: result} );
      } );

  } 

  componentWillReceiveProps(newProps) {

    if ( newProps.personId !== this.props.personId ) {
        this.props.client.getUserFromServer(newProps.personId).then ( (result) => {
            this.setState ( {person: result} );
          } );  
    }

  }


  render () {

    const { client, isRed, status } = this.props;
    const { person } = this.state;

    if (!person) return null;
    const school = client.getSchoolById (person.SchoolId);    
    const schoolSrc = school ? 'https://capoeirasport.com/graphics/logo_schools/' + school.Id + '/logo.png' : null;
    const groupSrc = client ? client.getGroupUrl(person.GroupIamInId) : 'images/groups/' + person.GroupIamInId + '/logo.png';

    let plashkaClassName = 'PersonNowCard-background-plashka ' + ( isRed ? 'PersonNowCard-background-plashka-red' : 'PersonNowCard-background-plashka-blue')

    if (status < 0) {
      if (isRed) {
        plashkaClassName += ' PNWB-red-lose'
      }
      if (!isRed) {
        plashkaClassName += ' PNWB-blue-lose'
      }
    }

    if (status > 0) {
      if (isRed) {
        plashkaClassName += ' PNWB-red-win'
      }
      if (!isRed) {
        plashkaClassName += ' PNWB-blue-win'
      }
    }

    const schoolNode = ( schoolSrc && person.SchoolId !== 25) ? 
         <img src = {schoolSrc}/>
      : null

    const groupNode = (groupSrc && person.GroupIamInId) ? 
      <img src = {groupSrc} className = {isRed ? '' : 'PersonNowCard-group-blue-img'}/>
    : null      

    const { Name, Surname } = person;
    return <div key = {'PNWB' + person.Id} className = 'PersonNowCard-main'>
      <div className = { isRed ? 'PersonNowCard-school' : 'PersonNowCard-group' }>
        { isRed ? schoolNode : groupNode }
      </div>
      <div className = 'PersonNowCard-competitor'>
        <p>
         {`${Surname} ${Name}`}
        </p>
      </div>
      <div className = { isRed ? 'PersonNowCard-group' : 'PersonNowCard-school' }>
        { isRed ? groupNode : schoolNode }      
      </div>

      <div className = 'PersonNowCard-background '>
        <div className = {plashkaClassName}>

        </div>
      </div>

    </div>
  }

}


class PersonNextCard extends Component {

  constructor (props) {
    super(props);
    const { client, personId } = props;
    this.state = {person:null}

    if ( personId !== 0 )
    client.getUserFromServer(personId).then ( (result) => {
        this.setState ( {person: result} );
      } );

  } 

  componentWillReceiveProps(newProps) {

    if ( newProps.personId !== this.props.personId ) {
        this.props.client.getUserFromServer(newProps.personId).then ( (result) => {
            this.setState ( {person: result} );
          } );  
    }

  }


  render () {
    const { person } = this.state;

    if (!person) return null;
    const { Name, Surname } = person;

    return <div key = {'PNWBNext' + person.Id} className = 'PersonNextCard-main'>
      <div className = 'PersonNextCard-competitor'>
        <p>
         {`${Surname} ${Name}`}
        </p>
      </div>
    </div>
  }

}