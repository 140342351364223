import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import TrefaText from 'src/ver2/components/trefaText';
import moment from 'moment/min/moment-with-locales';

import 'moment/locale/ru';
import './style.css';

class LittleLogger extends PureComponent {
    render() {

        const { Log } = this.props;
        if (!Log) return null;

        const { Text, TimeStamp } = Log;
        const tsMoment = moment(TimeStamp, 'YYYY-MM-DD HH:mm:ss');

        return <div className='little_logger_main'>
            <div className='little_logger_main_left'>
                <span>
                    {tsMoment.format('DD MMM')}
                </span>
                <span>
                    {tsMoment.format('HH:mm')}
                </span>
            </div>
            <div className='little_logger_main_text'>
                <TrefaText trefaText={Text} />
            </div>
        </div>
    }
}

export default withCapoeiraSportState('client, isMobile')(LittleLogger);