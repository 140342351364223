import React, { PureComponent } from 'react';
import RoundState, { withRoundState } from './context';
import { TrefaLayout } from 'src/ver2/containers/layout';

import RoundInfoPlashka from './plashki/info';
import RoundISequencePlashka from './plashki/sequence';
import RoundISequencesPlashka from './plashki/sequences';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import RoundBackPlashka from './plashki/back';

import SequenceCreator from './containers/sequence-creator';



import Loader from 'src/ver2/components/loader';

import './styles.css';

class RoundPage extends PureComponent {

    state = {
        page: 'round' // creation
    }

    goCompetit = () => {
        this.setState({ page: 'creation' })
    }

    render() {

        let width = 1200;
        const { isRoundReady, isRoundDataReady, isUsersSequencesReady, UsersSequences, iamuser, client, Round, RoundRules, isMobile,
            mayIParticipate,
            mayIParticipateError
        } = this.props;

        if (!isRoundReady) return <div className='round_page'>
            <Loader text='Загрузка раунда' />
        </div>

        if (!isRoundDataReady) return <div className='round_page'>
            <Loader text='формирование раунда' />
        </div>

        if ( isMobile ) width = isMobile.width;

        const { page } = this.state;

        if (page === 'creation') {
            return <div style={{ width }} className='round_page'>
                <SequenceCreator
                    sendSequence={() => { }}
                    sequenceSended={false}
                    reloadPage={() => { }}
                    roundRules={RoundRules}
                />
            </div>
        }

        if (isMobile) {

            width = isMobile.width;

            let components_ = [];
            components_.push(
                {
                    component: RoundBackPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 156,
                    componentProps: { height: 60, yPadding: 'little' }
                },
                {
                    component: RoundInfoPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 1,
                    componentProps: { height: 150, yPadding: 'little', _title: 'Описание раунда' }
                },
                {
                    component: RoundISequencePlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 2,
                    componentProps: { height: 100, yPadding: 'little', _title: 'Задание' }
                }
            )
    
            if (isUsersSequencesReady && iamuser) {
                if (UsersSequences && !UsersSequences.find(x => x.UserId === iamuser.Id)) {
    
                    if (mayIParticipate) {
                        components_.push(
                            {
                                component: ButtonPlashka,
                                cell_x: 2,
                                collapse: 1,
                                id: 3,
                                componentProps: { height: 70, yPadding: 'usual', text: 'ПРИНЯТЬ УЧАСТИЕ', onClick: this.goCompetit }
                            }
                        )
                    } else {
                        components_.push(
                            {
                                component: ButtonPlashka,
                                cell_x: 2,
                                collapse: 1,
                                id: 3,
                                componentProps: { height: 70, yPadding: 'usual', disabled: true, disabledDiscription: mayIParticipateError , text: 'ПРИНЯТЬ УЧАСТИЕ', onClick: this.goCompetit }
                            }
                        )
                    }
                }
            }
    
            components_.push(
                {
                    component: RoundISequencesPlashka,
                    cell_x: 2,
                    collapse: 1,
                    id: 4,
                    componentProps: { height: 750, yPadding: 'little', _title: 'Связки участников' }
                }
            )
    
            return <div style={{ width }} className='round_page'>
                <TrefaLayout greedsX={[5, width - 10, 5]}
                    components={
                        components_
                    }
                />
            </div> 
        }


        // NOT MOBILE

        let components_ = [];
        components_.push(
            {
                component: RoundBackPlashka,
                cell_x: 2,
                collapse: 1,
                id: 156,
                componentProps: { height: 60, yPadding: 'little' }
            },
            {
                component: RoundInfoPlashka,
                cell_x: 2,
                collapse: 1,
                id: 1,
                componentProps: { componentSelfHeight: true, height: 150, yPadding: 'little', _title: 'Описание раунда' }
            },
            {
                component: RoundISequencePlashka,
                cell_x: 2,
                collapse: 1,
                id: 2,
                componentProps: { height: 100, yPadding: 'little', _title: 'Задание' }
            },
            {
                component: RoundISequencesPlashka,
                cell_x: 4,
                collapse: 1,
                id: 4,
                componentProps: { height: 750, yPadding: 'little', _title: 'Связки участников' }
            }
        )

        if (isUsersSequencesReady && iamuser) {
            if (UsersSequences && !UsersSequences.find(x => x.UserId === iamuser.Id)) {

                if (mayIParticipate) {
                    components_.push(
                        {
                            component: ButtonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 3,
                            componentProps: { height: 70, yPadding: 'usual', text: 'ПРИНЯТЬ УЧАСТИЕ', onClick: this.goCompetit }
                        }
                    )
                } else {
                    components_.push(
                        {
                            component: ButtonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 3,
                            componentProps: { height: 70, yPadding: 'usual', disabled: true, disabledDiscription: mayIParticipateError , text: 'ПРИНЯТЬ УЧАСТИЕ', onClick: this.goCompetit }
                        }
                    )
                }
            }
        }


        return <div style={{ width }} className='round_page'>
            <TrefaLayout greedsX={[25, 410, 25, 715, 25]}
                components={
                    components_
                }
            />
        </div>
    }
}

const RoundPageWithState = withRoundState(`
Round,
isRoundReady,

iAmParticipator, 
mayIParticipate,
mayIParticipateError,

RoundRules,
RoundRulesObj,

isUsersSequencesReady,
UsersSequences,
            
isRoundDataReady,
isUserDataReady,

location,

client,
iamuser,
iAmAdmin,
isMobile
`)(RoundPage);

const RoundPageWithContent = (props) => <RoundState {...props}> {RoundPageWithState()} </RoundState>

export default RoundPageWithContent;