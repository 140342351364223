import React, { PureComponent } from 'react';
import LinkIcon from 'src/ver2/components/ui/icons/link';
import { Link } from 'react-router-dom';
import { copyToClipboard } from 'src/ver2/utils';

import './style.css';

export default class LinkButton extends PureComponent {

    /*
        sizes
        
        mini,
        mini_long,
        usual
    */

    render() {

        const { type = 'link', link, onClick, size = 'usual', horizontal = false, saveText = null, text = <span>ССЫЛКА <br /> НА БОЙ </span>, style = {} } = this.props;

        let className = 'ui_button_link';
        if (size === 'mini') className = 'ui_button_link ui_button_link_mini';
        if (size === 'mini_long') className = 'ui_button_link ui_button_link_mini_long';

        if (horizontal) {
            return <div
                onClick={() => copyToClipboard(saveText)}
                className={'ui_button_link_horizontal'}
            >
                <LinkIcon /> <span> {text} </span>
            </div>
        }

        const component = <div
            onClick={onClick}
            className={className}
            style={style}
        >
            <LinkIcon /> <span> {text} </span>
        </div>

        if (type === 'link')
            return <Link to={link}>
                {component}
            </Link>

        if (type === 'clipboard')
            return <div onClick={() => copyToClipboard(saveText)}>
                {component}
            </div>

        return component
    }

}

