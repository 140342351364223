import React, { PureComponent } from "react";
import ComboState, { withComboState } from 'src/ver2/components/combo/context';
import CoomboSchemeComponent from 'src/ver2/components/combo/scheme';
import Loader from 'src/ver2/components/loader';

import './styles.css';

class ComboRow extends PureComponent {
    render() {
        const { isComboReady, Combo, height } = this.props;
        if (!isComboReady) return <div style={{ height }} className="combo_row_component_main"><Loader /></div>
        if (!Combo) return <div style={{ height }} className="combo_row_component_main">Ошибка сервера</div>
        return <div style={{ height }} className="combo_row_component_main">
            <CoomboSchemeComponent schemeHeight={height} Body={Combo.Body} />
        </div>
    }
}

const ComboPageWithState = withComboState(`
Combo,
isComboReady,

location,

client,
iamuser,
iAmAdmin,
isMobile
`)(ComboRow);

const ComboRowWithContent = (props) => <ComboState comboId={props.comboId} {...props}> {ComboPageWithState()} </ComboState>
export default ComboRowWithContent;