/***
* 
*  Тут конечная связка, которую вы высылаете
* 
*  */

import React, { Component } from 'react';

import { useDrag, useDrop } from 'react-dnd'

import { Card } from '../elements/card'

import { widthByCount, widthByCountMobile } from '../../helpers';

import { isEqual } from 'lodash';

/*
 
                client = {client}
             elements = {elements}
             round = {round}
             our_svyazka = {our_svyazka}
             getPointToElement = {this.getPointToElement}
             newOurSvyazka = {this.newOurSvyazka}
             selectSvyazkaNumber = {this.selectSvyazkaNumber}
             sequenceSelectedNumber = {sequenceSelectedNumber}
             cardSelectedElement = {cardSelectedElement}

*/


export class MobileSvyazka extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.formSvyazkaToShow();
  }

  componentDidUpdate() {
    if (!isEqual(this.props.last_our_svyazka, this.props.our_svyazka))
      this.props.formSvyazkaToShow();

    // теперь мы проверяем, можем ли запулять связку
    const { sequenceSelectedNumber, cardSelectedElement } = this.props;
    if (sequenceSelectedNumber !== undefined && cardSelectedElement !== undefined) {

      // нужно вытащить карточку, на которую кидаем
      const { svyazka_to_show } = this.props;
      const card = svyazka_to_show[sequenceSelectedNumber];

      if (this.props.mayDropHere({ element_id: cardSelectedElement, card })) {
        this.dropElement({ element_id: cardSelectedElement, index: sequenceSelectedNumber, reset: true });
      }
    }

  }

  TitleComponent = () => {

    const { rules, points } = this.props;

    if (rules && rules.MinPoints) {
      let className = 'dndtry-div-mobile-svyazka-title';
      if (points < rules.MinPoints) className += ' dndtry-div-mobile-svyazka-title-less';
      return <div className={className}>
        Ваша связка ( базовые очки: {points.toFixed(1)}/{rules.MinPoints} )
      </div>
    }

    return <div className='dndtry-div-mobile-svyazka-title'>
      Ваша связка ( базовые очки: {points.toFixed(1)} )
    </div>
  }

  render() {
    const { selectSvyazkaNumber, svyazka_to_show } = this.props;
    if (!svyazka_to_show) return null;

    return <div className='dndtry-div-mobile-svyazka'>
      <this.TitleComponent />
      <div className='dndtry-div-mobile-svyazka-row'>

        {svyazka_to_show.map((card, index) => {
          return <div
            key={`card_div_svyazka ${index}`}
            onClick={() => { if (!card.our) selectSvyazkaNumber(index) }}>
            <this.MobileSvyazkaCard
              key={`card_svyazka ${index}`}
              {...{ svyazka_length: svyazka_to_show.length }}
              {...{ ...card }}
            /></div>
        })}

      </div>
      <this.MobileSvyazkaOptions />
    </div>
  }


  // тут мы показываем про связку всякое + через неё мы удаляем

  MobileSvyazkaOptions = () => {

    const { newOurSvyazka, our_svyazka } = this.props;

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: 'KNIGHT2',
      drop: (dragged_item) => {

        const { index } = dragged_item;

        let our_svyazka_ = [...our_svyazka]

        our_svyazka_[index] = null;

        newOurSvyazka({ our_svyazka: our_svyazka_ })


      },
      canDrop: (dragged_item) => {
        return dragged_item.in_svyazka;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    })

    return <div ref={drop} className='dndtry-div-mobile-svyazka-options'>

      {canDrop ? 'УДАЛИТЬ' : ''}

    </div>

  }

  dropElement = ({ element_id, index, reset }) => {

    const { our_svyazka, newOurSvyazka } = this.props;

    let our_svyazka_ = [...our_svyazka]

    our_svyazka_[index] = { element_id }

    newOurSvyazka({ our_svyazka: our_svyazka_, reset })
  }


  // это карточка в верхней части - или серая или наложенная
  /**
   * 
   * elements - это все элементы (вообще, с сервера)
   * element_id - это если в ней уже лежит элемент
   * element - это аналог type, это если по заданию конкретный элемент
   * 
   * is_selected - мы на неё ткнули чтобы туда выложить какую-то карточку
   */
  MobileSvyazkaCard = ({ our, element_id, type, element, subtype, index, svyazka_length }) => {

    const { client, elements, sequenceSelectedNumber, mayDropHere, mobile, iamuser } = this.props;
    const is_selected = sequenceSelectedNumber === index;

    const [{ isDragging }, drag] = useDrag({
      item: { type: element_id ? 'KNIGHT' : 'KNIGHT2', element_id, index, in_svyazka: true },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    })

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: 'KNIGHT',
      drop: (dragged_item) => {

        const { element_id } = dragged_item;
        this.dropElement({ element_id, index });

      },
      canDrop: (dragged_item) => {

        const { element_id } = dragged_item;
        const card = { type, element, subtype };

        return mayDropHere({ element_id, card });

      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    })

    const width = mobile ? widthByCountMobile(svyazka_length) : widthByCount(svyazka_length);

    let coverId = null;

    if (iamuser && iamuser.VSettings) {
      const VSettingsObj = JSON.parse(iamuser.VSettings);
      if (VSettingsObj && VSettingsObj.paint && VSettingsObj.paint.cover) coverId = VSettingsObj.paint.cover;
    }

    return <Card
      {...{
        client,
        element_id,
        coverId, // 'cover_brazil_1',
        elements,
        reff: our ? drag : drop,
        isDragging,
        type,
        subtype,
        element,
        mayDropElement: canDrop,
        hoverElement: isOver,
        sizes: {
          width,
          is_selected
        }
      }}

    />
  }


}
