import React, { PureComponent } from 'react';

import { withCapoeiraSportState } from 'src/ver2/context';
import { CheckIcon } from 'src/ver2/components/ui/icons';

import Loader from 'src/ver2/components/loader';
// import { Link } from 'react-router-dom';

import './style.css';

// class OTDiscriptionPlashka
// classname: ot_isadded_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class OTDiscriptionPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    /*
        const { setComponentSelfHeight, plashkaId } = this.props;
        setComponentSelfHeight({ height: height + 45, plashkaId });
    */

    render() {

        const { upperStyle } = this.props;
        const { width, height } = upperStyle;

        // if (!Event) return <div style={{ width, height }} className='ot_isadded_plashka'>
        //     <Loader />
        // </div>

        return <div style={{ width, height }} className='ot_isadded_plashka'>
            <CheckIcon width={height * 0.6} height={height * 0.6} /> <span>вы добавили этот элемент</span>
        </div>
    }
}

export default withCapoeiraSportState(`
client
`)(OTDiscriptionPlashka)