// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input, Select } from 'antd';

const Option = Select.Option;

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest27 extends PureComponent {
    state = {
        options: {
            1: null,
            2: null,
            3: null,
            4: null
        }

    }

    clickOnSend = () => {
        const { options } = this.state;
        const answer = `${options[1]}${options[2]}${options[3]}${options[4]}`;
        this.props.sendSpecial({ data: { [answer]: 1 } })
    }



    render() {
        const { isMobile } = this.props;
        const { options } = this.state;
        const className = 'quest27_container'

        return <div className={className}>
            <Select style={{ width: 150 }} size='small' placeholder={'КРАСНЫЙ'} onChange={(color) => { this.setState({ options: { ...options, 1: color } }) }} >
                <Option value='1' >ПАНДЕЙРУ</Option>
                <Option value='2' >БЕРИМБАО</Option>
                <Option value='3' >АГОГО</Option>
                <Option value='4' >АТАБАК</Option>
            </Select>
            <Select style={{ width: 150 }} size='small' placeholder={'СИНИЙ'} onChange={(color) => { this.setState({ options: { ...options, 2: color } }) }} >
                <Option value='1' >ПАНДЕЙРУ</Option>
                <Option value='2' >БЕРИМБАО</Option>
                <Option value='3' >АГОГО</Option>
                <Option value='4' >АТАБАК</Option>
            </Select>
            <Select style={{ width: 150 }} size='small' placeholder={'ЗЕЛЕНЫЙ'} onChange={(color) => { this.setState({ options: { ...options, 3: color } }) }} >
                <Option value='1' >ПАНДЕЙРУ</Option>
                <Option value='2' >БЕРИМБАО</Option>
                <Option value='3' >АГОГО</Option>
                <Option value='4' >АТАБАК</Option>
            </Select>
            <Select style={{ width: 150 }} size='small' placeholder={'ФИОЛЕТОВЫЙ'} onChange={(color) => { this.setState({ options: { ...options, 4: color } }) }} >
                <Option value='1' >ПАНДЕЙРУ</Option>
                <Option value='2' >БЕРИМБАО</Option>
                <Option value='3' >АГОГО</Option>
                <Option value='4' >АТАБАК</Option>
            </Select>
            <div className='quest20_container_send' onClick={this.clickOnSend}><span>ОТВЕТИТЬ</span></div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest27);