import React, { PureComponent, createRef } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { groupBy, orderBy } from 'lodash';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { ElementCard } from 'src/ver2/containers/challenges/components/element-card';
import TPLan from 'src/ver2/pages/technic/components/plan';

import { CheckIcon, CrossIcon } from 'src/ver2/components/ui/icons';

import TechnicAllPlashka from './plashki/all';
import { TECHNIC_HEADER_TEXT, CHOOSE_BUTTONS_LIST_LABELS } from './helpers/texts';

import './styles.css';

/*

    Это страница показывает все элементы, она подразумевается для общего использования, т.е. не в игровых целях, а именно для тех
    кто зашел сюда посмотреть техники.

    Фильтры слева пока убираем, оставляет только фильтры показа ( горизонтально сверху вних, или матрицей )

*/

class TechincPageAll extends PureComponent {

    constructor(props) {
        super(props);


        this.elements = props.client.getServerElements();
        this.groupElements = groupBy(this.elements, 'type');

        this.state = {
            typesFilter: {},
            chooseKey: 'list' // matrix, plan
        }

        const type_keys = Object.keys(this.groupElements);

        for (let i = 0; i < type_keys.length; i++) {

            this.state.typesFilter[type_keys] = null;

            this.groupElements[type_keys[i]] = orderBy(this.groupElements[type_keys[i]], 'Points', 'asc');
            this.groupElements[type_keys[i]] = orderBy(this.groupElements[type_keys[i]], 'Tier', 'asc');
        }

    }

    componentDidMount() {
    }

    //                prevProps, prevState
    componentDidUpdate() {

    }

    clickOnAddElement = async (ElementKey) => {
        if (!ElementKey) return;
        const { client, renewElements } = this.props;
        await client.addUserElement(ElementKey);
        renewElements();
    }

    render() {

        const { iamuser, isMobile } = this.props;
        const { chooseKey } = this.state;

        const width = isMobile ? isMobile.width : 1200;

        let ComponentT = null;

        if (chooseKey === 'list') ComponentT = this.ElementsList;
        if (chooseKey === 'matrix') ComponentT = this.ElementsMatrix;
        if (chooseKey === 'plan') ComponentT = this.ElementsPlan;

        return <div style={{ width }} className='technic_pageall'>
            <this.Header />
            <ComponentT />
        </div>
    }

    Header = () => {

        const ChooseButton = ({ myChooseKey }) => {
            const { chooseKey } = this.state;

            const className = myChooseKey === chooseKey ? 'technic_pageall_header_chooser_button technic_pageall_header_chooser_button_choosed' : 'technic_pageall_header_chooser_button';

            return <div onClick={() => { this.setState({ chooseKey: myChooseKey }) }} className={className}>
                <span>{CHOOSE_BUTTONS_LIST_LABELS[myChooseKey]}</span>
            </div>
        }

        return <div className='technic_pageall_header'>
            <span>{TECHNIC_HEADER_TEXT}</span>
            <div className='technic_pageall_header_chooser'>
                <ChooseButton myChooseKey='list' />
                <ChooseButton myChooseKey='matrix' />
                <ChooseButton myChooseKey='plan' />
            </div>
        </div>
    }

    ElementsPlan = () => {
        return <div className='ElementsPlan_elements'>
            <TPLan />
        </div>
    }

    ElementsMatrix = () => {
        const { client, isMobile, iamuser } = this.props;
        const { typesFilter } = this.state;

        const type_keys = Object.keys(this.groupElements);

        const sizes = {
            width: isMobile ? 60 : 170
        }

        const { VSettings = '{}' } = iamuser || {};
        const VSettingsObj = JSON.parse(VSettings);
        const coverId = (VSettingsObj && VSettingsObj.paint && VSettingsObj.paint.cover) ? VSettingsObj.paint.cover : null;

        return type_keys.map(type_key => {

            return <div key={`sead-${type_key}`} className='ElementsHorizontal_container'>

                <this.HorizontalTitle {...{ type_key }} />

                <div className={'ElementsMatrix_elements'}>
                    {
                        this.groupElements[type_key].map(
                            element => {
                                if (typesFilter[type_key]) {
                                    const { subtypes } = element;
                                    if (!subtypes || !subtypes.find(x => x === typesFilter[type_key])) return null;
                                }

                                let myElement = null;
                                if (iamuser && iamuser.myElements) {
                                    myElement = iamuser.myElements.find(x => x.ElementKey === element.key);
                                }

                                return <ElementCard
                                    key={`seocd-${element.key}`}
                                    isMobile={isMobile}
                                    cover_id={myElement ? coverId : null}
                                    // cover_id={myCover && myElements[element.key] ? myCover : null}
                                    is_mine={myElement}
                                    is_login={iamuser}
                                    clickOnAdd={this.clickOnAddElement}
                                    // education_add={education_add}
                                    {...{ client, sizes, element_key: element.key }}
                                />
                            }
                        )
                    }

                </div>
            </div>
        })
    }

    // это большой контейнер, в котором идут горизонтально элементы линиями вниз
    ElementsList = () => {

        const { client } = this.props;
        const { typesFilter } = this.state;

        const type_keys = Object.keys(this.groupElements);

        return type_keys.map(type_key => {

            return <div key={`sead-${type_key}`} className='ElementsHorizontal_container'>

                <this.HorizontalTitle {...{ type_key }} />

                <div className={'ElementsHorizontal_elements'}>
                    {
                        this.groupElements[type_key].map(
                            element => {
                                if (typesFilter[type_key]) {
                                    const { subtypes } = element;
                                    if (!subtypes || !subtypes.find(x => x === typesFilter[type_key])) return null;
                                }
                                return <this.HorizontalElement
                                    element={element}
                                />
                            }
                        )
                    }

                </div>
            </div>
        })

    }

    HorizontalTitle = ({ type_key }) => {

        const { client, isMobile } = this.props;
        const { typesFilter } = this.state;

        // subtypes это типа там прыжки, хуяжки и т.д.
        const subtypes = client.getSubtypesByType(type_key);
        const onImgClick = (subtype) => {
            if (typesFilter[type_key] === subtype) {
                this.setState({ typesFilter: { ...this.state.typesFilter, [type_key]: null } })
            } else {
                this.setState({ typesFilter: { ...this.state.typesFilter, [type_key]: subtype } })
            }
        }

        const width = (isMobile && isMobile.width) ? isMobile.width : 1200;

        return <div style={{ height: width * 0.15 }} className={'HorizontalTitle_container'} >
            <div style={{ width: width * 0.12 }} className='BLANK_WIDTH' />
            <img style={{ width: width * 0.09 }} src={client.getTechnicNeededLogo('ty_' + type_key)} className='HorizontalTitle_title_img' alt='' />
            <div style={{ width: width * 0.037 }} className='BLANK_WIDTH' />
            <div className={'HorizontalTitle_title'}>
                {client.getTypeDiscription(type_key, true)}
            </div>
            <div style={{ width: width * 0.055 }} className='BLANK_WIDTH' />
            <div className='HorizontalTitle_icons'>
                {subtypes.map(subtype => {

                    let card_src = `st_${subtype}`;
                    if (subtype === 'jump') card_src = `st_${type_key}_${subtype}`;
                    // const style = typesFilter[type_key] === subtype ? { opacity: 1 } : {};
                    let style = {
                        width: width * 0.045,
                        height: width * 0.045,
                        opacity: typesFilter[type_key] === subtype ? 1 : 0.5,
                        marginRight: width * 0.007
                    }

                    return <Tooltip title={client.getSubtypeName(subtype)}>
                        <img style={style} alt='' onClick={() => onImgClick(subtype)} src={client.getTechnicLogoUrl(card_src)} />
                    </Tooltip>
                })}
            </div>
        </div>
    }

    HorizontalElement = ({ element }) => {

        const { client, isMobile, iamuser } = this.props;

        const {
            isVideo,
            key,
            title,
            Tier,
            Points,
            Discription,
            DiscriptionShort
        } = element;


        const width = (isMobile && isMobile.width) ? isMobile.width : 1200;
        let myElement = null;
        if (iamuser && iamuser.myElements) {
            myElement = iamuser.myElements.find(x => x.ElementKey === key);
        }

        return <div style={{ height: width * 0.15 }} className='HorizontalElement_container'>
            <div style={{ width: width * 0.035 }} className='BLANK_WIDTH'>
                {
                    (myElement && myElement.Status === 'ok') ?
                        <Tooltip title={`Элемент добавлен ${myElement.AddedDate}`}>
                            <div style={{
                                top: -74 * width / 1200,
                                left: 50 * width / 1200,
                            }} className='HorizontalElement_container_myelement'>

                                <CheckIcon width={width * 0.036} height={width * 0.036} />
                            </div>
                        </Tooltip>

                        : <div className='HorizontalElement_container_myelement' />
                }

            </div>
            <div style={{ width: width * 0.685, height: width * 0.13 }} className='HorizontalElement_main'>
                <div style={{ width: width * 0.025 }} className='BLANK_WIDTH' />
                <div style={{ width: width * 0.15, height: width * 0.15 }} className='HorizontalElement_squad'>
                    <div className='HorizontalElement_squad_background' />
                    <img src={client.getTechnicLogoUrl(key)} />
                </div>
                <div style={{ width: width * 0.51, height: width * 0.13 }} className='HorizontalElement_title_container'>
                    <div style={{ width: width * 0.51, height: width * 0.04 }} className='HorizontalElement_title'>
                        <div style={{ width: width * 0.044 }} className='BLANK_WIDTH' />
                        <Link to={`/technic/${key}`}>
                            {title.map((item, index) => {
                                if (index < title.length - 1) {
                                    return `${item} / `
                                }
                                return item;
                            })}
                        </Link>
                    </div>
                    <div style={{ width: width * 0.49, height: width * 0.09 }} className='HorizontalElement_discription'>
                        <div style={{ width: width * 0.018 }} className='BLANK_WIDTH' />
                        {isMobile ?
                            <Tooltip title={
                                <div className='HorizontalElement_discription_text_mobiletooltip'>
                                    {
                                        (DiscriptionShort || Discription || 'Нет описания').split('/').map((row, index) => {
                                            return [
                                                row,
                                                <br />
                                            ]
                                        })
                                    }
                                </div>
                            }>
                                <div className='HorizontalElement_discription_text'>
                                    {
                                        (DiscriptionShort || Discription || 'Нет описания').split('/').map((row, index) => {
                                            return [
                                                row,
                                                <br />
                                            ]
                                        })
                                    }
                                </div>
                            </Tooltip>
                            :

                            <div className='HorizontalElement_discription_text'>
                                {
                                    (DiscriptionShort || Discription || 'Нет описания').split('/').map((row, index) => {
                                        return [
                                            row,
                                            <br />
                                        ]
                                    })
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div style={{ width: width * 0.005 }} className='BLANK_WIDTH' />
            <div style={{ width: width * 0.24, height: width * 0.135 }} className='HorizontalElement_video'>
                {isVideo ?
                    <VideoToPlay elementKey={key} isMobile={isMobile} />
                    :
                    <p>ВИДЕО НЕТ</p>}
            </div>
        </div>
    }
}

class VideoToPlay extends PureComponent {
    myRef = createRef();

    onClick = () => {
        if (this.myRef.current) {
            if (this.myRef.current.paused) {
                this.myRef.current.play();
            } else
                this.myRef.current.pause();
        }
    }

    render() {

        const { elementKey, isMobile } = this.props;
        if (!isMobile)
            return <video
                playsInline="true"
                id="my-player"
                class="video-js"
                preload="none"
                muted="true"
                poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
                loop="true"
                width='100%'
                controls=""
                data-setup="{}"
                onClick={this.onClick}
                ref={this.myRef}
                autoplay='true'
            >
                <source
                    src={`https://capoeirasport.com/graphics/video_technics/${elementKey}.mp4`}
                    type="video/mp4"
                />
            </video>

        return <video
            playsInline="true"
            id="my-player"
            class="video-js"
            preload="none"
            muted="true"
            poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
            loop="true"
            width='100%'
            controls=""
            data-setup="{}"
            onClick={this.onClick}
            ref={this.myRef}
        >
            <source
                src={`https://capoeirasport.com/graphics/video_technics/${elementKey}.mp4`}
                type="video/mp4"
            />
        </video>
    }
}


export default withCapoeiraSportState('iamuser, client, isMobile, renewElements')(TechincPageAll);