import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaText from 'src/ver2/components/trefaText';
import { Modal, Button, message } from 'antd';

import Loader from 'src/ver2/components/loader';

// import { Link } from 'react-router-dom';

import './style.css';

// class ShopItemsPlashka
// classname: shop_items_plashka

/**
  ShowType (!)

  0 - не показываем (компонент вообще не дойдёт сюда)
  1 - показываем, если есть хотя бы один живой артикул, но артикулы прячем после раскупки
  2 - показываем всегда, но отсутствующие артикулы ставим время изготовления

 */

class RealShopItem extends PureComponent {

    state = {
        buyModal: false,
        articuleIdChoosed: null
    }

    get imgSrc() {
        const { RealShopItem } = this.props;
        const { Id } = RealShopItem;
        return `https://capoeirasport.com/graphics/real_item/${Id}/main.jpg`;
    }

    changeBuyModal = () => {
        const buyModal = !this.state.buyModal;
        // показываем
        if (buyModal) {
            const { RealShopItem, RealShopArticulesByItems = {} } = this.props;
            const { Id } = RealShopItem;

            const RealShopArticuleByItems = RealShopArticulesByItems[Id];
            const articuleIdChoosed = RealShopArticuleByItems[0].Id;
            return this.setState({ buyModal, articuleIdChoosed })
        }
        this.setState({ buyModal, articuleIdChoosed: null })
    }

    changeArticuleIdChoosed = (articuleIdChoosed) => this.setState({ articuleIdChoosed })

    render() {
        const { RealShopItem, isMobile, RealShopArticulesByItems = {} } = this.props;
        const { Title, Cost, Id, ShowType } = RealShopItem;

        const { buyModal } = this.state;

        const width = isMobile ? isMobile.width * 0.45 : 206;
        const height = width * 1.5;

        const RealShopArticulesByItem = RealShopArticulesByItems[Id] || [];

        let isShown = (ShowType === 2);

        if (!isShown)
            for (let i = 0; i < RealShopArticulesByItem.length; i++) {
                if (RealShopArticulesByItem[i].Count && RealShopArticulesByItem[i].Count > 0) {
                    isShown = true;
                    break;
                }
            }

        if (!isShown) return null;

        return <div style={{ width, height }} className='realshopitem_main'>
            <div style={{ width, height }} onClick={this.changeBuyModal}>
                <div style={{ width, height: width }} className='realshopitem_main_img'>
                    <img src={this.imgSrc} />
                </div>
                <div className='realshopitem_main_cost'>
                    <span>
                        {`${Cost} ₽`}
                    </span>
                </div>
                <div className='realshopitem_main_title'>
                    <span>
                        {Title}
                    </span>
                </div>
                <div className='realshopitem_main_articules'>
                    {RealShopArticulesByItems[Id] &&
                        RealShopArticulesByItems[Id].map(({ Name, Count }) => {
                            let className = 'realshopitem_main_articule';
                            if (!Count || Count < 0) {
                                if (ShowType === 1) {
                                    return null;
                                }
                                className += ' realshopitem_main_articule_not'
                            }
                            return <span style={{ height: width * 0.08 }} className={className}> {Name} </span>
                        })
                    }
                </div>
            </div>
            <Modal
                className='buyItemClassModal'
                visible={buyModal}
                onCancel={() => { this.changeBuyModal() }}
                footer={null}
                title={`Купить ${Title}`}
                width={1000}
            >
                {buyModal ? <this.BuyItemModal /> : null}
            </Modal>

        </div>
    }

    BuyItemModal = () => {

        const { RealShopItem, isMobile, RealShopArticulesByItems = {}, iamuser } = this.props;
        const { Title, Cost, Id, Text, Settings, ShowType } = RealShopItem;
        const { changeArticuleIdChoosed } = this;
        const { articuleIdChoosed } = this.state;

        const RealShopArticuleByItems = RealShopArticulesByItems[Id];
        const choosedRealShopArticule = RealShopArticuleByItems.find(x => x.Id === articuleIdChoosed) || {};

        const SettingsObj = Settings ? JSON.parse(Settings) : {}

        const title = (text) => <div className='realshopitem_buyitem_main_right_title'>{text}</div>

        const className = isMobile ? 'realshopitem_buyitem_main realshopitem_buyitem_main_mobile' : 'realshopitem_buyitem_main';

        return <div className='realshopitem_buyitem_main'>
            <div className={className}>
                <div className='realshopitem_buyitem_main_left'>
                    <div className='realshopitem_buyitem_main_left_img'>
                        <img src={this.imgSrc} />
                    </div>
                </div>
                <div className='realshopitem_buyitem_main_right'>
                    <div className='realshopitem_buyitem_main_right_itemtitle'>
                        {Title}
                    </div>
                    {title('Цена')}
                    <div className='realshopitem_buyitem_main_right_row'>
                        {`${Cost} ₽`}
                    </div>
                    {title('Описание')}
                    <div className='realshopitem_buyitem_main_right_row'>
                        <TrefaText trefaText={Text} />
                    </div>
                    {title(`${RealShopArticuleByItems[0].Title} в наличии`)}
                    <div className='realshopitem_buyitem_main_right_articules'>
                        {RealShopArticulesByItems[Id] &&
                            RealShopArticulesByItems[Id].map(({ Name, Count, Id }) => {
                                let className = 'realshopitem_buyitem_main_right_articule';
                                const title = Count > 0 ? `${Count} шт.` : `нет`;

                                if (!Count || Count < 0) className += ' realshopitem_buyitem_main_right_articule_not';

                                if (Id === articuleIdChoosed) className += ' realshopitem_buyitem_main_right_articule_choosed';

                                return <div className={className} onClick={() => {
                                    if ((!Count || Count < 0) && (ShowType < 2)) return null;
                                    changeArticuleIdChoosed(Id)
                                }}>
                                    <span> {`${Name} - ${title}`} </span>
                                </div>
                            })
                        }
                    </div>
                    {(ShowType === 2) && <div className='realshopitem_buyitem_main_right_minirow'>
                        если нет в наличии, Вы можете заказать изготовлеие
                    </div>}
                    {title(`${choosedRealShopArticule.Title} ${choosedRealShopArticule.Name}`)}
                    <div className='realshopitem_buyitem_main_right_row'>
                        {choosedRealShopArticule.Text}
                    </div>

                    {
                        (!choosedRealShopArticule.Count || choosedRealShopArticule.Count < 0) &&
                        title('Изготовление')
                    }
                    {
                        (!choosedRealShopArticule.Count || choosedRealShopArticule.Count < 0) &&
                        <div className='realshopitem_buyitem_main_right_row'>
                            {`${SettingsObj.creationTime}`}
                        </div>
                    }
                    {
                        iamuser ? null :
                            <div style = {{color: 'red'}}>
                                Для добавления в корзину войдите в свой аккаунт!
                            </div>
                    }
                    {
                        iamuser ? <Button onClick={() => {
                            this.props.addToOrder({ RealArticuleId: articuleIdChoosed });
                            this.changeBuyModal();
                        }} type="primary" size="large">В КОРЗИНУ</Button> :
                            <div title='Войдите в аккаунт'
                                onClick={() => {
                                    message.error('Войдите в свой аккаунт')
                                }}
                            >
                                <Button type="primary" size="large" disabled='true'>В КОРЗИНУ</Button>
                            </div>
                    }
                </div>
            </div>
        </div>
    }

}

// const AAA = {
//     "RealThemes":{
//         "adultform":{
//             "title": "Взрослая белая форма",
//             "sales":[{ "Ids": [1, 2], "Minus": 500, "saleTitle": "Цена при покупке комплекта 4500 руб" }]
//         },
//         "youngform": {
//             "title": "Детская белая форма",
//             "sales": [{ "Ids": [1, 2], "Minus": 500, "saleTitle": "Цена при покупке комплекта 4500 руб" }]
//         },
//         "batizado": {
//             "title": "Футболки с аттестаций"
//         },
//         "sleeve": {
//             "title": "Одежда с рукавами"
//         },
//         "other": {
//             "title": "Другое"
//         }
//     }
// }


export default withShopState(`
Shop,  
client,
iamuser,
isMobile,
RealShopArticulesByItems,
addToOrder
`)(RealShopItem);