const VERDE_CLARO = '#90ee90';
const VERDE = '#00FF00';
const AMARELA = '#FFFF00';
const AZUL = '#0000FF';
const VARMELHA = '#FF0000';
const BRANCO = '#FFFFFF';



export const schools = {

    1: {
        Id: 1,
        Name: 'SóForça',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'child premiero 1': {
                Name: 'child premiero 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'child premiero 2': {
                Name: 'child premiero 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#33FF33',
                }
            },

            'kids premiero': {
                Name: 'kids premiero',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'kids calouro 1': {
                Name: 'kids calouro 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'kids calouro 2': {
                Name: 'kids calouro 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFFFF',
                    k2: '#FF7700',
                }
            },

            'kids aluno 1': {
                Name: 'kids aluno 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#FFFFFF',
                    k2: '#33FF33',
                }
            },

            'kids aluno 2': {
                Name: 'kids aluno 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#008000',
                    c4: '#008000',
                    k1: '#FFFFFF',
                    k2: '#008000',
                }
            },

            'kids aluno forca 1': {
                Name: 'kids aluno forca 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#42aaff',
                    c4: '#42aaff',
                    k1: '#FFFFFF',
                    k2: '#42aaff',
                }
            },

            'kids aluno forca 2': {
                Name: 'kids aluno forca 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'kids batizado': {
                Name: 'kids batizado',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#FFFFFF',
                    k2: '#666666',
                }
            },

            'kids batizado forca': {
                Name: 'kids batizado forca',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#FFFFFF',
                    k2: '#8b00ff',
                }
            },

            'kids graduado': {
                Name: 'kids graduado',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#FFFFFF',
                    k2: '#ff0000',
                }
            },

            'gray': {
                Name: 'gray',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#666666',
                    k2: '#666666',
                }
            },
            'aluno': {
                Name: 'aluno',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },
            'aluno forca': {
                Name: 'aluno forca',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },
            'batizado': {
                Name: 'batizado',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },
            'batizado forca': {
                Name: 'batizado forca',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#FFFF00',
                    k2: '#33FF33',
                }
            },
            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#33FF33',
                    c2: '#33FF33',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#33FF33',
                    k2: '#33FF33',
                }
            },
            'graduado forca': {
                Name: 'graduado forca',
                Colors: {
                    c1: '#33FF33',
                    c2: '#33FF33',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#33FF33',
                    k2: '#0000FF',
                }
            },
            'formado': {
                Name: 'formado',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },
            'formado forca': {
                Name: 'formado forca',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#0000FF',
                    k2: '#FF0000',
                }
            },
            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#FF0000',
                    k2: '#FF0000',
                }
            },
            'instructor': {
                Name: 'instructor',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },
            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#000000',
                    k2: '#000000',
                }
            },
            'mestrando': {
                Name: 'mestrando',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#FFFFFF',
                    k2: '#000000',
                }
            },
            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#FF0000',
                    k2: '#000000',
                }
            },
        }

    },



    2: {
        Id: 2,
        Name: 'Marinheiro',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            '1kids': {
                Name: '1 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#964b00',
                    k2: '#964b00',
                }
            },


            '2kids': {
                Name: '2 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#c53dff',
                    k2: '#c53dff',
                }
            },

            '3kids': {
                Name: '3 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffff00',
                    k2: '#ffff00',
                }
            },

            '4kids': {
                Name: '4 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#1959d1',
                    k2: '#1959d1',
                }
            },

            '5kids': {
                Name: '5 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            '6kids': {
                Name: '6 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#11AA11',
                    k2: '#11AA11',
                }
            },

            '7kids': {
                Name: '7 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#964b00',
                    k2: '#c53dff',
                }
            },

            '8kids': {
                Name: '8 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffff00',
                    k2: '#c53dff',
                }
            },

            '9kids': {
                Name: '9 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffff00',
                    k2: '#1959d1',
                }
            },

            '10kids': {
                Name: '10 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FF7700',
                    k2: '#1959d1',
                }
            },

            '11kids': {
                Name: '11 kids',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FF7700',
                    k2: '#11AA11',
                }
            },

            '1adult': {
                Name: '1 adult',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#FFFFFF',
                    k2: '#33FF33',
                }
            },
            '2adult': {
                Name: '2 adult',
                Colors: {
                    c1: '#33FF33',
                    c2: '#33FF33',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#33FF33',
                    k2: '#33FF33',
                }
            },
            '3adult': {
                Name: '3 adult',
                Colors: {
                    c1: '#964b00',
                    c2: '#964b00',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#964b00',
                    k2: '#33FF33',
                }
            },
            '4adult': {
                Name: '4 adult',
                Colors: {
                    c1: '#964b00',
                    c2: '#964b00',
                    c3: '#964b00',
                    c4: '#964b00',
                    k1: '#964b00',
                    k2: '#964b00',
                }
            },
            'estagario': {
                Name: 'estagario',
                Colors: {
                    c1: '#ffa500',
                    c2: '#ffa500',
                    c3: '#964b00',
                    c4: '#964b00',
                    k1: '#ffa500',
                    k2: '#964b00',
                }
            },
            'formado': {
                Name: 'formado',
                Colors: {
                    c1: '#ffa500',
                    c2: '#ffa500',
                    c3: '#ffa500',
                    c4: '#ffa500',
                    k1: '#ffa500',
                    k2: '#ffa500',
                }
            },
            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#1959d1',
                    c2: '#1959d1',
                    c3: '#ffa500',
                    c4: '#ffa500',
                    k1: '#1959d1',
                    k2: '#ffa500',
                }
            },
            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#1959d1',
                    c2: '#1959d1',
                    c3: '#1959d1',
                    c4: '#1959d1',
                    k1: '#1959d1',
                    k2: '#1959d1',
                }
            },
            '1instructor': {
                Name: '1 instructor',
                Colors: {
                    c1: '#d9d507',
                    c2: '#d9d507',
                    c3: '#ffa500',
                    c4: '#ffa500',
                    k1: '#d9d507',
                    k2: '#ffa500',
                }
            },
            '2instructor': {
                Name: '2 instructor',
                Colors: {
                    c1: '#d9d507',
                    c2: '#d9d507',
                    c3: '#d9d507',
                    c4: '#d9d507',
                    k1: '#d9d507',
                    k2: '#d9d507',
                }
            },
            '1professor': {
                Name: '1 professor',
                Colors: {
                    c1: '#c53dff',
                    c2: '#c53dff',
                    c3: '#d9d507',
                    c4: '#d9d507',
                    k1: '#c53dff',
                    k2: '#d9d507',
                }
            },
            '2professor': {
                Name: '2 professor',
                Colors: {
                    c1: '#c53dff',
                    c2: '#c53dff',
                    c3: '#c53dff',
                    c4: '#c53dff',
                    k1: '#c53dff',
                    k2: '#c53dff',
                }
            },
            'contramestre': {
                Name: 'contra-mestre',
                Colors: {
                    c1: '#5e225e',
                    c2: '#5e225e',
                    c3: '#c53dff',
                    c4: '#c53dff',
                    k1: '#5e225e',
                    k2: '#c53dff',
                }
            },
            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#5e225e',
                    c2: '#5e225e',
                    c3: '#5e225e',
                    c4: '#5e225e',
                    k1: '#5e225e',
                    k2: '#5e225e',
                }
            },
        }
    },


    3: {
        Id: 3,
        Name: 'AXÉ capoeira',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'infantil crua': {
                Name: 'Infantil crua',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#ffffe0',
                    k2: '#ffffe0',
                }
            },

            'ponta cinza': {
                Name: 'ponta cinza',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#666666',
                    k2: '#666666',
                }
            },

            'crua e cinza': {
                Name: 'crua e cinza',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#666666',
                    k2: '#ffffe0',
                }
            },

            'cinza': {
                Name: 'cinza',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#666666',
                    k2: '#666666',
                }
            },

            'cinza ponta laranja': {
                Name: 'cinza ponta laranja',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'cinza e laranja': {
                Name: 'cinza e laranja',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#FF7700',
                    k2: '#666666',
                }
            },

            'laranja': {
                Name: 'laranja',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'laranja ponta azul': {
                Name: 'laranja ponta azul',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#0f93ff',
                    k2: '#0f93ff',
                }
            },

            'laranja e azul': {
                Name: 'laranja e azul',
                Colors: {
                    c1: '#0f93ff',
                    c2: '#0f93ff',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#0f93ff',
                    k2: '#FF7700',
                }
            },

            'crua': {
                Name: 'crua',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#ffffe0',
                    k2: '#ffffe0',
                }
            },

            'crua ponta azul': {
                Name: 'crua ponta azul',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#0f93ff',
                    k2: '#0f93ff',
                }
            },

            'crua e azul': {
                Name: 'crua e azul',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#0f93ff',
                    c4: '#0f93ff',
                    k1: '#ffffe0',
                    k2: '#0f93ff',
                }
            },

            'azul': {
                Name: 'azul',
                Colors: {
                    c1: '#0f93ff',
                    c2: '#0f93ff',
                    c3: '#0f93ff',
                    c4: '#0f93ff',
                    k1: '#0f93ff',
                    k2: '#0f93ff',
                }
            },

            'azul ponta marrom': {
                Name: 'azul ponta marrom',
                Colors: {
                    c1: '#0f93ff',
                    c2: '#0f93ff',
                    c3: '#0f93ff',
                    c4: '#0f93ff',
                    k1: '#663300',
                    k2: '#663300',
                }
            },

            'azul e marrom': {
                Name: 'azul e marrom',
                Colors: {
                    c1: '#0f93ff',
                    c2: '#0f93ff',
                    c3: '#663300',
                    c4: '#663300',
                    k1: '#0f93ff',
                    k2: '#663300',
                }
            },

            'marrom': {
                Name: 'marrom',
                Colors: {
                    c1: '#663300',
                    c2: '#663300',
                    c3: '#663300',
                    c4: '#663300',
                    k1: '#663300',
                    k2: '#663300',
                }
            },

            'marrom ponta verde': {
                Name: 'marrom ponta verde',
                Colors: {
                    c1: '#663300',
                    c2: '#663300',
                    c3: '#663300',
                    c4: '#663300',
                    k1: '#004d00',
                    k2: '#004d00',
                }
            },

            'marrom e verde': {
                Name: 'marrom e verde',
                Colors: {
                    c1: '#663300',
                    c2: '#663300',
                    c3: '#004d00',
                    c4: '#004d00',
                    k1: '#663300',
                    k2: '#004d00',
                }
            },

            'estagiãrio': {
                Name: 'estagiãrio',
                Colors: {
                    c1: '#663300',
                    c2: '#663300',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#663300',
                    k2: '#ffffe0',
                }
            },

            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#004d00',
                    c2: '#004d00',
                    c3: '#004d00',
                    c4: '#004d00',
                    k1: '#004d00',
                    k2: '#004d00',
                }
            },

            'graduado 2°grau': {
                Name: 'graduado 2°grau',
                Colors: {
                    c1: '#004d00',
                    c2: '#004d00',
                    c3: '#004d00',
                    c4: '#004d00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#004d00',
                    c2: '#004d00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#004d00',
                    k2: '#FFFF00',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'professor 2°grau': {
                Name: 'professor 2°grau',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#FFFF00',
                    k2: '#8b00ff',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'mestrando': {
                Name: 'mestrando',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#8b00ff',
                    k2: '#ff0000',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#ff0000',
                    k2: '#ff0000',
                }
            },

            'mestre 2°grau': {
                Name: 'mestre 2°grau',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ff0000',
                    k2: '#ffffff',
                }
            },

            'grão mestre': {
                Name: 'grão mestre',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#ffffff',
                }
            },
        }
    },



    4: {
        Id: 4,
        Name: 'Mundo capoeira',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids aluno iniciante 1': {
                Name: 'kids aluno iniciante 1',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#ffffff',
                }
            },

            'kids aluno iniciante 2': {
                Name: 'kids aluno iniciante 2',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#FFFF00',
                }
            },

            'kids aluno iniciante 3': {
                Name: 'kids aluno iniciante 3',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids aluno intermediario 2': {
                Name: 'kids aluno intermediario 2',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#FF7700',
                }
            },

            'kids aluno intermediario 3': {
                Name: 'kids aluno intermediario 3',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'kids aluno avancado 1': {
                Name: 'kids aluno avancado 1',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#000066',
                }
            },

            'kids aluno avancado 2': {
                Name: 'kids aluno avancado 2',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#000066',
                    k2: '#000066',
                }
            },

            'kids aluno avancado 3': {
                Name: 'kids aluno avancado 3',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#008000',
                }
            },

            
            'kids aluno avancado 4': {
                Name: 'kids aluno avancado 4',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#008000',
                    k2: '#008000',
                }
            },

            'kids aluno avancado 5': {
                Name: 'kids aluno avancado 5',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#8b00ff',
                }
            },

            'kids aluno avancado 6': {
                Name: 'kids aluno avancado 6',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'kids aluno avancado 7': {
                Name: 'kids aluno avancado 7',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#7b3f00',
                }
            },

            'kids aluno avancado 8': {
                Name: 'kids aluno avancado 8',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'adults aluno iniciante 1': {
                Name: 'adults aluno iniciante 1',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#ffffff',
                }
            },

            'adults aluno iniciante 2': {
                Name: 'adults aluno iniciante 2',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#ffffff',
                    k2: '#FFFF00',
                }
            },

            'adults aluno intermediario 1': {
                Name: 'adults aluno intermediario 1',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'adults aluno intermediario 2': {
                Name: 'adults aluno intermediario 2',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FF7700',
                    k2: '#FFFF00',
                }
            },

            'adults aluno avancado 1': {
                Name: 'adults aluno avancado 1',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FF7700',
                    k2: '#ffffff',
                }
            },

            'adults aluno avancado 2': {
                Name: 'adults aluno avancado 2',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#000066',
                    c4: '#000066',
                    k1: '#FF7700',
                    k2: '#000066',
                }
            },

            'graduado 1': {
                Name: 'graduado 1',
                Colors: {
                    c1: '#000066',
                    c2: '#000066',
                    c3: '#000066',
                    c4: '#000066',
                    k1: '#000066',
                    k2: '#000066',
                }
            },

            'graduado 2': {
                Name: 'graduado 2',
                Colors: {
                    c1: '#000066',
                    c2: '#000066',
                    c3: '#008000',
                    c4: '#008000',
                    k1: '#000066',
                    k2: '#008000',
                }
            },

            'graduado 3': {
                Name: 'graduado 3',
                Colors: {
                    c1: '#008000',
                    c2: '#008000',
                    c3: '#008000',
                    c4: '#008000',
                    k1: '#008000',
                    k2: '#008000',
                }
            },

            'graduado 4': {
                Name: 'graduado 4',
                Colors: {
                    c1: '#008000',
                    c2: '#008000',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#008000',
                    k2: '#8b00ff',
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#8b00ff',
                    k2: '#7b3f00',
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#7b3f00',
                    k2: '#ff0000',
                }
            },

            'mestrando': {
                Name: 'mestrando',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#ff0000',
                    k2: '#ff0000',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#000000',
                    k2: '#000000',
                }
            },
        }
    },

    5: {
        Id: 5,
        Name: 'Alcateia capoeira',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'anos 1': {
                Name: 'anos 1',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#666666',
                    k2: '#666666',
                }
            },

            'anos 2': {
                Name: 'anos 2',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#666666',
                    k2: '#FFFF00',
                }
            },

            'anos 3': {
                Name: 'anos 3',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'anos 4': {
                Name: 'anos 4',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'anos 5': {
                Name: 'anos 5',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'anos 6': {
                Name: 'anos 6',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#FF7700',
                    k2: '#00ff00',
                }
            },

            'anos 7': {
                Name: 'anos 7',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#00ff00',
                    k2: '#00ff00',
                }
            },

            'anos 8': {
                Name: 'anos 8',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#00ff00',
                    k2: '#0000ff',
                }
            },

            'anos 9': {
                Name: 'anos 9',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#0000ff',
                    k2: '#0000ff',
                }
            },

            'anos 10': {
                Name: 'anos 10',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#ffffff',
                    k2: '#666666',
                }
            },

            'anos 11': {
                Name: 'anos 11',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#ffffff',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#666666',
                }
            },

            'anos 12': {
                Name: 'anos 12',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#ffffff',
                    k2: '#FFFF00',
                }
            },

            'anos 13': {
                Name: 'anos 13',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#ffffff',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FFFF00',
                }
            },

            'anos 14': {
                Name: 'anos 14',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#ffffff',
                    k2: '#FF7700',
                }
            },

            'anos 15': {
                Name: 'anos 15',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#ffffff',
                    c4: '#00ff00',
                    k1: '#00ff00',
                    k2: '#FF7700',
                }
            },

            'anos 16': {
                Name: 'anos 16',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#ffffff',
                    k2: '#00ff00',
                }
            },

            'anos 17': {
                Name: 'anos 17',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#ffffff',
                    c4: '#0000ff',
                    k1: '#0000ff',
                    k2: '#00ff00',
                }
            },

            'anos 18': {
                Name: 'anos 18',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#ffffff',
                    k2: '#0000ff',
                }
            },

            'crua': {
                Name: 'crua',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#ffffe0',
                    k2: '#ffffe0',
                }
            },

            'cinza': {
                Name: 'cinza',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#666666',
                    k2: '#666666',
                }
            },

            'cinza amareia': {
                Name: 'cinza amareia',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#666666',
                    k2: '#FFFF00',
                }
            },

            'amareia': {
                Name: 'amareia',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'amareia laranja': {
                Name: 'amareia laranja',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'laranja': {
                Name: 'laranja',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'laranja varde': {
                Name: 'laranja varde',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#FF7700',
                    k2: '#00ff00',
                }
            },

            'graduado 1': {
                Name: 'graduado 1',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#00ff00',
                    k2: '#00ff00',
                }
            },

            'graduado 2': {
                Name: 'graduado 2',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#00ff00',
                    k2: '#0000ff',
                }
            },

            'graduado 3': {
                Name: 'graduado 3',
                Colors: {
                    c1: '#0000ff',
                    c2: '#0000ff',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#0000ff',
                    k2: '#0000ff',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#8b00ff',
                    k2: '#0000ff',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#8b00ff',
                    k2: '#7b3f00',
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'professor 3': {
                Name: 'professor 3',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#ad102f',
                    c4: '#ad102f',
                    k1: '#7b3f00',
                    k2: '#ad102f',
                }
            },

            'contramestre 1': {
                Name: 'contramestre 1',
                Colors: {
                    c1: '#ad102f',
                    c2: '#ad102f',
                    c3: '#ad102f',
                    c4: '#ad102f',
                    k1: '#ad102f',
                    k2: '#ad102f',
                }
            },

            'contramestre 2': {
                Name: 'contramestre 2',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#ad102f',
                    c4: '#ad102f',
                    k1: '#000000',
                    k2: '#ad102f',
                }
            },

            'contramestre 3': {
                Name: 'contramestre 3',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#000000',
                    k2: '#ff0000',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#ff0000',
                    k2: '#ff0000',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ff0000',
                    k2: '#ffffff',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#000000',
                    k2: '#000000',
                }
            },

            'estagiario': {
                Name: 'estagiario',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#ffff00',
                    c4: '#ffff00',
                    k1: '#000000',
                    k2: '#ffff00',
                }
            },
        }
    },

    //////////////////////////////////////////////////////////////////////////////

    6: {
        Id: 6,
        Name: 'РЦК CDO',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },


    //////////////////////////////////////////////////////////////////////////////

    7: {
        Id: 7,
        Name: 'Arte capoeira',
        Gradation: {


            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            // ОРАНЖЕВЫЙ #FF7700
            // ФИОЛЕТОВЫЙ #8b00ff
            // КОРИЧНЕВЫЙ #7b3f00
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'kids 7': {
                Name: 'kids 7',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'kids 8': {
                Name: 'kids 8',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'kids 9': {
                Name: 'kids 9',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'teen 1': {
                Name: 'teen 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'teen 2': {
                Name: 'teen 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'teen 3': {
                Name: 'teen 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'teen 4': {
                Name: 'teen 4',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFFFF',
                    k2: '#FF7700',
                }
            },

            'teen 5': {
                Name: 'teen 5',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'teen 6': {
                Name: 'teen 6',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'teen 7': {
                Name: 'teen 7',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'teen 8': {
                Name: 'teen 8',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'teen 9': {
                Name: 'teen 9',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'aluno 1': {
                Name: 'aluno 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'aluno 2': {
                Name: 'aluno 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'aluno 3': {
                Name: 'aluno 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'aluno 4': {
                Name: 'aluno 4',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'aluno 5': {
                Name: 'aluno 5',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },


            'aluno 6': {
                Name: 'aluno 6',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'graduado 1': {
                Name: 'graduado 1',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'graduado 2': {
                Name: 'graduado 2',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#0000FF',
                    k2: '#00FF00',
                }
            },

            'monitor 1': {
                Name: 'monitor 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'monitor 2': {
                Name: 'monitor 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#00FF00',
                    k2: '#8b00ff',
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#8b00ff',
                    k2: '#7b3f00',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'formando': {
                Name: 'formando',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#7b3f00',
                    k2: '#FF0000',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#FF0000',
                    k2: '#FF0000',
                }
            },
        }
    },

    8: {
        Id: 8,
        Name: 'Portao de ouro',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids minitor': {
                Name: 'kids minitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#87cefa',
                    c4: '#ffffad',
                    k1: '#87cefa',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },



    ///////////////////////////// MUZENZA ////////////////////////////////

    9: {
        Id: 9,
        Name: 'Muzenza',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#ffffff',
                    k2: '#666666',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#ffffff',
                    k2: '#FFFF00',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#ffffff',
                    k2: '#FF7700',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#ffffff',
                    k2: '#00ff00',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#ffffff',
                    k2: '#ff0000',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#ffffff',
                    k2: '#0000ff',
                }
            },

            'kids 7': {
                Name: 'kids 7',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#666666',
                    k2: '#FF7700',
                }
            },

            'kids 8': {
                Name: 'kids 8',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#666666',
                    k2: '#00ff00',
                }
            },

            'kids 9': {
                Name: 'kids 9',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#666666',
                    k2: '#ff0000',
                }
            },

            'kids 10': {
                Name: 'kids 10',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#666666',
                    k2: '#0000ff',
                }
            },

            'kids 11': {
                Name: 'kids 11',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#FFFF00',
                    k2: '#00ff00',
                }
            },

            'kids 12': {
                Name: 'kids 12',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#FFFF00',
                    k2: '#ff0000',
                }
            },

            'kids 13': {
                Name: 'kids 13',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#FFFF00',
                    k2: '#0000ff',
                }
            },

            'kids 14': {
                Name: 'kids 14',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#FF7700',
                    k2: '#ff0000',
                }
            },

            'kids 15': {
                Name: 'kids 15',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#FF7700',
                    k2: '#0000ff',
                }
            },

            'kids 16': {
                Name: 'kids 16',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#00ff00',
                    k2: '#0000ff',
                }
            },

            'cinza': {
                Name: 'cinza',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#666666',
                    k2: '#666666',
                }
            },

            'cinza amarela': {
                Name: 'cinza amarela',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#666666',
                    k2: '#FFFF00',
                }
            },

            'amarela': {
                Name: 'amarela',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'amarela laranja': {
                Name: 'amarela laranja',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'laranja': {
                Name: 'laranja',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'laranja varde': {
                Name: 'laranja varde',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#FF7700',
                    k2: '#00ff00',
                }
            },

            'varde': {
                Name: 'varde',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#00ff00',
                    k2: '#00ff00',
                }
            },

            'varde varmelho': {
                Name: 'varde varmelho',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#00ff00',
                    k2: '#ff0000',
                }
            },

            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#00ff00',
                    k2: '#0000ff',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#0000ff',
                    c2: '#0000ff',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#0000ff',
                    k2: '#ff0000',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000ff',
                    c2: '#0000ff',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#0000ff',
                    k2: '#0000ff',
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#ff0000',
                    k2: '#8b00ff',
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#ff0000',
                    k2: '#7b3f00',
                }
            },

            'professor 3': {
                Name: 'professor 3',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#ff0000',
                    k2: '#000000',
                }
            },

            'contramestre 1': {
                Name: 'contramestre 1',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'contramestre 2': {
                Name: 'contramestre 2',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#8b00ff',
                    k2: '#7b3f00',
                }
            },

            'contramestre 3': {
                Name: 'contramestre 3',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#ff0000',
                    k2: '#ff0000',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#000000',
                    k2: '#000000',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#ad102f',
                    c2: '#ad102f',
                    c3: '#ad102f',
                    c4: '#ad102f',
                    k1: '#ad102f',
                    k2: '#ad102f',
                }
            },

            'mestre 4': {
                Name: 'mestre 4',
                Colors: {
                    c1: '#ad102f',
                    c2: '#ad102f',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ad102f',
                    k2: '#ffffff',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#ffffff',
                }
            },

            'estagiario': {
                Name: 'estagiario',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#ffff00',
                    c4: '#ffff00',
                    k1: '#000000',
                    k2: '#ffff00',
                }
            },
        }
    },


    //////////////////////////////////////////////////////////////////////////////

    10: {
        Id: 10,
        Name: 'Сapoeira Camara',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 0': {
                Name: 'kids 0',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'kids 7': {
                Name: 'kids 7',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#00ff00',
                }
            },

            'kids 8': {
                Name: 'kids 8',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#00ff00',
                    k2: '#00ff00',
                }
            },

            'crua': {
                Name: 'crua',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'crua amarelo': {
                Name: 'crua amarelo',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'amarelo': {
                Name: 'amarelo',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'amarelo laranja': {
                Name: 'amarelo laranja',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'laranja': {
                Name: 'laranja',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },


            'laranja azul': {
                Name: 'laranja azul',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'monitor 1': {
                Name: 'monitor 1',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#0000FF',
                    k2: '#00FF00',
                }
            },

            'monitor 2': {
                Name: 'monitor 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#00FF00',
                    k2: '#8b00ff',
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#8b00ff',
                    k2: '#7b3f00',
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#7b3f00',
                    k2: '#FF0000',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#FF0000',
                    k2: '#FF0000',
                }
            },

        }
    },

    11: {
        Id: 11,
        Name: 'Capoeira Angola',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },
        }
    },


    12: {
        Id: 12,
        Name: 'Afro-Ritmo Capoeira',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },


    ////////////////////////////////// RAIZ

    13: {
        Id: 13,
        Name: 'Raiz Сapoeira',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 0': {
                Name: 'kids 0',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#ffffe0',
                    k2: '#ffffe0',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#FF7700',
                    k2: '#ffffe0',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#FF7700',
                    k2: '#666666',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#663300',
                    c4: '#663300',
                    k1: '#FF7700',
                    k2: '#663300',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#FF7700',
                    k2: '#00ff00',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FF7700',
                    k2: '#FFFF00',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'kids 7': {
                Name: 'kids 7',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'caloiro 1': {
                Name: 'caloiro 1',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#ffffe0',
                    k2: '#ffffe0',
                }
            },

            'caloiro 2': {
                Name: 'caloiro 2',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#FF7700',
                    k2: '#ffffe0',
                }
            },

            'aluno 1': {
                Name: 'aluno 2',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#ffffe0',
                    k2: '#666666',
                }
            },

            'aluno 2': {
                Name: 'aluno 2',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#663300',
                    c4: '#663300',
                    k1: '#ffffe0',
                    k2: '#663300',
                }
            },

            'aluno 3': {
                Name: 'aluno 3',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#ffffe0',
                    k2: '#00ff00',
                }
            },


            'aluno 4': {
                Name: 'aluno 4',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#00ff00',
                    k2: '#00ff00',
                }
            },

            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#00ff00',
                    c2: '#00ff00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#00ff00',
                    k2: '#FFFF00',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#ffffe0',
                    c2: '#ffffe0',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#ffffe0',
                    k2: '#FFFF00',
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: '#0000ff',
                    c2: '#0000ff',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#0000ff',
                    k2: '#FFFF00',
                }
            },

            'instrutor 3': {
                Name: 'instrutor 3',
                Colors: {
                    c1: '#0000ff',
                    c2: '#0000ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#0000ff',
                    k2: '#8b00ff',
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: '#0000ff',
                    c2: '#0000ff',
                    c3: '#ffffe0',
                    c4: '#ffffe0',
                    k1: '#0000ff',
                    k2: '#ffffe0',
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: '#0000ff',
                    c2: '#0000ff',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#0000ff',
                    k2: '#0000ff',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00ff00',
                    c2: '#FFFF00',
                    c3: '#0000ff',
                    c4: '#00ff00',
                    k1: '#FFFF00',
                    k2: '#0000ff',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#FFFFFF',
                    k2: '#00ff00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#FFFFFF',
                    k2: '#0000ff',
                }
            },

            'mestre 4': {
                Name: 'mestre 4',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    14: {
        Id: 14,
        Name: 'FICAG',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },
        }
    },

    15: {
        Id: 15,
        Name: 'FGR Capoeira',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'infantil 1': {
                Name: 'infantil 1',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#008000',
                    k2: '#008000',
                }
            },

            'infantil 2': {
                Name: 'infantil 2',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffff00',
                    k2: '#ffff00',
                }
            },

            'infantil 3': {
                Name: 'infantil 3',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#000099',
                    k2: '#000099',
                }
            },

            'infantil 4': {
                Name: 'infantil 4',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#008000',
                    k2: '#ffff00',
                }
            },

            'infantil 5': {
                Name: 'infantil 5',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#008000',
                    k2: '#000099',
                }
            },

            'infantil 6': {
                Name: 'infantil 6',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffff00',
                    k2: '#000099',
                }
            },


            'adaptacao': {
                Name: 'adaptacao',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#7b3f00',
                    k2: '#ffffff',
                }
            },

            'aluno 1': {
                Name: 'aluno 1',
                Colors: {
                    c1: '#008000',
                    c2: '#008000',
                    c3: '#008000',
                    c4: '#008000',
                    k1: '#008000',
                    k2: '#008000',
                }
            },

            'aluno 2': {
                Name: 'aluno 2',
                Colors: {
                    c1: '#ffff00',
                    c2: '#ffff00',
                    c3: '#ffff00',
                    c4: '#ffff00',
                    k1: '#ffff00',
                    k2: '#ffff00',
                }
            },

            'aluno 3': {
                Name: 'aluno 3',
                Colors: {
                    c1: '#000099',
                    c2: '#000099',
                    c3: '#000099',
                    c4: '#000099',
                    k1: '#000099',
                    k2: '#000099',
                }
            },

            'aluno 4': {
                Name: 'aluno 4',
                Colors: {
                    c1: '#008000',
                    c2: '#008000',
                    c3: '#ffff00',
                    c4: '#ffff00',
                    k1: '#008000',
                    k2: '#ffff00',
                }
            },

            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#008000',
                    c2: '#008000',
                    c3: '#000099',
                    c4: '#000099',
                    k1: '#008000',
                    k2: '#000099',
                }
            },

            'estagiario': {
                Name: 'estagiario',
                Colors: {
                    c1: '#ffff00',
                    c2: '#ffff00',
                    c3: '#000099',
                    c4: '#000099',
                    k1: '#ffff00',
                    k2: '#000099',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#008000',
                    c2: '#000099',
                    c3: '#000099',
                    c4: '#ffff00',
                    k1: '#008000',
                    k2: '#ffff00',
                }
            },


            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#008000',
                    c2: '#008000',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#008000',
                    k2: '#ffffff',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#ffff00',
                    c2: '#ffff00',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffff00',
                    k2: '#ffffff',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#000099',
                    c2: '#000099',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#000099',
                    k2: '#ffffff',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#ffffff',
                    c2: '#ffffff',
                    c3: '#ffffff',
                    c4: '#ffffff',
                    k1: '#ffffff',
                    k2: '#ffffff',
                }
            },
        }
    },

    16: {
        Id: 16,
        Name: 'Vem Camara',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 3-6 1°': {
                Name: 'kids 3-6 1°',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'kids 3-6 2°': {
                Name: 'kids 3-6 2°',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids 3-6 3°': {
                Name: 'kids 3-6 3°',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'kids 3-6 4°': {
                Name: 'kids 3-6 4°',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'kids 7+ 1°': {
                Name: 'kids 7+ 1°',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFFFF',
                }
            },

            'kids 7+ 2°': {
                Name: 'kids 7+ 2°',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids 7+ 3°': {
                Name: 'kids 7+ 3°',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'kids 7+ 4°': {
                Name: 'kids 7+ 4°',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'kids 10+ 1°': {
                Name: 'kids 10+ 1°',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FFFFFF',
                }
            },

            'kids 10+ 2°': {
                Name: 'kids 10+ 2°',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FFFF00',
                }
            },

            'kids 10+ 3°': {
                Name: 'kids 10+ 3°',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'kids 10+ 4°': {
                Name: 'kids 10+ 4°',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'kids 13+ 1°': {
                Name: 'kids 13+ 1°',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#FFFFFF',
                }
            },

            'kids 13+ 2°': {
                Name: 'kids 13+ 2°',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#FFFF00',
                }
            },

            'kids 13+ 3°': {
                Name: 'kids 13+ 3°',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#FF7700',
                }
            },

            'kids 13+ 4°': {
                Name: 'kids 13+ 4°',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'kids 13+ 5°': {
                Name: 'kids 13+ 5°',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#666666',
                    k2: '#666666',
                }
            },

            'adults 1°': {
                Name: 'adults 1°',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'adults 2°': {
                Name: 'adults 2°',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFFFF',
                }
            },

            'adults 3°': {
                Name: 'adults 3°',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'adults 4°': {
                Name: 'adults 4°',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'adults 5°': {
                Name: 'adults 5°',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'adults 6°': {
                Name: 'adults 6°',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#0000FF',
                    k2: '#FF7700',
                }
            },

            'graduado 1°': {
                Name: 'graduado 1°',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'graduado 2°': {
                Name: 'graduado 2°',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#00FF00',
                    k2: '#0000FF',
                }
            },

            'graduado 3°': {
                Name: 'graduado 3°',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'graduado 4°': {
                Name: 'graduado 4°',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#00FF00',
                    k2: '#8b00ff',
                }
            },

            'instrutor 1°': {
                Name: 'instrutor 1°',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'instrutor 2°': {
                Name: 'instrutor 2°',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#7b3f00',
                    k2: '#8b00ff',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'contra-mestre': {
                Name: 'contra-mestre',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#ff0000',
                    k2: '#7b3f00',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#ff0000',
                    k2: '#ff0000',
                }
            }
        }
    },


    17: {
        Id: 17,
        Name: 'Sabotage Capoeira',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'cordao 1': {
                Name: 'cordao 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'cordao 2': {
                Name: 'cordao 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'cordao 3': {
                Name: 'cordao 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffad',
                    k2: '#90ee90',
                }
            },

            'cordao 4': {
                Name: 'cordao 4',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'cordao 5': {
                Name: 'cordao 5',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'cordao 6': {
                Name: 'cordao 6',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'cordao 7': {
                Name: 'cordao 7',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'cordao 8': {
                Name: 'cordao 8',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'cordao 9': {
                Name: 'cordao 9',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'cordao 10': {
                Name: 'cordao 10',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'cordao 11': {
                Name: 'cordao 11',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'cordao 12': {
                Name: 'cordao 12',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'cordao 13': {
                Name: 'cordao 13',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'cordao 14': {
                Name: 'cordao 14',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },
        }
    },

    18: {
        Id: 18,
        Name: 'CDO SPB',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },


    19: {
        Id: 19,
        Name: 'Palmares',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'cordao 1': {
                Name: 'cordao 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'cordao 2': {
                Name: 'cordao 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'cordao 3': {
                Name: 'cordao 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffad',
                    k2: '#90ee90',
                }
            },

            'cordao 4': {
                Name: 'cordao 4',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'cordao 5': {
                Name: 'cordao 5',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'cordao 6': {
                Name: 'cordao 6',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'cordao 7': {
                Name: 'cordao 7',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'cordao 8': {
                Name: 'cordao 8',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'cordao 9': {
                Name: 'cordao 9',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'cordao 10': {
                Name: 'cordao 10',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'cordao 11': {
                Name: 'cordao 11',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'cordao 12': {
                Name: 'cordao 12',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'cordao 13': {
                Name: 'cordao 13',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'cordao 14': {
                Name: 'cordao 14',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },
        }
    },

    20: {
        Id: 20,
        Name: 'CDO',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    21: {
        Id: 21,
        Name: 'Abada Сapoeira',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'iniciante': {
                Name: 'iniciante',
                Colors: {
                    c1: '#cccccc',
                    c2: '#cccccc',
                    c3: '#cccccc',
                    c4: '#cccccc',
                    k1: '#cccccc',
                    k2: '#cccccc',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'crua amarelo': {
                Name: 'crua amarelo',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'amarelo': {
                Name: 'amarelo',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'crua laranja': {
                Name: 'crua laranja',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFFFF',
                    k2: '#FF7700',
                }
            },

            'amarelo laranja': {
                Name: 'amarelo laranja',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'laranja': {
                Name: 'laranja',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'crua azul': {
                Name: 'crua azul',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'amarelo azul': {
                Name: 'amarelo azul',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'laranja azul': {
                Name: 'laranja azul',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'graduado 1': {
                Name: 'graduado 1',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'graduado 2': {
                Name: 'graduado 2',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#0000FF',
                    k2: '#00FF00',
                }
            },

            'graduado 3': {
                Name: 'graduado 3',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'graduado 4': {
                Name: 'graduado 4',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#00FF00',
                    k2: '#8b00ff',
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#8b00ff',
                    k2: '#7b3f00',
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#7b3f00',
                    k2: '#FF0000',
                }
            },

            'mestrando': {
                Name: 'mestrando',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#FF0000',
                    k2: '#FF0000',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF0000',
                    k2: '#FFFFFF',
                }
            },


            'grao-mestre': {
                Name: 'grao-mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    22: {
        Id: 22,
        Name: 'Real Capoeira',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    23: {
        Id: 23,
        Name: 'ACMB',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'aluno 1': {
                Name: 'aluno 1',
                Colors: {
                    c1: VERDE_CLARO,
                    c2: VERDE_CLARO,
                    c3: VERDE_CLARO,
                    c4: VERDE_CLARO,
                    k1: VERDE_CLARO,
                    k2: VERDE_CLARO,
                }
            },

            'aluno 2': {
                Name: 'aluno 2',
                Colors: {
                    c1: VERDE_CLARO,
                    c2: VERDE_CLARO,
                    c3: VERDE,
                    c4: VERDE,
                    k1: VERDE_CLARO,
                    k2: VERDE,
                }
            },

            'aluno 3': {
                Name: 'aluno 3',
                Colors: {
                    c1: VERDE,
                    c2: VERDE,
                    c3: VERDE,
                    c4: VERDE,
                    k1: VERDE,
                    k2: VERDE,
                }
            },

            'aluno 4': {
                Name: 'aluno 4',
                Colors: {
                    c1: VERDE,
                    c2: VERDE,
                    c3: VERDE,
                    c4: VERDE,
                    k1: VERDE,
                    k2: AMARELA,
                }
            },

            'aluno 5': {
                Name: 'aluno 5',
                Colors: {
                    c1: AMARELA,
                    c2: AMARELA,
                    c3: AMARELA,
                    c4: AMARELA,
                    k1: AMARELA,
                    k2: AMARELA,
                }
            },

            'aluno 6': {
                Name: 'aluno 6',
                Colors: {
                    c1: AMARELA,
                    c2: AMARELA,
                    c3: AMARELA,
                    c4: AMARELA,
                    k1: AMARELA,
                    k2: AZUL,
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: AZUL,
                    c2: AZUL,
                    c3: AZUL,
                    c4: AZUL,
                    k1: AZUL,
                    k2: AZUL,
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: AZUL,
                    c2: AZUL,
                    c3: AZUL,
                    c4: AZUL,
                    k1: AZUL,
                    k2: AMARELA,
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: AZUL,
                    c2: AZUL,
                    c3: AZUL,
                    c4: AZUL,
                    k1: AZUL,
                    k2: VARMELHA,
                }
            },

            'formado': {
                Name: 'formado',
                Colors: {
                    c1: VARMELHA,
                    c2: VARMELHA,
                    c3: VARMELHA,
                    c4: VARMELHA,
                    k1: VARMELHA,
                    k2: VARMELHA,
                }
            },

            'formado 1': {
                Name: 'formado 1',
                Colors: {
                    c1: VARMELHA,
                    c2: VARMELHA,
                    c3: VARMELHA,
                    c4: VARMELHA,
                    k1: VARMELHA,
                    k2: AZUL,
                }
            },

            'estagiario 1': {
                Name: 'estagiario 1',
                Colors: {
                    c1: VERDE,
                    c2: VERDE,
                    c3: AMARELA,
                    c4: AMARELA,
                    k1: VERDE,
                    k2: AMARELA,
                }
            },

            'estagiario 2': {
                Name: 'estagiario 2',
                Colors: {
                    c1: VERDE,
                    c2: VERDE,
                    c3: AMARELA,
                    c4: AMARELA,
                    k1: VARMELHA,
                    k2: AMARELA,
                }
            },

            'estagiario 3': {
                Name: 'estagiario 3',
                Colors: {
                    c1: VARMELHA,
                    c2: VARMELHA,
                    c3: AZUL,
                    c4: AZUL,
                    k1: VARMELHA,
                    k2: AZUL,
                }
            },

            'estagiario 3 am': {
                Name: 'estagiario 3 am',
                Colors: {
                    c1: VARMELHA,
                    c2: VARMELHA,
                    c3: AZUL,
                    c4: AZUL,
                    k1: AMARELA,
                    k2: AZUL,
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: VERDE,
                    c2: VERDE,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: VERDE,
                    k2: BRANCO,
                }
            },

            'professor 1 az': {
                Name: 'professor 1 az',
                Colors: {
                    c1: VERDE,
                    c2: VERDE,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: AZUL,
                    k2: BRANCO,
                }
            },

            'professor 1 ve': {
                Name: 'professor 1 ve',
                Colors: {
                    c1: VERDE,
                    c2: VERDE,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: VARMELHA,
                    k2: BRANCO,
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: AMARELA,
                    c2: AMARELA,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: AMARELA,
                    k2: BRANCO,
                }
            },

            'professor 2 az': {
                Name: 'professor 2 az',
                Colors: {
                    c1: AMARELA,
                    c2: AMARELA,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: AZUL,
                    k2: BRANCO,
                }
            },

            'professor 2 ve': {
                Name: 'professor 2 ve',
                Colors: {
                    c1: AMARELA,
                    c2: AMARELA,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: VARMELHA,
                    k2: BRANCO,
                }
            },

            'professor 3': {
                Name: 'professor 3',
                Colors: {
                    c1: AZUL,
                    c2: AZUL,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: AZUL,
                    k2: BRANCO,
                }
            },

            'professor 3 ve': {
                Name: 'professor 3 ve',
                Colors: {
                    c1: AZUL,
                    c2: AZUL,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: VARMELHA,
                    k2: BRANCO,
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: VARMELHA,
                    c2: VARMELHA,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: VARMELHA,
                    k2: BRANCO,
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: BRANCO,
                    c2: BRANCO,
                    c3: BRANCO,
                    c4: BRANCO,
                    k1: BRANCO,
                    k2: BRANCO,
                }
            },
            /*
            const VERDE_CLARO = '#90ee90';
 const VERDE = '#00FF00';
 const AMARELA = '#FFFF00';
 const AZUL = '#0000FF';
 const VARMELHA = '#FF0000';
 const BRANCO = '#FFFFFF';
            */

        }
    },

    24: {
        Id: 24,
        Name: 'Senzala',
        Gradation: {

            'cordao 1': {
                Name: 'cordao 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'cordao 2': {
                Name: 'cordao 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'cordao 3': {
                Name: 'cordao 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'cordao 4': {
                Name: 'cordao 4',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFFFF',
                    k2: '#FF7700',
                }
            },

            'cordao 5': {
                Name: 'cordao 5',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'cordao 6': {
                Name: 'cordao 6',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'cordao 7': {
                Name: 'cordao 7',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#FFFFFF',
                    k2: '#666666',
                }
            },

            'cordao 8': {
                Name: 'cordao 8',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#666666',
                    k2: '#666666',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: '#33FF33',
                    c2: '#33FF33',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#33FF33',
                    k2: '#33FF33',
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'contra-mestre': {
                Name: 'contra-mestre',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#ff0000',
                    c2: '#ff0000',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#ff0000',
                    k2: '#ff0000',
                }
            },



            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

        }
    },

    26: {
        Id: 26,
        Name: 'Senzala Chiquinho',
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

        }
    },

    27: {
        Id: 27,
        Name: 'Capoeira de ouro',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    28: {
        Id: 28,
        Name: 'Capoeira beira mar',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'young 1': {
                Name: 'young 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#222222',
                    c3: '#90ee90',
                    c4: '#222222',
                    k1: '#90ee90',
                    k2: '#222222',
                }
            },

            'young 2': {
                Name: 'young 2',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#222222',
                    c3: '#FFFF00',
                    c4: '#222222',
                    k1: '#FFFF00',
                    k2: '#222222',
                }
            },

            'young 3': {
                Name: 'young 3',
                Colors: {
                    c1: '#0000FF',
                    c2: '#222222',
                    c3: '#0000FF',
                    c4: '#222222',
                    k1: '#0000FF',
                    k2: '#222222',
                }
            },

            'kids batizado': {
                Name: 'kids batizado',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'kids 7': {
                Name: 'kids 7',
                Colors: {
                    c1: '#0000FF',
                    c2: '#FFFF00',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'kids 8': {
                Name: 'kids 8',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'kids 9': {
                Name: 'kids 9',
                Colors: {
                    c1: '#0000FF',
                    c2: '#00FF00',
                    c3: '#0000FF',
                    c4: '#00FF00',
                    k1: '#0000FF',
                    k2: '#00FF00',
                }
            },

            'kids 10': {
                Name: 'kids 10',
                Colors: {
                    c1: '#0000FF',
                    c2: '#FFFF00',
                    c3: '#0000FF',
                    c4: '#00FF00',
                    k1: '#0000FF',
                    k2: '#00FF00',
                }
            },

            'batizado': {
                Name: 'batizado',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 4': {
                Name: 'adult 4',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'formado': {
                Name: 'formado',
                Colors: {
                    c1: '#00FF00',
                    c2: '#0000FF',
                    c3: '#00FF00',
                    c4: '#0000FF',
                    k1: '#00FF00',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#00FF00',
                    c3: '#0000FF',
                    c4: '#FFFF00',
                    k1: '#0000FF',
                    k2: '#FFFF00',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },
        }
    },

    29: {
        Id: 29,
        Name: 'Capoeira Dende',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    30: {
        Id: 30,
        Name: 'Сapoeira team',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'iniciante': {
                Name: 'iniciante',
                Colors: {
                    c1: '#cccccc',
                    c2: '#cccccc',
                    c3: '#cccccc',
                    c4: '#cccccc',
                    k1: '#cccccc',
                    k2: '#cccccc',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'crua amarelo': {
                Name: 'crua amarelo',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'amarelo': {
                Name: 'amarelo',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'crua laranja': {
                Name: 'crua laranja',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFFFF',
                    k2: '#FF7700',
                }
            },

            'amarelo laranja': {
                Name: 'amarelo laranja',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },

            'laranja': {
                Name: 'laranja',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },

            'crua azul': {
                Name: 'crua azul',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'amarelo azul': {
                Name: 'amarelo azul',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'laranja azul': {
                Name: 'laranja azul',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },

            'graduado 1': {
                Name: 'graduado 1',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'graduado 2': {
                Name: 'graduado 2',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#0000FF',
                    k2: '#00FF00',
                }
            },

            'graduado 3': {
                Name: 'graduado 3',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'graduado 4': {
                Name: 'graduado 4',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#00FF00',
                    k2: '#8b00ff',
                }
            },

            'instrutor 1': {
                Name: 'instrutor 1',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },

            'instrutor 2': {
                Name: 'instrutor 2',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#8b00ff',
                    k2: '#7b3f00',
                }
            },

            'professor 1': {
                Name: 'professor 1',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },

            'professor 2': {
                Name: 'professor 2',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#7b3f00',
                    k2: '#FF0000',
                }
            },

            'mestrando': {
                Name: 'mestrando',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#FF0000',
                    k2: '#FF0000',
                }
            },

            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FF0000',
                    k2: '#FFFFFF',
                }
            },


            'grao-mestre': {
                Name: 'grao-mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    31: {
        Id: 31,
        Name: 'Vento capoeira',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'child premiero 1': {
                Name: 'kids premiero',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'child premiero 2': {
                Name: 'kids premiero',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#33FF33',
                }
            },

            'kids premiero': {
                Name: 'kids premiero',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

            'kids calouro 1': {
                Name: 'kids calouro 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'kids calouro 2': {
                Name: 'kids calouro 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFFFF',
                    k2: '#FF7700',
                }
            },

            'kids aluno 1': {
                Name: 'kids aluno 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#FFFFFF',
                    k2: '#33FF33',
                }
            },

            'kids aluno 2': {
                Name: 'kids aluno 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#008000',
                    c4: '#008000',
                    k1: '#FFFFFF',
                    k2: '#008000',
                }
            },

            'kids aluno forca 1': {
                Name: 'kids aluno forca 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#42aaff',
                    c4: '#42aaff',
                    k1: '#FFFFFF',
                    k2: '#42aaff',
                }
            },

            'kids aluno forca 2': {
                Name: 'kids aluno forca 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'kids batizado': {
                Name: 'kids batizado',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#FFFFFF',
                    k2: '#666666',
                }
            },

            'kids batizado forca': {
                Name: 'kids batizado forca',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#FFFFFF',
                    k2: '#8b00ff',
                }
            },

            'kids graduado': {
                Name: 'kids graduado',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#FFFFFF',
                    k2: '#ff0000',
                }
            },

            'gray': {
                Name: 'gray',
                Colors: {
                    c1: '#666666',
                    c2: '#666666',
                    c3: '#666666',
                    c4: '#666666',
                    k1: '#666666',
                    k2: '#666666',
                }
            },
            'aluno': {
                Name: 'aluno',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },
            'aluno forca': {
                Name: 'aluno forca',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },
            'batizado': {
                Name: 'batizado',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },
            'batizado forca': {
                Name: 'batizado forca',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#FFFF00',
                    k2: '#33FF33',
                }
            },
            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#33FF33',
                    c2: '#33FF33',
                    c3: '#33FF33',
                    c4: '#33FF33',
                    k1: '#33FF33',
                    k2: '#33FF33',
                }
            },
            'graduado forca': {
                Name: 'graduado forca',
                Colors: {
                    c1: '#33FF33',
                    c2: '#33FF33',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#33FF33',
                    k2: '#0000FF',
                }
            },
            'formado': {
                Name: 'formado',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },
            'formado forca': {
                Name: 'formado forca',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#0000FF',
                    k2: '#FF0000',
                }
            },
            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#FF0000',
                    k2: '#FF0000',
                }
            },
            'instructor': {
                Name: 'instructor',
                Colors: {
                    c1: '#7b3f00',
                    c2: '#7b3f00',
                    c3: '#7b3f00',
                    c4: '#7b3f00',
                    k1: '#7b3f00',
                    k2: '#7b3f00',
                }
            },
            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#000000',
                    c2: '#000000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#000000',
                    k2: '#000000',
                }
            },
            'mestrando': {
                Name: 'mestrando',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#FFFFFF',
                    k2: '#000000',
                }
            },
            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#000000',
                    c4: '#000000',
                    k1: '#FF0000',
                    k2: '#000000',
                }
            },
        }

    },


    32: {
        Id: 32,
        Name: 'Laranja Capoeira',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },


    33: {
        Id: 33,
        Name: 'Gaiola Capoeira',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    34: {
        Id: 34,
        Name: 'GCFV',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'Aluno Iniciante Nivel1': {
                Name: 'Aluno Iniciante Nivel1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },
            'Aluno Iniciante Nivel2': {
                Name: 'Aluno Iniciante Nivel2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },
            'Aluno Iniciante Nivel3': {
                Name: 'Aluno Iniciante Nivel3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },
            'Aluno Atuante Nivel1': {
                Name: 'Aluno Atuante Nivel1',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FFFF00',
                    k2: '#FF7700',
                }
            },
            'Aluno Atuante Nivel2': {
                Name: 'Aluno Atuante Nivel2',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#FF7700',
                    c4: '#FF7700',
                    k1: '#FF7700',
                    k2: '#FF7700',
                }
            },
            'Aluno Atuante Nivel3': {
                Name: 'Aluno Atuante Nivel3',
                Colors: {
                    c1: '#FF7700',
                    c2: '#FF7700',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FF7700',
                    k2: '#0000FF',
                }
            },
            'Monitor': {
                Name: 'Monitor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },
            'Monitor Superior': {
                Name: 'Monitor Superior',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#0000FF',
                    k2: '#FF0000',
                }
            },
            'Formado': {
                Name: 'Formado',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#FF0000',
                    c4: '#FF0000',
                    k1: '#FF0000',
                    k2: '#FF0000',
                }
            },
            'Formado Superior': {
                Name: 'Formado Superior',
                Colors: {
                    c1: '#FF0000',
                    c2: '#FF0000',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#FF0000',
                    k2: '#8b00ff',
                }
            },
            'Instrutor': {
                Name: 'Instrutor',
                Colors: {
                    c1: '#8b00ff',
                    c2: '#8b00ff',
                    c3: '#8b00ff',
                    c4: '#8b00ff',
                    k1: '#8b00ff',
                    k2: '#8b00ff',
                }
            },
            'Professor Nivel1': {
                Name: 'Professor Nivel1',
                Colors: {
                    c1: '#dddddd',
                    c2: '#dddddd',
                    c3: '#00ff00',
                    c4: '#00ff00',
                    k1: '#dddddd',
                    k2: '#00ff00',
                }
            },
            'Professor Nivel2': {
                Name: 'Professor Nivel2',
                Colors: {
                    c1: '#dddddd',
                    c2: '#dddddd',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#dddddd',
                    k2: '#FFFF00',
                }
            },
            'Contramestre': {
                Name: 'Contramestre',
                Colors: {
                    c1: '#dddddd',
                    c2: '#dddddd',
                    c3: '#0000ff',
                    c4: '#0000ff',
                    k1: '#dddddd',
                    k2: '#0000ff',
                }
            },
            'Mestre': {
                Name: 'Mestre',
                Colors: {
                    c1: '#dddddd',
                    c2: '#dddddd',
                    c3: '#ff0000',
                    c4: '#ff0000',
                    k1: '#dddddd',
                    k2: '#ff0000',
                }
            },
            'Grand Mestre': {
                Name: 'Grand Mestre',
                Colors: {
                    c1: '#dddddd',
                    c2: '#dddddd',
                    c3: '#dddddd',
                    c4: '#dddddd',
                    k1: '#dddddd',
                    k2: '#dddddd',
                }
            },
        }
    },  
    35: {
        Id: 35,
        Name: 'TUCUM',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'young 1': {
                Name: 'young 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#999999',
                    c3: '#60ff60',
                    c4: '#999999',
                    k1: '#60ff60',
                    k2: '#999999',
                }
            },

            'young 2': {
                Name: 'young 2',
                Colors: {
                    c1: '#ffff66',
                    c2: '#999999',
                    c3: '#ffff66',
                    c4: '#999999',
                    k1: '#ffff66',
                    k2: '#999999',
                }
            },

            'young 3': {
                Name: 'young 3',
                Colors: {
                    c1: '#87cefa',
                    c2: '#999999',
                    c3: '#87cefa',
                    c4: '#999999',
                    k1: '#87cefa',
                    k2: '#999999',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#60ff60',
                    c2: '#60ff60',
                    c3: '#60ff60',
                    c4: '#60ff60',
                    k1: '#60ff60',
                    k2: '#60ff60',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#60ff60',
                    c2: '#60ff60',
                    c3: '#60ff60',
                    c4: '#ffff66',
                    k1: '#60ff60',
                    k2: '#60ff60',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#60ff60',
                    c2: '#ffff66',
                    c3: '#60ff60',
                    c4: '#ffff66',
                    k1: '#60ff60',
                    k2: '#ffff66',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#ffff66',
                    c2: '#ffff66',
                    c3: '#ffff66',
                    c4: '#60ff60',
                    k1: '#ffff66',
                    k2: '#ffff66',
                }
            },
            
            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#ffff66',
                    c2: '#ffff66',
                    c3: '#ffff66',
                    c4: '#ffff66',
                    k1: '#ffff66',
                    k2: '#ffff66',
                }
            },
            
            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#ffff66',
                    c2: '#ffff66',
                    c3: '#ffff66',
                    c4: '#87cefa',
                    k1: '#ffff66',
                    k2: '#ffff66',
                }
            },

            'kids 7': {
                Name: 'kids 7',
                Colors: {
                    c1: '#ffff66',
                    c2: '#87cefa',
                    c3: '#ffff66',
                    c4: '#87cefa',
                    k1: '#ffff66',
                    k2: '#87cefa',
                }
            },

            
            'kids 8': {
                Name: 'kids 8',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#ffff66',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids 9': {
                Name: 'kids 9',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'graduado': {
                Name: 'graduado',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grange mestre': {
                Name: 'grange mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    36: {
        Id: 36,
        Name: 'АК Ростов-На-Дону',
        Gradation: {

            // СЕРЫЙ #666666
            // САЛАТОВЫЙ #90ee90
            // ЖЕЛТОВАТЫЙ #ffffad
            // ГОЛУБОЙ #87cefa

            // ЗЕЛЕНЫЙ #00FF00
            // ЖЕЛТЫЙ #FFFF00
            // СИНИЙ #0000FF
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#90ee90',
                    c4: '#90ee90',
                    k1: '#90ee90',
                    k2: '#90ee90',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#90ee90',
                    c2: '#ffffad',
                    c3: '#90ee90',
                    c4: '#ffffad',
                    k1: '#90ee90',
                    k2: '#ffffad',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#ffffad',
                    c2: '#ffffad',
                    c3: '#ffffad',
                    c4: '#ffffad',
                    k1: '#ffffad',
                    k2: '#ffffad',
                }
            },

            'kids monitor': {
                Name: 'kids monitor',
                Colors: {
                    c1: '#ffffad',
                    c2: '#87cefa',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#ffffad',
                    k2: '#87cefa',
                }
            },

            'kids instrutor': {
                Name: 'kids instrutor',
                Colors: {
                    c1: '#87cefa',
                    c2: '#87cefa',
                    c3: '#87cefa',
                    c4: '#87cefa',
                    k1: '#87cefa',
                    k2: '#87cefa',
                }
            },

            'kids professor': {
                Name: 'kids professor',
                Colors: {
                    c1: '#90ee90',
                    c2: '#90ee90',
                    c3: '#ffffad',
                    c4: '#87cefa',
                    k1: '#90ee90',
                    k2: '#87cefa',
                }
            },

            'kids contra-mestre': {
                Name: 'kids contra-mestre',
                Colors: {
                    c1: '#87cefa',
                    c2: '#ffffff',
                    c3: '#ffffad',
                    c4: '#90ee90',
                    k1: '#ffffff',
                    k2: '#87cefa',
                }
            },

            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#00FF00',
                    c4: '#00FF00',
                    k1: '#00FF00',
                    k2: '#00FF00',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#00FF00',
                    c4: '#FFFF00',
                    k1: '#00FF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'minitor': {
                Name: 'minitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'monitor': {
                Name: 'monitor',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'instrutor': {
                Name: 'instrutor',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'professor': {
                Name: 'professor',
                Colors: {
                    c1: '#00FF00',
                    c2: '#00FF00',
                    c3: '#FFFF00',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#00FF00',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'mestre 1': {
                Name: 'mestre 1',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#00FF00',
                    c3: '#FFFFFF',
                    c4: '#00FF00',
                    k1: '#FFFFFF',
                    k2: '#00FF00',
                }
            },

            'mestre 2': {
                Name: 'mestre 2',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFF00',
                    c3: '#FFFFFF',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },

            'mestre 3': {
                Name: 'mestre 3',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#0000FF',
                    c3: '#FFFFFF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },

            'grande mestre': {
                Name: 'grande mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },

        }
    },

    37: {
        Id: 37,
        Name: 'Tartaruga branca',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

        }
    },

    38: {
        Id: 38,
        Name: 'Jalará',
        Gradation: {
            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

            'kids 1': {
                Name: 'kids 1',
                Colors: {
                    c1: '#999999',
                    c2: '#999999',
                    c3: '#999999',
                    c4: '#999999',
                    k1: '#999999',
                    k2: '#999999',
                }
            },

            'kids 2': {
                Name: 'kids 2',
                Colors: {
                    c1: '#55FF55',
                    c2: '#55FF55',
                    c3: '#999999',
                    c4: '#999999',
                    k1: '#55FF55',
                    k2: '#999999',
                }
            },
            'kids 2 melh': {
                Name: 'kids 2 melh',
                Colors: {
                    c1: '#009900',
                    c2: '#009900',
                    c3: '#999999',
                    c4: '#999999',
                    k1: '#009900',
                    k2: '#999999',
                }
            },

            'kids 3': {
                Name: 'kids 3',
                Colors: {
                    c1: '#FFFF99',
                    c2: '#FFFF99',
                    c3: '#999999',
                    c4: '#999999',
                    k1: '#FFFF99',
                    k2: '#999999',
                }
            },
            'kids 3 melh': {
                Name: 'kids 3 melh',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#999999',
                    c4: '#999999',
                    k1: '#FFFF00',
                    k2: '#999999',
                }
            },

            'kids 4': {
                Name: 'kids 4',
                Colors: {
                    c1: '#5599FF',
                    c2: '#5599FF',
                    c3: '#999999',
                    c4: '#999999',
                    k1: '#5599FF',
                    k2: '#999999',
                }
            },
            'kids 4 melh': {
                Name: 'kids 4 melh',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#999999',
                    c4: '#999999',
                    k1: '#0000FF',
                    k2: '#999999',
                }
            },

            'kids 5': {
                Name: 'kids 5',
                Colors: {
                    c1: '#FFFF99',
                    c2: '#FFFF99',
                    c3: '#55FF55',
                    c4: '#999999',
                    k1: '#FFFF99',
                    k2: '#999999',
                }
            },
            'kids 5 melh': {
                Name: 'kids 5 melh',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#009900',
                    c4: '#999999',
                    k1: '#FFFF00',
                    k2: '#999999',
                }
            },

            'kids 6': {
                Name: 'kids 6',
                Colors: {
                    c1: '#55FF55',
                    c2: '#55FF55',
                    c3: '#5599FF',
                    c4: '#999999',
                    k1: '#55FF55',
                    k2: '#999999',
                }
            },
            'kids 6 melh': {
                Name: 'kids 6 melh',
                Colors: {
                    c1: '#009900',
                    c2: '#009900',
                    c3: '#0000FF',
                    c4: '#999999',
                    k1: '#009900',
                    k2: '#999999',
                }
            },

            'kids 7': {
                Name: 'kids 7',
                Colors: {
                    c1: '#FFFF99',
                    c2: '#FFFF99',
                    c3: '#5599FF',
                    c4: '#999999',
                    k1: '#FFFF99',
                    k2: '#999999',
                }
            },
            'kids 7 melh': {
                Name: 'kids 7 melh',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#0000FF',
                    c4: '#999999',
                    k1: '#FFFF00',
                    k2: '#999999',
                }
            },

            'kids 8': {
                Name: 'kids 8',
                Colors: {
                    c1: '#FFFF99',
                    c2: '#55FF55',
                    c3: '#5599FF',
                    c4: '#999999',
                    k1: '#FFFF99',
                    k2: '#999999',
                }
            },
            'kids 8 melh': {
                Name: 'kids 8 melh',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#009900',
                    c3: '#0000FF',
                    c4: '#999999',
                    k1: '#FFFF00',
                    k2: '#999999',
                }
            },

            // ВЗРОСЛЫЕ
            
            'adult 1': {
                Name: 'adult 1',
                Colors: {
                    c1: '#55FF55',
                    c2: '#55FF55',
                    c3: '#55FF55',
                    c4: '#55FF55',
                    k1: '#55FF55',
                    k2: '#55FF55',
                }
            },
            'adult 1 melh': {
                Name: 'adult 1 melh',
                Colors: {
                    c1: '#009900',
                    c2: '#009900',
                    c3: '#009900',
                    c4: '#009900',
                    k1: '#009900',
                    k2: '#009900',
                }
            },

            'adult 2': {
                Name: 'adult 2',
                Colors: {
                    c1: '#FFFF99',
                    c2: '#FFFF99',
                    c3: '#FFFF99',
                    c4: '#FFFF99',
                    k1: '#FFFF99',
                    k2: '#FFFF99',
                }
            },
            'adult 2 melh': {
                Name: 'adult 2 melh',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFF00',
                    k2: '#FFFF00',
                }
            },

            'adult 3': {
                Name: 'adult 3',
                Colors: {
                    c1: '#5599FF',
                    c2: '#5599FF',
                    c3: '#5599FF',
                    c4: '#5599FF',
                    k1: '#5599FF',
                    k2: '#5599FF',
                }
            },
            'adult 3 melh': {
                Name: 'adult 3 melh',
                Colors: {
                    c1: '#0000FF',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#0000FF',
                    k2: '#0000FF',
                }
            },

            'adult 4': {
                Name: 'adult 4',
                Colors: {
                    c1: '#55FF55',
                    c2: '#55FF55',
                    c3: '#FFFF99',
                    c4: '#FFFF99',
                    k1: '#55FF55',
                    k2: '#FFFF99',
                }
            },
            'adult 4 melh': {
                Name: 'adult 4 melh',
                Colors: {
                    c1: '#009900',
                    c2: '#009900',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#009900',
                    k2: '#FFFF00',
                }
            },

            'adult 5': {
                Name: 'adult 5',
                Colors: {
                    c1: '#55FF55',
                    c2: '#55FF55',
                    c3: '#5599FF',
                    c4: '#5599FF',
                    k1: '#55FF55',
                    k2: '#5599FF',
                }
            },
            'adult 5 melh': {
                Name: 'adult 5 melh',
                Colors: {
                    c1: '#009900',
                    c2: '#009900',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#009900',
                    k2: '#0000FF',
                }
            },

            'adult 6': {
                Name: 'adult 6',
                Colors: {
                    c1: '#FFFF99',
                    c2: '#FFFF99',
                    c3: '#5599FF',
                    c4: '#5599FF',
                    k1: '#FFFF99',
                    k2: '#5599FF',
                }
            },
            'adult 6 melh': {
                Name: 'adult 6 melh',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#FFFF00',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFF00',
                    k2: '#0000FF',
                }
            },

            
            'formado': {
                Name: 'formado',
                Colors: {
                    c1: '#FFFF00',
                    c2: '#0000FF',
                    c3: '#0000FF',
                    c4: '#009900',
                    k1: '#FFFF00',
                    k2: '#009900',
                }
            },
            'professor 1 grao': {
                Name: 'professor 1 grao',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#009900',
                    c4: '#009900',
                    k1: '#FFFFFF',
                    k2: '#009900',
                }
            },
            'professor 2 grao': {
                Name: 'professor 2 grao',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFF00',
                    c4: '#FFFF00',
                    k1: '#FFFFFF',
                    k2: '#FFFF00',
                }
            },
            'contra mestre': {
                Name: 'contra mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#0000FF',
                    c4: '#0000FF',
                    k1: '#FFFFFF',
                    k2: '#0000FF',
                }
            },
            'mestre': {
                Name: 'mestre',
                Colors: {
                    c1: '#FFFFFF',
                    c2: '#FFFFFF',
                    c3: '#FFFFFF',
                    c4: '#FFFFFF',
                    k1: '#FFFFFF',
                    k2: '#FFFFFF',
                }
            },
        }
    },

    50: {
        Id: 50,
        Name: '- - -',
        IsFacial: true,
        Gradation: {

            '- - -': {
                Name: '- - -',
                Colors: {
                    c1: '#00000022',
                    c2: '#00000022',
                    c3: '#00000022',
                    c4: '#00000022',
                    k1: '#00000022',
                    k2: '#00000022',
                }
            },

        }
    },


}