// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { orderBy, groupBy } from 'lodash';

import { withCapoeiraSportState } from 'src/ver2/context';
import MoneyComponent from 'src/ver2/components/money';
import { Input, Button, Select } from 'antd';

const Option = Select.Option;


/**
 * 
 * Это компонент каменной таблички нажимной
 */

const MODES = [
    { key: 'island_free', title: 'Похвала!' },
    { key: 'island_pushups', title: 'Отжимания' },
    { key: 'island_pullups', title: 'Подтягивания' },
    { key: 'island_bars', title: 'Брусья' },
    { key: 'island_berimbao', title: 'Беримбао' },
    { key: 'island_pandeiro', title: 'Пандейру' },
    { key: 'island_atabak', title: 'Атабак' }
]

class Quest27 extends PureComponent {
    state = {
        freeStarsCount: 1,
        freeStarsPerson: 2,
        status: 'allok',
        teamusers: [],
        selectedTeamUserId: null,
        freeStartOperations: [],


        selectedMode: 'island_free',
        loadedOperations: {

        }

    }

    // clickOnSend = () => {
    //     const { options } = this.state;
    //     const answer = `${options[1]}${options[2]}${options[3]}${options[4]}`;
    //     this.props.sendSpecial({ data: { [answer]: 1 } })
    // }

    loadAllTeamUsers = async () => {
        const { IslandId, client } = this.props;
        const teamusers = await client.islandGetUsersData({ IslandId, WithTeams: 1 });
        this.setState({ teamusers: orderBy(teamusers, 'Surname') });
    }

    loadAllFreeStartOperations = async () => {
        const { client } = this.props;
        const freeStartOperations = await client.getUsersOperationsByScores(null, 'is2', 'island_free');
        this.setState({ freeStartOperations: orderBy(freeStartOperations, 'Id', 'desc') });
    }
    loadSelectedOperations = async (selectedMode) => {
        const { client } = this.props;
        const selectedModeOperations = await client.getUsersOperationsByScores(null, 'is2', selectedMode) || [];

        let selectedModeOperationsRes = [];
        for (let i = 0; i < selectedModeOperations.length; i++) {
            const { AllowedTo, Settings } = selectedModeOperations[i];
            const UserId = JSON.parse(AllowedTo)[0].Id;
            const SettingsObj = JSON.parse(Settings);
            const Lvl = SettingsObj.lvl;
            selectedModeOperationsRes.push({ ...selectedModeOperations[i], UserId, Lvl })
        }

        selectedModeOperationsRes = groupBy(selectedModeOperationsRes, 'UserId');
        this.setState({ loadedOperations: { ...this.state.loadedOperations, [selectedMode]: selectedModeOperationsRes } });
    }

    componentDidMount = () => {
        this.loadAllTeamUsers();
        this.loadAllFreeStartOperations();
    }

    render() {
        const { isMobile } = this.props;
        const { selectedMode } = this.state;
        const className = 'mainadmin_container';

        let content = [];
        switch (selectedMode) {
            case 'island_free': {
                content = [
                    <this.AddStarFreeComponent />,
                    <this.GivedOpeations />
                ]
                break;
            }
            case 'island_bars':
            case 'island_berimbao':
            case 'island_pandeiro':
            case 'island_atabak':
            case 'island_pullups':
            case 'island_pushups': {
                content = [
                    <this.AddStarFizuhaComponent />
                ]
                break;
            }
            default: {

            }
        }

        return <div className={className}>
            <Select style={{ marginBottom: 5, width: 200 }} value={selectedMode} onChange={(selectedMode) => { this.setState({ selectedMode }) }}>
                {MODES.map(({ key, title }) => {
                    return <Option key={key}>{title}</Option>
                })}
            </Select>
            {content}
        </div>
    }

    AddStarFizuhaComponent = () => {
        const { selectedMode, teamusers, status } = this.state;
        const { loadedOperations } = this.state;

        if (!loadedOperations[selectedMode]) {
            this.loadSelectedOperations(selectedMode);
            return null;
        }

        const FIZGUHA = {
            island_pushups: {
                text: 'Отжимания ',
                pointId: 91,
                titles: [
                    { title: '25 раз', stars: 2 },
                    { title: '40 раз', stars: 3 },
                    { title: '60 раз', stars: 5 }
                ]
            },
            island_pullups: {
                text: 'Подтягивания ',
                pointId: 91,
                titles: [
                    { title: '8 раз', stars: 2 },
                    { title: '11 раз', stars: 3 },
                    { title: '13 раз', stars: 5 }
                ]
            },
            island_bars: {
                text: 'Брусья ',
                pointId: 91,
                titles: [
                    { title: '10 раз', stars: 2 },
                    { title: '15 раз', stars: 3 },
                    { title: '25 раз', stars: 5 }
                ]
            },
            island_berimbao: {
                text: 'Беримбао  ',
                pointId: 91,
                titles: [
                    { title: 'ур 1', stars: 2 },
                    { title: 'ур 2', stars: 3 },
                    { title: 'ур 3', stars: 5 }
                ]
            },
            island_pandeiro: {
                text: 'Пандейру  ',
                pointId: 91,
                titles: [
                    { title: 'ур 1', stars: 2 },
                    { title: 'ур 2', stars: 3 }
                ]
            },
            island_atabak: {
                text: 'Атабак  ',
                pointId: 91,
                titles: [
                    { title: 'ур 1', stars: 2 },
                    { title: 'ур 2', stars: 3 }
                ]
            }
        }

                // { key: 'island_bars', title: 'Брусья' },
        // { key: 'island_berimbao', title: 'Отжимания' },
        // { key: 'island_pandeiro', title: 'Подтягивания' },
        // { key: 'island_atabak', title: 'Брусья' }

        const releaseOnce = (UserId, index) => {
            const { client } = this.props;
            this.setState({ status: 'waiting' })

            const starsCountObj = FIZGUHA[selectedMode].titles[index - 1].stars;

            const Settings = JSON.stringify({ money: { is2: starsCountObj }, lvl: index });
            const IslandPointId = FIZGUHA[selectedMode].pointId;
            const AllowedTo = JSON.stringify([{ Id: Number(UserId) }]);
            const Discription = FIZGUHA[selectedMode].text + FIZGUHA[selectedMode].titles[index - 1].title;

            client.islandPointOperation({ UserId, Settings, IslandPointId, AllowedTo, Type: selectedMode, Discription }).then(
                async () => {
                    this.loadSelectedOperations(selectedMode);
                    this.setState({ status: 'allOk' });
                }
            )
        }

        return <div className='islandAdmin_free'>
            {
                teamusers.map(({ Surname, Name, Id }) => {
                    return <div key={`isfr_${Id}_${selectedMode}`} className='islandAdmin_free_block islandAdmin_free_block_row' >
                        <div className='islandAdmin_free_block_fio'>
                            {`${Surname} ${Name}`}
                        </div>
                        <div className='islandAdmin_free_block_buttons'>
                            {
                                FIZGUHA[selectedMode].titles.map(({ title, stars }, index) => {

                                    let isPrimary = false;
                                    if (loadedOperations[selectedMode][Id]) {
                                        if (loadedOperations[selectedMode][Id][0].Lvl === (index + 1)) {
                                            isPrimary = true;
                                        }
                                    }

                                    return <Button
                                        disabled={status === 'waiting'}
                                        type={isPrimary ? 'primary' : 'default'}
                                        key={`ifff_${selectedMode}_${index}_${Id}`}
                                        onClick={() => { releaseOnce(Id, index + 1) }}
                                    >
                                        {`${title}  ${stars}★`}
                                    </Button>
                                })
                            }
                        </div>
                    </div>
                })
            }
        </div>
    }

    AddStarFreeComponent = () => {

        const { client, iamuser } = this.props;
        const { freeStarsCount, teamusers, selectedTeamUserId } = this.state;

        const releaseFree = () => {
            this.setState({ status: 'waiting' })

            const UserId = iamuser.Id;
            const Type = 'island_free';
            const freeStarsCount_ = Number(freeStarsCount) || 0;

            const Settings = JSON.stringify({ money: { is2: freeStarsCount_ } });
            const IslandPointId = 105;
            const AllowedTo = JSON.stringify([{ Id: Number(selectedTeamUserId) }]);
            const Discription = 'Похвала!';

            client.islandPointOperation({ UserId, Settings, IslandPointId, AllowedTo, Type, Discription }).then(
                async () => {
                    await this.loadAllFreeStartOperations();
                    this.setState({ status: 'allOk' });
                }
            )
        }

        return <div className='islandAdmin_free'>
            <div className='islandAdmin_free_block'>
                <Select
                    style={{ width: 250 }}
                    size="default"
                    placeholder={'НЕ ВЫБРАН'}
                    onChange={(selectedTeamUserId) => { this.setState({ selectedTeamUserId }) }} >
                    {
                        teamusers.map(({ Surname, Name, Id }) => {
                            return <Option value={Id}>{`${Surname} ${Name}`}</Option>
                        })
                    }
                </Select>
            </div>
            <div className='islandAdmin_free_block'>
                <MoneyComponent size={55} type={'is2'} noAmount />
                <Input style={{ width: 60 }} value={freeStarsCount} onChange={(e) => { this.setState({ freeStarsCount: e.target.value }) }} />
                <Button onClick={releaseFree} disabled={!selectedTeamUserId || this.state.status === 'waiting'}>ВЫДАТЬ</Button>
            </div>
        </div>
    }

    GivedOpeations = () => {
        const { freeStartOperations, teamusers } = this.state;
        return <div className='freeStartOperations_main'>
            {freeStartOperations.map(({ AllowedTo, Settings, ReleaseDate, Id }) => {

                const AllowedToObj = JSON.parse(AllowedTo);
                const SettingsObj = JSON.parse(Settings);
                const UserId = AllowedToObj[0].Id;
                const user = teamusers.find(x => x.Id === Number(UserId));
                if (!user) return null;

                const amount = SettingsObj.money.is2;

                return <div key={`fsopr_${Id}`} className='freeStartOperation_main'>
                    <div className='freeStartOperation_main_user'>
                        {`${user.Surname} ${user.Name}`}
                    </div>
                    <div className='freeStartOperation_main_stars'>
                        {`${amount} ★`}
                    </div>
                    <div className='freeStartOperation_main_date'>
                        {ReleaseDate}
                    </div>
                </div>
            })}
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest27);