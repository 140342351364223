import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import Loader from 'src/ver2/components/loader';
import PersonsLittleListPlashka from 'src/ver2/plashki/person/littleList';
import { TrefaLayout } from 'src/ver2/containers/layout';



// import { Link } from 'react-router-dom';

import './style.css';

// class EventContactsPlashka
// classname: event_contacts_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class EventContactsPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Event, upperStyle, isStuffReady, Stuff } = this.props;
        const { width, height } = upperStyle;

        if (!isStuffReady) return <div style={{ width, height }} className='event_contacts_plashka'>
            <Loader text = 'загрузка контактов' />
        </div>

        return <div style={{ width, height }} className='event_contacts_plashka'>
            <TrefaLayout greedsX={[5, width - 10, 5]}
                components={[
                    {

                        component: PersonsLittleListPlashka,
                        cell_x: 2,
                        collapse: 1,
                        id: 8888,
                        componentProps: { height: 100, Persons: Stuff.contacts, autoSize: true, mode: 'contact' }

                    }
                ]}
            />
        </div>
    }

}


export default withEventState('Event, isStuffReady, Stuff')(EventContactsPlashka)