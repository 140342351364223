import React, { Component } from 'react';
import './style.css';

export default class Map extends Component {


    componentDidMount() {

        const { adres, mapId } = this.props;
        const ymaps = window.ymaps;

        if (ymaps)
            ymaps.ready(() => {

                const comp = document.getElementById(mapId);
                if (!comp) return;

                var myMap = new ymaps.Map(mapId, {
                    center: [55.751574, 37.573856],
                    zoom: 9,
                    controls: []
                }, {
                    searchControlProvider: 'yandex#search'
                })

                this.myMap = myMap;

                var myGeocoder = ymaps.geocode(adres);

                myGeocoder.then(
                    function (res) {
                        const coordinates = res.geoObjects.get(0).geometry.getCoordinates();
                        myMap.setCenter(coordinates, 13);

                        const myGeoObject = new ymaps.GeoObject({
                            geometry: {
                                type: "Point",// тип геометрии - точка
                                coordinates // координаты точки
                            },
                            properties: {
                                // hintContent: `<div class = 'event_map_hint'>${adres}</div>`
                            }
                        });

                        myMap.geoObjects.add(myGeoObject);

                    },
                    function (err) {
                    }
                );
            });
    }

    componentWillReceiveProps = (nextProps) => {
        // if (nextProps.city !== this.props.city && citys[nextProps.city]) {
        //     const {coords} = citys[nextProps.city];
        //     this.myMap.setCenter(coords, 10);
        // }
        // if (nextProps.metro !== this.props.metro && metros[nextProps.metro]) {
        //     const {coords} = metros[nextProps.metro];
        //     this.myMap.setCenter(coords, 13);
        // }
        // if (nextProps.place !== this.props.place && places[nextProps.place]) {
        //     const {coords} = places[nextProps.place];
        //     this.myMap.setCenter(coords, 14);
        // }
    }

    render() {

        const { width, height, mapId } = this.props;

        return <div id={mapId} className='event_map_id' style={{ width, height }}>
        </div>
    }

}