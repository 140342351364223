import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';

import ComboRow from 'src/ver2/components/combo/comboRow';
import SoloRoundRow from 'src/ver2/containers/challenges/components/roundRow';

import Loader from 'src/ver2/components/loader';

import { Collapse, Avatar, Icon } from 'antd';
import { sortBy, groupBy } from 'lodash';

// import { Link } from 'react-router-dom';

import './style.css';

// class EventRoundsPlashka
// classname: event_rounds_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */


const { Panel } = Collapse;


class EventRoundsPlashka extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            heightSetted: false
        }

        props.client.getServerTime().then((result) => {
            this.setState({ serverTime: result })
        });
    }

    componentDidMount = () => {
        this.setHeight()
    }

    componentDidUpdate = () => {
        this.setHeight()
    }

    setHeight = async () => {
        // const { isCompetitorsCountInCategorysReady, competitorsCountInCategorys, Categorys, setComponentSelfHeight, plashkaId } = this.props;
        // const { heightSetted } = this.state;
        // if (!heightSetted && isCompetitorsCountInCategorysReady) {
        //     this.setState({
        //         heightSetted: true
        //     })
        //     setComponentSelfHeight({ height: 50 * Categorys.length + 550, plashkaId });
        // }
    }


    render() {

        const { Rounds, isRoundsReady, Combos, isCombosReady, upperStyle, client, iAmParticipator, Event } = this.props;
        const { serverTime } = this.state;
        const { width, height } = upperStyle;

        if (!isRoundsReady || !isCombosReady) return <div style={{ width, height }} className='event_rounds_plashka'>
            <Loader />
        </div>

        let myTier = 0;

        if (iAmParticipator) {
            const { Settings } = iAmParticipator;
            const SettingsObj = JSON.parse(Settings);
            const { tier } = SettingsObj;
            myTier = Number(tier);
        }

        console.log('EventRoundsPlashka', { Combos, Rounds });

        let AllTasks = (Rounds || []).reduce((memo, item) => { return [...memo, { ...item, taskType: 'round' }] }, [])
        AllTasks = [...AllTasks, ...(Combos || []).reduce((memo, item) => { return [...memo, { ...item, taskType: 'combo' }] }, [])];

        if (!AllTasks || !AllTasks.length) return <div style={{ width, height }} className='event_rounds_plashka'>
            <span>
                РАУНДЫ ПОКА НЕ ОПРЕДЕЛЕНЫ
            </span>
        </div>

        AllTasks = sortBy(AllTasks, ['Tier', 'Number']);
        AllTasks = groupBy(AllTasks, 'Tier');
        let LetAllTasksKeys = Object.keys(AllTasks)

        if (myTier && LetAllTasksKeys.find(x => Number(x) === myTier)) {
            let timedArray = [];
            for (let i = 0; i < LetAllTasksKeys.length; i++) {
                if (Number(LetAllTasksKeys[i]) !== myTier) timedArray.push(Number(LetAllTasksKeys[i]))
            }
            LetAllTasksKeys = [ myTier, ...timedArray ]
        }

        const { SettingsObj } = Event;
        let TierNames = {};
        if (SettingsObj && SettingsObj.ParticipatorsMain && SettingsObj.ParticipatorsMain.ParticipatorsData) {
            const tierNames = SettingsObj.ParticipatorsMain.ParticipatorsData.find(x => x.name === 'tier');
            if (tierNames && tierNames.options) {
                TierNames = tierNames.options.reduce((memo, value) => {
                    return { ...memo, [value.key]: value.title }
                }, {})
            }
        }

        const getTierName = (tier) => {
            if (TierNames[tier]) {
                return TierNames[tier];
            }
            return `Tier ${tier}`;
        }

        return <div style={{ width, height }} className='event_rounds_plashka'>
            {LetAllTasksKeys.map((tier) => {

                const TierTasks = AllTasks[tier];

                return <>
                    {myTier === Number(tier) ? <div className='event_rounds_plashka_title'>{`${getTierName(tier)} - ВЫ УЧАСТВУЕТЕ ТУТ`}</div> :
                        <div className='event_rounds_plashka_title'>{getTierName(tier)}</div>
                    }
                    {
                        TierTasks.map(Task => {
                            if (Task.taskType === 'round') {
                                return <SoloRoundRow key={'solo-round-c-round' + Task.Id} client={client} round={Task} serverTime={serverTime} />
                            }
                            if (Task.taskType === 'combo') {
                                return <ComboRow key={'solo-round-c-combo' + Task.Id} comboId={Task.Id} height = {50} />
                            }
                            return null;
                        })
                    }
                </>
            })}
        </div>

        // if (myTier) {
        //     return <div style={{ width, height }} className='event_rounds_plashka'>
        //         <div className='event_rounds_plashka_title'>{`${getTierName(myTier)} - ВЫ УЧАСТВУЕТЕ ТУТ`}</div>
        //         {Rounds_.map((round) => {
        //             const { Tier } = round;
        //             if (Tier !== myTier) return null;
        //             return <SoloRoundRow key={'solo-round-c-' + round.Id} client={client} round={round} serverTime={serverTime} />
        //         })}
        //         {Rounds_.map((round) => {
        //             const { Tier } = round;

        //             if (Tier === myTier) return null;
        //             if (Tier !== TierNow) {
        //                 TierNow = Tier;
        //                 return <>
        //                     <div className='event_rounds_plashka_title event_rounds_plashka_title_nothere'>{getTierName(Tier)}</div>
        //                     <SoloRoundRow key={'solo-round-c-' + round.Id} client={client} round={round} serverTime={serverTime} />
        //                 </>
        //             }
        //             return <SoloRoundRow key={'solo-round-c-' + round.Id} client={client} round={round} serverTime={serverTime} />
        //         })}
        //     </div>
        // }

        // return <div style={{ width, height }} className='event_rounds_plashka'>
        //     {Rounds_.map((round) => {

        //         const { Tier } = round;
        //         if (Tier !== TierNow) {
        //             TierNow = Tier;
        //             return <>
        //                 <div className='event_rounds_plashka_title'>{getTierName(Tier)}</div>
        //                 <SoloRoundRow key={'solo-round-c-' + round.Id} client={client} round={round} serverTime={serverTime} />
        //             </>
        //         }

        //         return <SoloRoundRow key={'solo-round-c-' + round.Id} client={client} round={round} serverTime={serverTime} />
        //     })}
        // </div>
    }

}

// const EventRoundsPlashkaWrapped = (props) => <EventWrapper Wrapped={EventRoundsPlashka} eventId={props.eventId} componentProps={{ ...props }} />
// export default withCapoeiraSportState('client')(EventRoundsPlashkaWrapped);

export default withEventState(`
Event, 

Rounds,
isRoundsReady, 
Combos, 
isCombosReady,

iAmParticipator,

client,
iamuser

`)(EventRoundsPlashka)