// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';
import ImageUploader from 'src/ver2/containers/imageChanger';

import DataChanger, {compareFunction} from 'src/ver2/containers/dataChanger';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент создания предмета
 */

const AddItemModal = ({ iAmAdmin, addRealItem, closeModal }) => {

    if (!iAmAdmin) return <div>ЧО ТЫ ТУТ ДЕЛАЕШЬ???</div>

    const mainInformationChange = async ( Data ) => {

        // const ShopMain_ = {...ShopMain, IsOn: ShopMain.IsOn ? 'yes' : 'no'}

        // // тут у нас функция сравнения
        // const resultSend = compareFunction( Shop, ShopMain_ );

        // if (Object.keys(resultSend).length > 0 ) {
        //     addRealItem(resultSend);
        //     closeModal();
        // }

        addRealItem(Data);
        closeModal();
    }

    return <div className='admin_modal'>
        <Tabs className='admin_modal_tabs' tabPosition='left' style={{ color: 'white', heightMin: 500 }} defaultActiveKey="1">

            <TabPane tab={'Основные данные'} key="1">
            <DataChanger
                        header={<div>
                            Основные данные магазина
                         </div>}
                        data={{ Title: '', Type: 'cloth', Cost: 0, ArticuleTitle: '' }}
                        buttonText={'СОХРАНИТЬ'}
                        buttonState={'allNeeded'}
                        onButtonClick={mainInformationChange}
                        components={[
                            {
                                name: 'Title',
                                title: 'Название',
                                type: 'input',
                                style: {
                                    width: 600
                                },
                                neededText: ' '
                            },
                            {
                                name: 'Type',
                                title: 'Тип',
                                type: 'select',
                                isNecessery: true,
                                options:
                                    [
                                        {
                                            key: 'cloth',
                                            title: 'одежда'
                                        }
                                    ],
                                defValue: 'cloth'
                            },
                            {
                                name: 'Cost',
                                title: 'Цена',
                                type: 'input',
                                style: {
                                    width: 600
                                },
                                neededText: 'Цифра в рублях'
                            },
                            {
                                name: 'ArticuleTitle',
                                title: 'Название артикула',
                                type: 'input',
                                style: {
                                    width: 600
                                },
                                neededText: ' '
                            },
                        ]}
                    />
            </TabPane>
        </Tabs>
    </div>
}

/*
    ChildrenComponent - какой компонент использовать
    CholdrenProps - что туда прокинуть
    onCallback - функция, которую вызывать при триггере компонента
    callbackName - имя функции, которую тригерить у компонента
    callbackParam - имя параметра, в котором к компоненты улетит callbackStatus
*/

class InfoAdder extends Component {
    state = {
        callbackStatus: ''
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { callbackStatus } = this.state;
        return <TrefaTextEdit buttonText={'Добавить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Добавить' }} onButtonClick={this.onCallback} />
    }

}

class InfoChanger extends Component {
    state = {
        callbackStatus: ''
    }

    onCallback = (data) => {
        const { onCallback } = this.props;
        this.setState({ callbackStatus: 'loading' });
        onCallback(data).then(() => {
            this.setState({ callbackStatus: '' });
        })
    }

    render() {
        const { text } = this.props;
        const { callbackStatus } = this.state;
        return <TrefaTextEdit text={text} buttonText={'Сохранить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Сохранение' }} onButtonClick={this.onCallback} />
    }

}

export default withShopState(`

Shop, 
addRealItem,

iAmAdmin,

client

`)(AddItemModal);