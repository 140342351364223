import React, { PureComponent } from 'react';

import { withEventState } from 'src/ver2/pages/event/context';
import { NumberText } from 'src/ver2/containers/lowlavel/numberText';
import { Tabs } from 'antd';

import Loader from 'src/ver2/components/loader';
import { orderBy, groupBy } from 'lodash';
import LittlePerson from 'src/ver2/components/person/little';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import GroupPlate from 'src/ver2/components/group/groupPlate';
// import { Link } from 'react-router-dom';

import './style.css';

// class SpringSoloResults
// classname: sprig_solo_results


const TabPane = Tabs.TabPane;

class SpringSoloResults extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }
    state = {
        ParticipatorsWithResult: [],
        UsersScores: {}
    }

    setComponentSelfHeight = ({ height }) => {
        const { setComponentSelfHeight, plashkaId } = this.props;

        if (height === this.state.lastHeight) return;
        this.setState({ lastHeight: height })
        setComponentSelfHeight({ plashkaId, height });

    };


    componentDidMount = async () => {
        const { client, Event } = this.props;
        const Scores = await client.getScoresInEvent(Event.Id, 'spr');
        const UsersScores = groupBy(Scores, 'SubjectId');
        this.usersResults(UsersScores);
        this.setState({
            UsersScores
        })

        this.TMR = setInterval(
            () => {
                const el = document.getElementById('sprig_solo_results_container_id');
                if (el) {
                    const { clientHeight } = el;
                    this.setComponentSelfHeight({ height: clientHeight + 50 })
                }
            },
            300
        )
    }

    componentWillUnmount() {
        if (this.TMR) {
            clearInterval(this.TMR);
            delete this.TMR;
        }
    }

    componentDidUpdate = (prevProps) => {
        const wasReady = prevProps.isParticipatorsReady && prevProps.isSoloSequencesReady;
        const nowReady = this.props.isParticipatorsReady && this.props.isSoloSequencesReady;
        if (!wasReady && nowReady) {
            this.usersResults(this.state.UsersScores);
        }
    }

    usersResults = (UsersScores) => {

        const { Participators, SequncesConfirm, isParticipatorsReady } = this.props;
        if (!isParticipatorsReady) return;
        if (!SequncesConfirm || !SequncesConfirm.byUser) return;
        let ParticipatorsWithResult = [];

        // let SequncesConfirmByRounds = groupBy(SequncesConfirm.all, 'RoundId');
        // const elements = client.elements_as_object;

        for (let i = 0; i < Participators.length; i++) {
            const { UserId } = Participators[i];
            if (!SequncesConfirm.byUser[UserId] || !SequncesConfirm.byUser[UserId].length) continue;
            const Points = SequncesConfirm.byUser[UserId].reduce((memo, sequence) => {
                return {
                    Points: memo.Points + sequence.Points
                }
            }
                , { Points: 0 })

            const roundOver = SequncesConfirm.byUser[UserId].length;

            let Spr = 0;
            if (UsersScores && UsersScores[UserId]) {
                Spr = UsersScores[UserId][0].all;
            }

            ParticipatorsWithResult.push({ ...Participators[i], ...Points, sumPoints: Spr + Points.Points, Spr, roundOver })
        }
        this.setState({
            ParticipatorsWithResult: orderBy(ParticipatorsWithResult, ['roundOver', 'sumPoints'], ['desc', 'desc'])
        });
    }

    render() {

        const { isSoloSequencesReady, isParticipatorsReady, upperStyle, isMobile } = this.props;
        const { width, height } = upperStyle;

        const { ParticipatorsWithResult } = this.state;

        if (!isSoloSequencesReady || !isParticipatorsReady) return <div style={{ width, height }} className='sprig_solo_results'>
            <Loader />
        </div>

        // const SequncesConfirmArray = orderBy(SequncesConfirm.all, 'SequenceSum', 'desc');

        return <div style={{ width, height }} className='sprig_solo_results'>
            <div id='sprig_solo_results_container_id'>
                <Tabs>
                    <TabPane tab={'ЛИЧНЫЕ'} key="1">
                        <div className='sprig_solo_results_container'>
                            {ParticipatorsWithResult.map((result, index) => {
                                return <this.OneResult key={'sprsrc_' + index}  {...result} index={index} />
                            })}
                        </div>
                    </TabPane>
                    <TabPane tab={'ГРУППЫ'} key="2">
                        {this.MassResults()}
                    </TabPane>
                </Tabs>
            </div>
        </div>
    }

    MassResults = () => {
        const { ParticipatorsWithResult } = this.state;
        const { isMobile } = this.props;
        const groupsStat = groupBy(ParticipatorsWithResult, 'GroupIamInId');
        const groupsParticipators = Object.keys(groupsStat);

        let Groups = [];

        for (let i = 0; i < groupsParticipators.length; i++) {
            const groupKey = groupsParticipators[i];
            const gr_ = groupsStat[groupKey];

            const sumPoints = gr_.reduce((value, { sumPoints }) => { return value + sumPoints }, 0)
            Groups.push( { groupId: groupKey, sumPoints } );
        }

        Groups = orderBy(Groups, 'sumPoints', 'desc');
        const ntHeight = isMobile ? 20 : 35;

        return <div className='sprig_solo_results_container'>
            <div className='sprig_solo_results_container_title'>ГРУППЫ</div>
            <div className='sprig_solo_results_container_discr'>Группы получают 1 мерч-предмет в магазин при наборе 1000 очков, 2 предмета при наборе 2000 очков</div>
            {Groups.map ( ({ groupId, sumPoints }) => {

                const items = (sumPoints >= 1000) ? '2 ПРЕДМЕТ' : '1 ПРЕДМЕТ';
                const backWidth = (sumPoints >= 1000) ? ((sumPoints - 1000) / 10) : (sumPoints / 10) 

                return <div className='sprig_solo_results_one_container sprig_solo_results_one_container_group'>
                    <div className = 'sprig_solo_results_one_container_group_back' style = {{width: `${backWidth}%`}}/>
                    <GroupPlate width={isMobile ? 180 : 270} height={isMobile ? 30 : 40} GroupId={groupId} />
                    <NumberText count={sumPoints} text={items} height={ntHeight} />
                </div>
            })}
        </div>

    }

    OneResult = (result) => {

        const { client, isMobile, SequncesConfirm } = this.props;
        const { Points, Spr, roundOver, index } = result;
        const { byUser } = SequncesConfirm;
        let Suffix = [];

        const ntHeight = isMobile ? 20 : 35;

        Suffix.push(<NumberText count={`${roundOver}/3`} text={'РАУНДЫ'} height={ntHeight} />)
        Suffix.push(<NumberText count={Points} text={'ОЧКИ'} height={ntHeight} />)
        Suffix.push(<NumberText count={Spr} text={'ЗАДАНИЯ'} height={ntHeight} />)
        Suffix.push(<NumberText count={Points + Spr} text={'ИТОГ'} height={ntHeight} />)

        let addedClassName = 'sprig_solo_results_one_container_not';
        if (roundOver === 3) {
            addedClassName = 'sprig_solo_results_one_container_usual';
            if (index === 0) addedClassName = 'sprig_solo_results_one_container_1';
            if (index === 1) addedClassName = 'sprig_solo_results_one_container_2';
            if (index === 2) addedClassName = 'sprig_solo_results_one_container_3';
        }

        return <div className={'sprig_solo_results_one_container ' + addedClassName}>
            <LittlePerson
                height={isMobile ? 40 : 70}
                key={'plt' + result.UserId}
                DirectPerson={{ ...result, Id: result.UserId }}
                personsDirect
                Suffix={Suffix}
            />
        </div>
    }

}

/*
                        SequncesConfirm: {
                            all: SequncesConfirm,
                            byUser: SCbyUser
                        }
                        ,
                        isSoloSequencesReady: true
*/

export default withEventState(`

Event,

SequncesConfirm,
isSoloSequencesReady,

Participators,
isParticipatorsReady,

client,
isMobile,
iamuser,
`)(SpringSoloResults)