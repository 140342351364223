async function getAchivments({ AchivmentId, UserId }) {
    let uri = 'https://' + this.ServerUrl + '/getAchivments';

    if (AchivmentId) uri += '?AchivmentId=' + AchivmentId;
    if (UserId) uri += '?UserId=' + UserId;

    const answer = await this.standartDataResult(uri, { timeout: 1500 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

async function getAchivmentsFromServer() {
    let uri = 'https://' + this.ServerUrl + '/serverAllAchivments';

    const answer = await this.standartDataResult(uri);
    // if (!answer.error) {
    //   return answer.result;
    // }
    // return answer.result;

    console.log('achivments', answer);
    this.ACHIVMENTS = answer.result.ACHIVMENTS;
    // this.state.birthdays = (answer.result && answer.result.length) ? [...answer.result] : [];
    this.reloadPage('achivments');

}

export default {
    getAchivments,
    getAchivmentsFromServer
}