// ТУТ ВСЯКАЯ ХУЙНЯ, КОТОРАЯ ЧТО-ТО ОТПРАВЛЯЕТ НА СЕРВЕР
import { sortBy, groupBy, orderBy } from 'lodash';
import { coupleLooserId, coupleWinnerId } from './index';


// отправляет места на сервер в данной категории, сам выбирает круговая она или нет
export const sendPlacesToServer = async (props, state) => {
    const { client, competitionId, selectedCategory, category } = props;
    const { story, scores, competitors, wins } = state;
    const { IsCircled, IsDouble } = category;

    if (IsCircled) {
        return sendCirclePlacesToServer({ client, competitionId, selectedCategory, competitors, scores, story, wins });
    } else {
        if (IsDouble) {
            return sendNotCircleDoublePlacesToServer({ client, competitionId, selectedCategory, competitors, story, category });
        }
        return sendNotCirclePlacesToServer({ client, competitionId, selectedCategory, competitors, story });
    }

}

const sendNotCirclePlacesToServer = async ({ client, competitionId, selectedCategory, competitors, story }) => {
    // берем всех компетиторов и введём у них понятие sendedPlace
    let competitorPlaces = [];

    // прочесываем все пары и в каждой ставим лузеров, исходя из этого ставим место
    for (let i = 0; i < story.length; i++) {
        const LooserId = coupleLooserId(story[i], competitors);
        if (LooserId > 0) {
            competitorPlaces.push({
                UserId: LooserId,
                Place: story[i].Circle_id + 1
            })
        }

        // и отдельно еще смотрим первую пару 
        if (story[i].Circle_id === 1) {
            const WinnerId = coupleWinnerId(story[i], competitors);
            if (WinnerId > 0) {
                competitorPlaces.push({
                    UserId: WinnerId,
                    Place: 1
                })
            }
        }

    }

    for (let i = 0; i < competitorPlaces.length; i++) {
        await client.sendPlaceToServer(competitionId, selectedCategory, competitorPlaces[i].UserId, competitorPlaces[i].Place);
    }

}

const sendNotCircleDoublePlacesToServer = async ({ client, competitionId, selectedCategory, competitors, story, category }) => {
    // берем всех компетиторов и введём у них понятие sendedPlace
    let competitorPlaces = [];

    const LookedCircle = category.ElderCircle + 1;

    const storyNeeded = groupBy(story, 'Circle_id');

    let NowPlace = 3;

    if (storyNeeded && storyNeeded[LookedCircle]) {
        const story_ = sortBy(storyNeeded[LookedCircle], 'Couple_id');

        for (let i = 0; i < story_.length; i++) {

            if (i > 0) NowPlace = 3;
            if (i > (0 + 2)) NowPlace = 4;
            if (i > (0 + 2 + 4)) NowPlace = 5;
            if (i > (0 + 2 + 4 + 8)) NowPlace = 6;
            if (i > (0 + 2 + 4 + 8 + 16)) NowPlace = 7;
            if (i > (0 + 2 + 4 + 8 + 16 + 32)) NowPlace = 8;

            // и отдельно еще смотрим первую пару 
            if (story_[i].Couple_id === 0) {
                const WinnerId = coupleWinnerId(story_[i], competitors);
                const LooserId = coupleLooserId(story_[i], competitors);
                if (WinnerId > 0) {
                    competitorPlaces.push({
                        UserId: WinnerId,
                        Place: 1
                    })
                }
                if (LooserId > 0) {
                    competitorPlaces.push({
                        UserId: LooserId,
                        Place: 2
                    })
                }
                continue;
            }

            const LooserId = coupleLooserId(story_[i], competitors);
            if (LooserId > 0) {
                competitorPlaces.push({
                    UserId: LooserId,
                    Place: NowPlace
                })
            }
        }
    }

    for (let i = 0; i < competitorPlaces.length; i++) {
        await client.sendPlaceToServer(competitionId, selectedCategory, competitorPlaces[i].UserId, competitorPlaces[i].Place);
    }

}


const sendCirclePlacesToServer = async ({ client, competitionId, selectedCategory, competitors, scores, story, wins }) => {

    // let competitors_ = [];

    // for (let i = 0; i < competitors.length; i++) {
    //     competitors_.push({ ...competitors[i], scores: scores[competitors[i].Jrebiy] })
    // }

    // competitors_ = sortBy(competitors_, x => { return x.scores }).reverse();

    
    
        // снчала по жребию
    let competitors__ = sortBy(competitors, x => { return x.Jrebiy });

    //потом дописываем очки
    for (let i = 0; i < competitors__.length; i++) {
        competitors__[i].circleScores = 0;
        competitors__[i].winsScores = 0;
        competitors__[i].tieScores = 0;
        if (scores[i + 1]) competitors__[i].circleScores = scores[i + 1];
        if (wins[i + 1]) competitors__[i].winsScores = wins[i + 1];
    }

    let competitors_ = orderBy(competitors__, ['winsScores', 'circleScores'], ['desc', 'desc']);

    // теперь смотрим тай-брейки
    for (let i = 0; i < competitors_.length - 1; i++) {
        const wins1 = competitors_[i].winsScores;
        const wins2 = competitors_[i + 1].winsScores;

        const scores1 = competitors_[i].circleScores;
        const scores2 = competitors_[i + 1].circleScores;

        if (wins1 && (wins1 === wins2) && (scores1 === scores2)) {
            // теперь надо проверить, что нет тая со следйющим участников
            if (competitors_[i + 2] && competitors_[i + 2].winsScores === wins1 && competitors_[i + 2].circleScores === scores1) {

            } else {
                // смотрим тай
                // ищем пару
                const neededStory = story.find(
                    x =>
                    (
                        (x.Red_Id === competitors_[i].Jrebiy && x.Blue_Id === competitors_[i + 1].Jrebiy) || 
                        (x.Red_Id === competitors_[i+1].Jrebiy && x.Blue_Id === competitors_[i].Jrebiy)
                    )
                );
                if ( neededStory && neededStory.WinTo ) {
                    let neededJrebiy = 0;
                    if (neededStory.WinTo === 1 || neededStory.WinTo === 3) {
                        neededJrebiy = neededStory.Red_Id;
                    } else {
                        neededJrebiy = neededStory.Blue_Id;
                    }

                    for ( let j = 0; j < competitors_.length; j++) {
                        if (competitors_[j].Jrebiy === neededJrebiy) {
                            competitors_[j].tieScores = 1;
                            continue;
                        }
                    }
                }
            }
        }
    }

    
    competitors_ = orderBy(competitors_, ['winsScores', 'circleScores', 'tieScores'], ['desc', 'desc', 'desc']);

    for (let i = 0; i < competitors_.length; i++) {
        if (competitors_[i].Id > 0) {
            await client.sendPlaceToServer(competitionId, selectedCategory, competitors_[i].Id, i < 3 ? i + 1 : i);
        }

    }
}




// этот метод отправлял видео на сервер, в данный момеент не нужно
/*
export const sendToServerVideo = async () => {
    const { client, selectedCategory, category } = this.props;
    const { story, competitors } = this.state;

    // берем всех компетиторов и введём у них понятие sendedPlace

    // прочесываем все пары и в каждой ставим лузеров, исходя из этого ставим место
    for ( let i = 0; i < story.length; i++) {

      if ( story[i].VideoId !== 0 ) {

        const LooserId = coupleLooserId(story[i], competitors );
        const WinnerId = coupleWinnerId(story[i], competitors );

        let title = '"' + category.Name + ' ';

        if ( story[i].Circle_id === 1 ) title += 'ФИНАЛ'; else title += '1 / ' + this.couplesInCircle(story[i].Circle_id);

        title += '"';

        const res = await client.sendVideoUsersToServer ( story[i].VideoId, LooserId, WinnerId, selectedCategory, title);

     }

    }
  }
  */