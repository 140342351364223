import React, { PureComponent } from 'react';
import CRMState, { withCRMState } from './GroupCRM/context';
import GroupCRM from './GroupCRM';
import Login from 'src/ver2/containers/menu/components/login';

import './style.css';



class CRMPage extends PureComponent {
    render () {
        return <div className='crmpage_main'>
            <div className='crmpage_main_login'>
                <Login/>
            </div>
            <GroupCRM/>
        </div>
    }
}

const ShopPageWithState = withCRMState(`
`)(CRMPage);

const CRMPageWithContent = (props) => <CRMState {...props}> {ShopPageWithState()} </CRMState>

export default CRMPageWithContent;