import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withCapoeiraSportState } from 'src/ver2/context';
import { sortBy } from 'lodash';
import PlaceRect from 'src/ver2/containers/lowlavel/placeRect';
import Loader from 'src/ver2/components/loader';
import LittlePerson from 'src/ver2/components/person/little';
import SchoolPlate from 'src/ver2/components/school/schoolPlate';
import { PersonPlaceRectMini } from 'src/ver2/components/place/person';
import { SchoolPlaceRectMini } from 'src/ver2/components/place/school';


import './style.css';

/*

    Это большая плашка, которая показывает разные рейтинги
    принимает raitingSubject, season, type, category
    когда что то из этого меняется, оно подгружает новый рейтинг, а старый - кеширует



*/


class RaitingBigTable extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loadedRating: null,
            allHeight: 100
        }

        // по сути кэш
        this.raitingsSaved = {}

    }

    setComponentSelfHeight = (height) => {
        const { setComponentSelfHeight, plashkaId } = this.props;
        if ( this.state.allHeight === height ) return;
        this.setState({allHeight: height})
        setComponentSelfHeight({plashkaId, height});
    }

    componentDidMount() {
        this.loadRaiting();
    }

    componentDidUpdate({ raitingSubject, season, type, category }) {

        if (raitingSubject !== this.props.raitingSubject || category !== this.props.category || season !== this.props.season || type !== this.props.type) {
            this.setState({ loadedRating: 'loading' });
            this.loadRaiting();
        }
    }

    raitingKeyed = ({ raitingSubject, season, type, category }) => `${raitingSubject}_${season}_${type}_${category}`;

    loadRaiting = async () => {
        const { client, raitingSubject, season, type, category, region } = this.props;

        // берём из кэша

        const cashedName = this.raitingKeyed({ raitingSubject, season, type, category });

        if (this.raitingsSaved[cashedName]) {
            this.setState({ loadedRating: this.raitingsSaved[this.raitingKeyed({ raitingSubject, season, type, category })] })
            return;
        }

        let raiting = [];

        if (raitingSubject === 'user') {
            raiting = await client.getSubjectRaitingFromServer({ subject: 'user', as_sum: true, season, type, category, region });
        }
        if (raitingSubject === 'school') {
            if ( category === 'absolute' ) {
                raiting = await client.getSubjectRaitingFromServer({ subject: 'school', as_sum: true, season, type, region });
            } else 
            raiting = await client.getSubjectRaitingFromServer({ subject: 'school', as_sum: true, season, type, category, region });
        }
        // кжшируем
        this.raitingsSaved[cashedName] = sortBy(sortBy(raiting, 'AllScores').reverse(), 'Place');

        this.setState({ loadedRating: this.raitingsSaved[cashedName] })
    }

    render() {

        const { raitingSubject, season, type, category } = this.props;

        const Raiting = this.state.loadedRating;

        // const { width, height } = upperStyle;

        this.setComponentSelfHeight (
            500 + ((Raiting && Raiting !== 'loading' && Raiting.length > 0) ? Raiting.length * 56 : 100)
        )

        return <div className='raiting_big_plashka'>
            {
                (Raiting && Raiting !== 'loading' && Raiting.length > 0)
                    ?
                    [
                        <this.RaitingTitle />,
                        <this.BestRaitingBlock data={Raiting.slice(0, 3)} />,
                        <this.RaitingHeader />,
                        Raiting.map((currentRaiting, index) => {
                            return <this.OneRaitingBlock key={'rbpl_' + raitingSubject + category + season + '-' + type + '-' + index} currentRaiting={currentRaiting} />
                        })
                    ]
                    :
                    (Raiting === 'loading')
                        ?
                        [
                            <this.RaitingHeader />,
                            <Loader text = 'Загрузка рейтинга' />
                        ]
                        :
                        'Рейтинг не сформирован'
            }
        </div>

    }

    RaitingTitle = () => {
        const { raitingSubject, season, type, category, client } = this.props;

        let categoryName = (category === 'absolute') ? 'абсолютная' : client.CategoryNames[category];
        let raitingSubjectName = raitingSubject === 'school' ? 'Школьный' : 'Личный';
        let typeName = type === 'comp_pro' ? 'ПРО' : 'новичков';

        return <div className = 'raiting_big_plashka__raitingtitle'>
            {`${raitingSubjectName} рейтинг сезона ${season} среди ${typeName} `}
            <br/>
            {`Категория: ${categoryName}`}
        </div>
    }

    RaitingHeader = () => {

        const height = 25;
        const fontSize = height * 0.7;

        const { catName } = this.props;

        return <div className='raiting_big_plashka__oneraiting raiting_big_plashka__oneraiting_header'>
            <div className='raiting_big_plashka__oneraiting_place'>
                <div style={{ fontSize }} className='raiting_big_plashka__oneraiting_place_inside'>
                    Место
                </div>
            </div>
            <div style={{ fontSize }} className='raiting_big_plashka__oneraiting_category'>
                <span> {catName || 'Категория'}</span>
            </div>
            <div style={{ fontSize }} className='raiting_big_plashka__oneraiting_scores'>
                <span>Очки</span>
            </div>
        </div>
    }

    OneRaitingBlock = ({ currentRaiting }) => {
        const { category, raitingSubject } = this.props;
        const { PlaceName, Place, Category, AllScores, UserId, SchoolId } = currentRaiting;

        const height = 25;
        const fontSize = height * 0.75;
        let className = 'raiting_big_plashka__oneraiting raiting_big_plashka__oneraiting_row ';
        if (category === Category) {
            className += 'raiting_big_plashka__oneraiting_row_selected';
        }

        return <div className={className}>
            <div className='raiting_big_plashka__oneraiting_place'>
                <div className='raiting_big_plashka__oneraiting_place_inside'>
                    <PlaceRect {...{ height, Place, PlaceName }} />
                </div>
            </div>
            <div style={{ fontSize }} className='raiting_big_plashka__oneraiting_category'>
                {
                    (raitingSubject === 'user') ?
                        <LittlePerson height={50} personId={UserId} personsDirect = {true}  DirectPerson = {currentRaiting} />
                        :
                        <SchoolPlate height={50} width={'100%'} SchoolId={SchoolId} />
                }
            </div>
            <div style={{ fontSize }} className='raiting_big_plashka__oneraiting_scores'>
                <span>{AllScores}</span>
            </div>
        </div >
    }

    BestRaitingBlock = ({ data }) => {

        const { raitingSubject, isMobile } = this.props;

        const height = isMobile ? 120 : 260;

        if (raitingSubject === 'user')
            return <div className='raiting_big_plashka__best'>
                {data.map((subject) => {
                    return <PersonPlaceRectMini key={'brbb-' + subject.UserId} personId={subject.UserId} height={height} place={subject.Place} />
                })}
            </div>


        if (raitingSubject === 'school')
            return <div className='raiting_big_plashka__best'>
                {data.map((subject) => {
                    return <SchoolPlaceRectMini key={'brbb-' + subject.UserId} schoolId={subject.SchoolId} height={height} place={subject.Place} />
                })}
            </div>

    }
}


export default withCapoeiraSportState('iamuser, getText, client, isMobile')(RaitingBigTable);