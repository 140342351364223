import React from 'react';
import CoomboSchemeComponent from 'src/ver2/components/combo/scheme';
import './styles.css';

const ComboSchemePlashka = ({ upperStyle: { width, height }, Body, innerId }) =>
    <div style={{ width, height }} className='combo_scheme_plashka'>
            <div className='combo_scheme_plashka_inner' id={innerId}>
                <CoomboSchemeComponent schemeHeight={55} Body = {Body} />
            </div>
    </div >

export default ComboSchemePlashka;