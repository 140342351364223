import React, { PureComponent } from 'react';
import CRMWithState from './crmwithstate';
import CRMPage from './PersonCRM';

import './style.css';


export default class CRMMainPage extends PureComponent {

    constructor (props) {
        super();
        this.PersonId = null;
        if (props && props.match && props.match.params && props.match.params.personId) {
            this.PersonId = props.match.params.personId;
        }
    }
    
    render () {

        if (this.PersonId) return <CRMPage personId = {this.PersonId} />
        return <CRMWithState/>
    }
}