import React, { Component } from 'react';
import './style.css';
import { groupBy, orderBy } from 'lodash'

import { withCapoeiraSportState } from 'src/ver2/context';

import { Tooltip } from 'antd';

const ONE_CELL_WIDTH = 70;
const Y_STRIKE = 1;
const Y_DEFENCE = 1;
const Y_MOVEMENTS = 9;

const DEFAULT_ELEMENTS_PLAN = {
    elements: {

        frontal: { x: 1 , y: Y_DEFENCE },
        
        french: { x: 1 , y: Y_STRIKE + 5 },

        bensao: { x: 1 , y: Y_STRIKE + 2 },
        chapa: { x: 4 , y: Y_STRIKE + 2 },

        lateral: { x: 2 , y: Y_STRIKE + 4 },

        qexuada: { x: 2 , y: Y_STRIKE + 4 },
        armada: { x: 3 , y: Y_STRIKE + 4 },

        martelo: { x: 4 , y: Y_STRIKE + 3 },

        gancho: { x: 8 , y: Y_STRIKE + 3 },

        compassa: { x: 5 , y: Y_STRIKE + 6 },
        complokot: { x: 7 , y: Y_STRIKE + 6 },
        fintcomp: { x: 3 , y: Y_STRIKE + 6 },
        martnochao: { x: 7 , y: Y_STRIKE + 7 },

        parafuso: { x: 7 , y: Y_STRIKE + 5 },
        pulada: { x: 6 , y: Y_STRIKE + 4 },

        armpuldouble: { x: 9 , y: Y_STRIKE + 4 },

        kick540: { x: 9 , y: Y_STRIKE + 5 },
        // pulada: { x: 5 , y: Y_STRIKE + 3 },

        diagonale: { x: 1 , y: Y_MOVEMENTS },
        hole: { x: 2 , y: Y_MOVEMENTS },
        auusual: { x: 3 , y: Y_MOVEMENTS + 1 },
        amazonia: { x: 5 , y: Y_MOVEMENTS + 1 },

        cocorinha: { x: 1 , y: Y_MOVEMENTS + 2 },
        macaco: { x: 5 , y: Y_MOVEMENTS + 2 },

        ponte: { x: 3 , y: Y_MOVEMENTS + 3 },
        ponwh: { x: 4 , y: Y_MOVEMENTS + 4 },
        ponlokot: { x: 5 , y: Y_MOVEMENTS + 4 },

        coluna: { x: 7 , y: Y_MOVEMENTS + 4 }, 

        perekid: { x: 6 , y: Y_MOVEMENTS + 3 }, 

        negativa: { x: 1 , y: Y_MOVEMENTS + 5 },

        resistencia: { x: 4 , y: Y_MOVEMENTS + 6 },
        reversao: { x: 7 , y: Y_STRIKE + 8 },

    },
    links: [
        { linked: ['qexuada', 'armada'], type: 'education' },
        { linked: ['armada', 'pulada'], type: 'primal' },
        { linked: ['armada', 'parafuso'], type: 'education' },
        { linked: ['french', 'parafuso'], type: 'education' },
        { linked: ['bensao', 'chapa'], type: 'education' },
        { linked: ['chapa', 'gancho'], type: 'education' },
        { linked: ['martelo', 'gancho'], type: 'education' },
        { linked: ['pulada', 'armpuldouble'], type: 'primal' },
        { linked: ['parafuso', 'kick540'], type: 'primal' },
        { linked: ['martelo', 'martnochao'], type: 'education' },
        { linked: ['lateral', 'qexuada'], type: 'education'},
        { linked: ['diagonale', 'hole'], type: 'education'},
        { linked: ['hole', 'auusual'], type: 'education'},
        { linked: ['hole', 'compassa'], type: 'education'},
        { linked: ['hole', 'martnochao'], type: 'education'},
        { linked: ['fintcomp', 'compassa'], type: 'education'},
        { linked: ['ponte', 'ponwh'], type: 'primal'},
        { linked: ['ponwh', 'ponlokot'], type: 'primal'},
        { linked: ['ponte', 'perekid'], type: 'education'},
        { linked: ['compassa', 'complokot'], type: 'education'},
        
        { linked: ['cocorinha', 'macaco'], type: 'education'},
        { linked: ['auusual', 'macaco'], type: 'education'},

        { linked: ['auusual', 'amazonia'], type: 'primal'},
        { linked: ['compassa', 'reversao'], type: 'education'},
        { linked: ['auusual', 'reversao'], type: 'education'},
    ]
}

class TPLan extends Component {

    constructor(props) {

        super(props);

        const elementsPlan = props.elementsPlan || DEFAULT_ELEMENTS_PLAN;
        const { elements } = elementsPlan;

        let max_x = 0;
        let max_y = 0;

        const elementsKeys = Object.keys(elements);
        for (let i = 0; i < elementsKeys.length; i++) {
            const elementKey = elementsKeys[i];
            const element = elements[elementKey];
            if (element.x > max_x) {
                max_x = element.x;
            }
            if (element.y > max_y) {
                max_y = element.y;
            }
        }

        this.state = {
            elementsPlan,
            max_x,
            max_y
        }

    }

    componentDidMount() {
        this.draw();
    }

    oneCellWidth = () => {
        const { scale } = this.props;
        return ONE_CELL_WIDTH * (scale || 1);
    }

    draw = () => {
        let canvas = document.getElementById('canvasPlan');
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');


        const { elementsPlan } = this.state;
        const { links, elements } = elementsPlan;

        ctx.strokeStyle = "white";

        for (let i = 0; i < links.length; i++) {
            const { linked, type } = links[i];


            const firstElParams = this.getElementDrawParams(elements[linked[0]])
            const secondElParams = this.getElementDrawParams(elements[linked[1]])

            if (type === 'education') ctx.strokeStyle = "gray";
            if (type === 'primal') ctx.strokeStyle = "orange";

            ctx.beginPath();       // Начинает новый путь
            ctx.moveTo(firstElParams.left + firstElParams.width, firstElParams.top + firstElParams.height / 2);    // Передвигает перо в точку (30, 50)
            ctx.lineTo(secondElParams.left, secondElParams.top + secondElParams.height / 2);  // Рисует линию до точки (150, 100)
            ctx.stroke();
        }
    }

    render() {

        const { max_x, max_y } = this.state;

        const oneCellWidth = this.oneCellWidth();

        const style = {
            width: oneCellWidth * max_x,
            height: oneCellWidth * max_y
        }

        return <div style={style} onClick = {this.draw} className='TPLan_conatiner'>
            <this.DrawLinks />
            <this.DrawElements />
        </div>
    }

    DrawElements = () => {

        const { elementsPlan } = this.state;
        const { elements } = elementsPlan;

        const elementsKeys = Object.keys(elements);

        let Answer = [];

        for (let i = 0; i < elementsKeys.length; i++) {
            const elementKey = elementsKeys[i];
            const element = elements[elementKey];
            const { x, y } = element;
            // const elementObj = props.client.getElement(elementsKey);
            Answer.push(
                <this.OneElement
                    key={`dee-${elementKey}`}
                    {...{ elementKey, x, y }}
                />
            )
        }
        return Answer;
    }

    DrawLinks = () => {
        return <canvas id={'canvasPlan'} >

        </canvas>
    }

    getElementDrawParams = ({ elementKey, x, y }) => {
        const oneCellWidth = this.oneCellWidth();
        const oneElementWidth = oneCellWidth * 0.7;
        const oneElementMargin = (oneCellWidth - oneElementWidth) / 2;

        return {
            left: ((x - 1) * oneCellWidth) + oneElementMargin,
            top: ((y - 1) * oneCellWidth) + oneElementMargin,
            width: oneElementWidth,
            height: oneElementWidth,
            mrg: oneElementMargin
        }
    }

    OneElement = ({ elementKey, x, y }) => {

        const { client, iamuser } = this.props;

        const oneCellWidth = this.oneCellWidth();
        const oneElementWidth = oneCellWidth * 0.7;
        const oneElementMargin = (oneCellWidth - oneElementWidth) / 2;

        const element = client.getElement(elementKey);
        const { title } = element;

        const style = this.getElementDrawParams({ elementKey, x, y });

        let className = 'TPLan_OneElement';
        // let className = 'TPlan_OneElement';
        if (iamuser && iamuser.myElementsObj) {
            if (iamuser.myElementsObj[elementKey]) {

            } else {
                className = 'TPlan_OneElement TPlan_OneElement_dashed';
            }
        }

        return <Tooltip title={title[0]}>
            <div style={style} className={className}>
                <img src={client.getTechnicLogoUrl(elementKey)} alt='' />
            </div>
        </Tooltip>
    }

}


export default withCapoeiraSportState('iamuser, client, isMobile')(TPLan);