import React, { PureComponent } from 'react';
import LittleApplication from 'src/ver2/components/application/little';
import Loader from 'src/ver2/components/loader';

import './style.css';

//getEventById

/**
  Это плашка заявок
  Сюда приходит массив заявок Applications
 */

export default class ApplicationsLittleListPlashka extends PureComponent {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const { Applications, itemHeight, upperStyle, onAccept, onDecline, onlyNotAccepted = false } = this.props;
        const { width, height } = upperStyle;

        if (!Applications) return <div style={{ width, height: Math.max(height, 100) }}  className='applications_little_plashka'>
            <Loader/>
        </div>

        const Appl = [...Applications].reverse();

        return <div style={{ width, height }} className='applications_little_plashka'>
            {Appl.map((Application) => {
                const { Id, Result } = Application;
                if (Result === 'accept' && onlyNotAccepted) return null;
                return <LittleApplication height={itemHeight || 40} key={'plt' + Id} Application={Application} onAccept = {onAccept} onDecline = {onDecline} />
            })}
        </div>
    }

}

// export default withCapoeiraSportState('client')(EventLittleListPlashka);