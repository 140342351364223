import React, { Component } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import './style.css';

import DataChanger from 'src/ver2/containers/dataChanger';
import AddOperationPurpose from './purpose';
import ButtonPlashka from 'src/ver2/plashki/ui/button';
import ItemInventarComponent from 'src/ver2/components/item/inventar';




const PAYLOAD_TYPICAL = {
    scores: {
        social: 0,
        judge: 0,
    },
    money: {
        tri: 0,
        squ: 0,
        kmo: 0
    }
}

class AddOperation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Data: {
                type: 'visiting',
                persons: [],
                text: 'Посещение мероприятия',
                payload: { ...PAYLOAD_TYPICAL },
            }
        }

        // выбираем типы в зависимости от того, что нам пришло
        this.TYPES = [
            { key: 'visiting', title: 'Посещение' },
            { key: 'reward', title: 'Премирование' },
        ]

        const { operationsTypes } = props;
        if (operationsTypes === 'championship') {
            this.TYPES = [
                { key: 'visiting', title: 'Посещение' },
                { key: 'reward', title: 'Премирование' },
                { key: 'medals', title: 'Медалисты' }
            ]
            this.state.Data.medals = {
                medalName: '',
                medalPlace: ''
            }
        }
        if (operationsTypes === 'islandReward') {
            this.TYPES = [
                { key: 'reward', title: 'Личные' }
            ]
            this.state.Data.type = 'reward';
            this.state.Data.text = 'Похвала!'
        }

        if (props.resourses) {
            this.state.Data.payload = { ...props.resourses }
        }

    }


    render() {

        const { onAddOperation } = this.props;
        const { Data } = this.state;
        const {
            type,
            persons,
            text,
            payload,
            medals
        } = Data;

        const TypeData = { type };
        const onTypeData = ({ type }) => { this.setState({ Data: { ...Data, type } }) };


        const TextData = { text };
        const onTextData = ({ text }) => { this.setState({ Data: { ...Data, text } }) };

        const addPerson = (Id) => this.setState({ Data: { ...Data, persons: [...persons, { Id }] } })
        const deletePerson = (indexToRemove) => {
            let persons_ = [...persons];
            persons_.splice(indexToRemove, 1);
            this.setState({ Data: { ...Data, persons: persons_ } })
        }

        const PayloadData = { payload };
        const onPayloadData = ({ payload }) => { this.setState({ Data: { ...Data, payload } }) };

        const MedalsData = medals;
        const onMedalsData = (medals) => { this.setState({ Data: { ...Data, medals } }) };

        return <div className='add_operation_content'>
            <div className='add_operation_content_inrow'>
                <DataChanger
                    header={<div>
                        Добавление операции
                    </div>}
                    data={TypeData}
                    buttonText={'ОК'}
                    buttonState={'allNeeded'}
                    onButtonClick={onTypeData}
                    components={[
                        {
                            name: 'type',
                            title: 'Тип операции',
                            type: 'select',
                            options: this.TYPES,
                            neededText: ' '
                        }
                    ]}
                />
                <DataChanger
                    header={<div>
                        Очки и ресурсы
                    </div>}
                    data={PayloadData}
                    buttonText={'ОК'}
                    buttonState={'allNeeded'}
                    onButtonClick={onPayloadData}
                    components={[
                        {
                            name: 'payload',
                            type: 'scores',
                            neededText: ' '
                        }
                    ]}
                />
                <DataChanger
                    header={<div>
                        Описание операции
                    </div>}
                    data={TextData}
                    buttonText={'ОК'}
                    buttonState={'allNeeded'}
                    onButtonClick={onTextData}
                    components={[
                        {
                            name: 'text',
                            title: 'Введите краткое описание',
                            type: 'input',
                            style: {
                                width: 400
                            },
                            neededText: ' '
                        }
                    ]}
                />
            </div>
            {type === 'reward' && <AddOperationPurpose Persons={persons} addPerson={addPerson} deletePerson={deletePerson} />}
            {type === 'visiting' && <div className='add_operation_content_visiting'> {` Количество явок: ${this.props.presenseCount} `}</div>}
            {type === 'medals' && <div className='add_operation_content_inrow'> <DataChanger
                header={<div>
                    Медали
                </div>}
                data={MedalsData}
                buttonText={'ОК'}
                buttonState={'allNeeded'}
                onButtonClick={onMedalsData}
                components={[
                    {
                        name: 'medalName',
                        title: 'Введите название медали',
                        type: 'input',
                        style: {
                            width: 400
                        },
                        neededText: ' '
                    },
                    {
                        name: 'medalPlace',
                        title: 'Введите место (1, 2 или 3)',
                        type: 'input',
                        style: {
                            width: 400
                        },
                        neededText: ' '
                    }
                ]}
            />
                <ItemInventarComponent
                    width={150}
                    name={medals.medalName}
                    uid={1}
                    plashkaType='infomodal'
                />
            </div>
            }

            <ButtonPlashka
                upperStyle={{ width: 150, height: 35 }}
                text={'ДОБАВИТЬ'}
                onClick={() => { onAddOperation(Data) }}
            />
        </div>
    }

}


export default withCapoeiraSportState('client, iamuser')(AddOperation);