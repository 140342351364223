// это стандартный тайтл, который везде используется
import React, { PureComponent } from 'react';
import './style.css';

import { withCapoeiraSportState } from 'src/ver2/context';
import { Input } from 'antd';
import { gsap } from 'gsap';

/**
 * 
 * Это компонент каменной таблички нажимной
 */

class Quest66 extends PureComponent {
    state = {
        deg1: 0,
        deg2: 0
    }

    init = () => {
        this.setState({ editText: '' })
    }

    send = () => {
        const {
            deg1,
            deg2
        } = this.state;

        const editText = `${deg1}_${deg2}`;
        this.props.sendSpecial({ data: { [editText]: 1 } })
    }

    bigCirclePlus = ( step ) => {
        let deg1 = this.state.deg1 + step;
        if (deg1 < 0) deg1 += 12;
        if (deg1 >= 12) deg1 -= 12;
        this.setState({ deg1 })
    }

    littleCirclePlus = ( step ) => {
        let deg2 = this.state.deg2 + step;
        if (deg2 < 0) deg2 += 12;
        if (deg2 >= 12) deg2 -= 12;
        this.setState({ deg2 })
    }

    render() {
        const className = 'quest66_container'

        const { isMobile } = this.props;
        const {
            deg1,
            deg2
        } = this.state;

        const width = isMobile ? isMobile.width * 0.9: 400;
        const height = width;

        return <div className={className}>
            <div style = {{width, height}} className='quest66_circles_container'>
                <div style = {{width, height}} className='quest66_circle1'/>
                <div style = {{width: width * 0.7, height: height * 0.7, transform: `rotate(${30 * deg1}deg)`}} className='quest66_circle2'/>
                <div style = {{width: width * 0.5, height: height * 0.5, transform: `rotate(${30 * deg2}deg)`}} className='quest66_circle3'/>
                <div onClick={() => { this.send()}} style = {{width: width * 0.3, height: height * 0.3}} className='quest66_circle4'/>

                <div onClick={() => { this.bigCirclePlus(-1)}} style = {{width: width * 0.15, height: width * 0.15}} className='quest66_circle_up_left'/>
                <div onClick={() => { this.bigCirclePlus(1)}} style = {{width: width * 0.15, height: width * 0.15}} className='quest66_circle_down_left'/>
                <div onClick={() => { this.littleCirclePlus(-1)}} style = {{width: width * 0.15, height: width * 0.15}} className='quest66_circle_up_right'/>
                <div onClick={() => { this.littleCirclePlus(1)}} style = {{width: width * 0.15, height: width * 0.15}} className='quest66_circle_down_right'/>

            </div>
        </div>
    }

}

export default withCapoeiraSportState('iamuser, client, isMobile')(Quest66);