import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import Loader from 'src/ver2/components/loader';

import { TrefaLayout } from 'src/ver2/containers/layout';

import RegionMapPlashka from './plashki/map';
import RegionInfoPlashka from './plashki/info';
import RegionSchoolsPlashka from './plashki/schools';
import GroupsBigListPlashka from 'src/ver2/plashki/group/bigList';
import RegionEventsPlashka from './plashki/events';
import UiChooser from 'src/ver2/plashki/ui/chooser';

import RaitingPage from 'src/ver2/pages/raiting';

import BlankPlashka from './plashki/_test';

import PanteonPlashka from 'src/ver2/plashki/person/panteon';

import SomeInfo from 'src/ver2/components/info';

import './styles.css';

class RegionPage extends PureComponent {

    constructor(props) {

        super(props);

        const region = this.props.match.params.region;

        this.state = {
            status: 'loaded',
            page: 'info',
            region
        }

        this.searchDefault = {
            page: 'info'
        }

    }

    linkTo = (param, value) => {
        let { page } = this.state;
        let linkObject = { page };
        linkObject[param] = value;
        return `?${Object.keys(linkObject).reduce((memo, key, index) => {
            if (!linkObject[key]) return memo;
            return memo + `${index > 0 ? '&' : ''}${key}=${linkObject[key]}`
        }, '')}`
    }

    chooseTab = () => {
        const { location } = this.props;
        const { search } = location;

        // парсим search
        let searchObj = {
            ...this.searchDefault
        }

        const searchAsObj = search.split('?');
        if (searchAsObj && searchAsObj[1]) {
            const search_ = searchAsObj[1].split('&');
            for (let i = 0; i < search_.length; i++) {
                const search__ = search_[i].split('=');
                searchObj[search__[0]] = search__[1];
            }
        }

        this.setState({
            ...searchObj
        })

    }

    componentDidMount() {
        this.chooseTab();
    }
    componentDidUpdate() {
        this.chooseTab();
        const region = this.props.match.params.region;
        if (this.state.region !== region) {
            this.setState({ region })
        }
    }

    render() {

        const { isMobile } = this.props;

        const width = isMobile ? isMobile.width : 1200;

        const { status, region, page } = this.state;

        if (status === 'loading') return <div style={{ width, height: 700 }} className='region_page'>
            <Loader />
        </div>
        let Items = [];
        Items.push({ Title: 'Инфо', name: 'info', icon: 'info', linkTo: `/region/${region}/${this.linkTo('page', 'info')}` });
        Items.push({ Title: 'Спорт', name: 'sport', icon: 'categorys', linkTo: `/region/${region}/${this.linkTo('page', 'sport')}` });
        Items.push({ Title: 'Судьи', name: 'judge', icon: 'categorys', linkTo: `/region/${region}/${this.linkTo('page', 'judge')}` });
        Items.push({ Title: 'Рейтинг', name: 'raiting', icon: 'results', linkTo: `/region/${region}/${this.linkTo('page', 'raiting')}` });

        const components = [
            {
                component: RegionInfoPlashka,
                cell_x: 2,
                collapse: 1,
                id: 1,
                componentProps: { height: 175, yPadding: 'mini', _title: 'Регион', region }
            },
            {
                component: RegionMapPlashka,
                cell_x: 3,
                collapse: 1,
                id: 2,
                componentProps: { height: isMobile ? 150 : 355, yPadding: 'mini', region }
            },
            {
                component: UiChooser,
                cell_x: 2,
                collapse: 1,
                id: 3,
                componentProps: {
                    height: 130, yPadding: 'usual', tab: page, Items, mobileStyle: true, itemHeight: isMobile ? 50 : 75
                }
            }
        ]

        let DownedLayout = null;

        if (page === 'info') {

            if (isMobile) {
                DownedLayout = <>
                    <SomeInfo size='mini' infoKey='NO_MY_SCHOOL_IN_REGION' />
                    <TrefaLayout greedsX={[10, width - 20, 10]}
                        components={
                            [
                                {
                                    component: RegionSchoolsPlashka,
                                    cell_x: 2,
                                    collapse: 1,
                                    id: 11,
                                    componentProps: { height: 195, yPadding: 'mini', _title: 'Школы', region }
                                },
                                {
                                    component: GroupsBigListPlashka,
                                    cell_x: 2,
                                    collapse: 1,
                                    id: 12,
                                    componentProps: { height: 350, yPadding: true, _title: 'Группы', mode: { name: 'region', region } }
                                },
                                {
                                    component: RegionEventsPlashka,
                                    cell_x: 2,
                                    collapse: 1,
                                    id: 13,
                                    componentProps: { height: 955, yPadding: true, _title: 'Соревнования', region }
                                }
                            ]
                        }
                    />
                </>
            } else

                DownedLayout = <>
                    <SomeInfo size='mini' infoKey='NO_MY_SCHOOL_IN_REGION' />
                    <TrefaLayout greedsX={[35, 600, 60, width - 755, 60]}
                        components={
                            [
                                {
                                    component: RegionSchoolsPlashka,
                                    cell_x: 2,
                                    collapse: 1,
                                    id: 11,
                                    componentProps: { height: 255, yPadding: 'mini', _title: 'Школы', region }
                                },
                                {
                                    component: GroupsBigListPlashka,
                                    cell_x: 2,
                                    collapse: 1,
                                    id: 12,
                                    componentProps: { height: 350, yPadding: true, _title: 'Группы', mode: { name: 'region', region } }
                                },
                                {
                                    component: RegionEventsPlashka,
                                    cell_x: 4,
                                    collapse: 1,
                                    id: 13,
                                    componentProps: { height: 955, yPadding: true, _title: 'Соревнования', region }
                                }
                            ]
                        }
                    />
                </>
        }

        if (page === 'judge') {
            DownedLayout = <TrefaLayout greedsX={ isMobile ? [5, width - 10, 10] :[30, width - 80, 40]}
                components={
                    [
                        {
                            component: BlankPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 24, _title: 'Судьи' }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 21,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'high',
                                itemHeight: isMobile ? 120 : 240,
                                panteonMode: { type: "regionJudges", region, judgeRank: 'vseross' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 22,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'high',
                                itemHeight: isMobile ? 120 : 240,
                                panteonMode: { type: "regionJudges", region, judgeRank: '1' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 23,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionJudges", region, judgeRank: '2' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 24,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionJudges", region, judgeRank: '3' }
                            }
                        },
                    ]
                }
            />
        }

        if (page === 'sport') {
            DownedLayout = <TrefaLayout greedsX={ isMobile ? [5, width - 10, 10] :[30, width - 80, 40]}
                components={
                    [
                        {
                            component: BlankPlashka,
                            cell_x: 2,
                            collapse: 1,
                            componentProps: { height: 24, _title: 'Спортивные разряды' }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 121,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'high',
                                itemHeight: isMobile ? 120 : 240,
                                panteonMode: { type: "regionSport", region, sportRank: 'ms' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 122,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'high',
                                itemHeight: isMobile ? 120 : 240,
                                panteonMode: { type: "regionSport", region, sportRank: 'kms' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 123,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionSport", region, sportRank: '1s' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 124,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionSport", region, sportRank: '2s' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 125,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionSport", region, sportRank: '3s' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 126,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionSport", region, sportRank: '1y' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 127,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionSport", region, sportRank: '2y' }
                            }
                        },
                        {
                            component: PanteonPlashka,
                            cell_x: 2,
                            collapse: 1,
                            id: 128,
                            componentProps: {
                                height: 200, yPadding: 'mini',
                                titleHeight: isMobile ? 32 : 72, autoTitle: true, mode: 'usual',
                                panteonMode: { type: "regionSport", region, sportRank: '3y' }
                            }
                        }
                    ]
                }
            />
        }

        if (page === 'raiting') {
            DownedLayout = <RaitingPage location={this.props.location} mainTo={`/region/${region}`} region={region} />
        }

        if (isMobile) {
            return <div style={{ width }} className='region_page'>
                <TrefaLayout greedsX={[10, width - 20, 10]}
                    components={
                        components
                    }
                />
                {DownedLayout}
            </div>
        }

        return <div style={{ width }} className='region_page'>
            <TrefaLayout greedsX={[35, 400, width - 435]}
                components={
                    components
                }
            />
            {DownedLayout}
        </div>
    }
}


export default withCapoeiraSportState('client, showInfoModal, isMobile')(RegionPage);