import ComboInfoPlashka from './info';
import ComboTitlePlashka from './title';
import ComboSchemePlashka from './scheme';

const exportt = {
    ComboInfoPlashka,
    ComboTitlePlashka,
    ComboSchemePlashka
}

export default exportt;