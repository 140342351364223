import React, { PureComponent } from 'react';

import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaText from 'src/ver2/components/trefaText';

import Loader from 'src/ver2/components/loader';
import { message } from 'antd';
// import { Link } from 'react-router-dom';

import './style.css';

// class ShopDonatePlashka
// classname: event_donate_plashka

/**
  Это просто типовая плашка, копировать отсюда
 */

class ShopDonatePlashka extends PureComponent {

    render() {

        const { upperStyle, iamuser } = this.props;
        const { width, height } = upperStyle;

        if (!iamuser) {

            return <div onClick={() => {message.info('Пожалуйста, войдите в аккаунт, чтобы иметь возможность сделать донат') }} style={{ width, height }} className='event_donate_plashka'>
                <img src='/../images/ver2/scores/donate/250.png' />
                <img src='/../images/ver2/scores/donate/500.png' />
                <img src='/../images/ver2/scores/donate/1000.png' />
                <img src='/../images/ver2/scores/donate/2000.png' />
            </div>
        }

        return <div style={{ width, height }} className='event_donate_plashka'>
            <div className='event_donate_plashka_wrapper' title="Внести 250р">
                <form name={'3330'} method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
                    <input type="hidden" name="receiver" value="4100117952110682" />
                    <input type="hidden" name="formcomment" value="CapoeiraSport поддержка 250" />
                    <input type="hidden" name="short-dest" value="CS donation 250" />
                    <input type="hidden" name="label" value={`{"donate": 250, "UserId": ${iamuser.Id}}`} />
                    <input type="hidden" name="quickpay-form" value="donate" />
                    <input type="hidden" name="targets" value="донат" />
                    <input type="hidden" name="sum" value="250" data-type="number" />
                    <input type="hidden" name="need-fio" value="false" />
                    <input type="hidden" name="need-email" value="false" />
                    <input type="hidden" name="need-phone" value="false" />
                    <input type="hidden" name="need-address" value="false" />
                    <input type="hidden" name="successURL" value="https://capoeirasport.com/shop/4" />
                    <input type="submit" value='' className='event_donate_plashka_input' />
                    <img className='event_donate_plashka_img_250' src='/../images/ver2/scores/donate/250.png' />
                </form>
            </div>
            <div className='event_donate_plashka_wrapper' title="Внести 500р">
                <form name={'3341'} method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
                    <input type="hidden" name="receiver" value="4100117952110682" />
                    <input type="hidden" name="formcomment" value="CapoeiraSport поддержка 500" />
                    <input type="hidden" name="short-dest" value="CS donation 500" />
                    <input type="hidden" name="label" value={`{"donate": 500, "UserId": ${iamuser.Id}}`} />
                    <input type="hidden" name="quickpay-form" value="donate" />
                    <input type="hidden" name="targets" value="донат" />
                    <input type="hidden" name="sum" value="500" data-type="number" />
                    <input type="hidden" name="need-fio" value="false" />
                    <input type="hidden" name="need-email" value="false" />
                    <input type="hidden" name="need-phone" value="false" />
                    <input type="hidden" name="need-address" value="false" />
                    <input type="hidden" name="successURL" value="https://capoeirasport.com/shop/4" />

                    <input type="submit" value='' className='event_donate_plashka_input' />
                </form>
                <img className='event_donate_plashka_img_500' src='/../images/ver2/scores/donate/500.png' />
            </div>
            <div className='event_donate_plashka_wrapper' title="Внести 1000р">
                <form name={'3345'} method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
                    <input type="hidden" name="receiver" value="4100117952110682" />
                    <input type="hidden" name="formcomment" value="CapoeiraSport поддержка 1000" />
                    <input type="hidden" name="short-dest" value="CS donation 1000" />
                    <input type="hidden" name="label" value={`{"donate": 1000, "UserId": ${iamuser.Id}}`} />
                    <input type="hidden" name="quickpay-form" value="donate" />
                    <input type="hidden" name="targets" value="донат" />
                    <input type="hidden" name="sum" value="1000" data-type="number" />
                    <input type="hidden" name="need-fio" value="false" />
                    <input type="hidden" name="need-email" value="false" />
                    <input type="hidden" name="need-phone" value="false" />
                    <input type="hidden" name="need-address" value="false" />
                    <input type="hidden" name="successURL" value="https://capoeirasport.com/shop/4" />

                    <input type="submit" value='' className='event_donate_plashka_input' />
                    <img className='event_donate_plashka_img_1000' src='/../images/ver2/scores/donate/1000.png' />
                </form>
            </div>
            <div className='event_donate_plashka_wrapper' title="Внести 2000р">
                <form name={'3344'} method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
                    <input type="hidden" name="receiver" value="4100117952110682" />
                    <input type="hidden" name="formcomment" value="CapoeiraSport поддержка 2000" />
                    <input type="hidden" name="short-dest" value="CS donation 2000" />
                    <input type="hidden" name="label" value={`{"donate": 2000, "UserId": ${iamuser.Id}}`} />
                    <input type="hidden" name="quickpay-form" value="donate" />
                    <input type="hidden" name="targets" value="донат" />
                    <input type="hidden" name="sum" value="2000" data-type="number" />
                    <input type="hidden" name="need-fio" value="false" />
                    <input type="hidden" name="need-email" value="false" />
                    <input type="hidden" name="need-phone" value="false" />
                    <input type="hidden" name="need-address" value="false" />
                    <input type="hidden" name="successURL" value="https://capoeirasport.com/shop/4" />

                    <input type="submit" value='' className='event_donate_plashka_input' />
                    <img className='event_donate_plashka_img_2000' src='/../images/ver2/scores/donate/2000.png' />
                </form>
            </div>
        </div>
    }

}

export default withShopState(`
Shop,  
client,
iamuser,
Discription,
isDiscriptionReady
`)(ShopDonatePlashka);