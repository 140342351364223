// это стандартный тайтл, который везде используется
import React, { Component } from 'react';
import './style.css';
import { Tabs, message } from 'antd';
import { withShopState } from 'src/ver2/pages/shop/context';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';
import ImageUploader from 'src/ver2/containers/imageChanger';

import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';

const TabPane = Tabs.TabPane;

/**
 * 
 * Это компонент создания предмета
 */

const AddItemArrival = ({ iAmAdmin, addRealArrival, RealArticuleId, closeModal, RealShopArticules }) => {

    if (!iAmAdmin) return <div>ЧО ТЫ ТУТ ДЕЛАЕШЬ???</div>

    const mainInformationChange = async (Data) => {
        addRealArrival({ ...Data, RealArticuleId });
        closeModal();
    }

    const Articule = (RealShopArticules || []).find( x => x.Id === RealArticuleId );
    const ArticuleTitle = (Articule || {}).Name;

    return <div className='admin_modal'>
        <Tabs className='admin_modal_tabs' tabPosition='left' style={{ color: 'white', heightMin: 500 }} defaultActiveKey="1">

            <TabPane tab={'Основные данные'} key="1">
                <DataChanger
                    header={<div>
                        Добавления артикула {ArticuleTitle}
                    </div>}
                    data={{ Count: 0 }}
                    buttonText={'ДОБАВИТЬ'}
                    buttonState={'allNeeded'}
                    onButtonClick={mainInformationChange}
                    components={[
                        {
                            name: 'Count',
                            title: 'Количество',
                            type: 'input',
                            style: {
                                width: 200
                            },
                            neededText: ' '
                        }
                    ]}
                />
            </TabPane>
        </Tabs>
    </div>
}

export default withShopState(`

Shop, 
addRealArrival,
RealShopArticules,

iAmAdmin,

client

`)(AddItemArrival);