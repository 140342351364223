import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';
import { SoloSequence } from './components/sequence';
import Helmet from "react-helmet";

import './style.css';

class SequencePaga extends PureComponent {

    state = {};

    render() {

        const width = 1200;
        const { client } = this.props;
        const { isMobile } = this.props;

        const code = this.props.match.params.code;

        return <div style={{ width }} className='sequence_page'>
            <Helmet
                    title="Связка капоэйра на CapoeiraSport"
                    meta={[
                        { "name": "description","property": "description", "content": "Капоэйристы могут делиться своими связками, участвовать в online соревнованиях, челленджах, делиться техникой" },
                        { "name": "og:image","property": "og:image", "content": "https://capoeirasport.com/images/ver2/logo.png" },
                        { "name": "og:title","property": "og:title", "content": "Связка капоэйра" },
                        { "name": "og:type","property": "og:type", "content": "video" },
                        { "name": "og:url","property": "og:url", "content": `https://capoeirasport.com/sequence/${code}` },
                        { "name": "og:video","property": "og:video", "content": `https://capoeirasport.com/graphics/video_technics/sequences/${code}.mp4` }
                    ]}
                />
            <SoloSequence
                client={client}
                code = {code}
                isMobile={isMobile}
            />
        </div>
    }

}


export default withCapoeiraSportState('iamuser, client, isMobile')(SequencePaga);