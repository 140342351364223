import React, { Component } from 'react';
import './component.css';

import LittlePerson from 'src/ver2/components/person/little';
import { SoloSequenceRow } from './components/sequence-row';
// import { SoloSpecial } from '../../components/special';
import { SoloMiniSequence } from 'src/ver2/containers/challenges/components/mini-sequence';
import EventAvatar from 'src/ver2/components/event/avatar';
import TrefaText from 'src/ver2/components/trefaText';
import TrefaTextEdit from 'src/ver2/components/trefaText/edit';

import { Input, Button, Modal, message } from 'antd';

import { Link } from 'react-router-dom';

const copyToClipboard = (value) => {
  const copytext = document.getElementById('copyCopyText');
  if (copytext) {
    copytext.value = value;
    copytext.select();
    document.execCommand('copy');
    message.success('Скопировано ' + value);
  }
}

export class SoloSequence extends Component {

  constructor(props) {
    super(props);

    // сюда заводим код

    this.state = {}

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {

      this.state.mobile = true;

    } else this.state.mobile = false;

    const { code, client, iSawItForCompetitions } = props;

    this.state.telephone = '+79267674316';

    this.state.status = 'loading';
    this.state.sendVideoKey = 'WU';
    this.state.isCopiedText = false;
    this.state.sendVideoYB = '';
    this.state.sendVideoIN = '';
    this.state.judges5MyScore = 0;
    this.state.showCommentModal = false;

    const iamuser = client.iamuser;

    let UserId = client.loggedUserId();

    this.UserId = UserId;
    this.sequenceId = null;

    /*  */

    if (code) {

      client.getSoloUserSequences({ Code: code }).then((result) => {

        if (!result || !result[0]) {
          return this.setState({ sequence: null, status: 'no_sequence' })
        }

        const sequence = result[0];

        const { RoundId } = sequence;

        // тут проверяем, если смотрит владелец связки - мы с неё снимаем i saw it

        if (iSawItForCompetitions && iSawItForCompetitions.find(x => x.ThingId === sequence.Id)) {
          const thisISaw = iSawItForCompetitions.find(x => x.ThingId === sequence.Id);
          client.deleteISawItSolo(thisISaw.Id);
        }

        if (!RoundId) return this.setState({ sequence: null, status: 'no_sequence' });

        // теперь грузим раунд

        client.getSoloRounds(null, RoundId).then((resultRound) => {
          if (!resultRound && !resultRound[0]) {
            return this.setState({ sequence: null, status: 'no_sequence' })
          }

          const is_mine = UserId && sequence.UserId === UserId;

          const { Type } = resultRound[0];
          if (Type === 'judge5' || Type === 'judge5h') {
            // подгружаем, кто тут судьи  
            let judges5 = [];
            this.loadJudge5Roles(resultRound[0].Id, sequence);
          }

          //и дальше грузим соревы
          client.getSoloCompetitions(resultRound[0].SoloCompetitionId).then(resultCompetition => {

            this.setState({ sequence, round: resultRound[0], competition: resultCompetition[0], is_mine, status: 'loaded' })

          })


        }

        )



      })

    } else {
      this.state.status = 'no_sequence'
    }

    /* Что у нас в связке такое важное - 
      "Status": 
         waiting - подали и ожидает
         canceled - отменена
         confirm - подтверждена
    */

  }

  loadJudge5Roles = async (roundId, sequence) => {
    const { client } = this.props;
    let judge5Judges = await client.getSomeRoles({ SubjectType: 'round', SubjectId: roundId })

    // тут же смотрим оценки судей и их комментарии
    const comments = await client.getDiscriptions({ subject: 'sequence', subjectId: sequence.Id, type: 'judge5comment' }) || [];

    if (sequence && sequence.Options) {
      let OptionsParse = JSON.parse(sequence.Options);
      if (this.UserId && OptionsParse && OptionsParse.judges5 && OptionsParse.judges5.scores && OptionsParse.judges5.scores[this.UserId]) {
        this.setState({ judges5MyScore: OptionsParse.judges5.scores[this.UserId] })
      }
    }

    for (let i = 0; i < judge5Judges.length; i++) {
      const { UserId } = judge5Judges[i];
      const comment = comments.find(x => x.AuthorId === UserId);
      if (comment) {
        judge5Judges[i].comment = { ...comment };
      }
    }

    this.setState({ judge5Judges: judge5Judges })
  }

  amIInstructor = async () => {
    let amIInstructor = true;
    this.setState({ amIInstructor })
  }

  get amIJudge5() {
    const { judge5Judges } = this.state;
    if (!judge5Judges || !this.UserId) return false;

    if (judge5Judges.find(x => x.UserId === this.UserId)) {
      return true;
    }

    return false;
  }

  componentWillUpdate() {

    const { client } = this.props;
    const { sequence } = this.state;
    const sequenceId = sequence ? sequence.Id : null;

    if (client.loggedUserId() !== this.UserId || this.sequenceId !== sequenceId) {

      if (client.loggedUserId() !== this.UserId) {
        this.UserId = client.loggedUserId();
      }

      if (this.sequenceId !== sequenceId)
        this.sequenceId = sequenceId;

      if (sequence && sequence.UserId) {
        const is_mine = this.UserId && sequence.UserId === this.UserId;
        this.setState({ is_mine })
      }
    }

  }

  componentDidUpdate() {

    // const { client } = this.props;
    const { isJudge, sequence } = this.state;

    // тут же смотрим судейство 
    if (!isJudge && sequence && this.UserId) {
      if (this.UserId === 2) {
        this.setState({ isJudge: true })
      }
    }

  }

  judgeSavePoint = (JudgePoints, Points) => {
    const { code, client } = this.props;
    const { scores_element, scores_seq } = JudgePoints;

    let JudgeOptions = '';

    for (let i = 0; i < scores_element.length; i++) {
      JudgeOptions += `el_${i}*${scores_element[i].toFixed(1)}/`;
    }

    for (let i = 0; i < scores_seq.length; i++) {
      JudgeOptions += `sq_${i}*${scores_seq[i].toFixed(1)}`;
      if (i < scores_seq.length - 1) {
        JudgeOptions += `/`;
      }
    }

    // сначала сохраняем асинхронно результат, потом грузим его

    const { sequence: { UserId } } = this.state;

    // client.setISawItSolo({ UserId, Type: 'sequencePoints', ThingId: Id, JudgeId: client.loggedUserId() });



    client.judgeSoloUserSequences({ JudgeUserId: client.loggedUserId(), JudgeOptions, Points, Code: code }).then(() => {

      client.addNotification({
        UserId,
        Body: `^a_^text^a_^Ваша <a href='https://capoeirasport.com/sequence/${code}'>связка</a> получила очки^!`
      })

      client.getSoloUserSequences({ Code: code }).then((result) => {

        if (!result && !result[0]) {
          return this.setState({ sequence: null, status: 'no_sequence' })
        }

        const sequence = result[0];

        const { RoundId } = sequence;

        if (!RoundId) return this.setState({ sequence: null, status: 'no_sequence' });

        // теперь грузим раунд

        client.getSoloRounds(null, RoundId).then((result) => {
          if (!result && !result[0]) {
            return this.setState({ sequence: null, status: 'no_sequence' })
          }
          this.setState({ sequence, round: result[0], status: 'loaded' })
        }
        )
      })
    })




    this.setState({ status: 'loading' })

  }

  render() {

    const { client } = this.props;
    const { status, sequence, competition, mobile, showCommentModal } = this.state;

    if (status === 'loading') return <div>Загрузка...</div>
    if (status === 'no_sequence' || !sequence) return <div>Такой связки нет</div>

    return (
      <div className='solo-sequence-div' style={mobile ? { paddingBottom: 50 } : {}}>
        <this.UpperRow />
        <this.DescriptionRow />
        <SoloSequenceRow {...{ client, competition, sequence, judgeSavePoint: this.judgeSavePoint, mobile }} />
        <this.VideoRow />
        <this.Judges5Judges />
        <this.JudgeComment />
        {showCommentModal && <Modal
          onCancel={() => { this.setState({ showCommentModal: false }) }}
          visible={showCommentModal}
          footer={null}
          // className = 'solo-sequence-div_modalclass'
          width={1200}
        // content={showCommentModal ? <this.CommentModal /> : null}
        >
          {showCommentModal ? <this.CommentModal /> : null}
        </Modal>}
      </div>
    );
  }

  CommentModal = () => {
    const { judge5Judges, sequence, round } = this.state;
    console.log('CommentModal');
    if (!judge5Judges) return null;

    const myComment = judge5Judges.find(x => x.UserId === this.UserId);
    let comment = null;
    if (myComment && myComment.comment) comment = myComment.comment;

    const addDiscription = async (body) => {
      const { client } = this.props;
      await client.addDiscription({ body, subject: 'sequence', subjectId: sequence.Id, type: 'judge5comment' });
      this.loadJudge5Roles(round.Id, sequence);
      message.success('Добавлено инфо');
    }

    const updateDiscription = async (body) => {
      const { client } = this.props;
      if (!comment) return;
      const discriptionId = comment.Id;
      await client.updateDiscription({ discriptionId, body });
      this.loadJudge5Roles(round.Id, sequence);
      message.success('Изменения сохранены');
    }

    return <div className='solo-sequence-div-commentmodal_content'>
      {comment ?
        <InfoChanger text={comment.Body} onCallback={updateDiscription} /> :
        <InfoAdder onCallback={addDiscription} />
      }
    </div>
  }

  Judges5Judges = () => {
    const { judge5Judges, sequence, judges5MyScore, round } = this.state;
    const { isMobile } = this.props;
    if (!judge5Judges) return null;

    let finalScore = null;

    let OptionsParse = {};
    if (sequence && sequence.Options) {
      OptionsParse = JSON.parse(sequence.Options);
      if (OptionsParse && OptionsParse.judges5 && OptionsParse.judges5.result) {
        finalScore = OptionsParse.judges5.result;
      }
    }

    const minusScore = () => {
      const newScore = judges5MyScore - 0.5;
      if (newScore >= 0) {
        this.setState({ judges5MyScore: newScore })
      }
    }
    const plusScore = () => {
      const newScore = judges5MyScore + 0.5;
      if (newScore <= 5) {
        this.setState({ judges5MyScore: newScore })
      }
    }

    let style = {};

    if (isMobile) {
      style = { width: isMobile.width }
    }

    return <div style={style} className='solo-sequenct-judge-comment-div'>
      <div className='solo-sequenct-judge-comment-title'>
        ОЦЕНКА НА ПОЯС
      </div>
      <div className='solo-sequenct-judge-comment-final'>
        {finalScore ? `ФИНАЛЬНЫЙ БАЛЛ :    ${finalScore}` : 'ОЦЕНКИ ПОКА НЕТ'}
      </div>
      {(this.amIJudge5 || this.state.is_mine) && <>
        {(this.amIJudge5 && !finalScore) && <div className='solo-sequenct-judge5-setscore'>
          Ваш балл
          <br />
          <div className='solo-sequenct-judge5-setscore-buttons'>
            <div className='solo-sequenct-judge5-setscore-buttons-pluses' onClick={minusScore}><span>-</span></div>
            <div className='solo-sequenct-judge5-setscore-buttons-score'><span>{judges5MyScore}</span></div>
            <div className='solo-sequenct-judge5-setscore-buttons-pluses' onClick={plusScore}><span>+</span></div>
          </div>
          {/* <Input value={judges5MyScore} onChange={(e) => { this.setState({ judges5MyScore: Number(e.target.value) }) }} /> */}
          <Button type='primary' onClick={() => { this.judge5SendScore() }} >ОТПРАВИТЬ</Button>
        </div>
        }
        {this.amIJudge5 && <div className='solo-sequenct-judge5-setscore-buttoncomment'><Button type='primary' onClick={() => { this.setState({ showCommentModal: true }) }} >КОММЕНТАРИЙ</Button></div>}
        {
          judge5Judges.map(({ UserId, comment }, index) => {

            let score = null;

            if (OptionsParse && OptionsParse.judges5 && OptionsParse.judges5.scores && OptionsParse.judges5.scores[UserId]) {
              score = OptionsParse.judges5.scores[UserId]
            }

            let personPart = null;
            if (this.amIJudge5) {
              personPart = <LittlePerson personId={UserId} height={60} Suffix={(score && this.amIJudge5) ? <div className='solo-sequenct-judge-suffix-score'>{score}</div> : null} />
            } else {
              if (round && round.Type === 'judge5') {
                personPart = <LittlePerson personId={UserId} height={60} Suffix={(score && this.amIJudge5) ? <div className='solo-sequenct-judge-suffix-score'>{score}</div> : null} />
              }
              if (round && round.Type === 'judge5h') {
                personPart = <div> СУДЬЯ {index + 1} </div>
              }
            }

            return <>
              {personPart}
              {comment && <div className='solo-sequenct-judge-comment-body'><TrefaText trefaText={comment.Body} /></div>}
            </>
          })
        }
      </>}
    </div >

  }

  UpperRow = () => {

    const { mobile } = this.state;

    if (mobile) return <div className='solo-sequence-column-upper-div'>
      <div className='solo-sequence-row-upper-div'>
        <this.CompetitionLogo />
        <this.RoundTier />
        <this.RoundNumber />
      </div>
      <this.SequencePerson />
      <this.SequenceStatus />
    </div>

    return <div className='solo-sequence-row-upper-div'>
      <this.CompetitionLogo />
      <this.RoundTier />
      <this.RoundNumber />
      <this.SequencePerson />
      <this.SequenceStatus />
    </div>
  }

  DescriptionRow = () => {


    const { client } = this.props;
    const { round } = this.state;

    const { Description, Sequence, Options, EndingText } = round;

    let special_array = [];

    if (EndingText) {

      const parts = EndingText.split('/');

      for (let i = 0; i < parts.length; i++) {

        const part = parts[i];

        const first_part = part.substring(0, 3);
        const second_part = part.substring(3);

        if (first_part === 'sp_') {
          // special_array.push(<SoloSpecial client={client} special_type={second_part} />)
        }

      }

    }

    /* TODO ТУТ НАДО ДОПИСАТЬ ЕЩЕ soloCompetition -> RULES */
    return <div className='solo-sequence-description-div'>
      <b> Задание: </b>
      {Description.split('/').map((item, index) => {
        return <div key={`ssdd${index}`}> {item} </div>
      })}
      <SoloMiniSequence
        client={client}
        Sequence={Sequence}
        Options={Options}
      />
      {special_array}
    </div>
  }


  CompetitionLogo = () => {
    const { round } = this.state;
    const { SoloCompetitionId } = round;
    return <div className='solo-sequence-row-div-competition-logo'>
      <Link to={`/event/${SoloCompetitionId}/?page=rounds`}>
        <EventAvatar eventId={SoloCompetitionId} size={80} />
      </Link>
    </div>
  }

  RoundTier = () => {
    const { round } = this.state;
    return <div className='solo-sequence-row-div-number'>
      <div className='solo-sequence-row-div-tworows-upper'>{round.Tier}</div>
      <div className='solo-sequence-row-div-tworows-lower'>TIER</div>
    </div>
  }

  RoundNumber = () => {
    const { round } = this.state;
    const { Id } = round;
    return <Link to={`/round/${Id}`}>
      <div className='solo-sequence-row-div-number'>
        <div className='solo-sequence-row-div-tworows-upper'>{round.Number}</div>
        <div className='solo-sequence-row-div-tworows-lower'>РАУНД</div>
      </div>
    </Link>
  }

  SequencePerson = () => {
    const { client } = this.props;
    const { sequence, mobile } = this.state;

    return <div className='solo-sequence-row-div-person' style={mobile ? { margin: 0 } : {}}>
      <LittlePerson personId={sequence.UserId} height={60} />
      {/* <PersonCard personId = {sequence.UserId} client = {client} LittleSize/> */}
    </div>
  }

  SequenceStatus = () => {
    const { sequence, mobile } = this.state;
    const { Points, Status, IsVideo } = sequence;

    let Upper = '';
    if (Status === 'confirm') Upper = Points;

    let status = Status;
    if (Status === 'confirm') status = 'ОЧКИ'

    if (Status === 'waiting') {
      Upper = 'ОЖИДАЕТ';
      if (!IsVideo) { status = 'ВИДЕО' } else { status = 'ОЦЕНКИ' };
    }

    return <div className='solo-sequence-row-div-status' style={mobile ? { border: 'none' } : {}}>
      <div className='solo-sequence-row-div-tworows-upper' > {Upper} </div>
      <div className='solo-sequence-row-div-tworows-lower'>{status}</div>
    </div>
  }

  JudgeComment = () => {

    const { sequence, is_mine, isJudge } = this.state;
    if (!sequence || !sequence.JudgeComment) return null;
    if (!is_mine && !isJudge) return null;

    const { JudgeComment } = sequence;

    const JudgeComment_array = JudgeComment.split('/');

    return <div className='solo-sequenct-judge-comment-div'>
      <div className='solo-sequenct-judge-comment-title'>
        Комментарий судьи (его видите только вы)
      </div>
      {JudgeComment_array.map(comment_string => {
        return <div className='solo-sequenct-judge-comment-string' >{comment_string}</div>
      })}

    </div>


  }

  VideoRow = () => {
    const { sequence, is_mine, sendVideoKey, isJudge, mobile } = this.state;
    const { IsVideo, Code } = sequence;

    // если нет видео и связка не ваша
    if (!IsVideo && !is_mine && !isJudge) return null;

    // это значит что связка моя, но видео не отправлено
    // или связка отправлена через вотсап, но при этом смотрящий - судья. Он может догрузить через инстаграм
    if ((!IsVideo && is_mine)) {
      return <div className='solo-sequence-video-div'>
        <this.SendVideoContainer />
      </div>
    }

    if ((IsVideo === 'SENDED' || !IsVideo) && isJudge) {
      return <div className='solo-sequence-video-div'>
        Вы - судья, пользователь отправил видео связку
        <br />
        <Button onClick={() => { copyToClipboard(Code) }} style={{ marginTop: 10, narginBottom: 10 }} type='primary'>
          {Code}
        </Button>
        <br />
        <Button onClick={() => { copyToClipboard(`ffmpeg -i ${Code}.mp4 -y -vf scale=w=640:h=360:force_original_aspect_ratio=decrease -c:v h264_qsv -crf 20 -r 30 -g 30 result/${Code}.mp4`); }} style={{ marginTop: 10, narginBottom: 10 }} type='primary'>
          СКОПИРОВАТЬ КОД FFMPEG
        </Button>
        <br />
        <Button onClick={() => { this.userSendVideo('i') }} style={{ marginTop: 10, narginBottom: 10 }} type='primary'>
          ВИДЕО ОТПРАВЛЕНО!
        </Button>
      </div>
    }



    if (IsVideo === 'SENDED') return null;

    const url = IsVideo.substring(3);
    if (IsVideo[0] === 'y')
      return <div className='solo-sequence-video-div'>
        <iframe
          width="70%"
          height="300px"
          src={`https://www.youtube.com/embed/${url}`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>

    const showCotrols = (this.amIJudge5 || isJudge);

    if (IsVideo[0] === 'i') {
      return <div className='solo-sequence-video-div'>
        <video className='info-fon-video'
          loop
          muted={'true'}
          controls={showCotrols}
          autoPlay
          data-setup="{ }"
          poster={`https://capoeirasport.com/graphics/video_technics/logo.png`}
          width={mobile ? (document.documentElement.clientWidth - 10) : 700}
        >
          <source
            src={`https://capoeirasport.com/graphics/video_technics/sequences/${Code}.mp4`}
            type="video/mp4" />
        </video>
      </div >

      /* 
        ffmpeg -i p6c18sfz.mp4 -y -vf scale=w=640:h=360:force_original_aspect_ratio=decrease -c:v h264_qsv -crf 20 -r 30 -g 30 result/p6c18sfz.mp4
      */


    }
    return null;

  }

  SendVideoContainer = () => {

    const { sendVideoKey, isCopiedText } = this.state;
    const { code } = this.props;

    if (sendVideoKey === 'WU') {
      return <div className='solo-sequence-send-video-container'>
        {!isCopiedText ? <div>
          Если Вы сняли видео по данной связке и готовы его отправить, нажмите на кпопку ниже, чтобы скопировать необходимый технический текст
          <br />
          <Button onClick={() => { copyToClipboard(`Челлендж capoeirasport.com. Код: ${code}`); this.setState({ isCopiedText: true }) }} style={{ marginTop: 10, narginBottom: 10 }} type='primary'>
            СКОПИРОВАТЬ
          </Button>
        </div> :

          <div>
            Теперь нажмите на кнопку ниже, она переведёт Вас в Телеграм челленджа. <br />
            Вставьте скопированный текст в сообщение и прикрепите видео, соответствующее связке. <br /><br />
            <a href='tg://resolve?domain=RCF_RussianCapoeiraFederation'>
              <Button onClick={() => {

                Modal.info({
                  title: '',
                  content: 'Вы отправили видео в телеграм канал?',
                  onOk: () => { this.userSendVideo('SENDED') },
                  onCancel: () => { this.setState({ isCopiedText: false }) },
                  okText: 'Да',
                  cancelText: 'Нет, возникла ошибка',
                  okCancel: true,
                  centered: true
                })
              }

              } style={{ marginTop: 10, narginBottom: 10 }} type='primary'>
                ОТПРАВИТЬ
              </Button>
            </a>
          </div>

        }

      </div>
    }

    return <div className='solo-sequence-send-video-container'>

    </div>

  }

  // СУДЬЯ-5 отправляет оценку
  judge5SendScore = async () => {

    const { client } = this.props;
    // сначала подгружаем текущие баллы ЕЩЕ РАЗ
    const { sequence, judges5MyScore, judge5Judges } = this.state;
    const { Code } = sequence;

    const seqArr = await client.getSoloUserSequences({ Code });
    const seq = seqArr[0];
    const { Options } = seq;

    // { judges5: { scores: {<UserId>:score}, result: score  } }

    let OptionsParse = { judges5: { scores: {} } }

    if (Options) {
      OptionsParse = JSON.parse(Options);
      if (OptionsParse && OptionsParse.judges5 && OptionsParse.judges5.result) {
        message.error('Нельзя отправить, финальный балл уже стоит')
        return;
      }

      if (!OptionsParse.judges5) {
        OptionsParse.judges5 = { scores: {} }
      }

    }

    // если можем отправить
    OptionsParse.judges5.scores[this.UserId] = judges5MyScore;

    // теперь проверяем, не все ли судьи отправили оценки. Если да - мы пишем результат
    const judges5Length = (judge5Judges || []).length;
    if (Object.keys(OptionsParse.judges5.scores).length >= judges5Length) {
      const fullScore = Object.values(OptionsParse.judges5.scores).reduce((memo, value) => { return memo + value }, 0);
      const avarageScore = fullScore / judges5Length;
      OptionsParse.judges5.result = Math.round(avarageScore * 2) / 2;
    }

    await client.judgeSendOptionsSequence({ Options: JSON.stringify(OptionsParse), Code });
    client.getSoloUserSequences({ Code }).then((result) => {

      if (!result && !result[0]) {
        return this.setState({ sequence: null, status: 'no_sequence' })
      }
      const sequence = result[0];
      const { RoundId } = sequence;
      if (!RoundId) return this.setState({ sequence: null, status: 'no_sequence' });

      // теперь грузим раунд
      client.getSoloRounds(null, RoundId).then((result) => {
        if (!result && !result[0]) {
          return this.setState({ sequence: null, status: 'no_sequence' })
        }
        this.setState({ sequence, round: result[0], status: 'loaded' })
      }
      )
    })

  }

  userSendVideo = (Video) => {
    const { code, client } = this.props;

    // сначала сохраняем асинхронно результат, потом грузим его

    client.userSendVideoSequence({ IsVideo: Video, Code: code }).then(() => {

      client.getSoloUserSequences({ Code: code }).then((result) => {

        if (!result && !result[0]) {
          return this.setState({ sequence: null, status: 'no_sequence' })
        }
        const sequence = result[0];
        const { RoundId } = sequence;
        if (!RoundId) return this.setState({ sequence: null, status: 'no_sequence' });

        // теперь грузим раунд
        client.getSoloRounds(null, RoundId).then((result) => {
          if (!result && !result[0]) {
            return this.setState({ sequence: null, status: 'no_sequence' })
          }
          this.setState({ sequence, round: result[0], status: 'loaded' })
        }
        )
      })
    })

    this.setState({ status: 'loading' })

  }

}

/*
    ChildrenComponent - какой компонент использовать
    CholdrenProps - что туда прокинуть
    onCallback - функция, которую вызывать при триггере компонента
    callbackName - имя функции, которую тригерить у компонента
    callbackParam - имя параметра, в котором к компоненты улетит callbackStatus
*/

class InfoAdder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callbackStatus: ''
    }
  }

  onCallback = (data) => {
    const { onCallback } = this.props;
    this.setState({ callbackStatus: 'loading' });
    onCallback(data).then(() => {
      this.setState({ callbackStatus: '' });
    })
  }

  render() {
    const { callbackStatus } = this.state;
    return <TrefaTextEdit buttonText={'Добавить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Добавить' }} onButtonClick={this.onCallback} />
  }

}

class InfoChanger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callbackStatus: ''
    }
  }

  onCallback = (data) => {
    const { onCallback } = this.props;
    this.setState({ callbackStatus: 'loading' });
    onCallback(data).then(() => {
      this.setState({ callbackStatus: '' });
    })
  }

  render() {
    const { text } = this.props;
    const { callbackStatus } = this.state;
    return <TrefaTextEdit text={text} buttonText={'Сохранить'} buttonState={{ disabled: callbackStatus === 'loading', disabledText: 'Сохранение' }} onButtonClick={this.onCallback} />
  }

}